
import React from 'react';

import icon_bg_onb    from "../../static/pics/bg/bg-onb.svg"
import main_img       from "../../static/pics/onboarding/main-img.png";
import bg_onb_left    from "../../static/pics/bg/bg-onb-left.svg";
import farming        from "../../static/pics/onboarding/farming.svg";
import farming_sm     from "../../static/pics/onboarding/farming-sm.svg";
import launchpad      from "../../static/pics/onboarding/launchpad.svg";
import launchpad_sm   from "../../static/pics/onboarding/farming-sm.svg";
import mint           from "../../static/pics/onboarding/mint.svg";
import saft           from "../../static/pics/onboarding/saft.svg";
import cross_nft      from "../../static/pics/onboarding/cross-nft.svg";
import tickets        from "../../static/pics/onboarding/tickets.svg";

import { withTranslation }  from "react-i18next";
import {
	Link,
	withRouter,
	match,
	Redirect
} from 'react-router-dom';
import {
	History,
	Location
} from 'history';
import {
	gotoListResolve,
} from '../../reducers';

type MainPageProps = {
	store   : any,
	t       : any;
	match   : match;
	location: Location,
	history : History,
}
type MainPageState = {
	redirectToList: boolean,
}

class MainPage extends React.Component<MainPageProps, MainPageState> {

	store       : any;
	unsubscribe!: Function;
	t           : any;

	constructor(props: MainPageProps) {
		super(props);

		this.store = props.store;
		this.state = {
			redirectToList: false,
		};
		this.t     = this.props.t;
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {
			if ( this.store.getState().gotoListRequested ) {
				this.store.dispatch(gotoListResolve());
				this.setState({ redirectToList: true });
			}
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	render() {

		if ( this.state.redirectToList ) {
			return <Redirect to="/list" />
		}

		return (
			<main className="s-main">
				<section className="onb-intro">
					<img className="onb-bg" src={ icon_bg_onb } alt="" />
					<img className="onb-bg-left" src={ bg_onb_left } alt="" />
					<div className="bg-gradient"></div>
					<div className="container">
						<div className="inner">
							<picture className="onb-intro__img">
								<img src={ main_img } alt="" />
							</picture>
							<div className="onb-intro__text">
								<h1 className="h1">DAO Envelop <span className="text-grad">dApps</span></h1>
								<p>NFT2.0 starts here. New features, new utilities, new cases</p>
								<a className="btn" href="/wrap">Create NFT2.0</a>
							</div>
						</div>
					</div>
				</section>
				<section className="onb-services">
					<div className="container">
						<div className="row">
							<div className="col-12 col-sm-6">
								<a className="onb-services__item" href="https://appv0.envelop.is/farming">
									<picture className="img">
										<source media="(min-width: 1310px)" srcSet={ farming } />
										<img src={ farming_sm } alt="Liquid Farming" />
									</picture>
									<h3>Liquid Farming </h3>
									<div className="text">
										<p>Keep and trade your farm position</p>
										<span className="link-style">Start farming</span>
									</div>
								</a>
							</div>
							<div className="col-12 col-sm-6">
								<a className="onb-services__item" href="https://app.envelop.is/launchpad">
									<picture className="img">
										<source media="(min-width: 1310px)" srcSet={ launchpad } />
										<img src={ launchpad_sm } alt="Launchpad" />
									</picture>
									<h3>Launchpad</h3>
									<div className="text">
										<p>The easiest way to&nbsp;trade collateralized NFT</p>
										<span className="link-style">Create a&nbsp;launchpad</span>
									</div>
								</a>
							</div>
							<div className="col-12 col-sm-6 col-lg-3">
								<a className="onb-services__item sm-item" href="/mint">
									<picture className="img">
										<img src={ mint } alt="Mint" />
									</picture>
								<h3>Mint</h3>
								<div className="text">
									<p>Secure storage of&nbsp;your multichain NFTs</p>
									<span className="link-style">Mint NFT</span>
								</div>
								</a>
							</div>
							<div className="col-12 col-sm-6 col-lg-3">
								<Link className="onb-services__item sm-item" to="/saft">
									<picture className="img">
										<img src={ saft } alt="SAFT" />
									</picture>
									<h3>SAFT</h3>
									<div className="text">
										<p>Fundraise tool</p>
										<span className="link-style">Wrap batch </span>
									</div>
								</Link>
							</div>
							<div className="col-12 col-sm-6 col-lg-3">
								<Link className="onb-services__item sm-item" to="/crossings">
									<picture className="img">
										<img src={ cross_nft } alt="Crossing" />
									</picture>
									<h3>Cross-NFT</h3>
									<div className="text">
										<p>Cross-chain liquidity transfer tool</p>
										<span className="link-style">Start crossing</span>
									</div>
								</Link>
							</div>
							<div className="col-12 col-sm-6 col-lg-3">
								<a className="onb-services__item sm-item" href="https://getpass.is/">
									<picture className="img">
										<img src={ tickets } alt="Tickets" />
									</picture>
									<h3>Tickets</h3>
									<div className="text">
										<p>Web3.0 event manager</p>
										<span className="link-style">Create tickets</span>
									</div>
								</a>
							</div>
							
						</div>
					</div>
				</section>
			</main>
		)
	}
}

export default withTranslation("translations")(withRouter(MainPage));