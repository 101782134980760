
import { MetamaskAdapter }  from '.';
import erc1155_abi          from '../../abis/_erc1155.json';
import {
	OriginalTokenType,
	_AssetType
} from './_types';
import { Contract }         from 'web3-eth-contract';
import { processSwarmUrl } from '../_utils';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

export const checkApprovalERC1155Token = async (params: {
	metamaskAdapter?: MetamaskAdapter,
	contract?       : Contract,
	contractAddress?: string,
	userAddress     : string,
	addressTo       : string,
}) => {
	let contractToCall = params.contract;
	if ( !contractToCall ) {
		if ( params.metamaskAdapter ) {
			contractToCall = new params.metamaskAdapter.web3.eth.Contract(erc1155_abi as any, params.contractAddress);
		} else {
			console.log('Cannot create contract');
			return;
		}
	}

	return await contractToCall.methods.isApprovedForAll(params.userAddress, params.addressTo).call();
}
export const setApprovalERC1155Token = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	userAddressFrom: string,
	addressTo: string,
	t: any
) => {

	if ( !contractAddress ) { throw new Error(t('Empty params')); }
	if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { throw new Error(t('Bad contract address')); }
	if ( !metamaskAdapter.web3.utils.isAddress(addressTo) ) { throw new Error(t('Bad aprroval address')); }

	const contract = new metamaskAdapter.web3.eth.Contract(erc1155_abi as any, contractAddress);

	const tx = contract.methods.setApprovalForAll(
		addressTo,
		true
	);

	// pre-send transaction check
	try {
		await tx.estimateGas({ from: userAddressFrom })
	} catch(e: any) {
		let errorMsg = '';
		try {
				errorMsg = e.message;
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
		} catch(ignored) {
			errorMsg = e;
		}
		throw errorMsg;
	}

	return tx.send({ from: userAddressFrom });
}
export const setApprovalERC1155TokenMultisig = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	userAddressFrom: string,
	addressTo: string,
	t: any
) => {
	return new Promise((res, rej) => {
		if ( !contractAddress ) { rej(new Error(t('Empty params'))); }
		if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { rej(new Error(t('Bad contract address'))); }
		if ( !metamaskAdapter.web3.utils.isAddress(addressTo) ) { rej(new Error(t('Bad aprroval address'))); }

		const contract = new metamaskAdapter.web3.eth.Contract(erc1155_abi as any, contractAddress);

		const tx = contract.methods.setApprovalForAll(
			addressTo,
			true
		);

		tx.send({ from: userAddressFrom }, (err: any, data: any) => {
			if ( err ) { rej(err); }
			res(data);
		});
	});
}
export const transferERC1155Token = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	tokenId: string,
	amount: BigNumber,
	userAddressFrom: string,
	addressTo: string,
	t: any
) => {

	if ( !contractAddress || !tokenId                           ) { throw new Error(t('Empty params')); }
	if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { throw new Error(t('Bad contract address')); }
	if ( !metamaskAdapter.web3.utils.isAddress(addressTo)       ) { throw new Error(t('Bad recipient address')); }

	const contract = new metamaskAdapter.web3.eth.Contract(erc1155_abi as any, contractAddress);

	const tx = contract.methods.safeTransferFrom(
		userAddressFrom,
		addressTo,
		tokenId,
		amount.toString(),
		'0x0'
	);

	// pre-send transaction check
	try {
		await tx.estimateGas({ from: userAddressFrom })
	} catch(e: any) {
		let errorMsg = '';
		try {
				errorMsg = e.message;
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
		} catch(ignored) {
			errorMsg = e;
		}
		throw errorMsg;
	}

	return tx.send({ from: userAddressFrom })

}
export const transferERC1155TokenMultisig = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	tokenId: string,
	amount: BigNumber,
	userAddressFrom: string,
	addressTo: string,
	t: any
) => {

	return new Promise((res, rej) => {
		if ( !contractAddress || !tokenId                           ) { rej(new Error(t('Empty params'))); }
		if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { rej(new Error(t('Bad contract address'))); }
		if ( !metamaskAdapter.web3.utils.isAddress(addressTo)       ) { rej(new Error(t('Bad recipient address'))); }

		const contract = new metamaskAdapter.web3.eth.Contract(erc1155_abi as any, contractAddress);

		const tx = contract.methods.safeTransferFrom(
			userAddressFrom,
			addressTo,
			tokenId,
			amount.toString(),
			'0x0'
		);

		tx.send({ from: userAddressFrom }, (err: any, data: any) => {
			if ( err ) { rej(err); }
			res(data);
		});
	});

}
export const getERC1155Token = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	tokenId: string,
	userAddress: string,
	t: any,
	sortParams: {
		blockNumber: BigNumber | undefined,
		logIndex: BigNumber | undefined
	}
): Promise<OriginalTokenType> => {

	if ( !contractAddress || !tokenId ) { throw new Error(t('Empty params')); }

	if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { throw new Error(t('Bad address')); }

	const chainId  = await metamaskAdapter.web3.eth.getChainId();

	// if ( contractAddress === metamaskAdapter.wrapperContract.contractAddress ) {
	// 	return metamaskAdapter.wrapperContract.getWrappedTokenById(
	// 		contractAddress,
	// 		metamaskAdapter.wrapperContract.contract,
	// 		tokenId,
	// 		chainId
	// 	)
	// }

	let tokenUrl;
	let tokenUrlRaw;
	let tokenUrlRawJSON = '';
	let token;
	let tokenParsed;
	let name;
	let description;
	let image;
	let imageRaw;
	let copies;
	let totalSupply;
	try {
		const contract = new metamaskAdapter.web3.eth.Contract(erc1155_abi as any, contractAddress);

		const copiesRes = await contract?.methods.balanceOf(userAddress, tokenId).call();
		copies = new BigNumber(copiesRes);

		try { totalSupply = new BigNumber(await contract?.methods.totalSupply(tokenId).call()); } catch(ignored) {}

		tokenUrlRaw = await contract?.methods.uri(tokenId).call();
		tokenUrl = processSwarmUrl(tokenUrlRaw);
	} catch(e: any) {
		console.log('Cannot get erc1155 token: ', e)

		let errorMsg = '';
		try {
			const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
			errorMsg = errorParsed.originalError.message;
		} catch(ignored) {}

		if ( errorMsg === '' ) {
			throw new Error(t('Cannot connect to contract'))
		} else {
			throw new Error(errorMsg)
		}
	}

	try {
		token           = await fetch(tokenUrl);
		tokenUrlRawJSON = await token.text();
		tokenParsed     = JSON.parse(tokenUrlRawJSON);
		name            = tokenParsed.name        || '';
		description     = tokenParsed.description || '';
		image           = '';
		imageRaw        = '';
		if ( tokenParsed.image     ) { image = processSwarmUrl(tokenParsed.image    ); imageRaw = tokenParsed.image }
		if ( tokenParsed.image_url ) { image = processSwarmUrl(tokenParsed.image_url); imageRaw = tokenParsed.image_url }
	} catch(e) {
		console.log('Cannot fetch token data', token);
		name        = '';
		description = '';
		image       = '';
	}

	return {
		chainId,
		contractAddress,
		tokenId,
		tokenUrlRawJSON,
		tokenUrl,
		tokenUrlRaw,
		name,
		description,
		image,
		imageRaw,
		assetType: _AssetType.ERC1155,
		amount: copies,
		totalSupply,
	}
}