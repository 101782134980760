
import React               from 'react';
import {
	withRouter,
	match,
} from 'react-router-dom';
import {
	MetamaskAdapter,
} from '../../models/BlockchainAdapter';

import {
	History,
	Location
} from 'history';
import {
	withTranslation
} from "react-i18next";

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type MyRoyaltiesPageProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function,
	t                     : any,
	match                 : match;
	location              : Location,
	history               : History,
}
type MyRoyaltiesPageState = {
}

class MyRoyaltiesPage extends React.Component<MyRoyaltiesPageProps, MyRoyaltiesPageState> {

	store                 : any;
	unsubscribe!          : Function;
	metamaskAdapter       : MetamaskAdapter;
	showAuthMethodSelector: Function;
	t                     : any;

	constructor(props: MyRoyaltiesPageProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.t                      = props.t;

		this.state = {
		}
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {
			this.setState({
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	render() {
		return (
			<main className="s-main">
				<div className="container">
					<div className="h3">White List</div>
					<div className="c-wrap__table light height-auto">
						<div className="item item-header">
							<div className="row">
								<div className="col-md-2 mb-2">Address</div>
								<div className="col-md-3 mb-2">Name</div>
								<div className="col-md-5 mb-2">Enabled For</div>
								<div className="col-md-2 mb-2 md-right">Transfer Fee Model</div>
							</div>
						</div>
						<div className="item">
							<div className="row">
								<div className="mb-2 col-12 col-sm-6 col-md-2">
									<span className="col-legend">Address </span>
									<span>0x867....1185</span>
								</div>
								<div className="mb-2 col-12 col-sm-6 col-md-3">
									<span className="col-legend">Name </span>
									<span>Some name</span>
								</div>
								<div className="mb-2 col-12 col-sm-6 col-md-5">
									<span className="col-legend mb-2">Enabled For </span>
									<div className="tb-tags">
										<span className="t-tag">fee</span>
										<span className="t-tag">collateral</span>
										<span className="t-tag">removal from collateral</span>
									</div>
								</div>
								<div className="mb-2 md-right col-12 col-sm-6 col-md-2">
									<span className="col-legend">Transfer Fee Model </span>
									<a href="/">0x867....1185</a>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="row">
								<div className="mb-2 col-12 col-sm-6 col-md-2">
									<span className="col-legend">Address </span>
									<span>0x867....1185</span>
								</div>
								<div className="mb-2 col-12 col-sm-6 col-md-3">
									<span className="col-legend">Name </span>
									<span>Some name</span>
								</div>
								<div className="mb-2 col-12 col-sm-6 col-md-5">
									<span className="col-legend mb-2">Enabled For </span>
									<div className="tb-tags">
										<span className="t-tag">fee</span>
										<span className="t-tag">collateral</span>
										<span className="t-tag">removal from collateral</span>
									</div>
								</div>
								<div className="mb-2 md-right col-12 col-sm-6 col-md-2">
									<span className="col-legend">Transfer Fee Model </span>
									<a href="/">0x867....1185</a>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="row">
								<div className="mb-2 col-12 col-sm-6 col-md-2">
									<span className="col-legend">Address </span>
									<span>0x867....1185</span>
								</div>
								<div className="mb-2 col-12 col-sm-6 col-md-3">
									<span className="col-legend">Name </span>
									<span>Some name</span>
								</div>
								<div className="mb-2 col-12 col-sm-6 col-md-5">
									<span className="col-legend mb-2">Enabled For </span>
									<div className="tb-tags">
										<span className="t-tag">fee</span>
										<span className="t-tag">collateral</span>
										<span className="t-tag">removal from collateral</span>
									</div>
								</div>
								<div className="mb-2 md-right col-12 col-sm-6 col-md-2">
									<span className="col-legend">Transfer Fee Model </span>
									<a href="/">0x867....1185</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		)
	}
}

export default withTranslation("translations")(withRouter(MyRoyaltiesPage));