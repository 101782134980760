
import icon_logo from "../../static/pics/logo.svg";
import i_in from "../../static/pics/socials/in.svg"
import telegram from "../../static/pics/socials/telegram.svg"
import twitter from "../../static/pics/socials/twitter.svg"
import m from "../../static/pics/socials/m.svg"
import github from "../../static/pics/socials/github.svg"
import youtube from "../../static/pics/socials/youtube.svg"
import insta from "../../static/pics/socials/insta.svg"
import discord from "../../static/pics/socials/discord.svg"
import reddit from "../../static/pics/socials/reddit.svg"
import tiktok from "../../static/pics/socials/tiktok.svg"
import dw_telegram from "../../static/pics/socials/dw-telegram.svg"
import { useTranslation } from 'react-i18next';

function Footer() {

	const { t } = useTranslation();

	return (
		<footer className="s-footer">
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-2 col-lg-auto">
						<div className="s-footer__logo">
							<img src={ icon_logo } alt="" />
						</div>
					</div>
					<div className="col-12 col-md-6 col-lg-auto">
						<ul className="s-footer__menu">
							<li><a target="_blank" rel="noopener noreferrer"
								href="http://dao.envelop.is">{ t('DAO') }</a></li>
							<li><a target="_blank" rel="noopener noreferrer"
								href="/">{ t('dApps') }</a></li>
							<li><a target="_blank" rel="noopener noreferrer"
								href="https://getpass.is/">{ t('GetPass') }</a></li>
							<li><a target="_blank" rel="noopener noreferrer"
								href="https://ecosystem.envelop.is/">{ t('Ecosystem') }</a></li>
							<li><a target="_blank" rel="noopener noreferrer"
								href="https://docs.envelop.is/">{ t('Docs') }</a></li>	
							<li><a target="_blank" rel="noopener noreferrer" className="text-grad"
								href="mailto:info@envelop.is">info@envelop.is</a></li>
						</ul>
					</div>
					<div className="col-12 col-md-4 col-lg-auto ml-lg-auto">
						<ul className="socials">
							<li><a href="https://www.linkedin.com/company/niftsy" target="_blank" rel="noopener noreferrer" title="LinkedIn"><img src={ i_in } alt="NIFTSY is token" /></a></li>
							<li><a href="https://t.me/envelop_en" target="_blank" rel="noopener noreferrer" title="Telegram"><img src={ telegram } alt="ENVELOP telegram group" /></a></li>
							<li><a href="https://twitter.com/Envelop_project" target="_blank" rel="noopener noreferrer" title="Twitter"><img src={ twitter } alt="Our twitter" /></a></li>
							<li><a href="https://blog.envelop.is/" target="_blank" rel="noopener noreferrer" title="Medium"><img src={ m } alt="Blog about Web 3.0" /></a></li>
							<li><a href="https://github.com/niftsy/niftsysmarts" target="_blank" rel="noopener noreferrer" title="Github"><img src={ github } alt="Github of our NFT project" /></a></li>
							<li><a href="https://www.youtube.com/c/ENVELOP" target="_blank" rel="noopener noreferrer" title="YouTube"><img src={ youtube } alt="ENVELOP. NFTs YouTube Channel" /></a></li>
							<li><a href="http://Instagram.com/envelop.project" target="_blank" rel="noopener noreferrer" title="Instagram"><img src={ insta } alt="Instagram envelop.project" /></a></li>
							<li><a href="https://discord.gg/gtYcjqq76f" target="_blank" rel="noopener noreferrer" title="Discord"><img src={ discord } alt="ENVELOP Discord group" /></a></li>
							<li><a href="https://www.reddit.com/r/ENVELOP_NIFTSY/" target="_blank" rel="noopener noreferrer" title="Reddit"><img src={ reddit } alt="ENVELOP Reddit" /></a></li>
							<li><a href="https://www.tiktok.com/@envelop.is" target="_blank" rel="noopener noreferrer" title="TikTok"><img src={ tiktok } alt="ENVELOP TikTok Channel" /></a></li>
							<li><a href="https://t.me/nonfungible_web" target="_blank" rel="noopener noreferrer" title="Telegram (DeWeb)"><img src={ dw_telegram } alt="ENVELOP telegram group (DeWeb)" /></a></li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer;