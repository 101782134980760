
import React from 'react';
import {
	withRouter,
} from 'react-router-dom';
import {
	History,
	Location
} from 'history';
import {
	WrappedTokenType,
	ERC20ContractParamsType,
	MetamaskAdapter,
	OriginalTokenType,
	WNFTStorageContract,
	fillCollateralsImages,
} from '../../models/BlockchainAdapter';

import TokenWrappedPreviewPage from '../TokenWrappedPreviewPage';
import TokenPreviewPage        from '../TokenPreviewPage';

import {
	getOriginalToken,
	getWrappedToken
} from '../../models/TokenFetchWrapper/tokenfetchwrapper';

import {
	clearError,
	clearInfo,
	requestChain,
	setAuthMethod,
	setError,
	setInfo,
	tokenPreviewClear,
} from '../../reducers';

import { withTranslation, } from "react-i18next";
import { fetchWrappedTokenById } from '../../models/APIService/apiservice';
import { localStorageGet } from '../../models/_utils';

import icon_loading        from '../../static/pics/mascot/loading.png';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type TokenPreviewWrapperProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function;
	t                     : any,
	match                 : any;
	location              : Location,
	history               : History,
}
type TokenPreviewWrapperState = {
	chainId                 : number,
	userAddress             : string,
	wNFTStorages            : {
		list: Array<{ address: string, standart: string }>,
		storagesCount: number,
	},
	balanceNative           : BigNumber,
	decimalsNative          : number,
	iconNative              : string,
	symbolNative            : string,
	wrappedToken            : WrappedTokenType | undefined,
	originalToken           : OriginalTokenType | undefined,
	explorerBaseUrl         : string,

	techToken            : ERC20ContractParamsType,
	erc20CollateralTokens: Array<ERC20ContractParamsType>,
	erc20OtherTechTokens : Array<ERC20ContractParamsType>,
	transferAllowances   : Array<{ wrapperAddress: string, transferModelAddress: string, erc20TokenAddress: string, allowance: BigNumber }>,

	copiedHint: undefined | { id: string, icon: string },

	cannotLoad    : boolean,
	loadInProgress: boolean,
}

class TokenPreviewWrapper extends React.Component<TokenPreviewWrapperProps, TokenPreviewWrapperState> {

	store                 : any;
	metamaskAdapter       : MetamaskAdapter;
	showAuthMethodSelector: Function;
	unsubscribe!          : Function;
	t                     : any;

	contractAddress       : string;
	tokenId               : string;
	tokenFetchRequested   : boolean;

	constructor(props: TokenPreviewWrapperProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.t                      = props.t;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.tokenFetchRequested    = false;
		this.contractAddress        = props.match.params.contractAddress;
		this.tokenId                = props.match.params.tokenId;

		const userAddress    = this.store.getState().account.address;
		const wrappedToken   = undefined;
		const originalToken  = undefined;
		const cannotLoad     = false;
		const loadInProgress = false;

		const wNFTStorages = this.store.getState().wNFTStorages;
		const paramsProcessed = this.getToken({ wrappedToken, originalToken, wNFTStorages, cannotLoad, loadInProgress, userAddress });

		this.state = {
			chainId                 : this.store.getState().metamaskAdapter.chainId,
			userAddress             : userAddress,
			balanceNative           : this.store.getState().account.balanceNative,
			decimalsNative          : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative            : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative              : this.store.getState().metamaskAdapter.networkTokenIcon,
			explorerBaseUrl         : this.store.getState().metamaskAdapter.explorerBaseUrl,

			wrappedToken            : paramsProcessed.wrappedToken,
			originalToken           : paramsProcessed.originalToken,
			wNFTStorages            : paramsProcessed.wNFTStorages,
			cannotLoad              : paramsProcessed.cannotLoad,
			loadInProgress          : paramsProcessed.loadInProgress,

			techToken            : this.store.getState().erc20TechTokenParams,
			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
			erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
			transferAllowances   : this.store.getState().transferModelAllowances,

			copiedHint: undefined,
		}

		this.store.dispatch(requestChain( parseInt(`${props.match.params.chainId}`) ));
		if ( this.store.getState().metamaskAdapter.logged && this.metamaskAdapter.wrapperContract ) {
			if ( this.store.getState().metamaskAdapter.requestChainId && this.store.getState().metamaskAdapter.requestChainId !== this.store.getState().metamaskAdapter.chainId ) {
				this.store.dispatch(setInfo({
					text: this.t(`You are trying to open chain which does not match one selected in metamask`),
					buttons: [
						{
							text: this.t('Switch network'),
							clickFunc: () => {
								(window as any).ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: '0x' + Number(this.store.getState().metamaskAdapter.requestChainId).toString(16) }], // chainId must be in hexadecimal numbers
								})
								.catch((e: any) => {
									if ( e.code === 4902 ) {

										const foundChain = this.metamaskAdapter.availiableChains.find((item) => { return item.chainId === this.store.getState().metamaskAdapter.requestChainId });
										if ( !foundChain ) { return; }

										(window as any).ethereum.request({
											method: "wallet_addEthereumChain",
											params: [{
												chainId: '0x' + Number(foundChain.chainId).toString(16),
												rpcUrls: [ foundChain.chainRPCUrl ],
												chainName: foundChain.chainName,
												nativeCurrency: {
													name: foundChain.networkTokenTicket,
													symbol: foundChain.networkTokenTicket,
													decimals: foundChain.networkTokenDecimals
												},
												blockExplorerUrls: [ foundChain.explorerBaseUrl ]
											}]
										})
											.catch((e: any) => {
												this.store.dispatch(clearInfo());
												this.store.dispatch(setError({
													text: `${this.t('Cannot add chain')}: ${e.message || e}`,
													buttons: undefined,
													links: undefined,
												}));
											})
									}
								})
							}
						},
						{
							text: this.t('Cancel'),
							clickFunc: async () => {
								window.location.href = '/list';
								this.store.dispatch(requestChain( this.store.getState().metamaskAdapter.chainId ));
								this.store.dispatch(clearInfo());
							}
						},
					],
					links: undefined
				}));
			}
		} else {
			const prevAuthMethod =  localStorageGet('authMethod').toLowerCase();
			if ( !prevAuthMethod ) {
				this.showAuthMethodSelector( false );
				this.tokenFetchRequested = true;
				return;
			} else {
				this.tokenFetchRequested = true;
				this.store.dispatch(setAuthMethod( prevAuthMethod ));
				this.metamaskAdapter.connect();
			}
		}
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {

			const userAddress     = this.store.getState().account.address;
			let wrappedToken      = this.state.wrappedToken;
			let originalToken     = this.state.originalToken;
			const wNFTStorages    = this.store.getState().wNFTStorages;
			const cannotLoad      = this.state.cannotLoad;
			const loadInProgress  = this.state.loadInProgress;
			const paramsProcessed = this.getToken({ wrappedToken, originalToken, wNFTStorages, cannotLoad, loadInProgress, userAddress });

			this.setState({
				chainId        : this.store.getState().metamaskAdapter.chainId,
				userAddress    : userAddress,

				wrappedToken   : paramsProcessed.wrappedToken,
				originalToken  : paramsProcessed.originalToken,
				wNFTStorages   : paramsProcessed.wNFTStorages,
				cannotLoad     : paramsProcessed.cannotLoad,
				loadInProgress : paramsProcessed.loadInProgress,
				balanceNative  : this.store.getState().account.balanceNative,
				decimalsNative : this.store.getState().metamaskAdapter.networkTokenDecimals,
				symbolNative   : this.store.getState().metamaskAdapter.networkTokenTicket,
				iconNative     : this.store.getState().metamaskAdapter.networkTokenIcon,
				explorerBaseUrl: this.store.getState().metamaskAdapter.explorerBaseUrl,

				techToken            : this.store.getState().erc20TechTokenParams,
				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
				erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
				transferAllowances   : this.store.getState().transferModelAllowances,
			});
		});
 	}
	componentWillUnmount() {
		this.unsubscribe();
		this.store.dispatch(tokenPreviewClear());
	}
	componentDidUpdate(prevProps: TokenPreviewWrapperProps) {

		if ( !this.props.match.params.contractAddress ) { return; }
		if ( !prevProps.match.params.contractAddress  ) { return; }
		if (
			this.props.match.params.chainId === prevProps.match.params.chainId &&
			this.props.match.params.contractAddress.toLowerCase() === prevProps.match.params.contractAddress.toLowerCase() &&
			this.props.match.params.tokenId === prevProps.match.params.tokenId
		) { return; }

		this.contractAddress = this.props.match.params.contractAddress;
		this.tokenId         = this.props.match.params.tokenId;

		const userAddress    = this.store.getState().account.address;
		const wrappedToken   = undefined;
		const originalToken  = undefined;
		const cannotLoad     = false;
		const loadInProgress = false;

		const wNFTStorages = this.store.getState().wNFTStorages;
		const paramsProcessed = this.getToken({ wrappedToken, originalToken, wNFTStorages, cannotLoad, loadInProgress, userAddress });

		this.setState({
			wrappedToken            : paramsProcessed.wrappedToken,
			originalToken           : paramsProcessed.originalToken,
			wNFTStorages            : paramsProcessed.wNFTStorages,
			cannotLoad              : paramsProcessed.cannotLoad,
			loadInProgress          : paramsProcessed.loadInProgress,
		});
	}
	cannotLoadToken(e: any) {
		console.log('Cannot load token', e);
		this.setState({ cannotLoad: true, loadInProgress: false, });
		this.store.dispatch(setError({
			text: `Cannot load token: ${e.message.split('\n')[0]}`,
			buttons: [{
				text: this.t('Ok'),
				clickFunc: () => {
					this.store.dispatch(clearError());
					this.props.history.push(`/list`);
				}
			}],
			links: undefined
		}));
	}
	getWrappedToken(storageContract: WNFTStorageContract, tokenId: string) {
		fetchWrappedTokenById({
			chainId: this.metamaskAdapter.chainId || 0,
			contractAddress: storageContract.contractAddress,
			tokenId: tokenId,
			assetType: storageContract.contractStandart,
			userAddress: this.metamaskAdapter.userAddress
		})
			.then((data) => {
				if ( data ) {
					fillCollateralsImages(this.metamaskAdapter, data, this.metamaskAdapter.userAddress)
						.then((ddata) => {
							this.setState({
								loadInProgress: false,
								wrappedToken: ddata,
							});
						})
				} else {
					getWrappedToken({
						chainId: this.metamaskAdapter.chainId || 0,
						contractAddress: storageContract.contractAddress,
						tokenId: tokenId,
						assetType: storageContract.contractStandart,
						wNFTStorageContract: storageContract,
						metamaskAdapter: this.metamaskAdapter,
					})
						.then((data: WrappedTokenType | undefined) => {
							if ( data ) {
								fillCollateralsImages(this.metamaskAdapter, data, this.metamaskAdapter.userAddress)
									.then((ddata) => {
										this.setState({
											loadInProgress: false,
											wrappedToken: ddata,
										});
									})
							} else {
								this.cannotLoadToken({ message: '' });
							}
						})
						.catch((e: any) => {
							this.cannotLoadToken(e);
						});
				}
			})
			.catch((e: any) => {
				this.cannotLoadToken(e);
			});

	}
	getToken(params: {
		wrappedToken: WrappedTokenType | undefined,
		originalToken: OriginalTokenType | undefined ,
		wNFTStorages: {
			list: Array<{ address: string, standart: string }>,
			storagesCount: number,
		},
		cannotLoad: boolean,
		loadInProgress: boolean,
		userAddress: string
	}) {

		let output = {
			wrappedToken  : params.wrappedToken,
			originalToken : params.originalToken,
			wNFTStorages  : params.wNFTStorages,
			cannotLoad    : params.cannotLoad,
			loadInProgress: params.loadInProgress,
		};

		if ( params.cannotLoad                                                     ) { return output; }
		if ( params.loadInProgress                                                 ) { return output; }
		if ( params.wrappedToken && params.originalToken                           ) { return output; }
		if ( !params.wNFTStorages                                                  ) { return output; }
		if ( params.wNFTStorages.storagesCount !== params.wNFTStorages.list.length ) { return output; }
		if ( !this.metamaskAdapter.wrapperContract                                 ) { return output; }

		if ( this.metamaskAdapter.wrapperContract.addressIsStorage(this.contractAddress) ) {
			// Wrapped token

			const storageContract = this.metamaskAdapter.wrapperContract.getStorageContract(this.contractAddress);
			if ( !storageContract ) {
				setTimeout(() => {
					const userAddress     = this.store.getState().account.address;
					let   wrappedToken    = this.state.wrappedToken;
					let   originalToken   = this.state.originalToken;
					const wNFTStorages    = this.store.getState().wNFTStorages;
					const cannotLoad      = this.state.cannotLoad;
					const loadInProgress  = this.state.loadInProgress;
					const paramsProcessed = this.getToken({ wrappedToken, originalToken, wNFTStorages, cannotLoad, loadInProgress, userAddress });

					this.setState({
						wrappedToken            : paramsProcessed.wrappedToken,
						originalToken           : paramsProcessed.originalToken,
						wNFTStorages            : paramsProcessed.wNFTStorages,
						cannotLoad              : paramsProcessed.cannotLoad,
						loadInProgress          : paramsProcessed.loadInProgress,
					});

				}, 500); // waiting a little bit to be sure that contracts has been inited
				return output;
			}

			// checking cache...
			const foundWrappedToken = this.store.getState().wrappedTokens.filter((item: WrappedTokenType) => {
				return item.contractAddress.toLowerCase() === this.contractAddress.toLowerCase() &&
						item.tokenId === this.tokenId
			});

			if ( foundWrappedToken.length ) {
				// ... and take token from cache if exists ...

				output = {
					...output,
					wrappedToken: foundWrappedToken[0],
					loadInProgress: false
				};

			} else {
				// ... or load if does not
				if ( storageContract.contract ) {

					output = {
						...output,
						loadInProgress: true
					};
					this.getWrappedToken(storageContract.contract, this.tokenId);
				}
			}
		} else {
			// Original token
			output = {
				...output,
				loadInProgress: true
			};

			getOriginalToken({
				metamaskAdapter: this.metamaskAdapter,
				contractAddress: this.contractAddress,
				tokenId: this.tokenId,
				t: this.t,
				userAddress: params.userAddress
			})
				.then((data) => {
					if ( data ) {
						this.setState({ originalToken: data, loadInProgress: false });
					}
				})
				.catch((error) => {
					this.setState({ cannotLoad: true, loadInProgress: false });
					this.store.dispatch(setError({
						text: `Cannot load token: ${error.message.split('\n')[0]}`,
						buttons: [{
							text: this.t('Ok'),
							clickFunc: () => {
								this.store.dispatch(clearError());
								this.props.history.push(`/list`);
							}
						}],
						links: undefined
					}));
				})
		}

		return output;
	}

	render() {

		if ( this.state.wrappedToken ) {
			return <TokenWrappedPreviewPage
				metamaskAdapter = { this.metamaskAdapter }
				store = { this.store }
				token = { this.state.wrappedToken }
			/>
		}

		if ( this.state.originalToken ) {
			return <TokenPreviewPage
				metamaskAdapter = { this.metamaskAdapter }
				store = { this.store }
				token = { this.state.originalToken }
			/>
		}

		return (
			<main className="s-main">
			<div className="modal">
			<div className="modal__inner">
			<div className="modal__bg"></div>
			<div className="container">
			<div className="modal__content">
				<div className="c-info only-title">
				<div className="c-info__img"><img src={ icon_loading } alt="" /></div>

					<div className="c-info__text">
						<div className="h2">
							{ 'Loading NFT-token' }
							<span className="loading-dots"><span>.</span><span>.</span><span>.</span></span>
						</div>
					</div>

				</div>
			</div>
			</div>
			</div>
			</div>
			</main>
		)

	}
}

export default withTranslation("translations")(withRouter(TokenPreviewWrapper));