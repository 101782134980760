
import React from 'react';
import {
	MetamaskAdapter,
	OriginalTokenType,
	WrappedTokenType,
	_AssetType
} from '../../models/BlockchainAdapter';

import {
	History,
} from 'history';

import {
	setApprovalERC721Token,
	setApprovalForAllERC721Token
} from '../../models/BlockchainAdapter/erc721contract';
import {
	clearInfo,
	setError,
	setInfo,
	setLoading,
	unsetLoading
} from '../../reducers';
import {
	setApprovalERC1155Token,
	setApprovalERC1155TokenMultisig
} from '../../models/BlockchainAdapter/erc1155contract';

import { withTranslation } from "react-i18next";

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type ApprovePopupProps = {
	store          : any,
	metamaskAdapter: MetamaskAdapter,
	t              : any,
	wrappedToken   : WrappedTokenType | undefined,
	originalToken  : OriginalTokenType | undefined,
	closePopup     : Function,
	history        : History,
}
type ApprovePopupState = {
	approveToAddress: string,
	authMethod: string,
}

class ApprovePopup extends React.Component<ApprovePopupProps, ApprovePopupState> {

	store          : any;
	unsubscribe!   : Function;
	metamaskAdapter: MetamaskAdapter;
	t              : any;
	closePopup     : Function;
	history        : History;

	constructor(props: ApprovePopupProps) {
		super(props);

		this.store           = props.store;
		this.metamaskAdapter = props.metamaskAdapter;
		this.t               = props.t;
		this.closePopup      = props.closePopup;
		this.history         = props.history;

		this.state = {
			approveToAddress: '',
			authMethod: this.store.getState().metamaskAdapter.authMethod || 'metamask',
		}
	}

	approveSuccess(data: any, qty: number, isMultisig?: boolean) {
		this.store.dispatch(unsetLoading());
		this.metamaskAdapter.updateAllBalances();

		let msgText = `${this.t('Your token has been approved for')}: ${this.state.approveToAddress}`;
		if ( qty > 1 ) {
			msgText = `${this.t('Your tokens have been approved for')}: ${this.state.approveToAddress}`;
		}
		if ( isMultisig ) {
			msgText = 'Transaction has been created'
		}

		this.store.dispatch(setInfo({
			text: msgText,
			 buttons: [{
				text: 'Ok',
				clickFunc: () => { this.store.dispatch(clearInfo()) }
			 }],
			links: [{
				text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
				url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
			}]
		}));
		this.closePopup();
	}
	approveError(e: any) {
		console.log(e);
		this.store.dispatch(unsetLoading());

		let errorMsg = '';
		try {
			if ( 'message' in e ) {
				errorMsg = e.message;
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
			} else {
				errorMsg = `${e}`;
			}
		} catch (ignored) {
			errorMsg = `${e}`;
		}

		let links = undefined;
		try {
			if ('transactionHash' in e) {
				links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
			} else {
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					const txHash = errorParsed.transactionHash;
					if ( txHash ) {
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					}
				} catch(ignored) {}
			}
		} catch (ignored) {}

		this.store.dispatch(setError({
			text: `${this.t('Cannot approve token')}: ${errorMsg}`,
			buttons: undefined,
			links: links,
		}));
	}
	async approveAllWrappedTokenSubmit() {
		this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

		if ( !this.props.wrappedToken ) { return; }

		const fountContract = this.metamaskAdapter.wrapperContract.getStorageContract(this.props.wrappedToken.contractAddress);
		if ( !fountContract || !fountContract.contract ) { return; }

		if ( this.state.authMethod === 'gnosis' ) {
			fountContract.contract.approveAllTokensMultisig(this.state.approveToAddress)
				.then((data: any) => { this.approveSuccess(data, 2, true, ) })
				.catch((e) => { this.approveError(e) })
		} else {
			fountContract.contract.approveAllTokens(this.state.approveToAddress)
				.then((data) => { this.approveSuccess(data, 2) })
				.catch((e) => { this.approveError(e) })
		}
	}
	async approveWrappedTokenSubmit() {
		this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

		if ( !this.props.wrappedToken ) { return; }

		const fountContract = this.metamaskAdapter.wrapperContract.getStorageContract(this.props.wrappedToken.contractAddress);
		if ( !fountContract || !fountContract.contract ) { return; }

		if ( this.state.authMethod === 'gnosis' ) {
			fountContract.contract.approveTokenMultisig(this.props.wrappedToken, this.state.approveToAddress)
				.then((data: any) => { this.approveSuccess(data, 1, true) })
				.catch((e) => { this.approveError(e) })
		} else {
			fountContract.contract.approveToken(this.props.wrappedToken, this.state.approveToAddress)
				.then((data) => { this.approveSuccess(data, 1) })
				.catch((e) => { this.approveError(e) })
		}
	}
	async approveAllNotWrappedTokenSubmit() {
		this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

		if ( !this.props.originalToken ) { return; }

		if ( this.props.originalToken.assetType === _AssetType.ERC1155 ) {
			if ( this.state.authMethod === 'gnosis' ) {
				setApprovalERC1155TokenMultisig(
					this.metamaskAdapter,
					this.props.originalToken.contractAddress || '',
					this.store.getState().account.address,
					this.state.approveToAddress,
					this.t
				)
					.then((data) => { this.approveSuccess(data, 2, true) })
					.catch((e) => { this.approveError(e) })
			} else {
				setApprovalERC1155Token(
					this.metamaskAdapter,
					this.props.originalToken.contractAddress || '',
					this.store.getState().account.address,
					this.state.approveToAddress,
					this.t
				)
					.then((data) => { this.approveSuccess(data, 2) })
					.catch((e) => { this.approveError(e) })
			}
		} else {
			if ( this.state.authMethod === 'gnosis' ) {
				setApprovalForAllERC721Token(
					this.metamaskAdapter,
					this.props.originalToken.contractAddress || '',
					this.store.getState().account.address,
					this.state.approveToAddress,
					this.t
				)
					.then((data) => { this.approveSuccess(data, 2,  true) })
					.catch((e) => { this.approveError(e) })
			} else {
				setApprovalForAllERC721Token(
					this.metamaskAdapter,
					this.props.originalToken.contractAddress || '',
					this.store.getState().account.address,
					this.state.approveToAddress,
					this.t
				)
					.then((data) => { this.approveSuccess(data, 2) })
					.catch((e) => { this.approveError(e) })
			}
		}
	}
	async approveNotWrappedTokenSubmit() {
		this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

		if ( !this.props.originalToken ) { return; }

		if ( this.state.authMethod === 'gnosis' ) {
			setApprovalERC721Token(
				this.metamaskAdapter,
				this.props.originalToken.contractAddress || '',
				this.props.originalToken.tokenId || '',
				this.store.getState().account.address,
				this.state.approveToAddress,
				this.t
			)
				.then((data: any) => { this.approveSuccess(data, 1, true); })
				.catch((e) => { this.approveError(e); });
		} else {
			setApprovalERC721Token(
				this.metamaskAdapter,
				this.props.originalToken.contractAddress || '',
				this.props.originalToken.tokenId || '',
				this.store.getState().account.address,
				this.state.approveToAddress,
				this.t
			)
				.then((data: any) => { this.approveSuccess(data, 1); })
				.catch((e) => { this.approveError(e); });
		}
	}
	async approveAllWNFTV0TokenSubmit() {
		this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

		if ( !this.props.wrappedToken ) { return; }

		setApprovalForAllERC721Token(
			this.metamaskAdapter,
			this.props.wrappedToken.contractAddress || '',
			this.store.getState().account.address,
			this.state.approveToAddress,
			this.t
		)
			.then((data) => { this.approveSuccess(data, 2) })
			.catch((e) => { this.approveError(e) })
	}
	async approveWNFTV0TokenSubmit() {
		this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

		if ( !this.props.wrappedToken ) { return; }

		setApprovalERC721Token(
			this.metamaskAdapter,
			this.props.wrappedToken.contractAddress || '',
			this.props.wrappedToken.tokenId || '',
			this.store.getState().account.address,
			this.state.approveToAddress,
			this.t
		)
			.then((data: any) => {
				this.approveSuccess(data, 1);
			})
			.catch((e) => {
				this.approveError(e);
			});
	}
	getApproveAllSubmitBtn() {
		if (
			this.state.approveToAddress === '' ||
			!this.metamaskAdapter.web3.utils.isAddress(this.state.approveToAddress)
		) {
			return (
				<div className="col col-12 col-sm-4">
					<button
						className="btn btn-yellow w-100"
						type="button"
						disabled={ true }
					>{ this.t('Approve all') }</button>
				</div>
			)
		}
		return (
			<div className="col">
				<button
					className="btn"
					type="button"
					onClick={() => {
						if ( this.props.wrappedToken && this.props.wrappedToken.assetType === _AssetType.wNFTv0 ) {
							this.approveAllWNFTV0TokenSubmit();
							return;
						}
						if ( this.props.wrappedToken ) {
							this.approveAllWrappedTokenSubmit();
						} else {
							this.approveAllNotWrappedTokenSubmit();
						}
					}}
				>{ this.t('Approve all') }</button>
			</div>
		)
	}
	getApproveSubmitBtn() {
		if ( this.props.wrappedToken  && this.props.wrappedToken.assetType  === _AssetType.ERC1155 ) { return null; }
		if ( this.props.originalToken && this.props.originalToken.assetType === _AssetType.ERC1155 ) { return null; }
		if (
			this.state.approveToAddress === '' ||
			!this.metamaskAdapter.web3.utils.isAddress(this.state.approveToAddress)
		) {
			return (
				<div className="col col-12 col-sm-4">
					<button
						className="btn w-100"
						type="button"
						disabled={ true }
					>{ this.t('Approve') }</button>
				</div>
			)
		}
		return (
			<div className="col">
				<button
					className="btn"
					type="button"
					onClick={() => {
						if ( this.props.wrappedToken && this.props.wrappedToken.assetType === _AssetType.wNFTv0 ) {
							this.approveWNFTV0TokenSubmit();
							return;
						}
						if ( this.props.wrappedToken ) {
							this.approveWrappedTokenSubmit();
						} else {
							this.approveNotWrappedTokenSubmit();
						}
					}}
				>{ this.t('Approve') }</button>
			</div>
		)
	}

	render() {
		return (
			<div className="modal">
				<div
					className="modal__inner"
					onClick={(e) => {
						e.stopPropagation();
						if ((e.target as HTMLTextAreaElement).className === 'modal__inner') {
							this.closePopup();
						}
					}}
				>
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
							<div
								className="modal__close"
								onClick={() => { this.closePopup() }}
							>
								<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
									<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
								</svg>
							</div>
							<div className="c-add">
								<div className="c-add__text">
									<div className="h2">{ this.t('Set approval for token') }</div>
								</div>
								<div className="c-add__coins">
									<div className="c-add__form">

											<div className="form-row">
												<div className="col col-12">
													<div className="input-group">
														<input
															className="input-control"
															type="text"
															placeholder={ this.t('Paste address') }
															value={ this.state.approveToAddress }
															onChange={(e) => { this.setState({ approveToAddress: e.target.value }) }}
														/>
													</div>
												</div>

												{ this.getApproveSubmitBtn() }
												{ this.getApproveAllSubmitBtn() }
											</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation("translations")(ApprovePopup);