
import { default as urljoin } from 'url-join';

import {
	WrappedTokenType,
	_AssetType
} from '../BlockchainAdapter';
import {
	decodeAssetTypeFromIndex,
	decodeWrappedToken,
	_AssetItem,
	_Fee,
	_Lock,
	_Royalty
} from '../BlockchainAdapter/_types';

import { processSwarmUrl } from '../_utils';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

export type DiscoveredToken = {
	blocknumber: BigNumber,
	logindex: number,
	contract_address: string,
	token_id: string,
	owner: string,
	token_uri: string,
	asset_type: string,
	is_wnft?: boolean,
	balance?: string,
	in_contract_address?: string,
	in_token_id?: string,
	in_asset_type?: string,
	totalSupply?: string
}

export type APIWrappedToken = {
    collateral_json: Array<{
		amount: number,
		tokenId: number,
		assetType: number,
		contractAddress: string
	}>,
    contract_address: string,
    token_id: string,
    token_uri: string,
    owner: string,
    blocknumber: BigNumber,
    logindex: BigNumber,
    wnft_type: _AssetType,
	balance: number | undefined,
    initial_out_balance: BigNumber,
    in_asset_type: number,
    in_contract_address: string,
    in_token_id: string,
    in_amount: BigNumber,
    unwrap_destination: string,
    rules: string,
    is_burned: boolean,
    fees: Array<_Fee>,
    locks: Array<_Lock>,
    royalties: Array<_Royalty>,
    first_owner: string,
    create_tx: string,
    burn_tx: string,
    inserted: string,
    updated: string,
    updated_by: string,
    asset_type: _AssetType,
    is_wnft: boolean,
	totalSupply: string | undefined,
}
export type APICrossingItem = {
	id: string,
	source_chain: number,
	keeper_contract: string,
	source_tx: string,
	crossing_initiator: string,
	target_chain: number,
	target_contract: string,
	target_token_id: string,
	target_owner: string,
	sortorder: number,
	target_burn_txhash: string,
	target_burner_address: string,
	source_nft_status: string,
}
export type APIOracleSign = {
	oracle_signature: string,
	oracle_signer: string,
	sender: string,
	source_chain: number,
	source_keeper: string,
	target_chain: number,
	target_contract: string,
	target_token_id: string,
	tx_hash: string,
}
export type APIRoyaltyItem = {
	chain_id: number,
	contract_address: string,
	token_id: string,
	royalty_for: string,
	royalty_amount: BigNumber,
	royalty_from: string,
	royalty_token: string,
	blocknumber: BigNumber,
	txhash: string,
}
export type SwarmStampBatchId = {
	name: string,
	desc: string,
}

const parseAPIToken = async (item: APIWrappedToken, chainId: number): Promise<WrappedTokenType> => {
	const inAsset = {
		asset: {
			assetType: decodeAssetTypeFromIndex(`${item.in_asset_type}`),
			contractAddress: item.in_contract_address,
		},
		tokenId: `${item.in_token_id}`,
		amount: `${item.in_amount}`,
	};

	let collateral: Array<_AssetItem> = [];
	if ( item.collateral_json ) {
		collateral = item.collateral_json.map((iitem): _AssetItem => {
			return {
				asset: {
					assetType: decodeAssetTypeFromIndex(`${iitem.assetType}`),
					contractAddress: iitem.contractAddress,
				},
				tokenId: `${iitem.tokenId}`,
				amount: `${iitem.amount}`,
			}
		})
	}

	let tokenUrl;
	let tokenUrlRaw;
	let tokenUrlRawJSON = '';

	if ( item.token_uri ) {
		try {
			tokenUrlRaw = item.token_uri;
			tokenUrl = await fetch(processSwarmUrl(item.token_uri));
			tokenUrlRawJSON = await tokenUrl.text();
		} catch(e: any) {
			console.log('Cannot fetch tokenURL', e)
		}
	} else {
		requestTokenURIUpdate({ chainId: chainId, contractAddress: item.contract_address, tokenId: item.token_id });
	}

	const amount      = item.balance ? new BigNumber(item.balance) : undefined;
	const totalSupply = item.totalSupply ? new BigNumber(item.totalSupply) : undefined;

	return decodeWrappedToken({
		inWNFT: {
			inAsset,
			collateral,
			unWrapDestinition: item.unwrap_destination,
			unWrapDestination: item.unwrap_destination,
			fees: item.fees,
			locks: item.locks,
			royalties: item.royalties,
			rules: item.rules,
		},
		owner: item.owner,
		chainId: chainId,
		contractAddress: item.contract_address,
		tokenId: item.token_id,
		assetType: item.asset_type,
		amount,
		totalSupply,
		tokenUrl: item.token_uri,
		tokenUrlRaw: tokenUrlRaw || '',
		tokenUrlRawJSON: tokenUrlRawJSON,
		sortParams: {
			blockNumber: item.blocknumber,
			logIndex   : item.logindex
		},
		create_tx: item.create_tx,
	})
}
const parseAPITokens = async (tokens: Array<APIWrappedToken>, chainId: number): Promise<Array<WrappedTokenType>> => {
	return await Promise.all(tokens.map((item: APIWrappedToken): Promise<WrappedTokenType> => { return parseAPIToken(item, chainId) }));
}

const createAuthToken = async () => {

	async function sha256(message: string) {
		const msgBuffer = new TextEncoder().encode(message);
		const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
		return hashHex;
	}
	async function signTimed(name: string, key: string) {
		const now = new Date().getTime();
		const timeBlock = parseInt(`${now / (parseInt(key_active || '0') * 1000)}`);

		return sha256(name+key+timeBlock)
	}

	const app_name   = window.location.host;
	const app_id     = process.env.REACT_APP_ORACLE_APP_ID;
	const app_key    = process.env.REACT_APP_ORACLE_APP_KEY;
	const key_active = process.env.REACT_APP_ORACLE_KEY_ACTIVE_TIME;

	if ( !app_id || !app_key || !key_active ) {
		console.log('No app_id or app_key of key_active in .env');
		return '';
	}
	const tempKey = await signTimed(app_name, app_key);

	return `${app_id}.${tempKey}`
}

export const fetchUserTokens = async (chainId: number, assetType: _AssetType, userAddress: string, page: number):Promise<Array<DiscoveredToken>> => {

	const tokensOnPage = 12

	const authToken = await createAuthToken();
	if ( authToken === '' ) {
		console.log('Cannot create token');
		return [];
	}

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return []; }

	let url = '';
	if ( assetType === _AssetType.ERC721 ) {
		url = urljoin(BASE_URL, `/discover/721/user/${chainId}/${userAddress}?page=${page}&size=${tokensOnPage}`);
	}
	if ( assetType === _AssetType.ERC1155 ) {
		url = urljoin(BASE_URL, `/discover/1155/user/${chainId}/${userAddress}?page=${page}&size=${tokensOnPage}`);
	}

	try {
		const resp = await fetch(url, {
			headers: {
				'Authorization': authToken,
			}
		});

		const respParsed: Array<DiscoveredToken> = await resp.json();
		if ( !resp.ok ) {
			console.log('Cannot fetch token from oracle', respParsed);
			return [];
		}
		if ('error' in respParsed) {
			return [];
		}

		return respParsed;
	} catch (e) {
		console.log('Cannot fetch token from oracle', e);
		return [];
	}
}
export const fetchUserTokensOfContract = async (chainId: number, assetType: _AssetType, userAddress: string, contractAddress: string, page: number):Promise<Array<DiscoveredToken>> => {

	const tokensOnPage = 12

	const authToken = await createAuthToken();
	if ( authToken === '' ) {
		console.log('Cannot create token');
		return [];
	}

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return []; }

	let url = '';
	if ( assetType === _AssetType.ERC721 ) {
		url = urljoin(BASE_URL, `/discover/721/user/${chainId}/${userAddress}/${contractAddress}?page=${page}&size=${tokensOnPage}`);
	}
	if ( assetType === _AssetType.ERC1155 ) {
		url = urljoin(BASE_URL, `/discover/1155/user/${chainId}/${userAddress}/${contractAddress}?page=${page}&size=${tokensOnPage}`);
	}

	try {
		const resp = await fetch(url, {
			headers: {
				'Authorization': authToken,
			}
		});

		const respParsed: Array<DiscoveredToken> = await resp.json();
		if ( !resp.ok ) {
			console.log('Cannot fetch token from oracle', respParsed);
			return [];
		}
		if ('error' in respParsed) {
			return [];
		}

		return respParsed;
	} catch (e) {
		console.log('Cannot fetch token from oracle', e);
		return [];
	}
}

export const fetchUserWrappedTokens = async (chainId: number, assetType: _AssetType, userAddress: string, page: number):Promise<Array<WrappedTokenType>> => {

	const tokensOnPage = 12

	const authToken = await createAuthToken();
	if ( authToken === '' ) {
		console.log('Cannot create token');
		return [];
	}

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return []; }

	let url = '';
	if ( assetType === _AssetType.ERC721 ) {
		url = urljoin(BASE_URL, `/wnft_collateral/721/user/${chainId}/${userAddress}?page=${page}&size=${tokensOnPage}`);
	}
	if ( assetType === _AssetType.ERC1155 ) {
		url = urljoin(BASE_URL, `/wnft_collateral/1155/user/${chainId}/${userAddress}?page=${page}&size=${tokensOnPage}`);
	}

	try {
		const resp = await fetch(url, {
			headers: {
				'Authorization': authToken,
			}
		});

		const respParsed: Array<APIWrappedToken> = await resp.json();
		if ( !resp.ok ) {
			console.log('Cannot fetch token from oracle', respParsed);
			return [];
		}
		if ('error' in respParsed) {
			return [];
		}


		return await parseAPITokens(respParsed, chainId);
	} catch (e) {
		console.log('Cannot fetch token from oracle', e);
		return [];
	}
}

export const fetchWrappedTokenById = async (params: {
	chainId: number,
	contractAddress: string,
	tokenId: string,
	userAddress?: string,
	assetType?: _AssetType
}):Promise<WrappedTokenType | undefined> => {

	const authToken = await createAuthToken();
	if ( authToken === '' ) {
		console.log('Cannot create token');
		return undefined;
	}

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return undefined; }

	const url721 = urljoin(BASE_URL, `/wnft_collateral/721/${params.chainId}/${params.contractAddress}/${params.tokenId}`);
	const url1155 = urljoin(BASE_URL, `/wnft_collateral/1155/${params.chainId}/${params.contractAddress}/${params.tokenId}`);

	let respParsed;

	let url = '';
	if ( params.assetType ) {
		if ( params.assetType === _AssetType.ERC721 ) {
			url = url721;
		}
		if ( params.assetType === _AssetType.ERC1155 ) {
			url = url1155;
		}

		try {
			const resp = await fetch(url, {
				headers: {
					'Authorization': authToken,
				}
			});
			respParsed = await resp.json();
		} catch (e) {
			console.log('Cannot fetch token from oracle', e);
			return undefined;
		}
	} else {

		try {
			const resp = await fetch(url721, {
				headers: {
					'Authorization': authToken,
				}
			});
			if ( resp && resp.ok ) {
				respParsed = await resp.json();
			}
		} catch (e) {
			console.log('Cannot fetch token from oracle', e);
			return undefined;
		}

		if ( !respParsed || !respParsed.length || 'error' in respParsed ) {
			try {
				const resp = await fetch(url1155, {
					headers: {
						'Authorization': authToken,
					}
				});
				if ( resp && resp.ok ) {
					respParsed = await resp.json();
				}
			} catch (e) {
				console.log('Cannot fetch token from oracle', e);
				return undefined;
			}
		}
	}

	if ( !respParsed || !respParsed.length || 'error' in respParsed ) {
		console.log('Cannot find token niether 721 nor 1155 from oracle');
		return undefined;
	}


	const foundUserToken = respParsed.find((item: APIWrappedToken) => {
		if ( !params.userAddress ) { return false; }
		return item.owner.toLowerCase() === params.userAddress.toLowerCase()
	});

	if ( foundUserToken ) {
		return await parseAPIToken(foundUserToken, params.chainId);
	}

	return await parseAPIToken(respParsed[0], params.chainId);

}

export const fetchOriginalTokenById = async (params: {
	chainId: number,
	contractAddress: string,
	tokenId: string,
	userAddress?: string,
	assetType?: _AssetType
}):Promise<DiscoveredToken | undefined> => {

	const authToken = await createAuthToken();
	if ( authToken === '' ) {
		console.log('Cannot create token');
		return undefined;
	}

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return undefined; }

	const url721 = urljoin(BASE_URL, `/discover/721/${params.chainId}/${params.contractAddress}/${params.tokenId}`);
	const url1155 = urljoin(BASE_URL, `/discover/1155/${params.chainId}/${params.contractAddress}/${params.tokenId}`);

	let respParsed: Array<DiscoveredToken> | undefined;

	let url = '';
	if ( params.assetType ) {
		if ( params.assetType === _AssetType.ERC721 ) {
			url = url721;
		}
		if ( params.assetType === _AssetType.ERC1155 ) {
			url = url1155;
		}

		try {
			const resp = await fetch(url, {
				headers: {
					'Authorization': authToken,
				}
			});
			respParsed = await resp.json();
		} catch (e) {
			console.log('Cannot fetch token from oracle', e);
			return undefined;
		}
	} else {

		try {
			const resp = await fetch(url721, {
				headers: {
					'Authorization': authToken,
				}
			});
			if ( resp && resp.ok ) {
				respParsed = await resp.json();
			}
		} catch (e) {
			console.log('Cannot fetch token from oracle', e);
			return undefined;
		}

		if ( !respParsed || !respParsed.length || 'error' in respParsed ) {
			try {
				const resp = await fetch(url1155, {
					headers: {
						'Authorization': authToken,
					}
				});
				if ( resp && resp.ok ) {
					respParsed = await resp.json();
				}
			} catch (e) {
				console.log('Cannot fetch token from oracle', e);
				return undefined;
			}
		}

		if ( !respParsed || !respParsed.length || 'error' in respParsed ) {
			console.log('Cannot find token niether 721 nor 1155 from oracle');
			return undefined;
		}
	}

	if ( !respParsed || 'error' in respParsed ) { return undefined; }

	const foundUserToken = respParsed.find((item: DiscoveredToken) => {
		if ( !params.userAddress ) { return false; }
		return item.owner.toLowerCase() === params.userAddress.toLowerCase()
	});

	if ( foundUserToken ) {
		return foundUserToken;
	}

	return respParsed[0];
}

export const fetchCrossings = async (params: {
	userAddress: string,
	page?: number,
}):Promise<Array<APICrossingItem> | undefined> => {

	const authToken = await createAuthToken();
	if ( authToken === '' ) {
		console.log('Cannot create token');
		return undefined;
	}

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return undefined; }

	const tokensOnPage = 12
	const page = params.page || 1;
	const url  = urljoin(BASE_URL, `/crossings/user/${params.userAddress}?page=${page}&size=${tokensOnPage}`);

	let respParsed: Array<APICrossingItem>;

	try {
		const resp = await fetch(url, {
			headers: {
				'Authorization': authToken,
			},
		});
		respParsed = await resp.json();
	} catch (e) {
		console.log('Cannot fetch token from oracle', e);
		return undefined;
	}

	if ( !respParsed || 'error' in respParsed ) { return undefined; }

	return respParsed;
}
export const fetchBurns = async (params: {
	userAddress: string,
	page?: number,
}):Promise<Array<APICrossingItem> | undefined> => {

	const authToken = await createAuthToken();
	if ( authToken === '' ) {
		console.log('Cannot create token');
		return undefined;
	}

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return undefined; }

	const tokensOnPage = 12
	const page = params.page || 1;
	const url  = urljoin(BASE_URL, `/burns/user/${params.userAddress}?page=${page}&size=${tokensOnPage}`);

	let respParsed: Array<APICrossingItem>;

	try {
		const resp = await fetch(url, {
			headers: {
				'Authorization': authToken,
			},
		});
		respParsed = await resp.json();
	} catch (e) {
		console.log('Cannot fetch token from oracle', e);
		return undefined;
	}

	if ( !respParsed || 'error' in respParsed ) { return undefined; }

	return respParsed;
}

export const getOracleCrossingFreezeSign = async (params: {
	chainId: number,
	txHash: string
}):Promise<APIOracleSign | undefined> => {

	const authToken = await createAuthToken();
	if ( authToken === '' ) {
		console.log('Cannot create token');
		return undefined;
	}

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return undefined; }

	const url  = urljoin(BASE_URL, `/web3/get_proof_of_freez/${params.chainId}/${params.txHash}`);

	let respParsed: APIOracleSign;

	try {
		const resp = await fetch(url, {
			headers: {
				'Authorization': authToken,
			},
		});
		respParsed = await resp.json();
	} catch (e) {
		console.log('Cannot fetch oracle signature', e);
		return undefined;
	}

	if ( !respParsed || 'error' in respParsed ) { return undefined; }

	return respParsed;
}

export const getOracleCrossingBurnSign = async (params: {
	chainId: number,
	txHash: string
}):Promise<APIOracleSign | undefined> => {

	const authToken = await createAuthToken();
	if ( authToken === '' ) {
		console.log('Cannot create token');
		return undefined;
	}

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return undefined; }

	const url  = urljoin(BASE_URL, `/web3/get_proof_of_burn/${params.chainId}/${params.txHash}`);

	let respParsed: APIOracleSign;

	try {
		const resp = await fetch(url, {
			headers: {
				'Authorization': authToken,
			},
		});
		respParsed = await resp.json();
	} catch (e) {
		console.log('Cannot fetch oracle signature', e);
		return undefined;
	}

	if ( !respParsed || 'error' in respParsed ) { return undefined; }

	return respParsed;
}

export const getOracleNftMinterSign = async (params: {
	address: string,
	chain_id: number,
	token_id: number,
	token_uri: string,
	batch: number,
	amount: number,
	standart: number
}):Promise<APIOracleSign | undefined> => {

	// const authToken = await createAuthToken();
	// if ( authToken === '' ) {
	// 	console.log('Cannot create token');
	// 	return undefined;
	// }

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return undefined; }

	const url  = urljoin(BASE_URL, '/web3/mintsign');

	let respParsed: APIOracleSign;

	try {
	    const requestOptions = {
	        method: 'POST',
	        headers: {
	        	// 'Authorization': authToken,
	        	'Content-Type': 'application/json'
	        },
	        body: JSON.stringify({
	        	address: params.address,
	        	chainId: params.chain_id,
	        	tokenId: params.token_id.toString(),
	        	tokenURI: params.token_uri,
	        	batch: params.batch,
	        	amount: params.amount,
	        	standart: params.standart
	        })
	    };
		const resp = await fetch(url, requestOptions);
		respParsed = await resp.json();
	} catch (e) {
		console.log('Cannot fetch oracle signature', e);
		return undefined;
	}

	if ( !respParsed || 'error' in respParsed ) { return undefined; }

	return respParsed;
}

export const fetchSwarmStamp = async (params: {
	name: string,
	desc: string,
	image: string | ArrayBuffer,
	mime: string,
	props: Array<{
		type: string,
		name: string
	}>
}):Promise<Array<SwarmStampBatchId> | undefined> => {

	// const authToken = await createAuthToken();
	// if ( authToken === '' ) {
	// 	console.log('Cannot create token');
	// 	return undefined;
	// }

	let BASE_URL = process.env.REACT_APP_ORACLE_API_MINT_URL;
	let url = BASE_URL || '';
	if ( !BASE_URL ) {
		BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
		if ( !BASE_URL ) { console.log('No oracle mint url in .env'); return undefined; }
		url = urljoin(BASE_URL, `mint/`);
	}
	url  = urljoin(url, `new/`);
	console.log('url', url);

	let respParsed: Array<SwarmStampBatchId>;

	try {

		// set timeout for fetch request
		const controller = new AbortController();
		setTimeout(() => { controller.abort(); console.log("Fetch request 10s timeout occurred"); }, 10000);

		const requestOptions = {
	        method: 'POST',
	        signal: controller.signal,
	        headers: {
	        	// 'Authorization': authToken,
	        	'Content-Type': 'application/json'
	        },
	        body: JSON.stringify({
	        	name: params.name,
	        	desc: params.desc,
	        	image: params.image,
	        	mime: params.mime,
	        	props: params.props
	        })
	    };
		const resp = await fetch(url, requestOptions);
		respParsed = await resp.json();
	} catch (e) {
		console.log('Cannot post newly minted NFT data', e);
		return undefined;
	}

	if ( !respParsed || 'error' in respParsed ) { return undefined; }

	return respParsed;
}


export const fetchRoyalty = async (params: {
	chainId: number,
	userAddress: string,
	page?: number,
}):Promise<Array<APIRoyaltyItem> | undefined> => {

	const authToken = await createAuthToken();
	if ( authToken === '' ) {
		console.log('Cannot create token');
		return undefined;
	}

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return undefined; }

	const tokensOnPage = 12
	const page = params.page || 1;
	const url  = urljoin(BASE_URL, `/royalty/user/${params.chainId}/${params.userAddress}?page=${page}&size=${tokensOnPage}`);

	let respParsed: Array<APIRoyaltyItem>;

	try {
		const resp = await fetch(url, {
			headers: {
				'Authorization': authToken,
			},
		});
		respParsed = await resp.json();
	} catch (e) {
		console.log('Cannot fetch token from oracle', e);
		return undefined;
	}

	if ( !respParsed || 'error' in respParsed ) { return undefined; }

	return respParsed;
}

export const requestTokenURIUpdate = async (params: {
	chainId: number,
	contractAddress: string,
	tokenId: string,
}):Promise<Array<SwarmStampBatchId> | undefined> => {

	console.log('TokenURI update requested for', params);

	const authToken = await createAuthToken();
	if ( authToken === '' ) {
		console.log('Cannot create token');
		return;
	}

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return undefined; }

	try {
		fetch(urljoin(BASE_URL, `/update_token_uri/${params.chainId}/${params.contractAddress}/${params.tokenId}`), {
			headers: { 'Authorization': authToken, }
		})
	} catch (e: any) {
		console.log('Cannot send update tokenURI request', e);
	}
}
