
import React               from 'react';
import Tippy               from '@tippyjs/react';

import {
	withRouter,
	match,
	Link,
} from 'react-router-dom';
import {
	assetTypeToString,
	ERC20ContractParamsType,
	LockType,
	MetamaskAdapter,
	WrappedTokensStatType,
	WrappedTokenType,
	_AssetType
} from '../../models/BlockchainAdapter';
import {
	setError,
	unsetLoading,
	clearError,
	setLoading,
	setAuthMethod,
} from '../../reducers';

import default_icon     from '../../static/pics/coins/_default.svg';
import icon_loading     from '../../static/pics/loading.svg';

import TokenViewer     from '../CoinViewer';
import TokenIconViewer from '../CoinIconViewer';
import TokenInList, {
	TokenRenderType
} from '../TokenInList';

import {
	History,
	Location
} from 'history';
import {
	withTranslation
} from "react-i18next";

import { compactString, getABI, localStorageGet, tokenToFloat } from '../../models/_utils';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type TokenListProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function,
	t                     : any,
	match                 : match;
	location              : Location,
	history               : History,
}
type TokenListState = {
	input_nft_address       : string,
	input_token_id          : string,
	wrapEmptiness           : boolean,
	mintChecked             : boolean,
	tokenFetchRequested     : boolean,
	tokenMintRequested      : boolean,

	decimalsNative          : number,
	symbolNative            : string,
	iconNative              : string,
	minterContract          : string,

	techToken               : ERC20ContractParamsType,

	wrappedTokens           : Array<WrappedTokenType>,
	wrappedTokensFiltered   : Array<WrappedTokenType>,
	discoveredTokens        : Array<WrappedTokenType>,
	discoveredTokensFiltered: Array<WrappedTokenType>,
	waitingTokens           : Array<{ token: WrappedTokenType, msg: string }>,
	wrappedTokensStat       : WrappedTokensStatType,
	chainId                 : number,
	metamaskLogged          : boolean,
	explorerBaseUrl         : string,

	currentPage             : string,
	wrappedPages            : number,
	discoveredPages         : number,
	wrappedCurrentPage      : number,
	discoveredCurrentPage   : number,

	erc20CollateralTokens   : Array<ERC20ContractParamsType>,
	erc20OtherTechTokens    : Array<ERC20ContractParamsType>,

	filterByString          : string,
	filterByToken           : Array<ERC20ContractParamsType>,
	filterByParams          : {
		timeLock   : boolean,
		fee        : boolean,
		unwrapReady: boolean,
	}

	loadingInProgress       : boolean,

	statCollateralOpened    : boolean,
	statRoyaltyOpened       : boolean,
	filterCoinsOpened       : boolean,
	filterSortOpened        : boolean,

	sortBy                  : string,
}

class TokenList extends React.Component<TokenListProps, TokenListState> {

	store                 : any;
	unsubscribe!          : Function;
	metamaskAdapter       : MetamaskAdapter;
	showAuthMethodSelector: Function;
	t                     : any;
	copiedHintTimer       : number;
	copiedHintTimeout     : number;
	scrollToBlock         : React.RefObject<HTMLInputElement>;
	statCollateralBlockRef: React.RefObject<HTMLInputElement>;
	statRoyaltyBlockRef   : React.RefObject<HTMLInputElement>;
	filterCoinsRef        : React.RefObject<HTMLInputElement>;
	filterSortRef         : React.RefObject<HTMLInputElement>;
	tokensOnPage          : number;
	mintSubscribtion      : any | undefined;

	constructor(props: TokenListProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.t                      = props.t;
		this.copiedHintTimer        = 0;
		this.tokensOnPage           = 12;
		this.scrollToBlock          = React.createRef();
		this.statCollateralBlockRef = React.createRef();
		this.statRoyaltyBlockRef    = React.createRef();
		this.filterCoinsRef         = React.createRef();
		this.filterSortRef          = React.createRef();

		this.copiedHintTimeout      = 2; // s

		this.mintSubscribtion       = undefined;

		const waitingTokens: Array<{ token: WrappedTokenType, msg: string }> = this.store.getState().waitingTokens;
		const ignoredTokens: Array<{ contractAddress: string, tokenId: string }> = this.store.getState().ignoredTokens;
		const wrappedTokens: Array<WrappedTokenType> = this.store.getState().wrappedTokens
			.filter((item: WrappedTokenType) => {
				// const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => { return iitem.token.tokenUrl === item.tokenUrl });
				const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => {
					return iitem.token.contractAddress === item.contractAddress && iitem.token.tokenId === item.tokenId
				});
				return !foundWaiting.length;
			});
		const discoveredTokens: Array<WrappedTokenType> = this.store.getState().discoveredTokens
			.filter((item: WrappedTokenType) => {
				const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => {
					return iitem.token.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.token.tokenId}` === `${item.tokenId}`
				});
				return !foundWaiting.length;
			})
			.filter((item: WrappedTokenType) => {
				const foundWrapped = wrappedTokens.filter((iitem: WrappedTokenType) => {
					if ( iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}` ) { return true }
					// if ( item.assetType === _AssetType.ERC1155 ) {
					// 	if ( iitem.amount && iitem.amount.lte(0) ) { return false }
					// 	return true;
					// }
					if ( item.assetType === _AssetType.ERC721 ) {
						if ( iitem.originalTokenInfo && iitem.originalTokenInfo.contractAddress && iitem.originalTokenInfo.tokenId && iitem.originalTokenInfo.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.originalTokenInfo.tokenId}` === `${item.tokenId}` )
						return false;
					}
					return false;
				});
				return !foundWrapped.length;
				// const foundWrapped = wrappedTokens.filter((iitem: WrappedTokenType) => {
				// 	return ( iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}` ) ||
				// 	( iitem.originalTokenInfo && iitem.originalTokenInfo.contractAddress && iitem.originalTokenInfo.tokenId && iitem.originalTokenInfo.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.originalTokenInfo.tokenId}` === `${item.tokenId}` )
				// });
				// return !foundWrapped.length;
			})
			.filter((item: WrappedTokenType) => {
				const foundIgnored = ignoredTokens.filter((iitem: { contractAddress: string, tokenId: string }) => {
					return iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}`
				});
				return !foundIgnored.length;
			});

		this.state = {
			input_nft_address       : '',
			input_token_id          : '',
			mintChecked             : false,
			wrapEmptiness           : false,
			tokenFetchRequested     : false,
			tokenMintRequested      : false,

			decimalsNative          : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative            : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative              : this.store.getState().metamaskAdapter.networkTokenIcon,
			minterContract          : this.store.getState().metamaskAdapter.minterContract,

			techToken               : this.store.getState().erc20TechTokenParams,

			wrappedTokens           : wrappedTokens,
			wrappedTokensFiltered   : wrappedTokens,
			waitingTokens           : waitingTokens,
			discoveredTokens        : discoveredTokens,
			discoveredTokensFiltered: discoveredTokens,
			wrappedTokensStat       : this.store.getState().wrappedTokensStat,
			chainId                 : this.store.getState().metamaskAdapter.chainId,
			metamaskLogged          : this.store.getState().metamaskAdapter.logged,
			explorerBaseUrl         : this.store.getState().metamaskAdapter.explorerBaseUrl,

			erc20CollateralTokens   : this.store.getState().erc20CollateralTokens,
			erc20OtherTechTokens    : this.store.getState().erc20OtherTechTokens,
			filterByString          : '',
			filterByToken           : [],
			filterByParams          : {
				timeLock   : false,
				fee        : false,
				unwrapReady: false,
			},

			currentPage          : 'wrapped',
			wrappedPages         : Math.ceil( wrappedTokens.length / this.tokensOnPage ),
			discoveredPages      : Math.ceil( discoveredTokens.length / this.tokensOnPage ),
			wrappedCurrentPage   : 0,
			discoveredCurrentPage: 0,

			loadingInProgress    : this.store.getState().tokenLoadingInProgress,

			statCollateralOpened  : false,
			statRoyaltyOpened     : false,
			filterCoinsOpened     : false,
			filterSortOpened      : false,

			sortBy                : '',
		}
	}

	componentDidMount() {

		// this.scrollToBlock.current?.scrollIntoView();

		const prevAuthMethod = localStorageGet('authMethod').toLowerCase();
		if ( prevAuthMethod ) {
			this.store.dispatch(setAuthMethod( prevAuthMethod ));
			this.metamaskAdapter.connect();
		} else {
			this.props.showAuthMethodSelector();
		}

		this.unsubscribe = this.store.subscribe(() => {

			if ( this.state.tokenFetchRequested && this.metamaskAdapter.wrapperContract ) {
				this.setState({ tokenFetchRequested: false });
				this.submitFetchToken();
			}
			if ( this.state.tokenMintRequested && this.metamaskAdapter.wrapperContract ) {
				this.setState({ tokenMintRequested: false });
				this.submitMintToken();
			}

			const waitingTokens = this.store.getState().waitingTokens;
			const ignoredTokens: Array<{ contractAddress: string, tokenId: string }> = this.store.getState().ignoredTokens;
			const incompleteTokens = this.store.getState().incompleteTokens
				.filter((item: WrappedTokenType) => {
					// const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => { return iitem.token.tokenUrl === item.tokenUrl });
					const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => {
						return iitem.token.contractAddress === item.contractAddress && iitem.token.tokenId === item.tokenId
					});
					return !foundWaiting.length;
				});
			const wrappedTokens: Array<WrappedTokenType> = this.store.getState().wrappedTokens
				.filter((item: WrappedTokenType) => {
					// const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => { return iitem.token.tokenUrl === item.tokenUrl });
					const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => {
						return iitem.token.contractAddress === item.contractAddress && iitem.token.tokenId === item.tokenId
					});
					return !foundWaiting.length;
				});
			const discoveredTokens: Array<WrappedTokenType> = this.store.getState().discoveredTokens
				.filter((item: WrappedTokenType) => {
					const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => {
						return iitem.token.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.token.tokenId}` === `${item.tokenId}`
					});
					return !foundWaiting.length;
				})
				.filter((item: WrappedTokenType) => {
					const foundWrapped = wrappedTokens.filter((iitem: WrappedTokenType) => {
						if ( iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}` ) { return true }
						// if ( item.assetType === _AssetType.ERC1155 ) {
						// 	if ( iitem.amount && iitem.amount.lte(0) ) { return false }
						// 	return true;
						// }
						if ( item.assetType === _AssetType.ERC721 ) {
							if ( iitem.originalTokenInfo && iitem.originalTokenInfo.contractAddress && iitem.originalTokenInfo.tokenId && iitem.originalTokenInfo.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.originalTokenInfo.tokenId}` === `${item.tokenId}` )
							return false;
						}
						return false;
					});
					return !foundWrapped.length;
					// const foundWrapped = wrappedTokens.filter((iitem: WrappedTokenType) => {
					// 	return ( iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}` ) ||
					// 	( iitem.originalTokenInfo && iitem.originalTokenInfo.contractAddress && iitem.originalTokenInfo.tokenId && iitem.originalTokenInfo.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.originalTokenInfo.tokenId}` === `${item.tokenId}` )
					// });
					// return !foundWrapped.length;
				})
				.filter((item: WrappedTokenType) => {
					const foundIncomplete = incompleteTokens.filter((iitem: WrappedTokenType) => {
						return iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}`
					});
					return !foundIncomplete.length;
				})
				.filter((item: WrappedTokenType) => {
					const foundIgnored = ignoredTokens.filter((iitem: { contractAddress: string, tokenId: string }) => {
						return iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}`
					});
					return !foundIgnored.length;
				});

			const wrappedTokensFiltered    = this.filterTokens(wrappedTokens, this.state.filterByString, this.state.filterByToken, this.state.filterByParams);
			const discoveredTokensFiltered = this.filterTokens(discoveredTokens, this.state.filterByString, this.state.filterByToken, this.state.filterByParams);

			this.setState({
				decimalsNative          : this.store.getState().metamaskAdapter.networkTokenDecimals,
				iconNative              : this.store.getState().metamaskAdapter.networkTokenIcon,
				symbolNative            : this.store.getState().metamaskAdapter.networkTokenTicket,
				minterContract          : this.store.getState().metamaskAdapter.minterContract,
				techToken               : this.store.getState().erc20TechTokenParams,
				wrappedTokens           : wrappedTokens,
				wrappedTokensFiltered   : wrappedTokensFiltered,
				waitingTokens           : waitingTokens,
				discoveredTokens        : discoveredTokens,
				discoveredTokensFiltered: discoveredTokensFiltered,
				wrappedTokensStat       : this.store.getState().wrappedTokensStat,
				chainId                 : this.store.getState().metamaskAdapter.chainId,
				metamaskLogged          : this.store.getState().metamaskAdapter.logged,
				explorerBaseUrl         : this.store.getState().metamaskAdapter.explorerBaseUrl,
				erc20CollateralTokens   : this.store.getState().erc20CollateralTokens,
				erc20OtherTechTokens    : this.store.getState().erc20OtherTechTokens,

				wrappedPages            : Math.ceil( wrappedTokensFiltered.length / this.tokensOnPage ),
				discoveredPages         : Math.ceil( discoveredTokensFiltered.length / this.tokensOnPage ),

				loadingInProgress       : this.store.getState().tokenLoadingInProgress,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	// ----- WRAP BLOCK -----
	async submitFetchToken() {

		let is721 = true;

		if ( !is721 ) {
			this.store.dispatch(setError({
				text: this.t('Address is not ERC721-compatible'),
				buttons: [{
					text: this.t('Try anyway'),
					clickFunc: () => {
						this.store.dispatch(clearError());
						this.props.history.push(`/token/${this.state.chainId}/${this.state.input_nft_address}/${this.state.input_token_id}`);
					}
				},
				{
					text: this.t('Change address'),
					clickFunc: () => {
						this.store.dispatch(clearError());
						this.store.dispatch(unsetLoading());
					}
				}],
				links: undefined
			}));
			return;
		}

		if (
			this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() === this.state.input_nft_address.toLowerCase()
		) {
			this.store.dispatch(setError({
				text: this.t('You cannot wrap wrapped token'),
				buttons: [{
					text: this.t('Preview token'),
					clickFunc: () => {
						this.store.dispatch(clearError());
						this.props.history.push(`/token/${this.state.chainId}/${this.state.input_nft_address}/${this.state.input_token_id}`);
					}
				},
				{
					text: this.t('Change address'),
					clickFunc: () => {
						this.store.dispatch(clearError());
						this.store.dispatch(unsetLoading());
					}
				}],
				links: undefined
			}));
			return;
		}

		this.props.history.push(`/token/${this.state.chainId}/${this.state.input_nft_address}/${this.state.input_token_id}`);
	}
	tokenMinted(tokenId: string) {
		if ( this.mintSubscribtion ) { this.mintSubscribtion.unsubscribe(); }
		this.store.dispatch(unsetLoading());
		this.props.history.push(`/wrap/${this.state.chainId}/${this.state.minterContract}/${tokenId}`);
	}
	async submitMintToken() {
		if ( !this.state.minterContract ) { return; }

		this.store.dispatch(setLoading({ msg: this.t('Waiting for mint') }));
		// mintToken(
		// 	this.metamaskAdapter,
		// 	this.state.minterContract,
		// 	this.store.getState().account.address
		// )
		// 		.then((data) => {
		// 			this.store.dispatch(unsetLoading());
		// 			this.props.history.push(`/token/${this.state.chainId}/${this.state.minterContract}/${data.events.Transfer.returnValues.tokenId}`);
		// 		})
		// 		.catch((e) => {
		// 			console.log('Cannot mint after send: ', e);
		// 			let errorMsg = '';

		// 			if ('message' in e) {
		// 				try {
		// 					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
		// 					errorMsg = errorParsed.message
		// 						.replace('execution reverted: ', '');
		// 				} catch(ignored) {}
		// 			}

		// 			this.store.dispatch(setError({
		// 				text: `${this.t('Cannot mint token')}: ${errorMsg || e}`,
		// 				buttons: undefined,
		// 				links: undefined,
		// 			}));

		// 			this.store.dispatch(unsetLoading());
		// 			return;
		// 		})

		const userAddress = this.store.getState().account.address;
		const contractAddress = this.state.minterContract;
		let minterAbi;
		try {
			minterAbi = getABI(this.state.chainId, contractAddress, 'minter');
		} catch(e) {
			throw e;
		}

		const contract = new this.metamaskAdapter.web3.eth.Contract(minterAbi as any, contractAddress);

		const tx = contract.methods.mint( userAddress );

		if ( this.state.chainId !== 137 ) {
			this.mintSubscribtion = contract.events.Transfer(
				{
					fromBlock: 'earliest',
					filter: { from: '0x0000000000000000000000000000000000000000', to: userAddress }
				},
				(e: any, data: any) => {
					console.log('Mint event caught', data);
					this.tokenMinted(data.returnValues.tokenId);
				}
			);
		}

		// pre-send transaction check
		try {
			await tx.estimateGas({ from: userAddress })
		} catch(e: any) {
			throw new Error(e.message);
		}

		tx
			.send({ from: userAddress })
			.then((data: any) => {
				if ( this.state.chainId === 137 ) {
					setTimeout(() => {
						this.tokenMinted(data.events.Transfer.returnValues.tokenId);
					}, 30*1000)
				} else {
					this.tokenMinted(data.events.Transfer.returnValues.tokenId);
				}
			})
			.catch((e: any) => {
				this.store.dispatch(setError({
					text: `${this.t('Cannot mint token')}: ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));

				this.store.dispatch(unsetLoading());
				return;
			})
	}
	getSubmitBtn() {
		if ( this.state.mintChecked ) {
			// button with mint
			return (
				<button
					className="btn btn-grad"
					disabled={ false }
					onClick={async (e) => {
						e.preventDefault();
						this.store.dispatch(clearError());

						if ( !this.state.metamaskLogged ) {
							this.showAuthMethodSelector();
							this.setState({ tokenMintRequested: true });
						} else {
							this.submitMintToken();
						}
					}}
				>{ this.t('Mint and wrap now') }</button>
			)
		} else {
			if ( this.state.wrapEmptiness ) {
				return (
					<Link
						className="btn btn-grad"
						to="/wrap?empty=true"
					>{ this.t('Wrap now') }</Link>
				)
			}
			if (
				this.state.input_nft_address === '' &&
				this.state.input_token_id    === ''
			) {
				// redirect to empty wrap
				return (
					<Link
						className="btn btn-grad"
						to="/wrap"
					>{ this.t('Wrap now') }</Link>
				)
			}

			if (
				this.state.input_nft_address === '' ||
				this.state.input_token_id    === '' ||
				( this.metamaskAdapter && this.metamaskAdapter.web3 && !this.metamaskAdapter.web3.utils.isAddress(this.state.input_nft_address) ) ||
				( (!this.metamaskAdapter || !this.metamaskAdapter.web3) && (this.state.input_nft_address.length !== 42 || !this.state.input_nft_address.startsWith('0x')) )
			) {
				// block button because errors
				return (
					<button
						className="btn btn-grad"
						disabled={ true }
					>{ this.t('Wrap now') }</button>
				)
			}

			// redirect to token wrap
			return (
				<Link
					className="btn btn-grad"
					to={`/wrap/${this.state.chainId}/${this.state.input_nft_address}/${this.state.input_token_id}`}
				>{ this.t('Wrap now') }</Link>
			)

		}
	}
	getWrapBlock() {
		return (
			<div className="c-wrap">
				<div className="c-wrap__header">
					<div className="h3">{ this.t('Wrap NFT') }</div>
					<div className="c-wrap__info">
						<div>
						{ this.t('Non-fungible token wrap') } <span className="text-grad">{ this.t('absolutly') }</span> <span className="text-grad">{ this.t('free') }</span> { this.t('for author (only gas)') }
						</div>
						{/* <img src={ icon_i_attention } alt="" /> */}
						<Tippy
							content={ this.t('Gas is the fee required to successfully conduct a transaction or execute a contract on blockchain platform') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 512 }
						>
							<span className="i-tip"></span>
						</Tippy>
					</div>
				</div>
				<div className="c-wrap__form">
					<div className="row">
						<div className="col col-12 col-md-5 col-lg-6">
							<label className="input-label">{ this.t('NFT Address') }</label>
							<input
								className="input-control"
								type="text"
								placeholder={ this.t('Paste here') }
								disabled={ this.state.mintChecked || this.state.wrapEmptiness }
								value={ this.state.input_nft_address }
								onChange={(e) => {
									this.store.dispatch(clearError());

									this.setState({
										input_nft_address: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, ""),
									})
								}}
							/>
						</div>
						<div className="col col-12 col-md-4 col-lg-3">
							<label className="input-label">{ this.t('Token ID') }</label>
							<input
								className="input-control"
								type="text"
								placeholder="99 999"
								disabled={ this.state.mintChecked || this.state.wrapEmptiness }
								value={ this.state.input_token_id }
								onChange={(e) => {
									this.store.dispatch(clearError());

									this.setState({
										input_token_id: e.target.value.toLowerCase().replace(/[^0-9]/g, ""),
									})
								}}
							/>
						</div>
						<div className="col col-12 col-md-3 col-lg-3">
							<label className="input-label">&nbsp;</label>
							{ this.getSubmitBtn() }
						</div>

						<div className="col col-12">
							<div className="alert mt-md-2 mb-2 mb-md-2 text-muted">Disclaimer: be careful with your NFTs! If you do not use the wrapping parameters correctly, you may lose access to your non-fungible tokens forever. So check your data before sending a transaction</div>
						</div>

						<div className="col col-12 mb-1">
							<div className="row">
								<div className="col-auto mt-1 mb-2">
									<small>{ this.t('If you have not your NFT, you can') }</small>
								</div>
								{
									this.state.minterContract ?
									(
										<div className="col-auto my-1">
											<label className="checkbox sm">
												<input
													type="checkbox"
													checked={ this.state.mintChecked }
													disabled={ this.state.wrapEmptiness }
													onChange={(e) => {
														this.setState({ mintChecked: e.target.checked });
													}}
												/>
												<span className="check"></span>
												<span className="check-text">{ this.t('Mint original NFT') }</span>
											</label>
										</div>
									) : null
								}
								<div className="col-auto my-1">
									<label className="checkbox sm">
										<input
											type="checkbox"
											checked={ this.state.wrapEmptiness }
											disabled={ this.state.mintChecked }
											onChange={(e) => {
												this.setState({ wrapEmptiness: e.target.checked });
											}}
										/>
										<span className="check"></span>
										<span className="check-text">{ this.t('Wrap emptiness') }</span>
										<Tippy
											content={ this.t('Mint wNFT without original NFT') }
											appendTo={ document.getElementsByClassName("wrapper")[0] }
											trigger='mouseenter'
											interactive={ false }
											arrow={ false }
											maxWidth={ 512 }
										>
											<span className="i-tip"></span>
										</Tippy>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	// ----- END WRAP BLOCK -----

	// ----- STAT BLOCK -----
	getStatWrappedBlock() {
		return (
			<div className="col col-12 col-md-6 col-lg-4">
				<div className="field-wrap">
					<div className="field-row">
						<label className="field-label">
							{ this.t('Wrapped') }
							<Tippy
								content={ this.t('Amount of owned wrapped NFTs') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 260 }
							>
								<span className="i-tip black"></span>
							</Tippy>
						</label>
					</div>
					<div className="field-row">
						<input
							className="field-control"
							type="text"
							value={ this.state.wrappedTokensStat.count }
							readOnly={ true }
						/>
						<span className="field-unit">{ this.t('NFT') }</span>
					</div>
				</div>
			</div>
		)
	}
	closeStatCollateralMenu = () => {
		setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = null;
			this.setState({ statCollateralOpened: false });
		}, 100);
	}
	openStatCollateralMenu = () => {
		setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = (e: any) => {
				if ( !this.statCollateralBlockRef.current ) { return; }
				if ( e.path && e.path.includes(this.statCollateralBlockRef.current) ) { return; }
				this.closeStatCollateralMenu();
			};
		}, 100);
		this.setState({ statCollateralOpened: true });
	}
	closeStatRoyaltyMenu = () => {
		setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = null;
			this.setState({ statRoyaltyOpened: false });
		}, 100);
	}
	openStatRoyaltyMenu = () => {
		setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = (e: any) => {
				if ( !this.statRoyaltyBlockRef.current ) { return; }
				if ( e.path && e.path.includes(this.statRoyaltyBlockRef.current) ) { return; }
				this.closeStatRoyaltyMenu();
			};
		}, 100);
		this.setState({ statRoyaltyOpened: true });
	}
	getStatCollateralBlock() {

		if (
			!this.state.wrappedTokensStat ||
			this.state.wrappedTokensStat.collaterals.length === 0
		) { return null; }

		return (
			<div className="db-section">
				<div className="container">
					<div className="c-wrap__table light">
						<div className="item item-header">
							<div className="row">
							<div className="mb-2 col-md-2 text-muted">Token</div>
							<div className="mb-2 col-md text-muted">Amount</div>
							<div className="mb-2 col-md-3 align-right"></div>
							</div>
						</div>
						{
							this.state.wrappedTokensStat.collaterals
							.sort((item, prev) => { return item.address.localeCompare(prev.address) })
							.map((item) => {

								if ( !item.amount ) { return null; }

								let foundToken: ERC20ContractParamsType | undefined;
								if ( item.address === '0x0000000000000000000000000000000000000000' ) {
									foundToken = {
										address: item.address,
										allowance: new BigNumber(0),
										balance: new BigNumber(0),
										decimals: this.state.decimalsNative,
										icon: this.state.iconNative,
										name: this.state.symbolNative,
										symbol: this.state.symbolNative,
									}
								}
								if ( this.state.techToken.address.toLowerCase() === item.address.toLowerCase() ) {
									foundToken = this.state.techToken;
								}
								if ( !foundToken ) {
									const _foundToken = this.state.erc20OtherTechTokens.find((iitem) => { return item.address.toLowerCase() === iitem.address.toLowerCase() });
									if ( _foundToken ) { foundToken = _foundToken; }
								}
								if ( !foundToken ) {
									const _foundToken = this.state.erc20CollateralTokens.find((iitem) => { return item.address.toLowerCase() === iitem.address.toLowerCase() });
									if ( _foundToken ) { foundToken = _foundToken; }
								}
								if ( !foundToken ) {
									foundToken = {
										address: item.address,
										allowance: new BigNumber(0),
										balance: new BigNumber(0),
										decimals: 18,
										icon: default_icon,
										name: compactString(item.address),
										symbol: compactString(item.address),
									};
								}
								return (
									<div className="item">
										<div className="row">
											<div className="col-12 col-sm-3 col-md-2 mb-2">
												<div className="tb-coin">
													<span className="i-coin">
														<img src={ foundToken.icon } alt="" />
													</span>
													<span className="name">{ foundToken.symbol }</span>
												</div>
											</div>
											<div className="col-12 col-sm mb-2"><span className="text-break">{ tokenToFloat(item.amount, foundToken.decimals).toString() }</span></div>
											{/* <div className="col-12 col-sm-3 mb-2 text-right"><span className="text-muted">~ 100 DAI</span></div> */}
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		)
	}
	getCollateralCount() {
		if (
			!this.state.wrappedTokensStat ||
			this.state.wrappedTokensStat.collaterals.length === 0
		) { return ''; }

		return ( <span>{ this.state.wrappedTokensStat.collaterals.length }</span> )
	}
	// ----- END STAT BLOCK -----

	getWrappedTokensCount() {
		if ( !this.state.wrappedTokens || !this.state.wrappedTokensFiltered ) { return '' }

		if ( this.state.wrappedTokens.length === this.state.wrappedTokensFiltered.length ) {
			return ( <span>{ this.state.wrappedTokensFiltered.length }</span> )
		}

		return ( <span>{ this.state.wrappedTokensFiltered.length } / { this.state.wrappedTokens.length }</span> )
	}
	getDiscoveredTokensCount() {
		if ( !this.state.discoveredTokens || !this.state.discoveredTokensFiltered ) { return '' }

		if ( this.state.discoveredTokens.length === this.state.discoveredTokensFiltered.length ) {
			return ( <span>{ this.state.discoveredTokensFiltered.length }</span> )
		}

		return ( <span>{ this.state.discoveredTokensFiltered.length } / { this.state.discoveredTokens.length }</span> )
	}
	getPageSelector() {
		return (
			<div className="db-section__toggle">
				<button
					className={ `tab ${ this.state.currentPage === 'wrapped' ? 'active' : '' }` }
					onClick={() => { this.setState({ currentPage: 'wrapped' }) }}
				>
					Wrapped
					{ ' ' }
					{
						this.getWrappedTokensCount()
					}
				</button>
				{ this.state.discoveredTokens.length ?
					(
						<button
							className={ `tab ${ this.state.currentPage === 'discovered' ? 'active' : '' }` }
							onClick={() => { this.setState({ currentPage: 'discovered' }) }}
						>
							Discovered
							{ ' ' }
							{
								this.getDiscoveredTokensCount()
							}
						</button>
					) : null
				}
				{
					!this.state.wrappedTokensStat || this.state.wrappedTokensStat.collaterals.length !== 0 ? (
						<button
							className={ `tab ${ this.state.currentPage === 'collateral' ? 'active' : '' }` }
							onClick={() => { this.setState({ currentPage: 'collateral' }) }}
						>
							Collateral
							{ ' ' }
							{
								this.getCollateralCount()
							}
						</button>
					) : null
				}
			</div>
		)
	}
	// ----- FILTER -----
	// ----- FILTER COINS -----
	filterTokens(
		tokensList    : Array<WrappedTokenType>,
		strQuery      : string,
		tokensQuery   : Array<ERC20ContractParamsType>,
		filterByParams: {
			timeLock   : boolean,
			fee        : boolean,
			unwrapReady: boolean,
		}
	): Array<WrappedTokenType> {
		const strQueryParsed    = strQuery.toLowerCase();
		const tokensQueryParsed = tokensQuery.map((item) => { return {
			...item,
			address: item.address.toLowerCase(),
			name: item.address.toLowerCase(),
			symbol: item.address.toLowerCase(),
		} });

		const filtered = tokensList
			.filter((item) => {

				if ( strQueryParsed !== '' ) {

					if ( item.contractAddress.toLowerCase().includes(strQueryParsed) ) { return true; }
					if ( `${item.tokenId}`.toLowerCase().includes(strQueryParsed) ) { return true; }

					if ( assetTypeToString(item.assetType, '').toLowerCase().includes(strQueryParsed)) { return true; }

					return false;
				}

				if ( tokensQueryParsed.length ) {

					if ( item.fees && item.fees.length ) {
						const feeFound = tokensQueryParsed.find((iitem) => { return iitem.address === item.fees[0].token.toLowerCase() });
						if ( feeFound ) { return true; }
					}

					if ( item.collateral && item.collateral.length ) {
						const collateralFound = tokensQueryParsed.find((iitem) => {
							return !!item.collateral.find((iiitem) => { return iitem.address === iiitem.address.toLowerCase() });
						});
						if ( collateralFound ) { return true; }
					}

					return false;
				}

				return true;
			})
			.filter((item) => {

				if ( filterByParams.fee ) {
					if ( item.fees.length ) { return true; }
					return false;
				}
				if ( filterByParams.timeLock ) {
					const foundTimeLock = item.locks.find((iitem) => { return iitem.lockType === LockType.time });
					if ( foundTimeLock ) {
						const now = new BigNumber(new Date().getTime());
						if ( now.lt(foundTimeLock.param) ) { return true; }
					}

					return false;
				}
				if ( filterByParams.unwrapReady ) {

					if ( item.assetType === _AssetType.ERC1155 ) {
						if ( item.amount && item.totalSupply ) {
							if ( !item.amount.eq(item.totalSupply) ) { return false; }
						}
					}

					const foundTimeLock = item.locks.find((iitem) => { return iitem.lockType === LockType.time });
					if ( foundTimeLock ) {
						const now = new BigNumber(new Date().getTime());
						if ( foundTimeLock.param.gt(now) ) { return false; }
					}

					const foundValueLock = item.locks.find((iitem) => { return iitem.lockType === LockType.value });
					if ( foundValueLock ) {

						let foundToken = undefined;
						if ( item.fees[0].token.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
							foundToken = this.state.techToken;
						} else {
							foundToken = this.state.erc20OtherTechTokens.find((iitem) => {
								if ( !item ) { return false; }
								return iitem.address.toLowerCase() === item.fees[0].token.toLowerCase()
							});
							if ( !foundToken ) {
								foundToken = this.state.erc20CollateralTokens.find((iitem) => {
									if ( !item ) { return false; }
									return iitem.address.toLowerCase() === item.fees[0].token.toLowerCase()
								});
							}
						}
						if ( !foundToken ) { return false; }

						if ( foundValueLock.param.gt(item.collectedFees) ) { return false; }

					}

					if ( item.rules.noUnwrap ) { return false; }

					return true;
				}

				return true;
			});

		return filtered;
	}
	getFilterTokenListItem(item: ERC20ContractParamsType) {
		const foundToken = this.state.filterByToken.find((iitem) => { return iitem.address.toLowerCase() === item.address.toLowerCase() });
		if ( foundToken ) {
			return (
				<li
					key={ item.address }
					className="option selected"
					onClick={() => {
						const filterTokensUpdated = this.state.filterByToken.filter((iitem) => {
							return iitem.address.toLowerCase() !== item.address.toLowerCase();
						});
						const wrappedTokenFilteredUpdated = this.filterTokens(this.state.wrappedTokens, this.state.filterByString, filterTokensUpdated, this.state.filterByParams);
						const discoveredTokenFilteredUpdated = this.filterTokens(this.state.discoveredTokens, this.state.filterByString, filterTokensUpdated, this.state.filterByParams);
						this.setState({
							filterByToken           : filterTokensUpdated,
							wrappedTokensFiltered   : wrappedTokenFilteredUpdated,
							discoveredTokensFiltered: discoveredTokenFilteredUpdated,
							wrappedPages            : Math.ceil( wrappedTokenFilteredUpdated.length / this.tokensOnPage ),
							discoveredPages         : Math.ceil( wrappedTokenFilteredUpdated.length / this.tokensOnPage ),
						});
					}}
				>
					<div className="option-coin">
						<span className="i-coin"><img src={ item.icon || default_icon } alt="" /></span>
						<span className="name">{ item.symbol }</span>
					</div>
				</li>
			)
		} else {
			return (
				<li
					key={ item.address }
					className="option"
					onClick={() => {
						const filterTokensUpdated = [
							...this.state.filterByToken,
							item
						];
						const wrappedTokenFilteredUpdated = this.filterTokens(this.state.wrappedTokens, this.state.filterByString, filterTokensUpdated, this.state.filterByParams);
						const discoveredTokenFilteredUpdated = this.filterTokens(this.state.discoveredTokens, this.state.filterByString, filterTokensUpdated, this.state.filterByParams);
						this.setState({
							filterByToken           : filterTokensUpdated,
							wrappedTokensFiltered   : wrappedTokenFilteredUpdated,
							discoveredTokensFiltered: discoveredTokenFilteredUpdated,
							wrappedPages            : Math.ceil( wrappedTokenFilteredUpdated.length / this.tokensOnPage ),
							discoveredPages         : Math.ceil( discoveredTokenFilteredUpdated.length / this.tokensOnPage ),
							wrappedCurrentPage      : 0,
							discoveredCurrentPage   : 0,
						});
					}}
				>
					<div className="option-coin">
						<span className="i-coin"><img src={ item.icon || default_icon } alt="" /></span>
						<span className="name">{ item.symbol }</span>
					</div>
				</li>
			)
		}
	}
	getFilterCoinsList() {
		if ( this.state.filterCoinsOpened ) {

			// {
			// 	this.state.erc20OtherTechTokens.map((item) => {
			// 		return this.getFilterTokenListItem(item);
			// 	})
			// }

			return (
				<ul className="options-list list-gray">

					{ this.getFilterTokenListItem({
						address    : '0x0000000000000000000000000000000000000000',
						icon       : this.state.iconNative,
						decimals   : this.state.decimalsNative,
						name       : 'native',
						symbol     : this.state.symbolNative,
						balance    : new BigNumber(0),
						allowance  : new BigNumber(0),
						permissions: {
							enabledForCollateral       : true,
							enabledForFee              : false,
							enabledRemoveFromCollateral: false,
						}
					}) }
					{ this.getFilterTokenListItem(this.state.techToken) }

					{
						this.state.erc20CollateralTokens.map((item) => {
							return this.getFilterTokenListItem(item);
						})
					}

				</ul>
			)
		}
	}
	openFilterCoinsList() {
		setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = (e: any) => {
				if ( !this.filterCoinsRef.current ) { return; }
				if ( e.path && e.path.includes(this.filterCoinsRef.current) ) { return; }
				this.closeFilterCoinsList();
			};
		}, 100);
		this.setState({ filterCoinsOpened: true });
	}
	closeFilterCoinsList() {
		const body = document.querySelector('body');
		if ( !body ) { return; }
		body.onclick = null;
		this.setState({ filterCoinsOpened: false });
	}
	// ----- END FILTER COINS -----
	// ----- FILTER SORT -----
	getFilterSortList() {
		if ( this.state.filterSortOpened ) {
			return (
				<ul className="options-list list-gray" style={{ display: 'block' }}>
					<li
						className="option"
						onClick={() => {
							this.setState({ sortBy: 'Address' });
						}}
					>Address</li>
					<li
						className="option"
						onClick={() => {
							this.setState({ sortBy: 'With image' });
						}}
					>With image</li>
				</ul>
			)
		}
	}
	openFilterSortList() {
		setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = (e: any) => {
				if ( !this.filterSortRef.current ) { return; }
				if ( e.path && e.path.includes(this.filterSortRef.current) ) { return; }
				this.closeFilterSortList();
			};
		}, 100);
		this.setState({ filterSortOpened: true });
	}
	closeFilterSortList() {
		const body = document.querySelector('body');
		if ( !body ) { return; }
		body.onclick = null;
		this.setState({ filterSortOpened: false });
	}
	// ----- END FILTER SORT -----
	getWrappedFilterBlock() {
		if ( this.state.currentPage !== 'wrapped' ) { return; }
		return (
			<div className="db-filter">
				<div className="row">
					<div className="col-12 col-md-5 col-lg-6 mb-4">
					{/* <div className="col-12 col-md-8 col-lg-8 mb-4"> */}
						<input
							className="input-control control-gray control-search"
							type="text"
							placeholder="NFT Address, ID..."
							value={this.state.filterByString}
							onChange={(e) => {
								const strUpdated = e.target.value;
								const wrappedTokenFilteredUpdated = this.filterTokens(this.state.wrappedTokens, strUpdated, this.state.filterByToken, this.state.filterByParams);
								const discoveredTokenFilteredUpdated = this.filterTokens(this.state.discoveredTokens, strUpdated, this.state.filterByToken, this.state.filterByParams);
								this.setState({
									filterByString          : strUpdated,
									wrappedTokensFiltered   : wrappedTokenFilteredUpdated,
									discoveredTokensFiltered: discoveredTokenFilteredUpdated,
									wrappedPages            : Math.ceil( wrappedTokenFilteredUpdated.length / this.tokensOnPage ),
									discoveredPages         : Math.ceil( discoveredTokenFilteredUpdated.length / this.tokensOnPage ),
									wrappedCurrentPage      : 0,
									discoveredCurrentPage   : 0,
								});
							}}
						/>
					</div>
					<div className="col-12 col-sm-8 col-md-4 col-lg-4 mb-4">
					{/* <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4"> */}
						<div
							className="select-custom select-collateral"
							ref={ this.filterCoinsRef }
						>
							<div
								onClick={() => {
									if ( this.state.filterCoinsOpened ) {
										this.closeFilterCoinsList();
									} else {
										this.openFilterCoinsList();
									}
								}}
								className={`input-control control-gray ${ this.state.filterCoinsOpened ? 'active' : '' }`}
							>
								{
									this.state.filterByToken.length ?
									(
										<span className="coins">
											{
												this.state.filterByToken.map((item) => {
													return ( <span className="i-coin"><img src={ item.icon || default_icon } alt="" /></span> )
												})
											}
										</span>
									): ( <span className="empty">Select tokens</span> )
								}
							</div>
							{ this.getFilterCoinsList() }
						</div>
					</div>
					<div className="col-12 col-sm-4 col-md-3 col-lg-2 mb-4">
						<div
							className="select-custom"
							ref={ this.filterSortRef }
						>
							<div
								className={`input-control control-gray ${ this.state.filterSortOpened ? 'active' : '' }`}
								onClick={() => {
									if ( this.state.filterSortOpened ) {
										this.closeFilterSortList();
									} else {
										this.openFilterSortList();
									}
								}}
							>
								<span className="empty">{ this.state.sortBy === '' ? 'Sort by' : this.state.sortBy }</span>
							</div>
							{ this.getFilterSortList() }
						</div>
					</div>
					<div className="col-12">
						<div className="row">
							<div className="col-auto mb-2">
								<label className="checkbox">
									<input
										type="checkbox"
										onChange={(e) => {
											const paramsUpdated = {
												...this.state.filterByParams,
												timeLock: e.target.checked
											};
											const wrappedTokenFilteredUpdated = this.filterTokens(this.state.wrappedTokens, this.state.filterByString, this.state.filterByToken, paramsUpdated);
											this.setState({
												filterByParams          : paramsUpdated,
												wrappedTokensFiltered   : wrappedTokenFilteredUpdated,
												wrappedPages            : Math.ceil( wrappedTokenFilteredUpdated.length / this.tokensOnPage ),
											});
										}}
									/>
									<span className="check"></span>
									<span className="check-text">TimeLock</span>
								</label>
							</div>
							<div className="col-auto mb-2">
								<label className="checkbox">
									<input
										type="checkbox"
										onChange={(e) => {
											const paramsUpdated = {
												...this.state.filterByParams,
												fee: e.target.checked
											};
											const wrappedTokenFilteredUpdated = this.filterTokens(this.state.wrappedTokens, this.state.filterByString, this.state.filterByToken, paramsUpdated);
											this.setState({
												filterByParams: paramsUpdated,
												wrappedTokensFiltered: wrappedTokenFilteredUpdated,
												wrappedPages         : Math.ceil( wrappedTokenFilteredUpdated.length / this.tokensOnPage ),
											});
										}}
									/>
									<span className="check"> </span>
									<span className="check-text">Transfer Fee</span>
								</label>
							</div>
							<div className="col-auto mb-2">
								<label className="checkbox">
									<input
										type="checkbox"
										onChange={(e) => {
											const paramsUpdated = {
												...this.state.filterByParams,
												unwrapReady: e.target.checked
											};
											const wrappedTokenFilteredUpdated = this.filterTokens(this.state.wrappedTokens, this.state.filterByString, this.state.filterByToken, paramsUpdated);
											this.setState({
												filterByParams       : paramsUpdated,
												wrappedTokensFiltered: wrappedTokenFilteredUpdated,
												wrappedPages         : Math.ceil( wrappedTokenFilteredUpdated.length / this.tokensOnPage ),
											});
										}}
									/>
									<span className="check"> </span>
									<span className="check-text">Ready to unwrap</span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	getDiscoveredFilterBlock() {
		if ( this.state.currentPage !== 'discovered' ) { return; }
		return (
			<div className="db-filter">
				<div className="row">
					<div className="col-12 col-md-8 col-lg-10 mb-4">
					{/* <div className="col-12 col-md-8 col-lg-8 mb-4"> */}
					{/* <div className="col-12 col-md-12 col-lg-12 mb-2"> */}
						<input
							className="input-control control-gray control-search"
							type="text"
							placeholder="NFT Address, ID..."
							value={this.state.filterByString}
							onChange={(e) => {
								const strUpdated = e.target.value;
								const wrappedTokenFilteredUpdated = this.filterTokens(this.state.wrappedTokens, strUpdated, this.state.filterByToken, this.state.filterByParams);
								const discoveredTokenFilteredUpdated = this.filterTokens(this.state.discoveredTokens, strUpdated, this.state.filterByToken, this.state.filterByParams);
								this.setState({
									filterByString          : strUpdated,
									wrappedTokensFiltered   : wrappedTokenFilteredUpdated,
									discoveredTokensFiltered: discoveredTokenFilteredUpdated,
									wrappedPages            : Math.ceil( wrappedTokenFilteredUpdated.length / this.tokensOnPage ),
									discoveredPages         : Math.ceil( discoveredTokenFilteredUpdated.length / this.tokensOnPage ),
									wrappedCurrentPage      : 0,
									discoveredCurrentPage   : 0,
								});
							}}
						/>
					</div>

					{/* <div className="col-12 col-sm-4 col-md-3 col-lg-2 mb-4"> */}
					{/* <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4"> */}
					<div className="col-12 col-sm-12 col-md-4 col-lg-2 mb-4">
						<div
							className="select-custom"
							ref={ this.filterSortRef }
						>
							<div
								className={`input-control control-gray ${ this.state.filterSortOpened ? 'active' : '' }`}
								onClick={() => {
									if ( this.state.filterSortOpened ) {
										this.closeFilterSortList();
									} else {
										this.openFilterSortList();
									}
								}}
							>
								<span className="empty">{ this.state.sortBy === '' ? 'Sort by' : this.state.sortBy }</span>
							</div>
							{ this.getFilterSortList() }
						</div>
					</div>
				</div>
			</div>
		)
	}
	// ----- END FILTER -----

	getInProgressTokensList() {
		if (
			// !this.state.incompleteTokens.length &&
			!this.state.waitingTokens.length
		) {
			return null
		}

		return (
			<React.Fragment>
			<div className="db-section">
			<div className="divider right short"></div>
			<div className="container">
				<div className="c-header">
					<div className="h3">{ this.t('In progress') }</div>
				</div>
				<div className="c-row" ref={ this.scrollToBlock }>
					{
						this.state.waitingTokens
							// .sort((item, prev) => {
							// 	if ( item.token.contractAddress < prev.token.contractAddress ) { return -1 }
							// 	if ( item.token.contractAddress > prev.token.contractAddress ) { return  1 }

							// 	try {
							// 		if ( new BigNumber(item.token.tokenId).isNaN() || new BigNumber(prev.token.tokenId).isNaN() ) {
							// 			if ( item.token.tokenId < prev.token.tokenId ) { return -1 }
							// 			if ( item.token.tokenId > prev.token.tokenId ) { return  1 }
							// 		}
							// 		const itemTokenIdNumber = new BigNumber(item.token.tokenId);
							// 		const prevTokenIdNumber = new BigNumber(prev.token.tokenId);

							// 		if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return -1 }
							// 		if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return  1 }
							// 	} catch ( ignored ) {
							// 		if ( item.token.tokenId < prev.token.tokenId ) { return -1 }
							// 		if ( item.token.tokenId > prev.token.tokenId ) { return  1 }
							// 	}

							// 	return 0
							// })
							.map((item: { token: WrappedTokenType, msg: string }) => {
								return (
									<div className="c-col">
									<TokenInList
										key={ `${item.token.contractAddress}${item.token.tokenId}` }
										store = { this.store }
										metamaskAdapter = { this.metamaskAdapter }
										originalToken = { item.token }
										tokenType = { TokenRenderType.waiting }
										footerMsg = { item.msg }
									/>
									</div>
								)
							})
					}
					{/* {
						this.state.incompleteTokens
							// .sort((item, prev) => {

							// 	if ( item.contractAddress < prev.contractAddress ) { return -1 }
							// 	if ( item.contractAddress > prev.contractAddress ) { return  1 }

							// 	try {
							// 		if ( new BigNumber(item.tokenId).isNaN() || new BigNumber(prev.tokenId).isNaN() ) {
							// 			if ( item.tokenId < prev.tokenId ) { return -1 }
							// 			if ( item.tokenId > prev.tokenId ) { return  1 }
							// 		}
							// 		const itemTokenIdNumber = new BigNumber(item.tokenId);
							// 		const prevTokenIdNumber = new BigNumber(prev.tokenId);

							// 		if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return -1 }
							// 		if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return  1 }
							// 	} catch ( ignored ) {
							// 		if ( item.tokenId < prev.tokenId ) { return -1 }
							// 		if ( item.tokenId > prev.tokenId ) { return  1 }
							// 	}

							// 	return 0
							// })
							.map((item: WrappedTokenType) => {
								return (
									<div className="c-col">
									<TokenInList
										key={ `${item.token.contractAddress}${item.token.tokenId}` }
										store = { this.store }
										metamaskAdapter = { this.metamaskAdapter }
										token = { item }
										tokenType = { TokenRenderType.incomplete }
									/>
									</div>
								)
							})
					} */}
				</div>
			</div>
			</div>
			</React.Fragment>
		)
	}

	getYourTokenPagination() {
		if ( this.state.wrappedPages < 2 ) { return null; }
		return (
			<div className="pagination">
				<ul>
					<li>
						<button
							className={`arrow ${ this.state.wrappedCurrentPage === 0 ? 'disabled' : '' }`}
							onClick={(e) => {
								e.preventDefault();
								if ( this.state.wrappedCurrentPage === 0 ) { return; }
								this.setState({ wrappedCurrentPage: this.state.wrappedCurrentPage - 1 })
							}}
						>
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M24.0684 10.3225C24.504 10.7547 24.5067 11.4582 24.0744 11.8938L15.9623 20.0679L23.9397 28.1062C24.372 28.5418 24.3693 29.2453 23.9338 29.6775C23.4982 30.1098 22.7947 30.1071 22.3624 29.6716L13.6082 20.8505C13.1783 20.4173 13.1783 19.7184 13.6082 19.2852L22.4971 10.3284C22.9294 9.89287 23.6329 9.89019 24.0684 10.3225Z" fill="white"></path>
							</svg>
						</button>
					</li>
					{
						Array.from({ length: this.state.wrappedPages }).map((item, idx) => {
							return (
								<li key={ idx }>
									<button
										className={ this.state.wrappedCurrentPage === idx ? 'active' : ''}
										onClick={(e) => { e.preventDefault(); this.setState({ wrappedCurrentPage: idx }) }}
									>
										{ idx + 1 }
									</button>
								</li>
							)
						})
					}
					<li>
						<button
							className={`arrow ${ this.state.wrappedCurrentPage === (this.state.wrappedPages - 1) ? 'disabled' : '' }`}
							onClick={(e) => {
								e.preventDefault();
								if ( this.state.wrappedCurrentPage === (this.state.wrappedPages - 1) ) { return; }
								this.setState({ wrappedCurrentPage: this.state.wrappedCurrentPage + 1 })
							}}
						>
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M15.9316 29.6775C15.496 29.2453 15.4933 28.5418 15.9256 28.1062L24.0377 19.9321L16.0603 11.8938C15.628 11.4582 15.6307 10.7547 16.0662 10.3225C16.5018 9.89019 17.2053 9.89287 17.6376 10.3284L26.3918 19.1495C26.8217 19.5827 26.8217 20.2816 26.3918 20.7148L17.5029 29.6716C17.0706 30.1071 16.3671 30.1098 15.9316 29.6775Z" fill="white"></path>
							</svg>
						</button>
					</li>
				</ul>
			</div>
		)
	}
	getYourTokensFooter() {
		if ( this.state.loadingInProgress ) {
			return (
				<div className="lp-list__footer">
					<img className="loading" src={ icon_loading } alt="" />
				</div>
			)
		}

		if ( !this.state.wrappedTokensFiltered.length ) {
			return (
				<div className="lp-list__footer">
					<div className="nomore">There's no more wNFT yet</div>
				</div>
			)
		}
	}
	getYourTokensBody() {

		let sortedList;

		if ( this.state.sortBy === 'With image' ) {
			sortedList = this.state.wrappedTokensFiltered
				.sort((item, prev) => {
					if ( item.image && !prev.image ) { return -1 }
					if ( prev.image && !item.image ) { return  1 }

					if ( item.contractAddress.toLowerCase() < prev.contractAddress.toLowerCase() ) { return -1 }
					if ( item.contractAddress.toLowerCase() > prev.contractAddress.toLowerCase() ) { return  1 }

					if ( prev.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() ) {
						try {
							if ( new BigNumber(item.tokenId).isNaN() || new BigNumber(prev.tokenId).isNaN() ) {
								if ( parseInt(`${item.tokenId}`) < parseInt(`${prev.tokenId}`) ) { return  1 }
								if ( parseInt(`${item.tokenId}`) > parseInt(`${prev.tokenId}`) ) { return -1 }
							}
							const itemTokenIdNumber = new BigNumber(item.tokenId);
							const prevTokenIdNumber = new BigNumber(prev.tokenId);

							if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return  1 }
							if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return -1 }
						} catch ( ignored ) {
							if ( `${item.tokenId}`.toLowerCase() < `${prev.tokenId}`.toLowerCase() ) { return  1 }
							if ( `${item.tokenId}`.toLowerCase() > `${prev.tokenId}`.toLowerCase() ) { return -1 }
						}
					}

					return 0
				})
		} else {
			sortedList = this.state.wrappedTokensFiltered
				.sort((item, prev) => {
					if (
						item.contractAddress.toLowerCase() === this.metamaskAdapter.chainConfig.wrapperContract.toLowerCase() &&
						prev.contractAddress.toLowerCase() !== this.metamaskAdapter.chainConfig.wrapperContract.toLowerCase()
					) {
						return -1
					}
					if (
						prev.contractAddress.toLowerCase() === this.metamaskAdapter.chainConfig.wrapperContract.toLowerCase() &&
						item.contractAddress.toLowerCase() !== this.metamaskAdapter.chainConfig.wrapperContract.toLowerCase()
					) {
						return 1
					}
					if ( item.contractAddress.toLowerCase() < prev.contractAddress.toLowerCase() ) { return -1 }
					if ( item.contractAddress.toLowerCase() > prev.contractAddress.toLowerCase() ) { return  1 }

					if ( prev.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() ) {
						try {
							if ( new BigNumber(item.tokenId).isNaN() || new BigNumber(prev.tokenId).isNaN() ) {
								if ( parseInt(`${item.tokenId}`) < parseInt(`${prev.tokenId}`) ) { return  1 }
								if ( parseInt(`${item.tokenId}`) > parseInt(`${prev.tokenId}`) ) { return -1 }
							}
							const itemTokenIdNumber = new BigNumber(item.tokenId);
							const prevTokenIdNumber = new BigNumber(prev.tokenId);

							if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return  1 }
							if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return -1 }
						} catch ( ignored ) {
							if ( `${item.tokenId}`.toLowerCase() < `${prev.tokenId}`.toLowerCase() ) { return  1 }
							if ( `${item.tokenId}`.toLowerCase() > `${prev.tokenId}`.toLowerCase() ) { return -1 }
						}
					}

					return 0
				})
		}

		return (
			<React.Fragment>
				<p style={{ fontSize: '24px' }}>To correctly show your NFTs, <b>please disable Shield</b> in Brave browser or other ad blockers.</p>
				<div className="c-row" ref={ this.scrollToBlock }>
					{
						sortedList
							.slice(
								(this.state.wrappedCurrentPage * this.tokensOnPage),
								(this.state.wrappedCurrentPage * this.tokensOnPage) + this.tokensOnPage
							)
							.map((item: WrappedTokenType) => {
								return (
									<div className="c-col" key={ `${item.contractAddress}${item.tokenId}` }>
									<TokenInList
										store = { this.store }
										metamaskAdapter = { this.metamaskAdapter }
										wrappedToken = { item }
										tokenType = { TokenRenderType.wrapped }
									/>
									</div>
								)
							})
					}
				</div>
				{ this.getYourTokenPagination() }
			</React.Fragment>
		)
	}
	getYourTokensList() {

		return (
			<React.Fragment>
			<div className="db-section">
			<div className="container">
				{ this.getWrappedFilterBlock() }
				{ this.getYourTokensBody() }
				{ this.getYourTokensFooter() }
			</div>
			</div>
			</React.Fragment>
		)
	}
	getDiscoveredTokenPagination() {
		if ( this.state.discoveredPages < 2 ) { return null; }
		return (
			<div className="pagination">
				<ul>
					<li>
						<button
							className={`arrow ${ this.state.discoveredCurrentPage === 0 ? 'disabled' : '' }`}
							onClick={(e) => {
								e.preventDefault();
								if ( this.state.discoveredCurrentPage === 0 ) { return; }
								this.setState({ discoveredCurrentPage: this.state.discoveredCurrentPage - 1 })
							}}
						>
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M24.0684 10.3225C24.504 10.7547 24.5067 11.4582 24.0744 11.8938L15.9623 20.0679L23.9397 28.1062C24.372 28.5418 24.3693 29.2453 23.9338 29.6775C23.4982 30.1098 22.7947 30.1071 22.3624 29.6716L13.6082 20.8505C13.1783 20.4173 13.1783 19.7184 13.6082 19.2852L22.4971 10.3284C22.9294 9.89287 23.6329 9.89019 24.0684 10.3225Z" fill="white"></path>
							</svg>
						</button>
					</li>
					{
						Array.from({ length: this.state.discoveredPages }).map((item, idx) => {
							return (
								<li key={ idx }>
									<button
										className={ this.state.discoveredCurrentPage === idx ? 'active' : ''}
										onClick={(e) => { e.preventDefault(); this.setState({ discoveredCurrentPage: idx }) }}
									>
										{ idx + 1 }
									</button>
								</li>
							)
						})
					}
					<li>
						<button
							className={`arrow ${ this.state.discoveredCurrentPage === (this.state.discoveredPages - 1) ? 'disabled' : '' }`}
							onClick={(e) => {
								e.preventDefault();
								if ( this.state.discoveredCurrentPage === (this.state.discoveredPages - 1) ) { return; }
								this.setState({ discoveredCurrentPage: this.state.discoveredCurrentPage + 1 })
							}}
						>
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M15.9316 29.6775C15.496 29.2453 15.4933 28.5418 15.9256 28.1062L24.0377 19.9321L16.0603 11.8938C15.628 11.4582 15.6307 10.7547 16.0662 10.3225C16.5018 9.89019 17.2053 9.89287 17.6376 10.3284L26.3918 19.1495C26.8217 19.5827 26.8217 20.2816 26.3918 20.7148L17.5029 29.6716C17.0706 30.1071 16.3671 30.1098 15.9316 29.6775Z" fill="white"></path>
							</svg>
						</button>
					</li>
				</ul>
			</div>
		)
	}
	getDiscoveredTokensList() {
		if ( !this.state.discoveredTokens.length ) { return null }

		let sortedList;
		if ( this.state.sortBy === 'With image' ) {
			sortedList = this.state.discoveredTokensFiltered
			// sort by contractaddress and tokenid
			.sort((item, prev) => {

				if ( item.image && !prev.image ) { return -1 }
				if ( prev.image && !item.image ) { return  1 }

				if ( item.assetType < prev.assetType ) { return -1 }
				if ( item.assetType > prev.assetType ) { return  1 }

				if ( item.contractAddress.toLowerCase() < prev.contractAddress.toLowerCase() ) { return -1 }
				if ( item.contractAddress.toLowerCase() > prev.contractAddress.toLowerCase() ) { return  1 }

				try {
					if ( new BigNumber(item.tokenId).isNaN() || new BigNumber(prev.tokenId).isNaN() ) {
						if ( parseInt(`${item.tokenId}`) < parseInt(`${prev.tokenId}`) ) { return -1 }
						if ( parseInt(`${item.tokenId}`) > parseInt(`${prev.tokenId}`) ) { return  1 }
					}
					const itemTokenIdNumber = new BigNumber(item.tokenId);
					const prevTokenIdNumber = new BigNumber(prev.tokenId);

					if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return -1 }
					if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return  1 }
				} catch ( ignored ) {
					if ( `${item.tokenId}`.toLowerCase() < `${prev.tokenId}`.toLowerCase() ) { return -1 }
					if ( `${item.tokenId}`.toLowerCase() > `${prev.tokenId}`.toLowerCase() ) { return  1 }
				}

				return 0
			})
		} else {
			sortedList = this.state.discoveredTokensFiltered
				// sort by contractaddress and tokenid
				.sort((item, prev) => {

					if ( item.assetType < prev.assetType ) { return -1 }
					if ( item.assetType > prev.assetType ) { return  1 }

					if ( item.contractAddress.toLowerCase() < prev.contractAddress.toLowerCase() ) { return -1 }
					if ( item.contractAddress.toLowerCase() > prev.contractAddress.toLowerCase() ) { return  1 }

					try {
						if ( new BigNumber(item.tokenId).isNaN() || new BigNumber(prev.tokenId).isNaN() ) {
							if ( parseInt(`${item.tokenId}`) < parseInt(`${prev.tokenId}`) ) { return -1 }
							if ( parseInt(`${item.tokenId}`) > parseInt(`${prev.tokenId}`) ) { return  1 }
						}
						const itemTokenIdNumber = new BigNumber(item.tokenId);
						const prevTokenIdNumber = new BigNumber(prev.tokenId);

						if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return -1 }
						if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return  1 }
					} catch ( ignored ) {
						if ( `${item.tokenId}`.toLowerCase() < `${prev.tokenId}`.toLowerCase() ) { return -1 }
						if ( `${item.tokenId}`.toLowerCase() > `${prev.tokenId}`.toLowerCase() ) { return  1 }
					}

					return 0
				})
				// sort by blocknumber and logindex
				// .sort((item, prev) => {

				// 	if ( !item.sortParams.blockNumber || !item.sortParams.logIndex ) { return  1 }
				// 	if ( !prev.sortParams.blockNumber || !prev.sortParams.logIndex ) { return -1 }

				// 	if ( item.sortParams.blockNumber.lt(prev.sortParams.blockNumber) ) { return  1 }
				// 	if ( item.sortParams.blockNumber.gt(prev.sortParams.blockNumber) ) { return -1 }

				// 	if ( item.sortParams.logIndex.lt(prev.sortParams.logIndex) ) { return  1 }
				// 	if ( item.sortParams.logIndex.gt(prev.sortParams.logIndex) ) { return -1 }

				// 	return 0
				// })
		}

		return (
			<React.Fragment>
			<div className="db-section">
			<div className="container">

				{ this.getDiscoveredFilterBlock() }

				<p style={{ fontSize: '24px' }}>To correctly show your NFTs, <b>please disable Shield</b> in Brave browser or other ad blockers.</p>
				<div className="c-row">
					{
						sortedList
							.slice(
								(this.state.discoveredCurrentPage * this.tokensOnPage),
								(this.state.discoveredCurrentPage * this.tokensOnPage) + this.tokensOnPage
							)
							.map((item: WrappedTokenType) => {
								return (
									<div className="c-col" key={ `${item.contractAddress}${item.tokenId}` }>
									<TokenInList
										store = { this.store }
										metamaskAdapter = { this.metamaskAdapter }
										originalToken = { item }
										tokenType = { TokenRenderType.discovered }
									/>
									</div>
								)
							})
					}
				</div>
				{ this.getDiscoveredTokenPagination() }
			</div>
			</div>
			</React.Fragment>
		)
	}
	getCurrentPage() {
		if ( this.state.currentPage === 'wrapped' ) {
			return this.getYourTokensList()
		}
		if ( this.state.currentPage === 'discovered' ) {
			return this.getDiscoveredTokensList()
		}
		if ( this.state.currentPage === 'collateral' ) {
			return this.getStatCollateralBlock();
		}
	}

	getInfoText() {
		return (
			<div className="db-info">
				<div className="divider right"></div>
				<div className="container mt-6">
					<div className="row">
						<div className="col-12 col-md-8">
							<div className="c-wrap">
								<p><b>NFT 2.0&nbsp;is known under many names</b>: Smart, Wrapped, Programmable, etc. </p>
								<p><b>DAO Envelop (2020) is&nbsp;the pioneer of&nbsp;this trend.</b><br /> You can do&nbsp;a&nbsp;lot with Envelop applications: create wrapped NFT, collateralize ERC-20 tokens and native coins (and NFTs in&nbsp;some special cases), and use complex mechanics like Cross NFT, which are extending to&nbsp;ZKP very soon (2023).</p>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="text-small">
								<p>If&nbsp;you have constructive suggestions on&nbsp;how to&nbsp;improve Envelop applications, you are welcome to&nbsp;participate in&nbsp;the bounty campaign. Envelop administrator is&nbsp;always in&nbsp;touch. Write and develop a&nbsp;new market with&nbsp;us!</p><a className="btn btn-sm btn-border" href="https://t.me/TW4Ys" target="_blank" rel="noopener noreferrer">Write to Envelop</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		return (
			<main className="s-main">

				<div className="container mt-4">
					<div className="row mb-6">
							<div className="col-12 col-md-3 order-md-2">
								<a className="btn btn-grad w-100" href="/wrap">
									Free Wrap
								</a>
							</div>

							<div className="col-12 col-md order-md-1">
								{ this.getPageSelector() }
							</div>
					</div>
				</div>


				{ this.getInProgressTokensList() }
				{ this.getCurrentPage() }

				{ this.getInfoText() }

			</main>
		)
	}
}

export default withTranslation("translations")(withRouter(TokenList));