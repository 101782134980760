
import { Contract } from "web3-eth-contract";
import ERC20Contract from './erc20contract';
import MetamaskAdapter from './metamaskadapter';
import {
	AdvancedLoaderStageType,
	clearInfo,
	createAdvancedLoading,
	setError,
	setInfo,
	setLoading,
	unsetLoading,
	updateStepAdvancedLoading,
	_AdvancedLoadingStatus
} from "../../reducers";

import {
	SAFTPayOption,
	SAFTTariff,
} from './_types';
import { getABI } from '../_utils';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type SubscriptionDispatcherPropsType = {
	metamaskAdapter            : MetamaskAdapter,
	store                      : any,
	t                          : any,
	subscriptionRegistryAddress: string | undefined,
	subscriptionAgentAddress   : string | undefined,
	serviceProviderAddress     : string,
	updateParentComponent      : () => void;
}

export default class SubscriptionDispatcher {

	metamaskAdapter               : MetamaskAdapter;
	store                         : any;
	t                             : any;

	updateParentComponent         : () => void;

	subscriptionAgentAddress!     : undefined | string;
	subscriptionRegistryAddress!  : undefined | string;
	subscriptionRegistryContract! : Contract | undefined;
	subscriptionAgentContract!    : Contract | undefined;

	serviceProviderAddress!       : string;
	availableTariffs              : Array<SAFTTariff>;
	paymentTokens                 : Array<ERC20Contract>;

	subscriptionRemainings        : undefined | {
		timeRemaining: BigNumber,
		txRemaining: number,
	};

	constructor(props: SubscriptionDispatcherPropsType) {
		this.metamaskAdapter             = props.metamaskAdapter;
		this.store                       = props.store;
		this.t                           = props.t;
		this.updateParentComponent       = props.updateParentComponent;
		this.availableTariffs            = [];
		this.paymentTokens               = [];

		this.subscriptionAgentAddress    = props.subscriptionAgentAddress;
		this.subscriptionRegistryAddress = props.subscriptionRegistryAddress;
		this.serviceProviderAddress      = props.serviceProviderAddress;

		this.createContracts();
	}

	async createContracts() {

		if (
			!this.subscriptionRegistryAddress ||
			this.subscriptionRegistryAddress.toLowerCase() === '0x0000000000000000000000000000000000000000' ||
			!this.subscriptionAgentAddress ||
			this.subscriptionAgentAddress.toLowerCase() === '0x0000000000000000000000000000000000000000'
		) {
			this.subscriptionRemainings = undefined;
			this.subscriptionRegistryContract = undefined;
			return;
		}

		if ( !this.store.getState()._loading ) {
			this.store.dispatch(setLoading({ msg: this.t('Checking subscription') }));
		}

		let subscriptionRegistryContractABI;
		try {
			subscriptionRegistryContractABI = getABI(this.metamaskAdapter.chainId || 0, this.subscriptionRegistryAddress, 'subscriptionRegistryContract');
		} catch(e) {
			console.log(`Cannot load ${this.subscriptionRegistryAddress} subscriptionRegistryContract abi:`, e);
			throw new Error(`Cannot load subscriptionRegistryContract abi`);
		}
		this.subscriptionRegistryContract = new this.metamaskAdapter.web3.eth.Contract(subscriptionRegistryContractABI, this.subscriptionRegistryAddress);

		let subscriptionAgentABI;
		try {
			subscriptionAgentABI = getABI(this.metamaskAdapter.chainId || 0, this.subscriptionAgentAddress, 'subscriptionAgent');
		} catch(e) {
			console.log(`Cannot load ${this.subscriptionAgentAddress} subscriptionAgent abi:`, e);
			throw new Error(`Cannot load subscriptionAgent abi`);
		}
		this.subscriptionAgentContract = new this.metamaskAdapter.web3.eth.Contract(subscriptionAgentABI, this.subscriptionAgentAddress);

		await this.fetchTariffs();
		await this.checkSubcription();
		if ( this.store.getState()._loading.toLowerCase().includes('subscription') ) {
			this.store.dispatch(unsetLoading());
		}
	}

	isLocked() {
		if ( !this.subscriptionRemainings ) { return false; }

		const timeLock = this.subscriptionRemainings.timeRemaining.lte( new BigNumber(new Date().getTime()).dividedBy(1000) );
		if ( !timeLock ) { return false; }
		const qtyLock  = this.subscriptionRemainings.txRemaining === 0;
		if ( !qtyLock ) { return false; }

		return true;
	}
	async checkSubcription() {
		const defaultRemainings = {
			timeRemaining: new BigNumber(0),
			txRemaining  : 0
		};

		if ( !this.subscriptionRegistryContract ) {
			this.setRemainings(defaultRemainings); return;
		}

		const userTicket = await this.subscriptionRegistryContract.methods.getUserTicketForService(this.serviceProviderAddress, this.metamaskAdapter.userAddress).call();

		if (
			userTicket &&
			userTicket.countsLeft &&
			userTicket.validUntil
		) {
			const validUntil = new BigNumber(userTicket.validUntil);
			const countsLeft = parseInt(userTicket.countsLeft)
			const now = new BigNumber(new Date().getTime()).dividedBy(1000);

			if ( validUntil.gt(now) || countsLeft > 0 ) {
				this.setRemainings({
					timeRemaining: validUntil,
					txRemaining  : countsLeft,
				});
				return;
			}
		}

		this.setRemainings(defaultRemainings);
	}
	setRemainings(remainings: { timeRemaining: BigNumber, txRemaining: number }) {
		this.subscriptionRemainings = remainings;
		this.updateParentComponent();
	}
	async fetchTariffs() {
		if ( !this.subscriptionRegistryContract ) { return; }

		const fetchedTariffs = await this.subscriptionRegistryContract.methods.getAvailableAgentsTariffForService(
			this.subscriptionAgentAddress,
			this.serviceProviderAddress,
		).call();

		this.availableTariffs = fetchedTariffs[1]
			.map((item: SAFTTariff, idx: number) => { return { ...item, idx: parseInt(fetchedTariffs[0][idx]) } })
			.map((item: SAFTTariff) => {
				return {
					...item,
					subscription: {
						...item.subscription,
						timelockPeriod: new BigNumber(item.subscription.timelockPeriod),
						ticketValidPeriod:  new BigNumber(item.subscription.ticketValidPeriod),
					},
					payWith: item.payWith.map((iitem: SAFTPayOption, idx: number) => {
						return {
							...iitem,
							agentFeePercent: new BigNumber(iitem.agentFeePercent),
							paymentAmountWithFee: new BigNumber(iitem.paymentAmount),
							paymentAmount: new BigNumber(iitem.paymentAmount),
							idx
						}
					})
				}
			});

		for ( const item of this.availableTariffs ) {
			for ( const iitem of item.payWith ) {

				const foundToken = this.paymentTokens.find((iiitem) => { return iiitem.contractAddress.toLowerCase() === iitem.paymentToken.toLowerCase() });
				if ( !foundToken ) {
					this.paymentTokens.push(new ERC20Contract({
						web3: this.metamaskAdapter.web3,
						store: this.store,
						contractAddress: iitem.paymentToken,
						contractType: 'subscriptionPayment',
						userAddress: this.metamaskAdapter.userAddress,
						wrapperAddress: this.subscriptionRegistryAddress || '0x0000000000000000000000000000000000000000',
					}))
				}
			}
		}

		this.updateParentComponent();
	}

	async getPriceWithFee(tariffIdx: number, paymentToken: string) {
		if ( !this.subscriptionAgentContract ) { return; }
		if ( !this.subscriptionRegistryContract ) { return; }

		const foundTariff = this.availableTariffs.find((item) => { return item.idx === tariffIdx });
		if ( !foundTariff ) { return; }

		const foundPayOption = foundTariff.payWith.find((item) => { return item.paymentToken.toLowerCase() === paymentToken.toLowerCase() });
		if ( !foundPayOption ) { return; }

		const resp = await this.subscriptionRegistryContract.methods.getTicketPrice(this.serviceProviderAddress, tariffIdx, foundPayOption.idx).call()
		return new BigNumber(resp[1]);
	}
	async buySubscription(tariffIdx: number, paymentToken: string, buyFor?: string) {
		if ( !this.subscriptionAgentContract ) { return; }
		if ( !this.subscriptionRegistryContract ) { return; }

		const foundERC20Contract = this.paymentTokens.find((item) => { return item.contractAddress.toLowerCase() === paymentToken.toLowerCase() });
		if ( !foundERC20Contract ) { return; }

		const foundTariff = this.availableTariffs.find((item) => { return item.idx === tariffIdx });
		if ( !foundTariff ) { return; }

		const foundPayOption = foundTariff.payWith.find((item) => { return item.paymentToken.toLowerCase() === paymentToken.toLowerCase() });
		if ( !foundPayOption ) { return; }

		let priceTopay = await this.getPriceWithFee(tariffIdx, paymentToken);
		if ( !priceTopay ) {
			console.log('Cannot fetch price to pay, use price multiplied by 2');
			priceTopay = foundPayOption.paymentAmount.multipliedBy(2);
		}

		const loaderStages: Array<AdvancedLoaderStageType> = []

		if ( paymentToken !== '0x0000000000000000000000000000000000000000' ) {
			loaderStages.push({
				id: 'approveerc20',
				sortOrder: 1,
				text: `Approving ${foundERC20Contract.erc20Params.symbol}`,
				status: _AdvancedLoadingStatus.loading
			});
		}
		loaderStages.push({
			id: 'buy',
			sortOrder: 1,
			text: `Buying subscription`,
			status: _AdvancedLoadingStatus.queued
		});
		const advLoader = {
			title: 'Waiting for buy',
			stages: loaderStages
		};
		this.store.dispatch(createAdvancedLoading(advLoader));

		let balance;
		if ( paymentToken === '0x0000000000000000000000000000000000000000' ) {
			balance = await this.metamaskAdapter.fetchNativeBalance()
			if ( balance.lt( priceTopay ) ) {
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Not enough')} ${foundERC20Contract.erc20Params.symbol}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}
		} else {
			balance = await foundERC20Contract.getBalance(this.subscriptionRegistryAddress);
			if ( balance.balance.lt( priceTopay ) ) {
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Not enough')} ${foundERC20Contract.erc20Params.symbol}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}
			if ( balance.allowance.lt( priceTopay ) ) {
				try {
					await foundERC20Contract.makeAllowance(priceTopay, this.subscriptionRegistryAddress);
				} catch (e: any) {
					this.store.dispatch(unsetLoading());
					this.store.dispatch(setError({
						text: `${this.t('Cannot approve')} ${foundERC20Contract.erc20Params.symbol}: ${e.message}`,
						buttons: undefined,
						links: undefined,
					}));
					return;
				}
			}
		}

		if ( paymentToken !== '0x0000000000000000000000000000000000000000' ) {
			this.store.dispatch(updateStepAdvancedLoading({
				id: 'approveerc20',
				sortOrder: 1,
				text: `Approving ${foundERC20Contract.erc20Params.symbol}`,
				status: _AdvancedLoadingStatus.complete
			}));
		}
		this.store.dispatch(updateStepAdvancedLoading({
			id: 'buy',
			sortOrder: 1,
			text: `Buying subscription`,
			status: _AdvancedLoadingStatus.loading
		}));

		const tx = this.subscriptionAgentContract.methods.buySubscription(this.serviceProviderAddress, tariffIdx, foundPayOption.idx, buyFor || this.metamaskAdapter.userAddress, this.metamaskAdapter.userAddress);

		let errMsg = '';
		try {
			await tx.estimateGas({
				from: this.metamaskAdapter.userAddress,
				value: paymentToken === '0x0000000000000000000000000000000000000000' ? priceTopay : undefined
			});
		} catch(e: any) {
			try {
				console.log('Cannot buy before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
			} catch(ignored) {}
		}
		if ( errMsg !== '' ) {
			this.store.dispatch(setError({
				text: `${this.t('Cannot buy subscription')}: ${errMsg}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}

		tx
			.send({
				from: this.metamaskAdapter.userAddress,
				value: paymentToken === '0x0000000000000000000000000000000000000000' ? priceTopay : undefined
			})
			.then((data: any) => {
				this.store.dispatch(unsetLoading());
				setTimeout(() => {
					this.metamaskAdapter.updateAllBalances();
					this.checkSubcription();
				}, 100);

				this.store.dispatch(setInfo({
					text: `${this.t('Subscription Successfully Bought')}`,
					buttons: [{
					text: 'Ok',
					clickFunc: () => {
						this.store.dispatch(clearInfo());
					}
					}],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
			})
			.catch((e: any) => {
				console.log('Cannot buy after send: ', e);

				this.store.dispatch(unsetLoading());

				let errorMsg = '';
				if ('message' in e) {
					errorMsg = e.message
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.originalError.message;
					} catch(ignored) {}
				}

				let links = undefined;
				if ('transactionHash' in e) {
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						const txHash = errorParsed.transactionHash;
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					} catch(ignored) {}
				}

				if ( !errorMsg.includes('was not mined within 50 blocks') ) {
					this.store.dispatch(setError({
						text: `${this.t('Cannot buy subcription')}: ${errorMsg}`,
						buttons: undefined,
						links: links,
					}));
				}
			});

	}

}