
import Web3         from 'web3';
import { Contract } from "web3-eth-contract";
import {
	MetamaskAdapter,
} from '.';

import BigNumber  from 'bignumber.js';
import { WrapTransactionArgs } from './_types';
import { getABI } from '../_utils';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type CheckerContractPropsType = {
	store              : any,
	web3               : Web3,
	metamaskAdapter    : MetamaskAdapter,
	contractAddress    : string,
	userAddress        : string,
	t                  : any,
}

export default class CheckerContract {

	web3                   : Web3;
	metamaskAdapter        : MetamaskAdapter;
	store                  : any;
	contractAddress        : string;
	userAddress            : string;
	contract               : Contract;
	t                      : any;

	constructor(props: CheckerContractPropsType) {
		this.web3                    = props.web3;
		this.metamaskAdapter         = props.metamaskAdapter;
		this.store                   = props.store;
		this.contractAddress         = props.contractAddress;
		this.userAddress             = props.userAddress;
		this.t                       = props.t;

		let checkerABI;
		try {
			checkerABI = getABI(this.metamaskAdapter.chainId || 0, this.contractAddress, 'checker');
		} catch(e) {
			console.log(`Cannot load ${this.contractAddress} checker abi:`, e);
			throw new Error(`Cannot load checker abi`);
		}
		this.contract = new this.web3.eth.Contract(checkerABI, this.contractAddress);
		console.log('checker', this.contract);
	}

	async checkWrapArgs(args: WrapTransactionArgs) {
		const output = await this.contract.methods.checkWrap(args._inData, args._collateral, args._wrappFor).call();
		return output;
	}

}