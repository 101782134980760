
import Web3         from 'web3';
import { Contract } from "web3-eth-contract";

import erc721_abi from '../../abis/_erc721.json';
import MetamaskAdapter from './metamaskadapter';
import {
	WrappedTokensStatType
} from './wrappercontract';
import {
	fetchWrappedTokenById
} from '../APIService/apiservice';
import {
	getOriginalToken
} from '../TokenFetchWrapper/tokenfetchwrapper';
import {
	CollateralItem,
	decodeWrappedToken,
	WrappedTokenType,
	_AssetType
} from './_types';

import {
	addWNFTStorage,
	clearInfo,
	setError,
	setInfo,
	setLoading,
	unsetLoading,
	wrappedStatsUpdate,
	wrappedTokensAdd
} from '../../reducers';

import {
	getABI,
	processSwarmUrl
} from '../_utils';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type WNFTStorageContractPropsType = {
	web3            : Web3,
	metamaskAdapter : MetamaskAdapter,
	store           : any,
	chainId         : number,
	contractAddress : string,
	contractStandart: _AssetType,
	userAddress     : string,
}

export const calcTokenStats = (token: WrappedTokenType, store: any) => {
	let stat: WrappedTokensStatType = store.getState().wrappedTokensStat;

	const summedCollateral: Array<CollateralItem> = sumCollaterals(stat.collaterals, token.collateral);

	stat = {
		count      : stat.count + 1,
		collaterals: summedCollateral
	}

	store.dispatch(wrappedStatsUpdate(stat));
	return stat;
}
export const sumCollaterals = (statCollaterals: Array<CollateralItem>, tokenCollateral: Array<CollateralItem>): Array<CollateralItem> => {
	let output: Array<CollateralItem> = statCollaterals;

	tokenCollateral.forEach((item: CollateralItem) => {

		if ( item.assetType === _AssetType.empty ) { return; }

		if ( item.assetType === _AssetType.native || item.assetType === _AssetType.ERC20 ) {

			let amountToAdd = item.amount || new BigNumber(0);

			const foundAdded = output.find((iitem: CollateralItem) => { return item.address.toLowerCase() === iitem.address.toLowerCase() });
			if ( foundAdded && foundAdded.amount ) {
				amountToAdd = amountToAdd.plus(foundAdded.amount)
			};

			output = [
				...output.filter((iitem) => { return item.address.toLowerCase() !== iitem.address.toLowerCase() }),
				{
					...item,
					amount: amountToAdd,
				}
			];
		}
		if ( item.assetType === _AssetType.ERC721 ) {

			let amountToAdd = new BigNumber(1);

			const foundAdded = output.find((iitem: CollateralItem) => { return item.address.toLowerCase() === iitem.address.toLowerCase() });
			if ( foundAdded && foundAdded.amount ) {
				amountToAdd = amountToAdd.plus(foundAdded.amount)
			};

			output = [
				...output.filter((iitem) => { return item.address.toLowerCase() !== iitem.address.toLowerCase() }),
				{
					...item,
					amount: amountToAdd,
				}
			];
		}
		if ( item.assetType === _AssetType.ERC1155 ) {

			let amountToAdd = item.amount || new BigNumber(1);

			const foundAdded = output.find((iitem: CollateralItem) => { return item.address.toLowerCase() === iitem.address.toLowerCase() });
			if ( foundAdded && foundAdded.amount ) {
				amountToAdd = amountToAdd.plus(foundAdded.amount)
			};

			output = [
				...output.filter((iitem) => { return item.address.toLowerCase() !== iitem.address.toLowerCase() }),
				{
					...item,
					amount: amountToAdd,
				}
			];
		}

	});

	return output;
}
export const fillCollateralsImages = async (metamaskAdapter: MetamaskAdapter, token: WrappedTokenType, userAddress: string): Promise<WrappedTokenType> => {
	const collateralUpdated = await Promise.all(token.collateral.map(async (item) => {
		if ( !item.address || item.address === '0' || item.address === '0x0000000000000000000000000000000000000000' || !item.tokenId ) { return item; }

		if ( item.assetType === _AssetType.ERC20 ) {
			if ( !metamaskAdapter.getERC20Contract(item.address) ) { await metamaskAdapter.addERC20Contracts(item.address) }
		}

		let tokenUrl;
		let tokenImg;
		let tokenImgRaw;

		if ( metamaskAdapter.wrapperContract.addressIsStorage(item.address) ) {
			const token = await fetchWrappedTokenById({
				chainId: metamaskAdapter.chainId || 0,
				contractAddress: item.address,
				tokenId: item.tokenId || '',
				assetType: item.assetType,
			});
			if ( token && token.tokenUrl ) {
				tokenUrl    = processSwarmUrl(token.tokenUrl);
			}
		}

		if ( tokenUrl ) {
			try {
				const resp = await fetch(tokenUrl);
				if ( resp && resp.ok ) {
					const respParsed = await resp.json();
					if ( 'image' in respParsed ) {
						tokenImg = processSwarmUrl(respParsed.image)
						tokenImgRaw = respParsed.image
					}
					if ( 'image_url' in respParsed ) {
						tokenImg = processSwarmUrl(respParsed.image_url)
						tokenImgRaw = respParsed.image_url
					}
				}
			} catch(e) {
				console.log('Cannot load tokenurl of collateral item', e);
			}
		}

		if ( !tokenImg ) {
			if ( item.assetType === _AssetType.ERC721 ) {
				const tokenParsed = await getOriginalToken({
					metamaskAdapter: metamaskAdapter,
					contractAddress: item.address,
					tokenId: item.tokenId,
					assetType: _AssetType.ERC721,
					userAddress: userAddress,
				});
				tokenImg = ''
				if ( tokenParsed.image     ) { tokenImg = processSwarmUrl(tokenParsed.image    ); tokenImgRaw = tokenParsed.image     };
				if ( tokenParsed.image_url ) { tokenImg = processSwarmUrl(tokenParsed.image_url); tokenImgRaw = tokenParsed.image_url };
				return {
					...item,
					tokenImg: tokenImg,
					tokenImgRaw: tokenImgRaw,
				};
			}

			if ( item.assetType === _AssetType.ERC1155 ) {
				if ( !item.address || item.address === '0' || item.address === '0x0000000000000000000000000000000000000000' || !item.tokenId ) { return item; }
				const tokenParsed = await getOriginalToken({
					metamaskAdapter: metamaskAdapter,
					contractAddress: item.address,
					tokenId: item.tokenId,
					userAddress: userAddress,
					assetType: _AssetType.ERC1155
				});
				tokenImg = ''
				if ( tokenParsed.image     ) { tokenImg = processSwarmUrl(tokenParsed.image    ); tokenImgRaw = tokenParsed.image     };
				if ( tokenParsed.image_url ) { tokenImg = processSwarmUrl(tokenParsed.image_url); tokenImgRaw = tokenParsed.image_url };
				return {
					...item,
					tokenImg: tokenImg,
					tokenImgRaw: tokenImgRaw,
				};
			}
		}

		return {
			...item,
			tokenImg: tokenImg,
			tokenImgRaw: tokenImgRaw,
		};
	}));

	return {
		...token,
		collateral: collateralUpdated,
	}
}

export default class WNFTStorageContract {

	web3            : Web3;
	metamaskAdapter : MetamaskAdapter;
	store           : any;
	chainId         : number;
	contractAddress : string;
	contractStandart: _AssetType;
	userAddress     : string;
	contract        : Contract;
	wrapperContract?: string;

	constructor(props: WNFTStorageContractPropsType) {
		this.web3             = props.web3;
		this.metamaskAdapter  = props.metamaskAdapter;
		this.store            = props.store;
		this.chainId          = props.chainId;
		this.contractAddress  = props.contractAddress;
		this.contractStandart = props.contractStandart;
		this.userAddress      = props.userAddress;

		let wNFTStorageABI;
		try {
			wNFTStorageABI = getABI(this.chainId, this.contractAddress, 'storage');
		} catch(e) {
			console.log(`Cannot load ${this.contractAddress} storage abi:`, e);
			throw new Error(`Cannot load storage abi`);
		}
		this.contract = new this.web3.eth.Contract(wNFTStorageABI, this.contractAddress);
		this.store.dispatch(addWNFTStorage({ address: this.contractAddress, standart: _AssetType[this.contractStandart] }))

		this.getWrapperContract();
	}
	getWrapperContract() {
		try {
			if ( this.contractStandart === _AssetType.ERC1155 ) {
				this.contract.methods.wrapper().call()
					.then((data: string) => {
						try {
							if ( data.toLowerCase() !== this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() ) {
								this.wrapperContract = data;
								this.metamaskAdapter.wrapperContract.addTechTokenContract({ wrapperAddress: data });
							}
						} catch(e) { console.log('Cannot get wrapper', this.contractAddress); }
					})
			}

			if ( this.contractStandart === _AssetType.ERC721 ) {

				this.contract.methods.wrapperMinter().call()
					.then((data: string) => {
						try {
							this.wrapperContract = data;
							if ( data.toLowerCase() !== this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() ) {
								this.metamaskAdapter.wrapperContract.addTechTokenContract({ wrapperAddress: data });
							}
						} catch(e) { console.log('Cannot get wrapper', this.contractAddress); }
					})
			}
		} catch(e) { console.log('Cannot get wrapper', this.contractAddress); }
	}

	async getNFTTokenById(tokenId: string): Promise<WrappedTokenType> {

		let tokenUrl;
		let tokenUrlRaw;
		let owner   = undefined;
		let amount = undefined;
		let totalSupply = undefined;
		if ( this.contractStandart === _AssetType.ERC721 ) {
			tokenUrlRaw = await this.contract.methods.tokenURI(tokenId).call();
			tokenUrl    = processSwarmUrl(tokenUrlRaw);
			owner       = await this.contract.methods.ownerOf(tokenId).call()
		} else {
			tokenUrlRaw = await this.contract.methods.uri(tokenId).call();
			tokenUrl    = processSwarmUrl(tokenUrlRaw);
			try {
				amount = new BigNumber(await this.contract.methods.balanceOf(this.userAddress, tokenId).call());
			} catch(e) { console.log(`Cannot get amount of token ${tokenId}`) }
			try {
				totalSupply = new BigNumber(await this.contract.methods.totalSupply(tokenId).call());
			} catch(e) { console.log(`Cannot get amount of token ${tokenId}`) }
		}

		let tokenUrlRes;
		let tokenUrlText;
		try {
			tokenUrlRes  = await fetch(tokenUrl);
			tokenUrlText = await tokenUrlRes.text();
		} catch(e) {
			console.log('Cannot fetch tokenUrl', e);
		}

		const wrappedToken = await this.contract.methods.wnftInfo(tokenId).call();

		let wrappedTokenParsed = decodeWrappedToken({
			inWNFT         : wrappedToken,
			owner          : owner,
			chainId        : this.chainId,
			contractAddress: this.contractAddress,
			tokenId        : tokenId,
			assetType      : this.contractStandart,
			amount         : amount,
			totalSupply    : totalSupply,
			tokenUrl       : tokenUrl,
			tokenUrlRaw    : tokenUrlRaw,
			tokenUrlRawJSON: tokenUrlText,
		});
		wrappedTokenParsed = await fillCollateralsImages(this.metamaskAdapter, wrappedTokenParsed, this.userAddress);
		calcTokenStats(wrappedTokenParsed, this.store);

		// console.log('raw', wrappedToken);
		// console.log('parsed', wrappedTokenParsed);

		if ( wrappedTokenParsed.originalTokenInfo.assetType === _AssetType.ERC721 ) {
			const contract = new this.metamaskAdapter.web3.eth.Contract(erc721_abi as any, wrappedTokenParsed.originalTokenInfo.contractAddress);
			try {
				const originalTokenName = await contract.methods.name().call();
				wrappedTokenParsed = {
					...wrappedTokenParsed,
					originalTokenInfo: {
						...wrappedTokenParsed.originalTokenInfo,
						name: originalTokenName
					}
				}
			} catch(ignored) {}
		}

		return wrappedTokenParsed;
	}

	async getNFTs() {
		if ( this.contractStandart === _AssetType.ERC1155 ) { return; }
		const balance = await this.contract.methods.balanceOf( this.userAddress ).call();

		for (let idx = balance - 1; idx >= 0; idx--) {
			const contractTokenId = await this.contract.methods.tokenOfOwnerByIndex(this.userAddress, idx).call();
			const token = await this.getNFTTokenById(contractTokenId);

			if ( token.owner && token.owner.toLowerCase() === this.userAddress.toLowerCase() ) {
				this.store.dispatch(wrappedTokensAdd(token));
			}
		};
	}
	async getBalance() {
		if ( this.contractStandart === _AssetType.ERC1155 ) { return; }
		return await this.contract.methods.balanceOf(this.userAddress).call();
	}

	transferTokenSuccess(data: any, params: {
		token: WrappedTokenType,
		addressTo: string,
		amount: BigNumber,
		t: any
	}) {
		this.store.dispatch(unsetLoading());
		this.metamaskAdapter.updateAllBalances();

		this.store.dispatch(setInfo({
			text: `${params.t('Your token has been transferred')} (${params.addressTo})`,
			 buttons: [{
				text: 'Ok',
				clickFunc: () => { this.store.dispatch(clearInfo()) }
			 }],
			links: [{
				text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
				url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
			}]
		}));
	}
	transferTokenError(e: any, params: {
		token: WrappedTokenType,
		addressTo: string,
		amount: BigNumber,
		t: any
	}) {
		console.log(e);
		this.store.dispatch(unsetLoading());

		let errorMsg = '';
		try {
			if ( 'message' in e ) {
				errorMsg = e.message;
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
			} else {
				errorMsg = `${e}`;
			}
		} catch (ignored) {
			errorMsg = `${e}`;
		}

		let links = undefined;
		try {
			if ('transactionHash' in e) {
				links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
			} else {
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					const txHash = errorParsed.transactionHash;
					if ( txHash ) {
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					}
				} catch(ignored) {}
			}
		} catch (ignored) {}

		this.store.dispatch(setError({
			text: `${params.t('Cannot wrap token')}: ${errorMsg}`,
			buttons: undefined,
			links: links,
		}));
	}
	async transferToken(params: {
		token: WrappedTokenType,
		addressTo: string,
		amount: BigNumber,
		t: any
	}) {

		let techToken = undefined;
		if ( params.token.fees && params.token.fees.length ) {
			techToken = this.metamaskAdapter.wrapperContract.getTechTokenContract(params.token.fees[0].token);
		}

		if (
			params.token.fees.length &&
			!techToken
		) {


			const foundToken = this.metamaskAdapter.getERC20Contract(params.token.fees[0].token);
			if ( !foundToken ) { return; }

			const balance = await foundToken.getBalance(this.wrapperContract);

			if ( balance.balance.lt(new BigNumber(params.token.fees[0].value)) ) {
				this.store.dispatch(setError({ text: `Not enough ${foundToken.erc20Params.symbol}`, buttons: undefined, links: undefined }));
				return;
			}
			if ( balance.allowance.lt(new BigNumber(params.token.fees[0].value)) ) {
				this.store.dispatch(setLoading({ msg: `${params.t('Waiting for approve')} ${foundToken.erc20Params.symbol}. ${params.t('Please, give permission smart contract to spend your tokens for wNFT transfer. Push approve button')}` }));
				console.log(`making allowance for ${foundToken.erc20Params.symbol}`)
				try {
					await foundToken.makeAllowance(params.token.fees[0].value, this.wrapperContract);
				} catch(e: any) {
					console.log('Transfer fee approve failed', e);
					this.store.dispatch(unsetLoading());
					this.store.dispatch(setError({
						text: `${params.t('Cannot approve token')} ${foundToken.erc20Params.symbol}: ${e.message}`,
						buttons: undefined,
						links: undefined,
					}));
					throw e;
				}
				this.store.dispatch(unsetLoading());
			}
		}

		let tx;
		this.store.dispatch(setLoading({ msg: `${params.t('Waiting for transfer')}. ${params.t('After this action you will not own this wrapped NFT')}` }));

		if ( params.token.assetType === _AssetType.ERC1155 ) {
			tx = this.contract.methods.safeTransferFrom(
				this.userAddress,
				params.addressTo,
				params.token.tokenId,
				params.amount.toString(),
				'0x0'
			)
		} else {
			tx = this.contract.methods.transferFrom(
				this.userAddress,
				params.addressTo,
				params.token.tokenId
			)
		}

		// pre-send transaction check
		try {
			await tx.estimateGas({ from: this.userAddress })
		} catch(e: any) {
			try {
				this.store.dispatch(unsetLoading());

				console.log('Cannot transfer before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				const errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
				this.store.dispatch(setError({
					text: `${params.t('Cannot transfer token')}: ${errMsg}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			} catch(ignored) {}
		}

		let data;
		try {
			data = await tx.send({ from: this.userAddress })
		} catch (e: any) {
			this.transferTokenError(e, params);
			return;
		}
		this.transferTokenSuccess(data, params)
	}
	async transferTokenSubmitMultisig(tx: any) {

		let estimatedGas: BigNumber | undefined;
		// pre-send transaction check
		try {
			const estimatedGasRaw = await tx.estimateGas({ from: this.userAddress });
			console.log('estimatedGasRaw', estimatedGasRaw);
			estimatedGas = new BigNumber(estimatedGasRaw);
			console.log('estimatedGas', estimatedGas.toString());
			estimatedGas = estimatedGas.plus(20000);
			console.log('estimatedGasAdded', estimatedGas.toString());
		} catch(e: any) {
			try {
				console.log('Cannot transfer before send: ', e);
				this.store.dispatch(setError({
					text: `Cannot transfer token: ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				this.store.dispatch(unsetLoading());
				return;
			} catch(ignored) {}
		}

		if ( estimatedGas && !estimatedGas.isNaN() ) {
			return new Promise((res, rej) => {
				tx
					.send({ from: this.userAddress, gas: estimatedGas }, (err: any, data: any) => {
						if ( err ) { rej(err); }
						res(data);
					});
			})
		} else {
			return new Promise((res, rej) => {
				tx
					.send({ from: this.userAddress }, (err: any, data: any) => {
						if ( err ) { rej(err); }
						res(data);
					});
			})
		}
	}
	async transferTokenMultisig(params: {
		token: WrappedTokenType,
		addressTo: string,
		amount: BigNumber,
		t: any
	}) {

		let techToken = undefined;
		if ( params.token.fees && params.token.fees.length ) {
			techToken = this.metamaskAdapter.wrapperContract.getTechTokenContract(params.token.fees[0].token);
		}

		if (
			params.token.fees.length &&
			!techToken
		) {


			const foundToken = this.metamaskAdapter.getERC20Contract(params.token.fees[0].token);
			if ( !foundToken ) { return; }

			const balance = await foundToken.getBalance(this.wrapperContract);

			if ( balance.balance.lt(new BigNumber(params.token.fees[0].value)) ) {
				this.store.dispatch(setError({ text: `Not enough ${foundToken.erc20Params.symbol}`, buttons: undefined, links: undefined }));
				return;
			}
			if ( balance.allowance.lt(new BigNumber(params.token.fees[0].value)) ) {
				this.store.dispatch(setLoading({ msg: `${params.t('Waiting for approve')} ${foundToken.erc20Params.symbol}. ${params.t('Please, give permission smart contract to spend your tokens for wNFT transfer. Push approve button')}` }));
				console.log(`making allowance for ${foundToken.erc20Params.symbol}`)
				try {
					await foundToken.makeAllowanceMultisig(params.token.fees[0].value, this.wrapperContract);
				} catch(e: any) {
					console.log('Transfer fee approve failed', e);
					this.store.dispatch(unsetLoading());
					this.store.dispatch(setError({
						text: `${params.t('Cannot approve token')} ${foundToken.erc20Params.symbol}: ${e.message}`,
						buttons: undefined,
						links: undefined,
					}));
					throw e;
				}
				this.store.dispatch(unsetLoading());
			}
		}

		let tx;
		this.store.dispatch(setLoading({ msg: `${params.t('Waiting for transfer')}. ${params.t('After this action you will not own this wrapped NFT')}` }));

		if ( params.token.assetType === _AssetType.ERC1155 ) {
			tx = this.contract.methods.safeTransferFrom(
				this.userAddress,
				params.addressTo,
				params.token.tokenId,
				params.amount.toString(),
				'0x0'
			)
		} else {
			tx = this.contract.methods.transferFrom(
				this.userAddress,
				params.addressTo,
				params.token.tokenId
			)
		}

		let wrapTx;
		try {
			wrapTx = await this.transferTokenSubmitMultisig(tx)
		} catch(e: any) {
			console.log('Cannot transfer after send: ', e);
			this.store.dispatch(setError({
				text: `Cannot transfer token: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}

		this.store.dispatch(unsetLoading());
		this.metamaskAdapter.updateAllBalances();
		this.store.dispatch(setInfo({
			text: 'Transfer transaction has been created',
			 buttons: [{
				text: 'Ok',
				clickFunc: () => {
					this.store.dispatch(clearInfo());
				}
			 }],
			links: [{
				text: `View wrap tx on ${this.metamaskAdapter.chainConfig.explorerName}`,
				url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${wrapTx}`
			}]
		}));
	}
	async approveToken(token: WrappedTokenType, addressTo: string) {
		if ( token.assetType === _AssetType.ERC1155 ) { throw new Error('Cannot approve one 1155 token') }

		const tx = this.contract.methods.approve(addressTo, token.tokenId)
		// pre-send transaction check
		try {
			await tx.estimateGas({ from: this.userAddress })
		} catch(e: any) {
			try {
				this.store.dispatch(unsetLoading());

				console.log('Cannot transfer before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				const errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
				this.store.dispatch(setError({
					text: `Cannot approve token: ${errMsg}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			} catch(ignored) {}
		}

		return tx.send({ from: this.userAddress });
	}
	async approveAllTokens(addressTo: string) {

		const tx = this.contract.methods.setApprovalForAll(addressTo, true)
		// pre-send transaction check
		try {
			await tx.estimateGas({ from: this.userAddress })
		} catch(e: any) {
			try {
				this.store.dispatch(unsetLoading());

				console.log('Cannot transfer before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				const errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
				this.store.dispatch(setError({
					text: `Cannot approve token: ${errMsg}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			} catch(ignored) {}
		}

		return tx.send({ from: this.userAddress });
	}

	async approveTokenMultisig(token: WrappedTokenType, addressTo: string) {
		return new Promise((res, rej) => {
			if ( token.assetType === _AssetType.ERC1155 ) { rej(new Error('Cannot approve one 1155 token')) }

			const tx = this.contract.methods.approve(addressTo, token.tokenId)

			tx.send({ from: this.userAddress }, (err: any, data: any) => {
				if ( err ) { rej(err); }
				res(data);
			});
		});
	}
	async approveAllTokensMultisig(addressTo: string) {
		return new Promise((res, rej) => {
			const tx = this.contract.methods.setApprovalForAll(addressTo, true)
			tx.send({ from: this.userAddress }, (err: any, data: any) => {
				if ( err ) { rej(err); }
				res(data);
			});
		});
	}

	// addCheckoutEventListener() {
	// 	this.contract.events.Approval(
	// 		{
	// 			fromBlock: 'earliest',
	// 			filter: { owner: this.userAddress }
	// 		},
	// 		(e: any, data: any) => {
	// 			this.getBalance();
	// 		}
	// 	);
	// 	this.contract.events.Transfer(
	// 		{
	// 			fromBlock: 'earliest',
	// 			filter: { from: this.userAddress }
	// 		},
	// 		(e: any, data: any) => {
	// 			this.getBalance();
	// 		}
	// 	);
	// 	this.contract.events.Transfer(
	// 		{
	// 			fromBlock: 'earliest',
	// 			filter: { to: this.userAddress }
	// 		},
	// 		(e: any, data: any) => {
	// 			this.getBalance();
	// 		}
	// 	);
	// }

}