import React               from 'react';
import Tippy               from '@tippyjs/react';
import {
	CollateralItem,
	ERC20ContractParamsType,
	MetamaskAdapter,
	WrappedTokenType,
	_Asset,
	_AssetType,
	Lock,
	LockType,
} from '../../models/BlockchainAdapter';

import {
	addThousandSeparator,
	removeThousandSeparator,
	tokenToFloat,
	tokenToInt
} from '../../models/_utils';
import { withTranslation } from "react-i18next";
import CoinSelector from '../CoinSelector';
import CollateralViewer from '../CollateralViewer';
import { getOriginalToken } from '../../models/TokenFetchWrapper/tokenfetchwrapper';

import icon_attention        from '../../static/pics/icons/i-attention.svg';
import icon_external         from '../../static/pics/icons/i-external.svg';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type AddValuePopupProps = {
	store          : any,
	metamaskAdapter: MetamaskAdapter,
	t              : any,
	token          : WrappedTokenType,
	closePopup     : Function,
}
type AddValuePopupState = {

	addValueToken?    : WrappedTokenType,
	addValueValue     : string,
	addValueERC20Token: ERC20ContractParamsType | undefined,

	userAddress       : string,
	EIPPrefix         : string,
	authMethod        : string,

	balanceNative     : BigNumber,
	decimalsNative    : number,
	symbolNative      : string,
	iconNative        : string,
	networkTokenTicket: string,

	inputassetType        : _AssetType,
	inputCollateralAddress: string,
	inputCollateralTokenId: string,
	inputCollateralAmount : string,
	collaterals           : Array<CollateralItem>,
	maxCollaterals        : number,
	collateralErrors      : Array<{
		address: string,
		tokenId?: string,
		msg: string
	}>

	collateralWhitelist  : Array<_Asset> | undefined,

	explorerBaseUrl      : string,
	erc20CollateralTokens: Array<ERC20ContractParamsType>,
	erc20OtherTechTokens : Array<ERC20ContractParamsType>,
	techToken            : ERC20ContractParamsType,
	wrapperContract      : string,
	erc20Balance?        : {
		balance: BigNumber,
		allowance: BigNumber,
	},

	niftsyTokenIcon      : string,
	v0AppLink            : string,
}

class AddValuePopup extends React.Component<AddValuePopupProps, AddValuePopupState> {

	store          : any;
	unsubscribe!   : Function;
	metamaskAdapter: MetamaskAdapter;
	t              : any;
	closePopup     : Function;

	constructor(props: AddValuePopupProps) {
		super(props);

		this.store           = props.store;
		this.metamaskAdapter = props.metamaskAdapter;
		this.t               = props.t;
		this.closePopup      = props.closePopup;

		this.state = {
			addValueToken     : props.token,
			addValueValue     : '',
			addValueERC20Token: undefined,
			balanceNative     : this.store.getState().account.balanceNative,
			decimalsNative    : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative      : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative        : this.store.getState().metamaskAdapter.networkTokenIcon,
			networkTokenTicket: this.store.getState().metamaskAdapter.networkTokenTicket,
			explorerBaseUrl   : this.store.getState().metamaskAdapter.explorerBaseUrl,
			userAddress       : this.store.getState().account.address || '',
			EIPPrefix         : this.store.getState().metamaskAdapter.EIPPrefix,
			authMethod        : this.store.getState().metamaskAdapter.authMethod || 'metamask',

			collaterals           : [],
			inputassetType        : _AssetType.native,
			inputCollateralAddress: '',
			inputCollateralTokenId: '',
			inputCollateralAmount : '',
			maxCollaterals        : this.store.getState().metamaskAdapter.maxCollaterals || 0,
			collateralErrors      : [],

			collateralWhitelist  : this.store.getState().collateralWhitelist,

			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
			erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
			techToken            : this.store.getState().erc20TechTokenParams,
			wrapperContract      : this.store.getState().metamaskAdapter.wrapperContract,

			niftsyTokenIcon      : 'https://envelop.is/assets/img/niftsy.svg',

			v0AppLink            : 'https://appv0.envelop.is',
		}
	}

	componentDidMount() {

		this.unsubscribe = this.store.subscribe(() => {

			// need for update allowance after approve
			let addValueERC20TokenUpdate = this.state.addValueERC20Token;
			if ( this.state.addValueERC20Token ) {
				const tokenFound = this.store.getState().erc20CollateralTokens.filter((item: ERC20ContractParamsType) => {
					if ( !item.address || !this.state.addValueERC20Token ) { return false; }
					return item.address.toLowerCase() === this.state.addValueERC20Token.address.toLowerCase()
				});
				if ( tokenFound.length ) { addValueERC20TokenUpdate = tokenFound[0] }
			}

			this.setState({
				balanceNative        : this.store.getState().account.balanceNative,
				decimalsNative       : this.store.getState().metamaskAdapter.networkTokenDecimals,
				iconNative           : this.store.getState().metamaskAdapter.networkTokenIcon,
				symbolNative         : this.store.getState().metamaskAdapter.networkTokenTicket,
				explorerBaseUrl      : this.store.getState().metamaskAdapter.explorerBaseUrl,
				addValueERC20Token   : addValueERC20TokenUpdate,
				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
				erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
				techToken            : this.store.getState().erc20TechTokenParams,
				collateralWhitelist  : this.store.getState().collateralWhitelist,
				userAddress          : this.store.getState().account.address || '',
				EIPPrefix            : this.store.getState().metamaskAdapter.EIPPrefix,
				authMethod           : this.store.getState().metamaskAdapter.authMethod || 'metamask',
				wrapperContract      : this.store.getState().metamaskAdapter.wrapperContract,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	filterERC20ContractByPermissions(permissions: {
		enabledForCollateral?        : boolean,
		enabledForFee?               : boolean,
		enabledRemoveFromCollateral? : boolean,
	}): Array<ERC20ContractParamsType> {
		const tokensToSearch = [
			this.state.techToken,
			...this.state.erc20CollateralTokens,
			...this.state.erc20OtherTechTokens,
		];

		// OR LOGIC
		return tokensToSearch.filter((item) => {
			if ( permissions.enabledForCollateral        && item.permissions && item.permissions.enabledForCollateral        ) { return true; }
			if ( permissions.enabledForFee               && item.permissions && item.permissions.enabledForFee               ) { return true; }
			if ( permissions.enabledRemoveFromCollateral && item.permissions && item.permissions.enabledRemoveFromCollateral ) { return true; }

			return false;
		})
	}
	getAddValueTokenSelector() {
		return (
			<CoinSelector
				store         = { this.store }
				tokens        = { this.filterERC20ContractByPermissions({ enabledForCollateral: true }) }
				selectedToken = { this.state.addValueERC20Token ? this.state.addValueERC20Token.address : '0x0000000000000000000000000000000000000000' }
				onChange      = {(address: string) => {
					if ( !address || address === '0x0000000000000000000000000000000000000000' ) {
						this.setState({ addValueERC20Token: undefined });
						return;
					}

					if ( address.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
						this.setState({ addValueERC20Token: this.state.techToken });
						return;
					}

					const techTokenToAdd = this.state.erc20OtherTechTokens.find((item) => {
						if ( !item.address ) { return false; }
						return item.address.toLowerCase() === address.toLowerCase();
					});
					if ( techTokenToAdd ) {
						this.setState({ addValueERC20Token: techTokenToAdd });
						return;
					}

					const tokenToAdd = this.state.erc20CollateralTokens.find((item) => {
						if ( !item.address ) { return false; }
						return item.address.toLowerCase() === address.toLowerCase();
					});
					if ( tokenToAdd ) {
						this.setState({ addValueERC20Token: tokenToAdd })
						return;
					}

					this.setState({ addValueERC20Token: undefined });
				}}
			/>
		)
	}
	getERC20Link() {
		if ( this.state.inputCollateralAddress === '' ) { return null; }

		let foundToken: ERC20ContractParamsType | undefined = undefined;
		if ( this.state.inputCollateralAddress.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
			foundToken = this.state.techToken;
		} else {
			foundToken = this.state.erc20OtherTechTokens.find((item) => { return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
			if ( !foundToken ) {
				foundToken = this.state.erc20CollateralTokens.find((item) => { return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
			}
		}
		if ( !foundToken ) { return null; }

		let tokenImage = '';
		if ( foundToken.name.toLowerCase() === 'niftsy' ) {
			tokenImage = this.state.niftsyTokenIcon
		}

		return (
			<div className="d-flex align-items-center flex-wrap">
				<a
					className="ex-link mr-3 mt-3 mb-3"
					target="_blank"
					rel="noopener noreferrer"
					href={ `${this.state.explorerBaseUrl}/token/${foundToken.address}` }
				>
					<small>More about { foundToken.symbol }</small>
					<img className="i-ex" src={ icon_external } alt="" />
				</a>
				<a
					className="btn btn-sm btn-gray"
					href="/"
					onClick={(e) => {
						e.preventDefault();
						if ( !foundToken ) { return; }
						try {
							if ( !(window as any).ethereum ) { return; }

							(window as any).ethereum.request({
								method: 'wallet_watchAsset',
								params: {
									type: 'ERC20', // Initially only supports ERC20, but eventually more!
									options: {
										address: foundToken.address, // The address that the token is at.
										symbol: foundToken.symbol, // A ticker symbol or shorthand, up to 5 chars.
										decimals: foundToken.decimals, // The number of decimals in the token
										image: tokenImage, // A string url of the token logo
									},
								},
							});
						} catch(e) {
							console.log(e)
						}
					}}
				>
					Add to Metamask
				</a>
			</div>
		)
	}
	getAddValueBtn() {

		return (
			<div className="d-flex justify-content-center pt-4">
				<button
					className="btn btn-lg w-100"
					disabled={
						!this.state.collaterals.length ||
						!!this.state.collateralErrors.length
					}
					onClick={() => {
						if ( !this.state.addValueToken ) { return; }
						if ( this.state.authMethod === 'gnosis' ) {
							this.metamaskAdapter.wrapperContract.addCollateralMultisig({
								token: this.state.addValueToken,
								collaterals: this.state.collaterals,
								callback: () => { this.closePopup(); }
							})
						} else {
							this.metamaskAdapter.wrapperContract.addCollateral({
								token: this.state.addValueToken,
								collaterals: this.state.collaterals,
								callback: () => { this.closePopup(); }
							})
						}
					}}
				>Confirm</button>
			</div>
		)
	}

	isNativeBalanceEnough() {
		const amountParsed = new BigNumber(this.state.inputCollateralAmount);
		if ( !amountParsed || amountParsed.isNaN() || amountParsed.eq(0) ) { return true; }

		if ( tokenToInt(amountParsed, this.state.decimalsNative || 18).lte(this.state.balanceNative) ) { return true; }

		return false;
	}
	isCollateralNativeBtnDisabled() {
		if ( this.state.inputCollateralAmount === '' ) { return true; }

		const amountParsed = new BigNumber(this.state.inputCollateralAmount);
		if ( !amountParsed || amountParsed.isNaN() || amountParsed.eq(0) ) { return true; }

		if ( !this.isNativeBalanceEnough() ) { return true; }

		let slots = new BigNumber(this.state.maxCollaterals);
		const foundLock = this.props.token.locks.find((item: Lock) => { return item.lockType === LockType.slots });
		if ( foundLock ) {
			slots = foundLock.param
		}

		if ( slots.gt(this.props.token.collateral.length + this.state.collaterals.length) ) { return false; }

		const foundExistindCollateral = this.props.token.collateral.find((item: CollateralItem) => { return item.assetType === _AssetType.native });
		if ( foundExistindCollateral ) { return false; }

		const foundAddingCollateral = this.state.collaterals.find((item: CollateralItem) => { return item.assetType === _AssetType.native });
		if ( foundAddingCollateral ) { return false; }

		return true;
	}
	addCollateralNativeRow() {
		let amountNative = new BigNumber(0);
		const nativeAdded = this.state.collaterals.filter((item) => { return item.assetType === _AssetType.native });
		if ( nativeAdded.length && nativeAdded[0].amount ) {
			amountNative = new BigNumber(nativeAdded[0].amount);
		}
		const collateralsUpdated = [
			...this.state.collaterals.filter((item) => { return item.assetType !== _AssetType.native }),
			{
				assetType: _AssetType.native,
				address: '0x0000000000000000000000000000000000000000',
				amount: tokenToInt(new BigNumber(this.state.inputCollateralAmount).plus(amountNative), this.state.decimalsNative || 18),
			}
		];
		this.setState({
			collaterals: collateralsUpdated,
			inputCollateralAmount: '',
		})
	}
	getAddCollateralNativeBtn() {
		return (
			<button
				className="btn btn-grad"
				disabled={ this.isCollateralNativeBtnDisabled() }
				onClick={() => { this.addCollateralNativeRow() }}
			>Add</button>
		)
	}
	getCollateralNativeBalance() {
		return (
			<div className="c-add__max mt-3">
				<div>
					<span>Max: </span>
					<button
						onClick={() => { this.setState({ inputCollateralAmount: tokenToFloat(this.state.balanceNative, this.state.decimalsNative).toString() }) }}
					>{ tokenToFloat(this.state.balanceNative, this.state.decimalsNative).toFixed(5) }</button>
				</div>
			</div>
		)
	}
	getNativeAmountInput() {
		if ( this.isNativeBalanceEnough() ) {
			return (
				<React.Fragment>
					<div className="select-group">
						<input
							className="input-control"
							type="text"
							placeholder="0.000"
							value={ addThousandSeparator(this.state.inputCollateralAmount) }
							onChange={(e) => {
								let value = removeThousandSeparator(e.target.value);
								if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
									if ( new BigNumber(value).isNaN() ) { return; }
									value = new BigNumber(value).toString();
								}
								this.setState({ inputCollateralAmount: value, })
							}}
							onKeyPress={(e) => {
								if ( e.defaultPrevented)                      { return; }
								if ( !!this.isCollateralNativeBtnDisabled() ) { return; }
								if ( e.key !== 'Enter' )                      { return; }

								this.addCollateralNativeRow()
							}}
						/>
						<div className="select-coin">
							<div className="select-coin__value">
								<span className="field-unit">
									<span className="i-coin">
										<img src={ this.state.iconNative } alt="" />
									</span>
									{ this.state.symbolNative }
								</span>
							</div>
						</div>
					</div>
				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment>
					<div className="select-group">
						<input
							className="input-control has-error"
							type="text"
							placeholder="0.000"
							value={ addThousandSeparator(this.state.inputCollateralAmount) }
							onChange={(e) => {
								let value = removeThousandSeparator(e.target.value);
								if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
									if ( new BigNumber(value).isNaN() ) { return; }
									value = new BigNumber(value).toString();
								}
								this.setState({ inputCollateralAmount: value, })
							}}
							onKeyPress={(e) => {
								if ( e.defaultPrevented)                      { return; }
								if ( !!this.isCollateralNativeBtnDisabled() ) { return; }
								if ( e.key !== 'Enter' )                      { return; }

								this.addCollateralNativeRow()
							}}
						/>
						<div className="select-coin">
							<div className="select-coin__value">
								<span className="field-unit">
									<span className="i-coin">
										<img src={ this.state.iconNative } alt="" />
									</span>
									{ this.state.symbolNative }
								</span>
							</div>
						</div>
					</div>
					<div className="input-error">Not enough balance</div>
				</React.Fragment>
			)
		}
	}
	getCollateralNativeSlotsAlert() {
		let slots = new BigNumber(this.state.maxCollaterals);
		const foundLock = this.props.token.locks.find((item: Lock) => { return item.lockType === LockType.slots });
		if ( foundLock ) {
			slots = foundLock.param
		}

		if ( slots.gt(this.props.token.collateral.length + this.state.collaterals.length) ) { return null; }

		const addressToFind = '0x0000000000000000000000000000000000000000';

		const foundExistindCollateral = this.props.token.collateral.find((item: CollateralItem) => { return item.address.toLowerCase() === addressToFind });
		if ( foundExistindCollateral ) { return null; }

		const foundAddingCollateral = this.state.collaterals.find((item: CollateralItem) => { return item.address.toLowerCase() === addressToFind });
		if ( foundAddingCollateral ) { return null; }

		return ( <div className="alert alert-warning mb-3">You have reached the maximum number of collateral slots</div> );
	}
	getCollateralNativeBlock() {
		if ( this.state.inputassetType === _AssetType.native ) {
			return (
				<React.Fragment>
					{ this.getCollateralNativeSlotsAlert() }
					<div className="row">
						<div className="col col-12 col-md-7">
							<label className="input-label">Amount</label>
							{ this.getNativeAmountInput() }
							{ this.getCollateralNativeBalance() }
						</div>
						<div className="col col-12 col-md-2">
							<label className="input-label">&nbsp;</label>
							{ this.getAddCollateralNativeBtn() }
						</div>
					</div>
				</React.Fragment>
			)
		}
	}

	isERC20BalanceEnough() {
		const amountParsed = new BigNumber(this.state.inputCollateralAmount);
		const foundToken = this.state.erc20CollateralTokens.find((item) => { return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
		if ( foundToken && foundToken.balance.lt(tokenToInt(amountParsed, foundToken.decimals || 18)) ) { return false; }

		return true;
	}
	isCollateralERC20BtnDisabled() {
		if ( this.state.inputCollateralAddress === '' ) { return true; }
		if ( this.state.inputCollateralAmount  === '' ) { return true; }
		if ( !this.isERC20BalanceEnough()             ) { return true; }

		const amountParsed = new BigNumber(this.state.inputCollateralAmount);
		if ( !amountParsed || amountParsed.isNaN() || amountParsed.eq(0) ) { return true; }

		let slots = new BigNumber(this.state.maxCollaterals);
		const foundLock = this.props.token.locks.find((item: Lock) => { return item.lockType === LockType.slots });
		if ( foundLock ) {
			slots = foundLock.param
		}

		if ( slots.gt(this.props.token.collateral.length + this.state.collaterals.length) ) { return false; }

		const foundExistindCollateral = this.props.token.collateral.find((item: CollateralItem) => { return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
		if ( foundExistindCollateral ) { return false; }

		const foundAddingCollateral = this.state.collaterals.find((item: CollateralItem) => { return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
		if ( foundAddingCollateral ) { return false; }

		return true;
	}
	addCollateralERC20Row() {
		let amountAdded = new BigNumber(0);
		let amountToAdd = new BigNumber(this.state.inputCollateralAmount);
		const erc20Added = this.state.collaterals.filter((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase()
		});
		if ( erc20Added.length && erc20Added[0].amount ) {
			amountAdded = new BigNumber(erc20Added[0].amount);
		}

		let tokenToAdd = undefined;
		if ( this.state.techToken.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() ) {
			tokenToAdd = this.state.techToken;
		} else {
			const foundTechToken = this.state.erc20OtherTechTokens.find((item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase()
			});
			if ( foundTechToken ) {
				tokenToAdd = foundTechToken;
			} else {
				const foundToken = this.state.erc20CollateralTokens.find((item) => {
					if ( !item.address ) { return false; }
					return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase()
				});
				if ( foundToken ) {
					tokenToAdd = foundToken;
				}
			}
		}
		if ( tokenToAdd ) {
			amountAdded = tokenToFloat(new BigNumber(amountAdded), tokenToAdd.decimals || 18);
			amountToAdd = tokenToInt(amountToAdd.plus(amountAdded), tokenToAdd.decimals || 18);
		} else {
			amountToAdd = amountToAdd.plus(amountAdded)
		}

		const collateralsUpdated = [
			...this.state.collaterals.filter((item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() !== this.state.inputCollateralAddress.toLowerCase()
			}),
			{
				assetType: _AssetType.ERC20,
				address: this.state.inputCollateralAddress,
				amount: amountToAdd,
			}
		];
		this.setState({
			collaterals: collateralsUpdated,
			inputCollateralAmount: '',
			inputCollateralAddress: '',
		})
	}
	getAddCollateralERC20Btn() {
		return (
			<button
				className="btn btn-grad"
				disabled={ this.isCollateralERC20BtnDisabled() }
				onClick={() => { this.addCollateralERC20Row() }}
			>Add</button>
		)
	}
	getCollateralERC20AmountTitle() {
		const foundTech = this.state.erc20OtherTechTokens.find((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase()
		});
		const foundERC20 = this.state.erc20CollateralTokens.find((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase()
		});
		if (
			this.state.inputCollateralAddress &&
			this.state.inputCollateralAddress !== '' &&
			this.state.inputCollateralAddress !== '0' &&
			this.state.inputCollateralAddress !== '0x0000000000000000000000000000000000000000' &&
			this.state.inputCollateralAddress.toLowerCase() !== this.state.techToken.address.toLowerCase() &&
			!foundERC20 &&
			!foundTech
		) {
			return (
				<Tippy
					content={ this.t('decimals is unknown; enter amount in wei') }
					appendTo={ document.getElementsByClassName("wrapper")[0] }
					trigger='mouseenter'
					interactive={ false }
					arrow={ false }
					maxWidth={ 512 }
				>
					<label className="input-label text-orange">{ this.t('Amount') }*</label>
				</Tippy>
			)
		} else {
			return (
				<label className="input-label">
					{ this.t('Amount') }
					<Tippy
						content={ this.t('Maximum and allowanced amount of tokens which you can add to collateral of wrapped nft') }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<span className="i-tip"></span>
					</Tippy>
				</label>
			)
		}
	}
	updateERC20Balance() {
		const foundERC20Contract = this.metamaskAdapter.getERC20Contract(this.state.inputCollateralAddress.toLowerCase());
		if ( foundERC20Contract ) {
			const foundStorage = this.metamaskAdapter.wrapperContract.getStorageContract(this.props.token.contractAddress);
			if ( foundStorage && foundStorage.contract && foundStorage.contract.wrapperContract ) {
				foundERC20Contract.getBalance(foundStorage.contract.wrapperContract).then((data) => {
					this.setState({ erc20Balance: data })
				})
			} else {
				foundERC20Contract.getBalance(this.props.token.contractAddress).then((data) => {
					this.setState({ erc20Balance: data })
				})
			}
		} else {
			this.setState({ erc20Balance: undefined });
		}
	}
	getCollateralERC20Balance() {
		if ( this.state.inputCollateralAddress === '' ) { return null; }
		if ( !this.state.erc20Balance ) { return null; }

		const foundToken = this.state.erc20CollateralTokens.find((item) => { return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
		if ( !foundToken ) { return null; }

		return (
			<div className="c-add__max mt-2 mb-0">
				<div className="mt-1 mb-1">
					<span>Max: </span>
					<button
						onClick={() => { if ( this.state.erc20Balance ) { this.setState({ inputCollateralAmount: tokenToFloat(this.state.erc20Balance.balance, foundToken.decimals || 18).toString() }) } }}
					>{ tokenToFloat(this.state.erc20Balance.balance, foundToken.decimals || 18).decimalPlaces(5).toString() }</button>
				</div>
				<div>
					<span>Allowance: </span>
					<button
						onClick={() => { if ( this.state.erc20Balance ) { this.setState({ inputCollateralAmount: tokenToFloat(this.state.erc20Balance.allowance, foundToken.decimals || 18).toString() }) } }}
					>{ tokenToFloat(this.state.erc20Balance.allowance, foundToken.decimals || 18).decimalPlaces(5).toString() }</button>
				</div>
			</div>
		)
	}
	getCollateralERC20AddressInput() {
		if ( !this.isCollateralAddressInWhitelist() ) {
			return (
				<React.Fragment>
					<div className="select-group">
						<input
							className="input-control has-error"
							type="text"
							placeholder="0.000"
							value={ this.state.inputCollateralAddress }
							onChange={(e) => {
								setTimeout(() => { this.updateERC20Balance() }, 100);

								this.setState({ inputCollateralAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, ""), erc20Balance: undefined, })
							}}
							onKeyPress={(e) => {
								if ( e.defaultPrevented)                     { return; }
								if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
								if ( e.key !== 'Enter' )                     { return; }

								this.addCollateralERC20Row()
							}}
						/>
						<CoinSelector
							store         = { this.store }
							tokens        = { this.filterERC20ContractByPermissions({ enabledForCollateral: true }) }
							selectedToken = { this.state.inputCollateralAddress }
							onChange      = {(address: string) => {
								setTimeout(() => { this.updateERC20Balance() }, 100);
								this.setState({ inputCollateralAddress: address, erc20Balance: undefined, })
							}}
						/>
					</div>
					<div className="input-error">Address is not in whitelist</div>
				</React.Fragment>
			)
		}

		return (
			<React.Fragment>
				<div className="select-group">
					<input
						className="input-control"
						type="text"
						placeholder="0.000"
						value={ this.state.inputCollateralAddress }
						onChange={(e) => {
							setTimeout(() => { this.updateERC20Balance() }, 10);

							this.setState({ inputCollateralAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, ""), erc20Balance: undefined, })
						}}
						onKeyPress={(e) => {
							if ( e.defaultPrevented)                     { return; }
							if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
							if ( e.key !== 'Enter' )                     { return; }

							this.addCollateralERC20Row()
						}}
					/>
					<CoinSelector
						store         = { this.store }
						tokens        = { this.filterERC20ContractByPermissions({ enabledForCollateral: true }) }
						selectedToken = { this.state.inputCollateralAddress }
						onChange      = {(address: string) => {
							setTimeout(() => { this.updateERC20Balance() }, 10);
							this.setState({ inputCollateralAddress: address, erc20Balance: undefined, })
						}}
					/>
				</div>
			</React.Fragment>
		)

	}
	getCollateralERC20AmountInput() {
		if ( !this.isERC20BalanceEnough() ) {
			return (
				<React.Fragment>
					<input
						className="input-control has-error"
						type="text"
						placeholder=""
						value={ addThousandSeparator(this.state.inputCollateralAmount) }
						onChange={(e) => {
							let value = removeThousandSeparator(e.target.value);
							if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
								if ( new BigNumber(value).isNaN() ) { return; }
								value = new BigNumber(value).toString();
							}
							this.setState({ inputCollateralAmount: value, })
						}}
						onKeyPress={(e) => {
							if ( e.defaultPrevented)                     { return; }
							if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
							if ( e.key !== 'Enter' )                     { return; }

							this.addCollateralERC20Row()
						}}
					/>
					<div className="input-error">Not enough balance</div>
				</React.Fragment>
			)
		}

		return (
			<React.Fragment>
				<input
					className="input-control"
					type="text"
					placeholder=""
					value={ addThousandSeparator(this.state.inputCollateralAmount) }
					onChange={(e) => {
						let value = removeThousandSeparator(e.target.value);
						if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
							if ( new BigNumber(value).isNaN() ) { return; }
							value = new BigNumber(value).toString();
						}
						this.setState({ inputCollateralAmount: value, })
					}}
					onKeyPress={(e) => {
						if ( e.defaultPrevented)                     { return; }
						if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
						if ( e.key !== 'Enter' )                     { return; }

						this.addCollateralERC20Row()
					}}
				/>
			</React.Fragment>
		)

	}
	getCollateralERC20SlotsAlert() {
		if ( this.state.inputCollateralAddress === '' ) { return null; }

		let slots = new BigNumber(this.state.maxCollaterals);
		const foundLock = this.props.token.locks.find((item: Lock) => { return item.lockType === LockType.slots });
		if ( foundLock ) {
			slots = foundLock.param
		}

		if ( slots.gt(this.props.token.collateral.length + this.state.collaterals.length) ) { return null; }

		const addressToFind = this.state.inputCollateralAddress.toLowerCase();

		const foundExistindCollateral = this.props.token.collateral.find((item: CollateralItem) => { return item.address.toLowerCase() === addressToFind });
		if ( foundExistindCollateral ) { return null; }

		const foundAddingCollateral = this.state.collaterals.find((item: CollateralItem) => { return item.address.toLowerCase() === addressToFind });
		if ( foundAddingCollateral ) { return null; }

		return ( <div className="alert alert-warning mb-3">You have reached the maximum number of collateral slots</div> );
	}
	getCollateralERC20Block() {
		if ( this.state.inputassetType === _AssetType.ERC20 ) {
			return (
				<React.Fragment>
					{ this.getCollateralERC20SlotsAlert() }
					<div className="row">
						<div className="col col-12 col-md-7">
							<label className="input-label">Token Address</label>
							{ this.getCollateralERC20AddressInput() }
							{ this.getERC20Link() }
						</div>
						<div className="col col-12 col-md-3">
							{ this.getCollateralERC20AmountTitle() }
							{ this.getCollateralERC20AmountInput() }
							{ this.getCollateralERC20Balance() }
						</div>
						<div className="col col-12 col-md-2">
							<label className="input-label">&nbsp;</label>
							{ this.getAddCollateralERC20Btn() }
						</div>
					</div>
				</React.Fragment>
			)
		}
	}

	isCollateralERC721BtnDisabled() {
		const foundLock = this.props.token.locks.find((item: Lock) => { return item.lockType === LockType.slots });
		if ( foundLock ) {
			if ( foundLock.param.lt(this.state.collaterals.length + 1) ) { return true }
		}
		if ( this.state.collaterals.length > this.state.maxCollaterals - 1 ) { return true; }
		if ( this.state.inputCollateralAddress === '' ) { return true; }
		if ( this.state.inputCollateralTokenId === '' ) { return true; }
		if (
			this.metamaskAdapter.web3 &&
			!this.metamaskAdapter.web3.utils.isAddress(this.state.inputCollateralAddress)
		) { return true; }
		if ( this.state.collateralWhitelist ) {
			const foundWhitelistItem = this.state.collateralWhitelist.find((item: _Asset) => { return item.contractAddress.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
			if ( !foundWhitelistItem ) { return true; }
		}

		return false;
	}
	addCollateralERC721Row() {
		const address = this.state.inputCollateralAddress;
		const tokenId = this.state.inputCollateralTokenId;

		const collateralsUpdated = [
			...this.state.collaterals.filter((item) => {
					if ( item.assetType !== _AssetType.ERC721 ) { return true; }
					if ( !item.address ) { return false; }
					return item.address.toLowerCase() !== address.toLowerCase() ||
						item.tokenId !== tokenId
				}),
			{
				assetType: _AssetType.ERC721,
				address: address,
				tokenId: tokenId,
			}
		];
		this.setState({
			collaterals: collateralsUpdated,
			inputCollateralAmount: '',
			inputCollateralAddress: '',
			inputCollateralTokenId: '',
		})

		getOriginalToken({
			metamaskAdapter: this.metamaskAdapter,
			contractAddress: address,
			tokenId: tokenId,
			t: this.t,
			userAddress: this.state.userAddress,
			assetType: _AssetType.ERC721
		})
			.then((data) => {

				const collateralsUpdated = [
					...this.state.collaterals.filter((item) => {
						if ( item.assetType !== _AssetType.ERC721 ) { return true; }
						if ( !item.address ) { return false; }
						return item.address.toLowerCase() !== address.toLowerCase() ||
							item.tokenId !== tokenId
					}),
					{
						assetType: _AssetType.ERC721,
						address: address,
						tokenId: tokenId,
						tokenImg: data.image || ''
					}
				];

				let collateralErrorsUpdated = this.state.collateralErrors;
				if ( data.owner && data.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) {
					collateralErrorsUpdated = [
						...collateralErrorsUpdated,
						{
							address: address,
							tokenId: tokenId,
							msg: 'Not yours'
						}
					]
				}

				this.setState({
					collaterals: collateralsUpdated,
					collateralErrors: collateralErrorsUpdated,
				})

			})
			.catch((error) => {
				console.log('Cannot fetch 721 token', error);
				const collateralsUpdated = [
					...this.state.collaterals.filter((item) => {
						if ( item.assetType !== _AssetType.ERC721 ) { return true; }
						if ( !item.address ) { return false; }
						return item.address.toLowerCase() !== address.toLowerCase() ||
							item.tokenId !== tokenId
					}),
					{
						assetType: _AssetType.ERC721,
						address: address,
						tokenId: tokenId,
						tokenImg: ''
					}
				];

				let collateralErrorsUpdated = [
					...this.state.collateralErrors,
					{
						address: address,
						tokenId: tokenId,
						msg: 'Cannot fetch token'
					}
				]

				this.setState({
					collaterals: collateralsUpdated,
					collateralErrors: collateralErrorsUpdated,
				})
			})
	}
	getAddCollateralERC721Btn() {
		return (
			<button
				className="btn btn-grad"
				disabled={ this.isCollateralERC721BtnDisabled() }
				onClick={() => { this.addCollateralERC721Row() }}
			>Add</button>
		)
	}
	getCollateral721SlotsAlert() {
		let slots = new BigNumber(this.state.maxCollaterals);
		const foundLock = this.props.token.locks.find((item: Lock) => { return item.lockType === LockType.slots });
		if ( foundLock ) {
			slots = foundLock.param
		}

		if ( slots.gt(this.props.token.collateral.length + this.state.collaterals.length) ) { return null; }

		return ( <div className="alert alert-warning mb-3">You have reached the maximum number of collateral slots</div> );
	}
	getCollateralERC721Block() {
		if ( this.state.inputassetType === _AssetType.ERC721 ) {
			return (
				<React.Fragment>
					{ this.getCollateral721SlotsAlert() }
					<div className="row">
						<div className="col col-12 col-md-7">
							<label className="input-label">NFT Address</label>
							<input
								className="input-control"
								type="text"
								placeholder="Paste here"
								value={ this.state.inputCollateralAddress }
									onChange={(e) => { this.setState({ inputCollateralAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") }) }}
									onKeyPress={(e) => {
										if ( e.defaultPrevented)                     { return; }
										if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
										if ( e.key !== 'Enter' )                     { return; }

										this.addCollateralERC721Row()
									}}
							/>
						</div>
						<div className="col col-12 col-md-3">
							<label className="input-label">Token ID</label>
							<input
								className="input-control"
								type="text"
								placeholder="99 900"
								value={ addThousandSeparator(this.state.inputCollateralTokenId) }
								onChange={(e) => { this.setState({ inputCollateralTokenId: e.target.value.toLowerCase().replace(/[^0-9]/g, "") }) }}
								onKeyPress={(e) => {
									if ( e.defaultPrevented)                     { return; }
									if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
									if ( e.key !== 'Enter' )                     { return; }

									this.addCollateralERC721Row()
								}}
							/>
						</div>
						<div className="col col-12 col-md-2">
							<label className="input-label">&nbsp;</label>
							{ this.getAddCollateralERC721Btn() }
						</div>
					</div>
				</React.Fragment>
			)
		}
	}

	isCollateralERC1155BtnDisabled() {
		const foundLock = this.props.token.locks.find((item: Lock) => { return item.lockType === LockType.slots });
		if ( foundLock ) {
			if ( foundLock.param.lt(this.state.collaterals.length + 1) ) { return true }
		}
		if ( this.state.collaterals.length > this.state.maxCollaterals - 1 ) { return true; }
		if ( this.state.inputCollateralAmount === '' ) { return true; }
		const amountParsed = new BigNumber(this.state.inputCollateralAmount);
		if ( !amountParsed || amountParsed.isNaN() || amountParsed.eq(0) ) { return true; }
		if ( this.state.inputCollateralAddress === '' ) { return true; }
		if ( this.state.inputCollateralTokenId === '' ) { return true; }

		if ( this.state.collateralWhitelist ) {
			const foundWhitelistItem = this.state.collateralWhitelist.find((item: _Asset) => { return item.contractAddress.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
			if ( !foundWhitelistItem ) { return true }
		}

		if (
			this.metamaskAdapter.web3 &&
			!this.metamaskAdapter.web3.utils.isAddress(this.state.inputCollateralAddress)
		) { return true; }

		return false;
	}
	addCollateralERC1155Row() {
		const address = this.state.inputCollateralAddress;
		const tokenId = this.state.inputCollateralTokenId;
		const amount  = new BigNumber(this.state.inputCollateralAmount);

		const collateralsUpdated = [
			...this.state.collaterals.filter((item) => {
				if ( item.assetType !== _AssetType.ERC1155 ) { return true; }
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() !== address.toLowerCase() ||
					item.tokenId !== tokenId
			}),
			{
				assetType: _AssetType.ERC1155,
				address: address,
				tokenId: tokenId,
				amount : amount,
			}
		];
		this.setState({
			collaterals: collateralsUpdated,
			inputCollateralAmount: '',
			inputCollateralAddress: '',
			inputCollateralTokenId: '',
		})

		getOriginalToken({
			metamaskAdapter: this.metamaskAdapter,
			contractAddress: address,
			tokenId: tokenId,
			t: this.t,
			userAddress: this.state.userAddress,
			assetType: _AssetType.ERC1155
		})
			.then((data) => {

				const collateralsUpdated = [
					...this.state.collaterals.filter((item) => {
						if ( item.assetType !== _AssetType.ERC1155 ) { return true; }
						if ( !item.address ) { return false; }
						return item.address.toLowerCase() !== address.toLowerCase() ||
							item.tokenId !== tokenId
					}),
					{
						assetType: _AssetType.ERC1155,
						address: address,
						tokenId: tokenId,
						amount : amount,
						tokenImg: data.image || ''
					}
				];

				let collateralErrorsUpdated = this.state.collateralErrors;
				if ( data.amount && data.amount.eq(0) ) {
					collateralErrorsUpdated = [
						...collateralErrorsUpdated,
						{
							address: address,
							tokenId: tokenId,
							msg: 'Not enough balance'
						}
					]
				}

				this.setState({
					collaterals: collateralsUpdated,
					collateralErrors: collateralErrorsUpdated,
				})

			})
			.catch((error) => {
				console.log('Cannot fetch 1155 token', error);
				const collateralsUpdated = [
					...this.state.collaterals.filter((item) => {
						if ( item.assetType !== _AssetType.ERC1155 ) { return true; }
						if ( !item.address ) { return false; }
						return item.address.toLowerCase() !== address.toLowerCase() ||
							item.tokenId !== tokenId
					}),
					{
						assetType: _AssetType.ERC1155,
						address: address,
						tokenId: tokenId,
						amount : amount,
						tokenImg: ''
					}
				];

				let collateralErrorsUpdated = [
					...this.state.collateralErrors,
					{
						address: address,
						tokenId: tokenId,
						msg: 'Cannot fetch token'
					}
				]

				this.setState({
					collaterals: collateralsUpdated,
					collateralErrors: collateralErrorsUpdated,
				})
			})
	}
	getAddCollateralERC1155Btn() {
		return (
			<button
				className="btn btn-grad"
				disabled={ this.isCollateralERC1155BtnDisabled() }
				onClick={() => { this.addCollateralERC1155Row() }}
			>Add</button>
		)
	}
	getCollateral1155SlotsAlert() {
		if ( this.state.inputCollateralAddress === '' ) { return null; }
		if ( this.state.inputCollateralTokenId === '' ) { return null; }

		let slots = new BigNumber(this.state.maxCollaterals);
		const foundLock = this.props.token.locks.find((item: Lock) => { return item.lockType === LockType.slots });
		if ( foundLock ) {
			slots = foundLock.param
		}

		if ( slots.gt(this.props.token.collateral.length + this.state.collaterals.length) ) { return null; }

		const addressToFind = this.state.inputCollateralAddress.toLowerCase();
		const tokenToFind   = this.state.inputCollateralTokenId;

		const foundExistindCollateral = this.props.token.collateral.find((item: CollateralItem) => {
			if ( !item.tokenId ) { return false; }
			return item.address.toLowerCase() === addressToFind && item.tokenId === tokenToFind
		});
		if ( foundExistindCollateral ) { return null; }

		const foundAddingCollateral = this.state.collaterals.find((item: CollateralItem) => {
			if ( !item.tokenId ) { return false; }
			return item.address.toLowerCase() === addressToFind && item.tokenId === tokenToFind
		});
		if ( foundAddingCollateral ) { return null; }

		return ( <div className="alert alert-warning mb-3">You have reached the maximum number of collateral slots</div> );
	}
	getCollateralERC1155Block() {
		if ( this.state.inputassetType === _AssetType.ERC1155 ) {
			return (
				<React.Fragment>
					{ this.getCollateral1155SlotsAlert() }
					<div className="row">
						<div className="col col-12 col-md-5">
							<label className="input-label">NFT Address</label>
							<input
								className="input-control"
								type="text"
								placeholder="Paste here"
								value={ this.state.inputCollateralAddress }
								onChange={(e) => { this.setState({ inputCollateralAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") }) }}
								onKeyPress={(e) => {
									if ( e.defaultPrevented )                    { return; }
									if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
									if ( e.key !== 'Enter' )                     { return; }

									this.addCollateralERC1155Row()
								}}
							/>
						</div>
						<div className="col col-12 col-md-3">
							<label className="input-label">Token ID</label>
							<input
								className="input-control"
								type="text"
								placeholder="99 900"
								value={ addThousandSeparator(this.state.inputCollateralTokenId) }
								onChange={(e) => { this.setState({ inputCollateralTokenId: e.target.value.toLowerCase().replace(/[^0-9]/g, "") }) }}
								onKeyPress={(e) => {
									if ( e.defaultPrevented)                     { return; }
									if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
									if ( e.key !== 'Enter' )                     { return; }

									this.addCollateralERC1155Row()
								}}
							/>
							</div>
						<div className="col col-12 col-md-2">
							<label className="input-label">Amount</label>
							<input
								className="input-control"
								type="text"
								placeholder="10"
								value={ addThousandSeparator(this.state.inputCollateralAmount) }
								onChange={(e) => {
									// let value = removeThousandSeparator(e.target.value);
									// if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
									// 	if ( new BigNumber(value).isNaN() ) { return; }
									// 	value = new BigNumber(value).toString();
									// }
									// this.setState({ inputCollateralAmount: value, })
									const value = removeThousandSeparator(e.target.value.replaceAll(' ', ''));
									if (
										value === '' ||
										isNaN(parseInt(value))
									) {
										this.setState({ inputCollateralAmount: '' })
										return;
									}

									this.setState({ inputCollateralAmount: `${parseInt(value)}` })
								}}
								onKeyPress={(e) => {
									if ( e.defaultPrevented)                     { return; }
									if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
									if ( e.key !== 'Enter' )                     { return; }

									this.addCollateralERC20Row()
								}}
							/>
						</div>
						<div className="col col-12 col-md-2">
							<label className="input-label">&nbsp;</label>
							{ this.getAddCollateralERC1155Btn() }
						</div>
					</div>
				</React.Fragment>
			)
		}
	}
	get1155Amount(qty: BigNumber | undefined) {
		if ( !qty ) { return '' }
		if ( qty.eq(0) ) { return '' }
		if ( qty.eq(1) ) { return '1 item' }
		return `${qty} items`
	}

	getCollateralItemAlert() {
		return this.state.collateralErrors.map((item) => { return ( <div className="alert alert-warning mb-3">{`Error with collateral: ${item.address}:${item.tokenId || ''}: ${item.msg}`}</div> ) })
	}
	isCollateralAddressInWhitelist() {
		if ( this.state.inputCollateralAddress === '' ) { return true; }
		if ( this.state.collateralWhitelist ) {
			const foundWhitelistItem = this.state.collateralWhitelist.find((item: _Asset) => { return item.contractAddress.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
			if ( !foundWhitelistItem ) { return false; }
		}

		return true;
	}
	getCollateralRows() {
		return (
			<CollateralViewer
				store={this.store}
				metamaskAdapter={this.metamaskAdapter}
				t={ this.t }
				collaterals={ this.state.collaterals }
				collateralErrors={ this.state.collateralErrors }
				removeRow={(item: CollateralItem) => {
					const collaterals = this.state.collaterals.filter((iitem: CollateralItem) => {
						if ( item.assetType === _AssetType.ERC721 || item.assetType === _AssetType.ERC1155 ) {
							return item.address.toLowerCase() !== iitem.address.toLowerCase() ||
								item.tokenId !== iitem.tokenId
						} else {
							return item.address.toLowerCase() !== iitem.address.toLowerCase()
						}
					});
					this.setState({
						collaterals,
						collateralErrors: this.state.collateralErrors.filter((iitem) => { return item.address.toLowerCase() !== iitem.address.toLowerCase() || item.tokenId !== iitem.tokenId })
					});
				}}
				width={ 'wide' }
				color="light"
			/>
		)
	}
	getCollateralBlock() {
		return (

				<div className="c-wrap__form">
					<p>You can add assets to collateral of your wrapped NFT. Use list of approved tokens.</p>
					<div className="row row-sm mb-5">
						<div className="col-12 col-sm-auto mr-3 py-2">
							<label className="input-label mb-0">Token Type</label>
						</div>
						<div className="col-auto py-2">
							<label className="checkbox">
								<input
									type="radio"
									name="token-type"
									value={ _AssetType.native }
									checked={ this.state.inputassetType === _AssetType.native }
									onChange={() => {
										this.setState({
											inputassetType: _AssetType.native,
											inputCollateralAddress: '',
											inputCollateralAmount: '',
											inputCollateralTokenId: '',
											erc20Balance: undefined,
										})
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">Native</span>
							</label>
						</div>
						<div className="col-auto py-2">
							<label className="checkbox">
								<input
									type="radio"
									name="token-type"
									value={ _AssetType.ERC20 }
									checked={ this.state.inputassetType === _AssetType.ERC20 }
									onChange={() => {
										this.setState({
											inputassetType: _AssetType.ERC20,
											inputCollateralAddress: '',
											inputCollateralAmount: '',
											inputCollateralTokenId: '',
											erc20Balance: undefined,
										})
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">{ this.state.EIPPrefix }-20</span>
							</label>
						</div>
						<div className="col-auto py-2">
							<label className="checkbox">
								<input
									type="radio"
									name="token-type"
									value={ _AssetType.ERC721 }
									checked={ this.state.inputassetType === _AssetType.ERC721 }
									onChange={() => {
										this.setState({
											inputassetType: _AssetType.ERC721,
											inputCollateralAddress: '',
											inputCollateralAmount: '',
											inputCollateralTokenId: '',
											erc20Balance: undefined,
										})
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">{ this.state.EIPPrefix }-721</span>
							</label>
						</div>
						<div className="col-auto py-2">
							<label className="checkbox">
								<input
									type="radio"
									name="token-type"
									value={ _AssetType.ERC1155 }
									checked={ this.state.inputassetType === _AssetType.ERC1155 }
									onChange={() => {
										this.setState({
											inputassetType: _AssetType.ERC1155,
											inputCollateralAddress: '',
											inputCollateralAmount: '',
											inputCollateralTokenId: '',
											erc20Balance: undefined,
										})
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">{ this.state.EIPPrefix }-1155</span>
							</label>
						</div>
					</div>
					{/* { this.getCollateralItemAlert() } */}
					{ this.getCollateralNativeBlock() }
					{ this.getCollateralERC20Block() }
					{ this.getCollateralERC721Block() }
					{ this.getCollateralERC1155Block() }
				</div>

		)
	}
	getMaxSlots() {
		const foundLock = this.props.token.locks.find((item: Lock) => { return item.lockType === LockType.slots });
		if ( foundLock ) { return foundLock.param.toString() }

		return this.state.maxCollaterals.toString();
	}
	getV0Label() {
		if ( !this.state.addValueToken || this.state.addValueToken.assetType !== _AssetType.wNFTv0 ) { return null; }

		const tokenUrl = `${this.state.v0AppLink}/#/token?chain=${this.metamaskAdapter.chainId || 0}&contractAddress=${ this.state.addValueToken.contractAddress }&tokenId=${ this.state.addValueToken.tokenId }`

		return (
			<React.Fragment>
				<p className="text-orange">This NFT has been wrapped by the previous version of dApp. Some functions may not work properly with current version of dApp.<br /><a target="_blank" rel="noopener noreferrer" href={ tokenUrl } >Go to v0 app.</a></p>
			</React.Fragment>
		)
	}

	render() {
		return (
			<div className="modal modal-lg">
				<div className="modal__inner">
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
							<div
								className="modal__close"
								onClick={() => { this.closePopup() }}
							>
								<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
									<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
								</svg>
							</div>


							<div className="c-add">
								<div className="c-add__text">
									<div className="h2">Add Collateral</div>
									<div className="d-flex">
										<img className="mr-2" src={ icon_attention } alt="" />
										<span className="text-muted">мах { this.getMaxSlots() } entries</span>
									</div>
									{ this.getV0Label() }
								</div>
								{ this.getCollateralBlock() }
							</div>

							{ this.getCollateralRows() }

							{ this.getAddValueBtn() }

						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation("translations")(AddValuePopup);
