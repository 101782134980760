
import React from 'react';
import Tippy from '@tippyjs/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
	withTranslation,
	Trans
} from "react-i18next";
import {
	withRouter,
	match,
	Link
} from 'react-router-dom';
import {
	History,
	Location
} from 'history';
import {
	WrappedTokenType,
	ERC20ContractParamsType,
	MetamaskAdapter,
	assetTypeToString,
	_AssetType,
	Lock,
	LockType,
} from '../../models/BlockchainAdapter';

import {
	addThousandSeparator,
	compactString,
	tokenToFloat,
	unixtimeToStr,
} from '../../models/_utils';
import {
	setError,
	setLoading,
	unsetLoading
} from '../../reducers';

import AddValuePopup from '../AddValuePopup';
import TransferPopup from '../TransferPopup';
import ApprovePopup  from '../ApprovePopup';
import CrossingFreezePopup from '../CrossingFreezePopup';

import CollateralViewer from '../CollateralViewer';

import icon_cannot_load    from '../../static/pics/logo-mob.svg';
import icon_i_copy         from '../../static/pics/i-copy.svg';
import icon_warning        from "../../static/pics/icons/i-warning.svg";
import icon_external_green from "../../static/pics/icons/i-external-green.svg";
import icon_link_green     from "../../static/pics/icons/i-link-green.svg";
import icon_dots_hor       from "../../static/pics/icons/i-dots-hor.svg";
import icon_no_transfer    from "../../static/pics/icons/i-no-transfer.svg"
import icon_no_unwrap      from "../../static/pics/icons/i-no-unwrap.svg"
import icon_no_collateral  from "../../static/pics/icons/i-no-collateral.svg"
import icon_no_wrap        from "../../static/pics/icons/i-no-wrap.svg"
import icon_send           from "../../static/pics/icons/i-send.svg";

import default_icon        from '../../static/pics/coins/_default.svg';

import { requestTokenURIUpdate } from '../../models/APIService/apiservice';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type TokenWrappedPreviewPageProps = {
	store          : any,
	metamaskAdapter: MetamaskAdapter,
	token          : WrappedTokenType;
	t              : any,
	match          : match;
	location       : Location,
	history        : History,
}
type TokenWrappedPreviewPageState = {
	chainId                : number,
	userAddress            : string,
	decimalsNative         : number,
	iconNative             : string,
	symbolNative           : string,
	explorerBaseUrl        : string,
	marketplaceUrl         : string,
	EIPPrefix              : string,
	authMethod             : string,
	wrapperContractAddress : string,
	maxCollaterals         : number,

	techToken              : ERC20ContractParamsType,
	erc20CollateralTokens  : Array<ERC20ContractParamsType>,
	erc20OtherTechTokens   : Array<ERC20ContractParamsType>,
	transferAllowances     : Array<{ wrapperAddress: string, transferModelAddress: string, erc20TokenAddress: string, allowance: BigNumber }>,

	addValuePopup          : boolean,
	transferPopup          : boolean,
	approvePopup           : boolean,
	checkoutApproving      : boolean,
	freezePopup            : boolean,

	copiedHintWhere        : string,

	originalTokenInfoOpened: boolean,
	showOwnerBlock         : boolean,

	menuOpened             : boolean,
	cursorOnCardMenu       : boolean,
	tokensListOpened       : boolean,
	descriptionOpened      : boolean,

	crossing: {
		keeper: string,
		spawner: string,
		targetChains: Array<{ targetChainId: number, address: string, icon?: string, name?: string, }>
	},

	tokenURIRequested      : boolean,
}

class TokenWrappedPreviewPage extends React.Component<TokenWrappedPreviewPageProps, TokenWrappedPreviewPageState> {

	store                 : any;
	metamaskAdapter       : MetamaskAdapter;
	unsubscribe!          : Function;
	t                     : any;
	userMenuBlockRef      : React.RefObject<HTMLInputElement>;
	copiedHintTimer       : number;
	copiedHintTimeout     : number;

	DESCRIPTION_LENGTH    : number;

	constructor(props: TokenWrappedPreviewPageProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.t                      = props.t;
		this.userMenuBlockRef       = React.createRef();
		this.copiedHintTimer        = 0;
		this.copiedHintTimeout      = 2; // s

		this.DESCRIPTION_LENGTH     = 160;

		this.state = {
			chainId                 : this.store.getState().metamaskAdapter.chainId,
			userAddress             : this.store.getState().account.address,
			decimalsNative          : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative            : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative              : this.store.getState().metamaskAdapter.networkTokenIcon,
			explorerBaseUrl         : this.store.getState().metamaskAdapter.explorerBaseUrl,
			marketplaceUrl          : this.store.getState().metamaskAdapter.marketplaceUrl,
			EIPPrefix               : this.store.getState().metamaskAdapter.EIPPrefix,
			authMethod              : this.store.getState().metamaskAdapter.authMethod || 'metamask',
			wrapperContractAddress  : this.store.getState().metamaskAdapter.wrapperContract,
			maxCollaterals          : this.store.getState().metamaskAdapter.maxCollaterals || 0,

			techToken            : this.store.getState().erc20TechTokenParams,
			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
			erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
			transferAllowances   : this.store.getState().transferModelAllowances,

			addValuePopup        : false,
			transferPopup        : false,
			approvePopup         : false,
			checkoutApproving    : false,
			freezePopup          : false,

			copiedHintWhere      : '',

			originalTokenInfoOpened: false,
			showOwnerBlock         : false,

			menuOpened           : false,
			cursorOnCardMenu     : false,
			tokensListOpened     : false,
			descriptionOpened    : false,

			crossing             : this.store.getState().metamaskAdapter.crossing,

			tokenURIRequested    : false,
		}
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {
			this.setState({
				chainId        : this.store.getState().metamaskAdapter.chainId,
				userAddress    : this.store.getState().account.address,
				decimalsNative : this.store.getState().metamaskAdapter.networkTokenDecimals,
				symbolNative   : this.store.getState().metamaskAdapter.networkTokenTicket,
				iconNative     : this.store.getState().metamaskAdapter.networkTokenIcon,
				explorerBaseUrl: this.store.getState().metamaskAdapter.explorerBaseUrl,
				marketplaceUrl : this.store.getState().metamaskAdapter.marketplaceUrl,
				EIPPrefix      : this.store.getState().metamaskAdapter.EIPPrefix,
				authMethod     : this.store.getState().metamaskAdapter.authMethod || 'metamask',
				wrapperContractAddress: this.store.getState().metamaskAdapter.wrapperContract,
				maxCollaterals        : this.store.getState().metamaskAdapter.maxCollaterals || 0,

				techToken            : this.store.getState().erc20TechTokenParams,
				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
				erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
				transferAllowances   : this.store.getState().transferModelAllowances,

				crossing             : this.store.getState().metamaskAdapter.crossing,
			});
		});
 	}
	componentWillUnmount() {
		this.unsubscribe();
	}

	getBreadcrumbs() {
		return (
			<div className="breadcrumbs">
				<Link className="item-back btn btn-sm btn-gray" to="/list">
					<svg className="mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12 5.25H2.8725L7.065 1.0575L6 0L0 6L6 12L7.0575 10.9425L2.8725 6.75H12V5.25Z" fill="white"></path>
					</svg>
					<span>Back to the list</span>
				</Link>
			</div>
		)
	}

	getCopiedHint(where: string) {
		if ( this.state.copiedHintWhere === where  ) {
			return (<span className="btn-action-info">{ this.t('Copied') }</span>)
		}
	}
	getCopyLinkButton() {
		if ( !this.props.token ) { return null; }

		return (
			<div className="btn-group">
				<CopyToClipboard
					text={ `${window.location.origin}/token/${this.state.chainId}/${this.props.token.contractAddress}/${this.props.token.tokenId}` }
					onCopy={() => {
						if ( !this.props.token ) { return; }
						this.setState({
							copiedHintWhere: 'toolbarbtn'
						});
						clearTimeout(this.copiedHintTimer);
						this.copiedHintTimer = window.setTimeout(() => { this.setState({
							copiedHintWhere: ''
						}); }, this.copiedHintTimeout*1000);
					}}
				>
					<button className="btn btn-md btn-gray btn-img">
						<img src={ icon_link_green } alt="" />
					</button>
				</CopyToClipboard>
				{ this.getCopiedHint('toolbarbtn') }
			</div>
		)
	}
	checkoutERC20Approve = () => {
		if ( !this.props.token.fees.length ) { return null; }
		if ( this.props.token.fees[0].token.toLowerCase() === this.state.techToken.address.toLowerCase() ) { return null; }

		const foundTech = this.state.erc20OtherTechTokens.find((item) => { return item.address.toLowerCase() === this.props.token.fees[0].token.toLowerCase() });
		if ( foundTech ) { return null; }

		const erc20Contract = this.metamaskAdapter.getERC20Contract(this.props.token.fees[0].token);
		if ( !erc20Contract ) { return; }

		this.store.dispatch(setLoading({ msg: `${this.t('Waiting for approve')} ${erc20Contract.erc20Params.symbol}` }));

		erc20Contract.makeAllowance(this.props.token.fees[0].value, this.props.token.contractAddress)
			.then(() => {
				erc20Contract.getBalance();
				this.store.dispatch(unsetLoading());
			})
			.catch((e: any) => {
				console.log('Cannot approve fee', e);
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `Cannot make allowance: ${e.message.split('\n')[0]}`,
					buttons: undefined,
					links: undefined
				}));
			});

	}
	getToolbarFeeApproveBtn() {

		if ( this.props.token.owner && this.props.token.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) { return null; }
		if ( this.props.token.amount && this.props.token.amount.lte(0) ) { return null; }

		if ( !this.props.token.fees.length ) { return null; }
		if ( this.props.token.fees[0].token.toLowerCase() === this.state.techToken.address.toLowerCase() ) { return null; }

		const foundTech = this.state.erc20OtherTechTokens.find((item) => { return item.address.toLowerCase() === this.props.token.fees[0].token.toLowerCase() });
		if ( foundTech ) { return null; }

		const foundToken = this.state.erc20CollateralTokens.find((item) => { return item.address.toLowerCase() === this.props.token.fees[0].token.toLowerCase() });
		if ( !foundToken ) { return null; }

		if ( foundToken.allowance.gte(this.props.token.fees[0].value) ) { return null; }

		const valueParsed = addThousandSeparator(tokenToFloat(this.props.token.fees[0].value, foundToken.decimals || 18).toString());
		return (
			<div className="btn-group">
				<Tippy
					content={ `You need to approve ${valueParsed} ${foundToken.symbol} Click to start.` }
					appendTo={ document.getElementsByClassName("wrapper")[0] }
					trigger='mouseenter'
					interactive={ false }
					arrow={ false }
					maxWidth={ 260 }
				>
					<button
						className="btn btn-md btn-gray btn-img"
						onClick={() => {
							this.checkoutERC20Approve()
						}}
					>
						<img src={ icon_warning } alt="" />
					</button>
				</Tippy>
			</div>
		)
	}
	getRoyaltyFeeApproveBtn() {

		if ( this.props.token.owner && this.props.token.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) { return null; }
		if ( this.props.token.amount && this.props.token.amount.lte(0) ) { return null; }

		if ( !this.props.token.fees.length ) { return null; }
		if ( this.props.token.fees[0].token.toLowerCase() === this.state.techToken.address.toLowerCase() ) { return null; }

		const foundTech = this.state.erc20OtherTechTokens.find((item) => { return item.address.toLowerCase() === this.props.token.fees[0].token.toLowerCase() });
		if ( foundTech ) { return null; }

		const foundToken = this.state.erc20CollateralTokens.find((item) => { return item.address.toLowerCase() === this.props.token.fees[0].token.toLowerCase() });
		if ( !foundToken ) { return null; }

		if ( foundToken.allowance.gte(this.props.token.fees[0].value) ) { return null; }

		const valueParsed = addThousandSeparator(tokenToFloat(this.props.token.fees[0].value, foundToken.decimals || 18).toString());
		return (
			<Tippy
				content={ `You need to approve ${valueParsed} ${foundToken.symbol} Click to start.` }
				appendTo={ document.getElementsByClassName("wrapper")[0] }
				trigger='mouseenter'
				interactive={ false }
				arrow={ false }
				maxWidth={ 260 }
			>
				<button
					className="btn btn-gray btn-sm btn-img"
					onClick={() => {
						this.checkoutERC20Approve()
					}}
				>
					<img src={ icon_warning } alt="" />
				</button>
			</Tippy>
		)
	}
	getMenuTransferButton() {
		if ( this.props.token.owner && this.props.token.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) { return null; }
		if ( this.props.token.amount && this.props.token.amount.lte(0)                                               ) { return null; }
		return (
			<div className="btn-group">
				<button
					className="btn btn-md btn-gray btn-img"
					onClick={() => { this.setState({ transferPopup: true }) }}
				>
					<img src={ icon_send } alt="" />
				</button>
			</div>
		)
	}
	getMenuTokenApproveButton() {
		if ( !this.props.token ) { return null; }
		if ( this.props.token.rules.noTransfer ) { return null; }
		if ( this.props.token.owner && this.props.token.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) { return null; }
		if ( this.props.token.amount && this.props.token.amount.lte(0) ) { return null; }

		return (
			<li>
				<button
					onClick={() => { this.setState({ approvePopup: true }) }}
				>
					Approve
				</button>
			</li>
		)
	}
	getMenuTokenSellButton() {
		if ( !this.state.marketplaceUrl ) { return null; }
		if ( this.props.token.owner && this.props.token.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) { return null; }

		return (
			<li>
				<button
					onClick={() => { window.open(`${this.state.marketplaceUrl}${this.state.chainId}/${this.props.token.contractAddress}/${this.props.token.tokenId}`, '_blank') }}
				>
					Sell
				</button>
			</li>
		)
	}
	getMenuItemCrossingPopup() {
		if ( this.state.crossing ) {
			return (
				<li>
					<button
						onClick={() => {
							this.setState({ freezePopup: true });
						}}
					>{ this.t('Prepare for crossing') }</button>
				</li>
			)
		}
	}
	getMenuUpdateURIButton() {
		if ( this.state.tokenURIRequested ) { return (
			<li><button>Request sent</button></li>
		) }

		return (
			<li>
				<button
					onClick={() => {
						this.setState({ tokenURIRequested: true });
						requestTokenURIUpdate({
							chainId: this.state.chainId,
							contractAddress: this.props.token.contractAddress,
							tokenId: this.props.token.tokenId,
						})
					}}
				>
					Request token data update
				</button>
			</li>
		)
	}
	openCardMenu = () => {
		setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = (e: any) => {
				if ( !this.userMenuBlockRef.current ) { return; }
				if ( e.path && e.path.includes(this.userMenuBlockRef.current) ) { return; }
				this.closeCardMenu();
			};
		}, 100);
		this.setState({ menuOpened: true });
	}
	closeCardMenu = () => {
		setTimeout(() => {
			if ( this.state.cursorOnCardMenu ) { return; }
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = null;
			this.setState({ menuOpened: false });
		}, 100);
	}
	getMenu() {

		return (
			<div className="btn-dropdown">
				{
					this.state.menuOpened ?
					(
						<ul
							onMouseEnter={() => {
								this.openCardMenu();
								this.setState({ cursorOnCardMenu: true })
							}}
							onMouseLeave={() => {
								this.closeCardMenu();
								this.setState({ cursorOnCardMenu: false })
							}}
						>
							{ this.getMenuTokenApproveButton() }
							{ this.getMenuTokenSellButton() }
							{ this.getMenuItemCrossingPopup() }
							{ this.getMenuUpdateURIButton() }
						</ul>
					) : null
				}
			</div>
		)
	}
	getMenuBtn() {
		if (
			!this.getMenuTokenApproveButton() &&
			!this.getMenuUpdateURIButton()
		) { return null; }

		return (
			<div
				className="btn-group"
				ref={ this.userMenuBlockRef }
				onMouseEnter={ this.openCardMenu }
				onMouseLeave={ this.closeCardMenu }
				onClick={ this.openCardMenu }
			>
				<button
					className={`btn btn-md btn-gray btn-img ${ this.state.menuOpened ? 'active' : ''}`}
				>
					<img src={ icon_dots_hor } alt="" />
				</button>
				{ this.getMenu() }
			</div>
		)
	}
	getToolbar() {
		return (
			<div className="col-lg-auto order-lg-2">
				<div className="wnft-header__actions">
					<div className="btns-group">
						{ this.getToolbarFeeApproveBtn() }

						{ this.getMenuTransferButton() }

						<div className="btn-group">
							<a
								className="btn btn-md btn-gray btn-img"
								href={`${this.state.explorerBaseUrl}/address/${this.props.token.contractAddress}`}
								target="_blank" rel="noopener noreferrer"
							>
								<img src={ icon_external_green } alt="" />
							</a>
						</div>

						{ this.getCopyLinkButton() }
						{ this.getMenuBtn() }
					</div>
				</div>
			</div>
		)
	}
	getTypeBlock() {
		if ( this.props.token.assetType === _AssetType.ERC721 ) {
			return ( <div className="wnft-header__type">{ this.state.EIPPrefix }-721</div> )
		}
		if ( this.props.token.assetType === _AssetType.ERC1155 ) {
			return ( <div className="wnft-header__type">{ this.state.EIPPrefix }-1155</div> )
		}

		return null;
	}
	getRulesBlock() {
		return (
			<div className="wnft-header__settings">


				{
					this.props.token.rules.noUnwrap ?
					( <Tippy
						content={ this.t('No unwrap') }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 260 }
					>
						<span>
							<img src={ icon_no_unwrap } alt="" />
						</span>
					</Tippy>) : null
				}

				{
					this.props.token.rules.noWrap ?
					( <Tippy
						content={ this.t('No wrap') }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 260 }
					>
						<span>
							<img src={ icon_no_wrap } alt="" />
						</span>
					</Tippy>) : null
				}

				{
					this.props.token.rules.noTransfer ?
					( <Tippy
						content={ this.t('No transfer') }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 260 }
					>
						<span>
							<img src={ icon_no_transfer } alt="" />
						</span>
					</Tippy>) : null
				}

				{
					this.props.token.rules.noAddCollateral ?
					( <Tippy
						content={ this.t('No collateral') }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 260 }
					>
						<span>
							<img src={ icon_no_collateral } alt="" />
						</span>
					</Tippy>) : null
				}

			</div>
		)
	}
	getLikesBlock() {
		return null;
		// return (
		// 	<React.Fragment>
		// 		<div className="btn-group wnft-header__likes">
		// 			<button className="btn btn-sm btn-gray">
		// 				<span className="wnft__like">
		// 					<img src={ icon_em_like } alt="" />
		// 					<span>1</span>
		// 				</span>
		// 				<span className="wnft__like">
		// 					<img src={ icon_em_heart } alt="" />
		// 					<span>2</span>
		// 				</span>
		// 			</button>
		// 		</div>
		// 		<div className="btn-group wnft-header__likes">
		// 			<button className="btn btn-sm btn-gray">
		// 				<span className="wnft__like empty"><img src={ icon_em_heart } alt="" /></span>
		// 			</button>
		// 			<div className="btn-dropdown">
		// 				<ul>
		// 					<li>
		// 						<button className="item"> <img src={ icon_em_heart } alt="" /></button>
		// 					</li>
		// 					<li>
		// 						<button className="item"> <img src={ icon_em_like } alt="" /></button>
		// 					</li>
		// 					<li>
		// 						<button className="item"> <img src={ icon_em_fire } alt="" /></button>
		// 					</li>
		// 				</ul>
		// 			</div>
		// 		</div>
		// 	</React.Fragment>
		// )
	}
	getHeaderInfo() {
		return (
			<div className="col-lg-auto order-lg-1">
				<div className="wnft-header__title">
					<CopyToClipboard
						text={ this.props.token.contractAddress }
						onCopy={() => {
							if ( !this.props.token ) { return; }
							this.setState({
								copiedHintWhere: 'headeraddress'
							});
							clearTimeout(this.copiedHintTimer);
							this.copiedHintTimer = window.setTimeout(() => { this.setState({
								copiedHintWhere: ''
							}); }, this.copiedHintTimeout*1000);
						}}
					>
						<button className="btn-copy">
							<Tippy
								content={ this.props.token.contractAddress }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span>
									<span className="text-muted">ADDRESS </span>
									<b>{ compactString(this.props.token.contractAddress) }</b>
								</span>
							</Tippy>
							<img src={icon_i_copy} alt="" />
							{ this.getCopiedHint('headeraddress') }
						</button>
					</CopyToClipboard>
					<CopyToClipboard
						text={ this.props.token.tokenId }
						onCopy={() => {
							if ( !this.props.token ) { return; }
							this.setState({
								copiedHintWhere: 'headerid'
							});
							clearTimeout(this.copiedHintTimer);
							this.copiedHintTimer = window.setTimeout(() => { this.setState({
								copiedHintWhere: ''
							}); }, this.copiedHintTimeout*1000);
						}}
					>
						<button className="btn-copy">
							<Tippy
								content={ this.props.token.tokenId }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span>
									<span className="text-muted">ID </span>
									<b>{ compactString(this.props.token.tokenId) }</b>
								</span>
							</Tippy>
							<img src={icon_i_copy} alt="" />
							{ this.getCopiedHint('headerid') }
						</button>
					</CopyToClipboard>
					<div className="wnft-header__extra">
						{ this.getTypeBlock()  }
						{ this.getRulesBlock() }
						{ this.getLikesBlock() }
					</div>
				</div>
			</div>
		)
	}
	getHeader() {
		return (
			<div className="wnft-header">
				<div className="row justify-content-lg-between">
					{ this.getToolbar() }
					{ this.getHeaderInfo() }
				</div>
			</div>
		)
	}
	toggleDescription = () => {
		this.setState({ descriptionOpened: !this.state.descriptionOpened })
	}
	getDescription() {
		if ( !this.props.token.description ) { return null; }

		if ( this.props.token.description.length < this.DESCRIPTION_LENGTH ) {
			return (
				<div className="desc">
					<p>{ this.props.token.description }</p>
				</div>
			)
		}

		if ( !this.state.descriptionOpened ) {
			return (
				<div className="desc">
					<p>{ this.props.token.description.slice(0, this.DESCRIPTION_LENGTH) } <button className="btn-more" onClick={this.toggleDescription}>...more</button></p>
				</div>
			)
		} else {
			return (
				<div className="desc">
					<p>{ this.props.token.description } <button className="btn-more"onClick={this.toggleDescription}>...less</button></p>
				</div>
			)
		}
	}
	getTokenInfo() {
		return (
			<div className="wnft-header__info">
				<div className="h4">wNFT Info</div>
				{ this.getDescription() }
				<ul className="meta">
					{ this.props.token.amount ? (<li><span className="text-muted">Copies:</span> { this.props.token.amount.toString() }</li>) : null }
					{ this.props.token.totalSupply ? (<li><span className="text-muted">Total supply:</span> { this.props.token.totalSupply.toString() }</li>) : null }
				</ul>
			</div>
		)
	}

	getTokenMedia() {
		if ( this.props.token && this.props.token.image === undefined ) {
			return (
				<div className="inner">
					<div className="default">
						<img src={ icon_cannot_load } alt="" />
						<span><Trans i18nKey="Loading NON-FUNGIBLE TOKEN Preview" components={[<br />]} /></span>
					</div>
				</div>
			)
		}
		if ( this.props.token && this.props.token.image === '' ) {
			return (
				<div className="inner">
					<div className="default">
						<img src={ icon_cannot_load } alt="" />
						<span><Trans i18nKey="Cannot load NON-FUNGIBLE TOKEN Preview" components={[<br />]} /></span>
					</div>
				</div>
			)
		}

		return (
			<div className="inner">
				<video className="img" src={ this.props.token ? this.props.token.image : '' } poster={ this.props.token ? this.props.token.image : '' } autoPlay={ true } muted={ true } loop={ true } />
			</div>
		)
	}
	getUnlockConditions() {
		const conditions: Array<{ label: string, param: string, reached: boolean }> = [];

		if ( this.props.token.assetType === _AssetType.ERC1155 ) {
			if ( this.props.token.amount && this.props.token.totalSupply ) {
				if ( !this.props.token.amount.eq(this.props.token.totalSupply) ) {
					conditions.push({ label: 'Number of copies', param: `${this.props.token.amount}/${this.props.token.totalSupply}`, reached: false });
				} else {
					conditions.push({ label: 'Number of copies', param: `${this.props.token.amount}/${this.props.token.totalSupply}`, reached: true });
				}
			}
		}

		this.props.token.locks.forEach((item: Lock) => {

			if ( item.lockType === LockType.time ) {
				const nowDate = new BigNumber(new Date().getTime());
				if ( item.param.gt( nowDate ) ) {
					conditions.push({ label: 'Time Unlock', param: unixtimeToStr(item.param), reached: false })
				} else {
					conditions.push({ label: 'Time Unlock', param: unixtimeToStr(item.param), reached: true })
				}
			}

			if ( item.lockType === LockType.value ) {
				if ( !this.props.token ) { return null; }

				if ( this.props.token.fees.length ) {

					let foundToken = undefined;
					if ( this.props.token.fees[0].token.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
						foundToken = this.state.techToken;
					} else {
						foundToken = this.state.erc20OtherTechTokens.find((iitem) => {
							if ( !this.props.token ) { return false; }
							return iitem.address.toLowerCase() === this.props.token.fees[0].token.toLowerCase()
						});
						if ( !foundToken ) {
							foundToken = this.state.erc20CollateralTokens.find((iitem) => {
								if ( !this.props.token ) { return false; }
								return iitem.address.toLowerCase() === this.props.token.fees[0].token.toLowerCase()
							});
						}
					}
					if ( !foundToken ) { return null; }

					const diff = tokenToFloat(item.param.plus(-this.props.token.collectedFees), foundToken.decimals || 18);

					if ( item.param.gt(this.props.token.collectedFees) ) {
						conditions.push({ label: 'Value  Unlock', param: `${addThousandSeparator(diff.toString())} more ${foundToken.symbol}`, reached: false })
					} else {
						conditions.push({ label: 'Value  Unlock', param: `Collected: ${tokenToFloat(this.props.token.collectedFees, foundToken.decimals || 18).toString()}`, reached: true })
					}

				}
			}

		})

		return conditions;
	}
	getUnwrapBtn() {

		if ( this.props.token.rules.noUnwrap ) {
			return (
				<div>
					<div className="alert">The wNFT cannot be unwrapped due to the rules set by the creator</div>
				</div>
			)
		}
		const conditions = this.getUnlockConditions().filter((item) => { return item.reached === false });
		return (
			<div>
				<button
					className="btn btn-grad"
					disabled={ !!conditions.length }
					onClick={() => {
						if ( this.state.authMethod === 'gnosis' ) {
							this.metamaskAdapter.wrapperContract.unwrapTokenMultisig(this.props.token, this.props.history);
						} else {
							this.metamaskAdapter.wrapperContract.unwrapToken(this.props.token, this.props.history);
						}
					}}
				>Unwrap</button>
			</div>
		)
	}
	getLockBlock() {
		const conditions = this.getUnlockConditions();
		if ( !conditions.length ) { return null; }

		return (

			<div className="status mt-4">
				<p>Unwrap will be ready after: </p>
				<ul className="status-list">
					{
						conditions.map((item) => {
							return (
								<li
									key={item.label}
									className={ item.reached ? 'done' : '' }
								>{ item.label }: <b>{ item.param }</b></li>
							)
						})
					}
				</ul>
			</div>
		)
	}
	getOriginalTokenInfo() {
		if ( !this.props.token.originalTokenInfo ) { return null; }
		return (
			<div className="c-wrap p-0">

				<div
					className={`c-wrap__toggle ${ this.state.originalTokenInfoOpened ? 'active' : '' }`}
					onClick={() => { this.setState({ originalTokenInfoOpened: !this.state.originalTokenInfoOpened }) }}
				>
					<div> <b>Original NFT Info </b></div>
				</div>

				<div className="c-wrap__dropdown border-top pt-2 pt-md-3">
					<div className="flex-nowrap row py-3">
						<div className="col-4">NFT type</div>
						<div className="col-8 text-right"><b>{ assetTypeToString(this.props.token.originalTokenInfo.assetType, this.state.EIPPrefix) }</b></div>
					</div>
					{
						this.props.token.originalTokenInfo.assetType !== _AssetType.empty ? (
							<>
							<div className="flex-nowrap row py-3">
								<div className="col-4">Name</div>
								<div className="col-8 text-right"><b>{ this.props.token.originalTokenInfo.name ? this.props.token.originalTokenInfo.name : '—' }</b></div>
							</div>
							<div className="flex-nowrap row py-3">
								<div className="col-4">Token ID</div>
								<div className="col-8 text-right"><b>{ this.props.token.originalTokenInfo.tokenId ? this.props.token.originalTokenInfo.tokenId : '—' }</b></div>
							</div>
							</>
						) : null
					}
					{
						this.props.token.originalTokenInfo.assetType !== _AssetType.empty ?
						(<Tippy
							content={ this.props.token.originalTokenInfo.tokenId ? this.props.token.originalTokenInfo.contractAddress : '—' }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 512 }
						>
							<div className="flex-nowrap row py-3">
								<div className="col-4">Address</div>
								<div className="col-8 text-right">
									<a target="_blank" rel="noopener noreferrer" href={`${this.state.explorerBaseUrl}/address/${this.props.token.originalTokenInfo.contractAddress}`}>
										<b>{ this.props.token.originalTokenInfo.contractAddress ? compactString(this.props.token.originalTokenInfo.contractAddress) : '—' }</b>
									</a>
								</div>
							</div>
						</Tippy>) : null
					}
					{
						this.props.token.originalTokenInfo.amount && this.props.token.originalTokenInfo.amount.gt(0) ?
						(
							<div className="flex-nowrap row py-3">
								<div className="col-4">Amount</div>
								<div className="col-8 text-right"><b>{ this.props.token.originalTokenInfo.amount.toString() }</b></div>
							</div>
						): null
					}
					{
						this.props.token.originalTokenInfo.assetType !== _AssetType.empty && this.props.token.originalTokenInfo.contractAddress && this.props.token.originalTokenInfo.contractAddress !== '0' ?
						( <div className="mt-3">
							<Link
								className="btn btn-md btn-border"
								to={`/token/${this.state.chainId}/${this.props.token.originalTokenInfo.contractAddress}/${this.props.token.originalTokenInfo.tokenId}`}
							>View original NFT</Link>
						</div> ) : null
					}

				</div>
			</div>
		)
	}
	getOwnerBlock() {
		if (
			(
				this.props.token.assetType === _AssetType.ERC1155 || !this.props.token.owner
			) &&
			!this.props.token.owner &&
			!this.props.token.sortParams?.blockNumber &&
			!this.props.token.create_tx
		) { return null; }

		return (
			<div className="c-wrap p-0">
				<div
					className={`c-wrap__toggle ${ this.state.showOwnerBlock ? 'active' : '' }`}
					onClick={() => { this.setState({ showOwnerBlock: !this.state.showOwnerBlock }) }}
				>
					<div><b>wNFT info</b></div>
				</div>
				<div className="c-wrap__dropdown border-top pt-2 pt-md-3">
					{
						this.props.token.assetType !== _AssetType.ERC1155 && this.props.token.owner ? (
							<div className="flex-nowrap row py-3">
								<div className="col-4">Owner</div>
								<Tippy
									content={ this.props.token.owner }
									appendTo={ document.getElementsByClassName("wrapper")[0] }
									trigger='mouseenter'
									interactive={ false }
									arrow={ false }
									maxWidth={ 512 }
								>
									<div className="col-8 text-right">{ compactString(this.props.token.owner) }</div>
								</Tippy>
							</div>
						) : null
					}
					{
						this.props.token.sortParams?.blockNumber ? (
							<div className="flex-nowrap row py-3">
								<div className="col-4">Date/block</div>
								<div className="col-8 text-right">
									<a target="_blank" rel="noopener noreferrer" href={`${this.state.explorerBaseUrl}/block/${this.props.token.sortParams.blockNumber}`}>
										{ this.props.token.sortParams.blockNumber }
									</a>
								</div>
							</div>
						) : null
					}
					{
						this.props.token.create_tx ? (
							<div className="flex-nowrap row py-3">
								<div className="col-4">Transaction</div>
								<div className="col-8 text-right">
									<a target="_blank" rel="noopener noreferrer" href={`${this.state.explorerBaseUrl}/tx/${this.props.token.create_tx}`}>
										{ compactString(this.props.token.create_tx) }
									</a>
								</div>
							</div>
						) : null
					}

				</div>
			</div>
		)
	}

	getCollateralRows() {
		return (
			<CollateralViewer
				store={this.store}
				metamaskAdapter={this.metamaskAdapter}
				t={ this.t }
				collaterals={ this.props.token.collateral }
				width={ 'narrow' }
				showHeader={ true }
			/>
		)
	}
	isAddCollateralBtnDisabled() {

		if ( this.props.token.rules.noAddCollateral ) { return true; }

		const foundLock = this.props.token.locks.find((item) => { return item.lockType === LockType.slots });
		if ( !foundLock ) { return false; }

		if ( foundLock.param.lte(0) ) {
			return true;
		}
	}
	getMaxSlots() {
		const foundLock = this.props.token.locks.find((item) => { return item.lockType === LockType.slots });
		if ( foundLock ) { return foundLock.param.toString(); }

		return `${this.state.maxCollaterals}`;
	}
	getCollateralBlock() {
		return (
			<div className="c-wrap">
				<div className="c-wrap__header">
					<div className="d-flex align-items-center flex-wrap">
						<div className="h4">Collateral </div>
						<span className="py-2">{ this.props.token.collateral.length } assets <span className="text-muted">(мах { this.getMaxSlots() })</span></span>
					</div>
					<div>
						<button
							className="btn btn-md btn-grad"
							disabled={ this.isAddCollateralBtnDisabled() }
							onClick={() => { this.setState({ addValuePopup: true }) }}
						>Add</button>
					</div>
				</div>
				{ this.getCollateralRows() }
			</div>
		)
	}

	getFeeToken() {
		if ( !this.props.token.fees.length ) { return undefined; }

		let feeToken = undefined;
		if ( this.props.token.fees[0].token.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
			feeToken = this.state.techToken;
		} else {
			const foundTech = this.state.erc20OtherTechTokens.filter((item) => {
				if ( !item.address ) { return false;}
				return item.address.toLowerCase() === this.props.token.fees[0].token.toLowerCase()
			});
			if ( foundTech.length ) {
				feeToken = foundTech[0];
			} else {
				const foundToken = this.state.erc20CollateralTokens.filter((item) => {
					if ( !item.address ) { return false;}
					return item.address.toLowerCase() === this.props.token.fees[0].token.toLowerCase()
				});
				if ( foundToken.length ) {
					feeToken = foundToken[0];
				}
			}
		}
		return feeToken;
	}
	getFeeBlock() {

		const feeToken = this.getFeeToken();

		if ( feeToken ) {
			return (
				<Tippy
					content={ `Amount of fee for the transfer of wrapped NFT` }
					appendTo={ document.getElementsByClassName("wrapper")[0] }
					trigger='mouseenter'
					interactive={ false }
					arrow={ false }
					maxWidth={ 260 }
				>
					<span>
						<span className="i-coin"><img src={ feeToken.icon || default_icon } alt="" /></span>
						<span className="sum">
							{ feeToken ? tokenToFloat(this.props.token.fees[0].value, feeToken.decimals || 18).toString() : this.props.token.fees[0].value.toString() }
						</span>
					</span>
				</Tippy>
			)
		} else {
			return (
				<Tippy
					content={ this.t('decimals is unknown; enter amount in wei') }
					appendTo={ document.getElementsByClassName("wrapper")[0] }
					trigger='mouseenter'
					interactive={ false }
					arrow={ false }
					maxWidth={ 512 }
				>
					<React.Fragment>
						<span className="i-coin"><img src={ default_icon } alt="" /></span>
						<span className="sum text-orange">
							{ this.props.token.fees[0].value.toString() }*
						</span>
					</React.Fragment>
				</Tippy>
			)
		}
	}
	getRoyaltyRows() {
		return this.props.token.royalties.map((item, idx) => {

			const feeToken = this.getFeeToken();
			const royaltyAmount = this.props.token.fees[0].value.multipliedBy( item.percent.dividedBy(100) );

			return (
				<div
					className="item"
					key={ !item.address || item.address.toLowerCase() === this.state.wrapperContractAddress.toLowerCase() ? 'thewnft' : item.address }
				>
					<div className="row">
						<div className="mb-2 col-3 col-md-1">#{ idx + 1 }</div>
						<div className="mb-2 mb-md-2 col-9 col-md-5">
							{
								!item.address || item.address.toLowerCase() === this.state.wrapperContractAddress.toLowerCase() ?
								<span className="text-break">the wNFT</span> :
								(<Tippy
									content={ item.address }
									appendTo={ document.getElementsByClassName("wrapper")[0] }
									trigger='mouseenter'
									interactive={ false }
									arrow={ false }
									maxWidth={ 512 }
								>
									<span className="text-break">
										{ compactString(item.address) }
									</span>
								</Tippy>)
							}
						</div>
						<div className="mb-2 col-12 col-md-1">{ item.percent.toString() }%</div>
						<div className="mb-2 col-12 col-md-5 md-right">
							<span className="col-legend">Amount:</span>
							{
								feeToken ?
								( <span className="text-break">{ tokenToFloat(royaltyAmount, feeToken.decimals || 18).toString() }</span> ) :
								(
									<Tippy
										content={ this.t('decimals is unknown; amount shown in wei') }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 260 }
									>
										<span className="text-break text-orange">{ royaltyAmount.toString() }*</span>
									</Tippy>
								)
							}
						</div>
					</div>
				</div>
			)
		})
	}
	getRoyaltyBlock() {
		if ( !this.props.token.fees.length ) { return null; }

		return (
			<div className="c-wrap">
				<div className="c-wrap__header d-block mt-2">
					<div className="row flex-wrap justify-content-md-between">
						<div className="col-12 col-md-auto">
							<div className="h4 mb-0">
								Royalty
								<Tippy
									content={ `The distribution of transfer fee between the royalty income receivers` }
									appendTo={ document.getElementsByClassName("wrapper")[0] }
									trigger='mouseenter'
									interactive={ false }
									arrow={ false }
									maxWidth={ 260 }
								>
									<span className="i-tip"></span>
								</Tippy>
							</div>
							<p className="d-md-none">{ this.t('List of royalty income receivers with personal percents') }</p>
						</div>
						<div className="wnft-royalty col-12 col-md-auto d-inline-flex align-items-center">
							{ this.getRoyaltyFeeApproveBtn() }

							{ this.getFeeBlock() }
						</div>
					</div>
					<p className="mb-0 d-none d-md-block">{ this.t('List of royalty income receivers with personal percents') }</p>
				</div>
				<div className="c-wrap__table mt-3">

					<div className="item item-header">
						<div className="row">
							<div className="mb-2 col-md-1"></div>
							<div className="mb-2 col-md-5">Recipient</div>
							<div className="mb-2 col-md-1">Percent</div>
							<div className="mb-2 col-md-5 md-right">Amount</div>
						</div>
					</div>

					{ this.getRoyaltyRows() }
				</div>
			</div>
		)
	}
	getHistory() {
		return null;
		// return (
		// 	<div className="c-wrap">
		// 		<div className="c-wrap__header">
		// 			<div className="h4 mt-2">Activity History</div>
		// 		</div>
		// 		<div className="c-wrap__table mt-3">
		// 			<div className="item">
		// 				<div className="row">
		// 					<div className="mb-2 col-6 col-md-3">
		// 						<div className="tb-activity">
		// 							<img className="icon" src="../pics/icons/i-transfer.svg" alt="" />
		// 							<span>Transfer</span>
		// 						</div>
		// 					</div>
		// 					<div className="mb-2 col-6 col-md-3">0x867....1185 </div>
		// 					<div className="mb-2 col-6 col-md-3"><span className="text-muted">to</span>  0x867.....1185</div>
		// 					<div className="mb-2 col-6 col-md-3 md-right">
		// 						<a className="ex-link" href="/">
		// 							<span>21.01.2021</span>
		// 							<img className="i-ex" src="../pics/icons/i-external.svg" alt="" />
		// 						</a>
		// 					</div>
		// 				</div>
		// 			</div>
		// 			<div className="item">
		// 				<div className="row">
		// 					<div className="mb-2 col-6 col-md-3">
		// 						<div className="tb-activity">
		// 							<img className="icon" src="../pics/icons/i-wrap.svg" alt="" />
		// 							<span>Wrap</span>
		// 						</div>
		// 					</div>
		// 					<div className="mb-2 col-6 col-md-3">0x867....1185 </div>
		// 					<div className="mb-2 col-6 col-md-3"> </div>
		// 					<div className="mb-2 col-6 col-md-3 md-right">
		// 						<a className="ex-link" href="/">
		// 							<span>21.01.2021</span>
		// 							<img className="i-ex" src="../pics/icons/i-external.svg" alt="" />
		// 						</a>
		// 					</div>
		// 				</div>
		// 			</div>
		// 			<div className="item">
		// 				<div className="row">
		// 					<div className="mb-2 col-6 col-md-3">
		// 						<div className="tb-activity">
		// 							<img className="icon" src="../pics/icons/i-unwrap.svg" alt="" />
		// 							<span>Unwrap</span>
		// 						</div>
		// 					</div>
		// 					<div className="mb-2 col-6 col-md-3">0x867....1185 </div>
		// 					<div className="mb-2 col-6 col-md-3"> </div>
		// 					<div className="mb-2 col-6 col-md-3 md-right">
		// 						<a className="ex-link" href="/">
		// 							<span>21.01.2021</span>
		// 							<img className="i-ex" src="../pics/icons/i-external.svg" alt="" />
		// 						</a>
		// 					</div>
		// 				</div>
		// 			</div>
		// 			<div className="item">
		// 				<div className="row">
		// 					<div className="mb-2 col-6 col-md-3">
		// 						<div className="tb-activity">
		// 							<img className="icon" src="../pics/icons/i-collateral.svg" alt="" />
		// 							<span>Collateral</span>
		// 						</div>
		// 					</div>
		// 					<div className="mb-2 col-6 col-md-3">0x867....1185 </div>
		// 					<div className="mb-2 col-6 col-md-3">
		// 						<div className="tb-coin" data-tippy-content="Token type: Native">
		// 							<span className="i-coin"><img src="../pics/coins/eth.svg" alt="" /></span>
		// 							<span className="name text-muted">0.00023</span>
		// 						</div>
		// 					</div>
		// 					<div className="mb-2 col-6 col-md-3 md-right">
		// 						<a className="ex-link" href="/">
		// 							<span>21.01.2021</span>
		// 							<img className="i-ex" src="../pics/icons/i-external.svg" alt="" />
		// 						</a>
		// 					</div>
		// 				</div>
		// 			</div>
		// 			<div className="item">
		// 				<div className="row">
		// 					<div className="mb-2 col-6 col-md-3">
		// 						<div className="tb-activity">
		// 							<img className="icon" src="../pics/icons/i-collateral.svg" alt="" />
		// 							<span>Collateral</span>
		// 						</div>
		// 					</div>
		// 					<div className="mb-2 col-6 col-md-3">0x867....1185 </div>
		// 					<div className="mb-2 col-6 col-md-3">
		// 						<div className="tb-nft sm" data-tippy-content="Token type: ERC-1155">
		// 							<img src="../pics/tb-nft-default.svg" alt="" />
		// 							<span className="info text-muted">4 items</span>
		// 						</div>
		// 					</div>
		// 					<div className="mb-2 col-6 col-md-3 md-right">
		// 						<a className="ex-link" href="/">
		// 							<span>21.01.2021</span>
		// 							<img className="i-ex" src="../pics/icons/i-external.svg" alt="" />
		// 						</a>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// )
	}

	getFreezePopup() {

		if ( !this.state.freezePopup ) { return null; }
		return (
			<CrossingFreezePopup
				store={ this.store }
				metamaskAdapter={ this.metamaskAdapter }
				token={ this.props.token }
				closePopup={()=>{
					this.setState({ freezePopup: false })
				}}
			/>
		)
	}

	render() {

		return (
			<React.Fragment>
				<main className="s-main">
					<div className="container">
						{ this.getBreadcrumbs() }
						<div className="wnft-content">
							<div className="row">

								<div className="col-lg-8 d-lg-none">
									{ this.getHeader() }
								</div>

								<div className="col-lg-4">
									<div className="wnft-card">

										{ this.getTokenMedia() }

										{
											(
												this.props.token.owner &&
												this.props.token.owner.toLowerCase() === this.state.userAddress.toLowerCase()
											) ||
											(
												this.props.token.amount &&
												this.props.token.amount.gt(0)
											)?
											( <div className="wnft-card__unwrap">
												{ this.getUnwrapBtn() }
												{ this.getLockBlock() }
											</div> ) : null
										}

									</div>

									{ this.getOriginalTokenInfo() }
									{ this.getOwnerBlock() }
								</div>

								<div className="col-lg-8">

									<div className="d-none d-lg-block">
										{ this.getHeader() }
									</div>

									{ this.getTokenInfo() }
									{ this.getCollateralBlock() }
									{ this.getRoyaltyBlock() }

								</div>
							</div>
							{ this.getHistory() }
						</div>
					</div>
				</main>
				{
					this.state.addValuePopup && this.props.token ?
					(
						<AddValuePopup
							store={ this.store }
							metamaskAdapter={ this.metamaskAdapter }
							token={ this.props.token }
							closePopup={()=>{
								this.setState({ addValuePopup: false })
							}}
						/>
					)
					: null
				}
				{
					this.state.transferPopup && this.props.token ?
					(
						<TransferPopup
							store={ this.store }
							metamaskAdapter={ this.metamaskAdapter }
							wrappedToken={ this.props.token }
							originalToken={ undefined }
							history={ this.props.history }
							closePopup={()=>{
								this.setState({ transferPopup: false })
							}}
						/>
					)
					: null
				}
				{
					this.state.approvePopup && this.props.token ?
					(
						<ApprovePopup
							store={ this.store }
							metamaskAdapter={ this.metamaskAdapter }
							wrappedToken={ this.props.token }
							originalToken={ undefined }
							history={ this.props.history }
							closePopup={()=>{
								this.setState({ approvePopup: false })
							}}
						/>
					)
					: null
				}
				{ this.getFreezePopup() }
			</React.Fragment>
		)
	}
}

export default withTranslation("translations")(withRouter(TokenWrappedPreviewPage));