
import React               from 'react';
import { withTranslation } from "react-i18next";
import {
	CollateralItem,
	ERC20ContractParamsType,
	_AssetType,
} from '../../models/BlockchainAdapter';

import default_icon from '../../static/pics/coins/_default.svg';
import default_nft  from '../../static/pics/coins/_default_nft.svg';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type CoinIconViewerProps = {
	store         : any,
	t             : any,
	tokens        : Array<CollateralItem>,

	onClick?      : any,
	onMouseEnter? : any,
	onMouseLeave? : any,
}
type CoinIconViewerState = {
	erc20CollateralTokens: Array<ERC20ContractParamsType>,
	erc20OtherTechTokens: Array<ERC20ContractParamsType>,
	decimalsNative   : number,
	symbolNative     : string,
	iconNative       : string,
	techToken        : ERC20ContractParamsType,
	explorerBaseUrl  : string,
}

class CoinIconViewer extends React.Component<CoinIconViewerProps, CoinIconViewerState> {

	store         : any;
	unsubscribe!  : Function;
	t             : any;

	onClick?      : any;
	onMouseEnter? : any;
	onMouseLeave? : any;

	constructor(props: CoinIconViewerProps) {
		super(props);

		this.store        = props.store;
		this.t            = props.t;

		this.onClick      = props.onClick;
		this.onMouseEnter = props.onMouseEnter;
		this.onMouseLeave = props.onMouseLeave;

		const explorerBaseUrl       = this.store.getState().metamaskAdapter.explorerBaseUrl;
		const decimalsNative        = this.store.getState().metamaskAdapter.networkTokenDecimals;
		const symbolNative          = this.store.getState().metamaskAdapter.networkTokenTicket;
		const iconNative            = this.store.getState().metamaskAdapter.networkTokenIcon;
		const techToken             = this.store.getState().erc20TechTokenParams;
		const erc20CollateralTokens = this.store.getState().erc20CollateralTokens;
		const erc20OtherTechTokens  = this.store.getState().erc20OtherTechTokens;

		this.state = {
			explorerBaseUrl,
			decimalsNative,
			symbolNative,
			iconNative,
			erc20CollateralTokens,
			erc20OtherTechTokens,
			techToken,
		}
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {

			const explorerBaseUrl       = this.store.getState().metamaskAdapter.explorerBaseUrl;
			const decimalsNative        = this.store.getState().metamaskAdapter.networkTokenDecimals;
			const symbolNative          = this.store.getState().metamaskAdapter.networkTokenTicket;
			const iconNative            = this.store.getState().metamaskAdapter.networkTokenIcon;
			const techToken             = this.store.getState().erc20TechTokenParams;
			const erc20CollateralTokens = this.store.getState().erc20CollateralTokens;
			const erc20OtherTechTokens  = this.store.getState().erc20OtherTechTokens;

			this.setState({
				explorerBaseUrl,

				decimalsNative,
				symbolNative,
				iconNative,

				erc20CollateralTokens,
				erc20OtherTechTokens,
				techToken,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	getCollateralItem(item: CollateralItem) {

		// native token
		if ( item.assetType === _AssetType.native && item.amount ) {
			return ( <span className="i-coin" key={ 'native' }><img src={ this.state.iconNative || default_icon } alt="" /></span> )
		}

		if ( item.assetType === _AssetType.ERC20 && item.amount ) {
			// tech token
			if ( item.address.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
				return ( <span className="i-coin" key={ 'tech' }><img src={ this.state.techToken.icon || default_icon } alt="" /></span> )
			}

			// Common ERC20
			const foundTech = this.state.erc20OtherTechTokens.filter((iitem: ERC20ContractParamsType) => {
				if ( !iitem.address ) { return false; }
				return item.address.toLowerCase() === iitem.address.toLowerCase()
			});
			if ( foundTech.length ) {
				// known ERC20
				return ( <span className="i-coin" key={ foundTech[0].address }><img src={ foundTech[0].icon || default_icon } alt="" /></span> )
			}

			// Common ERC20
			const foundERC20 = this.state.erc20CollateralTokens.filter((iitem: ERC20ContractParamsType) => {
				if ( !iitem.address ) { return false; }
				return item.address.toLowerCase() === iitem.address.toLowerCase()
			});
			if ( foundERC20.length ) {
				// known ERC20
				return ( <span className="i-coin" key={ foundERC20[0].address }><img src={ foundERC20[0].icon || default_icon } alt="" /></span> )
			} else {
				// unknown ERC20
				return ( <span className="i-coin" key={ item.address }><img src={ default_icon } alt="" /></span> )
			}
		}

		if ( item.assetType === _AssetType.ERC721 ) {
			return ( <span className="i-coin" key={ `${item.address}${item.tokenId}` }><img src={ item.tokenImg || default_nft } alt="" /></span> )
		}

		if ( item.assetType === _AssetType.ERC1155 ) {
			return ( <span className="i-coin" key={ `${item.address}${item.tokenId}` }><img src={ item.tokenImg || default_nft } alt="" /></span> )
		}

		return null;
	}
	render() {
		return (
			<div className="coins">
				{
					this.props.tokens
						.sort((item, prev) => {
							if ( item.assetType < prev.assetType ) { return -1 }
							if ( item.assetType > prev.assetType ) { return  1 }

							if ( item.address.toLowerCase() < prev.address.toLowerCase() ) { return -1 }
							if ( item.address.toLowerCase() > prev.address.toLowerCase() ) { return  1 }

							if ( item.tokenId && prev.tokenId ) {
								try {
									if ( new BigNumber(item.tokenId).isNaN() || new BigNumber(prev.tokenId).isNaN() ) {
										if ( parseInt(`${item.tokenId}`) < parseInt(`${prev.tokenId}`) ) { return -1 }
										if ( parseInt(`${item.tokenId}`) > parseInt(`${prev.tokenId}`) ) { return  1 }
									}
									const itemTokenIdNumber = new BigNumber(item.tokenId);
									const prevTokenIdNumber = new BigNumber(prev.tokenId);

									if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return -1 }
									if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return  1 }
								} catch ( ignored ) {
									if ( `${item.tokenId}`.toLowerCase() < `${prev.tokenId}`.toLowerCase() ) { return -1 }
									if ( `${item.tokenId}`.toLowerCase() > `${prev.tokenId}`.toLowerCase() ) { return  1 }
								}
							}

							return 0
						})
						.slice(0, 10)
						.map((item) => { return this.getCollateralItem(item); })
				}
			</div>
		)
	}
}

export default withTranslation("translations")(CoinIconViewer);