
import Web3         from 'web3';
import { Contract } from "web3-eth-contract";
import erc20_abi    from '../../abis/_erc20.json';

import MetamaskAdapter from './metamaskadapter';

import {
	CollateralItem,
	encodeFees,
	encodeLocks,
	encodeRoyalties,
	encodeRules,
	Fee,
	getNativeCollateral,
	Lock,
	Royalty,
	Rules,
	SAFTRecipientItem,
	_Asset,
	_AssetType,
} from './_types';
import ERC20Contract from './erc20contract';
import {
	AdvancedLoaderStageType,
	clearInfo,
	setError,
	setInfo,
	setLoading,
	unsetLoading,
	_AdvancedLoadingStatus,
	createAdvancedLoading,
	updateStepAdvancedLoading,
} from '../../reducers';
import {
	checkApprovalERC721Token,
	setApprovalForAllERC721Token,
	setApprovalForAllERC721TokenMultisig
} from './erc721contract';
import {
	checkApprovalERC1155Token,
	setApprovalERC1155Token,
	setApprovalERC1155TokenMultisig
} from './erc1155contract';
import { getABI } from '../_utils';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type SAFTDispatcherPropsType = {
	web3                 : Web3,
	metamaskAdapter      : MetamaskAdapter,
	store                : any,
	batchWorkerAddress   : string,
	t                    : any,
}

export default class SAFTDispatcher {

	web3                          : Web3;
	metamaskAdapter               : MetamaskAdapter;
	store                         : any;
	t                             : any;

	batchWorkerAddress            : string;
	batchWorker!                  : Contract;
	trustedWrapperAddress!        : string;
	trustedWrapper!               : Contract;
	batchWhitelistContractAddress!: string;
	batchWhitelistContract!       : Contract;
	subscriptionRegistryAddress!  : string;

	whitelist                     : Array<_Asset>;
	blacklist                     : Array<_Asset>;

	batchTechTokenAddress!        : string;
	batchTechToken!               : ERC20Contract;

	batchCollaterals              : Array<ERC20Contract>;

	constructor(props: SAFTDispatcherPropsType) {
		this.web3                  = props.web3;
		this.metamaskAdapter       = props.metamaskAdapter;
		this.store                 = props.store;
		this.batchWorkerAddress    = props.batchWorkerAddress;
		this.t                     = props.t;
		this.whitelist             = [];
		this.blacklist             = [];
		this.batchCollaterals      = [];

		this.createContracts()
			.catch((e: any) => {
				this.store.dispatch(setError({
					text: `${this.t('Cannot init SAFT')}: ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
		});
	}

	async createContracts() {
		let batchWorkerABI;
		try {
			batchWorkerABI = getABI(this.metamaskAdapter.chainId || 0, this.batchWorkerAddress, 'batchworker');
		} catch(e) {
			console.log(`Cannot load ${this.batchWorkerAddress} batchworker abi:`, e);
			throw new Error(`Cannot load batchworker abi`);
		}
		this.batchWorker = new this.web3.eth.Contract(batchWorkerABI, this.batchWorkerAddress);

		try {
			const fetchedSubscriptionContract = await this.batchWorker.methods.subscriptionRegistry().call();
			if ( fetchedSubscriptionContract !== '0x0000000000000000000000000000000000000000' ) {
				this.subscriptionRegistryAddress = fetchedSubscriptionContract;
			}
		} catch(e) { console.log(`Cannot fetch subscription contract address:`, e); }

		this.trustedWrapperAddress = await this.batchWorker.methods.trustedWrapper().call();
		if ( this.trustedWrapperAddress === '0x0000000000000000000000000000000000000000' ) {
			console.log('No trustedWrapper address in batchWrapper');
			throw new Error(`No trustedWrapper address in batchWrapper`);
		}
		let trustedWrapperABI;
		try {
			trustedWrapperABI = getABI(this.metamaskAdapter.chainId || 0, this.trustedWrapperAddress, 'trustedWrapper');
		} catch(e) {
			console.log(`Cannot load ${this.trustedWrapperAddress} trustedWrapper abi:`, e);
			throw new Error(`Cannot load trustedWrapper abi`);
		}
		this.trustedWrapper = new this.web3.eth.Contract(trustedWrapperABI, this.trustedWrapperAddress);

		this.batchWhitelistContractAddress = await this.trustedWrapper.methods.protocolWhiteList().call();
		if ( this.batchWhitelistContractAddress === '0x0000000000000000000000000000000000000000' ) {
			console.log('No whitelist contract address in trustedWrapper');
			throw new Error(`No whitelist contract address in trustedWrapper`);
		}
		let batchWhitelistContractABI;
		try {
			batchWhitelistContractABI = getABI(this.metamaskAdapter.chainId || 0, this.batchWhitelistContractAddress, 'batchWhitelistContract');
		} catch(e) {
			console.log(`Cannot load ${this.batchWhitelistContractAddress} batchWhitelistContract abi:`, e);
			throw new Error(`Cannot load batchWhitelistContract abi`);
		}
		this.batchWhitelistContract = new this.web3.eth.Contract(batchWhitelistContractABI, this.batchWhitelistContractAddress);
		this.batchWhitelistContract.methods.getBLAddresses().call().then((data: Array<_Asset>) => {
			this.blacklist = data;
		});
		this.batchWhitelistContract.methods.getWLAddresses().call().then((data: Array<_Asset>) => {
			this.whitelist = data.map((item) => { return { ...item, assetType: parseInt(`${item.assetType}`) } });

			this.whitelist.forEach((item: _Asset) => {
				if ( item.assetType === _AssetType.ERC20 ) {
					this.batchCollaterals.push(new ERC20Contract({
						web3                : this.web3,
						store               : this.store,
						contractAddress     : item.contractAddress,
						contractType        : 'collateral_batchwrapper',
						userAddress         : this.metamaskAdapter.userAddress,
						wrapperAddress      : this.trustedWrapperAddress,
						whitelistContract   : this.batchWhitelistContract,
					}));
				}
			});
		});

		this.batchTechTokenAddress = await this.trustedWrapper.methods.protocolTechToken().call();
		this.batchTechToken = new ERC20Contract({
			web3                : this.web3,
			store               : this.store,
			contractAddress     : this.batchTechTokenAddress,
			contractType        : 'tech_batchwrapper',
			userAddress         : this.metamaskAdapter.userAddress,
			wrapperAddress      : this.trustedWrapperAddress,
			whitelistContract   : this.batchWhitelistContract,
		});

		const erc20FromConfig = this.store.getState().metamaskAdapter.batchWrapSupportedERC20Tokens;
		if ( erc20FromConfig ) {
			erc20FromConfig.forEach((item: _Asset) => {
				if ( item.assetType === _AssetType.ERC20 ) {
					this.batchCollaterals.push(new ERC20Contract({
						web3                : this.web3,
						store               : this.store,
						contractAddress     : item.contractAddress,
						contractType        : 'collateral_batchwrapper',
						userAddress         : this.metamaskAdapter.userAddress,
						wrapperAddress      : this.trustedWrapperAddress,
						whitelistContract   : this.batchWhitelistContract,
					}));
				}
			});
		}
	}

	isReady() {
		return !!this.batchWorker && !!this.trustedWrapper && !!this.batchWhitelistContract
	}

	async SAFTCheckOriginalTokenAllowances(
		originalAddress: _Asset | undefined,
		advancedLoaderStage?: AdvancedLoaderStageType,
		isMultisig?: boolean,
	) {
		if ( !originalAddress ) { return; }

		if ( advancedLoaderStage ) {
			this.store.dispatch(updateStepAdvancedLoading({
				id: 'approvenft',
				sortOrder: 1,
				text: `${this.t('Approving original tokens')}`,
				status: _AdvancedLoadingStatus.loading,
			}));
		} else {
			this.store.dispatch(setLoading({ msg: `${this.t('Approving original tokens')}` }));
		}

		if ( originalAddress.assetType === _AssetType.ERC721 ) {
			const approved = await checkApprovalERC721Token({
				metamaskAdapter: this.metamaskAdapter,
				contractAddress: originalAddress.contractAddress,
				userAddress: this.metamaskAdapter.userAddress,
				addressTo: this.trustedWrapperAddress
			});

			if ( !approved ) {
				console.log(`making allowance for all of ${originalAddress.contractAddress}`)
				if ( isMultisig ) {
					await setApprovalForAllERC721TokenMultisig(
						this.metamaskAdapter,
						originalAddress.contractAddress,
						this.metamaskAdapter.userAddress,
						this.trustedWrapperAddress,
						this.t,
					);
				} else {
					await setApprovalForAllERC721Token(
						this.metamaskAdapter,
						originalAddress.contractAddress,
						this.metamaskAdapter.userAddress,
						this.trustedWrapperAddress,
						this.t,
					);
				}
			}
		}

		if ( originalAddress.assetType === _AssetType.ERC1155 ) {
			const approved = await checkApprovalERC1155Token({
				metamaskAdapter: this.metamaskAdapter,
				contractAddress: originalAddress.contractAddress,
				userAddress: this.metamaskAdapter.userAddress,
				addressTo: this.trustedWrapperAddress
			});

			if ( !approved ) {
				console.log(`making allowance for all of ${originalAddress.contractAddress}`)
				if ( isMultisig ) {
					await setApprovalERC1155TokenMultisig(
						this.metamaskAdapter,
						originalAddress.contractAddress,
						this.metamaskAdapter.userAddress,
						this.trustedWrapperAddress,
						this.t,
					);
				} else {
					await setApprovalERC1155Token(
						this.metamaskAdapter,
						originalAddress.contractAddress,
						this.metamaskAdapter.userAddress,
						this.trustedWrapperAddress,
						this.t,
					);
				}
			}
		}

	}
	async makeAllowanceUnknown(
		collateral: CollateralItem,
		allowanceToCheck: BigNumber,
		EIPStandart: string
	): Promise<void> {
		// unknown token
		let contract;
		try {
			contract = new this.web3.eth.Contract(erc20_abi as any, collateral.address);
		} catch (e) {
			throw new Error(`Cannot connect to ERC20 contract (${collateral.address}): ${e}`);
		}

		const symbol = await contract.methods.symbol().call();
		this.store.dispatch(setLoading({ msg: `${ this.t('Checking approve') } (${ EIPStandart }): ${ symbol }` }));

		const balance   = new BigNumber(await contract.methods.balanceOf(this.metamaskAdapter.userAddress).call());
		const allowance = new BigNumber(await contract.methods.allowance(this.metamaskAdapter.userAddress, this.trustedWrapperAddress).call());

		if ( balance.lt(allowanceToCheck) ) { throw new Error(`Not enough balance of ${ symbol }`) }
		if ( allowance.lt(allowanceToCheck) ) {
			this.store.dispatch(setLoading({ msg: `${ this.t('Waiting for approve') } (${ EIPStandart }): ${ symbol }` }));
			await contract.methods.approve(this.trustedWrapperAddress, allowanceToCheck.toString()).send({ from: this.metamaskAdapter.userAddress });
		}
	}
	async makeAllowanceUnknownMultisig(
		collateral: CollateralItem,
		allowanceToCheck: BigNumber,
		EIPStandart: string
	): Promise<void> {
		return new Promise(async (res, rej) => {
			// unknown token
			let contract;
			try {
				contract = new this.web3.eth.Contract(erc20_abi as any, collateral.address);
			} catch (e) {
				rej(new Error(`Cannot connect to ERC20 contract (${collateral.address}): ${e}`));
				return;
			}

			const symbol = await contract.methods.symbol().call();
			this.store.dispatch(setLoading({ msg: `${ this.t('Checking approve') } (${ EIPStandart }): ${ symbol }` }));

			const balance   = new BigNumber(await contract.methods.balanceOf(this.metamaskAdapter.userAddress).call());
			const allowance = new BigNumber(await contract.methods.allowance(this.metamaskAdapter.userAddress, this.trustedWrapperAddress).call());

			if ( balance.lt(allowanceToCheck) ) { rej(new Error(`Not enough balance of ${ symbol }`)) }
			if ( allowance.lt(allowanceToCheck) ) {
				this.store.dispatch(setLoading({ msg: `${ this.t('Waiting for approve') } (${ EIPStandart }): ${ symbol }` }));
				contract.methods.approve(this.trustedWrapperAddress, allowanceToCheck.toString()).send({ from: this.metamaskAdapter.userAddress }, (err: any, data: any) => {
					if ( err ) { rej(err); }
					res(data);
				});
			}
		})
	}
	async SAFTCheckCollateralAllowances(
		collaterals: Array<CollateralItem>,
		qty        : number,
		advancedLoaderStage?: AdvancedLoaderStageType,
		isMultisig?: boolean
	) {
		const EIPStandart = `${this.store.getState().metamaskAdapter.EIPPrefix}-20` || 'ERC-20';
		if ( advancedLoaderStage ) {
			this.store.dispatch(updateStepAdvancedLoading({
				id: 'approveerc20collateral',
				sortOrder: 2,
				text: `Approving ${ EIPStandart } collateral tokens`,
				status: _AdvancedLoadingStatus.loading,
				current: 1,
				total: collaterals.length,
			}));
		} else {
			this.store.dispatch(setLoading({ msg: `Approving ${ EIPStandart } collateral tokens` }));
		}

		for ( let idx = 0; idx < collaterals.length; idx++ ) {

			const collateral = collaterals[idx];
			// skip native
			if ( collateral.address === '' || collateral.address === '' || collateral.address === '0x0000000000000000000000000000000000000000' ) { continue; }
			if (
				this.metamaskAdapter.wrapperContract.getTechTokenContract(collateral.address) ||
				this.batchTechTokenAddress.toLowerCase() === collateral.address.toLowerCase()
			) { continue; }
			if ( !collateral.amount || collateral.amount.eq(0) ) { continue; }
			const allowanceToCheck = collateral.amount.multipliedBy(new BigNumber(qty));

			const foundERC20Contract = this.metamaskAdapter.getERC20Contract(collateral.address);
			if ( foundERC20Contract ) {
				// known token
				if ( advancedLoaderStage ) {
					this.store.dispatch(updateStepAdvancedLoading({
						id: 'approveerc20collateral',
						sortOrder: 2,
						text: `Approving ${ EIPStandart } collateral tokens: ${foundERC20Contract.erc20Params.symbol}`,
						status: _AdvancedLoadingStatus.loading,
						current: idx + 1,
						total: collaterals.length,
					}));
				} else {
					this.store.dispatch(setLoading({ msg: `Approving ${ EIPStandart } collateral tokens: ${foundERC20Contract.erc20Params.symbol}` }));
				}

				const balance = await foundERC20Contract.getBalance(this.trustedWrapperAddress);
				if ( !balance ) { throw new Error(`Cannot update balance of ${ foundERC20Contract.erc20Params.symbol }`) }
				if ( balance.balance.lt(allowanceToCheck) ) { throw new Error(`Not enough balance of ${ foundERC20Contract.erc20Params.symbol }`) }
				if ( balance.allowance.lt(allowanceToCheck) ) {
					this.store.dispatch(setLoading({ msg: `${ this.t('Waiting for approve') } (${ EIPStandart }): ${ foundERC20Contract.erc20Params.symbol }` }));
					if ( isMultisig ) {
						await foundERC20Contract.makeAllowanceMultisig(allowanceToCheck, this.trustedWrapperAddress)
					} else {
						await foundERC20Contract.makeAllowance(allowanceToCheck, this.trustedWrapperAddress)
					}
				}

			} else {
				// unknown token
				if ( isMultisig ) {
					await this.makeAllowanceUnknownMultisig(collateral, allowanceToCheck,EIPStandart);
				} else {
					await this.makeAllowanceUnknown(collateral, allowanceToCheck,EIPStandart);
				}
			}
		}
	}
	createAdvancedLoaderWrap(params: {
		originalAddress: _Asset | undefined,
		recipients     : Array<SAFTRecipientItem>,
		collaterals    : Array<CollateralItem>,
		unwrapAfter    : BigNumber,
		unwrapDestination: string,
		outBalance       : number,
		fees             : Array<Fee>,
		royalties        : Array<Royalty>,
		locks            : Array<Lock>,
		rules            : Rules,
		outType          : _AssetType,
	}) {
		const loaderStages: Array<AdvancedLoaderStageType> = [{
			id: 'approvenft',
			sortOrder: 1,
			text: 'Checking approve original tokens',
			status: _AdvancedLoadingStatus.queued
		}];

		const qtyERC20Collaterals = params.collaterals.filter((item) => {
			const foundERC20Contract = this.metamaskAdapter.getERC20Contract(item.address);
			if ( foundERC20Contract && ( foundERC20Contract.contractType === 'tech' || foundERC20Contract.contractType === 'tech_wrapper' ) ) { return false }

			return item.assetType === _AssetType.ERC20
		});
		if ( qtyERC20Collaterals.length ) {
			loaderStages.push({
				id: 'approveerc20collateral',
				sortOrder: 2,
				text: 'Approving ERC-20 collateral tokens',
				status: _AdvancedLoadingStatus.queued,
				current: 0,
				total: qtyERC20Collaterals.length,
			});
		}

		const qty721Collaterals = params.collaterals.filter((item) => {
			return item.assetType === _AssetType.ERC721
		});
		if ( qty721Collaterals.length ) {
			loaderStages.push({
				id: 'approve721collateral',
				sortOrder: 3,
				text: 'Approving ERC-721 collateral tokens',
				status: _AdvancedLoadingStatus.queued,
				current: 0,
				total: qty721Collaterals.length,
			});
		}

		const qty1155Collaterals = params.collaterals.filter((item) => {
			return item.assetType === _AssetType.ERC1155
		});
		if ( qty1155Collaterals.length ) {
			loaderStages.push({
				id: 'approve1155collateral',
				sortOrder: 4,
				text: 'Approving ERC-1155 collateral tokens',
				status: _AdvancedLoadingStatus.queued,
				current: 0,
				total: qty1155Collaterals.length,
			});
		}

		loaderStages.push({
			id: 'wrap',
			sortOrder: 6,
			text: 'Wrapping batch of tokens',
			status: _AdvancedLoadingStatus.queued
		});

		return {
			title: 'Waiting for wrap',
			stages: loaderStages
		}
	}
	async SAFTToken(params: {
		originalAddress        : _Asset | undefined,
		recipients             : Array<SAFTRecipientItem>,
		collaterals            : Array<CollateralItem>,
		unwrapAfter            : BigNumber,
		unwrapDestination      : string,
		outBalance             : number,
		fees                   : Array<Fee>,
		royalties              : Array<Royalty>,
		locks                  : Array<Lock>,
		rules                  : Rules,
		outType                : _AssetType,
		updateSubscriptionFunc?: () => void,
		isMultisig?            : boolean
	}) {

		const EIPStandart = `${this.store.getState().metamaskAdapter.EIPPrefix}-20` || 'ERC-20';

		const advLoader = this.createAdvancedLoaderWrap(params);
		this.store.dispatch(createAdvancedLoading(advLoader));

		const approveOriginalLoader = advLoader.stages.find((item) => { return item.id === 'approvenft' });
		try {
			await this.SAFTCheckOriginalTokenAllowances(params.originalAddress, approveOriginalLoader, params.isMultisig);
			if ( approveOriginalLoader ) {
				this.store.dispatch(updateStepAdvancedLoading({
					id: 'approvenft',
					sortOrder: 1,
					text: `${this.t('Approving original tokens')}`,
					status: _AdvancedLoadingStatus.complete,
				}));
			}
		} catch (e: any) {
			console.log('Cannot approve while batchwraping while approving: ', e);

			if ( params.updateSubscriptionFunc ) { params.updateSubscriptionFunc(); }
			this.store.dispatch(unsetLoading());

			let errorMsg = '';
			if ('message' in e) {
				errorMsg = e.message
			} else {
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
			}

			let links = undefined;
			if ('transactionHash' in e) {
				links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
			} else {
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					const txHash = errorParsed.transactionHash;
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
				} catch(ignored) {}
			}

			this.store.dispatch(setError({
				text: `${this.t('Cannot batchwrap token')}: ${errorMsg}`,
				buttons: undefined,
				links: links,
			}));
			return;
		}

		const approveERC20Loader = advLoader.stages.find((item) => { return item.id === 'approveerc20collateral' });
		try {
			await this.SAFTCheckCollateralAllowances(params.collaterals, params.recipients.length, approveERC20Loader, params.isMultisig);
			if ( approveERC20Loader ) {
				this.store.dispatch(updateStepAdvancedLoading({
					id: 'approveerc20collateral',
					sortOrder: 2,
					text: `Approving ${ EIPStandart } collateral tokens`,
					status: _AdvancedLoadingStatus.complete,
					current: params.collaterals.length,
					total: params.collaterals.length,
				}));
			}
		} catch (e: any) {
			console.log('Cannot approve while batchwraping while approving: ', e);

			if ( params.updateSubscriptionFunc ) { params.updateSubscriptionFunc(); }
			this.store.dispatch(unsetLoading());

			let errorMsg = '';
			if ('message' in e) {
				errorMsg = e.message
			} else {
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
			}

			let links = undefined;
			if ('transactionHash' in e) {
				links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
			} else {
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					const txHash = errorParsed.transactionHash;
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
				} catch(ignored) {}
			}

			this.store.dispatch(setError({
				text: `${this.t('Cannot batchwrap token')}: ${errorMsg}`,
				buttons: undefined,
				links: links,
			}));
			return;
		}

		if ( advLoader ) {
			this.store.dispatch(updateStepAdvancedLoading({
				id: 'wrap',
				sortOrder: 6,
				text: `Wrapping batch of tokens`,
				status: _AdvancedLoadingStatus.loading,
			}));
		} else {
			this.store.dispatch(setLoading({ msg: `Wrapping batch of tokens` }));
		}

		const nativeCollateral = getNativeCollateral(params.collaterals).multipliedBy(params.recipients.length);
		const recievers = params.recipients.map((item) => { return item.userAddress });

		const collateralsParsed = params.collaterals
			.map((item) => {
				// if ( !item.amount || item.amount.eq(0) ) { return null; }
				return {
					asset: {
						assetType: item.assetType,
						contractAddress: item.address
					},
					tokenId: '0',
					amount: item.amount ? item.amount.toString() : '0'
				}
			});

		let inDataParsed = params.recipients.map((item) => {
			return {
				inAsset: {
					asset: {
						assetType: params.originalAddress && params.originalAddress.assetType ? params.originalAddress.assetType : _AssetType.empty,
						contractAddress: params.originalAddress && params.originalAddress.contractAddress ? params.originalAddress.contractAddress : '0x0000000000000000000000000000000000000000'
					},
					tokenId: item.tokenId || '0',
					amount: '0',
				},
				unWrapDestination: params.unwrapDestination,
				fees             : encodeFees(params.fees),
				locks            : encodeLocks(params.locks),
				royalties        : encodeRoyalties(params.royalties, this.trustedWrapperAddress),
				outType          : params.outType,
				outBalance       : params.outBalance.toString(),
				rules            : encodeRules(params.rules),
			}
		});

		const tx = this.batchWorker.methods.wrapBatch(
			inDataParsed,
			collateralsParsed,
			recievers
		);

		// pre-send transaction check
		const txParams: any = { from: this.metamaskAdapter.userAddress }
		if ( !nativeCollateral.eq(0) ) { txParams.value = nativeCollateral.toString() }

		if ( !params.isMultisig ) {
			// pre-send transaction check
			try {
				await tx.estimateGas(txParams)
			} catch(e: any) {
				console.log('Cannot batchwrap before send: ', e);
				let errorMsg = '';

				if ('message' in e) {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.message
							.replace('execution reverted: ', '');
					} catch(ignored) {}
				}

				this.store.dispatch(setError({
					text: `${this.t('Cannot batchwrap token')}: ${errorMsg || e}`,
					buttons: undefined,
					links: undefined,
				}));

				if ( params.updateSubscriptionFunc ) { params.updateSubscriptionFunc(); }
				this.store.dispatch(unsetLoading());
				return;
			}
		}

		if ( params.isMultisig ) {
			tx
				.send(txParams, (err: any, data: any) => {
					if ( err ) {
						console.log('Cannot saft after send: ', err);
						this.store.dispatch(setError({
							text: `${this.t('Cannot wrap token')}: ${err.message}`,
							buttons: undefined,
							links: undefined,
						}));
						this.store.dispatch(unsetLoading());
						return;
					}

					if ( params.updateSubscriptionFunc ) { params.updateSubscriptionFunc(); }
					this.store.dispatch(unsetLoading());

					this.store.dispatch(setInfo({
						text: `${this.t('Transaction has been created')}`,
						buttons: [{
							text: 'Ok',
							clickFunc: () => { this.store.dispatch(clearInfo()) }
						}],
						links: [{
							text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
							url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
						}]
					}));
				})
		} else {
			tx
				.send(txParams)
				.then((data: any) => {
					if ( params.updateSubscriptionFunc ) { params.updateSubscriptionFunc(); }
					this.store.dispatch(unsetLoading());

					if ( advLoader ) {
						this.store.dispatch(updateStepAdvancedLoading({
							id: 'wrap',
							sortOrder: 6,
							text: `Wrapping batch of tokens`,
							status: _AdvancedLoadingStatus.complete,
						}));
					}

					this.store.dispatch(setInfo({
						text: `${this.t('Non-Fungible Tokens are Successfully Wrapped')}`,
						buttons: [{
							text: 'Ok',
							clickFunc: () => { this.store.dispatch(clearInfo()) }
						}],
						links: [{
							text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
							url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
						}]
					}));
				})
				.catch((e: any) => {
					console.log('Cannot wrap after send: ', e);

					if ( params.updateSubscriptionFunc ) { params.updateSubscriptionFunc(); }
					this.store.dispatch(unsetLoading());

					let errorMsg = '';
					if ('message' in e) {
						try {
							const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
							errorMsg = errorParsed.message
								.replace('execution reverted: ', '');
						} catch(ignored) {}
					}

					let links = undefined;
					if ('transactionHash' in e) {
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
					} else {
						try {
							const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
							const txHash = errorParsed.transactionHash;
							links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
						} catch(ignored) {}
					}

					this.store.dispatch(setError({
						text: `${this.t('Cannot wrap tokens')}: ${errorMsg || e.message}`,
						buttons: undefined,
						links: links,
					}));
				})
		}
	}

}