
import React    from 'react';
import Tippy    from '@tippyjs/react';
import Dropzone from 'react-dropzone';
import {
	withRouter,
	match,
} from 'react-router-dom';
import {
	CollateralItem,
	ERC20ContractParamsType,
	Fee,
	LockType,
	Lock,
	MetamaskAdapter,
	OriginalTokenType,
	Royalty,
	RoyaltyInput,
	Rules,
	SAFTDispatcher,
	_Asset,
	_AssetType,
	ERC20Contract,
	SAFTRecipientItem,
	SubscriptionDispatcher,
	assetTypeToString,
} from '../../models/BlockchainAdapter';
import {
	clearInfo,
	requestChain,
	setAuthMethod,
	setError,
	setInfo
} from '../../reducers';
import MintPage from '../MintPage';
import {
	History,
	Location
} from 'history';
import { withTranslation, } from "react-i18next";

import SubscriptionRenderer from '../SubscriptionRenderer';
import InputWithOptions     from '../InputWithOptions';
import CoinSelector         from '../CoinSelector';
import {
	DiscoveredToken,
	fetchUserTokensOfContract
} from '../../models/APIService/apiservice';
import {
	getOriginalToken
} from '../../models/TokenFetchWrapper/tokenfetchwrapper';

import CollateralViewer from '../CollateralViewer';

import {
	addThousandSeparator,
	compactString,
	dateToUnixtime,
	localStorageGet,
	localStorageSet,
	monthesNames,
	removeThousandSeparator,
	tokenToFloat,
	tokenToInt
} from '../../models/_utils';

import icon_i_del from '../../static/pics/i-del.svg';
import icon_i_attention from '../../static/pics/i-attention.svg';

import erc721_abi from '../../abis/_erc721.json';
import erc1155_abi from '../../abis/_erc1155.json';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type SAFTPageProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function,
	hideAuthMethodSelector: Function,
	t                     : any,
	match                 : match;
	location              : Location,
	history               : History,
}
type SAFTPageState = {
	balancesNative   : BigNumber,
	decimalsNative   : number,
	symbolNative     : string,
	iconNative       : string,
	EIPPrefix        : string,
	userAddress      : string,

	techToken        : ERC20ContractParamsType | undefined,

	chainId          : number,
	metamaskLogged   : boolean,
	explorerBaseUrl  : string,
	authMethod       : string,

	erc20BatchCollateralTokens: Array<ERC20ContractParamsType>,
	erc20CollateralTokens: Array<ERC20ContractParamsType>,

	inProgressPopup: boolean,
	authMethodSelectorShown: boolean,

	useContract: string, // { own, predefined, empty }
	mintChecked: boolean,
	collectionName: string,
	badContract: boolean,
	inputassetType: _AssetType,
	fetchedTokens: Array<OriginalTokenType | DiscoveredToken>,
	nftAddressInput: string,
	unlockTimeInput: string,
	recipients: Array<SAFTRecipientItem>,
	recipientAddressInput: string,
	recipientTokenIdInput: string,
	royaltyRecipients: Array<RoyaltyInput>,
	savedAddresses: Array<string | { address: string, standart: _AssetType, chainId: number }>,
	predefinedContracts: Array<{ address: string, standart: _AssetType }>,
	inputAddWNFTChecked: boolean,
	inputRoyaltyRecipientAddress: string,

	SAFTBlacklist: Array<_Asset>,
	SAFTWhitelist: Array<_Asset>,

	advancedOptionsOpened: boolean,
	showErrors: Array<string>,

	inputOriginalCopies      : string,
	outputStandart           : _AssetType,
	outputCopies             : string,
	inputWNFTRecipientAddress: string,
	inputRules               : Rules
	inputTransferFeeAmount   : string,
	inputTransferFeeAddress  : string,

	collaterals: Array<CollateralItem>,
	collateralTokenAddressInput: string,
	collateralAmountInput: string,

	showMinterPage: boolean,

	subscriptionLocked: boolean;
}

class SAFTPage extends React.Component<SAFTPageProps, SAFTPageState> {

	store                  : any;
	unsubscribe!           : Function;
	metamaskAdapter        : MetamaskAdapter;
	showAuthMethodSelector : Function;
	hideAuthMethodSelector : Function;
	t                      : any;
	SAFTDispatcher         : SAFTDispatcher | undefined;
	subscriptionDispatcher?: SubscriptionDispatcher;
	scrollable             : any;

	chainChangeRequested   : boolean;

	constructor(props: SAFTPageProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.hideAuthMethodSelector = props.hideAuthMethodSelector;
		this.t                      = props.t;
		this.scrollable             = {};

		this.chainChangeRequested   = false;

		let savedAddresses = [];
		if ( this.store.getState().metamaskAdapter.chainId !== 0 ) {
			const addressesFromLocalStorage = localStorageGet('SAFT_originalContracts');
			if ( addressesFromLocalStorage ) {
				savedAddresses = JSON.parse(addressesFromLocalStorage).filter((item: string | { address: string, standart: _AssetType, chainId: number }) => {
					return typeof item === 'string' ||  item.chainId === this.store.getState().metamaskAdapter.chainId
				});
			}
		}

		const predefinedContracts = [];
		const minter721 = this.store.getState().metamaskAdapter.nftMinterContract721;
		if ( minter721 ) {
			predefinedContracts.push({
				address: minter721,
				standart: _AssetType.ERC721,
			});
		}
		// const minter1155 = this.store.getState().metamaskAdapter.nftMinterContract1155;
		// if ( minter1155 ) {
		// 	predefinedContracts.push({
		// 		value: minter1155,
		// 		label: compactString(minter1155, 10),
		// 	});
		// }

		if ( !this.SAFTDispatcher ) { this.SAFTDispatcher = this.metamaskAdapter.getSAFTDispatcher(); }
		let foundTech = undefined;
		let transferFeeTokenToSet = '';
		if ( this.SAFTDispatcher && this.SAFTDispatcher.batchTechToken && !foundTech ) {
			foundTech = this.SAFTDispatcher.batchTechToken.erc20Params;
			if ( transferFeeTokenToSet === '' && foundTech ) {
				transferFeeTokenToSet = foundTech.address
			}
		}

		let originalTokensBlacklist: Array<_Asset> = [];
		if ( this.SAFTDispatcher ) {
			originalTokensBlacklist = this.SAFTDispatcher.blacklist;
		}
		let originalTokensWhitelist: Array<_Asset> = [];
		if ( this.SAFTDispatcher ) {
			originalTokensWhitelist = this.SAFTDispatcher.whitelist;
		}

		if (
			this.SAFTDispatcher &&
			this.SAFTDispatcher.subscriptionRegistryAddress &&
			this.SAFTDispatcher.subscriptionRegistryAddress !== '' &&
			this.SAFTDispatcher.subscriptionRegistryAddress !== '0x0000000000000000000000000000000000000000' &&
			this.SAFTDispatcher.batchWorkerAddress &&
			this.SAFTDispatcher.batchWorkerAddress !== '' &&
			this.SAFTDispatcher.batchWorkerAddress !== '0x0000000000000000000000000000000000000000'
		) {
			this.subscriptionDispatcher = new SubscriptionDispatcher({
				store                      : this.store,
				metamaskAdapter            : this.metamaskAdapter,
				t                          : this.t,
				subscriptionRegistryAddress: this.SAFTDispatcher.subscriptionRegistryAddress,
				subscriptionAgentAddress   : this.metamaskAdapter.chainConfig.subscriptionAgent,
				serviceProviderAddress     : this.SAFTDispatcher.batchWorkerAddress,
				updateParentComponent      : () => { setTimeout(() => { this.updateState() }, 100) },
			});
		}

		if (
			!this.store.getState()._error &&
			this.metamaskAdapter &&
			this.metamaskAdapter.chainConfig &&
			!this.metamaskAdapter.chainConfig.batchWorker &&
			!this.chainChangeRequested
		) {
			this.chainChangeRequested = true;
			this.store.dispatch(setInfo({
				text: `Please switch to the correct network`,
				buttons: [
					{
						text: this.t('Switch network'),
						clickFunc: () => {
							(window as any).ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: '0x' + Number(56).toString(16) }], // chainId must be in hexadecimal numbers
							})
							.catch((e: any) => {
								if ( e.code === 4902 ) {

									const foundChain = this.metamaskAdapter.availiableChains.find((item) => { return item.chainId === 56 });
									if ( !foundChain ) { return; }

									(window as any).ethereum.request({
										method: "wallet_addEthereumChain",
										params: [{
											chainId: '0x' + Number(foundChain.chainId).toString(16),
											rpcUrls: [ foundChain.chainRPCUrl ],
											chainName: foundChain.chainName,
											nativeCurrency: {
												name: foundChain.networkTokenTicket,
												symbol: foundChain.networkTokenTicket,
												decimals: foundChain.networkTokenDecimals
											},
											blockExplorerUrls: [ foundChain.explorerBaseUrl ]
										}]
									})
										.catch((e: any) => {
											this.chainChangeRequested = false;
											this.store.dispatch(requestChain(undefined));
											this.store.dispatch(clearInfo());
											this.store.dispatch(setError({
												text: `${this.t('Cannot add chain')}: ${e.message || e}`,
												buttons: undefined,
												links: undefined,
											}));
										})
								}
							})
						}
					},
					{
						text: this.t('Cancel'),
						clickFunc: async () => {
							window.location.href = '/list';
							this.store.dispatch(requestChain( this.store.getState().metamaskAdapter.chainId ));
							this.store.dispatch(clearInfo());
						}
					},
				],
				links: undefined
			}));
		}

		this.state = {
			balancesNative   : this.store.getState().account.balance,
			decimalsNative   : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative     : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative       : this.store.getState().metamaskAdapter.networkTokenIcon,
			EIPPrefix        : this.store.getState().metamaskAdapter.EIPPrefix,
			userAddress      : this.store.getState().account.address,

			techToken        : foundTech,
			SAFTBlacklist    : originalTokensBlacklist,
			SAFTWhitelist    : originalTokensWhitelist,

			chainId          : this.store.getState().metamaskAdapter.chainId,
			metamaskLogged   : this.store.getState().metamaskAdapter.logged,
			explorerBaseUrl  : this.store.getState().metamaskAdapter.explorerBaseUrl,
			authMethod       : this.store.getState().metamaskAdapter.authMethod || 'metamask',

			erc20BatchCollateralTokens: this.store.getState().erc20BatchCollateralTokens,
			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,

			inProgressPopup: false,
			authMethodSelectorShown: false,

			advancedOptionsOpened: false,
			inputOriginalCopies: '',
			outputStandart: _AssetType.ERC721,
			outputCopies: '',
			inputWNFTRecipientAddress: '',
			inputRules: {
				noAddCollateral: false,
				noTransfer     : false,
				noUnwrap       : false,
				noWrap         : false,
			},
			inputTransferFeeAmount: '',
			royaltyRecipients: [],
			inputAddWNFTChecked: false,
			inputRoyaltyRecipientAddress: '',

			showErrors: [],

			useContract: 'own',
			mintChecked: false,
			collectionName: '',
			badContract: false,
			inputassetType: _AssetType.ERC721,
			fetchedTokens: [],
			nftAddressInput: '',
			unlockTimeInput: '',
			recipients: [],
			recipientAddressInput: '',
			recipientTokenIdInput: '',
			collaterals: [],
			collateralTokenAddressInput: '',
			collateralAmountInput: '',

			showMinterPage: false,

			inputTransferFeeAddress: transferFeeTokenToSet,

			savedAddresses,
			predefinedContracts,

			subscriptionLocked: true,
		}
	}

	componentDidMount() {

		if ( !this.store.getState().metamaskAdapter.logged ) {
			const prevAuthMethod = localStorageGet('authMethod').toLowerCase();
			if ( prevAuthMethod ) {
				this.store.dispatch(setAuthMethod( prevAuthMethod ));
				this.metamaskAdapter.connect();
			} else {
				this.props.showAuthMethodSelector();
			}
		}

		this.unsubscribe = this.store.subscribe(() => {

			if (
				!this.store.getState()._error &&
				this.metamaskAdapter &&
				this.metamaskAdapter.chainConfig &&
				!this.metamaskAdapter.chainConfig.batchWorker &&
				!this.chainChangeRequested
			) {
				this.chainChangeRequested = true;
				this.store.dispatch(setInfo({
					text: `Please switch to the correct network`,
					buttons: [
						{
							text: this.t('Switch network'),
							clickFunc: () => {
								(window as any).ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: '0x' + Number(56).toString(16) }], // chainId must be in hexadecimal numbers
								})
								.catch((e: any) => {
									if ( e.code === 4902 ) {

										const foundChain = this.metamaskAdapter.availiableChains.find((item) => { return item.chainId === 56 });
										if ( !foundChain ) { return; }

										(window as any).ethereum.request({
											method: "wallet_addEthereumChain",
											params: [{
												chainId: '0x' + Number(foundChain.chainId).toString(16),
												rpcUrls: [ foundChain.chainRPCUrl ],
												chainName: foundChain.chainName,
												nativeCurrency: {
													name: foundChain.networkTokenTicket,
													symbol: foundChain.networkTokenTicket,
													decimals: foundChain.networkTokenDecimals
												},
												blockExplorerUrls: [ foundChain.explorerBaseUrl ]
											}]
										})
											.catch((e: any) => {
												this.chainChangeRequested = false;
												this.store.dispatch(requestChain(undefined));
												this.store.dispatch(clearInfo());
												this.store.dispatch(setError({
													text: `${this.t('Cannot add chain')}: ${e.message || e}`,
													buttons: undefined,
													links: undefined,
												}));
											})
									}
								})
							}
						},
						{
							text: this.t('Cancel'),
							clickFunc: async () => {
								window.location.href = '/list';
								this.store.dispatch(requestChain( this.store.getState().metamaskAdapter.chainId ));
								this.store.dispatch(clearInfo());
							}
						},
					],
					links: undefined
				}));

				return;
			}

			if ( !this.SAFTDispatcher ) { this.SAFTDispatcher = this.metamaskAdapter.getSAFTDispatcher(); }
			let foundTech = this.state.techToken;
			let transferFeeTokenToSet = this.state.inputTransferFeeAddress;
			if ( this.SAFTDispatcher && this.SAFTDispatcher.batchTechToken && !foundTech ) {
				foundTech = this.SAFTDispatcher.batchTechToken.erc20Params;
				if ( transferFeeTokenToSet === '' && foundTech ) {
					transferFeeTokenToSet = foundTech.address
				}
			}

			let originalTokensBlacklist: Array<_Asset> = this.state.SAFTBlacklist;
			if ( this.SAFTDispatcher && !originalTokensBlacklist.length ) {
				originalTokensBlacklist = this.SAFTDispatcher.blacklist;
			}
			let originalTokensWhitelist: Array<_Asset> = this.state.SAFTWhitelist;
			if ( this.SAFTDispatcher && !originalTokensWhitelist.length ) {
				originalTokensWhitelist = this.SAFTDispatcher.whitelist;
			}

			let savedAddresses = this.state.savedAddresses;
			if ( this.store.getState().metamaskAdapter.chainId !== 0 && !savedAddresses.length ) {
				const addressesFromLocalStorage = localStorageGet('SAFT_originalContracts');
				if ( addressesFromLocalStorage ) {
					savedAddresses = JSON.parse(addressesFromLocalStorage).filter((item: string | { address: string, standart: _AssetType, chainId: number }) => {
						return typeof item === 'string' ||  item.chainId === this.store.getState().metamaskAdapter.chainId
					});
				}
			}

			const predefinedContracts = this.state.predefinedContracts;
			const minter721 = this.store.getState().metamaskAdapter.nftMinterContract721;
			const foundMinter721 = predefinedContracts.find((item) => { return item.address.toLowerCase() === minter721.toLowerCase() })
			if ( minter721 && !foundMinter721 ) {
				predefinedContracts.push({
					address: minter721,
					standart: _AssetType.ERC721,
				});
			}
			// const minter1155 = this.store.getState().metamaskAdapter.nftMinterContract1155;
			// const foundMinter1155 = predefinedContracts.find((item) => { return item.value.toLowerCase() === minter1155.toLowerCase() })
			// if ( minter1155 && !foundMinter1155 ) {
			// 	predefinedContracts.push({
			// 		value: minter1155,
			// 		label: compactString(minter1155, 10),
			// 	});
			// }

			if (
				!this.subscriptionDispatcher &&
				this.SAFTDispatcher &&
				this.SAFTDispatcher.subscriptionRegistryAddress &&
				this.SAFTDispatcher.subscriptionRegistryAddress !== '' &&
				this.SAFTDispatcher.subscriptionRegistryAddress !== '0x0000000000000000000000000000000000000000'
			) {
				this.subscriptionDispatcher = new SubscriptionDispatcher({
					store                      : this.store,
					metamaskAdapter            : this.metamaskAdapter,
					t                          : this.t,
					subscriptionRegistryAddress: this.SAFTDispatcher.subscriptionRegistryAddress,
					subscriptionAgentAddress   : this.metamaskAdapter.chainConfig.subscriptionAgent,
					serviceProviderAddress     : this.SAFTDispatcher.batchWorkerAddress,
					updateParentComponent      : () => { setTimeout(() => { this.updateState() }, 100) },
				});
			}

			this.setState({
				balancesNative       : this.store.getState().account.balance,
				decimalsNative       : this.store.getState().metamaskAdapter.networkTokenDecimals,
				iconNative           : this.store.getState().metamaskAdapter.networkTokenIcon,
				symbolNative         : this.store.getState().metamaskAdapter.networkTokenTicket,
				techToken            : foundTech,
				SAFTBlacklist        : originalTokensBlacklist,
				SAFTWhitelist        : originalTokensWhitelist,
				EIPPrefix            : this.store.getState().metamaskAdapter.EIPPrefix,
				userAddress          : this.store.getState().account.address,
				authMethod           : this.store.getState().metamaskAdapter.authMethod || 'metamask',

				chainId              : this.store.getState().metamaskAdapter.chainId,
				metamaskLogged       : this.store.getState().metamaskAdapter.logged,
				explorerBaseUrl      : this.store.getState().metamaskAdapter.explorerBaseUrl,
				erc20BatchCollateralTokens: this.store.getState().erc20BatchCollateralTokens,
				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,

				inputTransferFeeAddress: transferFeeTokenToSet,

				predefinedContracts,
				savedAddresses
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	updateState() {
		this.forceUpdate();
	}

	filterERC20ContractByPermissions(permissions: {
		enabledForCollateral?        : boolean,
		enabledForFee?               : boolean,
		enabledRemoveFromCollateral? : boolean,
	}): Array<ERC20ContractParamsType> {
		const tokensToSearch = [
			{
				address: '0x0000000000000000000000000000000000000000',
				allowance: new BigNumber(10**50),
				balance: this.state.balancesNative,
				decimals: this.state.decimalsNative,
				icon: this.state.iconNative,
				name: this.state.symbolNative,
				symbol: this.state.symbolNative,
				permissions: {
					enabledForCollateral: true,
					enabledForFee: false,
					enabledRemoveFromCollateral: true,
				}
			},
			...this.state.erc20BatchCollateralTokens.filter((item) => {
				if ( !this.state.techToken ) { return true; }
				return item.address.toLowerCase() !== this.state.techToken.address.toLowerCase()
			})
		];
		if ( this.state.techToken ) {
			tokensToSearch.push( this.state.techToken );
		}

		// OR LOGIC
		return tokensToSearch.filter((item) => {
			if ( permissions.enabledForCollateral        && item.permissions && item.permissions.enabledForCollateral        ) { return true; }
			if ( permissions.enabledForFee               && item.permissions && item.permissions.enabledForFee               ) { return true; }
			if ( permissions.enabledRemoveFromCollateral && item.permissions && item.permissions.enabledRemoveFromCollateral ) { return true; }

			return false;
		});
	}
	getDaysPretty() {
		const daysToAdd = parseInt(this.state.unlockTimeInput) || 0;
		if ( daysToAdd === 0 ) { return '' }

		const output = new Date();
		output.setDate(output.getDate() + daysToAdd);
		return `${output.getDate()} ${monthesNames[output.getMonth()]} ${output.getFullYear()}`;
	}
	addDays(num: number) {
		const output = new Date();
		output.setDate(output.getDate() + num);
		return output;
	}
	async testOriginalContractType(address: string): Promise<_AssetType> {
		const IID_IERC1155 = '0xd9b67a26';
		const IID_IERC721  = '0x80ac58cd';

		try {
			const testContract721  = new this.metamaskAdapter.web3.eth.Contract(erc721_abi  as any, address);
			const supports = await testContract721.methods.supportsInterface(IID_IERC721).call();
			if ( supports ) { return _AssetType.ERC721 }
		} catch (ignored) {}

		try {
			const testContract1155 = new this.metamaskAdapter.web3.eth.Contract(erc1155_abi as any, address);
			const supports = await testContract1155.methods.supportsInterface(IID_IERC1155).call();
			if ( supports ) { return _AssetType.ERC1155 }
		} catch (ignored) {}

		return _AssetType.ERC721;
	}
	async fetchCollectionName(address: string) {
		if (
			!this.metamaskAdapter.web3 ||
			!address ||
			!this.metamaskAdapter.web3.utils.isAddress(address) ||
			this.state.collectionName
		) { return; }

		const contractType = await this.testOriginalContractType(address);

		try {
			const contract = new this.metamaskAdapter.web3.eth.Contract(erc721_abi as any, address);
			const name = await contract.methods.name().call();
			this.setState({
				collectionName: name,
				inputassetType: contractType,
			});
		} catch (ignored) {
			this.setState({
				badContract: true,
				fetchedTokens: [],
				inputassetType: contractType,
			});
		}

		this.fetchContractTokensBatch(_AssetType.ERC721, address, 1);
	}
	async fetchContractTokensBatch(assetType: _AssetType, contractAddress: string, page: number) {

		let tokens;
		tokens = await fetchUserTokensOfContract(this.state.chainId, assetType, this.state.userAddress, contractAddress, page);

		if ( !tokens.length ) {

			if ( page === 1 ) {

				if ( assetType === _AssetType.ERC721 ) {
					this.fetchContractTokensBatch( _AssetType.ERC1155, contractAddress, page )
				}

				if ( assetType === _AssetType.ERC1155 ) {
					this.fetchContractTokensFromBlockchain( contractAddress )
				}

			}

		} else {
			this.fetchContractTokensBatch(assetType, contractAddress, page + 1);
			this.setState({
				fetchedTokens: [
					...this.state.fetchedTokens,
					...tokens
				]
			});
		}
	}
	async fetchContractTokensFromBlockchain(contractAddress: string) {
		let contract = undefined;
		let balance = undefined;
		try {
			contract = new this.metamaskAdapter.web3.eth.Contract(erc721_abi as any, contractAddress);
			balance = await contract.methods.balanceOf(this.state.userAddress).call();
		} catch (ignored) {
			this.setState({
				fetchedTokens: []
			});
		}

		if ( contract && balance ) {
			for ( let idx = 0; idx < balance; idx++ ) {
				const tokenId = await contract.methods.tokenOfOwnerByIndex(this.state.userAddress, idx).call();
				const token = await getOriginalToken({
					chainId: this.state.chainId,
					contractAddress: contractAddress,
					tokenId: tokenId,
					userAddress: this.state.userAddress,
					assetType: _AssetType.ERC721,
					metamaskAdapter: this.metamaskAdapter,
					contract: contract,
					t: this.t,
				});

				if ( token ) {
					this.setState({
						fetchedTokens: [
							...this.state.fetchedTokens,
							token
						]
					})
				}
			}
		}
	}
	getCollectionBlock() {
		if ( this.state.badContract ) {
			return (
				<div className="collection-name">
					<span>{ this.t('Cannot connect to contract') }</span>
				</div>
			)
		} else {
			return (
				<div className="collection-name">
					<span>{ this.t('Collection') }:&nbsp;</span>
					<b>{ this.state.collectionName }</b>
				</div>
			)
		}
	}
	getTokensQty() {
		if ( this.state.fetchedTokens.length ) {
			return (
				<div className="collection-name">
					<span>{ this.t('You have') }:&nbsp;</span>
					<b>{ this.state.fetchedTokens.length } { this.state.fetchedTokens.length === 1 ? 'token' : 'tokens' }</b>
				</div>
			)
		}
	}
	getCopiesBlock() {
		const showError = this.state.showErrors.find((item) => { return item === 'standart' });

		if ( this.state.outputStandart === _AssetType.ERC1155 ) {
			return (
				<div className="col-12 col-md-4">
					<div className="input-group mb-md-0">
						<label className="input-label">Copies</label>
						<input
							className={`input-control ${ showError ? 'has-error' : '' }`}
							type="text"
							value={ this.state.outputCopies }
							disabled={ this.subscriptionBlocked() }
							onChange={(e) => {
								const value = e.target.value.replaceAll(' ', '');
								if (
									value === '' ||
									isNaN(parseInt(value))
								) {
									this.setState({
										outputCopies: '',
										showErrors: this.state.showErrors.filter((item) => { return item !== 'standart' })
									})
									return;
								}

								this.setState({
									outputCopies: `${parseInt(value)}`,
									showErrors: this.state.showErrors.filter((item) => { return item !== 'standart' })
								})
							}}
						/>
					</div>
				</div>
			)
		}
	}
	getStandartSelectorBlock() {
		return (
			<div
				className="c-wrap"
				ref={ (e) => { this.scrollable.standart = e } }
			>
				<div className="row">
					<div className="col-12 col-md-6">
						<div className="input-group mb-md-0">
							<label className="input-label">wNFT Standard</label>
							<div className="row row-sm">
								<div className="col-auto my-2">
									<label className="checkbox">
										<input
											type="radio"
											name="wnft-standard"
											value={ _AssetType.ERC721 }
											disabled={ this.subscriptionBlocked() }
											checked={ this.state.outputStandart === _AssetType.ERC721 }
											onChange={() => { this.setState({ outputStandart: _AssetType.ERC721 }) }}
										/>
										<span className="check"> </span>
										<span className="check-text"><b>{ this.state.EIPPrefix }-721</b></span>
									</label>
								</div>
								<div className="col-auto my-2">
									<label className="checkbox">
										<input
											type="radio"
											name="wnft-standard"
											value={ _AssetType.ERC1155 }
											disabled={ this.subscriptionBlocked() }
											checked={ this.state.outputStandart === _AssetType.ERC1155 }
											onChange={() => { this.setState({ outputStandart: _AssetType.ERC1155 }) }}
										/>
										<span className="check"> </span>
										<span className="check-text"> <b>{ this.state.EIPPrefix }-1155</b></span>
									</label>
								</div>
							</div>
						</div>
					</div>
					{ this.getCopiesBlock() }
				</div>
			</div>
		)
	}
	getAdvancedOptionsBlock() {
		return (
			// <div className="c-wrap p-0 pb-3">
			<div className="c-wrap p-0">
				<div
					className={`c-wrap__toggle ${this.state.advancedOptionsOpened ? 'active' : ''}`}
					onClick={() => {
						this.setState({
							advancedOptionsOpened: !this.state.advancedOptionsOpened
						});
					}}
				>
					<div><b>Advanced options</b></div>
				</div>
				<div className="c-wrap__dropdown">
					{/* <div className="input-group">
						<label className="input-label">wNFT Recipient</label>
						<input
							className="input-control"
							type="text"
							placeholder="Paste here"
							value={ this.state.inputWNFTRecipientAddress }
							onChange={(e) => { this.setState({ inputWNFTRecipientAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") }) }}
						/>
					</div> */}
					<div className="input-group pt-2 mb-0">
						<label className="input-label pb-1">Disable:</label>
						<div className="mb-3">
							<label className="checkbox">
								<input
									type="checkbox"
									name=""
									checked={ this.state.inputRules.noUnwrap }
									disabled={ this.subscriptionBlocked() }
									onChange={(e) => {
										this.setState({
											inputRules: {
												...this.state.inputRules,
												noUnwrap: e.target.checked
											},
										})
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">
									Unwrapping
									<Tippy
										content={ 'The owner of a future wNFT will be unable to unwrap it' }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 512 }
									>
										<span className="i-tip ml-1"></span>
									</Tippy>
								</span>
							</label>
						</div>
						<div className="mb-3">
							<label className="checkbox">
								<input
									type="checkbox"
									name=""
									checked={ this.state.inputRules.noAddCollateral }
									disabled={ this.subscriptionBlocked() }
									onChange={(e) => {
										this.setState({
											inputRules: {
												...this.state.inputRules,
												noAddCollateral: e.target.checked
											}
										})
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">
									Adding collateral
									<Tippy
										content={ 'No one will be able to add collateral to future wNFT transactions' }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 512 }
									>
										<span className="i-tip ml-1"></span>
									</Tippy>
								</span>
							</label>
						</div>
						{/* <div className="mb-3">
							<label className="checkbox">
								<input
									type="checkbox"
									name=""
									checked={ this.state.inputRules.noWrap }
									disabled={ this.subscriptionBlocked() }
									onChange={(e) => {
										this.setState({
											inputRules: {
												...this.state.inputRules,
												noWrap: e.target.checked
											}
										})
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">
									Second wrapping
									<Tippy
										content={ 'The owner of a future wNFT will be unable to wrap it a second time' }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 512 }
									>
										<span className="i-tip ml-1"></span>
									</Tippy>
								</span>
							</label>
						</div> */}
						<div>
							<label className="checkbox">
								<input
									type="checkbox"
									name=""
									checked={ this.state.inputRules.noTransfer }
									disabled={ this.subscriptionBlocked() }
									onChange={(e) => {
										this.setState({
											inputRules: {
												...this.state.inputRules,
												noTransfer: e.target.checked,
											},
										});
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">
									Transferring (Soulbound <span className="text-nowrap"> Token) <Tippy
										content={ 'The owner of future wNFT will be unable to transfer it' }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 512 }
									>
										<span className="i-tip ml-1"></span>
									</Tippy>
									</span>
								</span>
							</label>
							<p><small className="text-muted">These options are&nbsp;irreversible. The custodian is&nbsp;accountable for&nbsp;any protocol-related actions.</small></p>
						</div>
					</div>
				</div>
			</div>
		)
	}
	getMintBlock() {
		let inputOptions: Array<{ value: string, label: string }> = [];
		if ( this.state.useContract === 'predefined' ) {
			inputOptions = this.state.predefinedContracts.map((item: { address: string, standart: _AssetType }) => {
				return { value: item.address, label: `${assetTypeToString(item.standart, this.state.EIPPrefix)} — ${compactString(item.address, 10)}` }
			})
		} else {
			inputOptions = this.state.savedAddresses.map((item: string | { address: string, standart: _AssetType, chainId: number }) => {
				if ( typeof item === 'string' ) {
					return { value: item, label: `${compactString(item, 10)}` }
				}
				return { value: item.address, label: `${assetTypeToString(item.standart, this.state.EIPPrefix)} — ${compactString(item.address, 10)}` }
			})
		}
		return (
			<div
				className="c-wrap c-b-wrap"
				ref={ (e) => { this.scrollable.originalToken = e } }
			>
				<div className="c-wrap__header mb-3">
					<div className="h3">SAFT</div>
				</div>
				<p className="text-orange">You can only  wrap the original ERC-721 tokens</p>
				<div className="row row-sm mb-5">
					<div className="col-12 col-lg-auto pt-2 pb-3 pb-lg-0">
						<label className="input-label mb-0">I want to:</label>
					</div>
					<div className="col-auto py-2">
						<label className="checkbox">
							<input
								type="radio"
								name="contract-type"
								onChange={() => { this.setState({
									useContract: 'own',
									nftAddressInput: '',
									collectionName: '',
									badContract: false,
									fetchedTokens: [],
								}) }}
								disabled={ this.subscriptionBlocked() }
								checked={ this.state.useContract === 'own' }
							/>
							<span className="check"> </span>
							<span className="check-text">use my nft contract</span>
						</label>
					</div>
					{
						this.state.predefinedContracts.length ? (
							<div className="col-auto py-2">
								<label className="checkbox">
									<input
										type="radio"
										name="contract-type"
										onChange={() => { this.setState({
											useContract: 'predefined',
											nftAddressInput: '',
											collectionName: '',
											badContract: false,
											fetchedTokens: [],
										}) }}
										disabled={ this.subscriptionBlocked() }
										checked={ this.state.useContract === 'predefined' }
									/>
									<span className="check"> </span>
									<span className="check-text">mint tokens with Envelop contract</span>
								</label>
							</div>
						) : null
					}
					<div className="col-auto py-2">
						<label className="checkbox">
							<input
								type="radio"
								name="contract-type"
								onChange={() => { this.setState({
									useContract: 'empty',
									nftAddressInput: '',
									recipientTokenIdInput: '',
									collectionName: '',
									badContract: false,
									fetchedTokens: [],
								}) }}
								disabled={ this.subscriptionBlocked() }
								checked={ this.state.useContract === 'empty' }
							/>
							<span className="check"> </span>
							<span className="check-text">wrap emptiness</span>
						</label>
					</div>
				</div>
				<div className="c-wrap__form">
					<div className="row">
						<div className="col col-12 col-md-8 col-lg-6">
							<label className="input-label">{ this.t('Original NFT Address') }</label>
							<InputWithOptions
								placeholder="Paste here"
								disabled={ this.state.useContract === 'empty' || this.subscriptionBlocked() }
								value={ this.state.nftAddressInput }
								onChange={(e) => {
									if ( this.metamaskAdapter.web3.utils.isAddress(e.target.value) ) {
										this.fetchCollectionName(e.target.value);
									}
									this.setState({
										nftAddressInput: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, ""),
										collectionName: '',
										badContract: false,
										fetchedTokens: [],
									})
								}}
								onSelect={(e) => {
									if ( this.metamaskAdapter.web3.utils.isAddress(e) ) {
										this.fetchCollectionName(e);
									}
									this.setState({
										nftAddressInput: e.toLowerCase().replace(/[^a-f0-9x]/g, ""),
										collectionName: '',
										badContract: false,
										fetchedTokens: [],
									})
								}}
								// options={
								// 	this.state.useContract === 'predefined' ?
								// 	this.state.predefinedContracts.filter((item) => { return item.value.toLowerCase().includes(this.state.nftAddressInput.toLowerCase()) }) :
								// 	this.state.savedAddresses.filter((item) => { return item.value.toLowerCase().includes(this.state.nftAddressInput.toLowerCase()) })
								// }
								options={ inputOptions }
							/>
							{ this.getCollectionBlock() }
							{ this.getTokensQty() }
						</div>
						<div
							className="col col-12 col-md-4 col-lg-3"
							ref={ (e) => { this.scrollable.timelock = e } }
						>
							<label className="input-label">{ this.t('Time Unlock') }</label>
							<input
								className="input-control"
								type="text"
								placeholder=""
								value={ this.state.unlockTimeInput }
								disabled={ this.subscriptionBlocked() }
								onChange={(e) => {
									const value = e.target.value.replaceAll(' ', '');
									if (
										value === ''
									) {
										this.setState({ unlockTimeInput: '' })
										return;
									}
									if (
										isNaN(parseInt(value)) ||
										parseInt(value) > 9999999
									) {
										return;
									}

									this.setState({ unlockTimeInput: `${parseInt(value)}` })
								}}
							/>
							<div className="collection-name">
								<span>{ this.t('Date') }:&nbsp;</span>
								<b>{ this.getDaysPretty() }</b>
							</div>
						</div>
						{/* <div className="col col-12 col-md-3 col-lg-3 order-4 order-md-3 mt-2 mt-md-0 pb-md-3 align-self-end">
							<label className="checkbox">
							<input
								type="checkbox"
								checked={ this.state.mintChecked }
								onChange={(e) => { this.setState({ mintChecked: e.target.checked, recipientTokenIdInput: '', }) }}
							/>
							<span className="check"></span>
							<span className="check-text">Mint</span>
							</label>
						</div> */}
					</div>

					{
						this.state.useContract === 'predefined' ? (
							<div className="row mt-2">
								{
									this.state.nftAddressInput &&
									this.metamaskAdapter.web3 && this.metamaskAdapter.web3.utils.isAddress(this.state.nftAddressInput) &&
									!this.state.badContract &&
									this.state.fetchedTokens.length === 0 ? (
										<div className="col-12 col-sm-7 col-md-auto mb-2 mb-sm-0">
											<div className="text-warning my-2">
												You have no NFTs in&nbsp;this contract. <br/ >
												You&nbsp;should mint at&nbsp;least one token to&nbsp;proceed.
											</div>
										</div>
									) : null
								}
								<div className="col-12 col-sm-4 col-md-auto">
									<button
										className="btn btn-outline d-inline-flex w-100"
										onClick={() => { this.openMinterPage() }}
									>Mint an&nbsp;NFT</button>
								</div>
							</div>
						) : null
					}

				</div>
			</div>
		)
	}
	getRecipientRow(recipient: SAFTRecipientItem, position: number) {
		return (
			<div className="item" key={ recipient.timeAdded }>
				<div className="row">
					<div className="col-md-1 mb-2">#{ position }</div>
					<div className="col-md-5 mb-2">
						<span className="text-break">{ recipient.userAddress }</span>
					</div>
					<div className="col-md-5 mb-2">{ this.state.useContract !== 'empty' ? `ID ${ recipient.tokenId }` : null }</div>
					<button
						className="btn-del"
						onClick={(e) => {
							const filteredRecipients = this.state.recipients.filter((item) => { return item.timeAdded !== recipient.timeAdded });
							this.setState({ recipients: filteredRecipients })
						}}
					>
						<img src={ icon_i_del } alt="" />
					</button>
				</div>
			</div>
		)
	}
	addRecipientDisabled() {

		if ( this.subscriptionBlocked() ) { return this.t('Add') }

		if (
			!this.metamaskAdapter.web3 || !this.metamaskAdapter.web3.utils.isAddress(this.state.recipientAddressInput)
		) {
			return this.t('Wrong address')
		}

		if ( !this.state.recipientTokenIdInput && this.state.useContract !== 'empty' && !this.state.mintChecked ) {
			return this.t('Empty token id')
		}

		if ( this.state.recipientTokenIdInput ) {
			const foundAdded = this.state.recipients.find((iitem) => {
				if ( !iitem.tokenId ) { return true }
				return iitem.tokenId === this.state.recipientTokenIdInput;
			});
			if ( foundAdded ) { return this.t('Already added') }
		}

		return ''
	}
	recipientsOnDrop(files: Array<File>) {

		if ( this.subscriptionBlocked() ) { return; }

		files.forEach((item) => {
			const fileReader = new FileReader();
			fileReader.addEventListener('load', (e) => {
				let text = e.target?.result;
				if ( typeof(text) !== 'string' ) {
					return;
				}
				if ( !text ) { return; }

				let rows: Array<string> = [];
				if ( text.includes('\r\n') ) {
					rows = text.split('\r\n');
				}
				else {
					if ( text.includes('\n\r') ) {
						rows = text.split('\n\r');
					} else {
						if ( text.includes('\n') ) {
							rows = text.split('\n');
					}
					}
				}

				rows.forEach((iitem) => {
					if ( this.metamaskAdapter.web3 &&
						this.metamaskAdapter.web3.utils.isAddress(iitem)
					) {
						// whole string is address
						this.addRecipientRow(iitem, '');
					} else {
						// two comma separated values
						try {
							const address = iitem.split(';')[0];
							const tokenId = iitem.split(';')[1].replaceAll(',', '.');

							if (
								!this.metamaskAdapter.web3 ||
								!this.metamaskAdapter.web3.utils.isAddress(address)
							) { return null; }

							this.addRecipientRow(address, tokenId);
						} catch(e) { console.log('Cannot add row from file', iitem) }
					}
				});

			});
			fileReader.readAsText(item)
		})
	}
	addRecipientRow(address: string, tokenId: string) {
		const foundToken = this.state.recipients.filter((item) => {
			if ( !item.tokenId ) { return false; }
			return item.tokenId === tokenId;
		});
		if ( foundToken.length ) { return; }

		const recipientsUpdated: Array<SAFTRecipientItem> = [
			// ...this.state.recipients.filter((item) => { return item.tokenId !== tokenId }),
			...this.state.recipients,
			{
				timeAdded: new Date().getTime(),
				userAddress: address,
				tokenId: tokenId
			}
		]
		this.setState({
			recipients: recipientsUpdated,
			recipientAddressInput: '',
			recipientTokenIdInput: '',
		});
	}
	getRecipientsBlock() {
		return (
			<Dropzone
				onDrop={(files) => { this.recipientsOnDrop(files) }}
				accept={ '.txt, .csv' }
				noClick={ true }
				noKeyboard={ true }
			>
				{
					({
						getRootProps, getInputProps, isDragActive, open
					}) => (
						<div {...getRootProps({ className: `c-wrap c-b-wrap ${isDragActive && !this.subscriptionBlocked() ? 'file-dragged' : ''}` })}>

							<div className="upload-poopover">
								<div className="inner">
									<div className="h3">{ this.t('Drop your file here') }</div>
								</div>
							</div>

							<input {...getInputProps()} />
							<div className="c-wrap__header" ref={ (e) => { this.scrollable.recipients = e } }>
								<div className="h3">{ this.t('Recipients') }</div>
								{
									this.state.useContract === 'predefined' ? (
										<button
											className="btn btn-gray"
											onClick={() => { this.openMinterPage() }}
											disabled={ this.subscriptionBlocked() }
										>Mint more tokens</button>
									) : null
								}
								{
									// this.state.useContract === 'own' ? (
									// 	<div className="d-sm-flex pt-4 pt-sm-2">
									// 		<div className="py-2 mr-4">
									// 			<label className="checkbox">
									// 				<input
									// 					type="radio"
									// 					name="contract-tokens"
									// 					checked={ !this.state.mintChecked }
									// 					disabled={ this.subscriptionBlocked() }
									// 					onChange={() => { this.setState({
									// 						mintChecked: false,
									// 						recipientTokenIdInput: '',
									// 					}) }}
									// 				/>
									// 				<span className="check"></span>
									// 				<span className="check-text">Use the contract's tokens</span>
									// 			</label>
									// 		</div>
									// 		<div className="py-2">
									// 			<label className="checkbox">
									// 				<input
									// 					type="radio"
									// 					name="contract-tokens"
									// 					checked={ this.state.mintChecked }
									// 					disabled={ this.subscriptionBlocked() }
									// 					onChange={() => { this.setState({
									// 						mintChecked: true,
									// 						recipientTokenIdInput: '',
									// 					}) }}
									// 				/>
									// 				<span className="check"></span>
									// 				<span className="check-text">Mint new tokens</span>
									// 			</label>
									// 		</div>
									// 	</div>
									// ) : null
								}
							</div>
							<div className="c-wrap__form">
								<div className="row">
									<div className="col col-12 col-md-5">
										<label className="input-label">{ this.t('Recipient Address') }</label>
										<input
											className="input-control"
											type="text"
											placeholder={ this.t('Paste here') }
											value={ this.state.recipientAddressInput }
											disabled={ this.subscriptionBlocked() }
											onChange={(e) => { this.setState({ recipientAddressInput: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") }) }}
											onKeyPress={(e) => {
												if ( e.defaultPrevented)             { return; }
												if ( !!this.addRecipientDisabled() ) { return; }
												if ( e.key !== 'Enter' )             { return; }

												this.addRecipientRow(this.state.recipientAddressInput, this.state.recipientTokenIdInput);
											}}
										/>
									</div>
									<div className="col col-12 col-md-4">
										<label className="input-label">{ this.t('Original NFT token ID') }</label>
										<InputWithOptions
											placeholder=""
											disabled={ this.state.useContract === 'empty' || this.state.mintChecked || this.subscriptionBlocked() }
											value={ this.state.recipientTokenIdInput }
											onChange={(e) => { this.setState({ recipientTokenIdInput: e.target.value.toLowerCase().replace(/[^0-9]/g, "") }) }}
											onKeyPress={(e) => {
												if ( e.defaultPrevented)             { return; }
												if ( !!this.addRecipientDisabled() ) { return; }
												if ( e.key !== 'Enter' )             { return; }

												this.addRecipientRow(this.state.recipientAddressInput, this.state.recipientTokenIdInput);
											}}
											options={
												this.state.fetchedTokens
													.filter((item) => {
														const tokenId = 'token_id' in item ? item.token_id : item.tokenId;
														const foundAdded = this.state.recipients.find((iitem) => {
															if ( !iitem.tokenId ) { return true }
															return iitem.tokenId === tokenId
														});
														if ( foundAdded ) { return false; }
														return true;
													})
													.map((item) => {
														const tokenId = 'token_id' in item ? item.token_id : item.tokenId;
														return { value: tokenId || '', label: compactString(tokenId || '', 3) }
													})
											}
											onSelect={(e) => { this.setState({ recipientTokenIdInput: e.toLowerCase().replace(/[^0-9]/g, "") }) }}
										/>
									</div>
									<div className="col col-12 col-md-3">
										<label className="input-label">&nbsp;</label>
										<button
											className="btn btn-grad"
											disabled={ !!this.addRecipientDisabled() }
											onClick={(e) => {
												this.addRecipientRow(this.state.recipientAddressInput, this.state.recipientTokenIdInput);
											}}
										>{ this.addRecipientDisabled() ? this.addRecipientDisabled() : 'Add' }</button>
									</div>
								</div>

								<div className="c-wrap__upload-row">
									<button
										className="btn-link btn-csv mr-md-3"
										onClick={ open }
									>{ this.t('Click to upload a CSV file') }</button>
									<span className="d-none d-md-inline">
										<span className="mr-3">{ this.t('or') } </span>
										{ this.t('drag and drop it here') }
									</span>
								</div>

							</div>
							<div className="c-wrap__table mt-3">
								{
									this.state.recipients.map((item, idx) => { return this.getRecipientRow(item, idx + 1) })
								}
							</div>
						</div>
					)
				}
			</Dropzone>
		)
	}

	getFeeBlock() {

		if ( this.state.inputRules.noTransfer ) { return null; }

		return (
			<div className="c-wrap">
				<div className="c-wrap__header">
					<div className="h3">
						Transfer Fee
						<Tippy
							content={ this.t('Amount of transfer fee. Sender has to pay this fee to transfer wNFT. So that fee amount must be approved to this contract before any wNFT transfer (marketplace trading).') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 512 }
						>
							<span className="i-tip ml-1"></span>
						</Tippy>
					</div>
				</div>
				<div className="c-wrap__form">
					<div className="row">
						<div className="col-md-7">
							<div className={ `select-group ${ this.subscriptionBlocked() ? 'disabled' : '' }` } >
								<input
									className="input-control"
									type="text"
									placeholder="0.000"
									disabled={ this.state.inputRules.noTransfer || this.subscriptionBlocked() }
									value={ addThousandSeparator(this.state.inputTransferFeeAmount) }
									onChange={(e) => {
										let value = removeThousandSeparator(e.target.value);
										if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
											if ( new BigNumber(value).isNaN() ) {
												return;
											}
											value = new BigNumber(value).toString();
										}

										this.setState({
											inputTransferFeeAmount: value,
										})
									}}
								/>
								<CoinSelector
									store         = { this.store }
									tokens        = { this.filterERC20ContractByPermissions({ enabledForFee: true }) }
									selectedToken = { this.state.inputTransferFeeAddress }
									onChange      = {(address: string) => {
										this.setState({
											inputTransferFeeAddress: address,
										})
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	getNonRemovableAddress() {

		if ( this.state.inputTransferFeeAmount === '' ) { return undefined; }
		if ( new BigNumber(this.state.inputTransferFeeAmount).isNaN() ) { return undefined; }
		if ( new BigNumber(this.state.inputTransferFeeAmount).eq(0) ) { return undefined; }
		if ( !this.state.inputAddWNFTChecked ) { return undefined; }

		return this.state.inputTransferFeeAddress
	}
	addCollateralRow(addressInput: string, amountInput: string) {

		let amount = new BigNumber(amountInput);
		if ( amount.isNaN() ) { return; }

		let address = addressInput;
		if ( address === '' || address === '0' || address === '0x0000000000000000000000000000000000000000' ) {
			address = '0x0000000000000000000000000000000000000000';
			amount = tokenToInt(amount, this.state.decimalsNative);
		}
		let tokenToAdd = undefined;
		if ( this.state.techToken && address.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
			tokenToAdd = this.state.techToken;
			amount = tokenToInt(amount, this.state.techToken.decimals || this.state.decimalsNative);
		}
		let foundERC20 = this.state.erc20BatchCollateralTokens.find((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === address.toLowerCase()
		});
		if ( !foundERC20 ) {
			foundERC20 = this.state.erc20CollateralTokens.find((item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() === address.toLowerCase()
			});
		}
		// if ( !foundERC20 ) {
		// 	if ( !this.SAFTDispatcher ) { return; }
		// 	foundERC20 = new ERC20Contract({
		// 		web3                : this.metamaskAdapter.web3,
		// 		store               : this.store,
		// 		contractAddress     : address,
		// 		contractType        : 'collateral_batchwrapper',
		// 		userAddress         : this.metamaskAdapter.userAddress,
		// 		wrapperAddress      : this.SAFTDispatcher.trustedWrapperAddress,
		// 		whitelistContract   : this.SAFTDispatcher.batchWhitelistContract,
		// 	}).erc20Params;
		// }
		if ( foundERC20 ) {
			tokenToAdd = foundERC20;
			amount = tokenToInt(amount, tokenToAdd.decimals || this.state.decimalsNative);
		}

		let collateralsUpdated = this.state.collaterals;
		let collateralToAdd: CollateralItem;

		const foundExisting = this.state.collaterals.filter((item) => { return item.address.toLowerCase() === address.toLowerCase() });
		if ( foundExisting.length ) {
			collateralsUpdated = this.state.collaterals.filter((item) => { return item.address.toLowerCase() !== address.toLowerCase() });
			collateralToAdd = {
				assetType: foundExisting[0].assetType,
				address: foundExisting[0].address,
				amount: foundExisting[0].amount ? foundExisting[0].amount.plus(amount) : new BigNumber(0),
			}
		} else {
			collateralToAdd = {
				assetType: address === '0x0000000000000000000000000000000000000000' ? _AssetType.native : _AssetType.ERC20,
				address: address,
				amount: amount,
			}
		}

		collateralsUpdated = [
			...collateralsUpdated,
			collateralToAdd
		]
		this.setState({
			collaterals: collateralsUpdated,
			collateralAmountInput: '',
			collateralTokenAddressInput: '',
		})
	}
	addCollateralDisabled() {
		if ( this.subscriptionBlocked() ) { return this.t('Add') }
		if (
			!!this.state.collateralTokenAddressInput &&
			this.state.collateralTokenAddressInput !== '0' &&
			(
				!this.metamaskAdapter.web3 ||
				!this.metamaskAdapter.web3.utils.isAddress(this.state.collateralTokenAddressInput)
			)
		) { return this.t('Wrong address') }

		const amount = new BigNumber(this.state.collateralAmountInput);
		if (
			!this.state.collateralAmountInput ||
			amount.eq(0) ||
			amount.isNaN()
		) { return this.t('Empty amount') }

		return ''
	}
	getCollateralAmountTitle() {
		let foundERC20 = this.state.erc20BatchCollateralTokens.find((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === this.state.collateralTokenAddressInput.toLowerCase()
		});
		if ( !foundERC20 ) {
			foundERC20 = this.state.erc20CollateralTokens.find((item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() === this.state.collateralTokenAddressInput.toLowerCase()
			});
		}
		if (
			this.state.collateralTokenAddressInput &&
			this.state.collateralTokenAddressInput !== '' &&
			this.state.collateralTokenAddressInput !== '0' &&
			this.state.collateralTokenAddressInput !== '0x0000000000000000000000000000000000000000' &&
			( this.state.techToken && this.state.collateralTokenAddressInput.toLowerCase() !== this.state.techToken.address.toLowerCase() ) &&
			!foundERC20
		) {
			return (
				<label className="input-label text-orange">
					<Tippy
						content={ this.t('decimals is unknown; enter amount in wei') }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<span>{ this.t('Amount') }*</span>
					</Tippy>
					<Tippy
						content={ this.t('Amount of tokens for every recipient') }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<span className="i-tip ml-1"></span>
					</Tippy>
				</label>
			)
		} else {
			return (
				<label className="input-label">
					{ this.t('Amount') }
					<Tippy
						content={ this.t('Amount of tokens for every recipient') }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<span className="i-tip ml-1"></span>
					</Tippy>
				</label>
			)
		}
	}
	getCollateralFootnote() {
		// const collateralFound = this.state.collaterals.filter((item) => {
		// 	return item.address !== '0' &&
		// 		item.address !== '0x0000000000000000000000000000000000000000' &&
		// 		item.address !== ''
		// });
		// if ( collateralFound.length ) {
		// 	return (
		// 		<div className="text-orange mt-4"><small>* { this.t('decimals is unknown; amount shown in wei') }</small></div>
		// 	)
		// } else {
		// 	return null
		// }

		// TODO: Check if unknown
		return null;
	}
	collateralOnDrop(files: Array<File>) {

		if ( this.subscriptionBlocked() ) { return; }

		files.forEach((item) => {
			const fileReader = new FileReader();
			fileReader.addEventListener('load', (e) => {
				let text = e.target?.result;
				if ( typeof(text) !== 'string' ) {
					return;
				}
				if ( !text ) { return; }
				let rows: Array<string> = [];
				if ( text.includes('\r\n') ) {
					rows = text.split('\r\n');
				}
				else {
					if ( text.includes('\n\r') ) {
						rows = text.split('\n\r');
					} else {
						if ( text.includes('\n') ) {
							rows = text.split('\n');
						}
					}
				}

				rows.forEach((iitem) => {
					try {
						const address = iitem.split(';')[0];
						const amount = iitem.split(';')[1].replaceAll(',', '.');

						this.addCollateralRow( address, amount )
					} catch(e) { console.log('Cannot add row from file', iitem) }
				});

			});
			fileReader.readAsText(item)
		})
	}
	getCollateralERC20Balance() {
		if ( this.state.collateralTokenAddressInput === '' ) { return null; }

		let foundToken = this.state.erc20BatchCollateralTokens.find((item) => { return item.address.toLowerCase() === this.state.collateralTokenAddressInput.toLowerCase() });
		if ( !foundToken ) { foundToken = this.state.erc20CollateralTokens.find((item) => { return item.address.toLowerCase() === this.state.collateralTokenAddressInput.toLowerCase() }); }

		if ( !foundToken ) { return null; }

		return (
			<div className="c-add__max mt-2 mb-0">
				<div className="mt-1 mb-1">
					<span>Max: </span>
					<button
						onClick={() => {
							if ( !foundToken ) { return; }
							this.setState({ collateralAmountInput: tokenToFloat(foundToken.balance, foundToken.decimals || 18).toString() })
						}}
					>{ tokenToFloat(foundToken.balance, foundToken.decimals || 18).toString() }</button>
				</div>
				<div>
					<span>Allowance: </span>
					<button
						onClick={() => {
							if ( !foundToken ) { return; }
							this.setState({ collateralAmountInput: tokenToFloat(foundToken.allowance, foundToken.decimals || 18).toString() })
						}}
					>{ tokenToFloat(foundToken.allowance, foundToken.decimals || 18).toString() }</button>
				</div>
			</div>
		)
	}
	getCollateralBlock() {

		if ( this.state.inputRules.noAddCollateral ) {
			return null;
		}

		return (
			<Dropzone
				onDrop={(files) => { this.collateralOnDrop(files) }}
				accept={ '.txt, .csv' }
				noClick={ true }
				noKeyboard={ true }
			>
				{
					({
						getRootProps, getInputProps, isDragActive, open
					}) => (
						<div {...getRootProps({ className: `c-wrap c-b-wrap ${isDragActive && !this.subscriptionBlocked() ? 'file-dragged' : ''}` })}>

							<div className="upload-poopover">
								<div className="inner">
									<div className="h3">{ this.t('Drop your file here') }</div>
								</div>
							</div>

							<input {...getInputProps()} />
							<div className="c-wrap__header" ref={ (e) => { this.scrollable.collaterals = e } }>
								<div className="h3">{ this.t('Collateral for each NFT') }</div>
								<div className="c-wrap__info">
									<div>{ this.t('Enter') } <span className="text-grad">{ this.t('address = 0') }</span> { this.t('to add') } { this.state.symbolNative }</div>
									<img src={ icon_i_attention } alt="" />
								</div>
							</div>
							<div className="c-wrap__form">
								<div className="row">
									<div className="col col-12 col-md-5">
										<label className="input-label">{ this.state.EIPPrefix }-20 { this.t('Address') }</label>
										<div className={ `select-group ${ this.subscriptionBlocked() ? 'disabled' : '' }` } >
											<input
												className="input-control"
												type="text"
												placeholder="0x000"
												disabled={ this.subscriptionBlocked() }
												value={ this.state.collateralTokenAddressInput }
												onChange={(e) => {
													const address = e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "");
													if ( this.metamaskAdapter.web3.utils.isAddress(address) ) {
														let foundERC20 = this.state.erc20CollateralTokens.find((item) => {
															if ( !item.address ) { return false; }
															return item.address.toLowerCase() === address.toLowerCase()
														});
														if ( !foundERC20 ) {
															foundERC20 = this.state.erc20BatchCollateralTokens.find((item) => {
																if ( !item.address ) { return false; }
																return item.address.toLowerCase() === address.toLowerCase()
															});
														}
														if ( !foundERC20 ) {
															if ( !this.SAFTDispatcher ) { return; }
															foundERC20 = new ERC20Contract({
																web3                : this.metamaskAdapter.web3,
																store               : this.store,
																contractAddress     : address,
																contractType        : 'collateral_batchwrapper',
																userAddress         : this.metamaskAdapter.userAddress,
																wrapperAddress      : this.SAFTDispatcher.trustedWrapperAddress,
																whitelistContract   : this.SAFTDispatcher.batchWhitelistContract,
															}).erc20Params;
														}
													}
													this.setState({ collateralTokenAddressInput: address })
												}}
												onKeyPress={(e) => {
													if ( e.defaultPrevented)              { return; }
													if ( !!this.addCollateralDisabled() ) { return; }
													if ( e.key !== 'Enter' )              { return; }

													this.addCollateralRow( this.state.collateralTokenAddressInput, this.state.collateralAmountInput )
												}}
											/>

											<CoinSelector
												store         = { this.store }
												tokens        = { this.filterERC20ContractByPermissions({ enabledForCollateral: true }) }
												selectedToken = { this.state.collateralTokenAddressInput }
												onChange      = {(address: string) => {
													this.setState({ collateralTokenAddressInput: address });
												}}
											/>

										</div>
									</div>
									<div className="col col-12 col-md-4">
										{ this.getCollateralAmountTitle() }
										{/* <label className="input-label">Amount</label> */}
										<input
											className="input-control"
											type="text"
											placeholder=""
											disabled={ this.subscriptionBlocked() }
											value={ addThousandSeparator(this.state.collateralAmountInput) }
											onChange={(e) => {
												let value = removeThousandSeparator(e.target.value);
												if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
													if ( new BigNumber(value).isNaN() ) { return; }
													value = new BigNumber(value).toString();
												}
												this.setState({ collateralAmountInput: value, })
											}}
											onKeyPress={(e) => {
												if ( e.defaultPrevented)              { return; }
												if ( !!this.addCollateralDisabled() ) { return; }
												if ( e.key !== 'Enter' )              { return; }

												this.addCollateralRow( this.state.collateralTokenAddressInput, this.state.collateralAmountInput )
											}}
										/>
										{ this.getCollateralERC20Balance() }
									</div>
									<div className="col col-12 col-md-3">
										<label className="input-label">&nbsp;</label>
										<button
											className="btn btn-grad"
											disabled={ !!this.addCollateralDisabled() }
											onClick={ (e) => {
												this.addCollateralRow( this.state.collateralTokenAddressInput, this.state.collateralAmountInput )
											} }
										>{ this.addCollateralDisabled() ? this.addCollateralDisabled() : 'Add' }</button>
									</div>
								</div>

								<div className="c-wrap__upload-row">
									<button
										className="btn-link btn-csv mr-md-3"
										onClick={ open }
									>{ this.t('Click to upload a CSV file') }</button>
									<span className="d-none d-md-inline">
										<span className="mr-3">{ this.t('or') } </span>
										{ this.t('drag and drop it here') }
									</span>
								</div>

							</div>

							{/* <div className="c-wrap__table mt-3">
								{ this.state.collaterals.map((item) => { return this.getCollateralRow(item) }) }
							</div> */}

							<CollateralViewer
								store={this.store}
								metamaskAdapter={this.metamaskAdapter}
								t={ this.t }
								collaterals={ this.state.collaterals }
								nonRemovableAddress={ this.getNonRemovableAddress() }
								removeRow={(item: CollateralItem) => {
									let collaterals = this.state.collaterals

									if  (
										item.address.toLowerCase() === this.state.collateralTokenAddressInput.toLowerCase()
									) {
										collaterals = [
											...collaterals.filter((iitem) => { return iitem.address.toLowerCase() !== item.address.toLowerCase() }),
											{
												...item,
												amount: new BigNumber(0)
											}
										]
									} else {
										collaterals = [
											...collaterals.filter((iitem) => { return iitem.address.toLowerCase() !== item.address.toLowerCase() }),
										]
									}

									this.setState({
										collaterals,
									});
								}}
								width={ 'wide' }
							/>

							{ this.getCollateralFootnote() }
						</div>
					)
				}
			</Dropzone>
		)
	}

	recalcPercents(recs: Array<RoyaltyInput>) {
		let sum = 10000;
		const recLength = recs.length;
		return recs.map((item: RoyaltyInput): RoyaltyInput => {
			let percentToAdd;
			if ( sum > Math.ceil(10000 / recLength) + recLength ) {
				percentToAdd = new BigNumber(Math.floor(10000 / recLength)).dividedBy(100);
				sum = sum - Math.floor(10000 / recLength);
			} else {
				percentToAdd = new BigNumber(sum).dividedBy(100);
				sum = 0;
			}
			return {
				...item,
				percent: percentToAdd.toFixed(2)
			}
		});
	}
	addRoyaltyRecipient(address: string | undefined) {

		const foundRoyaltyRecipient = this.state.royaltyRecipients.filter((item) => {
			if ( !item.address && !address ) { return true; }
			if ( !item.address ) { return false; }
			if ( !address ) { return false; }
			return item.address.toLowerCase() === address.toLowerCase()
		});
		if ( foundRoyaltyRecipient.length ) { return; }

		let collateralsUpdated = this.state.collaterals;
		if ( !address ) {
			const foundFeeToken = collateralsUpdated.find((item: CollateralItem) => { return item.address.toLowerCase() === this.state.inputTransferFeeAddress.toLowerCase() });
			if ( !foundFeeToken ) {
				collateralsUpdated = [
					...collateralsUpdated,
					{
						assetType: _AssetType.ERC20,
						address: this.state.inputTransferFeeAddress,
						amount: new BigNumber(0),
					}
				]
			}
		}

		this.setState({
			royaltyRecipients: [
				...this.state.royaltyRecipients,
				{
					address: address,
					percent: '0',
					timeAdded: new Date().getTime(),
				}
			],
			collaterals: collateralsUpdated,
			inputRoyaltyRecipientAddress: '',
			showErrors: this.state.showErrors.filter((item) => { return item !== 'royalty' })
		});
	}
	removeRoyaltyRecipient(address: string | undefined) {
		let royaltyRecipientsUpdated: Array<RoyaltyInput>;
		let theWNFTChecked = this.state.inputAddWNFTChecked;
		if ( address ) {
			royaltyRecipientsUpdated = this.state.royaltyRecipients.filter((item) => {
				if ( !item.address ) { return true; }
				return item.address.toLowerCase() !== address.toLowerCase()
			});
		} else {
			royaltyRecipientsUpdated = this.state.royaltyRecipients.filter((item) => {
				return !!item.address
			});
			theWNFTChecked = false;
		}

		let collateralsUpdated = this.state.collaterals;
		if ( !address ) {
			collateralsUpdated = collateralsUpdated.filter((item: CollateralItem) => { return !item.amount || !item.amount.eq(0) })
		}

		this.setState({
			royaltyRecipients: royaltyRecipientsUpdated,
			inputRoyaltyRecipientAddress: '',
			inputAddWNFTChecked: theWNFTChecked,
			collaterals: collateralsUpdated,
		});
	}
	getAddRoyaltyRecipientBtn() {
		return (
			<button
				className="btn btn-grad"
				type="submit"
				disabled={ this.isAddRoyaltyRecipientDisabled() }
				onClick={() => { this.addRoyaltyRecipient(this.state.inputRoyaltyRecipientAddress) }}
			>Add</button>
		)
	}
	isAddRoyaltyRecipientDisabled() {
		if ( this.state.inputRoyaltyRecipientAddress === '' ) { return true; }
		if (
			this.metamaskAdapter.web3 &&
			!this.metamaskAdapter.web3.utils.isAddress(this.state.inputRoyaltyRecipientAddress)
		) { return true; }
		return false;
	}
	getRoyaltyRecipientCalcedRoyalty(item: RoyaltyInput) {
		if ( this.state.inputTransferFeeAmount === '' ) { return '—' }

		const fee = new BigNumber(this.state.inputTransferFeeAmount);
		if ( !fee || fee.isNaN() ) { return '—' }

		const percent = new BigNumber(item.percent).dividedBy(100);

		return fee.multipliedBy(percent).toString()
	}
	getRoyaltyRecipientRow(item: RoyaltyInput, idx: number) {
		return (
			<div
				key={ item.address || 'none' }
				className="item"
			>
				<div className="row">
					<div className="mb-2 col-12 col-md-1">#{ idx }</div>
					<div className="mb-3 mb-md-2 col-12 col-md-4">
						<span className="col-legend">Token: </span>
						<span className="text-break">{ item.address ? compactString(item.address) : 'the wNFT' }</span>
					</div>
					<div className="mb-2 col-12 col-md-2">
						<div className="tb-input tb-percent">
							<input
								className="input-control"
								type="text"
								value={ item.percent.toString() }
								onFocus={(e) => { e.target.select() }}
								onChange={(e) => {

									let value = e.target.value.replaceAll(' ', '').replaceAll(',', '.');
									const valueParsed = new BigNumber(value);

									if ( value === '' ) {
										value = '';
									} else {
										if ( valueParsed.isNaN() ) { return; }
										if ( valueParsed.gt(100) ) { return; }
									}

									// the wNFT
									if ( !item.address ) {
										this.setState({
											royaltyRecipients: [
												...this.state.royaltyRecipients.filter((iitem) => { return !!iitem.address }),
												{
													address: undefined,
													percent: value,
												}
											],
											showErrors: this.state.showErrors.filter((item) => { return item !== 'royalty' })
										});
										return;
									}

									// plain address
									const foundRoyaltyRecipient = this.state.royaltyRecipients.filter((iitem) => {
										if ( !iitem.address ) { return false; }
										if ( !item.address ) { return false; }
										return iitem.address.toLowerCase() === item.address.toLowerCase()
									});
									if ( foundRoyaltyRecipient.length ) {
										this.setState({
											royaltyRecipients: [
												...this.state.royaltyRecipients.filter((iitem) => {
													if ( !iitem.address ) { return true; }
													if ( !item.address ) { return true; }
													return iitem.address.toLowerCase() !== item.address.toLowerCase()
												}),
												{
													address: item.address,
													percent: value,
													timeAdded: foundRoyaltyRecipient[0].timeAdded
												},
											],
											showErrors: this.state.showErrors.filter((item) => { return item !== 'royalty' })
										});
									}
								}}
								onBlur={() => {
									this.setState({
										royaltyRecipients: this.state.royaltyRecipients.map((item) => {
											return {
												...item,
												percent: new BigNumber(item.percent).toFixed(2, BigNumber.ROUND_DOWN)
											}
										})
									});
								}}
							/>
							<Tippy
								content={ this.t('Percent of royalty from the transfer fee amount') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span className="i-tip ml-1"></span>
							</Tippy>
						</div>
					</div>
					<div className="mb-2 col-12 col-md-4">
						<span className="col-legend">Amount: </span>
						<span className="text-break">{ this.getRoyaltyRecipientCalcedRoyalty(item) }</span>
					</div>
					{
						item.address ? (
							<button
								className="btn-del"
								onClick={() => { this.removeRoyaltyRecipient(item.address) }}
							><img src={ icon_i_del } alt="" /></button>
						): null
					}
				</div>
			</div>
		)
	}
	getDistributionAlert() {

		const showError = this.state.showErrors.find((item) => { return item === 'royalty' })
		if ( !showError ) { return null; }

		if ( this.state.inputTransferFeeAmount !== '' ) {
			const sumRoyaltyPercent = this.state.royaltyRecipients.reduce((acc, item) => {
				const percentToAdd = new BigNumber(item.percent);
				if ( percentToAdd.isNaN() ) { return acc }
				return acc.plus(percentToAdd)
			}, new BigNumber(0));
			if ( !sumRoyaltyPercent.eq(100) ) { return ( <div className="alert alert-error mt-3">Royalty percent is not equal 100</div> ) }
		}

		return null;
	}
	getRoyaltyBlock() {

		if ( this.state.inputRules.noTransfer ) { return null; }
		if ( this.state.inputTransferFeeAmount === '' || new BigNumber(this.state.inputTransferFeeAmount).isNaN() || new BigNumber(this.state.inputTransferFeeAmount).eq(0) ) { return null; }

		const showError = this.state.showErrors.find((item) => { return item === 'emptyRoyaltyRecipients' })

		return (
			<div className="c-wrap">
				<div className="c-wrap__header d-block">
					<div className="row align-items-center">
						<div className="col-12 col-md-auto">
							<div
								className="h3 mt-0"
								ref={ (e) => { this.scrollable.royalty = e } }
							>Royalty Recipients</div>
						</div>
						<div className="col-12 col-md-auto">
							<label className="checkbox">
								<input
									type="checkbox"
									disabled={ this.state.inputTransferFeeAmount === '' || this.state.inputRules.noTransfer || this.subscriptionBlocked() }
									checked={ this.state.inputAddWNFTChecked }
									onChange={(e) => {
										if ( e.target.checked ) {
											this.setState({
												inputAddWNFTChecked: e.target.checked,
												showErrors: this.state.showErrors.filter((item) => { return item !== 'emptyRoyaltyRecipients' })
											});
											setTimeout(() => { this.addRoyaltyRecipient(undefined) }, 1);
										} else {
											this.setState({
												inputAddWNFTChecked: e.target.checked,
												showErrors: this.state.showErrors.filter((item) => { return item !== 'emptyRoyaltyRecipients' }),
											})
											setTimeout(() => { this.removeRoyaltyRecipient(undefined) }, 1);
										}
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">Add the wNFT to the recipents’s list</span>
							</label>
						</div>
					</div>
				</div>
				<div className="c-wrap__form">
					<div className="row mb-1">
						<div className="col col-12 col-md-6">
							<label className="input-label">
								Address
								<Tippy
									content={ this.t('Address of royalty income reciever') }
									appendTo={ document.getElementsByClassName("wrapper")[0] }
									trigger='mouseenter'
									interactive={ false }
									arrow={ false }
									maxWidth={ 512 }
								>
									<span className="i-tip"></span>
								</Tippy>
							</label>
							<input
								className={`input-control ${ showError ? 'has-error' : '' }`}
								type="text"
								placeholder="Paste here"
								value={ this.state.inputRoyaltyRecipientAddress }
								disabled={ this.subscriptionBlocked() }
								onChange={(e) => {
									this.setState({
										inputRoyaltyRecipientAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, ""),
										showErrors: this.state.showErrors.filter((item) => { return item !== 'emptyRoyaltyRecipients' })
									})
								}}
								onKeyPress={(e) => {
									if ( e.defaultPrevented)               { return; }
									if ( !!this.isAddRoyaltyRecipientDisabled() ) { return; }
									if ( e.key !== 'Enter' )               { return; }

									this.addRoyaltyRecipient(this.state.inputRoyaltyRecipientAddress)
								}}
							/>
						</div>
						<div className="col col-12 col-md-2">
							<label className="input-label">&nbsp;</label>
							{ this.getAddRoyaltyRecipientBtn() }
						</div>
					</div>

					{
						this.state.royaltyRecipients.length ?
						(
							<div className="row">
								<div className="col-sm-6 col-md-3">
									<button
										className="btn btn-sm btn-gray"
										disabled={ this.subscriptionBlocked() }
										onClick={() => {
											this.setState({
												royaltyRecipients: this.recalcPercents(this.state.royaltyRecipients),
												showErrors: this.state.showErrors.filter((item) => { return item !== 'emptyRoyaltyRecipients' })
											});
										}}
									>Divide percents evenly</button>
								</div>
							</div>
						) : null
					}

					{ this.getDistributionAlert() }

				</div>
				<div className="c-wrap__table mt-3">
					{
						this.state.royaltyRecipients
							.sort((item, prev) => {

								if ( !item.address ) {
									return -1
								}
								if ( !prev.address ) {
									return 1
								}

								// if ( item.address < prev.address ) { return -1 }
								// if ( item.address > prev.address ) { return  1 }

								if ( !item.timeAdded ) {
									return -1
								}
								if ( !prev.timeAdded ) {
									return 1
								}
								if ( item.timeAdded < prev.timeAdded ) { return -1 }
								if ( item.timeAdded > prev.timeAdded ) { return  1 }

								return 0;

							})
							.map((item, idx) => { return this.getRoyaltyRecipientRow(item, idx + 1) })
					}
				</div>
			</div>
		)
	}

	hasErrors() {

		const errors: Array<{ msg: string, block: HTMLElement, showError?: string }> = [];

		// ----- ORIGINAL TOKEN -----
		if (
			this.state.useContract !== 'empty' &&
			this.state.nftAddressInput === ''
		) {
			errors.push({ msg: 'Enter original contract address or set empty', block: this.scrollable.originalToken })
		}

		if (
			this.metamaskAdapter &&
			this.metamaskAdapter.web3 &&
			this.state.useContract !== 'empty' &&
			this.state.nftAddressInput !== '' &&
			!this.metamaskAdapter.web3.utils.isAddress(this.state.nftAddressInput)
		) {
			errors.push({ msg: 'Bad address format', block: this.scrollable.originalToken })
		}

		if ( this.state.SAFTBlacklist.length ) {
			const foundBlacklistItem = this.state.SAFTBlacklist.find((item: any) => {
				if ( typeof(item) === 'string' ) {
					return item.toLowerCase() === this.state.nftAddressInput.toLowerCase()
				}
				return item.contractAddress.toLowerCase() === this.state.nftAddressInput.toLowerCase()
			});
			if ( foundBlacklistItem ) {
				errors.push({ msg: 'Original token address is blacklisted', block: this.scrollable.originalToken })
			}
		}

		if (
			this.state.outputStandart === _AssetType.ERC1155 &&
			( this.state.outputCopies === '' ||
			new BigNumber(this.state.outputCopies).isNaN() ||
			new BigNumber(this.state.outputCopies).lte(0) )
		) {
			errors.push({ msg: 'Enter wnft token amount', block: this.scrollable.standart, showError: 'standart' })
		}
		// ----- END ORIGINAL TOKEN -----

		// ----- COLLATERALS -----'
		if ( this.state.inputRules.noAddCollateral && this.state.collaterals.length ) {
			errors.push({ msg: 'Cannot add collaterals due to rule', block: this.scrollable.collaterals })
		}
		// ----- END COLLATERALS -----s

		// ----- RECIPIENTS -----
		if ( !this.state.recipients.length ) {
			errors.push({ msg: 'Empty NFT recipients list', block: this.scrollable.recipients })
		}
		// ----- END RECIPIENTS -----

		// ----- ROYALTIES -----
		if ( !this.state.inputRules.noTransfer ) {
			if (
				( this.state.inputTransferFeeAmount !== '' && !(new BigNumber(this.state.inputTransferFeeAmount).isNaN()) && !(new BigNumber(this.state.inputTransferFeeAmount).eq(0)) )
				&& !this.state.royaltyRecipients.length
			) {
				errors.push({ msg: 'Empty royalty recipients list', block: this.scrollable.royalty, showError: 'emptyRecipients' })
			} else {
				if ( ( this.state.inputTransferFeeAmount !== '' && !(new BigNumber(this.state.inputTransferFeeAmount).isNaN()) && !(new BigNumber(this.state.inputTransferFeeAmount).eq(0)) ) ) {
					const sumRoyaltyPercent = this.state.royaltyRecipients.reduce((acc, item) => {
						const percentToAdd = new BigNumber(item.percent);
						if ( percentToAdd.isNaN() ) { return acc }
						return acc.plus(percentToAdd)
					}, new BigNumber(0));
					if ( !sumRoyaltyPercent.eq(100) ) { errors.push({ msg: 'Royalty percent is not equal 100', block: this.scrollable.royalty, showError: 'royalty' }) }
				}
			}
		}
		// ----- END ROYALTIES -----

		// ----- LOCKS -----
		if (
			this.state.unlockTimeInput !== ''
		) {
			const days = new BigNumber(this.state.unlockTimeInput);
			if ( days.isNaN() || days.eq(0) ) {
				errors.push({ msg: 'Empty time unlock', block: this.scrollable.timelock, showError: 'timeUnlock' })
			}
		}

		// ----- END LOCKS -----

		// this.state.collateralErrors.forEach((item) => { errors.push({ msg: `Error with collateral: ${compactString(item.address)} - ${item.tokenId || ''}. ${item.msg}`, block: this.scrollable.collaterals }) })

		return errors;
	}
	getErrorsBlock() {
		const errors = this.hasErrors();

		if ( this.subscriptionBlocked() ) { return null; }
		if ( !errors.length ) { return null; }

		return (
			<div className="c-errors mt-4">
				<div className="mb-3">To enable the Wrap button, correct the following errors:</div>
				<ul>
				{
					errors.map((item) => {
						return (
							<li key={ item.msg }>
								<button
									className="btn-link"
									onClick={() => {


										item.block.scrollIntoView();
										if ( item.showError ) {
											this.setState({
												showErrors: [
													...this.state.showErrors.filter((iitem) => { return iitem !== item.showError }),
													item.showError
												]
											})
										}
									}}
								>
									{ item.msg }
								</button>
							</li>
						)
					})
				}
				</ul>
			</div>
		)
	}

	submitBtnDisabled() {

		if ( this.subscriptionBlocked() ) { return true; }

		if (
			this.state.useContract !== 'empty' &&
			( !this.metamaskAdapter.web3 || !this.metamaskAdapter.web3.utils.isAddress(this.state.nftAddressInput) )
		) {
			return true;
		}
		if ( !this.state.recipients.length ) {
			return true;
		}

		const foundEmptyRecTokenId = this.state.recipients.filter((item) => { return !item.tokenId });
		if ( this.state.useContract !== 'empty' && foundEmptyRecTokenId.length ) {
			return true;
		}

		return false;
	}
	getClearBtn() {
		return (
			<button
				className="btn btn-yellow w-100"
				onClick={() => {
					this.setState({
						useContract: 'own',
						mintChecked: false,
						collectionName: '',
						badContract: false,
						fetchedTokens: [],
						nftAddressInput: '',
						unlockTimeInput: '',
						recipients: [],
						recipientAddressInput: '',
						recipientTokenIdInput: '',
						collaterals: [],
						collateralTokenAddressInput: '',
						collateralAmountInput: '',
						outputCopies: '',
						outputStandart: _AssetType.ERC721,
					});
				}}
			>{ this.t('Clear form') }</button>
		)
	}
	getFeeToken() {
		let feeToken = undefined;
		if ( this.state.techToken && this.state.inputTransferFeeAddress.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
			feeToken = this.state.techToken;
		} else {
			let foundToken = this.state.erc20BatchCollateralTokens.find((item) => {
				if ( !item.address ) { return false;}
				return item.address.toLowerCase() === this.state.inputTransferFeeAddress.toLowerCase()
			});
			if ( !foundToken ) {
				foundToken = this.state.erc20CollateralTokens.find((item) => {
					if ( !item.address ) { return false;}
					return item.address.toLowerCase() === this.state.inputTransferFeeAddress.toLowerCase()
				});
			}
			if ( foundToken ) {
				feeToken = foundToken;
			}
		}
		return feeToken;
	}
	getWrapParamLocks(): Array<Lock> {
		const locks: Array<Lock> = [];

		if ( this.state.unlockTimeInput !== '' ) {
			const days = parseInt(this.state.unlockTimeInput);
			const unlockDate = this.addDays(days);
			locks.push({
				lockType: LockType.time,
				param: dateToUnixtime(unlockDate),
			});
		}

		return locks;
	}
	getWrapParamFees(): Array<Fee> {

		if ( this.state.inputRules.noTransfer ) { return []; }
		if ( this.state.inputTransferFeeAddress === '' ) { return []; }
		if ( this.state.inputTransferFeeAmount === '' ) { return []; }

		const feeToken = this.getFeeToken();
		if ( !feeToken ) {
			console.log('Cannot parse feetoken');
			throw new Error('Cannot parse feetoken');
		}

		const amount = tokenToInt(new BigNumber(this.state.inputTransferFeeAmount), feeToken.decimals || 18);

		const output: Array<Fee> = [];
		if ( this.state.inputTransferFeeAmount !== '' ) {
			output.push({
				token: this.state.inputTransferFeeAddress,
				value: amount,
			});
		}
		return output;
	}
	submitWrap() {
		if ( !this.SAFTDispatcher ) {
			this.SAFTDispatcher = this.metamaskAdapter.getSAFTDispatcher();
			if ( !this.SAFTDispatcher ) { return; }
		}

		let unwrapAfter = new BigNumber(0);
		if ( this.state.unlockTimeInput ) {
			const days = parseInt(this.state.unlockTimeInput);
			const newDate = this.addDays(days).getTime();
			unwrapAfter = new BigNumber( parseInt(`${newDate / 1000}`) );

			if ( unwrapAfter.isNaN() ) { unwrapAfter = new BigNumber(0); }
		}

		let recipientsToSubmit = this.state.recipients;
		if ( this.state.useContract === 'empty' || this.state.mintChecked ) {
			recipientsToSubmit = this.state.recipients.map((item) => { return { ...item, tokenId: '0' } });
		}

		let royaltiesParsed: Array<Royalty> = [];
		if ( !this.state.inputRules.noTransfer ) {
			royaltiesParsed = this.state.royaltyRecipients.map((item: RoyaltyInput): Royalty => {
				return {
					address: item.address,
					percent: new BigNumber(item.percent)
				}
			})
		}

		let updateSubscriptionFunc = undefined;
		if ( this.subscriptionDispatcher ) {
			updateSubscriptionFunc = () => {
				if ( this.subscriptionDispatcher ) {
					this.subscriptionDispatcher.checkSubcription();
				}
			}
		}
		this.SAFTDispatcher.SAFTToken({
			originalAddress: this.state.useContract === 'empty' ?
				undefined : { assetType: this.state.inputassetType, contractAddress: this.state.nftAddressInput },
			recipients: recipientsToSubmit,
			collaterals: this.state.inputRules.noAddCollateral ? [] : this.state.collaterals,
			unwrapAfter,
			fees             : this.getWrapParamFees(),
			royalties        : royaltiesParsed,
			rules            : this.state.inputRules,
			locks            : this.getWrapParamLocks(),
			outType          : this.state.outputStandart,
			outBalance       : this.state.outputStandart === _AssetType.ERC721 ? 0 : parseInt(this.state.outputCopies) || 1,
			// unwrapDestination: this.state.inputWNFTRecipientAddress !== '' ? this.state.inputWNFTRecipientAddress : '0x0000000000000000000000000000000000000000',
			unwrapDestination: '0x0000000000000000000000000000000000000000',
			updateSubscriptionFunc,
			isMultisig: this.state.authMethod === 'gnosis',
		});

		if ( this.state.useContract === 'own' && this.state.nftAddressInput !== '' ) {
			const updatedContractList = [
				...this.state.savedAddresses.filter((item: string | { address: string, standart: _AssetType }) => {
					if ( typeof item === 'string' ) {
						return item.toLowerCase() !== this.state.nftAddressInput.toLowerCase()
					}
					return item.address.toLowerCase() !== this.state.nftAddressInput.toLowerCase()
				}),
				{ address: this.state.nftAddressInput, standart: this.state.inputassetType, chainId: this.state.chainId }
			]
			localStorageSet('SAFT_originalContracts', JSON.stringify(updatedContractList));
			this.setState({ savedAddresses: updatedContractList })
		}
	}
	getSubmitBtn() {
		if ( this.submitBtnDisabled() ) {
			return (
				<button
					className="btn btn-grad w-100"
					disabled={ true }
				>{ this.t('Wrap Batch') }</button>
			)
		}

		if ( this.state.badContract ) {
			return (
				<button
					className="btn btn-grad w-100 btn-yellow"
					disabled={ false }
					onClick={() => { this.submitWrap() }}
				>{ this.t('Transaction could be reverted') }</button>
			)
		}

		return (
			<button
				className="btn btn-grad w-100"
				disabled={ false }
				onClick={() => { this.submitWrap() }}
			>{ this.t('Wrap Batch') }</button>
		)
	}
	getHistoryBlock() {
		return (
			<div className="c-wrap c-b-wrap">
				<div className="h4 mb-4"> <b>{ this.t('Transactions History') }</b></div>
				<div className="c-wrap__table">
					<div className="item item-header">
						<div className="row">
							<div className="col-2 col-md-1 mb-2"> </div>
							<div className="col-10 col-md-3 mb-2">{ this.t('Date') }</div>
							<div className="col-md-4 col-lg-3 mb-2">{ this.t('Tx Hash') }</div>
							<div className="col-6 col-md-2 mb-2 text-right">{ this.t('Batch size') }</div>
							<div className="col-6 col-md-2 col-lg-3 mb-2 text-right">{ this.t('Status') }</div>
						</div>
					</div>
					<div className="item">
						<div className="row">
							<div className="col-2 col-md-1 mb-2">#1</div>
							<div className="col-10 col-md-3 mb-2">2022-01-15 20:10:03 UTC</div>
							<div className="col-md-4 col-lg-3 mb-2"><a href="https://etherscan.io/">0x8679a598.......213ac1185</a></div>
							<div className="col-8 col-md-2 mb-2">
								<div className="batch-size">
									<span className="col-legend">{ this.t('Batch size') }:</span>
									8000
								</div>
							</div>
							<div className="col-4 col-md-2 col-lg-3 mb-2 text-right">
								<b className="text-orange">{ this.t('Pending') }</b>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="row">
							<div className="col-2 col-md-1 mb-2">#2</div>
							<div className="col-10 col-md-3 mb-2">2022-01-15 20:10:03 UTC</div>
							<div className="col-md-4 col-lg-3 mb-2"><a href="https://etherscan.io/">0x8679a598.......213ac1185</a></div>
							<div className="col-8 col-md-2 mb-2">
								<div className="batch-size">
									<span className="col-legend">{ this.t('Batch size') }:</span>
									8970
								</div>
							</div>
							<div className="col-4 col-md-2 col-lg-3 mb-2 text-right">
								<b className="text-red">{ this.t('Revert') }</b>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="row">
							<div className="col-2 col-md-1 mb-2">#3</div>
							<div className="col-10 col-md-3 mb-2">2022-01-15 20:10:03 UTC</div>
							<div className="col-md-4 col-lg-3 mb-2"><a href="https://etherscan.io/">0x8679a598.......213ac1185</a></div>
							<div className="col-8 col-md-2 mb-2">
								<div className="batch-size">
									<span className="col-legend">{ this.t('Batch size') }:</span>
									1111
								</div>
							</div>
							<div className="col-4 col-md-2 col-lg-3 mb-2 text-right">
								<b className="text-green">{ this.t('OK') }</b>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	openMinterPage() {
		this.setState({ showMinterPage: true });
	}
	closeMinterPage(address?: string) {
		const addressToSet = address ? address : this.state.nftAddressInput
		this.fetchCollectionName(addressToSet);
		this.setState({
			showMinterPage: false,

			nftAddressInput: addressToSet,
			collectionName: '',
			badContract: false,
			fetchedTokens: [],
		});
	}

	subscriptionBlocked() {
		if ( !this.subscriptionDispatcher ) { return false; }
		return this.subscriptionDispatcher.isLocked();
		// return this.state.subscriptionLocked;
	}

	render() {

		if ( this.state.showMinterPage ) {
			return (
				<MintPage
					store = { this.store }
					metamaskAdapter = { this.metamaskAdapter }
					closePageFunction={(address?: string) => { this.closeMinterPage(address) }}
				/>
			)
		}

		return (
			<React.Fragment>
			<main className="s-main">
				<div className="container">

					{
						this.subscriptionDispatcher ?
						<SubscriptionRenderer subscriptionDispatcher={ this.subscriptionDispatcher } /> : null
					}

					<div className="row">
						<div className="col-lg-8">
							{ this.getMintBlock() }
							{ this.getStandartSelectorBlock() }
						</div>
						<div className="col-lg-4">
							{ this.getAdvancedOptionsBlock() }
						</div>
					</div>

					{ this.getFeeBlock() }
					{ this.getRoyaltyBlock() }
					{ this.getRecipientsBlock() }
					{ this.getCollateralBlock() }

					<div className="row mb-7 mb-lg-9 justify-content-sm-between">
						<div className="col-12 col-sm-3 mt-3 order-2 order-sm-1">
							{ this.getClearBtn() }
						</div>
						<div className="col-12 col-sm-6 mt-3 order-1 order-sm-2">
							{ this.getSubmitBtn() }
							{ this.getErrorsBlock() }
						</div>
					</div>

					{/* { this.getHistoryBlock() } */}

				</div>
			</main>
			</React.Fragment>
		)
	}
}

export default withTranslation("translations")(withRouter(SAFTPage));