import React               from 'react';
import Tippy               from '@tippyjs/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
	withRouter,
	match,
	Link
} from 'react-router-dom';
import {
	ERC20ContractParamsType,
	Lock,
	LockType,
	MetamaskAdapter,
	removeERC721Token,
	WrappedTokenType,
	OriginalTokenType,
	_AssetType,
	assetTypeToString
} from '../../models/BlockchainAdapter';
import {
	incompleteTokensRemove,
} from '../../reducers';

import AddValuePopup       from '../AddValuePopup';
import TransferPopup       from '../TransferPopup';
import ApprovePopup        from '../ApprovePopup';
import TokenIconViewer     from '../CoinIconViewer';
import TokenViewer         from '../CoinViewer';
import CrossingFreezePopup from '../CrossingFreezePopup';

import icon_logo           from "../../static/pics/logo.svg";
import icon_i_copy         from '../../static/pics/i-copy.svg';
import icon_i_plus         from '../../static/pics/i-plus.svg';
import icon_i_link         from '../../static/pics/i-link.svg';
import icon_i_action       from '../../static/pics/icons/i-dots-hor.svg';

import {
	addThousandSeparator,
	compactString,
	tokenToFloat,
	unixtimeToStr,
} from '../../models/_utils';
import {
	History,
	Location
} from 'history';
import { Trans, withTranslation } from "react-i18next";

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

export enum TokenRenderType {
	waiting,
	incomplete,
	wrapped,
	discovered,
}

type TokenInListProps = {
	store          : any,
	metamaskAdapter: MetamaskAdapter,
	wrappedToken?  : WrappedTokenType,
	originalToken? : OriginalTokenType,
	tokenType      : TokenRenderType,
	footerMsg?     : string,
	t              : any,
	match          : match;
	location       : Location,
	history        : History,
}
type TokenInListState = {
	decimalsNative   : number,
	symbolNative     : string,
	iconNative       : string,
	minterContract   : string,

	techToken        : ERC20ContractParamsType,

	chainId          : number,
	metamaskLogged   : boolean,
	explorerBaseUrl  : string,
	marketplaceUrl   : string,
	EIPPrefix        : string,
	userAddress      : string,

	erc20CollateralTokens: Array<ERC20ContractParamsType>,
	erc20OtherTechTokens : Array<ERC20ContractParamsType>,

	copiedHintWhere : string,
	menuOpened      : boolean,
	cursorOnCardMenu: boolean,
	tokensListOpened: boolean,

	crossing: {
		keeper: string,
		spawner: string,
		targetChains: Array<{ targetChainId: number, address: string, icon?: string, name?: string, }>
	},

	addValuePopup: boolean,
	transferPopup: boolean,
	approvePopup : boolean,
	freezePopup  : boolean,

	v0AppLink    : string,
}

class TokenInList extends React.Component<TokenInListProps, TokenInListState> {

	store                 : any;
	unsubscribe!          : Function;
	metamaskAdapter       : MetamaskAdapter;
	t                     : any;
	copiedHintTimer       : number;
	copiedHintTimeout     : number;
	mintSubscribtion      : any | undefined;

	userMenuBlockRef      : React.RefObject<HTMLInputElement>;
	tokensListBlockRef    : React.RefObject<HTMLInputElement>;

	constructor(props: TokenInListProps) {
		super(props);

		this.store              = props.store;
		this.metamaskAdapter    = props.metamaskAdapter;
		this.t                  = props.t;
		this.copiedHintTimer    = 0;
		this.copiedHintTimeout  = 2; // s

		this.userMenuBlockRef   = React.createRef();
		this.tokensListBlockRef = React.createRef();

		this.state = {
			decimalsNative       : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative         : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative           : this.store.getState().metamaskAdapter.networkTokenIcon,
			minterContract       : this.store.getState().metamaskAdapter.minterContract,

			techToken            : this.store.getState().erc20TechTokenParams,

			chainId              : this.store.getState().metamaskAdapter.chainId,
			metamaskLogged       : this.store.getState().metamaskAdapter.logged,
			explorerBaseUrl      : this.store.getState().metamaskAdapter.explorerBaseUrl,
			marketplaceUrl       : this.store.getState().metamaskAdapter.marketplaceUrl,
			EIPPrefix            : this.store.getState().metamaskAdapter.EIPPrefix,
			userAddress          : this.store.getState().account.address,

			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
			erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,

			crossing             : this.store.getState().metamaskAdapter.crossing,

			copiedHintWhere      : '',
			menuOpened           : false,
			cursorOnCardMenu     : false,
			tokensListOpened     : false,

			addValuePopup        : false,
			transferPopup        : false,
			approvePopup         : false,
			freezePopup          : false,

			v0AppLink            : 'https://appv0.envelop.is',
		}
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {
			this.setState({
				decimalsNative       : this.store.getState().metamaskAdapter.networkTokenDecimals,
				iconNative           : this.store.getState().metamaskAdapter.networkTokenIcon,
				symbolNative         : this.store.getState().metamaskAdapter.networkTokenTicket,
				minterContract       : this.store.getState().metamaskAdapter.minterContract,
				techToken            : this.store.getState().erc20TechTokenParams,
				chainId              : this.store.getState().metamaskAdapter.chainId,
				metamaskLogged       : this.store.getState().metamaskAdapter.logged,
				explorerBaseUrl      : this.store.getState().metamaskAdapter.explorerBaseUrl,
				marketplaceUrl       : this.store.getState().metamaskAdapter.marketplaceUrl,
				EIPPrefix            : this.store.getState().metamaskAdapter.EIPPrefix,
				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
				erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
				userAddress          : this.store.getState().account.address,

				crossing             : this.store.getState().metamaskAdapter.crossing,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	// ----- HEADER -----
	getIdBlock() {
		let tokenId = '';
		if ( this.props.wrappedToken ) {
			tokenId = this.props.wrappedToken.tokenId;
		} else {
		    tokenId = this.props.originalToken ? this.props.originalToken.tokenId || '' : '';
		}

		if ( `${tokenId}`.length < 8 ) {
			return (
				<CopyToClipboard
					text={ tokenId }
					onCopy={() => {
						this.setState({
							copiedHintWhere: 'id'
						});
						clearTimeout(this.copiedHintTimer);
						this.copiedHintTimer = window.setTimeout(() => { this.setState({
							copiedHintWhere: ''
						}); }, this.copiedHintTimeout*1000);
					}}
				>
					<button className="btn-copy">
						<span>
							<span className="title">{ this.t('ID') }</span>
							{ compactString(tokenId) }
						</span>
						<img src={icon_i_copy} alt="" />
						{ this.getCopiedHint('id') }
					</button>
				</CopyToClipboard>
			)
		}

		return (
			<CopyToClipboard
				text={ tokenId }
				onCopy={() => {
					this.setState({
						copiedHintWhere: 'id'
					});
					clearTimeout(this.copiedHintTimer);
					this.copiedHintTimer = window.setTimeout(() => { this.setState({
						copiedHintWhere: ''
					}); }, this.copiedHintTimeout*1000);
				}}
			>
				<button className="btn-copy">
					<Tippy
						content={ tokenId }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<span>
							<span className="title">{ this.t('ID') }</span>
							{ compactString(tokenId) }
						</span>
					</Tippy>
					<img src={icon_i_copy} alt="" />
					{ this.getCopiedHint('id') }
				</button>
			</CopyToClipboard>
		)
	}
	getContractAddressBlock() {
		let contractAddress = '';
		if ( this.props.wrappedToken ) {
			contractAddress = this.props.wrappedToken.contractAddress;
		} else {
		    contractAddress = this.props.originalToken ? this.props.originalToken.contractAddress || '' : '';
		}

		return (
			<CopyToClipboard
				text={ contractAddress }
				onCopy={() => {
					this.setState({
						copiedHintWhere: 'address'
					});
					clearTimeout(this.copiedHintTimer);
					this.copiedHintTimer = window.setTimeout(() => { this.setState({
						copiedHintWhere: ''
					}); }, this.copiedHintTimeout*1000);
				}}
			>
				<button className="btn-copy">
					<Tippy
						content={ contractAddress }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<span>
							<span className="title">{ this.t('ADDRESS') }</span>
							{ compactString(contractAddress) }
						</span>
					</Tippy>
					<img src={icon_i_copy} alt="" />
					{ this.getCopiedHint('address') }
				</button>
			</CopyToClipboard>
		)
	}
	getCardHeader() {
		return (
			<div>
				{ this.getContractAddressBlock() }
				{ this.getIdBlock() }
			</div>
		)
	}
	// ----- MENU -----
	getMenuItemCopy() {

		let tokenId = '';
		if ( this.props.wrappedToken ) {
			tokenId = this.props.wrappedToken.tokenId;
		} else {
		    tokenId = this.props.originalToken ? this.props.originalToken.tokenId || '' : '';
		}
		let contractAddress = '';
		if ( this.props.wrappedToken ) {
			contractAddress = this.props.wrappedToken.contractAddress;
		} else {
		    contractAddress = this.props.originalToken ? this.props.originalToken.contractAddress || '' : '';
		}

		return (
			<li>
				<CopyToClipboard
					text={ `${window.location.origin}/token/${this.state.chainId}/${contractAddress}/${tokenId}` }
					onCopy={() => {
						this.setState({
							copiedHintWhere: 'tokenlinkimg'
						});
						clearTimeout(this.copiedHintTimer);
						this.copiedHintTimer = window.setTimeout(() => { this.setState({
							copiedHintWhere: ''
						}); }, this.copiedHintTimeout*1000);
					}}
				>
					<button>
					{ this.t('Copy NFT URL') }
					</button>
				</CopyToClipboard>
			</li>
		)
	}
	getMenuItemTransfer() {
		const getTransferFeeLabel = () => {
			if ( !this.props.wrappedToken || !this.props.wrappedToken.fees.length ) { return null; }
			if ( !this.props.wrappedToken.fees.length ) { return null; }

			if ( this.props.wrappedToken.fees[0].token.toLowerCase() === '0x0000000000000000000000000000000000000000' ) {
				return (
					<span className="data">
						{ addThousandSeparator(tokenToFloat(this.props.wrappedToken.fees[0].value, this.state.decimalsNative || 18).toString()) }
						{ '  ' }
						{ this.state.symbolNative }
					</span>
				)
			}

			if ( this.props.wrappedToken.fees[0].token.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
				return (
					<span className="data">
						{ addThousandSeparator(tokenToFloat(this.props.wrappedToken.fees[0].value, this.state.techToken.decimals || 18).toString()) }
						{ '  ' }
						{ this.state.techToken.symbol }
					</span>
				)
			}

			const foundTech = this.state.erc20OtherTechTokens.find((item) => {
				if ( !this.props.wrappedToken ) { return false; }
				return item.address.toLowerCase() === this.props.wrappedToken.fees[0].token.toLowerCase()
			});
			if ( foundTech ) {
				return (
					<span className="data">
						{ addThousandSeparator(tokenToFloat(this.props.wrappedToken.fees[0].value, foundTech.decimals || 18).toString()) }
						{ '  ' }
						{ foundTech.symbol }
					</span>
				)
			}

			const foundToken = this.state.erc20CollateralTokens.find((item) => {
				if ( !this.props.wrappedToken ) { return false; }
				return item.address.toLowerCase() === this.props.wrappedToken.fees[0].token.toLowerCase()
			});
			if ( foundToken ) {
				return (
					<span className="data">
						{ addThousandSeparator(tokenToFloat(this.props.wrappedToken.fees[0].value, foundToken.decimals || 18).toString()) }
						{ '  ' }
						{ foundToken.symbol }
					</span>
				)
			}

		}

		if (
			this.props.tokenType === TokenRenderType.wrapped
		) {
			if ( !this.props.wrappedToken ) { return null; }
			if ( this.props.wrappedToken.owner && this.props.wrappedToken.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) { return null; }
			if ( this.props.wrappedToken.amount && this.props.wrappedToken.amount.lte(0) ) { return null; }

			return (
				<li>
					<button
						onClick={() => {
							this.setState({
								transferPopup: true,
							})
						}}
					>
						{ this.t('Transfer') }
						<span className="data">
							{ getTransferFeeLabel() }
						</span>
					</button>
				</li>
			)
		} else {
			if ( !this.props.originalToken ) { return null; }
			if ( this.props.originalToken.owner && this.props.originalToken.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) { return null; }
			if ( this.props.originalToken.amount && this.props.originalToken.amount.lte(0) ) { return null; }
			return (
				<li>
					<button
						onClick={() => {
							this.setState({
								transferPopup: true,
							})
						}}
					>
						{ this.t('Transfer') }
					</button>
				</li>
			)
		}
	}
	getMenuItemApprove() {
		return (
			<li>
				<button
					onClick={() => {
						this.setState({
							approvePopup: true
						})
					}}
				>
					{ this.t('Set approval for all tokens of contract') }
				</button>
			</li>
		)
	}
	getMenuItemRemoveIncomplete() {
		if ( this.props.tokenType === TokenRenderType.incomplete ) {
			return (
				<li>
					<button
						onClick={() => {
							if ( !this.props.originalToken ) { return; }
							this.store.dispatch(incompleteTokensRemove(this.props.originalToken));
							removeERC721Token(this.props.originalToken, this.state.chainId)
						}}
					>{ this.t('Delete NFT') }</button>
				</li>
			)
		}
	}
	getMenuTokenSellButton() {
		if ( !this.state.marketplaceUrl ) { return null; }
		if ( !this.props.wrappedToken ) { return null; }
		if ( this.props.wrappedToken.owner && this.props.wrappedToken.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) { return null; }

		return (
			<li>
				<button
					onClick={() => {
						if ( !this.props.wrappedToken ) { return; }
						window.open(`https://scotch.sale/nft/${this.state.chainId}/${this.props.wrappedToken.contractAddress}/${this.props.wrappedToken.tokenId}`, '_blank')
					}}
				>
					Sell
				</button>
			</li>
		)
	}
	getMenuItemCrossingPopup() {
		if ( this.state.crossing && this.props.wrappedToken ) {
			return (
				<li>
					<button
						onClick={() => {
							this.setState({ freezePopup: true });
						}}
					>{ this.t('Prepare for crossing') }</button>
				</li>
			)
		}
	}
	openCardMenu = () => {
		setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = (e: any) => {
				if ( !this.userMenuBlockRef.current ) { return; }
				if ( e.path && e.path.includes(this.userMenuBlockRef.current) ) { return; }
				this.closeCardMenu();
			};
		}, 100);
		this.setState({ menuOpened: true });
	}
	closeCardMenu = () => {
		setTimeout(() => {
			if ( this.state.cursorOnCardMenu ) { return; }
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = null;
			this.setState({ menuOpened: false });
		}, 100);
	}
	getCardMenuList() {
		if ( this.state.menuOpened ) {
			return (
				<ul
					className="list-action"
					onMouseEnter={() => {
						this.openCardMenu();
						this.setState({ cursorOnCardMenu: true })
					}}
					onMouseLeave={() => {
						this.closeCardMenu();
						this.setState({ cursorOnCardMenu: false })
					}}
				>
					{ this.getMenuItemCopy() }
					{ this.getMenuItemTransfer() }
					{ this.getMenuItemApprove() }
					{ this.getMenuItemRemoveIncomplete() }
					{ this.getMenuTokenSellButton() }
					{ this.getMenuItemCrossingPopup() }
				</ul>
			)
		}
	}
	getCardMenu() {
		return (
			<div
				className="w-card__action"
				ref={ this.userMenuBlockRef }
			>
				<button
					className="btn-action"
					onMouseEnter={ this.openCardMenu }
					onMouseLeave={ this.closeCardMenu }
					onClick={ this.openCardMenu }
				>
					<img src={icon_i_action} alt="" />
				</button>

				{ this.getCardMenuList() }
			</div>
		)
	}
	// ----- END MENU -----
	// ----- END HEADER -----

	// ----- DATA -----
	closeTokenList = () => {
		const body = document.querySelector('body');
		if ( !body ) { return; }
		body.onclick = null;
		this.setState({ tokensListOpened: false });
	}
	openTokenList = () => {
		const body = document.querySelector('body');
		if ( !body ) { return; }
		body.onclick = (e: any) => {
			if ( !this.tokensListBlockRef.current ) { return; }
			if ( e.path && e.path.includes(this.tokensListBlockRef.current) ) { return; }
			this.closeTokenList();
		};
		this.setState({ tokensListOpened: true });
	}
	getCollateralBlock() {

		if ( !this.props.wrappedToken ) { return; }

		const ITEMS_TO_SHOW = 5;
		let additionalAssetssQty = this.props.wrappedToken.collateral.length - ITEMS_TO_SHOW;

		return (
			<div className="field-wrap">
				<div className="field-row">
					<label className="field-label">{ this.t('Collateral') }</label>
				</div>
				<div className="field-row">
					<div
						className="field-control field-collateral"
						ref={ this.tokensListBlockRef }
						onClick={ this.openTokenList }
						onMouseEnter={ this.openTokenList }
						onMouseLeave={ this.closeTokenList }
					>
						<TokenIconViewer
							store  = { this.store }
							tokens = { this.props.wrappedToken.collateral.slice(0,ITEMS_TO_SHOW) }
						/>
						{
							this.props.wrappedToken.collateral.length === 0 ?
							(
								<div className="sum">no assets</div>
							) : null
						}
						{
							this.props.wrappedToken.collateral.length > ITEMS_TO_SHOW ?
							(
								<span className="more-assets">
									{ '+' }
									{ additionalAssetssQty || '' }
									{ ' ' }
									{ additionalAssetssQty > 0 ? ( additionalAssetssQty === 1 ? this.t('asset') : this.t('assets') ) : this.t('no assets') }
								</span>
							) : null
						}
						{
							this.state.tokensListOpened && this.props.wrappedToken.collateral.length ?
								<TokenViewer
									store  = { this.store }
									tokens = { this.props.wrappedToken.collateral }
								/>
								: null
						}
					</div>
					{
						this.props.tokenType === TokenRenderType.wrapped && !this.props.wrappedToken.rules.noAddCollateral ?
						(
							<button
								className="btn-add"
								onClick={() => {
									this.setState({
										addValuePopup: true,
									})
								}}
							>
								<img src={ icon_i_plus } alt="" />
							</button>
						) : ''
					}
				</div>
			</div>
		)
	}
	// ----- END DATA -----

	getType() {
		let assetType = undefined;
		if ( this.props.wrappedToken ) {
			assetType = this.props.wrappedToken.assetType;
		} else {
		    assetType = this.props.originalToken ? this.props.originalToken.assetType || undefined : undefined;
		}

		if ( assetType ) {
			return ( <span className="w-card__tag" style={{ textTransform: 'none' }}>{ assetTypeToString(assetType, this.state.EIPPrefix) }</span> )
		}

		// if ( assetType === _AssetType.ERC721 ) {
		// 	return ( <span className="w-card__tag">{ this.state.EIPPrefix }-721</span> )
		// }
		// if ( assetType === _AssetType.ERC1155 ) {
		// 	return ( <span className="w-card__tag">{ this.state.EIPPrefix }-1155</span> )
		// }

		return null;
	}
	getIsNFTKey() {
		if ( !this.state.crossing || !this.state.crossing.spawner) { return null; }

		if ( this.props.wrappedToken  && this.props.wrappedToken.contractAddress.toLowerCase()  !== this.state.crossing.spawner.toLowerCase() ) { return null; }
		if ( this.props.originalToken && this.props.originalToken.contractAddress && this.props.originalToken.contractAddress.toLowerCase() !== this.state.crossing.spawner.toLowerCase() ) { return null; }

		return ( <span className="w-card__tag bg-white">NFT key</span> )
	}
	getExtra() {
		return (
			<div className="extra">
				{ this.getType() }
				{ this.getIsNFTKey() }
				{ this.getCopyButton() }
			</div>
		)
	}
	getMedia() {

		let image: string | undefined = '';
		if ( this.props.wrappedToken  ) { image = this.props.wrappedToken.image }
		if ( this.props.originalToken ) { image = this.props.originalToken.image }
		let tokenId = '';
		if ( this.props.wrappedToken ) {
			tokenId = this.props.wrappedToken.tokenId;
		} else {
		    tokenId = this.props.originalToken ? this.props.originalToken.tokenId || '' : '';
		}
		let contractAddress = '';
		if ( this.props.wrappedToken ) {
			contractAddress = this.props.wrappedToken.contractAddress;
		} else {
		    contractAddress = this.props.originalToken ? this.props.originalToken.contractAddress || '' : '';
		}

		if ( image === undefined ) {
			return (
				<Link
					className="inner"
					to={`/token/${this.state.chainId}/${contractAddress}/${tokenId}`}
				>
					<div className="default">
						<img src={ icon_logo } alt="" />
						<span><Trans i18nKey="Loading NON-FUNGIBLE TOKEN Preview" components={[<br />]} /></span>
					</div>
				</Link>
			)
		}
		if ( image === '' ) {
			return (
				<Link
					className="inner"
					to={`/token/${this.state.chainId}/${contractAddress}/${tokenId}`}
				>
					<div className="default">
						<img src={ icon_logo } alt="" />
						<span><Trans i18nKey="Cannot load NON-FUNGIBLE TOKEN Preview" components={[<br />]} /></span>
					</div>
				</Link>
			)
		}

		return (
			<Link
				className="inner"
				to={`/token/${this.state.chainId}/${contractAddress}/${tokenId}`}
			>
				<video className="img" src={ image } poster={ image } autoPlay={ true } muted={ true } loop={ true } />
			</Link>
		)
	}
	getCardFooter() {

		if ( this.props.wrappedToken && this.props.wrappedToken.assetType === _AssetType.wNFTv0 ) {
			return (
				<a
					className="btn btn-lg btn-yellow"
					target="_blank" rel="noopener noreferrer"
					href={`${this.state.v0AppLink}/#/token?chain=${this.state.chainId}&contractAddress=${this.props.wrappedToken.contractAddress}&tokenId=${this.props.wrappedToken.tokenId}`}
				>{ this.t('Unwrap in v0 app') }</a>
			)
		}

		if ( this.props.tokenType === TokenRenderType.discovered ) {
			if ( !this.props.originalToken ) { return null; }
			return (
				<React.Fragment>
				{/* <Link
					className="btn btn-lg btn-primary"
					to={`/token/${this.state.chainId}/${this.props.originalToken.contractAddress}/${this.props.originalToken.tokenId}`}
				>{ this.t('View') }</Link> */}
				<Link
					className="btn btn-lg btn-primary"
					to={`/wrap/${this.state.chainId}/${this.props.originalToken.contractAddress}/${this.props.originalToken.tokenId}`}
				>{ this.t('WRAP') }</Link>
				{/* <a
					className="btn btn-lg btn-primary"
					href={`/wrap?chainId=${this.state.chainId}&contractAddress=${this.props.originalToken.contractAddress}&tokenId=${this.props.originalToken.tokenId}`}
				>{ this.t('WRAP') }</a> */}
				</React.Fragment>
			)
		}
		if ( this.props.tokenType === TokenRenderType.waiting ) {
			return (
				<div className="w-card__status">
					<div>
						<span>{ this.props.footerMsg?.toUpperCase() }</span>
					</div>
				</div>
			)
		}

		if ( !this.props.wrappedToken ) { return null; }
		const conditions: Array<string> = [];

		if ( this.props.wrappedToken.rules.noUnwrap ) {
			conditions.push('No unwrap')
		}

		this.props.wrappedToken.locks.forEach((item: Lock) => {

			if ( item.lockType === LockType.time ) {
				const nowDate = new BigNumber(new Date().getTime());
				if ( item.param.gt( nowDate ) ) { conditions.push(unixtimeToStr(item.param)) }
			}

			if ( item.lockType === LockType.value ) {

				if ( !this.props.wrappedToken ) { return null; }

				if ( this.props.wrappedToken.fees.length ) {

					if ( item.param.gt(this.props.wrappedToken.collectedFees) ) {

						let foundToken = undefined;
						if ( this.props.wrappedToken.fees[0].token.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
							foundToken = this.state.techToken;
						} else {
							foundToken = this.state.erc20OtherTechTokens.find((iitem) => {
								if ( !this.props.wrappedToken ) { return false; }
								return iitem.address.toLowerCase() === this.props.wrappedToken.fees[0].token.toLowerCase()
							});
							if ( !foundToken ) {
								foundToken = this.state.erc20CollateralTokens.find((iitem) => {
									if ( !this.props.wrappedToken ) { return false; }
									return iitem.address.toLowerCase() === this.props.wrappedToken.fees[0].token.toLowerCase()
								});
							}
						}
						if ( !foundToken ) { return null; }

						const diff = tokenToFloat(item.param.plus(-this.props.wrappedToken.collectedFees), foundToken.decimals || 18);
						conditions.push(`Collect fee ${addThousandSeparator(diff.toString())} ${foundToken.symbol} to unwrap`)

					}

				}
			}

		})

		if (conditions.length) {
			return (
				<div className="w-card__status">
					<div>
						<span className="small">{ 'Cannot unwrap token:' }</span>
						<span>{ conditions.join(', ') }</span>
					</div>
				</div>
			)
		}

		return (
			<Link
				className="btn btn-lg btn-yellow"
				to={`/token/${this.state.chainId}/${this.props.wrappedToken.contractAddress}/${this.props.wrappedToken.tokenId}`}
			>{ this.t('UNWRAP') }</Link>
		)
	}

	getCopiedHint(where: string) {
		if ( this.state.copiedHintWhere === where  ) {
			return (<span className="btn-action-info">{ this.t('Copied') }</span>)
		}
	}
	getCopyButton() {
		let tokenId = '';
		if ( this.props.wrappedToken ) {
			tokenId = this.props.wrappedToken.tokenId;
		} else {
			 tokenId = this.props.originalToken ? this.props.originalToken.tokenId || '' : '';
		}
		let contractAddress = '';
		if ( this.props.wrappedToken ) {
			contractAddress = this.props.wrappedToken.contractAddress;
		} else {
			contractAddress = this.props.originalToken ? this.props.originalToken.contractAddress || '' : '';
		}

		return (
			<CopyToClipboard
				text={ `${window.location.origin}/token/${this.state.chainId}/${contractAddress}/${tokenId}` }
				onCopy={() => {
					this.setState({
						copiedHintWhere:'tokenlinkimg'
					});
					clearTimeout(this.copiedHintTimer);
					this.copiedHintTimer = window.setTimeout(() => { this.setState({
						copiedHintWhere: ''
					}); }, this.copiedHintTimeout*1000);
				}}
			>
				<button className="btn-token-link">
					<img src={ icon_i_link } alt="" />
					{ this.getCopiedHint('tokenlinkimg') }
				</button>
			</CopyToClipboard>
		)
	}

	getFreezePopup() {

		if ( !this.state.freezePopup ) { return null; }
		if ( this.props.wrappedToken ) {
			return (
				<CrossingFreezePopup
					store={ this.store }
					metamaskAdapter={ this.metamaskAdapter }
					token={ this.props.wrappedToken }
					closePopup={()=>{
						this.setState({ freezePopup: false })
					}}
				/>
			)
		}
		if ( this.props.originalToken ) {
			return (
				<CrossingFreezePopup
					store={ this.store }
					metamaskAdapter={ this.metamaskAdapter }
					token={ this.props.originalToken }
					closePopup={()=>{
						this.setState({ freezePopup: false })
					}}
				/>
			)
		}
		return null;
	}

	render() {
		let tokenId = this.props.wrappedToken ? this.props.wrappedToken.tokenId : '';
		    tokenId = this.props.originalToken ? this.props.originalToken.tokenId || '' : '';
		let contractAddress = this.props.wrappedToken ? this.props.wrappedToken.contractAddress : '';
		    contractAddress = this.props.originalToken ? this.props.originalToken.contractAddress || '' : '';

		return (
			<React.Fragment>
			<div className="w-card w-card-preview" key={`${contractAddress}${tokenId}`}>
				<div className="bg">
					<div className="w-card__meta">
						{ this.getCardHeader() }
						{ this.getCardMenu() }
					</div>

					<div className="w-card__token">
						{ this.getExtra() }
						{ this.getMedia() }
					</div>

					{
						this.props.tokenType === TokenRenderType.wrapped ?
						(
							<div className="w-card__param">
								<div className="form-row">
									{ this.getCollateralBlock() }
								</div>
								{/* <div className="form-row">
									{ this.getCollectedFeeBlock() }
									{ this.getTransferFeeBlock() }
									{ this.getRoyaltyBlock() }
								</div> */}
							</div>
						) : null
					}
					{ this.getCardFooter() }
				</div>
			</div>
			{
				this.props.wrappedToken && this.state.addValuePopup ?
				(
					<AddValuePopup
						store={ this.store }
						metamaskAdapter={ this.metamaskAdapter }
						token={ this.props.wrappedToken }
						closePopup={()=>{
							this.setState({ addValuePopup: false })
						}}
					/>
				)
				: null
			}
			{
				this.state.transferPopup ?
				(
					<TransferPopup
						store={ this.store }
						metamaskAdapter={ this.metamaskAdapter }
						wrappedToken={ this.props.wrappedToken }
						originalToken={this.props.originalToken }
						history={ this.props.history }
						closePopup={()=>{
							this.setState({ transferPopup: false })
						}}
					/>
				)
				: null
			}
			{
				this.state.approvePopup ?
				(
					<ApprovePopup
						store={ this.store }
						metamaskAdapter={ this.metamaskAdapter }
						wrappedToken={ this.props.wrappedToken }
						originalToken={ this.props.originalToken }
						history={ this.props.history }
						closePopup={()=>{
							this.setState({ approvePopup: false })
						}}
					/>
				)
				: null
			}
			{ this.getFreezePopup() }
			</React.Fragment>
		)
	}
}

export default withTranslation("translations")(withRouter(TokenInList));