import React from "react"
import { Link } from "react-router-dom"
import config from '../../config.json';

export type NavLinkItem = {
	name: string,
	description: string,
	url: string,
	active: boolean,
	local: boolean,
	newTab: boolean,
	children: Array<NavLinkItem>,
}

function NavLinks() {

	const getLinkBody = (item: NavLinkItem) => {
		if ( item.description && item.description !== '' ) {
			return (
				<React.Fragment>
					<span>{ item.name }</span>
					<small>{ item.description }</small>
				</React.Fragment>
			)
		}

		return item.name
	}
	const getLink = (item: NavLinkItem, nested: boolean) => {

		const onSaft = window.location.href.includes('/saft');
		let clazz = '';
		if ( !nested ) {
			clazz = `${clazz} link`;

			if ( item.name === 'SAFT' && onSaft ) {
				clazz = `${clazz} active`;
			}
			if ( item.name === 'Wrapper' && !onSaft ) {
				clazz = `${clazz} active`;
			}

		}

		if ( item.local ) {
			return (
				<Link
					className={ clazz }
					to={ item.url }
				>
					{ getLinkBody(item) }
				</Link>
			)
		}

		if ( item.newTab ) {
			return (
				<a
					href={ item.url }
					className={ clazz }
					target="_blank"
					rel="noopener noreferrer"
				>
					{ getLinkBody(item) }
				</a>
			)
		}

		return (
			<a
				href={ item.url }
				className={ clazz }
			>
				{ getLinkBody(item) }
			</a>
		)

	}
	const getList = (item: NavLinkItem) => {

		return (
			<div className="nav-item">
				<div className="link">
					<span className="mr-1">{ getLinkBody(item) }</span>
					<svg className="arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4.94 5.72667L8 8.78L11.06 5.72667L12 6.66667L8 10.6667L4 6.66667L4.94 5.72667Z" fill="white"></path>
					</svg>
				</div>
				<div className="nav-dropdown dropdown-launchpads">
					{
						item.children.map((iitem) => {
							return (
								<div className="dropdown-item">
									{ getLink(iitem, true) }
								</div>
							)
						})
					}
				</div>
			</div>
		)

	}
	const getItem = (item: NavLinkItem) => {

		if ( localStorage.getItem('authMethod') === 'gnosis' && item.newTab ) { return null; }

		if ( item.children && item.children.length ) { return getList(item) }

		return (
			<div className="nav-item">
				{ getLink(item, false) }
			</div>
		)

	}

	const navlinks = config.navigationLinks;

	return (
		<div className="s-header__nav">
			<div className="container">
				{ navlinks.map((item) => { return getItem(item) }) }
			</div>
		</div>
	)
}

export default NavLinks;