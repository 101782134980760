import Web3         from 'web3';
import { Contract } from "web3-eth-contract";
import erc721_abi   from '../../abis/_erc721.json';
import erc1155_abi  from '../../abis/_erc1155.json';
import erc20_abi    from '../../abis/_erc20.json';
import {
	MetamaskAdapter,
	ERC20Contract,
	_AssetType,
	CollateralItem,
	Royalty,
	Rules,
	OriginalTokenType,
	Fee,
	WrapTransactionArgs,
	encodeWrapArguments,
	Lock,
	getNativeCollateral,
} from '.';

import {
	unsetLoading,
	setError,
	setInfo,
	clearInfo,
	setLoading,
	discoveredTokensAdd,
	metamaskSetChainParams,
	wrappedTokensAdd,
	_AdvancedLoadingStatus,
	AdvancedLoaderStageType,
	createAdvancedLoading,
	updateStepAdvancedLoading,
} from '../../reducers';

import {
	fetchUserTokens,
	DiscoveredToken
} from '../APIService';

import CheckerContract from './checkercontract';
import WNFTStorageContract, {
	calcTokenStats,
	fillCollateralsImages
} from './wnftstoragecontract';
import {
	checkApprovalERC721Token,
	setApprovalERC721Token,
	setApprovalERC721TokenMultisig,
	setApprovalForAllERC721Token,
	setApprovalForAllERC721TokenMultisig
} from './erc721contract';

import {
	encodeCollaterals,
	originalToWrapped,
	WrappedTokenType,
} from './_types';

import {
	checkApprovalERC1155Token,
	setApprovalERC1155Token,
	setApprovalERC1155TokenMultisig
} from './erc1155contract';

import {
	getOriginalToken
} from '../TokenFetchWrapper/tokenfetchwrapper';

import {
	fetchUserWrappedTokens
} from '../APIService/apiservice';

import { History } from 'history';
import { compactString, getABI } from '../_utils';

import BigNumber  from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type WrapperContractPropsType = {
	store                   : any,
	web3                    : Web3,
	metamaskAdapter         : MetamaskAdapter,
	contractAddress         : string,
	checkerContractAddress? : string,
	userAddress             : string,
	erc20TokenAddress       : Array<string>,
	updateNativeBalance     : Function,
	t                       : any,
	wNFTStorages            : Array<{ address: string, standart: _AssetType }>,
	createWhitelistContract : Function,
}

export type WrappedTokensStatType = {
	count          : number,
	collaterals    : Array<CollateralItem>,
};

export default class WrapperContract {

	web3                    : Web3;
	metamaskAdapter         : MetamaskAdapter;
	store                   : any;
	userAddress             : string;

	contractAddress         : string;
	contract                : Contract;
	checkerContractAddress? : string;
	checkerContract!        : CheckerContract;
	erc20Contract           : Array<ERC20Contract>;
	wNFTStorages            : Array<{ address: string, standart: _AssetType, contract?: WNFTStorageContract }>;
	erc20TokenAddress       : Array<string>;
	createWhitelistContract : Function;
	t                       : any;

	constructor(props: WrapperContractPropsType) {
		this.web3                     = props.web3;
		this.metamaskAdapter          = props.metamaskAdapter;
		this.store                    = props.store;
		this.userAddress              = props.userAddress;
		this.contractAddress          = props.contractAddress;
		this.erc20Contract            = [];
		this.checkerContractAddress   = props.checkerContractAddress;
		this.wNFTStorages             = props.wNFTStorages;
		this.erc20TokenAddress        = props.erc20TokenAddress;
		this.createWhitelistContract  = props.createWhitelistContract;
		this.t                        = props.t;

		let wrapperABI;
		try {
			wrapperABI = getABI(this.metamaskAdapter.chainId || 0, this.contractAddress, 'wrapper');
		} catch(e) {
			console.log(`Cannot load ${this.contractAddress} wrapper abi:`, e);
			throw new Error(`Cannot load wrapper abi`);
		}
		this.contract = new this.web3.eth.Contract(wrapperABI, this.contractAddress);
		console.log('wrapper', this.contract);

		this.createCheckerContract();
		this.getParams();
	}

	async getParams() {

		try {
			const maxCollaterals = parseInt(await this.contract.methods.MAX_COLLATERAL_SLOTS().call());
			this.store.dispatch(metamaskSetChainParams({ maxCollaterals }));
		} catch(e) {
			this.store.dispatch(unsetLoading());
			this.store.dispatch(setError({
				text: `Cannot connect to wrapper contract`,
				buttons: undefined,
				links: undefined
			}));
			return;
		}

		await this.addTechTokenContract({
			wrapperAddress: this.contractAddress,
			createWhitelistContract: this.createWhitelistContract,
			wrapperContract: this.contract
		});

		this.createStorageContracts();

		this.store.dispatch(unsetLoading());

		this.updateTokens();
	}
	async addTechTokenContract(params: {
		wrapperAddress: string,
		createWhitelistContract?: Function,
		wrapperContract?: Contract,
	}) {

		const foundContract = this.erc20Contract.find((item) => { return item.wrapperAddress.toLowerCase() === params.wrapperAddress.toLowerCase() });
		if ( foundContract ) { return; }

		let contractToConnect = params.wrapperContract;
		if ( !contractToConnect ) {
			let wrapperABI;
			try {
				wrapperABI = getABI(this.metamaskAdapter.chainId || 0, this.contractAddress, 'wrapper');
			} catch(e) {
				console.log(`Cannot load ${this.contractAddress} wrapper abi:`, e);
				throw new Error(`Cannot load wrapper abi`);
			}
			contractToConnect = new this.web3.eth.Contract(wrapperABI, params.wrapperAddress);
		}

		const techTokenAddress = await contractToConnect.methods.protocolTechToken().call();

		if ( params.createWhitelistContract ) {
			const whitelistAddress = await contractToConnect.methods.protocolWhiteList().call();
			if ( whitelistAddress !== '0x0000000000000000000000000000000000000000' ) {
				await params.createWhitelistContract(whitelistAddress, techTokenAddress);

				this.erc20Contract = [
					...this.erc20Contract.filter((item) => { return item.contractAddress.toLowerCase() !== techTokenAddress.toLowerCase() }),
					new ERC20Contract({
						web3             : this.web3,
						store            : this.store,
						contractAddress  : techTokenAddress,
						contractType     : 'tech_wrapper',
						userAddress      : this.userAddress,
						wrapperAddress   : params.wrapperAddress,
						whitelistContract: this.metamaskAdapter.whitelistContract,
					})
				]
			}
		}

		this.erc20Contract = [
			...this.erc20Contract.filter((item) => { return item.contractAddress.toLowerCase() !== techTokenAddress.toLowerCase() }),
			new ERC20Contract({
				web3             : this.web3,
				store            : this.store,
				contractAddress  : techTokenAddress,
				contractType     : 'tech',
				userAddress      : this.userAddress,
				wrapperAddress   : params.wrapperAddress,
				whitelistContract: this.metamaskAdapter.whitelistContract,
			})
		]
	}
	getWrapperTechTokenContract() {
		const foundContract = this.erc20Contract.find((item) => { return item.wrapperAddress.toLowerCase() === this.contractAddress.toLowerCase() });
		if ( !foundContract ) { return undefined; }

		return foundContract;
	}
	getTechTokenContract(tokenAddress: string) {
		const foundContract = this.erc20Contract.find((item) => { return item.contractAddress.toLowerCase() === tokenAddress.toLowerCase() });
		if ( !foundContract ) { return undefined; }

		return foundContract;
	}

	async createCheckerContract() {

		if ( !this.checkerContractAddress ) { console.log('No checker contract'); return; }

		try {
			this.checkerContract = new CheckerContract({
				store              : this.store,
				metamaskAdapter    : this.metamaskAdapter,
				web3               : this.web3,
				contractAddress    : this.checkerContractAddress,
				userAddress        : this.userAddress,
				t                  : this.t,
			});
		} catch(e: any) {
			this.store.dispatch(setError({
				text: e.message,
				buttons: [{
					text: 'Try again',
					clickFunc: () => { this.metamaskAdapter.connect() }
				}],
				links: undefined
			}));
		}

		return;
	}

	createStorageContracts() {
		this.wNFTStorages = this.wNFTStorages.map((item) => {
			let contract;
			try {
				contract = new WNFTStorageContract({
					store              : this.store,
					web3               : this.web3,
					metamaskAdapter    : this.metamaskAdapter,
					chainId            : this.metamaskAdapter.chainId || 0,
					userAddress        : this.userAddress,
					contractAddress    : item.address,
					contractStandart   : item.standart,
				});
			} catch(e: any) {
				console.log(`Cannot create storage contract ${item.address}:`, e);
				return item;
			}

			return {
				...item,
				contract,
			}
		});
	}
	addressIsStorage(address: string) {
		const foundStorage = this.wNFTStorages
			.filter((item) => { return item.address.toLowerCase() === address.toLowerCase() });

		if ( foundStorage.length ) { return true; }

		return false;
	}
	getStorageContract(address: string) {
		const foundStorage = this.wNFTStorages
			.filter((item) => { return !!item.contract })
			.filter((item) => { return item.address.toLowerCase() === address.toLowerCase() });

		if ( foundStorage.length ) { return foundStorage[0] }

		return undefined;
	}

	async updateTokens() {
		this.getWrappedTokens(1, _AssetType.ERC1155);
		this.getWrappedTokens(1, _AssetType.ERC721);

		this.getDiscoveredTokens(1, _AssetType.ERC721);
		this.getDiscoveredTokens(1, _AssetType.ERC1155);
	}

	processWrappedTokens(wrappedTokens: Array<WrappedTokenType>) {
		wrappedTokens.forEach(async (item) => {

			if ( !item.tokenUrl ) {

				const contract = this.getStorageContract(item.contractAddress);
				if ( contract && contract.contract ) {
					try {
						this.store.dispatch(wrappedTokensAdd( await contract.contract.getNFTTokenById(item.tokenId) ));
					} catch(e) { console.log('Cannot get token', item.contractAddress, item.tokenId); }
					return;
				}

				if ( item.assetType === _AssetType.wNFTv0 ) {
					getOriginalToken({
						metamaskAdapter: this.metamaskAdapter,
						contractAddress: item.contractAddress,
						tokenId: item.tokenId,
						t: this.t,
						userAddress: this.userAddress,
						assetType: _AssetType.ERC721,
					})
					.then((data) => {
						const token: WrappedTokenType = originalToWrapped(data);
						token.assetType = _AssetType.wNFTv0;
						token.collateral = item.collateral;

						this.store.dispatch(wrappedTokensAdd( token ));
					})
					.catch((e) => {
						console.log('Cannot fetch wNFTv0 token', item.contractAddress, item.tokenId, e)
					})
				}
			}

			const itemWithImages = await fillCollateralsImages(this.metamaskAdapter, item, this.userAddress);
			calcTokenStats(itemWithImages, this.store);
			this.store.dispatch(wrappedTokensAdd(itemWithImages));
		});
	}
	async getWrappedTokens(page: number, assetType: _AssetType) {
		const chainId = await this.web3.eth.getChainId();
		const tokensOnPage = 12;

		let wrappedTokens: Array<WrappedTokenType> | undefined = undefined;
		try {
			wrappedTokens = await fetchUserWrappedTokens( chainId, assetType, this.userAddress, page );
		} catch(e) {
			console.log('Cannot fetch wrapped tokens from api', e);
		}

		if ( wrappedTokens && wrappedTokens.length ) {

			this.processWrappedTokens(wrappedTokens);
			if ( wrappedTokens.length === tokensOnPage ) {
				this.getWrappedTokens(page + 1, assetType);
			}
			return;

		}

		if (
			page === 1 &&
			( !wrappedTokens || ( wrappedTokens && !wrappedTokens.length ) )
		) {
			console.log('Trying to fetch tokens from blockchain');

			if ( this.wNFTStorages && this.wNFTStorages.length ) {
				this.wNFTStorages.forEach((item) => {
					if ( item.standart !== assetType ) { return; }
					if ( item.contract ) { item.contract.getNFTs() }
				});
			}
		}
	}

	processDiscoveredTokens(discoveredTokens: Array<DiscoveredToken>, assetType: _AssetType) {
		discoveredTokens.forEach(async (item) => {

			if ( this.addressIsStorage(item.contract_address) ) { return; }

			// if ( decodeAssetTypeFromIndex(item.asset_type) === _AssetType.wNFTv0 ) {
			// 	getOriginalToken({
			// 		metamaskAdapter: this.metamaskAdapter,
			// 		contractAddress: item.contract_address,
			// 		tokenId: item.token_id,
			// 		t: this.t,
			// 		userAddress: this.userAddress,
			// 		assetType: _AssetType.ERC721,
			// 	})
			// 	.then((data) => {
			// 		this.store.dispatch(wrappedTokensAdd( originalToWrapped(data) ));
			// 	})
			// 	.catch((e) => {
			// 		console.log('Cannot fetch wNFTv0 token', item.contract_address, item.token_id, e)
			// 	});

			// 	return;
			// }

			getOriginalToken({
				metamaskAdapter: this.metamaskAdapter,
				contractAddress: item.contract_address,
				tokenId: item.token_id,
				t: this.t,
				userAddress: this.userAddress,
				assetType
			})
			.then((data) => {
				this.store.dispatch(discoveredTokensAdd(data));
			})
			.catch((e) => {
				console.log('Cannot fetch discovered token', item.contract_address, item.token_id, e)
			})

		});
	}
	async getDiscoveredTokens(page: number, assetType: _AssetType) {
		const chainId = await this.web3.eth.getChainId();
		const tokensOnPage = 12;

		let discoveredTokens: Array<DiscoveredToken> | undefined = undefined;

		try {
			discoveredTokens = await fetchUserTokens( chainId, assetType, this.userAddress, page );
		} catch(e) {
			console.log('Cannot fetch discovered tokens from api', e);
		}

		if ( discoveredTokens && discoveredTokens.length ) {

			this.processDiscoveredTokens(discoveredTokens, assetType);
			if ( discoveredTokens.length === tokensOnPage ) {
				this.getDiscoveredTokens(page + 1, assetType);
			}
			return;

		}

		// if (
		// 	page === 1 &&
		// 	( !discoveredTokens || ( discoveredTokens && discoveredTokens.length ) )
		// ) {
		// 	if ( this.wNFTStorages && this.wNFTStorages.length ) {
		// 		this.wNFTStorages.forEach((item) => { if ( item.contract ) { item.contract.getNFTs() } });
		// 	}
		// }
	}

	async approveERC20Collaterals(params: { collaterals: Array<CollateralItem>, addressTo?: string, advancedLoaderStage?: AdvancedLoaderStageType }) {
		const erc20CollateralsToCheck = params.collaterals.filter((item) => { return item.assetType === _AssetType.ERC20 && item.address !== '0x0000000000000000000000000000000000000000' });

		for (let idx = 0; idx < erc20CollateralsToCheck.length; idx++) {

			const item = erc20CollateralsToCheck[idx];

			const foundERC20Token = this.metamaskAdapter.getERC20Contract(item.address);
			if ( foundERC20Token && item.amount ) {

				if ( params.advancedLoaderStage ) {
					this.store.dispatch(updateStepAdvancedLoading({
						id: 'approveerc20collateral',
						sortOrder: 2,
						text: `Approving ERC-20 collateral tokens: ${foundERC20Token.erc20Params.symbol}`,
						status: _AdvancedLoadingStatus.loading,
						current: idx + 1,
						total: erc20CollateralsToCheck.length,
					}));
				} else {
					this.store.dispatch(setLoading({ msg: `${this.t('Waiting for approve')} ${foundERC20Token.erc20Params.symbol}` }));
				}

				if ( foundERC20Token.contractType === 'tech' || foundERC20Token.contractType === 'tech_wrapper' ) {
					continue;
				}
				const balance = await foundERC20Token.getBalance(params.addressTo);

				if ( balance.balance.lt(item.amount) ) {
					console.log(`Not enough ${ foundERC20Token.erc20Params.symbol }`);
					this.store.dispatch(setError({
						text: `Not enough ${ foundERC20Token.erc20Params.symbol }`,
						buttons: undefined,
						links: undefined,
					}));
					continue;
				}
				if ( balance.allowance.lt(item.amount) ) {
					console.log(`making allowance for ${foundERC20Token.erc20Params.symbol}`)
					try {
						await foundERC20Token.makeAllowance(item.amount, params.addressTo);
					} catch(e: any) {
						console.log('ERC20Collateral approves failed', e);
						this.store.dispatch(unsetLoading());
						this.store.dispatch(setError({
							text: `${this.t('Cannot approve token')} ${foundERC20Token.erc20Params.symbol}: ${e.message}`,
							buttons: undefined,
							links: undefined,
						}));
						throw e;
					}
					if ( !params.advancedLoaderStage ) { this.store.dispatch(unsetLoading()); }
				}
			}
		};

		if ( params.advancedLoaderStage ) {
			this.store.dispatch(updateStepAdvancedLoading({
				id: 'approveerc20collateral',
				sortOrder: 2,
				text: `Approving ERC-20 collateral tokens`,
				status: _AdvancedLoadingStatus.complete,
				current: erc20CollateralsToCheck.length,
				total: erc20CollateralsToCheck.length,
			}));
		}
	}
	async approveERC721Collaterals(params: { collaterals: Array<CollateralItem>, approveAll: boolean, addressTo?: string, advancedLoaderStage?: AdvancedLoaderStageType }) {
		const erc721CollateralsToCheck = params.collaterals.filter((item) => { return item.assetType === _AssetType.ERC721 && item.address !== '0x0000000000000000000000000000000000000000' });

		for (let idx = 0; idx < erc721CollateralsToCheck.length; idx++) {

			const item = erc721CollateralsToCheck[idx];

			const tokenLabel = params.approveAll ? compactString(item.address) : `${compactString(item.address)}:${item.tokenId || ''}`
			if ( params.advancedLoaderStage ) {
				this.store.dispatch(updateStepAdvancedLoading({
					id: 'approve721collateral',
					sortOrder: 3,
					text: `Approving ERC-721 collateral tokens: ${tokenLabel}`,
					status: _AdvancedLoadingStatus.loading,
					current: idx + 1,
					total: erc721CollateralsToCheck.length,
				}));
			} else {
				this.store.dispatch(setLoading({ msg: `${this.t('Waiting for approve')} ${tokenLabel}` }));
			}

			const isApproved = await checkApprovalERC721Token({
				metamaskAdapter: this.metamaskAdapter,
				contractAddress: item.address,
				tokenId: item.tokenId || '',
				userAddress: this.userAddress,
				addressTo: params.addressTo || this.contractAddress
			});

			if ( isApproved ) { continue; }

			try {
				if ( params.approveAll ) {
					console.log(`making allowance for all of ${item.address}`)
					await setApprovalForAllERC721Token(
						this.metamaskAdapter,
						item.address,
						this.userAddress,
						params.addressTo || this.contractAddress,
						this.t,
					);
				} else {
					console.log(`making allowance for ${item.address}:${item.tokenId}`)
					await setApprovalERC721Token(
						this.metamaskAdapter,
						item.address,
						item.tokenId || '',
						this.userAddress,
						params.addressTo || this.contractAddress,
						this.t,
					);
				}
			} catch(e: any) {
				console.log('ERC721Collateral approves failed', e);
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Cannot approve token')} ${item.address}:${item.tokenId}: ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				throw e;
			}
			if ( !params.advancedLoaderStage ) { this.store.dispatch(unsetLoading()); }
		};

		if ( params.advancedLoaderStage ) {
			this.store.dispatch(updateStepAdvancedLoading({
				id: 'approve721collateral',
				sortOrder: 3,
				text: `Approving ERC-721 collateral tokens`,
				status: _AdvancedLoadingStatus.complete,
				current: erc721CollateralsToCheck.length,
				total: erc721CollateralsToCheck.length,
			}));
		}
	}
	async approveERC1155Collaterals(params: { collaterals: Array<CollateralItem>, addressTo?: string, advancedLoaderStage?: AdvancedLoaderStageType }) {
		const erc1155CollateralsToCheck = params.collaterals.filter((item) => { return item.assetType === _AssetType.ERC1155 && item.address !== '0x0000000000000000000000000000000000000000' });

		for (let idx = 0; idx < erc1155CollateralsToCheck.length; idx++) {

			const item = erc1155CollateralsToCheck[idx];

			const tokenLabel = compactString(item.address);
			if ( params.advancedLoaderStage ) {
				this.store.dispatch(updateStepAdvancedLoading({
					id: 'approve1155collateral',
					sortOrder: 4,
					text: `Approving ERC-1155 collateral tokens: ${tokenLabel}`,
					status: _AdvancedLoadingStatus.loading,
					current: idx + 1,
					total: erc1155CollateralsToCheck.length,
				}));
			} else {
				this.store.dispatch(setLoading({ msg: `${this.t('Waiting for approve')} ${tokenLabel}` }));
			}

			const isApproved = await checkApprovalERC1155Token({
				metamaskAdapter: this.metamaskAdapter,
				contractAddress: item.address,
				userAddress: this.userAddress,
				addressTo: params.addressTo || this.contractAddress
			});

			if ( isApproved ) { continue; }

			try {
				console.log(`making allowance for all of ${item.address}`)
				await setApprovalERC1155Token(
					this.metamaskAdapter,
					item.address,
					this.userAddress,
					params.addressTo || this.contractAddress,
					this.t,
				)

			} catch(e: any) {
				console.log('ERC1155Collateral approves failed', e);
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Cannot approve tokens of')} ${item.address}: ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				throw e;
			}
			if ( !params.advancedLoaderStage ) { this.store.dispatch(unsetLoading()); }
		};

		if ( params.advancedLoaderStage ) {
			this.store.dispatch(updateStepAdvancedLoading({
				id: 'approve1155collateral',
				sortOrder: 4,
				text: `Approving ERC-1155 collateral tokens`,
				status: _AdvancedLoadingStatus.complete,
				current: erc1155CollateralsToCheck.length,
				total: erc1155CollateralsToCheck.length,
			}));
		}
	}
	async approveWNFTCollateralsTransferFee(params: { collaterals: Array<CollateralItem>, addressTo?: string, advancedLoaderStage?: AdvancedLoaderStageType }) {
		for (let idx = 0; idx < params.collaterals.length; idx++) {
			const item = params.collaterals[idx];

			if ( !this.addressIsStorage( item.address ) ) { continue; }

			const storageContract = this.getStorageContract(item.address);
			if ( !storageContract || !storageContract.contract || !item.tokenId ) { console.log('Cannot get storage contract'); continue; }

			console.log(`Approve transfer fee for ${item.address} ${item.tokenId}`)

			let token;
			const foundToken = this.store.getState().wrappedTokens.find((iitem: WrappedTokenType) => { return item.address.toLowerCase() === iitem.contractAddress.toLowerCase() && `${item.tokenId}` === `${iitem.tokenId}` });
			if ( foundToken ) {
				token = foundToken;
			} else {
				token = await storageContract.contract.getNFTTokenById( item.tokenId );
			}

			if ( !token.fees.length ) { continue; }

			const tokenLabel = `${compactString(item.address)}:${item.tokenId || ''}`;
			if ( params.advancedLoaderStage ) {
				this.store.dispatch(updateStepAdvancedLoading({
					id: 'approvewnftcollateralfees',
					sortOrder: 5,
					text: `Approving WNFT collateral fees: ${tokenLabel}`,
					status: _AdvancedLoadingStatus.loading,
					current: idx + 1,
					total: params.collaterals.length,
				}));
			} else {
				this.store.dispatch(setLoading({ msg: `${this.t('Waiting for approve')} ${tokenLabel} transfer fee` }));
			}

			const foundERC20Token = this.metamaskAdapter.getERC20Contract( token.fees[0].token );

			if ( foundERC20Token ) {
				// known erc20
				if ( foundERC20Token.contractType === 'tech' || foundERC20Token.contractType === 'tech_wrapper' ) { continue; }

				if ( params.advancedLoaderStage ) {
					this.store.dispatch(updateStepAdvancedLoading({
						id: 'approvewnftcollateralfees',
						sortOrder: 5,
						text: `Approving WNFT collateral fees: ${tokenLabel} (${foundERC20Token.erc20Params.symbol})`,
						status: _AdvancedLoadingStatus.loading,
						current: idx + 1,
						total: params.collaterals.length,
					}));
				}

				const balance = await foundERC20Token.getBalance(params.addressTo);

				if ( token.fees[0].value.gt(balance.balance) ) {
					this.store.dispatch(unsetLoading());
					this.store.dispatch(setError({
						text: `Not enough ${foundERC20Token.erc20Params.symbol} tokens to pay transfer fee`,
						buttons: undefined,
						links: undefined,
					}));
				}

				if ( token.fees[0].value.gt(balance.allowance) ) {
					try {
						await foundERC20Token.makeAllowance( token.fees[0].value, params.addressTo );
						if ( !params.advancedLoaderStage ) { this.store.dispatch(unsetLoading()); }
					} catch (e:any) {
						console.log(`Cannot approve transfer fee for ${item.address} ${item.tokenId}`, e)
						this.store.dispatch(unsetLoading());
						this.store.dispatch(setError({
							text: `Cannot make allowance: ${e.message}`,
							buttons: undefined,
							links: undefined,
						}));
						throw e;
					}
				}
			} else {
				// unknown erc20
				const erc20Contract = new this.web3.eth.Contract(erc20_abi as any, token.fees[0].token);
				const symbol        = await erc20Contract.methods.symbol().call();

				if ( params.advancedLoaderStage ) {
					this.store.dispatch(updateStepAdvancedLoading({
						id: 'approvewnftcollateralfees',
						sortOrder: 5,
						text: `Approving WNFT collateral fees: ${tokenLabel} (${symbol})`,
						status: _AdvancedLoadingStatus.loading,
						current: idx + 1,
						total: params.collaterals.length,
					}));
				}

				const balance       = new BigNumber(await erc20Contract.methods.balanceOf(this.userAddress).call());
				const allowance     = new BigNumber(await erc20Contract.methods.allowance(this.userAddress, params.addressTo || this.contractAddress).call());

				if ( token.fees[0].value.gt(balance) ) {
					this.store.dispatch(unsetLoading());
					this.store.dispatch(setError({
						text: `Not enough ${symbol} tokens to pay transfer fee`,
						buttons: undefined,
						links: undefined,
					}));
				}

				if ( token.fees[0].value.gt(allowance) ) {
					try {
						await erc20Contract.methods.approve(params.addressTo || this.contractAddress, token.fees[0].value).send({ from: this.userAddress });
						if ( !params.advancedLoaderStage ) { this.store.dispatch(unsetLoading()); }
					} catch (e:any) {
						console.log(`Cannot approve transfer fee for ${item.address} ${item.tokenId}`, e)
						this.store.dispatch(unsetLoading());
						this.store.dispatch(setError({
							text: `Cannot make allowance: ${e.message}`,
							buttons: undefined,
							links: undefined,
						}));
						throw e;
					}
				}
			}

		}

		if ( params.advancedLoaderStage ) {
			this.store.dispatch(updateStepAdvancedLoading({
				id: 'approvewnftcollateralfees',
				sortOrder: 5,
				text: `Approving WNFT collateral fees`,
				status: _AdvancedLoadingStatus.complete,
				current: params.collaterals.length,
				total: params.collaterals.length,
			}));
		}
	}

	// ---------- WRAP ----------
	async wrapSubmit(params: {
		originalToken         : OriginalTokenType,
		unwrapDestination     : string,
		fees                  : Array<Fee>,
		locks                 : Array<Lock>,
		royalties             : Array<Royalty>,
		rules                 : Rules,
		outType               : _AssetType,
		outBalance            : number,
		collaterals           : Array<CollateralItem>,
		wrapFor               : string,
		wrapArguments         : WrapTransactionArgs,
		wrapperContractAddress: string,
		history               : History
	}) {
		this.store.dispatch(setLoading({ msg: this.t('Waiting for wrap') }));

		const nativeCollateral = getNativeCollateral(params.collaterals);
		console.log('wrapArguments', params.wrapArguments);
		const tx = this.contract.methods.wrap(
			params.wrapArguments._inData,
			params.wrapArguments._collateral,
			params.wrapArguments._wrappFor,
		);

		// pre-send transaction check
		let errMsg = '';
		try {
			await tx.estimateGas({ from: this.userAddress, value: nativeCollateral });
		} catch(e: any) {
			try {
				console.log('Cannot wrap before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
			} catch(ignored) {}
		}
		if ( errMsg !== '' ) {
			this.store.dispatch(setError({
				text: `${this.t('Cannot wrap token')}: ${errMsg}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}

		tx
			.send({ from: this.userAddress, value: nativeCollateral })
			.then((data: any) => {
				this.store.dispatch(unsetLoading());
				this.metamaskAdapter.updateAllBalances();

				this.store.dispatch(setInfo({
					text: `${this.t('Non-Fungible Token is Successfully Wrapped')}. Contract address: ${data.events.WrappedV1.returnValues.outAssetAddress}. Token ID: ${data.events.WrappedV1.returnValues.outTokenId}`,
					 buttons: [{
						text: 'Ok',
						clickFunc: () => {
							this.store.dispatch(clearInfo());
							params.history.push('/list');
						}
					 }],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
			})
			.catch((e: any) => {
				console.log('Cannot wrap after send: ', e);

				this.store.dispatch(unsetLoading());

				let errorMsg = '';
				if ('message' in e) {
					errorMsg = e.message
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.originalError.message;
					} catch(ignored) {}
				}

				let links = undefined;
				if ('transactionHash' in e) {
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						const txHash = errorParsed.transactionHash;
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					} catch(ignored) {}
				}

				if ( !errorMsg.includes('was not mined within 50 blocks') ) {
					this.store.dispatch(setError({
						text: `${this.t('Cannot wrap token')}: ${errorMsg}`,
						buttons: undefined,
						links: links,
					}));
				}
			})
	}
	createAdvancedLoaderWrap(params: {
		originalToken    : OriginalTokenType,
		approveAll       : boolean,
		unwrapDestination: string,
		fees             : Array<Fee>,
		locks            : Array<Lock>,
		royalties        : Array<Royalty>,
		rules            : Rules,
		outType          : _AssetType,
		outBalance       : number,
		collaterals      : Array<CollateralItem>,
		wrapFor          : string,
		history          : History,
	}) {
		const loaderStages: Array<AdvancedLoaderStageType> = [{
			id: 'approvenft',
			sortOrder: 1,
			text: 'Approving original token',
			status: _AdvancedLoadingStatus.loading
		}];

		const qtyERC20Collaterals = params.collaterals.filter((item) => {
			const foundERC20Contract = this.metamaskAdapter.getERC20Contract(item.address);
			if ( foundERC20Contract && ( foundERC20Contract.contractType === 'tech' || foundERC20Contract.contractType === 'tech_wrapper' ) ) { return false }

			return item.assetType === _AssetType.ERC20
		});
		if ( qtyERC20Collaterals.length ) {
			loaderStages.push({
				id: 'approveerc20collateral',
				sortOrder: 2,
				text: 'Approving ERC-20 collateral tokens',
				status: _AdvancedLoadingStatus.queued,
				current: 0,
				total: qtyERC20Collaterals.length,
			});
		}

		const qty721Collaterals = params.collaterals.filter((item) => {
			return item.assetType === _AssetType.ERC721
		});
		if ( qty721Collaterals.length ) {
			loaderStages.push({
				id: 'approve721collateral',
				sortOrder: 3,
				text: 'Approving ERC-721 collateral tokens',
				status: _AdvancedLoadingStatus.queued,
				current: 0,
				total: qty721Collaterals.length,
			});
		}

		const qty1155Collaterals = params.collaterals.filter((item) => {
			return item.assetType === _AssetType.ERC1155
		});
		if ( qty1155Collaterals.length ) {
			loaderStages.push({
				id: 'approve1155collateral',
				sortOrder: 4,
				text: 'Approving ERC-1155 collateral tokens',
				status: _AdvancedLoadingStatus.queued,
				current: 0,
				total: qty1155Collaterals.length,
			});
		}

		const qtyWNFTCollateralFees = params.collaterals.filter((item) => {
			return this.addressIsStorage( item.address )
		});
		if ( qtyWNFTCollateralFees.length ) {
			loaderStages.push({
				id: 'approvewnftcollateralfees',
				sortOrder: 5,
				text: 'Approving WNFT collateral fees',
				status: _AdvancedLoadingStatus.queued,
				current: 0,
				total: qtyWNFTCollateralFees.length,
			});
		}

		loaderStages.push({
			id: 'wrap',
			sortOrder: 6,
			text: 'Wrapping token',
			status: _AdvancedLoadingStatus.queued
		});

		return {
			title: 'Waiting for wrap',
			stages: loaderStages
		}
	}
	async wrapToken(params: {
		originalToken    : OriginalTokenType,
		approveAll       : boolean,
		unwrapDestination: string,
		fees             : Array<Fee>,
		locks            : Array<Lock>,
		royalties        : Array<Royalty>,
		rules            : Rules,
		outType          : _AssetType,
		outBalance       : number,
		collaterals      : Array<CollateralItem>,
		wrapFor          : string,
		history          : History,
	}) {

		const advLoader = this.createAdvancedLoaderWrap(params)
		this.store.dispatch(createAdvancedLoading(advLoader));

		if ( params.originalToken.assetType === _AssetType.ERC721 ) {
			const contract721 = new this.web3.eth.Contract(erc721_abi as any, params.originalToken.contractAddress);

			if ( !params.originalToken.tokenId ) {
				console.log('No original token id');
				throw new Error('No original token id');
			}

			let isApproved = false;
			try {
				isApproved = await checkApprovalERC721Token({
					metamaskAdapter: this.metamaskAdapter,
					contract: contract721,
					tokenId: params.originalToken.tokenId,
					userAddress: this.userAddress,
					addressTo: this.contractAddress
				});
			} catch (e: any) {
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Cannot wrap token')}: ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}
			if ( !isApproved ) {
				this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

				if ( params.approveAll ) {
					try {
						await contract721.methods.setApprovalForAll(this.contractAddress, true)
							.send({ from: this.userAddress })
					} catch (e: any) {
						this.store.dispatch(unsetLoading());
						this.store.dispatch(setError({
							text: `${this.t('Cannot wrap token')}: ${e.message}`,
							buttons: undefined,
							links: undefined,
						}));
					}
				} else {
					try {
						await contract721.methods.approve(this.contractAddress, params.originalToken.tokenId)
							.send({ from: this.userAddress })
					} catch (e: any) {
						this.store.dispatch(unsetLoading());
						this.store.dispatch(setError({
							text: `${this.t('Cannot wrap token')}: ${e.message}`,
							buttons: undefined,
							links: undefined,
						}));
						return;
					}
				}
			}
		}

		if ( params.originalToken.assetType === _AssetType.ERC1155 ) {
			const contract1155 = new this.web3.eth.Contract(erc1155_abi as any, params.originalToken.contractAddress);

			if ( !params.originalToken.tokenId ) {
				console.log('No original token id');
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('No original token id')}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}

			let isApproved = false;
			try {
				isApproved = await checkApprovalERC1155Token({
					metamaskAdapter: this.metamaskAdapter,
					contract: contract1155,
					userAddress: this.userAddress,
					addressTo: this.contractAddress
				});
			} catch (e: any) {
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Cannot wrap token')}: ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}
			if ( !isApproved ) {
				this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

				try {
					await contract1155.methods.setApprovalForAll(this.contractAddress, true)
						.send({ from: this.userAddress })
				} catch (e: any) {
					this.store.dispatch(unsetLoading());
					this.store.dispatch(setError({
						text: `${this.t('Cannot approve token')}: ${e.message}`,
						buttons: undefined,
						links: undefined,
					}));
					return;
				}

			}
		}

		this.store.dispatch(updateStepAdvancedLoading({
			id: 'approvenft',
			sortOrder: 1,
			text: 'Approving original token',
			status: _AdvancedLoadingStatus.complete
		}));

		const wrapArguments: WrapTransactionArgs = encodeWrapArguments({ ...params, wrapperContractAddress: this.contractAddress });

		if ( this.checkerContract ) {
			const checkerResult = await this.checkerContract.checkWrapArgs(wrapArguments);
			console.log('checked:', checkerResult);
		}

		const advLoaderStageERC20   = advLoader.stages.find((iitem) => { return iitem.id === 'approveerc20collateral'    });
		const advLoaderStageERC721  = advLoader.stages.find((iitem) => { return iitem.id === 'approve721collateral'      });
		const advLoaderStageERC1155 = advLoader.stages.find((iitem) => { return iitem.id === 'approve1155collateral'     });
		const advLoaderStageWNFTFee = advLoader.stages.find((iitem) => { return iitem.id === 'approvewnftcollateralfees' });
		try { await this.approveERC20Collaterals(          { collaterals: params.collaterals                   , advancedLoaderStage: advLoaderStageERC20   }); } catch(e) { return; }
		try { await this.approveERC721Collaterals(         { collaterals: params.collaterals, approveAll: false, advancedLoaderStage: advLoaderStageERC721  }); } catch(e) { return; }
		try { await this.approveERC1155Collaterals(        { collaterals: params.collaterals                   , advancedLoaderStage: advLoaderStageERC1155 }); } catch(e) { return; }
		try { await this.approveWNFTCollateralsTransferFee({ collaterals: params.collaterals                   , advancedLoaderStage: advLoaderStageWNFTFee }); } catch(e) { return; }

		this.store.dispatch(updateStepAdvancedLoading({
			id: 'wrap',
			sortOrder: 6,
			text: 'Wrapping token',
			status: _AdvancedLoadingStatus.loading
		}));
		this.wrapSubmit({
			...params,
			wrapArguments,
			wrapperContractAddress: this.contractAddress,
		});
	}
	// ---------- END WRAP ----------

	// ---------- MULTISIG ----------
	async approveERC20CollateralsMultisig(params: { collaterals: Array<CollateralItem>, addressTo?: string, advancedLoaderStage?: AdvancedLoaderStageType }) {
		const erc20CollateralsToCheck = params.collaterals.filter((item) => { return item.assetType === _AssetType.ERC20 && item.address !== '0x0000000000000000000000000000000000000000' });

		for (let idx = 0; idx < erc20CollateralsToCheck.length; idx++) {

			const item = erc20CollateralsToCheck[idx];

			const foundERC20Token = this.metamaskAdapter.getERC20Contract(item.address);
			if ( foundERC20Token && item.amount ) {

				if ( params.advancedLoaderStage ) {
					this.store.dispatch(updateStepAdvancedLoading({
						id: 'approveerc20collateral',
						sortOrder: 2,
						text: `Approving ERC-20 collateral tokens: ${foundERC20Token.erc20Params.symbol}`,
						status: _AdvancedLoadingStatus.loading,
						current: idx + 1,
						total: erc20CollateralsToCheck.length,
					}));
				} else {
					this.store.dispatch(setLoading({ msg: `${this.t('Waiting for approve')} ${foundERC20Token.erc20Params.symbol}` }));
				}

				if ( foundERC20Token.contractType === 'tech' || foundERC20Token.contractType === 'tech_wrapper' ) {
					continue;
				}
				const balance = await foundERC20Token.getBalance(params.addressTo);

				if ( balance.balance.lt(item.amount) ) {
					console.log(`Not enough ${ foundERC20Token.erc20Params.symbol }`);
					this.store.dispatch(setError({
						text: `Not enough ${ foundERC20Token.erc20Params.symbol }`,
						buttons: undefined,
						links: undefined,
					}));
					continue;
				}
				if ( balance.allowance.lt(item.amount) ) {
					console.log(`making allowance for ${foundERC20Token.erc20Params.symbol}`)
					await foundERC20Token.makeAllowanceMultisig(item.amount, params.addressTo);
				}
			}
		};
	}
	async approveERC721CollateralsMultisig(params: { collaterals: Array<CollateralItem>, approveAll: boolean, addressTo?: string, advancedLoaderStage?: AdvancedLoaderStageType }) {
		const erc721CollateralsToCheck = params.collaterals.filter((item) => { return item.assetType === _AssetType.ERC721 && item.address !== '0x0000000000000000000000000000000000000000' });

		for (let idx = 0; idx < erc721CollateralsToCheck.length; idx++) {

			const item = erc721CollateralsToCheck[idx];

			const tokenLabel = params.approveAll ? compactString(item.address) : `${compactString(item.address)}:${item.tokenId || ''}`
			if ( params.advancedLoaderStage ) {
				this.store.dispatch(updateStepAdvancedLoading({
					id: 'approve721collateral',
					sortOrder: 3,
					text: `Approving ERC-721 collateral tokens: ${tokenLabel}`,
					status: _AdvancedLoadingStatus.loading,
					current: idx + 1,
					total: erc721CollateralsToCheck.length,
				}));
			} else {
				this.store.dispatch(setLoading({ msg: `${this.t('Waiting for approve')} ${tokenLabel}` }));
			}

			const isApproved = await checkApprovalERC721Token({
				metamaskAdapter: this.metamaskAdapter,
				contractAddress: item.address,
				tokenId: item.tokenId || '',
				userAddress: this.userAddress,
				addressTo: params.addressTo || this.contractAddress
			});

			if ( isApproved ) { continue; }

			try {
				if ( params.approveAll ) {
					console.log(`making allowance for all of ${item.address}`)
					await setApprovalForAllERC721TokenMultisig(
						this.metamaskAdapter,
						item.address,
						this.userAddress,
						params.addressTo || this.contractAddress,
						this.t,
					);
				} else {
					console.log(`making allowance for ${item.address}:${item.tokenId}`)
					await setApprovalERC721TokenMultisig(
						this.metamaskAdapter,
						item.address,
						item.tokenId || '',
						this.userAddress,
						params.addressTo || this.contractAddress,
						this.t,
					);
				}
			} catch(e: any) {
				console.log('ERC721Collateral approves failed', e);
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Cannot approve token')} ${item.address}:${item.tokenId}: ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				throw e;
			}
			this.store.dispatch(unsetLoading());
		};
	}
	async approveERC1155CollateralsMultisig(params: { collaterals: Array<CollateralItem>, addressTo?: string, advancedLoaderStage?: AdvancedLoaderStageType }) {
		const erc1155CollateralsToCheck = params.collaterals.filter((item) => { return item.assetType === _AssetType.ERC1155 && item.address !== '0x0000000000000000000000000000000000000000' });

		for (let idx = 0; idx < erc1155CollateralsToCheck.length; idx++) {

			const item = erc1155CollateralsToCheck[idx];

			const tokenLabel = compactString(item.address);
			if ( params.advancedLoaderStage ) {
				this.store.dispatch(updateStepAdvancedLoading({
					id: 'approve1155collateral',
					sortOrder: 4,
					text: `Approving ERC-1155 collateral tokens: ${tokenLabel}`,
					status: _AdvancedLoadingStatus.loading,
					current: idx + 1,
					total: erc1155CollateralsToCheck.length,
				}));
			} else {
				this.store.dispatch(setLoading({ msg: `${this.t('Waiting for approve')} ${tokenLabel}` }));
			}

			const isApproved = await checkApprovalERC1155Token({
				metamaskAdapter: this.metamaskAdapter,
				contractAddress: item.address,
				userAddress: this.userAddress,
				addressTo: params.addressTo || this.contractAddress
			});

			if ( isApproved ) { continue; }

			try {
				console.log(`making allowance for all of ${item.address}`)
				await setApprovalERC1155TokenMultisig(
					this.metamaskAdapter,
					item.address,
					this.userAddress,
					params.addressTo || this.contractAddress,
					this.t,
				)

			} catch(e: any) {
				console.log('ERC1155Collateral approves failed', e);
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Cannot approve tokens of')} ${item.address}: ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				throw e;
			}
			this.store.dispatch(unsetLoading());
		};

	}
	async approveWNFTCollateralsTransferFeeMultisig(params: { collaterals: Array<CollateralItem>, addressTo?: string, advancedLoaderStage?: AdvancedLoaderStageType }) {
		for (let idx = 0; idx < params.collaterals.length; idx++) {
			const item = params.collaterals[idx];

			if ( !this.addressIsStorage( item.address ) ) { continue; }

			const storageContract = this.getStorageContract(item.address);
			if ( !storageContract || !storageContract.contract || !item.tokenId ) { console.log('Cannot get storage contract'); continue; }

			console.log(`Approve transfer fee for ${item.address} ${item.tokenId}`)

			let token: WrappedTokenType;
			const foundToken = this.store.getState().wrappedTokens.find((iitem: WrappedTokenType) => { return item.address.toLowerCase() === iitem.contractAddress.toLowerCase() && `${item.tokenId}` === `${iitem.tokenId}` });
			if ( foundToken ) {
				token = foundToken;
			} else {
				token = await storageContract.contract.getNFTTokenById( item.tokenId );
			}

			if ( !token.fees.length ) { continue; }

			const tokenLabel = `${compactString(item.address)}:${item.tokenId || ''}`;
			if ( params.advancedLoaderStage ) {
				this.store.dispatch(updateStepAdvancedLoading({
					id: 'approvewnftcollateralfees',
					sortOrder: 5,
					text: `Approving WNFT collateral fees: ${tokenLabel}`,
					status: _AdvancedLoadingStatus.loading,
					current: idx + 1,
					total: params.collaterals.length,
				}));
			} else {
				this.store.dispatch(setLoading({ msg: `${this.t('Waiting for approve')} ${tokenLabel} transfer fee` }));
			}

			const foundERC20Token = this.metamaskAdapter.getERC20Contract( token.fees[0].token );

			if ( foundERC20Token ) {
				// known erc20
				if ( foundERC20Token.contractType === 'tech' || foundERC20Token.contractType === 'tech_wrapper' ) { continue; }

				if ( params.advancedLoaderStage ) {
					this.store.dispatch(updateStepAdvancedLoading({
						id: 'approvewnftcollateralfees',
						sortOrder: 5,
						text: `Approving WNFT collateral fees: ${tokenLabel} (${foundERC20Token.erc20Params.symbol})`,
						status: _AdvancedLoadingStatus.loading,
						current: idx + 1,
						total: params.collaterals.length,
					}));
				}

				const balance = await foundERC20Token.getBalance(params.addressTo);

				if ( token.fees[0].value.gt(balance.balance) ) {
					this.store.dispatch(unsetLoading());
					this.store.dispatch(setError({
						text: `Not enough ${foundERC20Token.erc20Params.symbol} tokens to pay transfer fee`,
						buttons: undefined,
						links: undefined,
					}));
				}

				if ( token.fees[0].value.gt(balance.allowance) ) {
					await foundERC20Token.makeAllowanceMultisig( token.fees[0].value, params.addressTo );
				}
			} else {
				// unknown erc20
				const erc20Contract = new this.web3.eth.Contract(erc20_abi as any, token.fees[0].token);
				const symbol        = await erc20Contract.methods.symbol().call();

				if ( params.advancedLoaderStage ) {
					this.store.dispatch(updateStepAdvancedLoading({
						id: 'approvewnftcollateralfees',
						sortOrder: 5,
						text: `Approving WNFT collateral fees: ${tokenLabel} (${symbol})`,
						status: _AdvancedLoadingStatus.loading,
						current: idx + 1,
						total: params.collaterals.length,
					}));
				}

				const balance       = new BigNumber(await erc20Contract.methods.balanceOf(this.userAddress).call());
				const allowance     = new BigNumber(await erc20Contract.methods.allowance(this.userAddress, params.addressTo || this.contractAddress).call());

				if ( token.fees[0].value.gt(balance) ) {
					this.store.dispatch(unsetLoading());
					this.store.dispatch(setError({
						text: `Not enough ${symbol} tokens to pay transfer fee`,
						buttons: undefined,
						links: undefined,
					}));
				}

				if ( token.fees[0].value.gt(allowance) ) {
					return new Promise(async (res, rej) => {
						await erc20Contract.methods.approve(params.addressTo || this.contractAddress, token.fees[0].value).send({ from: this.userAddress }, (err: any, data: any) => {
							if ( err ) { rej(err); }
							res(data);
						});
					});
				}
			}

		}
	}
	async approveOriginalTokenMultisig(
		params: {
			originalToken    : OriginalTokenType,
			approveAll       : boolean,
			unwrapDestination: string,
			fees             : Array<Fee>,
			locks            : Array<Lock>,
			royalties        : Array<Royalty>,
			rules            : Rules,
			outType          : _AssetType,
			outBalance       : number,
			collaterals      : Array<CollateralItem>,
			wrapFor          : string,
			history          : History,
		}
	): Promise<void> {

		if ( params.originalToken.assetType === _AssetType.ERC721 ) {
			const contract721 = new this.web3.eth.Contract(erc721_abi as any, params.originalToken.contractAddress);

			if ( !params.originalToken.tokenId ) {
				console.log('No original token id');
				throw new Error('No original token id');
			}

			let isApproved = false;
			try {
				isApproved = await checkApprovalERC721Token({
					metamaskAdapter: this.metamaskAdapter,
					contract: contract721,
					tokenId: params.originalToken.tokenId,
					userAddress: this.userAddress,
					addressTo: this.contractAddress
				});
			} catch (e: any) {
				console.log('Cannot check approval of token', e);
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Cannot check approval of token')}: ${e}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}
			if ( !isApproved ) {
				this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

				if ( params.approveAll ) {
					return new Promise(async (res, rej) => {
						await contract721.methods.setApprovalForAll(this.contractAddress, true)
							.send({ from: this.userAddress }, (err: any, data: any) => {
								if ( err ) { rej(err); }
								res(data);
							})
					});
				} else {
					return new Promise(async (res, rej) => {
						await contract721.methods.approve(this.contractAddress, params.originalToken.tokenId)
							.send({ from: this.userAddress }, (err: any, data: any) => {
								if ( err ) { rej(err); }
								res(data);
							})
					});
				}
			}
		}

		if ( params.originalToken.assetType === _AssetType.ERC1155 ) {
			const contract1155 = new this.web3.eth.Contract(erc1155_abi as any, params.originalToken.contractAddress);

			if ( !params.originalToken.tokenId ) {
				console.log('No original token id');
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('No original token id')}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}

			let isApproved = false;
			try {
				isApproved = await checkApprovalERC1155Token({
					metamaskAdapter: this.metamaskAdapter,
					contract: contract1155,
					userAddress: this.userAddress,
					addressTo: this.contractAddress
				});
			} catch (e: any) {
				console.log('Cannot check approval of token', e);
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Cannot check approval of token')}: ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}
			if ( !isApproved ) {
				this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

				return new Promise(async (res, rej) => {
					await contract1155.methods.setApprovalForAll(this.contractAddress, true)
						.send({ from: this.userAddress }, (err: any, data: any) => {
							if ( err ) { rej(err); }
							res(data);
						})
				});
			}
		}
	}
	async wrapSubmitMultisig(params: {
		originalToken         : OriginalTokenType,
		unwrapDestination     : string,
		fees                  : Array<Fee>,
		locks                 : Array<Lock>,
		royalties             : Array<Royalty>,
		rules                 : Rules,
		outType               : _AssetType,
		outBalance            : number,
		collaterals           : Array<CollateralItem>,
		wrapFor               : string,
		wrapArguments         : WrapTransactionArgs,
		wrapperContractAddress: string,
		history               : History
	}) {
		this.store.dispatch(setLoading({ msg: this.t('Waiting for wrap') }));

		const nativeCollateral = getNativeCollateral(params.collaterals);
		const tx = this.contract.methods.wrap(
			params.wrapArguments._inData,
			params.wrapArguments._collateral,
			params.wrapArguments._wrappFor,
		);

		return new Promise(async (res, rej) => {
			tx.send({ from: this.userAddress, value: nativeCollateral }, (err: any, data: any) => {
				if ( err ) { rej(err); }
				res(data);
			})
		});
	}
	async wrapTokenMultisig(params: {
		originalToken    : OriginalTokenType,
		approveAll       : boolean,
		unwrapDestination: string,
		fees             : Array<Fee>,
		locks            : Array<Lock>,
		royalties        : Array<Royalty>,
		rules            : Rules,
		outType          : _AssetType,
		outBalance       : number,
		collaterals      : Array<CollateralItem>,
		wrapFor          : string,
		history          : History,
	}) {

		const advLoader = this.createAdvancedLoaderWrap(params)
		this.store.dispatch(createAdvancedLoading(advLoader));

		try {
			await this.approveOriginalTokenMultisig(params);
		} catch(e: any) {
			console.log('Cannot approve original token after send: ', e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot wrap token')}: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}

		const wrapArguments: WrapTransactionArgs = encodeWrapArguments({ ...params, wrapperContractAddress: this.contractAddress });

		if ( this.checkerContract ) {
			const checkerResult = await this.checkerContract.checkWrapArgs(wrapArguments);
			console.log('checked:', checkerResult);
		}

		const advLoaderStageERC20   = advLoader.stages.find((iitem) => { return iitem.id === 'approveerc20collateral'    });
		const advLoaderStageERC721  = advLoader.stages.find((iitem) => { return iitem.id === 'approve721collateral'      });
		const advLoaderStageERC1155 = advLoader.stages.find((iitem) => { return iitem.id === 'approve1155collateral'     });
		const advLoaderStageWNFTFee = advLoader.stages.find((iitem) => { return iitem.id === 'approvewnftcollateralfees' });
		try {
			await this.approveERC20CollateralsMultisig({ collaterals: params.collaterals, advancedLoaderStage: advLoaderStageERC20   });
		} catch(e: any) {
			console.log('Cannot approve ERC20 Collaterals after send: ', e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot approve ERC20 Collaterals')}: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}
		try {
			await this.approveERC721CollateralsMultisig({ collaterals: params.collaterals, approveAll: false, advancedLoaderStage: advLoaderStageERC721  });
		} catch(e: any) {
			console.log('Cannot approve ERC721 Collaterals after send: ', e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot approve ERC721 Collaterals')}: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}
		try {
			await this.approveERC1155CollateralsMultisig({ collaterals: params.collaterals, advancedLoaderStage: advLoaderStageERC1155 });
		} catch(e: any) {
			console.log('Cannot approve ERC1155 Collaterals after send: ', e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot approve ERC1155 Collaterals')}: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}
		try {
			await this.approveWNFTCollateralsTransferFeeMultisig({ collaterals: params.collaterals, advancedLoaderStage: advLoaderStageWNFTFee });
		} catch(e: any) {
			console.log('Cannot approve WNFTCollaterals TransferFee after send: ', e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot approve WNFTCollaterals TransferFee')}: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}

		this.store.dispatch(updateStepAdvancedLoading({
			id: 'wrap',
			sortOrder: 6,
			text: 'Wrapping token',
			status: _AdvancedLoadingStatus.loading
		}));
		let wrapTx;
		try {
			wrapTx = await this.wrapSubmitMultisig({
				...params,
				wrapArguments,
				wrapperContractAddress: this.contractAddress,
			});
		} catch(e: any) {
			console.log('Cannot wrap after send: ', e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot wrap token')}: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}

		this.store.dispatch(unsetLoading());
		this.metamaskAdapter.updateAllBalances();
		this.store.dispatch(setInfo({
			text: `${this.t('All transactions has been created. Your token will appear on the dashboard after execution')}`,
			 buttons: [{
				text: 'Ok',
				clickFunc: () => {
					this.store.dispatch(clearInfo());
					params.history.push('/list');
				}
			 }],
			links: [{
				text: `View wrap tx on ${this.metamaskAdapter.chainConfig.explorerName}`,
				url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${wrapTx}`
			}]
		}));
	}
	async unwrapTokenSubmitMultisig(tx: any) {
		return new Promise(async (res, rej) => {
			await tx
				.send({ from: this.userAddress }, (err: any, data: any) => {
					if ( err ) { rej(err); }
					res(data);
				})
		});
	}
	async unwrapTokenMultisig(token: WrappedTokenType, history: History) {
		this.store.dispatch(setLoading({ msg: this.t('Waiting for unwrap') }));

		const foundStorageContract = this.getStorageContract(token.contractAddress);
		if ( !foundStorageContract ) {
			console.log('Not wrapped token');
			throw new Error('Not wrapped token');
		}

		let tx;
		if ( foundStorageContract.contract && foundStorageContract.contract.wrapperContract ) {
			console.log('Old wrappercontract found: ', foundStorageContract.contract.wrapperContract);
			let wrapperABI;
			try {
				wrapperABI = getABI(this.metamaskAdapter.chainId || 0, foundStorageContract.contract.wrapperContract);
			} catch(e) {
				console.log(`Cannot load ${foundStorageContract.contract.wrapperContract} wrapper abi:`, e);
				throw new Error(`Cannot load wrapper abi`);
			}
			const contract = new this.web3.eth.Contract(wrapperABI, foundStorageContract.contract.wrapperContract);
			tx = contract.methods.unWrap(
				token.contractAddress,
				token.tokenId,
			);
		} else {
			tx = this.contract.methods.unWrap(
				token.contractAddress,
				token.tokenId,
			);
		}

		let wrapTx;
		try {
			wrapTx = await this.unwrapTokenSubmitMultisig(tx)
		} catch(e: any) {
			console.log('Cannot unwrap after send: ', e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot unwrap token')}: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}

		this.store.dispatch(unsetLoading());
		this.metamaskAdapter.updateAllBalances();
		history.push('/list');
		this.store.dispatch(setInfo({
			text: `${this.t('Unwrap transaction has been created')}`,
			 buttons: [{
				text: 'Ok',
				clickFunc: () => {
					this.store.dispatch(clearInfo());
				}
			 }],
			links: [{
				text: `View wrap tx on ${this.metamaskAdapter.chainConfig.explorerName}`,
				url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${wrapTx}`
			}]
		}));
	}
	// ---------- END MULTISIG ----------

	// ---------- UNWRAP ----------
	async unwrapToken(token: WrappedTokenType, history: History) {
		this.store.dispatch(setLoading({ msg: this.t('Waiting for unwrap') }));

		const foundStorageContract = this.getStorageContract(token.contractAddress);
		if ( !foundStorageContract ) {
			console.log('Not wrapped token');
			throw new Error('Not wrapped token');
		}

		let tx;
		if ( foundStorageContract.contract && foundStorageContract.contract.wrapperContract ) {
			console.log('Old wrappercontract found: ', foundStorageContract.contract.wrapperContract);
			let wrapperABI;
			try {
				wrapperABI = getABI(this.metamaskAdapter.chainId || 0, foundStorageContract.contract.wrapperContract, 'wrapper');
			} catch(e) {
				console.log(`Cannot load ${foundStorageContract.contract.wrapperContract} wrapper abi:`, e);
				throw new Error(`Cannot load wrapper abi`);
			}
			const contract = new this.web3.eth.Contract(wrapperABI, foundStorageContract.contract.wrapperContract);
			tx = contract.methods.unWrap(
				token.contractAddress,
				token.tokenId,
			);
		} else {
			tx = this.contract.methods.unWrap(
				token.contractAddress,
				token.tokenId,
			);
		}

		// pre-send transaction check
		let errMsg = '';
		let estimatedGas;
		try {
			estimatedGas = await tx.estimateGas({ from: this.userAddress })
		} catch(e: any) {
			try {
				console.log('Cannot unwrap before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
			} catch(ignored) {}
		}
		if ( errMsg !== '' ) { throw new Error(errMsg); }

		estimatedGas = new BigNumber(estimatedGas).plus(new BigNumber(100000)).toString();

		tx
			.send({ from: this.userAddress, gas: estimatedGas })
			.then((data: any) => {
				history.push('/list');

				this.store.dispatch(unsetLoading());
				this.metamaskAdapter.updateAllBalances();

				this.store.dispatch(setInfo({
					text: `${this.t('wNFT Successfully Unwrapped')}`,
					 buttons: [{
						text: 'Ok',
						clickFunc: () => {
							this.store.dispatch(clearInfo());
						}
					 }],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
			})
			.catch((e: any) => {
				console.log('Cannot unwrap after send: ', e);
				// history.goBack();

				this.store.dispatch(unsetLoading());
				this.metamaskAdapter.updateAllBalances();

				let errorMsg = '';
				if ('message' in e) {
					errorMsg = e.message
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.originalError.message;
					} catch(ignored) {}
				}

				let links = undefined;
				if ('transactionHash' in e) {
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						const txHash = errorParsed.transactionHash;
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					} catch(ignored) {}
				}

				if ( !errorMsg.includes('was not mined within 50 blocks') ) {
					this.store.dispatch(unsetLoading());
					this.store.dispatch(setError({
						text: `${this.t('Cannot unwrap token')}: ${errorMsg}`,
						buttons: undefined,
						links: links,
					}));
				}
			});
	}
	// ---------- END UNWRAP ----------

	async addCollateral(params: {
		token: WrappedTokenType,
		collaterals: Array<CollateralItem>,
		callback?: Function,
	}) {

		const foundStorageContract = this.getStorageContract(params.token.contractAddress);
		if ( !foundStorageContract ) {
			console.log('Not wrapped token');
			throw new Error('Not wrapped token');
		}
		let addressTo;
		if ( foundStorageContract.contract && foundStorageContract.contract.wrapperContract ) {
			addressTo = foundStorageContract.contract.wrapperContract;
		} else {
			addressTo = this.contractAddress;
		}

		try { await this.approveERC20Collaterals(          { collaterals: params.collaterals,                    addressTo }); } catch(e) { return; }
		try { await this.approveERC721Collaterals(         { collaterals: params.collaterals, approveAll: false, addressTo }); } catch(e) { return; }
		try { await this.approveERC1155Collaterals(        { collaterals: params.collaterals,                    addressTo }); } catch(e) { return; }
		try { await this.approveWNFTCollateralsTransferFee({ collaterals: params.collaterals,                    addressTo }); } catch(e) { return; }

		this.store.dispatch(setLoading({ msg: this.t('Waiting for refill') }));
		const nativeCollateral = getNativeCollateral(params.collaterals);
		console.log('add collaterals params', {
			contractAddress: params.token.contractAddress,
			tokenId: params.token.tokenId,
			collaterals: encodeCollaterals(params.collaterals),
			nativeCollateral: nativeCollateral
		});

		let tx;
		if ( foundStorageContract.contract && foundStorageContract.contract.wrapperContract ) {
			console.log('Old wrappercontract found: ', foundStorageContract.contract.wrapperContract);
			let wrapperABI;
			try {
				wrapperABI = getABI(this.metamaskAdapter.chainId || 0, foundStorageContract.contract.wrapperContract, 'wrapper');
			} catch(e) {
				console.log(`Cannot load ${foundStorageContract.contract.wrapperContract} wrapper abi:`, e);
				throw new Error(`Cannot load wrapper abi`);
			}
			const contract = new this.web3.eth.Contract(wrapperABI, foundStorageContract.contract.wrapperContract);
			tx = contract.methods.addCollateral(
				params.token.contractAddress,
				params.token.tokenId,
				encodeCollaterals(params.collaterals)
			);
		} else {
			tx = this.contract.methods.addCollateral(
				params.token.contractAddress,
				params.token.tokenId,
				encodeCollaterals(params.collaterals)
			);
		}

		let errMsg = '';
		try {
			await tx.estimateGas({ from: this.userAddress, value: nativeCollateral.toString() })
		} catch(e: any) {
			try {
				console.log('Cannot add collateral before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
			} catch(ignored) {}
		}
		if ( errMsg !== '' ) {
			this.store.dispatch(unsetLoading());
			this.store.dispatch(setError({
				text: `${this.t('Cannot add collateral')}: ${errMsg}`,
				buttons: undefined,
				links: undefined,
			}));
		}

		tx
			.send({ from: this.userAddress, value: nativeCollateral.toString() })
			.then((data: any) => {
				this.store.dispatch(unsetLoading());
				this.metamaskAdapter.updateAllBalances();

				this.store.dispatch(setInfo({
					text: this.t('Your Collateral Successfully Refilled'),
					buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
					}],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
				if ( params.callback ) { params.callback() }
			})
			.catch((e: any) => {
				this.store.dispatch(unsetLoading());
				console.log('Cannot add collateral', e);

				let errorMsg = '';
				if ('message' in e) {
					errorMsg = e.message
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.originalError.message;
					} catch(ignored) {}
				}

				let links = undefined;
				if ('transactionHash' in e) {
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						const txHash = errorParsed.transactionHash;
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					} catch(ignored) {}
				}

				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Cannot add collateral')}: ${errorMsg}`,
					buttons: undefined,
					links: links,
				}));
			});

	}
	createAdvancedLoaderAddCollateral(params: {
		token: WrappedTokenType,
		collaterals: Array<CollateralItem>,
		callback?: Function,
	}) {
		const loaderStages: Array<AdvancedLoaderStageType> = [];

		const qtyERC20Collaterals = params.collaterals.filter((item) => {
			const foundERC20Contract = this.metamaskAdapter.getERC20Contract(item.address);
			if ( foundERC20Contract && ( foundERC20Contract.contractType === 'tech' || foundERC20Contract.contractType === 'tech_wrapper' ) ) { return false }

			return item.assetType === _AssetType.ERC20
		});
		if ( qtyERC20Collaterals.length ) {
			loaderStages.push({
				id: 'approveerc20collateral',
				sortOrder: 1,
				text: 'Approving ERC-20 collateral tokens',
				status: _AdvancedLoadingStatus.queued,
				current: 0,
				total: qtyERC20Collaterals.length,
			});
		}

		const qty721Collaterals = params.collaterals.filter((item) => {
			return item.assetType === _AssetType.ERC721
		});
		if ( qty721Collaterals.length ) {
			loaderStages.push({
				id: 'approve721collateral',
				sortOrder: 2,
				text: 'Approving ERC-721 collateral tokens',
				status: _AdvancedLoadingStatus.queued,
				current: 0,
				total: qty721Collaterals.length,
			});
		}

		const qty1155Collaterals = params.collaterals.filter((item) => {
			return item.assetType === _AssetType.ERC1155
		});
		if ( qty1155Collaterals.length ) {
			loaderStages.push({
				id: 'approve1155collateral',
				sortOrder: 3,
				text: 'Approving ERC-1155 collateral tokens',
				status: _AdvancedLoadingStatus.queued,
				current: 0,
				total: qty1155Collaterals.length,
			});
		}

		const qtyWNFTCollateralFees = params.collaterals.filter((item) => {
			return this.addressIsStorage( item.address )
		});
		if ( qtyWNFTCollateralFees.length ) {
			loaderStages.push({
				id: 'approvewnftcollateralfees',
				sortOrder: 4,
				text: 'Approving WNFT collateral fees',
				status: _AdvancedLoadingStatus.queued,
				current: 0,
				total: qtyWNFTCollateralFees.length,
			});
		}

		loaderStages.push({
			id: 'addcollateral',
			sortOrder: 5,
			text: 'Adding colalteral to token',
			status: _AdvancedLoadingStatus.queued
		});

		return {
			title: 'Waiting for add collateral',
			stages: loaderStages
		}
	}
	async addCollateralSubmitMultisig(tx: any, nativeCollateral: BigNumber) {
		return new Promise(async (res, rej) => {
			await tx
				.send({ from: this.userAddress, value: nativeCollateral.toString() }, (err: any, data: any) => {
					if ( err ) { rej(err); }
					res(data);
				})
		});
	}
	async addCollateralMultisig(params: {
		token: WrappedTokenType,
		collaterals: Array<CollateralItem>,
		callback?: Function,
	}) {

		const advLoader = this.createAdvancedLoaderAddCollateral(params)
		this.store.dispatch(createAdvancedLoading(advLoader));

		const foundStorageContract = this.getStorageContract(params.token.contractAddress);
		if ( !foundStorageContract ) {
			console.log('Not wrapped token');
			throw new Error('Not wrapped token');
		}
		let addressTo;
		if ( foundStorageContract.contract && foundStorageContract.contract.wrapperContract ) {
			addressTo = foundStorageContract.contract.wrapperContract;
		} else {
			addressTo = this.contractAddress;
		}

		const advLoaderStageERC20   = advLoader.stages.find((iitem) => { return iitem.id === 'approveerc20collateral'    });
		const advLoaderStageERC721  = advLoader.stages.find((iitem) => { return iitem.id === 'approve721collateral'      });
		const advLoaderStageERC1155 = advLoader.stages.find((iitem) => { return iitem.id === 'approve1155collateral'     });
		const advLoaderStageWNFTFee = advLoader.stages.find((iitem) => { return iitem.id === 'approvewnftcollateralfees' });
		try {
			await this.approveERC20CollateralsMultisig({ collaterals: params.collaterals, addressTo, advancedLoaderStage: advLoaderStageERC20   });
		} catch(e: any) {
			console.log('Cannot approve ERC20 Collaterals after send: ', e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot approve ERC20 Collaterals')}: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}
		try {
			await this.approveERC721CollateralsMultisig({ collaterals: params.collaterals, addressTo, approveAll: false, advancedLoaderStage: advLoaderStageERC721  });
		} catch(e: any) {
			console.log('Cannot approve ERC721 Collaterals after send: ', e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot approve ERC721 Collaterals')}: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}
		try {
			await this.approveERC1155CollateralsMultisig({ collaterals: params.collaterals, addressTo, advancedLoaderStage: advLoaderStageERC1155 });
		} catch(e: any) {
			console.log('Cannot approve ERC1155 Collaterals after send: ', e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot approve ERC1155 Collaterals')}: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}
		try {
			await this.approveWNFTCollateralsTransferFeeMultisig({ collaterals: params.collaterals, addressTo, advancedLoaderStage: advLoaderStageWNFTFee });
		} catch(e: any) {
			console.log('Cannot approve WNFTCollaterals TransferFee after send: ', e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot approve WNFTCollaterals TransferFee')}: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}

		this.store.dispatch(setLoading({ msg: this.t('Waiting for refill') }));
		const nativeCollateral = getNativeCollateral(params.collaterals);
		console.log('add collaterals params', {
			contractAddress: params.token.contractAddress,
			tokenId: params.token.tokenId,
			collaterals: encodeCollaterals(params.collaterals),
			nativeCollateral: nativeCollateral
		});

		let tx;
		if ( foundStorageContract.contract && foundStorageContract.contract.wrapperContract ) {
			console.log('Old wrappercontract found: ', foundStorageContract.contract.wrapperContract);
			let wrapperABI;
			try {
				wrapperABI = getABI(this.metamaskAdapter.chainId || 0, foundStorageContract.contract.wrapperContract);
			} catch(e) {
				console.log(`Cannot load ${foundStorageContract.contract.wrapperContract} wrapper abi:`, e);
				throw new Error(`Cannot load wrapper abi`);
			}
			const contract = new this.web3.eth.Contract(wrapperABI, foundStorageContract.contract.wrapperContract);
			tx = contract.methods.addCollateral(
				params.token.contractAddress,
				params.token.tokenId,
				encodeCollaterals(params.collaterals)
			);
		} else {
			tx = this.contract.methods.addCollateral(
				params.token.contractAddress,
				params.token.tokenId,
				encodeCollaterals(params.collaterals)
			);
		}

		let wrapTx;
		try {
			wrapTx = await this.addCollateralSubmitMultisig(tx, nativeCollateral)
		} catch(e: any) {
			console.log('Cannot add collateral after send: ', e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot add collateral token')}: ${e.message}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}

		this.store.dispatch(unsetLoading());
		this.metamaskAdapter.updateAllBalances();

		this.store.dispatch(setInfo({
			text: `${this.t('All transactions has been created')}`,
			 buttons: [{
				text: 'Ok',
				clickFunc: () => {
					this.store.dispatch(clearInfo());
				}
			 }],
			links: [{
				text: `View wrap tx on ${this.metamaskAdapter.chainConfig.explorerName}`,
				url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${wrapTx}`
			}]
		}));
		if ( params.callback ) { params.callback() }

	}
}
