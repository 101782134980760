
import React from 'react';
import {
	ERC20ContractParamsType,
	MetamaskAdapter,
	OriginalTokenType,
	transferERC721Token,
	WrappedTokenType,
	_AssetType
} from '../../models/BlockchainAdapter';

import {
	History,
} from 'history';

import { withTranslation } from "react-i18next";
import {
	clearInfo,
	setError,
	setInfo,
	setLoading,
	unsetLoading
} from '../../reducers';
import {
	transferERC1155Token,
	transferERC1155TokenMultisig
} from '../../models/BlockchainAdapter/erc1155contract';
import { getABI } from '../../models/_utils';

import BigNumber from 'bignumber.js';
import { transferERC721TokenMultisig } from '../../models/BlockchainAdapter/erc721contract';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type TransferPopupProps = {
	store          : any,
	metamaskAdapter: MetamaskAdapter,
	t              : any,
	wrappedToken   : WrappedTokenType | undefined,
	originalToken  : OriginalTokenType | undefined,
	closePopup     : Function,
	history        : History,
}
type TransferPopupState = {
	transferAddress      : string,
	amount               : string,
	techToken            : ERC20ContractParamsType,
	erc20CollateralTokens: Array<ERC20ContractParamsType>,
	erc20OtherTechTokens : Array<ERC20ContractParamsType>,
	transferAllowances   : Array<{ wrapperAddress: string, transferModelAddress: string, erc20TokenAddress: string, allowance: BigNumber }>,
	v0AppLink            : string,
	authMethod           : string,
}

class TransferPopup extends React.Component<TransferPopupProps, TransferPopupState> {

	store          : any;
	unsubscribe!   : Function;
	metamaskAdapter: MetamaskAdapter;
	t              : any;
	closePopup     : Function;
	history        : History;

	constructor(props: TransferPopupProps) {
		super(props);

		this.store           = props.store;
		this.metamaskAdapter = props.metamaskAdapter;
		this.t               = props.t;
		this.closePopup      = props.closePopup;
		this.history         = props.history;

		this.state = {
			transferAddress      : '',
			amount               : '',
			techToken            : this.store.getState().erc20TechTokenParams,
			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
			erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
			transferAllowances   : this.store.getState().transferModelAllowances,
			v0AppLink            : 'https://appv0.envelop.is',
			authMethod           : this.store.getState().metamaskAdapter.authMethod || 'metamask',
		}
	}

	componentDidMount() {

		this.unsubscribe = this.store.subscribe(() => {

			this.setState({
				techToken            : this.store.getState().erc20TechTokenParams,
				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
				erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
				transferAllowances   : this.store.getState().transferModelAllowances,
				authMethod           : this.store.getState().metamaskAdapter.authMethod || 'metamask',
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	async transferWrappedTokenSubmit() {
		if ( !this.props.wrappedToken ) { return; }

		const storage = this.metamaskAdapter.wrapperContract.getStorageContract(this.props.wrappedToken.contractAddress);
		if ( !storage || !storage.contract ) { return; }

		try {
			if ( this.state.authMethod === 'gnosis' ) {
				await storage.contract.transferTokenMultisig({
					token: this.props.wrappedToken,
					addressTo: this.state.transferAddress,
					amount: new BigNumber(this.state.amount),
					t: this.t
				});
			} else {
				await storage.contract.transferToken({
					token: this.props.wrappedToken,
					addressTo: this.state.transferAddress,
					amount: new BigNumber(this.state.amount),
					t: this.t
				});
			}
		} catch(ignored) {}

		this.props.history.push('/list');
		this.closePopup()
	}

	transferNotWrappedError(e: any) {
		console.log(e);
		this.store.dispatch(unsetLoading());

		let errorMsg = '';
		try {
			if ( 'message' in e ) {
				errorMsg = e.message;
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
			} else {
				errorMsg = `${e}`;
			}
		} catch (ignored) {
			errorMsg = `${e}`;
		}

		let links = undefined;
		try {
			if ('transactionHash' in e) {
				links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
			} else {
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					const txHash = errorParsed.transactionHash;
					if ( txHash ) {
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					}
				} catch(ignored) {}
			}
		} catch (ignored) {}

		this.store.dispatch(setError({
			text: `${this.t('Cannot wrap token')}: ${errorMsg}`,
			buttons: undefined,
			links: links,
		}));
	}
	transferNotWrappedSuccess(data: any, addressTo: string) {
		this.store.dispatch(unsetLoading());
		this.metamaskAdapter.updateAllBalances();

		this.history.push('/list');

		this.store.dispatch(setInfo({
			text: `${this.t('Your token has been transferred')} (${addressTo})`,
			 buttons: [{
				text: 'Ok',
				clickFunc: () => { this.store.dispatch(clearInfo()) }
			 }],
			links: [{
				text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
				url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
			}]
		}));
		this.closePopup();
	}
	async transferNotWrappedTokenSubmit() {
		if ( !this.props.originalToken ) { return; }
		const addressTo = this.state.transferAddress;

		this.store.dispatch(setLoading({ msg: `${this.t('Waiting for transfer')}. ${this.t('After this action you will not own this NFT')}` }));

		if ( this.props.originalToken.assetType === _AssetType.ERC1155 ) {
			if ( this.state.authMethod === 'gnosis' ) {
				transferERC1155TokenMultisig(
					this.metamaskAdapter,
					this.props.originalToken.contractAddress || '',
					this.props.originalToken.tokenId || '',
					new BigNumber(this.state.amount),
					this.store.getState().account.address,
					addressTo,
					this.t
				)
					.then((data: any) => {
						this.store.dispatch(unsetLoading());
						this.metamaskAdapter.updateAllBalances();

						this.history.push('/list');

						this.store.dispatch(setInfo({
							text: `${this.t('Transaction has been created')}`,
							buttons: [{
								text: 'Ok',
								clickFunc: () => { this.store.dispatch(clearInfo()) }
							}],
							links: [{
								text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
								url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
							}]
						}));
						this.closePopup();
					})
					.catch((e) => {
						this.transferNotWrappedError(e)
					});
			} else {
				transferERC1155Token(
					this.metamaskAdapter,
					this.props.originalToken.contractAddress || '',
					this.props.originalToken.tokenId || '',
					new BigNumber(this.state.amount),
					this.store.getState().account.address,
					addressTo,
					this.t
				)
					.then((data) => {
						this.transferNotWrappedSuccess(data, addressTo);
					})
					.catch((e) => {
						this.transferNotWrappedError(e)
					});
			}
		} else {
			if ( this.state.authMethod === 'gnosis' ) {
				transferERC721TokenMultisig(
					this.metamaskAdapter,
					this.props.originalToken.contractAddress || '',
					this.props.originalToken.tokenId || '',
					this.store.getState().account.address,
					addressTo,
					this.t
				)
					.then((data: any) => {
						this.store.dispatch(unsetLoading());
						this.metamaskAdapter.updateAllBalances();

						this.history.push('/list');

						this.store.dispatch(setInfo({
							text: `${this.t('Transaction has been created')}`,
							buttons: [{
								text: 'Ok',
								clickFunc: () => { this.store.dispatch(clearInfo()) }
							}],
							links: [{
								text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
								url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
							}]
						}));
						this.closePopup();
					})
					.catch((e) => {
						this.transferNotWrappedError(e)
					});
			} else {
				transferERC721Token(
					this.metamaskAdapter,
					this.props.originalToken.contractAddress || '',
					this.props.originalToken.tokenId || '',
					this.store.getState().account.address,
					addressTo,
					this.t
				)
					.then((data) => {
						this.transferNotWrappedSuccess(data, addressTo);
					})
					.catch((e) => {
						this.transferNotWrappedError(e)
					});
			}
		}
	}

	async transferV0WrappedTokenSubmit() {
		if ( !this.props.wrappedToken ) { return; }
		const addressTo = this.state.transferAddress;

		this.store.dispatch(setLoading({ msg: `${this.t('Waiting for transfer')}. ${this.t('After this action you will not own this NFT')}` }));

		let techToken = undefined;
		if ( this.props.wrappedToken.fees && this.props.wrappedToken.fees.length ) {
			techToken = this.metamaskAdapter.wrapperContract.getTechTokenContract(this.props.wrappedToken.fees[0].token);
		}

		if (
			this.props.wrappedToken.fees.length &&
			!techToken
		) {

			const foundToken = this.metamaskAdapter.getERC20Contract(this.props.wrappedToken.fees[0].token);
			if ( !foundToken ) { return; }

			let wNFTv0ABI;
			try {
				wNFTv0ABI = getABI(this.metamaskAdapter.chainId || 0, this.props.wrappedToken.contractAddress, 'wnftv0');
			} catch(e) {
				console.log(`Cannot load ${this.props.wrappedToken.contractAddress} storage abi:`, e);
				this.store.dispatch(setError({
					text: `${this.t('Cannot load wNFTv0 abi')}: ${this.props.wrappedToken.contractAddress}`,
					buttons: undefined,
					links: undefined,
				}));
				this.store.dispatch(unsetLoading());
				return;
			}
			const contract = new this.metamaskAdapter.web3.eth.Contract(wNFTv0ABI, this.props.wrappedToken.contractAddress);

			let transferModel = this.props.wrappedToken.contractAddress;
			try {
				transferModel = (await contract.methods.partnersTokenList(this.props.wrappedToken.fees[0].token).call()).transferFeeModel;
			} catch (ignored) {}

			const balance = await foundToken.getBalance(transferModel);

			if ( balance.balance.lt(new BigNumber(this.props.wrappedToken.fees[0].value)) ) {
				this.store.dispatch(setError({ text: `Not enough ${foundToken.erc20Params.symbol}`, buttons: undefined, links: undefined }));
				return;
			}
			if ( balance.allowance.lt(new BigNumber(this.props.wrappedToken.fees[0].value)) ) {
				this.store.dispatch(setLoading({ msg: `${this.t('Waiting for approve')} ${foundToken.erc20Params.symbol}. ${this.t('Please, give permission smart contract to spend your tokens for wNFT transfer. Push approve button')}` }));
				console.log(`making allowance for ${foundToken.erc20Params.symbol}`)
				try {
					await foundToken.makeAllowance(this.props.wrappedToken.fees[0].value, transferModel);
				} catch(e: any) {
					console.log('Transfer fee approve failed', e);
					this.store.dispatch(unsetLoading());
					this.store.dispatch(setError({
						text: `${this.t('Cannot approve token')} ${foundToken.erc20Params.symbol}: ${e.message}`,
						buttons: undefined,
						links: undefined,
					}));
					throw e;
				}
				this.store.dispatch(unsetLoading());
			}
		}

		let tx;
		this.store.dispatch(setLoading({ msg: `${this.t('Waiting for transfer')}. ${this.t('After this action you will not own this wrapped NFT')}` }));

		let wNFTv0ABI;
		try {
			wNFTv0ABI = getABI(this.metamaskAdapter.chainId || 0, this.props.wrappedToken.contractAddress, 'wnftv0');
		} catch(e) {
			console.log(`Cannot load ${this.props.wrappedToken.contractAddress} storage abi:`, e);
			this.store.dispatch(setError({
				text: `${this.t('Cannot load wNFTv0 abi')}: ${this.props.wrappedToken.contractAddress}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}
		const contract = new this.metamaskAdapter.web3.eth.Contract(wNFTv0ABI, this.props.wrappedToken.contractAddress);
		tx = contract.methods.transferFrom(
			this.metamaskAdapter.userAddress,
			addressTo,
			this.props.wrappedToken.tokenId
		)

		// pre-send transaction check
		try {
			await tx.estimateGas({ from: this.metamaskAdapter.userAddress })
		} catch(e: any) {
			try {
				this.store.dispatch(unsetLoading());

				console.log('Cannot transfer before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				const errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
				this.store.dispatch(setError({
					text: `${this.t('Cannot transfer token')}: ${errMsg}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			} catch(ignored) {}
		}

		let data;
		try {
			data = await tx.send({ from: this.metamaskAdapter.userAddress })
		} catch (e: any) {
			console.log(e);
			this.store.dispatch(unsetLoading());

			let errorMsg = '';
			try {
				if ( 'message' in e ) {
					errorMsg = e.message;
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.originalError.message;
					} catch(ignored) {}
				} else {
					errorMsg = `${e}`;
				}
			} catch (ignored) {
				errorMsg = `${e}`;
			}

			let links = undefined;
			try {
				if ('transactionHash' in e) {
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						const txHash = errorParsed.transactionHash;
						if ( txHash ) {
							links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
						}
					} catch(ignored) {}
				}
			} catch (ignored) {}

			this.store.dispatch(setError({
				text: `${this.t('Cannot wrap token')}: ${errorMsg}`,
				buttons: undefined,
				links: links,
			}));
			return;
		}
		this.store.dispatch(unsetLoading());
		this.metamaskAdapter.updateAllBalances();

		this.store.dispatch(setInfo({
			text: `${this.t('Your token has been transferred')} (${addressTo})`,
			 buttons: [{
				text: 'Ok',
				clickFunc: () => { this.store.dispatch(clearInfo()) }
			 }],
			links: [{
				text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
				url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
			}]
		}));
	}
	isTransferBtnDisabled() {
		if ( !this.metamaskAdapter.web3.utils.isAddress(this.state.transferAddress) ) { return true; }

		if ( this.props.originalToken ) {
			if ( this.props.originalToken.assetType === _AssetType.ERC1155 ) {
				if ( this.state.amount === '' ) { return true; }
				if ( this.props.originalToken.amount && this.props.originalToken.amount.lt(new BigNumber(this.state.amount)) ) { return true; }
			}
		}
		if ( this.props.wrappedToken ) {
			if ( this.props.wrappedToken.assetType === _AssetType.ERC1155 ) {
				if ( this.state.amount === '' ) { return true; }
				if ( this.props.wrappedToken.amount && this.props.wrappedToken.amount.lt(new BigNumber(this.state.amount)) ) { return true; }
			}
		}
	}
	getTransferSubmitBtn() {

		if ( this.state.transferAddress === '' ) {
			return (
				<button
					className="btn"
					type="button"
					disabled={ true }
				>{ this.t('Accept') }</button>
			)
		}

		return (
			<button
				className="btn"
				type="button"
				disabled={ this.isTransferBtnDisabled() }
				onClick={(e) => {
					if ( this.props.wrappedToken && this.props.wrappedToken.assetType === _AssetType.wNFTv0 ) {
						this.transferV0WrappedTokenSubmit();
						return;
					}

					if ( this.props.wrappedToken ) {
						this.transferWrappedTokenSubmit();
					} else {
						this.transferNotWrappedTokenSubmit();
					}
				}}
			>{ this.t('Accept') }</button>
		)


	}
	getV0Label() {
		if ( !this.props.wrappedToken || this.props.wrappedToken.assetType !== _AssetType.wNFTv0 ) { return null; }

		const tokenUrl = `${this.state.v0AppLink}/#/token?chain=${this.metamaskAdapter.chainId || 0}&contractAddress=${ this.props.wrappedToken.contractAddress }&tokenId=${ this.props.wrappedToken.tokenId }`

		return (
			<React.Fragment>
				<p className="text-orange">This NFT has been wrapped by the previous version of dApp. Some functions may not work properly with current version of dApp.<br /><a target="_blank" rel="noopener noreferrer" href={ tokenUrl } >Go to v0 app.</a></p>
			</React.Fragment>
		)
	}

	render() {
		return (
			<div className="modal">
				<div
					className="modal__inner"
					onClick={(e) => {
						e.stopPropagation();
						if ((e.target as HTMLTextAreaElement).className === 'modal__inner') {
							this.closePopup();
						}
					}}
				>
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
							<div
								className="modal__close"
								onClick={() => { this.closePopup() }}
							>
								<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
									<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
								</svg>
							</div>
							<div className="c-add">
								<div className="c-add__text">
									<div className="h2">{ this.t('Transfer token') }</div>
									<p>{ this.t('After this action you will not own this wrapped NFT') }</p>
									{ this.getV0Label() }
								</div>
								<div className="c-add__coins">
									<div className="c-add__form">

											<div className="form-row">
												<div className="col">
													<input
														className="input-control"
														type="text"
														placeholder={ this.t('Paste address') }
														value={ this.state.transferAddress }
														onChange={(e) => { this.setState({ transferAddress: e.target.value }) }}
													/>
												</div>
												{
													( this.props.originalToken && this.props.originalToken.assetType === _AssetType.ERC1155 ) ||
													( this.props.wrappedToken  && this.props.wrappedToken.assetType  === _AssetType.ERC1155 )
													? (
														<div className="col">
															<input
																className="input-control"
																type="text"
																placeholder={ 'Enter amount' }
																value={ this.state.amount }
																onChange={(e) => {
																	const value = e.target.value.replaceAll(' ', '');
																	if (
																		value === '' ||
																		isNaN(parseInt(value))
																	) {
																		this.setState({ amount: '' })
																		return;
																	}

																	this.setState({ amount: `${parseInt(value)}` })
																}}
															/>
														</div>
													) : null
												}
												<div className="col">
													{ this.getTransferSubmitBtn() }
												</div>
											</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation("translations")(TransferPopup);