
import React from 'react';
import Tippy from '@tippyjs/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
	withRouter,
	match,
	Link
} from 'react-router-dom';
import {
	History,
	Location
} from 'history';
import {
	_AssetType,
	assetTypeToString,
	ERC20ContractParamsType,
	MetamaskAdapter,
	OriginalTokenType,
} from '../../models/BlockchainAdapter';

import {
	tokenPreviewClear,
} from '../../reducers';
import {
	compactString,
} from '../../models/_utils';

import icon_cannot_load    from '../../static/pics/logo-mob.svg';
import icon_i_copy         from '../../static/pics/i-copy.svg';
import icon_i_action       from '../../static/pics/i-action.svg';
import icon_i_link         from '../../static/pics/icons/i-link.svg';
import icon_i_download     from '../../static/pics/icons/i-download.svg';
import icon_link_green     from "../../static/pics/icons/i-link-green.svg";
import icon_external_green from "../../static/pics/icons/i-external-green.svg";
import icon_i_dots_hor     from "../../static/pics/icons/i-dots-hor.svg";
import icon_send           from "../../static/pics/icons/i-send.svg";

import { withTranslation, Trans } from "react-i18next";

import TransferPopup from '../TransferPopup';
import ApprovePopup from '../ApprovePopup';

import { requestTokenURIUpdate } from '../../models/APIService/apiservice';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type TokenPreviewPageProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	token                 : OriginalTokenType;
	t                     : any,
	match                 : match;
	location              : Location,
	history               : History,
}
type TokenPreviewPageState = {
	chainId        : number,
	userAddress    : string,
	decimalsNative : number,
	iconNative     : string,
	symbolNative   : string,
	explorerBaseUrl: string,
	EIPPrefix      : string,

	techToken            : ERC20ContractParamsType,
	erc20CollateralTokens: Array<ERC20ContractParamsType>,
	erc20OtherTechTokens : Array<ERC20ContractParamsType>,
	transferAllowances   : Array<{ wrapperAddress: string, transferModelAddress: string, erc20TokenAddress: string, allowance: BigNumber }>,

	approvePopup: boolean,
	transferPopup: boolean,

	showOwnerBlock   : boolean,
	showJson         : boolean,
	copiedHintWhere  : string,
	descriptionOpened: boolean,

	menuOpened      : boolean,
	cursorOnCardMenu: boolean,
	tokensListOpened: boolean,

	tokenURIRequested: boolean,
}

class TokenPreviewPage extends React.Component<TokenPreviewPageProps, TokenPreviewPageState> {

	store            : any;
	metamaskAdapter  : MetamaskAdapter;
	unsubscribe!     : Function;
	t                : any;
	menuBlockRef     : React.RefObject<HTMLInputElement>;
	copiedHintTimer  : number;
	copiedHintTimeout: number;

	DESCRIPTION_LENGTH: number;

	constructor(props: TokenPreviewPageProps) {
		super(props);

		this.store             = props.store;
		this.metamaskAdapter   = props.metamaskAdapter;
		this.t                 = props.t;
		this.menuBlockRef      = React.createRef();
		this.copiedHintTimer   = 0;
		this.copiedHintTimeout = 2; // s

		this.DESCRIPTION_LENGTH     = 160;

		this.state = {
			chainId        : this.store.getState().metamaskAdapter.chainId,
			userAddress    : this.store.getState().account.address,
			decimalsNative : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative   : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative     : this.store.getState().metamaskAdapter.networkTokenIcon,
			explorerBaseUrl: this.store.getState().metamaskAdapter.explorerBaseUrl,
			EIPPrefix      : this.store.getState().metamaskAdapter.EIPPrefix,

			techToken            : this.store.getState().erc20TechTokenParams,
			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
			erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
			transferAllowances   : this.store.getState().transferModelAllowances,

			approvePopup: false,
			transferPopup: false,

			showOwnerBlock   : false,
			showJson         : false,
			copiedHintWhere  : '',
			descriptionOpened: false,

			menuOpened           : false,
			cursorOnCardMenu     : false,
			tokensListOpened     : false,

			tokenURIRequested    : false,
		}
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {

			this.setState({
				chainId        : this.store.getState().metamaskAdapter.chainId,
				userAddress    : this.store.getState().account.address,

				decimalsNative : this.store.getState().metamaskAdapter.networkTokenDecimals,
				symbolNative   : this.store.getState().metamaskAdapter.networkTokenTicket,
				iconNative     : this.store.getState().metamaskAdapter.networkTokenIcon,
				explorerBaseUrl: this.store.getState().metamaskAdapter.explorerBaseUrl,
				EIPPrefix        : this.store.getState().metamaskAdapter.EIPPrefix,

				techToken            : this.store.getState().erc20TechTokenParams,
				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
				erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
				transferAllowances   : this.store.getState().transferModelAllowances,
			});
		});
 	}
	componentWillUnmount() {
		this.unsubscribe();
		this.store.dispatch(tokenPreviewClear());
	}

	getBreadcrumbs() {
		return (
			<div className="breadcrumbs">
				<Link className="item-back btn btn-sm btn-gray" to="/list">
					<svg className="mr-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12 5.25H2.8725L7.065 1.0575L6 0L0 6L6 12L7.0575 10.9425L2.8725 6.75H12V5.25Z" fill="white"></path>
					</svg>
					<span>Back to the list</span>
				</Link>
			</div>
		)
	}
	getCopyLinkButton() {
		if ( !this.props.token ) { return null; }

		return (
			<div className="btn-group">
				<CopyToClipboard
					text={ `${window.location.origin}/token/${this.state.chainId}/${this.props.token.contractAddress}/${this.props.token.tokenId}` }
					onCopy={() => {
						if ( !this.props.token ) { return; }
						this.setState({
							copiedHintWhere: 'toolbarbtn'
						});
						clearTimeout(this.copiedHintTimer);
						this.copiedHintTimer = window.setTimeout(() => { this.setState({
							copiedHintWhere: ''
						}); }, this.copiedHintTimeout*1000);
					}}
				>
					<button className="btn btn-md btn-gray btn-img">
						<img src={ icon_link_green } alt="" />
					</button>
				</CopyToClipboard>
				{ this.getCopiedHint('toolbarbtn') }
			</div>
		)
	}

	getHeaderInfo() {
		return (
			<div className="col-lg-auto order-lg-1">
				<div className="wnft-header__title">
					<CopyToClipboard
						text={ this.props.token.contractAddress || '' }
						onCopy={() => {
							if ( !this.props.token ) { return; }
							this.setState({
								copiedHintWhere: 'headeraddress'
							});
							clearTimeout(this.copiedHintTimer);
							this.copiedHintTimer = window.setTimeout(() => { this.setState({
								copiedHintWhere: ''
							}); }, this.copiedHintTimeout*1000);
						}}
					>
						<button className="btn-copy">
							<Tippy
								content={ this.props.token.contractAddress }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span>
									<span className="text-muted">ADDRESS </span>
									<b>{ compactString(this.props.token.contractAddress || '') }</b>
								</span>
							</Tippy>
							<img src={icon_i_copy} alt="" />
							{ this.getCopiedHint('headeraddress') }
						</button>
					</CopyToClipboard>
					<CopyToClipboard
						text={ this.props.token.tokenId || '' }
						onCopy={() => {
							if ( !this.props.token ) { return; }
							this.setState({
								copiedHintWhere: 'headerid'
							});
							clearTimeout(this.copiedHintTimer);
							this.copiedHintTimer = window.setTimeout(() => { this.setState({
								copiedHintWhere: ''
							}); }, this.copiedHintTimeout*1000);
						}}
					>
						<button className="btn-copy">
							<Tippy
								content={ this.props.token.tokenId }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span>
									<span className="text-muted">ID </span>
									<b>{ compactString(this.props.token.tokenId || '') }</b>
								</span>
							</Tippy>
							<img src={icon_i_copy} alt="" />
							{ this.getCopiedHint('headerid') }
						</button>
					</CopyToClipboard>
					<div className="wnft-header__extra">
						<div className="wnft-header__type white">
							<span className="text">NFT</span>
						</div>
						<div className="wnft-header__type white">
							<span className="text">{assetTypeToString(this.props.token.assetType, this.state.EIPPrefix)}</span>
						</div>
					</div>
				</div>
			</div>
		)
	}

	getTokenMedia() {
		if ( this.props.token && this.props.token.image === undefined ) {
			return (
				<div className="inner">
					<div className="default">
						<img src={ icon_cannot_load } alt="" />
						<span><Trans i18nKey="Loading NON-FUNGIBLE TOKEN Preview" components={[<br />]} /></span>
					</div>
				</div>
			)
		}
		if ( this.props.token && this.props.token.image === '' ) {
			return (
				<div className="inner">
					<div className="default">
						<img src={ icon_cannot_load } alt="" />
						<span><Trans i18nKey="Cannot load NON-FUNGIBLE TOKEN Preview" components={[<br />]} /></span>
					</div>
				</div>
			)
		}

		return (
			<div className="inner">
				<video className="img" src={ this.props.token ? this.props.token.image : '' } poster={ this.props.token ? this.props.token.image : '' } autoPlay={ true } muted={ true } loop={ true } />
			</div>
		)
	}

	getCopiedHint(where: string) {
		if ( this.state.copiedHintWhere === where ) {
			return (<span className="btn-action-info">{ this.t('Copied') }</span>)
		}
	}
	getMenuCopyLinkButton() {
		if ( !this.props.token ) { return null; }

		return (
			<div className="btn-group">
				<CopyToClipboard
					text={ `${window.location.origin}/token/${this.state.chainId}/${this.props.token.contractAddress}/${this.props.token.tokenId}` }
					onCopy={() => {
						if ( !this.props.token ) { return; }
						this.setState({
							copiedHintWhere: 'menucopy'
						});
						clearTimeout(this.copiedHintTimer);
						this.copiedHintTimer = window.setTimeout(() => { this.setState({
							copiedHintWhere: ''
						}); }, this.copiedHintTimeout*1000);
					}}
				>
					<button
						className="btn btn-md btn-gray btn-img"
					>
						<img src={ icon_link_green } alt="" />
					</button>
				</CopyToClipboard>
			</div>
		)
	}
	getMenuViewButton() {
		if ( !this.props.token ) { return null; }

		return (
			<div className="btn-group">
				<a
					className="btn btn-md btn-gray btn-img"
					target="_blank" rel="noopener noreferrer"
					href={`${this.state.explorerBaseUrl}/address/${this.props.token.contractAddress}`}
				>
					<img src={ icon_external_green } alt="" />
				</a>
			</div>
		)
	}
	getMenuTransferButton() {
		if ( this.props.token.owner && this.props.token.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) { return null; }
		if ( this.props.token.amount && this.props.token.amount.lte(0)                                               ) { return null; }
		return (
			<div className="btn-group">
				<button
					className="btn btn-md btn-gray btn-img"
					onClick={() => { this.setState({ transferPopup: true }) }}
				>
					<img src={ icon_send } alt="" />
				</button>
			</div>
		)
	}
	getMenuApproveButton() {
		if ( this.props.token.owner && this.props.token.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) { return null; }
		if ( this.props.token.amount && this.props.token.amount.lte(0)                                               ) { return null; }

		return (
			<li>
				<button onClick={() => { this.setState({ approvePopup: true }) }}>
					Approve
				</button>
			</li>
		)
	}
	getMenuUpdateURIButton() {
		if ( this.state.tokenURIRequested ) { return (
			<li><button>Request sent</button></li>
		) }

		return (
			<li>
				<button
					onClick={() => {
						this.setState({ tokenURIRequested: true });
						requestTokenURIUpdate({
							chainId: this.state.chainId,
							contractAddress: this.props.token.contractAddress || '',
							tokenId: this.props.token.tokenId || '',
						})
					}}
				>
					Request token data update
				</button>
			</li>
		)
	}
	openCardMenu = () => {
		setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = (e: any) => {
				if ( !this.menuBlockRef.current ) { return; }
				if ( e.path && e.path.includes(this.menuBlockRef.current) ) { return; }
				this.closeCardMenu();
			};
		}, 100);
		this.setState({ menuOpened: true });
	}
	closeCardMenu = () => {
		setTimeout(() => {
			if ( this.state.cursorOnCardMenu ) { return; }
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = null;
			this.setState({ menuOpened: false });
		}, 100);
	}
	getTokenMenu() {
		return (
		<div className="col-lg-auto order-lg-2">
		<div className="wnft-header__actions">
		<div className="btns-group">
			{ this.getMenuTransferButton() }
			{ this.getMenuViewButton() }
			{ this.getMenuCopyLinkButton() }

			<div
				className="btn-group"
				ref={ this.menuBlockRef }
			>
				{ this.getCopiedHint('menucopy') }
				<button
					className="btn btn-md btn-gray btn-img"
					onMouseEnter={ this.openCardMenu }
					onMouseLeave={ this.closeCardMenu }
					onClick={ this.openCardMenu }
				>
					<img src={ icon_i_dots_hor } alt="" />
				</button>

				{
					this.state.menuOpened ?
					(
						<div className="btn-dropdown">
							<ul
								className="list-action"
								onMouseEnter={() => {
									this.openCardMenu();
									this.setState({ cursorOnCardMenu: true })
								}}
								onMouseLeave={() => {
									this.closeCardMenu();
									this.setState({ cursorOnCardMenu: false })
								}}
							>
								{ this.getMenuApproveButton() }
								{ this.getMenuUpdateURIButton() }
							</ul>
						</div>
					): null
				}
			</div>

		</div>
		</div>
		</div>
		)
	}

	getWrapBtn() {

		if (
			( this.props.token.owner && this.props.token.owner.toLowerCase() === this.state.userAddress.toLowerCase() ) ||
			( this.props.token.amount && this.props.token.amount.gt(0) )
		) {
			return (
				<div className="wnft-card__unwrap">
					<div className="">
						<Link
							to={`/wrap/${this.state.chainId}/${this.props.token.contractAddress}/${this.props.token.tokenId}`}
							// href={`/wrap?chainId=${this.state.chainId}&contractAddress=${this.props.token.contractAddress}&tokenId=${this.props.token.tokenId}`}
							className="btn btn-grad"
						>
							{ this.t('Wrap') }
						</Link>
					</div>
				</div>
			)
		}
	}

	getOwnerBlock() {
		if ( this.props.token.assetType === _AssetType.ERC1155 ) { return; }

		return (
			<div className="c-wrap p-0">
				<div
					className={`c-wrap__toggle ${ this.state.showOwnerBlock ? 'active' : '' }`}
					onClick={() => { this.setState({ showOwnerBlock: !this.state.showOwnerBlock }) }}
				>
					<div><b>NFT info</b></div>
				</div>
				<div className="c-wrap__dropdown border-top pt-2 pt-md-3">
					{
						this.props.token.owner ? (
							<div className="flex-nowrap row py-3">
								<div className="col-4">Owner</div>
								<Tippy
									content={ this.props.token.owner }
									appendTo={ document.getElementsByClassName("wrapper")[0] }
									trigger='mouseenter'
									interactive={ false }
									arrow={ false }
									maxWidth={ 512 }
								>
									<div className="col-8 text-right">{ compactString(this.props.token.owner) }</div>
								</Tippy>
							</div>
						) : null
					}
					{
						this.props.token.sortParams?.blockNumber ? (
							<div className="flex-nowrap row py-3">
								<div className="col-4">Date/block</div>
								<div className="col-8 text-right">
									<a target="_blank" rel="noopener noreferrer" href={`${this.state.explorerBaseUrl}/block/${this.props.token.sortParams.blockNumber}`}>
										{ this.props.token.sortParams.blockNumber }
									</a>
								</div>
							</div>
						) : null
					}

					{/* <div className="flex-nowrap row py-3">
						<div className="col-4">Transaction</div>
						<div className="col-8 text-right"> <a href="#">0xd...0d8</a></div>
					</div> */}
				</div>
			</div>
		)
	}

	toggleDescription = () => {
		this.setState({ descriptionOpened: !this.state.descriptionOpened })
	}
	getDescription() {
		if ( !this.props.token.description ) { return null; }

		if ( this.props.token.description.length < this.DESCRIPTION_LENGTH ) {
			return (
				<p>{ this.props.token.description }</p>
			)
		}

		if ( !this.state.descriptionOpened ) {
			return (
				<p>{ this.props.token.description.slice(0, this.DESCRIPTION_LENGTH) } <button className="btn-more" onClick={this.toggleDescription}>...more</button></p>
			)
		} else {
			return (
				<p>{ this.props.token.description } <button className="btn-more"onClick={this.toggleDescription}>...less</button></p>
			)
		}
	}
	getNFTInfo() {
		return (
			<div className="wnft-header__info">
				<div className="h4">NFT Info</div>
				<div className="desc">
					<div className="title">{ this.props.token.name }</div>
					{ this.getDescription() }
				</div>
				<ul className="meta">
					{
						this.props.token.amount ? (
							<li><span className="text-muted">Copies:</span> <b>{ this.props.token.amount.toString() }</b></li>
						) : null
					}
					{
						this.props.token.totalSupply ? (
							<li><span className="text-muted">Total supply:</span> <b>{ this.props.token.totalSupply.toString() }</b></li>
						) : null
					}
			</ul>
			</div>
		)
	}

	getTokenUrl() {
		if ( !this.props.token ) { return ''; }
		if ( this.props.token.tokenUrlRaw ) { return `Token URI: ${this.props.token.tokenUrlRaw}`; }
		if ( this.props.token.tokenUrl ) { return `Token URI: ${this.props.token.tokenUrl}`; }
	}
	getTokenUrlJson() {

		let jsonFormatted;
		try {
			jsonFormatted = JSON.stringify(JSON.parse(this.props.token.tokenUrlRawJSON || ''), null, 4)
		} catch(e: any) { console.log('Cannot parse json', this.props.token.tokenUrlRawJSON); }

		return (
			<div className="c-wrap">
				<div className="w-card__json">
					<div className="row align-items-center">
						<div className="col-12 col-sm mb-3 mb-sm-0">
							<CopyToClipboard
								text={ this.props.token.tokenUrlRaw || '' }
								onCopy={() => {
									if ( !this.props.token ) { return; }
									this.setState({
										copiedHintWhere: 'copyjson'
									});
									clearTimeout(this.copiedHintTimer);
									this.copiedHintTimer = window.setTimeout(() => { this.setState({
										copiedHintWhere: ''
									}); }, this.copiedHintTimeout*1000);
								}}
							>
								<button className="btn-copy">
									<span><b>Token URI</b></span>
									<img src={ icon_i_copy } alt="" />
									{ this.getCopiedHint('copyjson') }
								</button>
							</CopyToClipboard>
						</div>
						<div className="col-12 col-sm-auto">
							<div className="btn-group">
								<a
									className="btn btn-sm btn-gray"
									target="_blank" rel="noopener noreferrer"
									href={ this.props.token.tokenUrl }
								>
									<span className="mr-2">Download JSON</span>
									<img src={ icon_i_download } alt="" />
								</a>
							</div>
						</div>
					</div>
					<div className="json-code">
						<div className="scroll-dark-bg">
							<pre>
								{ this.getTokenUrl() }
								{ '\n\n' }
								{ jsonFormatted }</pre>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {

		return (
			<React.Fragment>
				<main className="s-main">
				<div className="container">

				{ this.getBreadcrumbs() }


				<div className="wnft-content">
				<div className="row">
					<div className="col-lg-8 d-lg-none">
						<div className="wnft-header">
							<div className="row justify-content-lg-between">
								{ this.getTokenMenu() }
								{ this.getHeaderInfo() }
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="wnft-card">

							{ this.getTokenMedia() }

							{ this.getWrapBtn() }

						</div>
						{ this.getOwnerBlock() }
					</div>
				<div className="col-lg-8">
				<div className="d-none d-lg-block">
				<div className="wnft-header">
					<div className="row justify-content-lg-between">
						{ this.getTokenMenu() }
						{ this.getHeaderInfo() }
					</div>
				</div>
				</div>

				{ this.getNFTInfo() }

				{ this.getTokenUrlJson() }

				</div>
				</div>
				</div>
				</div>
				</main>



			{/* <main className="s-main">
			<div className="container">
			<div className="w-card">
			<div className="bg">
				{ this.getTokenHeaderBlock() }
				<div className="w-card__token">
					<button className="btn-token-link">
						<img src={ icon_i_link } alt="" />
					</button>
					{ this.getTokenMedia() }
				</div>
				<div className="w-card__param with-padding">
					<div className="w-card__json">
						<div className="row">
							<div className="col-12 col-sm mb-3 mb-sm-0">
								<div className="name">{ this.props.token.name }</div>
								<div className="mb-1"><span className="text-muted">Type:</span> { assetTypeToString(this.props.token.assetType, this.state.EIPPrefix) }</div>
								{ this.props.token.amount ? <div className="mb-1"><span className="text-muted">Balance:</span> { this.props.token.amount.toString() }</div> : null }
								{ this.props.token.totalSupply ? <div className="mb-1"><span className="text-muted">Total supply:</span> { this.props.token.totalSupply.toString() }</div> : null }
							</div>
							<div className="col-12 col-sm-auto">
								<div className="btns-group">
									<div className="btn-group">
										<button
											className={`btn btn-sm btn-gray ${ this.state.showJson ? 'active' : '' }`}
											onClick={() => {
												this.setState({ showJson: !this.state.showJson });
											}}
										>
											{ !this.state.showJson ? 'View raw JSON' : 'Hide raw JSON' }
										</button>
									</div>
									<div className="btn-group">
										<a
											className="btn btn-sm btn-gray"
											target="_blank" rel="noopener noreferrer"
											href={ this.props.token.tokenUrl }
										>
											<img src={ icon_i_download } alt="" />
										</a>
									</div>
								</div>
							</div>
						</div>
						{ this.getJsonBlock() }
					</div>
					{ this.getWrapBtn() }
				</div>
			</div>
			</div>
			</div>
			</main> */}
			{
				this.state.transferPopup && this.props.token ?
				(
					<TransferPopup
						store={ this.store }
						metamaskAdapter={ this.metamaskAdapter }
						wrappedToken={ undefined }
						originalToken={ this.props.token }
						history={ this.props.history }
						closePopup={()=>{
							this.setState({ transferPopup: false })
						}}
					/>
				)
				: null
			}
			{
				this.state.approvePopup && this.props.token ?
				(
					<ApprovePopup
						store={ this.store }
						metamaskAdapter={ this.metamaskAdapter }
						wrappedToken={ undefined }
						originalToken={ this.props.token }
						history={ this.props.history }
						closePopup={()=>{
							this.setState({ approvePopup: false })
						}}
					/>
				)
				: null
			}
			</React.Fragment>
		)
	}
}

export default withTranslation("translations")(withRouter(TokenPreviewPage));