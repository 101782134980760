
import React               from 'react';
import Tippy               from '@tippyjs/react';
import { withTranslation } from "react-i18next";
import { compactString, tokenToFloat }    from '../../models/_utils';
import {
	CollateralItem,
	ERC20ContractParamsType,
	_AssetType,
} from '../../models/BlockchainAdapter';

import default_icon from '../../static/pics/coins/_default.svg';
import default_nft  from '../../static/pics/coins/_default_nft.svg';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type CoinViewerProps = {
	store         : any,
	t             : any,
	tokens        : Array<CollateralItem>,
	position?     : string,
	onClick?      : any,
	onMouseEnter? : any,
	onMouseLeave? : any,
}
type CoinViewerState = {
	position             : string | undefined,
	erc20CollateralTokens: Array<ERC20ContractParamsType>,
	erc20OtherTechTokens : Array<ERC20ContractParamsType>,
	EIPPrefix        : string,
	decimalsNative   : number,
	symbolNative     : string,
	iconNative       : string,
	techToken        : ERC20ContractParamsType,
	explorerBaseUrl  : string,
}

class CoinViewer extends React.Component<CoinViewerProps, CoinViewerState> {

	store         : any;
	unsubscribe!  : Function;
	t             : any;

	onClick?      : any;
	onMouseEnter? : any;
	onMouseLeave? : any;

	constructor(props: CoinViewerProps) {
		super(props);

		this.store        = props.store;
		this.t            = props.t;

		this.onClick      = props.onClick;
		this.onMouseEnter = props.onMouseEnter;
		this.onMouseLeave = props.onMouseLeave;

		const explorerBaseUrl       = this.store.getState().metamaskAdapter.explorerBaseUrl;
		const decimalsNative        = this.store.getState().metamaskAdapter.networkTokenDecimals;
		const symbolNative          = this.store.getState().metamaskAdapter.networkTokenTicket;
		const iconNative            = this.store.getState().metamaskAdapter.networkTokenIcon;
		const techToken             = this.store.getState().erc20TechTokenParams;
		const erc20CollateralTokens = this.store.getState().erc20CollateralTokens;
		const erc20OtherTechTokens  = this.store.getState().erc20OtherTechTokens;
		const EIPPrefix             = this.store.getState().metamaskAdapter.EIPPrefix;

		this.state = {
			position : props.position,
			EIPPrefix,
			explorerBaseUrl,
			decimalsNative,
			symbolNative,
			iconNative,
			erc20CollateralTokens,
			erc20OtherTechTokens,
			techToken,
		}
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {

			const explorerBaseUrl       = this.store.getState().metamaskAdapter.explorerBaseUrl;
			const decimalsNative        = this.store.getState().metamaskAdapter.networkTokenDecimals;
			const symbolNative          = this.store.getState().metamaskAdapter.networkTokenTicket;
			const iconNative            = this.store.getState().metamaskAdapter.networkTokenIcon;
			const techToken             = this.store.getState().erc20TechTokenParams;
			const erc20CollateralTokens = this.store.getState().erc20CollateralTokens;
			const erc20OtherTechTokens  = this.store.getState().erc20OtherTechTokens;
			const EIPPrefix             = this.store.getState().metamaskAdapter.EIPPrefix;

			this.setState({
				EIPPrefix,
				explorerBaseUrl,

				decimalsNative,
				symbolNative,
				iconNative,

				erc20CollateralTokens,
				erc20OtherTechTokens,
				techToken,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	getCollateralItem(item: CollateralItem) {

		// native token
		if ( item.assetType === _AssetType.native && item.amount ) {
			return (
				<tr key={ 'native' }>
					<td>
						<span className="unit-sum">
							{ tokenToFloat(item.amount, this.state.decimalsNative).toString() }
						</span>
					</td>
					<td>
						<span className="field-unit">
							<span className="i-coin">
								<img src={ this.state.iconNative || default_icon } alt="" />
							</span>
							{ this.state.symbolNative }
						</span>
					</td>
				</tr>
			)
		}

		if ( item.assetType === _AssetType.ERC20 && item.amount ) {
			// tech token
			if ( item.address.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
				return (
					<tr key={ 'tech' }>
						<td>
							<span className="unit-sum">
								{ tokenToFloat(item.amount, this.state.techToken.decimals || 18).toString() }
							</span>
						</td>
						<td>
							<span className="field-unit">
								<span className="i-coin">
									<img src={ this.state.techToken.icon || default_icon } alt="" />
								</span>
								<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${this.state.techToken.address}` }>
									{ this.state.techToken.symbol }
								</a>
							</span>
						</td>
					</tr>
				)
			}

			// Tech ERC20
			const foundTech = this.state.erc20OtherTechTokens.filter((iitem: ERC20ContractParamsType) => {
				if ( !iitem.address ) { return false; }
				return item.address.toLowerCase() === iitem.address.toLowerCase()
			});
			if ( foundTech.length ) {
				// known tech
				return (
					<tr key={ item.address }>
						<td>
							<span className="unit-sum">
								{ tokenToFloat(item.amount, foundTech[0].decimals || 18).toString() }
							</span>
						</td>
						<td>
							<span className="field-unit">
								<span className="i-coin">
									<img src={ foundTech[0].icon || default_icon } alt="" />
								</span>
								<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${foundTech[0].address}` }>
									{ foundTech[0].symbol }
								</a>
							</span>
						</td>
					</tr>
				)
			}

			// Common ERC20
			const foundERC20 = this.state.erc20CollateralTokens.filter((iitem: ERC20ContractParamsType) => {
				if ( !iitem.address ) { return false; }
				return item.address.toLowerCase() === iitem.address.toLowerCase()
			});
			if ( foundERC20.length ) {
				// known ERC20
				return (
					<tr key={ item.address }>
						<td>
							<span className="unit-sum">
								{ tokenToFloat(item.amount, foundERC20[0].decimals || 18).toString() }
							</span>
						</td>
						<td>
							<span className="field-unit">
								<span className="i-coin">
									<img src={ foundERC20[0].icon || default_icon } alt="" />
								</span>
								<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${foundERC20[0].address}` }>
									{ foundERC20[0].symbol }
								</a>
							</span>
						</td>
					</tr>
				)
			} else {
				// unknown ERC20
				return (
					<tr key={ item.address }>
						<td>
							<span className="unit-sum">
								<Tippy
									content={ this.t('decimals is unknown; amount shown in wei') }
									appendTo={ document.getElementsByClassName("wrapper")[0] }
									trigger='mouseenter'
									interactive={ false }
									arrow={ false }
									maxWidth={ 260 }
								>
									<span className="unit-sum">
										{ item.amount.toString() }*
									</span>
								</Tippy>
							</span>
						</td>
						<td>
							<span className="field-unit">
								<span className="i-coin">
									<img src={ default_icon } alt="" />
								</span>
								<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${item.address}` }>
									{ compactString(item.address) }
								</a>
							</span>
						</td>
					</tr>
				)
			}
		}

		if ( item.assetType === _AssetType.ERC721 ) {
			return (
				<tr key={ `${item.address}${item.tokenId}` }>
					<td>
						<span className="unit-sum">
							<span className="unit-sum">
								{ item.amount && !item.amount.eq(0) ? item.amount.toString() : '1' } { ' ' } ({ this.state.EIPPrefix }-721)
							</span>
						</span>
					</td>
					<td>
						<span className="field-unit">
							<span className="i-coin">
								<img src={ item.tokenImg || default_nft } alt="" />
							</span>
							<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${item.address}/${item.tokenId || ''}` }>
								{ compactString(item.address) }
							</a>
						</span>
					</td>
				</tr>
			)
		}

		if ( item.assetType === _AssetType.ERC1155 ) {
			return (
				<tr key={ `${item.address}${item.tokenId}` }>
					<td>
						<span className="unit-sum">
							<span className="unit-sum">
								{ item.amount && !item.amount.eq(0) ? item.amount.toString() : '1' } { ' ' } ({ this.state.EIPPrefix }-1155)
							</span>
						</span>
					</td>
					<td>
						<span className="field-unit">
							<span className="i-coin">
								<img src={ item.tokenImg || default_nft } alt="" />
							</span>
							<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${item.address}/${item.tokenId || ''}` }>
								{ compactString(item.address) }
							</a>
						</span>
					</td>
				</tr>
			)
		}

		return null;
	}

	render() {

		return (
			<div
				className    ={ `field-collateral__details ${ this.state.position || '' }` }
				onClick      = { this.onClick }
				onMouseEnter = { this.onMouseEnter }
				onMouseLeave = { this.onMouseLeave }
			>
			<div className="inner">
				<table>
					<tbody>
						{
							this.props.tokens
								.sort((item, prev) => {
									if ( item.assetType < prev.assetType ) { return -1 }
									if ( item.assetType > prev.assetType ) { return  1 }

									if ( item.address.toLowerCase() < prev.address.toLowerCase() ) { return -1 }
									if ( item.address.toLowerCase() > prev.address.toLowerCase() ) { return  1 }

									if ( item.tokenId && prev.tokenId ) {
										try {
											if ( new BigNumber(item.tokenId).isNaN() || new BigNumber(prev.tokenId).isNaN() ) {
												if ( parseInt(`${item.tokenId}`) < parseInt(`${prev.tokenId}`) ) { return -1 }
												if ( parseInt(`${item.tokenId}`) > parseInt(`${prev.tokenId}`) ) { return  1 }
											}
											const itemTokenIdNumber = new BigNumber(item.tokenId);
											const prevTokenIdNumber = new BigNumber(prev.tokenId);

											if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return -1 }
											if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return  1 }
										} catch ( ignored ) {
											if ( `${item.tokenId}`.toLowerCase() < `${prev.tokenId}`.toLowerCase() ) { return -1 }
											if ( `${item.tokenId}`.toLowerCase() > `${prev.tokenId}`.toLowerCase() ) { return  1 }
										}
									}

									return 0
								})
								.map((item) => { return this.getCollateralItem(item) })
						}
					</tbody>
				</table>
			</div>
			</div>
		)
	}
}

export default withTranslation("translations")(CoinViewer);