
import { MetamaskAdapter }  from '.';
import erc721_abi           from '../../abis/_erc721.json';
import {
	OriginalTokenType,
	_AssetType
} from './_types';
import { Contract }         from 'web3-eth-contract';
import {
	getABI,
	localStorageGet,
	localStorageSet,
	processSwarmUrl
} from '../_utils';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

export const saveERC721Token = (token: OriginalTokenType): void => {
	const saved = localStorageGet('tokensIncomplete');
	if ( saved ) {
		const savedArr: Array<OriginalTokenType> = JSON.parse(saved);
		const filtered = savedArr.filter((item: OriginalTokenType) => {
			if ( !token.contractAddress || !token.tokenId ) { return false; }
			if ( !item.contractAddress  || !item.tokenId  ) { return false; }
			return !( item.contractAddress.toLowerCase() === token.contractAddress.toLowerCase() && `${item.tokenId}`.toLowerCase() === `${token.tokenId}`.toLowerCase() )
		});
		localStorageSet('tokensIncomplete', JSON.stringify([...filtered, { ...token }]));
	} else {
		localStorageSet('tokensIncomplete', JSON.stringify([token]));
	}
}
export const removeERC721Token = (token: OriginalTokenType, chainId: number): void => {
	const saved = localStorageGet('tokensIncomplete');
	if ( !saved ) { return; }

	const savedArr: Array<OriginalTokenType> = JSON.parse(saved);
	const clearedArr = savedArr
		.filter((item: OriginalTokenType) => { return item.chainId === chainId })
		.filter((item: OriginalTokenType) => {
			if ( !token.contractAddress || !token.tokenId ) { return false; }
			if ( !item.contractAddress  || !item.tokenId  ) { return false; }
			return item.contractAddress.toLowerCase() !== token.contractAddress.toLowerCase() || `${item.tokenId}`.toLowerCase() !== `${token.tokenId}`.toLowerCase()
		});
	localStorageSet('tokensIncomplete', JSON.stringify(clearedArr));
}
export const loadERC721Token = (metamaskAdapter: MetamaskAdapter, tokenId: string, chainId: number, userAddress: string): OriginalTokenType | null => {
	const saved = localStorageGet('tokensIncomplete');
	if ( saved ) {
		const savedArr: Array<OriginalTokenType> = JSON.parse(saved);
		const foundToken = savedArr
			.filter((item: OriginalTokenType) => { return item.owner   === userAddress })
			.filter((item: OriginalTokenType) => { return item.chainId === chainId })
			.filter((item: OriginalTokenType) => { return item.tokenId === tokenId })
		if ( foundToken.length ) { return foundToken[0] }
	}
	return null;
}
export const loadERC721TokenAll = (metamaskAdapter: MetamaskAdapter, chainId: number, userAddress: string): Array<OriginalTokenType> => {
	const saved = localStorageGet('tokensIncomplete');
	if ( saved ) {
		const found: Array<OriginalTokenType> = JSON.parse(saved);
		const foundTokens = found
			.filter((item: OriginalTokenType) => { return item.chainId === chainId })
			.filter((item: OriginalTokenType) => { return item.owner   === userAddress });
		return foundTokens;
	}
	return [];
}
export const getERC721Token = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	tokenId: string,
	t: any | undefined,
	sortParams: {
		blockNumber: BigNumber | undefined,
		logIndex: BigNumber | undefined
	}
): Promise<OriginalTokenType> => {

	if ( !contractAddress || !tokenId ) {
		const msg = t ? t('Empty params') : 'Empty params';
		throw new Error( msg );
	}

	if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) {
		const msg = t ? t('Bad address') : 'Bad address';
		throw new Error( msg );
	}

	const chainId  = await metamaskAdapter.web3.eth.getChainId();

	// if ( contractAddress === metamaskAdapter.wrapperContract.contractAddress ) {
	// 	return metamaskAdapter.wrapperContract.getWrappedTokenById(
	// 		contractAddress,
	// 		metamaskAdapter.wrapperContract.contract,
	// 		tokenId,
	// 		chainId
	// 	)
	// }

	let tokenUrl;
	let tokenUrlRaw;
	let tokenUrlRawJSON = '';
	let token;
	let tokenParsed;
	let owner;
	let name;
	let description;
	let image;
	let imageRaw;
	try {
		const contract = new metamaskAdapter.web3.eth.Contract(erc721_abi as any, contractAddress);

		owner = await contract?.methods.ownerOf(tokenId).call();
		// if ( owner.toLowerCase() !== userAddress.toLowerCase() ) { return null; }

		tokenUrlRaw = await contract?.methods.tokenURI(tokenId).call();
		tokenUrl = processSwarmUrl(tokenUrlRaw);
	} catch(e: any) {
		console.log('Cannot get erc721 token: ', e)

		let errorMsg = '';
		try {
			const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
			errorMsg = errorParsed.originalError.message;
		} catch(ignored) {}

		if ( errorMsg === '' ) {
			const msg = t ? t('Cannot connect to contract') : 'Cannot connect to contract';
			throw new Error( msg )
		} else {
			throw new Error(errorMsg)
		}
	}

	try {
		token           = await fetch(tokenUrl);
		tokenUrlRawJSON = await token.text();
		tokenParsed     = JSON.parse(tokenUrlRawJSON);
		name            = tokenParsed.name        || '';
		description     = tokenParsed.description || '';
		image           = '';
		imageRaw        = '';
		if ( tokenParsed.image     ) { image = processSwarmUrl(tokenParsed.image    ); imageRaw = tokenParsed.image    ; }
		if ( tokenParsed.image_url ) { image = processSwarmUrl(tokenParsed.image_url); imageRaw = tokenParsed.image_url; }
	} catch(e) {
		console.log('Cannot fetch token data', token);
		name        = '';
		description = '';
		image       = '';
	}

	return {
		owner,
		chainId,
		contractAddress,
		tokenId,
		tokenUrlRawJSON,
		tokenUrl,
		tokenUrlRaw,
		name,
		description,
		image,
		imageRaw,
		assetType: _AssetType.ERC721,
		sortParams,
	}
}
export const transferERC721Token = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	tokenId: string,
	userAddressFrom: string,
	addressTo: string,
	t: any
) => {

	if ( !contractAddress || !tokenId                           ) { throw new Error(t('Empty params')); }
	if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { throw new Error(t('Bad contract address')); }
	if ( !metamaskAdapter.web3.utils.isAddress(addressTo)       ) { throw new Error(t('Bad recipient address')); }

	const contract = new metamaskAdapter.web3.eth.Contract(erc721_abi as any, contractAddress);

	const tx = contract.methods.transferFrom(
		userAddressFrom,
		addressTo,
		tokenId
	);

	// pre-send transaction check
	try {
		await tx.estimateGas({ from: userAddressFrom })
	} catch(e: any) {
		let errorMsg = '';
		try {
				errorMsg = e.message;
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
		} catch(ignored) {
			errorMsg = e;
		}
		throw errorMsg;
	}

	return tx.send({ from: userAddressFrom })

}
export const transferERC721TokenMultisig = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	tokenId: string,
	userAddressFrom: string,
	addressTo: string,
	t: any
) => {

	return new Promise((res, rej) => {
		if ( !contractAddress || !tokenId                           ) { rej(new Error(t('Empty params'))); }
		if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { rej(new Error(t('Bad contract address'))); }
		if ( !metamaskAdapter.web3.utils.isAddress(addressTo)       ) { rej(new Error(t('Bad recipient address'))); }

		const contract = new metamaskAdapter.web3.eth.Contract(erc721_abi as any, contractAddress);

		const tx = contract.methods.transferFrom(
			userAddressFrom,
			addressTo,
			tokenId
		);

		tx.send({ from: userAddressFrom }, (err: any, data: any) => {
			if ( err ) { rej(err); }
			res(data);
		});
	});
}

export const checkApprovalERC721Token = async (params: {
	metamaskAdapter?: MetamaskAdapter,
	contract?: Contract,
	contractAddress?: string,
	tokenId?: string,
	userAddress: string,
	addressTo: string,
}) => {

	let contractToCall = params.contract;
	if ( !contractToCall ) {
		if ( params.metamaskAdapter ) {
			contractToCall = new params.metamaskAdapter.web3.eth.Contract(erc721_abi as any, params.contractAddress);
		} else {
			console.log('Cannot create contract');
			return;
		}
	}

	if ( params.tokenId ) {
		const approvedAddress = await contractToCall.methods.getApproved(params.tokenId).call();
		if ( approvedAddress.toLowerCase() === params.addressTo.toLowerCase() ) { return true; }
	}

	return await contractToCall.methods.isApprovedForAll(params.userAddress, params.addressTo).call();

}
export const setApprovalForAllERC721Token = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	userAddressFrom: string,
	addressTo: string,
	t: any
) => {

	if ( !contractAddress ) { throw new Error(t('Empty params')); }
	if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { throw new Error(t('Bad contract address')); }
	if ( !metamaskAdapter.web3.utils.isAddress(addressTo) ) { throw new Error(t('Bad aprroval address')); }

	const contract = new metamaskAdapter.web3.eth.Contract(erc721_abi as any, contractAddress);

	const tx = contract.methods.setApprovalForAll(
		addressTo,
		true
	);

	// pre-send transaction check
	try {
		await tx.estimateGas({ from: userAddressFrom })
	} catch(e: any) {
		let errorMsg = '';
		try {
				errorMsg = e.message;
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
		} catch(ignored) {
			errorMsg = e;
		}
		throw errorMsg;
	}

	return tx.send({ from: userAddressFrom });
}
export const setApprovalERC721Token = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	tokenId: string,
	userAddressFrom: string,
	addressTo: string,
	t?: any
) => {

	if ( !contractAddress ) { throw new Error(t('Empty params')); }
	if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { throw new Error(t('Bad contract address')); }
	if ( !metamaskAdapter.web3.utils.isAddress(addressTo) ) { throw new Error(t('Bad aprroval address')); }

	const contract = new metamaskAdapter.web3.eth.Contract(erc721_abi as any, contractAddress);

	const tx = contract.methods.approve(
		addressTo,
		tokenId
	);

	// pre-send transaction check
	try {
		await tx.estimateGas({ from: userAddressFrom })
	} catch(e: any) {
		let errorMsg = '';
		try {
				errorMsg = e.message;
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
		} catch(ignored) {
			errorMsg = e;
		}
		throw errorMsg;
	}

	return tx.send({ from: userAddressFrom });
}
export const setApprovalForAllERC721TokenMultisig = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	userAddressFrom: string,
	addressTo: string,
	t: any
) => {
	return new Promise((res, rej) => {
		if ( !contractAddress ) { rej(new Error(t('Empty params'))); }
		if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { rej(new Error(t('Bad contract address'))); }
		if ( !metamaskAdapter.web3.utils.isAddress(addressTo) ) { rej(new Error(t('Bad aprroval address'))); }

		const contract = new metamaskAdapter.web3.eth.Contract(erc721_abi as any, contractAddress);

		const tx = contract.methods.setApprovalForAll(
			addressTo,
			true
		);

		tx.send({ from: userAddressFrom }, (err: any, data: any) => {
			if ( err ) { rej(err); }
			res(data);
		});
	});
}
export const setApprovalERC721TokenMultisig = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	tokenId: string,
	userAddressFrom: string,
	addressTo: string,
	t?: any
) => {
	return new Promise((res, rej) => {
		if ( !contractAddress ) { rej(new Error(t('Empty params'))); }
		if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { rej(new Error(t('Bad contract address'))); }
		if ( !metamaskAdapter.web3.utils.isAddress(addressTo) ) { rej(new Error(t('Bad aprroval address'))); }

		const contract = new metamaskAdapter.web3.eth.Contract(erc721_abi as any, contractAddress);

		const tx = contract.methods.approve(
			addressTo,
			tokenId
		);

		tx.send({ from: userAddressFrom }, (err: any, data: any) => {
			if ( err ) { rej(err); }
			res(data);
		});
	});
}
export const mintToken = async ( metamaskAdapter: MetamaskAdapter, contractAddress: string, userAddress: string ) => {
	if ( !contractAddress ) { return; }

	let   minterAbi
	try {
		minterAbi = getABI(metamaskAdapter.chainId || 0, contractAddress, 'minter');
	} catch(e) {
		throw e;
	}

	const contract = new metamaskAdapter.web3.eth.Contract(minterAbi as any, contractAddress);

	const tx = contract.methods.mint( userAddress );

	// pre-send transaction check
	try {
		await tx.estimateGas({ from: userAddress })
	} catch(e: any) {
		let errorMsg = '';
		try {
			errorMsg = e.message;
			try {
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				errorMsg = errorParsed.originalError.message;
			} catch(ignored) {}
		} catch(ignored) {
			errorMsg = e;
		}
		throw new Error(errorMsg);
	}

	return tx.send({ from: userAddress })

}