
import React                from 'react';
import { CopyToClipboard }  from 'react-copy-to-clipboard';
import Tippy                from '@tippyjs/react';
import Blockies             from 'react-blockies';
import NavLinks             from '../NavLinks';

import {
	compactString,
	localStorageRemove,
	tokenToFloat
} from '../../models/_utils';
import {
	ChainParamsType,
	MetamaskAdapter,
} from '../../models/BlockchainAdapter';
import {
	clearError,
	clearSuccess,
	requestChain,
	unsetAuthMethod,
	unsetLoading,
} from '../../reducers';
import {
	Link,
	withRouter,
	match
} from 'react-router-dom';
import {
	History,
	Location
} from 'history';

import icon_logo            from '../../static/pics/logo.svg';
import icon_logo_mob        from '../../static/pics/logo-mob.svg';
import icon_i_copy          from '../../static/pics/i-copy.svg';
import icon_i_arrow_down    from '../../static/pics/icons/i-arrow-down.svg';
import icon_i_del           from '../../static/pics/i-del.svg';

import { withTranslation } from "react-i18next";

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type HeaderProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function,
	t                     : any,
	match                 : match;
	location              : Location,
	history               : History,
}
type HeaderState = {
	address            : string,
	metamaskLogged     : boolean,
	isTestNetwork      : boolean,
	currentChain       : number,
	chainName          : string,
	chainIcon          : string,
	chainColorCode     : string,
	availableChains    : Array<ChainParamsType>,
	chainSelectorOpened: boolean,

	balanceNative : BigNumber,
	decimalsNative: number,
	symbolNative  : string,

	balanceERC20  : BigNumber,
	decimalsERC20 : number,
	allowanceERC20: BigNumber,
	symbolERC20   : string,

	copiedHint: boolean,

	versionMenuOpened: boolean,
	chainMenuOpened  : boolean,
	userMenuOpened   : boolean,
	cursorOnUserMenu : boolean,

	linksMenuOpened  : boolean,
}

class Header extends React.Component<HeaderProps, HeaderState> {

	store                 : any;
	metamaskAdapter       : MetamaskAdapter;
	unsubscribe!          : Function;
	unlisten!             : Function;
	showAuthMethodSelector: Function;
	t                     : any;

	versionMenuBlockRef   : React.RefObject<HTMLInputElement>;
	chainMenuBlockRef     : React.RefObject<HTMLInputElement>;
	userMenuBlockRef      : React.RefObject<HTMLInputElement>;

	constructor(props: HeaderProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.t                      = props.t;

		this.versionMenuBlockRef    = React.createRef();
		this.chainMenuBlockRef      = React.createRef();
		this.userMenuBlockRef       = React.createRef();

		// const urlParams = queryString.parse(this.props.location.search);
		// if ( urlParams.chain ) {
		// 	this.store.dispatch(requestChain( parseInt(`${urlParams.chain}`) ));
		// }

		this.state = {
			address            : this.store.getState().account.address,
			metamaskLogged     : this.store.getState().metamaskAdapter.logged,
			isTestNetwork      : this.store.getState().metamaskAdapter.isTestNetwork,
			currentChain       : this.store.getState().metamaskAdapter.chainId,
			chainName          : this.store.getState().metamaskAdapter.chainName,
			chainIcon          : this.store.getState().metamaskAdapter.networkIcon,
			chainColorCode     : this.store.getState().metamaskAdapter.chainColorCode,
			availableChains    : this.store.getState().metamaskAdapter.availableChains,
			chainSelectorOpened: false,

			balanceNative : this.store.getState().account.balanceNative,
			decimalsNative: this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative  : this.store.getState().metamaskAdapter.networkTokenTicket,

			balanceERC20  : this.store.getState().erc20TechTokenParams.balance,
			decimalsERC20 : this.store.getState().erc20TechTokenParams.decimals,
			allowanceERC20: this.store.getState().erc20TechTokenParams.allowance,
			symbolERC20   : this.store.getState().erc20TechTokenParams.symbol,

			copiedHint       : false,
			versionMenuOpened: false,
			chainMenuOpened  : false,
			userMenuOpened   : false,
			cursorOnUserMenu : false,

			linksMenuOpened  : false,
		};
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {
			this.setState({
				address        : this.store.getState().account.address,
				metamaskLogged : this.store.getState().metamaskAdapter.logged,
				isTestNetwork  : this.store.getState().metamaskAdapter.isTestNetwork,
				currentChain   : this.store.getState().metamaskAdapter.chainId,
				chainName      : this.store.getState().metamaskAdapter.chainName,
				chainIcon      : this.store.getState().metamaskAdapter.networkIcon,
				chainColorCode : this.store.getState().metamaskAdapter.chainColorCode,
				availableChains: this.store.getState().metamaskAdapter.availableChains,

				balanceNative : this.store.getState().account.balanceNative,
				decimalsNative: this.store.getState().metamaskAdapter.networkTokenDecimals,
				symbolNative  : this.store.getState().metamaskAdapter.networkTokenTicket,

				balanceERC20  : this.store.getState().erc20TechTokenParams.balance,
				decimalsERC20 : this.store.getState().erc20TechTokenParams.decimals,
				allowanceERC20: this.store.getState().erc20TechTokenParams.allowance,
				symbolERC20   : this.store.getState().erc20TechTokenParams.symbol,
			});
		});
		// this.unlisten = this.props.history.listen(() => {
		// 	const urlParams = queryString.parse(this.props.location.search);
		// 	if ( urlParams.chain ) {
		// 		this.store.dispatch(requestChain( parseInt(`${urlParams.chain}`) ));
		// 	}
		// });
 	}
	componentWillUnmount() { this.unsubscribe(); }

	getLogo() {
		if ( this.state.metamaskLogged ) {
			return (
				<React.Fragment>
					<Link
						to="/list"
						className="s-header__logo d-none d-sm-block"
						rel="canonical"
					>
						<img src={ icon_logo } alt="ENVELOP" />
					</Link>
					<Link
						to="/list"
						className="s-header__logo mob d-sm-none"
						rel="canonical"
					>
						<img src={ icon_logo_mob } alt="ENVELOP" />
					</Link>
				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment>
					<Link
						to="/"
						className="s-header__logo d-none d-sm-block"
						rel="canonical"
					>
						<img src={ icon_logo } alt="ENVELOP" />
					</Link>
					<Link
						to="/"
						className="s-header__logo mob d-sm-none"
						rel="canonical"
					>
						<img src={ icon_logo_mob } alt="ENVELOP" />
					</Link>
				</React.Fragment>
			)
		}
	}
	getVersionBlock() {

		const closeVersionMenu = () => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = null;
			this.setState({ versionMenuOpened: false });
		}
		const openVersionMenu = () => {
			setTimeout(() => {
				const body = document.querySelector('body');
				if ( !body ) { return; }
				body.onclick = (e: any) => {
					if ( !this.versionMenuBlockRef.current ) { return; }
					const path = e.path || e.composedPath();
					if ( path && path.includes(this.versionMenuBlockRef.current) ) { return; }
					closeVersionMenu();
				};
			}, 100);
			this.setState({ versionMenuOpened: true, linksMenuOpened: false });
		}

		return (
			<div
				className="s-header__version"
				ref={ this.versionMenuBlockRef }
				onMouseLeave={ closeVersionMenu }
			>
				<button
					className={ `btn btn-sm btn-gray ${ this.state.versionMenuOpened ? 'active' : '' }` }
					onClick={ openVersionMenu }
					onMouseEnter={ openVersionMenu }
				>
					<span>v.1.0</span>
					<img className="arrow" src={ icon_i_arrow_down } alt="" />
				</button>
				{
					this.state.versionMenuOpened ?
					(
						<div className="btn-dropdown">
							<ul>
								<li>
									<button onClick={() => {
										window.location.href = 'https://appv0.envelop.is'
									}} className="item">v0</button>
								</li>
								<li>
									<button className="item">v1.0.0</button>
								</li>
								<li>
									<button onClick={() => {
										window.location.href = 'https://app.envelop.is/'
									}} className="item">v.1.1.0</button>
								</li>
							</ul>
						</div>
					) : null
				}
			</div>
		)
	}
	getChainSelectorDropdown() {
		if ( !this.state.chainMenuOpened ) { return null; }

		const mainnets = this.state.availableChains.filter((item) => { return !item.isTestNetwork });
		const testnets = this.state.availableChains.filter((item) => { return  item.isTestNetwork });

		return (
			<div className="btn-dropdown s-header__network-dropdown">
				<div className="dropdown-wrap">
					<div className="dropdown-header">
						<b>Select network</b>
						<div
							className="close"
							onClick={() => { this.setState({ chainMenuOpened: false }); }}
						>
							<img src={ icon_i_del } alt="" />
						</div>
					</div>
					<div className="scroll">
						<ul>
							{
								mainnets.map((item) => {
									return (
										<li key={item.chainId}>
											<button
												className="item"
												onClick={() => { this.store.dispatch(requestChain(item.chainId)) }}
											>
												<span className="logo">
													<img src={ item.networkIcon } alt="" />
												</span>
												<span className="name">{ item.chainName }</span>
											</button>
										</li>
									)
								})
							}
						</ul>
						<ul>
							{
								testnets.map((item) => {
									return (
										<li key={item.chainId}>
											<button
												className="item"
												onClick={() => { this.store.dispatch(requestChain(item.chainId)) }}
											>
												<span className="logo">
													<img src={ item.networkIcon } alt="" />
												</span>
												<span className="name">{ item.chainName } Testnet</span>
											</button>
										</li>
									)
								})
							}
						</ul>
					</div>
				</div>
			</div>
		)
	}
	getChainSelector() {

		if ( !this.state.metamaskLogged ) { return null; }

		const closeChainMenu = () => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = null;
			this.setState({ chainMenuOpened: false });
		}
		const openChainMenu = () => {
			setTimeout(() => {
				const body = document.querySelector('body');
				if ( !body ) { return; }
				body.onclick = (e: any) => {
					if ( !this.chainMenuBlockRef.current ) { return; }
					const path = e.path || e.composedPath();
					if ( path && path.includes(this.chainMenuBlockRef.current) ) { return; }
					closeChainMenu();
				};
			}, 100);
			this.setState({ chainMenuOpened: true, linksMenuOpened: false });
		}
		return (
			<div
				className={`s-header__network`}
				ref={ this.chainMenuBlockRef }
				onMouseLeave={ closeChainMenu }
			>
				<button
					className={ `btn btn-sm btn-gray s-header__network-btn ${ this.state.chainMenuOpened ? 'active' : '' }`}
					onClick={ openChainMenu }
					onMouseEnter={ openChainMenu }
				>
					<span className="logo">
						<img src={ this.state.chainIcon } alt="" />
					</span>
					<span className="name">{ `${this.state.chainName} ${this.state.isTestNetwork ? 'Testnet' : ''}` }</span>
					<svg className="arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4.94 5.72667L8 8.78L11.06 5.72667L12 6.66667L8 10.6667L4 6.66667L4.94 5.72667Z" fill="white"></path>
					</svg>
				</button>

				{ this.getChainSelectorDropdown() }
			</div>
		)
	}
	getBalancesFull() {
		const balances = [];
		// if ( !this.state.balanceERC20.eq(0) && this.state.decimalsERC20 ) {
		// 	balances.push(`${ tokenToFloat(new BigNumber(this.state.balanceERC20), this.state.decimalsERC20).toFixed(3, BigNumber.ROUND_DOWN)} ${ this.state.symbolERC20 }`)
		// }

		if ( this.state.decimalsNative ) {
			balances.push(`${ tokenToFloat(new BigNumber(this.state.balanceNative), this.state.decimalsNative) } ${ this.state.symbolNative }`)
		}
		return (
			<div className="info">
				{ balances.join(', ') }
			</div>
		)
	}
	getBalancesShort() {
		const balances = [];
		// if ( !this.state.balanceERC20.eq(0) && this.state.decimalsERC20 ) {
		// 	balances.push(`${ tokenToFloat(new BigNumber(this.state.balanceERC20), this.state.decimalsERC20).toFixed(3, BigNumber.ROUND_DOWN)} ${ this.state.symbolERC20 }`)
		// }

		if ( this.state.decimalsNative ) {
			balances.push(`${ tokenToFloat(new BigNumber(this.state.balanceNative), this.state.decimalsNative).toFixed(3, BigNumber.ROUND_DOWN) } ${ this.state.symbolNative }`)
		}
		return (
			<div className="info">
				{ balances.join(', ') }
			</div>
		)
	}
	getConnectBtn() {
		return (
			<button
				className="btn btn-connect"
				onClick={(e) => {
					this.store.dispatch(unsetLoading());
					this.store.dispatch(clearSuccess());
					this.store.dispatch(clearError());
					this.store.dispatch(unsetAuthMethod());

					this.showAuthMethodSelector();

					this.setState({ linksMenuOpened: false });
				}}
			>
				{ this.t('Connect') }
				<span className="d-none d-md-inline">&nbsp;{ this.t('Wallet') }</span>
			</button>
		)
	}
	getBalanceBlock() {
		return (
			<Tippy
				content={ this.getBalancesFull() }
				appendTo={ document.getElementsByClassName("wrapper")[0] }
				trigger='mouseenter'
				interactive={ false }
				arrow={ false }
				maxWidth={ 512 }
			>
				<div className="info">{ this.getBalancesShort() }</div>
			</Tippy>
		)
	}

	closeUserMenu = () => {
		setTimeout(() => {
			if ( this.state.cursorOnUserMenu ) { return; }
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = null;
			this.setState({ userMenuOpened: false });
		}, 100);
	}
	openUserMenu = () => {
		setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = (e: any) => {
				if ( !this.userMenuBlockRef.current ) { return; }
				const path = e.path || e.composedPath();
				if ( path && path.includes(this.userMenuBlockRef.current) ) { return; }
				this.closeUserMenu();
			};
		}, 100);
		this.setState({ userMenuOpened: true, linksMenuOpened: false });
	}
	getAvatarBlock() {
		return (
			<div className="s-user__avatar">
				<div className="img">
					{/* <img src={ icon_avatar } alt="" /> */}
					<Blockies
						seed      = { this.state.address }
						size      = {5}
						scale     = {10}
						color     = "#141616"
						bgColor   = "#4afebf"
						spotColor = "#ffffff"
					/>
				</div>
			</div>
		)
	}
	getUserMenu() {
		if ( !this.state.userMenuOpened ) { return; }

		return (
			<div
				className="s-user__menu"
				onMouseEnter={ () => {
					this.setState({ cursorOnUserMenu: true });
					this.openUserMenu();
				}}
				onMouseLeave={ () => {
					this.setState({ cursorOnUserMenu: false });
					this.closeUserMenu();
				}}
			>
				<ul className="inner">
					<li className="d-md-none">
						<div className="item address">
							<button className="btn-copy">
								<span>{ this.state.address ? compactString(this.state.address) : '' }</span>
								<img src={ icon_i_copy } alt="" />
								<span className="btn-action-info" style={{ display: this.state.copiedHint ? 'block' : 'none' }}>{ this.t('Copied') }</span>
							</button>
						</div>
					</li>
					<li>
						<Link to="/list" className="item">{ this.t('Dashboard') }</Link>
					</li>
					<li>
						<Link to="/crossings" className="item">{ this.t('My crossings') }</Link>
					</li>
					<li>
						<Link className="item" to="/royalty">{ this.t('My Royalties') }</Link>
					</li>
					{/* <li>
						<a href={`${window.location.origin}/mint`} className="item">{ this.t('Mint') }</a>
						<Link className="item" to="/mint">{ this.t('Mint') }</Link>
					</li> */}
					{/* <li>
						<Link className="item" to="/saft">{ this.t('SAFT') }</Link>
					</li> */}
					<li className="mt-md-2">
						<button
							onClick={(e) => {
								localStorageRemove('authMethod');
								window.location.reload();
							}}
							className="item disconnect"
						>{ this.t('Disconnect') }</button>
					</li>
				</ul>
			</div>
		)
	}
	getUserData() {
		return (
			<React.Fragment>

				<div
					className="s-user"
					ref={ this.userMenuBlockRef }
					onClick={ this.openUserMenu }
					onMouseEnter={ this.openUserMenu }
					onMouseLeave={ this.closeUserMenu }
				>
					<div className="s-user__toggle">
						{ this.getAvatarBlock() }
						<div className="s-user__data">
							<span className="mr-2">{ compactString(this.state.address) }</span>
							<svg className="arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M4.94 5.72667L8 8.78L11.06 5.72667L12 6.66667L8 10.6667L4 6.66667L4.94 5.72667Z" fill="white" fillOpacity="0.6"></path>
							</svg>
						</div>
					</div>
					<CopyToClipboard
						text={ this.state.address }
						onCopy={() => {
							this.setState({ copiedHint: true });
							setTimeout(() => { this.setState({ copiedHint: false }); }, 5*1000);
						}}
					>
						<button className="btn-copy">
							<img src={ icon_i_copy } alt="" />
							<span className="btn-action-info" style={{display: this.state.copiedHint ? 'block' : 'none' }}>{ this.t('Copied') }</span>
						</button>
					</CopyToClipboard>
				</div>
				{ this.getUserMenu() }
			</React.Fragment>
		)
	}
	getBtnOrData() {
		if ( !this.state.metamaskLogged ) {
			return this.getConnectBtn()
		} else {
			return this.getUserData()
		}
	}

	render() {

		return (
			<header className="s-header">
				<div className="container-fluid">
					<div className="d-flex align-items-center h-100">
						{ this.getLogo() }
						{ this.getVersionBlock() }

						<button
							className={`s-header__nav-toggle ${ this.state.linksMenuOpened ? 'opened' : '' }`}
							onClick={() => { this.setState({ linksMenuOpened: !this.state.linksMenuOpened }) }}
						><span className="burger-lines"></span></button>
						<div
							className="mob-nav-bg"
							onClick={() => {
								this.setState({ linksMenuOpened: false })
							}}
						> </div>

						<NavLinks />
					</div>

					<div className="d-flex align-items-center">
						{ this.getChainSelector() }
						{ this.getBtnOrData() }
					</div>
				</div>
			</header>
		)
	}
}

export default withTranslation("translations")(withRouter(Header));