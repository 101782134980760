
import { DiscoveredToken } from '../APIService';
import { processSwarmUrl } from '../_utils';

import BigNumber  from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

export enum _AssetType {
	wNFTv0  = -1,
	empty   =  0,
	native  =  1,
	ERC20   =  2,
	ERC721  =  3,
	ERC1155 =  4
}
export const decodeAssetTypeFromString  = (str: string): _AssetType => {
	const strCleared = str
		.toLowerCase()
		.replaceAll('-', '')
		.replaceAll(' ', '')
		.replaceAll('_', '');
	if ( strCleared.includes('1155')   ) { return _AssetType.ERC1155; }
	if ( strCleared.includes('721')    ) { return _AssetType.ERC721 ; }
	if ( strCleared.includes('20')     ) { return _AssetType.ERC20  ; }
	if ( strCleared.includes('native') ) { return _AssetType.native ; }
	if ( strCleared.includes('wNFT')   ) { return _AssetType.wNFTv0 ; }
	return _AssetType.empty;
}
export const decodeAssetTypeFromIndex  = (str: string | number): _AssetType => {

	if ( `${str}` === '-1'  ) { return _AssetType.wNFTv0;  }
	if ( `${str}` ===  '0'  ) { return _AssetType.empty;   }
	if ( `${str}` ===  '1'  ) { return _AssetType.native;  }
	if ( `${str}` ===  '2'  ) { return _AssetType.ERC20;   }
	if ( `${str}` ===  '3'  ) { return _AssetType.ERC721;  }
	if ( `${str}` ===  '4'  ) { return _AssetType.ERC1155; }

	return _AssetType.empty;
}
export const assetTypeToString  = (assetType: _AssetType, EIPPrefix: string): string => {

	if ( assetType === _AssetType.wNFTv0  ) { return 'wNFTv0'           ; }
	if ( assetType === _AssetType.empty   ) { return 'empty'            ; }
	if ( assetType === _AssetType.native  ) { return 'native'           ; }
	if ( assetType === _AssetType.ERC20   ) { return `${EIPPrefix}-20`  ; }
	if ( assetType === _AssetType.ERC721  ) { return `${EIPPrefix}-721` ; }
	if ( assetType === _AssetType.ERC1155 ) { return `${EIPPrefix}-1155`; }

	return 'empty';
}
export type _Asset = {
	assetType: _AssetType,
	contractAddress: string
}
export type _AssetItem = {
	asset: _Asset,
	tokenId: string,
	amount: string,
}

// ---------- COLLATERALS ----------
export type CollateralItem = {
	assetType: _AssetType,
	address: string,
	tokenId?: string,
	amount?: BigNumber,
	tokenImg?: string,
}
export const encodeCollaterals = (collaterals: Array<CollateralItem>, transferFeeAddress?: string): Array<_AssetItem> => {
	let sortedCollaterals = collaterals;

	if ( transferFeeAddress ) {
		sortedCollaterals = sortedCollaterals.sort((item, prev) => {
			return item.address.toLowerCase() === transferFeeAddress.toLowerCase() ? -1 : 1
		})
	}

	return sortedCollaterals
		.map((item: CollateralItem): _AssetItem => {
			return {
				asset: {
					assetType      : item.assetType,
					contractAddress: item.address,
				},
				tokenId: item.tokenId || '0',
				amount: item.amount ? item.amount.toString() : '0'
			}
		})
}
export const decodeCollaterals = (collaterals: Array<_AssetItem>): Array<CollateralItem> => {
	if ( !collaterals ) { return []; }
	return collaterals.map((item: _AssetItem): CollateralItem => {

		let amountParsed = new BigNumber(item.amount);
		if ( amountParsed.isNaN() ) { amountParsed = new BigNumber(0); }

		return {
			assetType: Number(item.asset.assetType),
			address: item.asset.contractAddress,
			tokenId: item.tokenId,
			amount: amountParsed,
		}
	})
}
export const getNativeCollateral = (collaterals: Array<CollateralItem>): BigNumber => {
	let output = new BigNumber(0);
	const foundCollateral = collaterals.filter((item: CollateralItem) => {
		return !item.address || item.address === '0' || item.address === '0x0000000000000000000000000000000000000000'
	});
	if ( foundCollateral.length && foundCollateral[0].amount ) {
		output = foundCollateral[0].amount;
	}
	return output;
}
export const calcCollectedFees = ( collaterals: Array<CollateralItem>, fees: Array<Fee> ): BigNumber => {
	if ( !fees || !fees.length ) { return new BigNumber(0); }
	const addressToSum = fees[0].token.toLowerCase();
	const output = collaterals.reduce((prev, item) => {
		if ( item.address.toLowerCase() !== addressToSum ) { return prev; }
		if ( !item.amount ) { return prev; }
		const amount = new BigNumber(item.amount);
		if ( amount.isNaN() ) { return prev; }

		return prev.plus(amount);
	}, new BigNumber(0));

	return output;
}
// ---------- COLLATERALS ----------

// ---------- ROYALTIES ----------
export type _Royalty = {
	beneficiary: string,
	percent: string,
}
export type Royalty = {
	address   : string | undefined,
	percent   : BigNumber, // fraction: 100.00
}
export type RoyaltyInput = {
	address   : string | undefined,
	percent   : string, // fraction: 100.00
	timeAdded?: number, // needs for ui purposes
}
export const encodeRoyalties = (royalties: Array<Royalty>, wrapperContractAddress: string): Array<_Royalty> => {
	return royalties.map((item: Royalty): _Royalty => {
		return {
			beneficiary: item.address || wrapperContractAddress,
			percent    : item.percent.multipliedBy(100).toString(),
		}
	})
}
export const decodeRoyalties = (royalties: Array<_Royalty>): Array<Royalty> => {
	if ( !royalties ) { return []; }
	return royalties.map((item: _Royalty): Royalty => {
		return {
			address: item.beneficiary === '0x0000000000000000000000000000000000000000' ? undefined : item.beneficiary,
			percent: new BigNumber(item.percent).dividedBy(100),
		}
	})
}
// ---------- END ROYALTIES ----------

// ---------- LOCKS ----------
export enum LockType {
	time  = '0x00',
	value = '0x01',
	slots = '0x02',
}
export const getLockType = (str: string): LockType | undefined => {
	if ( str === LockType.time  ) { return LockType.time ; }
	if ( str === LockType.value ) { return LockType.value; }
	if ( str === LockType.slots ) { return LockType.slots; }
	return undefined;
}
export type _Lock = {
	lockType: string,
	param: string,
}
export type Lock = {
	lockType: LockType,
	param: BigNumber,
}
export const encodeLocks = (locks: Array<Lock>): Array<_Lock> => {
	return locks.map((item: Lock): _Lock => {
		return {
			lockType: item.lockType,
			param   : item.param.toString(),
		}
	})
}
export const decodeLocks = (locks: Array<_Lock>): Array<Lock> => {
	if ( !locks ) { return []; }
	return locks.map((item: _Lock): Lock => {
		const lockTypeParsed = getLockType(item.lockType) || LockType.time;

		let amountParsed = new BigNumber(item.param);
		if ( amountParsed.isNaN() ) { amountParsed = new BigNumber(0); }

		if ( lockTypeParsed === LockType.time ) {
			amountParsed = amountParsed.multipliedBy(1000);
		}

		return {
			lockType: lockTypeParsed,
			param   : amountParsed
		}
	})
}
// ---------- END LOCKS ----------

// ---------- FEE ----------
export type _Fee = {
	feeType: string,
	param: string,
	token: string,
}
export type Fee = {
	value: BigNumber,
	token: string,
}
export const encodeFees = (fees: Array<Fee>): Array<_Fee> => {
	return fees.map((item: Fee): _Fee => {
		return {
			feeType: '0x00',
			param: item.value.toString(),
			token: item.token,
		}
	})
}
export const decodeFees = (fees: Array<_Fee>): Array<Fee> => {
	if ( !fees ) { return []; }
	return fees.map((item: _Fee): Fee => {
		let amountParsed = new BigNumber(item.param);
		if ( amountParsed.isNaN() ) { amountParsed = new BigNumber(0); }
		return {
			token: item.token,
			value: amountParsed
		}
	})
}
// ---------- END FEE ----------

// ---------- Rules ----------
export type Rules = {
	noUnwrap: boolean,
	noAddCollateral: boolean,
	noWrap: boolean,
	noTransfer: boolean,
}
export enum RulesBitMask {
	'noUnwrap'        = 0b0001,
	'noWrap'          = 0b0010,
	'noTransfer'      = 0b0100,
	'noAddCollateral' = 0b1000,
}
// contract type: bytes2 ('0x000f')
export const encodeRules = (settings: Rules): string => {
	let output = 0;
	if ( settings.noUnwrap        ) { output |= RulesBitMask.noUnwrap;        }
	if ( settings.noWrap          ) { output |= RulesBitMask.noWrap;          }
	if ( settings.noTransfer      ) { output |= RulesBitMask.noTransfer;      }
	if ( settings.noAddCollateral ) { output |= RulesBitMask.noAddCollateral; }

	return '0x' + output.toString(16).padStart(4, '0');
}
export const decodeRules = (settings: string): Rules => {
	const numParsed = parseInt(settings, 16);

	const output = {
		noUnwrap       : false,
		noAddCollateral: false,
		noWrap         : false,
		noTransfer     : false,
	};

	if ( numParsed & RulesBitMask.noUnwrap        ) { output.noUnwrap        = true; }
	if ( numParsed & RulesBitMask.noWrap          ) { output.noWrap          = true; }
	if ( numParsed & RulesBitMask.noTransfer      ) { output.noTransfer      = true; }
	if ( numParsed & RulesBitMask.noAddCollateral ) { output.noAddCollateral = true; }

	return output;
}
// ---------- END Rules ----------

export type _INData = {
	inAsset:_AssetItem,
	unWrapDestinition: string, // fallback for old contracts; will be deprecated
	unWrapDestination: string,
	fees: Array<_Fee>,
	locks: Array<_Lock>,
	royalties: Array<_Royalty>,
	outType: _AssetType,
	outBalance: string,
	rules: string,
}
export type WrapTransactionArgs = {
	_inData: _INData,
	_collateral: Array<_AssetItem>,
	_wrappFor: string,
}
export type OriginalTokenType = {
	owner?          : string,
	chainId?        : number,
	assetType       : _AssetType,
	contractAddress?: string,
	tokenId?        : string,
	amount?         : BigNumber,
	totalSupply?    : BigNumber,
	description?    : string,
	image?          : string,
	image_url?      : string,
	imageRaw?       : string,
	name?           : string,
	tokenUrl?       : string,
	tokenUrlRaw?    : string,
	tokenUrlRawJSON?: string,
	sortParams?: {
		blockNumber: BigNumber | undefined,
		logIndex: BigNumber | undefined
	}
}
export type _WNFT = {
	inAsset: _AssetItem,
	collateral: Array<_AssetItem>,
	unWrapDestinition?: string,
	unWrapDestination?: string,
	fees: Array<_Fee>,
	locks: Array<_Lock>,
	royalties: Array<_Royalty>,
	rules: string,
}
export type WrappedTokenType = {
	owner                  : string | undefined,
	contractAddress        : string,
	tokenId                : string,
	assetType              : _AssetType,
	originalTokenInfo      : OriginalTokenType,
	collateral             : Array<CollateralItem>,
	amount?                : BigNumber,
	totalSupply?           : BigNumber,
	description?           : string,
	image?                 : string,
	imageRaw?              : string,
	name?                  : string,
	fees                   : Array<Fee>,
	royalties              : Array<Royalty>,
	locks                  : Array<Lock>,
	unWrapDestination      : string,
	rules                  : Rules,
	tokenUrl?              : string,
	tokenUrlRaw?           : string,
	tokenUrlRawJSON?       : string,
	collectedFees          : BigNumber,
	sortParams?            : {
		blockNumber: BigNumber | undefined,
		logIndex: BigNumber | undefined
	},
	create_tx?: string,
}
export const discoveredToOriginal = (token: DiscoveredToken, chainId: number): OriginalTokenType => {
	return {
		owner: token.owner,
		chainId: chainId,
		assetType: decodeAssetTypeFromString(token.asset_type),
		contractAddress: token.contract_address,
		tokenId: token.token_id,
		amount: token.balance ? new BigNumber(token.balance) : undefined,
		totalSupply: token.totalSupply ? new BigNumber(token.totalSupply) : undefined,
		tokenUrl:token.token_uri,
	}
}
export const originalToWrapped = (token: OriginalTokenType): WrappedTokenType => {
	return {
		owner: token.owner,
		assetType: token.assetType,
		contractAddress: token.contractAddress || '0x0000000000000000000000000000000000000000',
		tokenId: token.tokenId || '0',
		amount: token.amount ? new BigNumber(token.amount) : undefined,
		totalSupply: token.totalSupply ? new BigNumber(token.totalSupply) : undefined,
		tokenUrl:token.tokenUrl,
		originalTokenInfo: {
			assetType: _AssetType.empty,
		},
		collateral: [],
		description: token.description,
		image: token.image,
		name: token.name,
		fees: [],
		royalties: [],
		locks: [],
		unWrapDestination: '0x0000000000000000000000000000000000000000',
		rules: decodeRules('0x0000'),
		collectedFees: new BigNumber(0)
	}
}

export const decodeWrappedToken = (params: {
	inWNFT         : _WNFT,
	owner          : string | undefined,
	chainId        : number,
	contractAddress: string,
	tokenId        : string,
	assetType      : _AssetType,
	amount         : BigNumber | undefined,
	totalSupply    : BigNumber | undefined,
	tokenUrl       : string,
	tokenUrlRaw    : string,
	tokenUrlRawJSON: string | undefined,
	sortParams?    : {
		blockNumber: BigNumber | undefined,
		logIndex: BigNumber | undefined
	},
	create_tx?: string,
}): WrappedTokenType => {

	let tokenUrlParsed;
	if ( params.tokenUrlRawJSON ) {
		try {
			tokenUrlParsed = JSON.parse(params.tokenUrlRawJSON);
		} catch (e: any) {
			console.log('Cannot parse tokenUrlBody', e);
		}
	}
	const parsedCollaterals   = decodeCollaterals(params.inWNFT.collateral);
	const parsedFees          = decodeFees(params.inWNFT.fees);
	const parsedCollectedFees = calcCollectedFees(parsedCollaterals, parsedFees);

	let tokenUrlRawJSON = '';
	let tokenUrl;
	let tokenUrlRaw;
	try {
		tokenUrlRawJSON = params.tokenUrlRawJSON || '';
		tokenUrl        = processSwarmUrl(params.tokenUrl);
		tokenUrlRaw     = params.tokenUrl;
	} catch(e) {
		console.log('Cannot fetch tokenURL', e)
	}

	let description = tokenUrlParsed ? tokenUrlParsed.description || '' : '';
	let name        = tokenUrlParsed ? tokenUrlParsed.name        || '' : '';
	let image    = '';
	let imageRaw = '';
	if ( tokenUrlParsed ) {
		if ( tokenUrlParsed.image     ) { image = processSwarmUrl(tokenUrlParsed.image    ); imageRaw = tokenUrlParsed.image;     }
		if ( tokenUrlParsed.image_url ) { image = processSwarmUrl(tokenUrlParsed.image_url); imageRaw = tokenUrlParsed.image_url; }
	}

	return {
		owner          : params.owner,
		contractAddress: params.contractAddress,
		tokenId        : params.tokenId,
		assetType      : params.assetType,
		amount         : params.amount,
		totalSupply    : params.totalSupply,

		originalTokenInfo : {
			owner          : '',
			chainId        : params.chainId,
			assetType      : decodeAssetTypeFromIndex(`${params.inWNFT.inAsset.asset.assetType}`),
			contractAddress: params.inWNFT.inAsset.asset.contractAddress,
			tokenId        : params.inWNFT.inAsset.tokenId,
			amount         : new BigNumber(params.inWNFT.inAsset.amount),
		},

		tokenUrlRawJSON,
		tokenUrl,
		tokenUrlRaw,
		description,
		image,
		imageRaw,
		name,

		collateral       : parsedCollaterals,
		fees             : parsedFees,
		collectedFees    : parsedCollectedFees,
		royalties        : decodeRoyalties(params.inWNFT.royalties),
		locks            : decodeLocks(params.inWNFT.locks),
		rules            : decodeRules(params.inWNFT.rules),
		unWrapDestination: params.inWNFT.unWrapDestination || params.inWNFT.unWrapDestinition || '',

		sortParams: params.sortParams,
		create_tx: params.create_tx,
	}
}
export const encodeINData = (params: {
	originalToken         : OriginalTokenType,
	unwrapDestination     : string,
	fees                  : Array<Fee>,
	locks                 : Array<Lock>,
	royalties             : Array<Royalty>,
	rules                 : Rules,
	outType               : _AssetType,
	outBalance            : number,
	wrapperContractAddress: string,
}): _INData => {
	return {
		inAsset          : {
			asset: {
				assetType: params.originalToken.assetType,
				contractAddress: params.originalToken.contractAddress || '0x0000000000000000000000000000000000000000',
			},
			tokenId: params.originalToken.tokenId || '0',
			amount: params.originalToken.amount ? params.originalToken.amount.toString() : '0',
		},
		unWrapDestinition: params.unwrapDestination,
		unWrapDestination: params.unwrapDestination,
		fees             : encodeFees(params.fees),
		locks            : encodeLocks(params.locks),
		royalties        : encodeRoyalties(params.royalties, params.wrapperContractAddress),
		outType          : params.outType,
		outBalance       : params.outBalance.toString(),
		rules            : encodeRules(params.rules),
	}
}
export const encodeWrapArguments = (params: {
	originalToken         : OriginalTokenType,
	unwrapDestination     : string,
	fees                  : Array<Fee>,
	locks                 : Array<Lock>,
	royalties             : Array<Royalty>,
	rules                 : Rules,
	outType               : _AssetType,
	outBalance            : number,
	collaterals           : Array<CollateralItem>,
	wrapFor               : string,
	wrapperContractAddress: string,
}): WrapTransactionArgs => {

	let transferToken = undefined;
	if ( params.fees.length ) {
		transferToken = params.fees[0].token;
	}

	return {
		_inData: encodeINData({ ...params }),
		_collateral: encodeCollaterals(params.collaterals, transferToken),
		_wrappFor: params.wrapFor,
	}
}

// ---------- SAFT ----------
export type SAFTRecipientItem = {
	timeAdded: number,
	userAddress: string,
	tokenId: string,
}

export type SAFTSubscriptionType = {
	beneficiary      : string,
	timelockPeriod   : BigNumber,
	ticketValidPeriod: BigNumber,
	counter          : number,
	isAvailable      : boolean,
}

export type SAFTPayOption = {
	agentFeePercent : BigNumber,
	paymentToken : string,
	paymentAmount: BigNumber,
	paymentAmountWithFee: BigNumber,
	idx          : number,
}

export type SAFTTariff = {
	idx         : number,
	subscription: SAFTSubscriptionType,
	payWith     : Array<SAFTPayOption>,
}
// ---------- END SAFT ----------