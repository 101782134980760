import React               from 'react';
import Tippy               from '@tippyjs/react';
import CopyToClipboard     from 'react-copy-to-clipboard';
import SecretInput         from '../SecretInput';
import {
	MetamaskAdapter,
	WrappedTokenType,
	_AssetType,
	OriginalTokenType,
	checkApprovalERC721Token,
} from '../../models/BlockchainAdapter';

import {
	setError,
	setLoading,
	unsetLoading
} from '../../reducers';
import {
	setApprovalERC721Token
} from '../../models/BlockchainAdapter/erc721contract';

import {
	CrossingDispatcher
} from '../../models/BlockchainAdapter';
import {
	checkApprovalERC1155Token,
	setApprovalERC1155Token
} from '../../models/BlockchainAdapter/erc1155contract';

import { withTranslation } from "react-i18next";

import default_icon from '../../static/pics/coins/_default.svg';
import icon_i_copy  from '../../static/pics/i-copy.svg';
import icon_i_link  from '../../static/pics/icons/i-external-green-sm.svg';

import BigNumber from 'bignumber.js';
import { compactString } from '../../models/_utils';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type CrossingFreezePopupProps = {
	store          : any,
	metamaskAdapter: MetamaskAdapter,
	t              : any,
	token          : WrappedTokenType | OriginalTokenType,
	closePopup     : Function,
}
type CrossingFreezePopupState = {

	userAddress       : string,
	EIPPrefix         : string,

	balanceNative     : BigNumber,
	decimalsNative    : number,
	symbolNative      : string,
	iconNative        : string,
	networkTokenTicket: string,
	explorerBaseUrl   : string,

	crossing          : {
		keeper: string,
		targetChains: Array<{ targetChainId: number, address: string, icon?: string, name?: string, }>
	},
	targetChain       : number | undefined,
	inputPinCode      : string,
	inputPinCodeHash  : string,
	checkedManualHash : boolean,

	targetChainListOpened: boolean,
	successPopup         : boolean,
	txHash               : string,
	saveCodeAcceptChecked: boolean,
	pinCodeCopied        : boolean,
	pinCodeHashCopied    : boolean,
	copiedHintWhere      : string,
}

class CrossingFreezePopup extends React.Component<CrossingFreezePopupProps, CrossingFreezePopupState> {

	store             : any;
	unsubscribe!      : Function;
	metamaskAdapter   : MetamaskAdapter;
	t                 : any;
	closePopup        : Function;
	targetListRef     : React.RefObject<HTMLInputElement>;
	crossingDispatcher: CrossingDispatcher | undefined;
	copiedHintTimer   : number;
	copiedHintTimeout : number;

	constructor(props: CrossingFreezePopupProps) {
		super(props);

		this.store           = props.store;
		this.metamaskAdapter = props.metamaskAdapter;
		this.t               = props.t;
		this.closePopup      = props.closePopup;
		this.targetListRef   = React.createRef();

		this.copiedHintTimer    = 0;
		this.copiedHintTimeout  = 2; // s

		this.crossingDispatcher = this.metamaskAdapter.getCrossingDispatcher();

		this.state = {
			balanceNative     : this.store.getState().account.balanceNative,
			decimalsNative    : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative      : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative        : this.store.getState().metamaskAdapter.networkTokenIcon,
			networkTokenTicket: this.store.getState().metamaskAdapter.networkTokenTicket,
			explorerBaseUrl   : this.store.getState().metamaskAdapter.explorerBaseUrl,
			userAddress       : this.store.getState().account.address || '',
			EIPPrefix         : this.store.getState().metamaskAdapter.EIPPrefix,

			crossing          : this.store.getState().metamaskAdapter.crossing,

			targetChain       : undefined,
			inputPinCode      : '',
			inputPinCodeHash  : '',
			checkedManualHash : false,

			targetChainListOpened: false,

			successPopup         : false,
			txHash               : '',
			saveCodeAcceptChecked: false,
			pinCodeCopied        : false,
			pinCodeHashCopied    : false,
			copiedHintWhere      : '',
		}
	}

	componentDidMount() {

		if ( !this.crossingDispatcher ) { this.crossingDispatcher = this.metamaskAdapter.getCrossingDispatcher(); }

		this.unsubscribe = this.store.subscribe(() => {

			this.setState({
				balanceNative        : this.store.getState().account.balanceNative,
				decimalsNative       : this.store.getState().metamaskAdapter.networkTokenDecimals,
				iconNative           : this.store.getState().metamaskAdapter.networkTokenIcon,
				symbolNative         : this.store.getState().metamaskAdapter.networkTokenTicket,
				explorerBaseUrl      : this.store.getState().metamaskAdapter.explorerBaseUrl,
				userAddress          : this.store.getState().account.address || '',
				EIPPrefix            : this.store.getState().metamaskAdapter.EIPPrefix,
				crossing             : this.store.getState().metamaskAdapter.crossing,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	targetChainListOpen = () => {
		setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = (e: any) => {
				if ( !this.targetListRef.current ) { return; }
				if ( e.path && e.path.includes(this.targetListRef.current) ) { return; }
				this.targetChainListClose();
			};
		}, 100);
		this.setState({ targetChainListOpened: true });
	}
	targetChainListClose = () => {
		const body = document.querySelector('body');
		if ( !body ) { return; }
		body.onclick = null;
		this.setState({ targetChainListOpened: false });
	}
	getTargetChain() {

		if ( !this.state.targetChain ) {
			return (
				<div
					className="input-control"
					onClick={() => { this.targetChainListOpen() }}
				>
					<span className="empty">Choose a target chain</span>
				</div>
			)
		}

		const foundChain = this.state.crossing.targetChains.find((item) => { return item.targetChainId === this.state.targetChain });
		if ( !foundChain ) {
			return (
				<div
					className="input-control"
					onClick={() => { this.targetChainListOpen() }}
				>
					<span className="empty">Choose a target chain</span>
				</div>
			)
		}

		return (
			<div
				className="input-control"
				onClick={() => { this.targetChainListOpen() }}
			>
				<span>
					<span className="option-chain">
						<img className="d-inline-block mr-1" src={ foundChain.icon ? foundChain.icon : default_icon } alt="" />
						{ foundChain.name ? foundChain.name : `Chain ${foundChain.targetChainId}` }
					</span>
				</span>
			</div>
		)
	}
	getTargetChainList() {
		if ( !this.state.targetChainListOpened ) { return null; }

		return (
			<ul className="options-list">
				{ this.state.crossing.targetChains.map((item) => {
					return (
						<li
							key={ item.targetChainId }
							className={`option ${ this.state.targetChain === item.targetChainId ? 'selected' : '' }`}
							onClick={() => { this.setState({ targetChain: item.targetChainId, targetChainListOpened: false }) }}
						>
							<span className="option-chain">
								<img className="d-inline-block mr-1" src={ item.icon ? item.icon : default_icon } alt="" />
								{ item.name ? item.name : `Chain ${item.targetChainId}` }
							</span>
						</li>
					)
				}) }
			</ul>
		)
	}

	generatePin(length: number) {
		return [...Array(length)].reduce((acc) => { return `${acc}${parseInt(`${Math.random()*10}`)}` }, '')
	}
	generateHash(str: string): string {
		const encodedStr = this.metamaskAdapter.web3.eth.abi.encodeParameter('uint256', str);
		const hash = this.metamaskAdapter.web3.utils.keccak256(encodedStr);
		return hash;
	}

	approveError(e: any) {
		console.log(e);
		this.store.dispatch(unsetLoading());

		let errorMsg = '';
		try {
			if ( 'message' in e ) {
				errorMsg = e.message;
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
			} else {
				errorMsg = `${e}`;
			}
		} catch (ignored) {
			errorMsg = `${e}`;
		}

		let links = undefined;
		try {
			if ('transactionHash' in e) {
				links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
			} else {
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					const txHash = errorParsed.transactionHash;
					if ( txHash ) {
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					}
				} catch(ignored) {}
			}
		} catch (ignored) {}

		this.store.dispatch(setError({
			text: `${this.t('Cannot approve token')}: ${errorMsg}`,
			buttons: undefined,
			links: links,
		}));
	}
	async approveToken() {
		if ( !this.crossingDispatcher          ) { return; }
		if ( !this.props.token.contractAddress ) { return; }
		if ( !this.props.token.tokenId         ) { return; }

		if ( this.props.token.assetType === _AssetType.ERC721 || this.props.token.assetType === _AssetType.wNFTv0 ) {
			const approved = await checkApprovalERC721Token({
				metamaskAdapter: this.metamaskAdapter,
				contractAddress: this.props.token.contractAddress,
				tokenId: this.props.token.tokenId,
				userAddress: this.metamaskAdapter.userAddress,
				addressTo: this.crossingDispatcher.keeperContractAddress
			});

			if ( !approved ) {
				this.store.dispatch(setLoading({ msg: `${this.t('Waiting for approve')} ${compactString(this.props.token.contractAddress)}:${this.props.token.tokenId}` }));
				try {
					await setApprovalERC721Token(
						this.metamaskAdapter,
						this.props.token.contractAddress,
						this.props.token.tokenId,
						this.metamaskAdapter.userAddress,
						this.crossingDispatcher.keeperContractAddress,
						this.t,
					);
				} catch(e: any) {
					throw new Error(e.message);
				}
			}

			this.store.dispatch(unsetLoading());
		}

		if ( this.props.token.assetType === _AssetType.ERC1155 ) {
			const approved = await checkApprovalERC1155Token({
				metamaskAdapter: this.metamaskAdapter,
				contractAddress: this.props.token.contractAddress,
				userAddress: this.metamaskAdapter.userAddress,
				addressTo: this.crossingDispatcher.keeperContractAddress
			});

			if ( !approved ) {
				this.store.dispatch(setLoading({ msg: `${this.t('Waiting for approve')} ${compactString(this.props.token.contractAddress)}:${this.props.token.tokenId}` }));
				try {
					await setApprovalERC1155Token(
						this.metamaskAdapter,
						this.props.token.contractAddress,
						this.metamaskAdapter.userAddress,
						this.crossingDispatcher.keeperContractAddress,
						this.t,
					);
				} catch(e: any) {
					throw new Error(e.message);
				}
			}

			this.store.dispatch(unsetLoading());
		}
	}
	async submitFreeze() {
		if ( !this.crossingDispatcher          ) { return; }

		if ( !this.props.token.contractAddress ) { return; }
		if ( !this.props.token.tokenId         ) { return; }
		if ( !this.state.targetChain           ) { return; }
		if ( !this.state.inputPinCodeHash      ) { return; }

		try {
			await this.approveToken();
		} catch(e) {
			this.approveError(e);
			return;
		}

		this.store.dispatch(setLoading({ msg: this.t('Waiting for freeze') }));

		this.crossingDispatcher.freezeToken(
			this.props.token.contractAddress,
			this.props.token.tokenId,
			this.state.targetChain,
			this.state.inputPinCodeHash,
		)
			.then((data) => {
				this.store.dispatch(unsetLoading());

				this.setState({
					txHash: data.transactionHash,
					successPopup: true,
				})
			})
			.catch((e: any) => {
				console.log(e);
				this.store.dispatch(unsetLoading());

				let errorMsg = '';
				try {
					if ( 'message' in e ) {
						errorMsg = e.message;
						try {
							const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
							errorMsg = errorParsed.originalError.message;
						} catch(ignored) {}
					} else {
						errorMsg = `${e}`;
					}
				} catch (ignored) {
					errorMsg = `${e}`;
				}

				let links = undefined;
				try {
					if ('transactionHash' in e) {
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
					} else {
						try {
							const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
							const txHash = errorParsed.transactionHash;
							if ( txHash ) {
								links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
							}
						} catch(ignored) {}
					}
				} catch (ignored) {}

				this.store.dispatch(setError({
					text: `${this.t('Cannot approve token')}: ${errorMsg}`,
					buttons: undefined,
					links: links,
				}));
			})
	}

	getFreezePopup() {
		return (
			<div className="modal">
				<div
					className="modal__inner"
					onMouseDown={(e) => {
						e.stopPropagation();
						if ((e.target as HTMLTextAreaElement).className === 'modal__inner' || (e.target as HTMLTextAreaElement).className === 'container') {
							this.closePopup();
						}
					}}
				>
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
							<div
								className="modal__close"
								onClick={() => { this.closePopup() }}
							>
								<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
									<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
								</svg>
							</div>
							<div className="c-add">
								<div className="c-add__text">
									<div className="h2">Prepare for crossing</div>
								</div>

								<div className="text-muted mb-2">
									<div> <small>wNFT with collateral can be frozen in the original blockchain.</small></div>
									<div> <small>You can create the NFT key in the target blockchain.</small></div>
									<div> <small>You can exchange the NFT key by transferring it and pin-code in the turn of assets in the target blockchain.</small></div>
									<div> <small>The new owner of the NFT key burns it in the target blockchain and unfreezes wrapped NFT in the original blockchain using the secret pin-code.</small></div>
								</div>

								<div className="c-add__coins">
									<div className="c-add__form">
										<div className="input-group">
											<label className="input-label">
												Target chain
												<Tippy
													content={ 'Blockchain where to create NFT key' }
													appendTo={ document.getElementsByClassName("wrapper")[0] }
													trigger='mouseenter'
													interactive={ false }
													arrow={ false }
													maxWidth={ 512 }
												>
													<span className="i-tip"></span>
												</Tippy>
											</label>
											<div
												className="select-custom"
												ref={ this.targetListRef }
											>
												{ this.getTargetChain() }
												{ this.getTargetChainList() }
											</div>
										</div>
										<div className="input-group">
											<div className="form-row">
												<div className="col col-sm-8">
													<label className="input-label">
														PIN-code
														<Tippy
															content={ 'Secret pin-code to unfreeze wrapped NFT in the original blockchain. Only you know the pin-code. Envelop doesn\'t keep the secret pin-code.' }
															appendTo={ document.getElementsByClassName("wrapper")[0] }
															trigger='mouseenter'
															interactive={ false }
															arrow={ false }
															maxWidth={ 512 }
														>
															<span className="i-tip"></span>
														</Tippy>
													</label>
													<SecretInput
														placeholder="Enter here"
														value={ this.state.inputPinCode }
														disabled={ this.state.checkedManualHash }
														onChange={(e) => {
															const value = e.target.value.toLowerCase().replaceAll(' ', '').replace(/[^0-9]/g, "");
															if ( value === '' ) {
																this.setState({ inputPinCode: '', inputPinCodeHash: '' })
																return;
															}

															this.setState({
																inputPinCode: value,
																inputPinCodeHash: this.generateHash(value),
															})
														}}
													/>
												</div>
												<div className="col col-sm-4">
													<label className="input-label d-none d-sm-block">&nbsp;</label>
													<button
														className="btn-link w-100"
														disabled={ this.state.checkedManualHash }
														onClick={(e) => {
															const genedPin = this.generatePin(20);
															const hash = this.generateHash(genedPin);

															this.setState({
																inputPinCode: genedPin,
																inputPinCodeHash: hash,
															})
														}}
													>Generate PIN</button>
												</div>
											</div>
										</div>
										<div className="input-group">
											<div className="input-label">
												Hash (secret)
												<Tippy
													content={ 'Result of the SHA256 (Solidity style) encoding of the secret pin-code. All users can find it in the blockchain. Envelop keeps the hash.' }
													appendTo={ document.getElementsByClassName("wrapper")[0] }
													trigger='mouseenter'
													interactive={ false }
													arrow={ false }
													maxWidth={ 512 }
												>
													<span className="i-tip"></span>
												</Tippy>
												<label className="checkbox d-block mt-3 d-sm-inline mt-sm-0 ml-sm-4">
													<input
														type="checkbox"
														checked={ this.state.checkedManualHash }
														onChange={(e) => {
															let pinCode = this.state.inputPinCode;
															let hash = this.state.inputPinCodeHash;
															if ( e.target.checked ) {
																pinCode = '';
																hash = '';
															}
															this.setState({
																checkedManualHash: e.target.checked,
																inputPinCode: pinCode,
																inputPinCodeHash: hash,
															})
														}}
													/>
													<span className="check"> </span>
													<span className="check-text">Enter manually</span>
													<Tippy
														content={ 'You can manually enter the hash of your secret pin-code. Use SHA56 (Solidity style) algorithm to encode.' }
														appendTo={ document.getElementsByClassName("wrapper")[0] }
														trigger='mouseenter'
														interactive={ false }
														arrow={ false }
														maxWidth={ 512 }
													>
														<span className="i-tip"></span>
													</Tippy>
												</label>
											</div>
											<input
												className="input-control"
												type="text"
												placeholder=""
												disabled={ !this.state.checkedManualHash }
												value={ this.state.inputPinCodeHash }
												onChange={(e) => {
													this.setState({ inputPinCode: '', inputPinCodeHash: e.target.value })
												}}
											/>
										</div>
										<div className="form-row mt-6">
											<div className="col-sm-6">
												<button
													className="btn"
													disabled={ !this.state.targetChain || !this.state.inputPinCodeHash }
													onClick={(e) => { this.submitFreeze() }}
												>Start Crossing</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	getCopiedHint(where: string) {
		if ( this.state.copiedHintWhere === where  ) {
			return (<span className="btn-action-info">{ this.t('Copied') }</span>)
		}
	}
	getSuccessPopup() {
		return (
			<div className="modal">
				<div className="modal__inner">
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
							<div className="c-add">
								<div className="c-add__text">
									<div className="h2">wNFT secret</div>
									<p>You have created a&nbsp;wrapped NFT with the secret pin-code. Copy the data and don’t tell anyone. After closing the window, you will never be&nbsp;able to&nbsp;restore them.</p>
									<p>
										<a className="ex-link" target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/tx/${this.state.txHash}` }>
											Transaction
											{ ' ' }
											<img className="i-ex" src={ icon_i_link } alt="" />
										</a>
									</p>
								</div>
								<div className="c-add__form">
									{
										this.state.inputPinCode ? (
											<div className="row mb-4">
												<div className="col-12">
													<label className="input-label">
														PIN-code
														<Tippy
															content={ 'Secret pin-code to unfreeze wrapped NFT in the original blockchain. Only you know the pin-code. Envelop doesn\'t keep the secret pin-code.' }
															appendTo={ document.getElementsByClassName("wrapper")[0] }
															trigger='mouseenter'
															interactive={ false }
															arrow={ false }
															maxWidth={ 512 }
														>
															<span className="i-tip"></span>
														</Tippy>
													</label>
												</div>
												<div className="col-sm-10 pr-sm-0">
													<SecretInput
														placeholder="Enter here"
														value={ this.state.inputPinCode }
														readonly={ true }
														onChange={(e) => {
															const value = e.target.value.toLowerCase().replaceAll(' ', '').replace(/[^0-9]/g, "");
															if ( value === '' ) {
																this.setState({ inputPinCode: '', inputPinCodeHash: '' })
																return;
															}

															this.setState({
																inputPinCode: value,
																inputPinCodeHash: this.generateHash(value),
															})
														}}
													/>
												</div>
												<div className="col-sm-2 mt-3 mt-sm-0">
													<CopyToClipboard
														text={ this.state.inputPinCode }
														onCopy={() => {
															this.setState({
																copiedHintWhere:'pincode'
															});
															clearTimeout(this.copiedHintTimer);
															this.copiedHintTimer = window.setTimeout(() => {
																this.setState({
																	copiedHintWhere: ''
																});
															}, this.copiedHintTimeout*1000);
														}}
													>
														<button className="btn btn-gray w-100">
															<img src={ icon_i_copy } alt="" />
															{ this.getCopiedHint('pincode') }
														</button>
													</CopyToClipboard>
												</div>
											</div>
										) : null
									}
									<div className="row mb-6 mb-sm-8">
										<div className="col-12">
											<label className="input-label">
												Proof
												<Tippy
													content={ 'Result of the SHA256 (Solidity style) encoding of the secret pin-code. All users can find it in the blockchain. Envelop keeps the hash.' }
													appendTo={ document.getElementsByClassName("wrapper")[0] }
													trigger='mouseenter'
													interactive={ false }
													arrow={ false }
													maxWidth={ 512 }
												>
													<span className="i-tip"></span>
												</Tippy>
											</label>
										</div>
										<div className="col-sm-10 pr-sm-0">
											<input
												className="input-control control-gray"
												type="text"
												readOnly={ true }
												value={ this.state.inputPinCodeHash }
											/>
										</div>
										<div className="col-sm-2 mt-3 mt-sm-0">
											<CopyToClipboard
												text={ this.state.inputPinCodeHash }
												onCopy={() => {
													this.setState({
														copiedHintWhere:'pincodehash'
													});
													clearTimeout(this.copiedHintTimer);
													this.copiedHintTimer = window.setTimeout(() => {
														this.setState({
															copiedHintWhere: ''
														});
													}, this.copiedHintTimeout*1000);
												}}
											>
												<button className="btn btn-gray w-100">
													<img src={ icon_i_copy } alt="" />
													{ this.getCopiedHint('pincodehash') }
												</button>
											</CopyToClipboard>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-7">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={ this.state.saveCodeAcceptChecked }
													onChange={(e) => {
														this.setState({ saveCodeAcceptChecked: e.target.checked });
													}}
												/>
												<span className="check"></span>
												<span className="check-text">I&nbsp;understood and accepted the conditions as&nbsp;well&nbsp;I copied all the data to&nbsp;close this window</span>
											</label>
										</div>
										<div className="col-sm-4 offset-sm-1 mt-4 mt-sm-0">
											<button
												className="btn w-100"
												disabled={ !this.state.saveCodeAcceptChecked }
												onClick={() => {
													window.location.href = '/crossings'
													this.closePopup();
													this.metamaskAdapter.updateAllBalances();
												}}
											>Close</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		if ( this.state.successPopup ) {
			return this.getSuccessPopup()
		}
		return this.getFreezePopup()
	}
}

export default withTranslation("translations")(CrossingFreezePopup);
