
import {
	ERC20ContractParamsType,
	WrappedTokenType,
	WrappedTokensStatType,
	ChainParamsType,
	OriginalTokenType,
	_Asset,
	SAFTTariff,
} from '../models/BlockchainAdapter';
import {
	AdvancedLoaderStageType,
	AdvancedLoaderType
} from './reducer';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

// ---------- NAVIGATION ----------
export const gotoMain = () => {
	return {
		type: 'GOTO_MAIN',
	}
}
export const gotoPreview = () => {
	return {
		type: 'GOTO_PREVIEW',
	}
}
export const gotoList = () => {
	return {
		type: 'GOTO_LIST',
	}
}
export const setLoading = (payload: { msg: string }) => {
	return {
		type: 'SET_LOADING',
		payload,
	}
}
export const unsetLoading = () => {
	return {
		type: 'UNSET_LOADING',
	}
}
export const createAdvancedLoading = (payload: AdvancedLoaderType) => {
	return {
		type: 'CREATE_ADVANCED_LOADING',
		payload,
	}
}
export const clearAdvancedLoading = () => {
	return {
		type: 'CLEAR_ADVANCED_LOADING',
	}
}
export const updateStepAdvancedLoading = (payload: AdvancedLoaderStageType) => {
	return {
		type: 'UPDATE_STEP_ADVANCED_LOADING',
		payload,
	}
}
export const setError = (payload: {
	title?: string,
	text  : string | Array<string>,
	buttons: undefined | Array<{
		text: string,
		clickFunc: Function,
	}>,
	links: undefined | Array<{
		text: string,
		url : string,
	}>
}) => {
	return {
		type: 'SET_ERROR',
		payload,
	}
}
export const clearError = () => {
	return {
		type: 'CLEAR_ERROR',
	}
}
export const setInfo = (payload: {
	title?: string,
	text  : string | Array<string>,
	buttons: undefined | Array<{
		text: string,
		clickFunc: Function,
	}>,
	links: undefined | Array<{
		text: string,
		url : string,
	}>
}) => {
	return {
		type: 'SET_INFO',
		payload,
	}
}
export const clearInfo = () => {
	return {
		type: 'CLEAR_INFO',
	}
}
export const setSuccess = (
	payload: {
		token          : WrappedTokenType | undefined,
		icon           : string,
		text           : string,
		transactionHash: string,
	}
) => {
	return {
		type: 'SET_SUCCESS',
		payload,
	}
}
export const clearSuccess = () => {
	return {
		type: 'CLEAR_SUCCESS',
	}
}
export const resetAppData = () => {
	return {
		type: 'RESET_APP_DATA',
	}
}
export const gotoListRequest = () => {
	return {
		type: 'GOTO_LIST_REQUEST',
	}
}
export const gotoListResolve = () => {
	return {
		type: 'GOTO_LIST_RESOLVE',
	}
}
// ---------- END NAVIGATION ----------

// ---------- CONNECTION ----------
export const metamaskConnectionSuccess = (payload: { address: string }) => {
	return {
		type: 'METAMASK_CONNECTION_SUCCESS',
		payload,
	}
}
export const metamaskConnectionNotInstalled = () => {
	return {
		type: 'METAMASK_CONNECTION_NOT_INSTALLED',
	}
}
export const metamaskConnectionRejected = () => {
	return {
		type: 'METAMASK_CONNECTION_REJECTED',
	}
}
export const metamaskSetChainParams = (
	payload: {
		chainId?             : number | undefined,
		chainName?           : string,
		chainColorCode?      : string,
		chainRPCUrl?         : string,
		networkTokenTicket?  : string,
		EIPPrefix?           : string,
		networkTokenDecimals?: number,
		networkTokenIcon?    : string,
		networkIcon?         : string,
		isTestNetwork?       : Boolean,
		explorerBaseUrl?     : string,
		marketplaceUrl?      : string,
		explorerName?        : string,
		transferRoyaltyModel?: string,
		minterContract?      : string,
		wrapperContract?     : string,
		checkerContract?     : string,
		WNFTStorageContracts?: Array<{ address: string, standart: string }>,
		supportedERC20Tokens?: Array<string>,
		maxCollaterals?      : number,
		crossing?            : {
			keeper      : string,
			spawner     : string,
			targetChains: Array<{ targetChainId: number, address: string }>
		},
		nftMinterContract?   : string,
		batchWorker?         : string,
		subscriptionAgent?   : string,
	}
) => {
	return {
		type: 'METAMASK_SET_CHAIN_PARAMS',
		payload,
	}
}
export const metamaskSetAvailableChains = ( payload: Array<ChainParamsType> ) => {
	return {
		type: 'METAMASK_SET_AVAILABLE_CHAINS',
		payload,
	}
}
export const setAuthMethod = ( payload: string ) => {
	return {
		type: 'SET_AUTH_METHOD',
		payload,
	}
}
export const unsetAuthMethod = () => {
	return {
		type: 'UNSET_AUTH_METHOD',
	}
}
export const requestChain = ( payload: number | undefined ) => {
	return {
		type: 'REQUEST_CHAIN',
		payload
	}
}
export const addWNFTStorage = ( payload: { address: string, standart: string } ) => {
	return {
		type: 'ADD_WNFT_STORAGE',
		payload
	}
}
// ---------- END CONNECTION ----------

// ---------- NATIVE TOKEN ----------
export const updateNativeBalance = ( payload: { balance: BigNumber } ) => {
	return {
		type: 'UPDATE_NATIVE_BALANCE',
		payload,
	}
}
// ---------- END NATIVE TOKEN ----------

// ---------- WRAPPER CONTRACT ----------
export const updateTransferAllowance = ( payload: {
	wrapperAddress      : string,
	transferModelAddress: string,
	erc20TokenAddress   : string,
	allowance           : BigNumber,
} ) => {
	return {
		type: 'UPDATE_TRANSFER_ALLOWANCE',
		payload,
	}
}
export const erc20TechContractParamsUpdate = (payload: ERC20ContractParamsType) => {
	return {
		type: 'ERC20_TECH_CONTRACT_PARAMS_UPDATE',
		payload,
	}
}
export const erc20OtherTechContractParamsUpdate = (payload: ERC20ContractParamsType) => {
	return {
		type: 'ERC20_OTHER_TECH_CONTRACT_PARAMS_UPDATE',
		payload,
	}
}
export const erc20CollateralContractParamsUpdate = (payload: ERC20ContractParamsType) => {
	return {
		type: 'ERC20_COLATERAL_CONTRACT_PARAMS_UPDATE',
		payload,
	}
}
export const erc20BatchCollateralContractParamsUpdate = (payload: ERC20ContractParamsType) => {
	return {
		type: 'ERC20_BATCH_COLATERAL_CONTRACT_PARAMS_UPDATE',
		payload,
	}
}
// ---------- END WRAPPER CONTRACT ----------

// ---------- ERC721 FETCH ----------
export const wrappedTokensAdd = (payload: WrappedTokenType) => {
	return {
		type: 'WRAPPED_TOKENS_ADD',
		payload,
	}
}
export const wrappedTokensRemove = (payload: WrappedTokenType) => {
	return {
		type: 'WRAPPED_TOKENS_REMOVE',
		payload,
	}
}
export const wrappedTokensClear = () => {
	return {
		type: 'WRAPPED_TOKENS_CLEAR',
	}
}
export const incompleteTokensAdd = (payload: OriginalTokenType) => {
	return {
		type: 'INCOMPLETE_TOKENS_ADD',
		payload,
	}
}
export const incompleteTokensRemove = (payload: OriginalTokenType) => {
	return {
		type: 'INCOMPLETE_TOKENS_REMOVE',
		payload,
	}
}
export const tokenUpdate = (payload: WrappedTokenType) => {
	return {
		type: 'TOKEN_UPDATE',
		payload,
	}
}
export const tokenPreviewClear = () => {
	return {
		type: 'TOKEN_PREVIEW_CLEAR',
	}
}
export const wrappedStatsUpdate = (payload: WrappedTokensStatType) => {
	return {
		type: 'WRAPPED_STATS_UPDATE',
		payload,
	}
}
export const discoveredTokensAdd = (payload: OriginalTokenType) => {
	return {
		type: 'DISCOVERED_TOKENS_ADD',
		payload,
	}
}
export const discoveredTokensRemove = (payload: OriginalTokenType) => {
	return {
		type: 'DISCOVERED_TOKENS_REMOVE',
		payload,
	}
}
export const discoveredTokensClear = () => {
	return {
		type: 'DISCOVERED_TOKENS_CLEAR',
	}
}
export const ignoredTokensAdd = (payload: { contractAddress: string, tokenId: string }) => {
	return {
		type: 'IGNORED_TOKENS_ADD',
		payload,
	}
}
export const setTokensLoading = () => {
	return {
		type: 'SET_TOKENS_LOADING',
	}
}
export const unsetTokensLoading = () => {
	return {
		type: 'UNSET_TOKENS_LOADING',
	}
}
// ---------- END ERC721 FETCH ----------

// ---------- WAITING TOKEN ----------
export const waitingTokensAdd = (payload: { token: OriginalTokenType, msg: string }) => {
	return {
		type: 'WAITING_TOKENS_ADD',
		payload,
	}
}
export const waitingTokensRemove = (payload: OriginalTokenType) => {
	return {
		type: 'WAITING_TOKENS_REMOVE',
		payload,
	}
}
// ---------- END WAITING TOKEN ----------

// ---------- WL BL ----------
export const collateralWhitelistAdd = (payload: { list: Array<_Asset> }) => {
	return {
		type: 'COLLATERAL_WHITELIST_ADD',
		payload,
	}
}
export const originalTokensBlacklistAdd = (payload: { list: Array<_Asset> }) => {
	return {
		type: 'ORIGINAL_TOKEN_BLACKLIST_ADD',
		payload,
	}
}
// ---------- END WL BL ----------

// ---------- SAFT ----------
export const saftUpdateSubscription = (payload: {
	subscription: undefined | {
		timeRemaining: BigNumber,
		txRemaining: number,
	}
}) => {
	return {
		type: 'SAFT_UPDATE_SUBSCRIPTION',
		payload,
	}
}
export const saftSetTariffs = (payload: { tariffs: Array<SAFTTariff> }) => {
	return {
		type: 'SAFT_SET_TARIFFS',
		payload,
	}
}
// ---------- END WL BL ----------
