
import Web3         from 'web3';
import { Contract } from "web3-eth-contract";

import MetamaskAdapter from './metamaskadapter';

import {
	OriginalTokenType,
	_AssetType
} from './_types';

import { getABI, processSwarmUrl } from '../_utils';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type CrossingDispatcherPropsType = {
	web3                 : Web3,
	metamaskAdapter      : MetamaskAdapter,
	store                : any,
	keeperContractAddress: string,
	spawner              : string,
	targetChains         : Array<{ targetChainId: number, address: string, icon?: string, name?: string }>,
}

export default class CrossingDispatcher {

	web3                 : Web3;
	metamaskAdapter      : MetamaskAdapter;
	store                : any;
	keeperContractAddress: string;
	keeperContract       : Contract;
	spawnerContract      : Contract;
	spawner              : string;
	targetChains         : Array<{ targetChainId: number, address: string, icon?: string, name?: string }>;

	constructor(props: CrossingDispatcherPropsType) {
		this.web3                  = props.web3;
		this.metamaskAdapter       = props.metamaskAdapter;
		this.store                 = props.store;
		this.keeperContractAddress = props.keeperContractAddress;
		this.spawner               = props.spawner;
		this.targetChains          = props.targetChains;

		let keeperContractABI;
		try {
			keeperContractABI = getABI(this.metamaskAdapter.chainId || 0, this.keeperContractAddress, 'keeper');
		} catch(e) {
			console.log(`Cannot load ${this.keeperContractAddress} wrapper abi:`, e);
			throw new Error(`Cannot load keeper abi`);
		}
		this.keeperContract = new this.web3.eth.Contract(keeperContractABI, this.keeperContractAddress);

		let spawnerContractABI;
		try {
			spawnerContractABI = getABI(this.metamaskAdapter.chainId || 0, this.spawner, 'spawner');
		} catch(e) {
			console.log(`Cannot load ${this.spawner} wrapper abi:`, e);
			throw new Error(`Cannot load spawner abi`);
		}
		this.spawnerContract = new this.web3.eth.Contract(spawnerContractABI, this.spawner);
	}

	async getNFTTokenById(tokenId: string): Promise<OriginalTokenType> {
		let tokenUrl;
		let tokenUrlRaw;
		tokenUrlRaw = await this.spawnerContract.methods.tokenURI(tokenId).call();
		tokenUrl = processSwarmUrl(tokenUrlRaw);

		let tokenUrlRes;
		let tokenUrlText;
		let tokenUrlJSON;
		try {
			tokenUrlRes  = await fetch(tokenUrl);
			tokenUrlText = await tokenUrlRes.text();
			tokenUrlJSON = JSON.parse(tokenUrlText);
		} catch(e) {
			console.log('Cannot fetch tokenUrl', e);
		}

		let image = '';
		let imageRaw = '';
		if ( tokenUrlJSON.image     ) { image = processSwarmUrl(tokenUrlJSON.image    ); imageRaw = tokenUrlJSON.image    ; }
		if ( tokenUrlJSON.image_url ) { image = processSwarmUrl(tokenUrlJSON.image_url); imageRaw = tokenUrlJSON.image_url; }

		return {
			assetType: _AssetType.ERC721,
			owner: this.metamaskAdapter.userAddress,
			chainId: this.metamaskAdapter.chainId || 0,
			contractAddress: this.spawner,
			tokenId: tokenId,
			description: tokenUrlJSON.description || '',
			image,
			imageRaw,
			name: tokenUrlJSON.name || '',
			tokenUrl: tokenUrl,
			tokenUrlRaw: tokenUrlRaw,
			tokenUrlRawJSON: tokenUrlText,
		};
	}

	async getNFTs() {
		const output = [];

		const balance = await this.spawnerContract.methods.balanceOf( this.metamaskAdapter.userAddress ).call();

		for (let idx = balance - 1; idx >= 0; idx--) {
			const contractTokenId = await this.spawnerContract.methods.tokenOfOwnerByIndex(this.metamaskAdapter.userAddress, idx).call();
			const token = await this.getNFTTokenById(contractTokenId);
			output.push(token);
		};

		return output;
	}

	async freezeToken(contractAddress: string, tokenId: string, targetChain: number, secretHash: string) {
		const tx = this.keeperContract.methods.freeze({ contractAddress, tokenId }, targetChain, secretHash);

		try {
			tx.estimateGas({ from: this.metamaskAdapter.userAddress });
		} catch(e) {
			throw e;
		}

		return tx.send({ from: this.metamaskAdapter.userAddress })
	}
	async unfreezeToken(params: {
		targetContractAddress: string,
		targetTokenId: string,
		secretHash: string,
		signature: string
	}) {
		const tx = this.keeperContract.methods.unFreeze(
			params.secretHash,
			params.targetContractAddress,
			params.targetTokenId,
			params.signature
		);

		try {
			tx.estimateGas({ from: this.metamaskAdapter.userAddress });
		} catch(e) {
			throw e;
		}

		return tx.send({ from: this.metamaskAdapter.userAddress })
	}

	async burnToken(tokenId: string, userAddress: string) {
		const tx = this.spawnerContract.methods.burn(tokenId);

		try {
			tx.estimateGas({ from: userAddress });
		} catch(e) {
			throw e;
		}

		return tx.send({ from: userAddress });
	}
	async mintNewToken(params: {
		targetContract: string,
		targetTokenId: string,
		oracleSignature: string,
		userAddress: string
	}) {
		const tx = this.spawnerContract.methods.mint(params.targetTokenId, params.oracleSignature);

		try {
			tx.estimateGas({ from: params.userAddress });
		} catch(e) {
			throw e;
		}

		return tx.send({ from: params.userAddress });
	}

}