
import React from 'react';
import Tippy from '@tippyjs/react';
import {
	MetamaskAdapter,
	ERC20Contract,
	SAFTTariff,
	SubscriptionDispatcher,
} from '../../models/BlockchainAdapter';

import { withTranslation, } from "react-i18next";

import {
	compactString,
	tokenToFloat,
} from '../../models/_utils';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type SubscriptionRendererProps = {
	t                      : any,
	subscriptionDispatcher : SubscriptionDispatcher;
}
export type SubscriptionRendererState = {
	balancesNative   : BigNumber,
	decimalsNative   : number,
	symbolNative     : string,
	iconNative       : string,
	EIPPrefix        : string,
	chainId          : number,
	metamaskLogged   : boolean,
	explorerBaseUrl  : string,

	buyFor       : string,
	paymentTokens: Array<ERC20Contract>,

	subscriptionRemainings: undefined | {
		timeRemaining: BigNumber,
		txRemaining: number,
	};
	subscriptionTariffs             : Array<SAFTTariff>,
	subscriptionPopupOpened         : boolean,
	recieverEdited                  : boolean,

	subscriptionPaymentTypeSelected : string,
	subscriptionTypeSelected        : string,
	subscriptionPlanSelected        : string,
	subscriptionTariffSelectedIdx   : number,
	subscriptionTokenSelected       : string,

	paymentTypeListOpened     : boolean,
	subscriptionTypeListOpened: boolean,
	subscriptionPlanListOpened: boolean,
	paymentTokenListOpened    : boolean,

	priceWithFee: BigNumber | undefined,
}

class SubscriptionRenderer extends React.Component<SubscriptionRendererProps, SubscriptionRendererState> {

	store                  : any;
	metamaskAdapter        : MetamaskAdapter;
	unsubscribe!           : Function;
	t                      : any;
	subscriptionDispatcher : SubscriptionDispatcher;

	TX_names: { singular: string, plural: string };

	constructor(props: SubscriptionRendererProps) {
		super(props);

		this.store                   = props.subscriptionDispatcher.store;
		this.metamaskAdapter         = props.subscriptionDispatcher.metamaskAdapter;
		this.t                       = props.t;
		this.subscriptionDispatcher  = props.subscriptionDispatcher;
		const subscriptionTariffs    = this.subscriptionDispatcher.availableTariffs;
		const paymentTokens          = this.subscriptionDispatcher.paymentTokens;
		const subscriptionRemainings = this.subscriptionDispatcher.subscriptionRemainings;

		this.TX_names = { singular: 'wrap', plural: 'wraps' }

		this.state = {
			balancesNative   : this.store.getState().account.balance,
			decimalsNative   : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative     : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative       : this.store.getState().metamaskAdapter.networkTokenIcon,
			EIPPrefix        : this.store.getState().metamaskAdapter.EIPPrefix,

			chainId          : this.store.getState().metamaskAdapter.chainId,
			metamaskLogged   : this.store.getState().metamaskAdapter.logged,
			explorerBaseUrl  : this.store.getState().metamaskAdapter.explorerBaseUrl,

			buyFor: '',
			subscriptionRemainings,
			paymentTokens,
			subscriptionTariffs,
			subscriptionPaymentTypeSelected: '',
			subscriptionTypeSelected: '',
			subscriptionPlanSelected: '',
			subscriptionTariffSelectedIdx: -1,
			subscriptionTokenSelected: '',
			recieverEdited          : false,

			subscriptionPopupOpened: false,

			paymentTypeListOpened: false,
			subscriptionTypeListOpened: false,
			subscriptionPlanListOpened: false,
			paymentTokenListOpened: false,

			priceWithFee: undefined
		}
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {
			this.setState({
				balancesNative        : this.store.getState().account.balance,
				decimalsNative        : this.store.getState().metamaskAdapter.networkTokenDecimals,
				iconNative            : this.store.getState().metamaskAdapter.networkTokenIcon,
				symbolNative          : this.store.getState().metamaskAdapter.networkTokenTicket,
				EIPPrefix             : this.store.getState().metamaskAdapter.EIPPrefix,
				chainId               : this.store.getState().metamaskAdapter.chainId,
				metamaskLogged        : this.store.getState().metamaskAdapter.logged,
				explorerBaseUrl       : this.store.getState().metamaskAdapter.explorerBaseUrl,

				subscriptionTariffs   : this.subscriptionDispatcher.availableTariffs,
				paymentTokens         : this.subscriptionDispatcher.paymentTokens,
				subscriptionRemainings: this.subscriptionDispatcher.subscriptionRemainings,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	getSubscriptionBlock() {
		if ( this.subscriptionDispatcher.isLocked() ) {
			return (
				<div className="bw-subscib">
					<div className="d-inline-block mr-2 my-3">To use the Batch Wrap you need to  </div>
					<button
						className="btn btn-outline"
						onClick={() => {
							this.setState({
								subscriptionPopupOpened      : true,
								subscriptionTariffs          : this.subscriptionDispatcher.availableTariffs,
								paymentTokens                : this.subscriptionDispatcher.paymentTokens,
								subscriptionTariffSelectedIdx: -1,
								subscriptionTokenSelected     : '',
							});
						}}
					>Subscribe</button>
				</div>
			)
		}

		if ( !this.state.subscriptionRemainings ) {
			return null;
		}

		if ( parseInt(`${this.state.subscriptionRemainings.txRemaining}`) !== 0 ) {
			return (
				<div className="bw-subscib">
					You have <span className="days">{ this.state.subscriptionRemainings.txRemaining } { this.state.subscriptionRemainings.txRemaining === 1 ? 'wrap' : 'wraps' }</span> left
					<button
						className="btn btn-outline ml-3"
						onClick={() => {
							this.setState({
								subscriptionPopupOpened      : true,
								subscriptionTariffs          : this.subscriptionDispatcher.availableTariffs,
								paymentTokens                : this.subscriptionDispatcher.paymentTokens,
								subscriptionTariffSelectedIdx: -1,
								subscriptionTokenSelected     : '',
							});
						}}
					>+ Subscription</button>
				</div>
			)
		}

		const now = new BigNumber(new Date().getTime()).dividedBy(1000);
		const diff = this.state.subscriptionRemainings.timeRemaining.minus(now);

		return (
			<div className="bw-subscib">
				Your subscription expires in <span className="days">{ this.convertTimeToStr(diff) }</span>
				<button
					className="btn btn-outline ml-3"
					onClick={() => {
						this.setState({
							subscriptionPopupOpened      : true,
							subscriptionTariffs          : this.subscriptionDispatcher.availableTariffs,
							paymentTokens                : this.subscriptionDispatcher.paymentTokens,
							subscriptionTariffSelectedIdx: -1,
							subscriptionTokenSelected     : '',
						});
					}}
				>+ Subscription</button>
			</div>
		)
	}
	getSubscriptionERC20Balance() {

		const foundToken = this.state.paymentTokens.find((item) => { return item.contractAddress.toLowerCase() === this.state.subscriptionTokenSelected.toLowerCase() });
		if ( !foundToken || !foundToken.erc20Params ) { return null; }

		return (
			<div className="c-add__max">
				<span>Max: </span>
				<button>{ tokenToFloat(foundToken.erc20Params.balance, foundToken.erc20Params.decimals || 18).toString() }</button>
				<span className="ml-2">Allowance: </span>
				<button>{ tokenToFloat(foundToken.erc20Params.allowance, foundToken.erc20Params.decimals || 18).toString() }</button>
			</div>
		)
	}
	convertTimeToStr(diff: BigNumber) {

		// const monthsRemaining = diff.dividedToIntegerBy(3600).dividedToIntegerBy(24).dividedToIntegerBy(30);
		// if ( monthsRemaining.gt(0) ) {
		// 	if ( monthsRemaining.eq( 1 ) ) { return `${monthsRemaining.toFixed(0)} ${this.t('month')}` }
		// 	return `${monthsRemaining.toFixed(0)} ${this.t('months')}`
		// }

		const daysRemaining = diff.dividedToIntegerBy(3600).dividedToIntegerBy(24);
		if ( daysRemaining.gt(0) ) {
			if ( daysRemaining.eq( 1 ) ) { return `${daysRemaining.toFixed(0)} ${this.t('day')}` }
			return `${daysRemaining.toFixed(0)} ${this.t('days')}`
		}

		const hoursRemaining = diff.dividedToIntegerBy(3600);
		if ( hoursRemaining.gt(0) ) {
			if ( hoursRemaining.eq( 1 ) ) { return `${hoursRemaining.toFixed(0)} ${this.t('hour')}` }
			return `${hoursRemaining.toFixed(0)} ${this.t('hours')}`
		}

		const minutesRemaining = diff.dividedToIntegerBy(60);
		if ( minutesRemaining.gt(0) ) {
			if ( minutesRemaining.eq( 1 ) ) { return `${minutesRemaining.toFixed(0)} ${this.t('minute')}` }
			return `${minutesRemaining.toFixed(0)} ${this.t('minutes')}`
		}

		if ( diff.eq( 1 ) ) { return `${diff.toFixed(0)} ${this.t('second')}` }
		return `${diff.toFixed(0)} ${this.t('seconds')}`

	}
	getPaymentToken(address: string) {
		if ( address === '0x0000000000000000000000000000000000000000' ) {
			return {
				name: this.state.symbolNative,
				symbol: this.state.symbolNative,
				decimals: this.state.decimalsNative,
				balance: this.state.balancesNative,
				allowance: new BigNumber(0),
				icon: this.state.iconNative,
				permissions: {}
			}
		}
		const foundToken = this.state.paymentTokens.find((item) => { return item.contractAddress.toLowerCase() === address.toLowerCase() });
		if ( !foundToken ) {
			return undefined;
		}

		return foundToken.erc20Params;
	}
	getTicketDetails() {
		if ( this.state.subscriptionTariffSelectedIdx === -1 ) { return null; }

		const foundTicket = this.state.subscriptionTariffs.find((item) => { return item.idx === this.state.subscriptionTariffSelectedIdx });
		if ( !foundTicket ) { return null; }

		let fullPriceStr = undefined;
		const foundPayOption = foundTicket.payWith.find((item) => { return this.state.subscriptionTokenSelected.toLowerCase() === item.paymentToken.toLowerCase() })
		if ( foundPayOption ) {
			const payToken = this.getPaymentToken(foundPayOption.paymentToken);
			const symbolParsed = payToken ? payToken.symbol : foundPayOption.paymentToken;

			const decimalsParsed = payToken ? payToken.decimals : 18;
			if ( this.state.priceWithFee ) {
				fullPriceStr = `${tokenToFloat(this.state.priceWithFee, decimalsParsed)} ${symbolParsed}`
			}
		}

		return (
			<React.Fragment>
			{
				!foundTicket.subscription.timelockPeriod.eq(0) ? (
					<p className="mt-0">
						Lock time:
						<br />
						<b className="text-green">
							{ this.convertTimeToStr(foundTicket.subscription.timelockPeriod) }
							<Tippy
								content={ this.t('Time to lock your collateral tokens.') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span className="i-tip ml-1"></span>
							</Tippy>
						</b>
					</p>
				) : null
			}
			{
				!foundTicket.subscription.ticketValidPeriod.eq(0) ? (
					<p className="mt-0">
						Subscription time:
						<br />
						<b className="text-green">
							{ this.convertTimeToStr(foundTicket.subscription.ticketValidPeriod) }
							<Tippy
								content={ this.t('The period of time during which the subscription is valid. There is no limit on the number of times the service can be used.') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span className="i-tip ml-1"></span>
							</Tippy>
						</b>
					</p>
				) : null
			}
			{
				parseInt(`${foundTicket.subscription.counter}`) !== 0 ? (
					<p className="mt-0">
						{ this.TX_names.plural.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) } count:
						<b className="text-green">
							{ ' ' }
							{ foundTicket.subscription.counter }
							<Tippy
								content={ this.t('The number of calls to the service according to the price plan. There is no time limit.') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span className="i-tip ml-1"></span>
							</Tippy>
						</b>
					</p>
				) : null
			}
			{
				fullPriceStr ? (
					<p className="mt-0">
						Price:
						<br />
						<b className="text-green">
							{ fullPriceStr }
							<Tippy
								content={ this.state.subscriptionPaymentTypeSelected === 'timelock' ? 'Number of collateral tokens that will be locked.' : 'Price with fee' }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span className="i-tip ml-1"></span>
							</Tippy>
						</b>
					</p>
				) : null
			}
			</React.Fragment>
		)
	}
	getPaymentTypeSelect() {
		let selectedTitle = 'Choose payment type';
		if ( this.state.subscriptionPaymentTypeSelected === 'timelock' ) { selectedTitle = 'Token deposit';  }
		if ( this.state.subscriptionPaymentTypeSelected === 'buy'      ) { selectedTitle = 'Direct payment'; }

		return (
			<div className="input-group">
				<label className="input-label">Payment type</label>
				<div className="select-custom select-token">
					<div
						className={`input-control ${this.state.paymentTypeListOpened ? 'active' : ''}`}
						onClick={() => {
							this.setState({
								paymentTypeListOpened: !this.state.paymentTypeListOpened,

								subscriptionTypeListOpened: false,
								subscriptionPlanListOpened: false,
								paymentTokenListOpened: false,
							})
						}}
					>
						<span
							className={`${this.state.subscriptionPaymentTypeSelected === '' ? 'empty' : ''}`}
						>{ selectedTitle }</span>
					</div>
					{
						this.state.paymentTypeListOpened ? (
							<ul className="options-list">
								<li className="option">
									<div
										className="option-token"
										onClick={() => {
											this.forceUpdate();
											this.setState({
												subscriptionPaymentTypeSelected: '',
												subscriptionTypeSelected: '',
												subscriptionPlanSelected: '',
												subscriptionTariffSelectedIdx: -1,
												subscriptionTokenSelected: '',
												priceWithFee: undefined,

												paymentTypeListOpened: false,
											});
										}}
									>
										<span>Choose payment type</span>
									</div>
								</li>
								<li className="option">
									<div
										className="option-token"
										onClick={() => {
											this.forceUpdate();
											this.setState({
												subscriptionPaymentTypeSelected: 'timelock',
												subscriptionTypeSelected: '',
												subscriptionPlanSelected: '',
												subscriptionTariffSelectedIdx: -1,
												subscriptionTokenSelected: '',
												priceWithFee: undefined,

												paymentTypeListOpened: false,
											});
										}}
									>
										<span>Token deposit</span>
									</div>
								</li>
								<li className="option">
									<div
										className="option-token"
										onClick={() => {
											this.forceUpdate();
											this.setState({
												subscriptionPaymentTypeSelected: 'buy',
												subscriptionTypeSelected: '',
												subscriptionPlanSelected: '',
												subscriptionTariffSelectedIdx: -1,
												subscriptionTokenSelected: '',
												priceWithFee: undefined,

												paymentTypeListOpened: false,
											});
										}}
									>
										<span>Direct payment</span>
									</div>
								</li>
							</ul>
						) : null
					}
				</div>
			</div>
		)
	}
	getSubscriptionTypeSelect() {
		if ( this.state.subscriptionPaymentTypeSelected === '' ) { return null; }

		let selectedTitle = 'Choose subscription type';
		if ( this.state.subscriptionTypeSelected === 'time' ) { selectedTitle = 'Period of services';  }
		if ( this.state.subscriptionTypeSelected === 'txs'  ) { selectedTitle = 'Volume of services'; }

		const subscriptionsFilteredTime = this.state.subscriptionTariffs
			.filter((item) => {
				if ( this.state.subscriptionPaymentTypeSelected === 'timelock' ) {
					return !item.subscription.timelockPeriod.eq(0)
				} else {
					return item.subscription.timelockPeriod.eq(0)
				}
			})
			.filter((item) => {
				return parseInt(`${item.subscription.counter}`) === 0
			});
		const subscriptionsFilteredTX = this.state.subscriptionTariffs
			.filter((item) => {
				if ( this.state.subscriptionPaymentTypeSelected === 'timelock' ) {
					return !item.subscription.timelockPeriod.eq(0)
				} else {
					return item.subscription.timelockPeriod.eq(0)
				}
			})
			.filter((item) => {
				return parseInt(`${item.subscription.counter}`) !== 0
			});

		return (
			<div className="input-group">
				<label className="input-label">Subscription type</label>
				<div className="select-custom select-token">
					<div
						className={`input-control ${this.state.subscriptionTypeListOpened ? 'active' : ''}`}
						onClick={() => {
							this.setState({
								subscriptionTypeListOpened: !this.state.subscriptionTypeListOpened,

								paymentTypeListOpened: false,
								subscriptionPlanListOpened: false,
								paymentTokenListOpened: false,
							})
						}}
					>
						<span
							className={`${this.state.subscriptionPaymentTypeSelected === '' ? 'empty' : ''}`}
						>{ selectedTitle }</span>
					</div>
					{
						this.state.subscriptionTypeListOpened ? (
							<ul className="options-list">
								<li
									className="option"
									onClick={() => {
										this.forceUpdate();
										this.setState({
											subscriptionTypeSelected: '',
											subscriptionPlanSelected: '',
											subscriptionTariffSelectedIdx: -1,
											subscriptionTokenSelected: '',
											priceWithFee: undefined,

											subscriptionTypeListOpened: false,
										});
									}}
								>
									<div className="option-token">
										<span>Choose subscription type</span>
									</div>
								</li>
								{
									subscriptionsFilteredTime.length ? (
										<li
											className="option"
											onClick={() => {
												this.forceUpdate();
												this.setState({
													subscriptionTypeSelected: 'time',
													subscriptionPlanSelected: '',
													subscriptionTariffSelectedIdx: -1,
													subscriptionTokenSelected: '',
													priceWithFee: undefined,

													subscriptionTypeListOpened: false,
												});
											}}
										>
											<div className="option-token">
												<span>Period of services</span>
											</div>
										</li>
									) : null
								}
								{
									subscriptionsFilteredTX.length ? (
										<li
											className="option"
											onClick={() => {
												this.forceUpdate();
												this.setState({
													subscriptionTypeSelected: 'txs',
													subscriptionPlanSelected: '',
													subscriptionTariffSelectedIdx: -1,
													subscriptionTokenSelected: '',
													priceWithFee: undefined,

													subscriptionTypeListOpened: false,
												});
											}}
										>
											<div className="option-token">
												<span>Volume of services</span>
											</div>
										</li>
									) : null
								}
							</ul>
						) : null
					}
				</div>
			</div>
		)
	}
	getSubscriptionPlanSelect() {
		if ( this.state.subscriptionTypeSelected === '' ) { return null; }

		const subscriptionsFiltered = this.state.subscriptionTariffs
			.filter((item) => {
				if ( this.state.subscriptionPaymentTypeSelected === 'timelock' ) {
					return !item.subscription.timelockPeriod.eq(0)
				} else {
					return item.subscription.timelockPeriod.eq(0)
				}
			})
			.filter((item) => {
				if ( this.state.subscriptionTypeSelected === 'time' ) {
					return parseInt(`${item.subscription.counter}`) === 0
				} else {
					return parseInt(`${item.subscription.counter}`) !== 0
				}
			})
			.sort((item, prev) => {
				const itemCount = parseInt(`${item.subscription.counter}`);
				const prevCount = parseInt(`${prev.subscription.counter}`);
				if ( itemCount > 0 && prevCount > 0 ) {
					return itemCount - prevCount
				}
				if ( item.subscription.ticketValidPeriod.gt(0) && prev.subscription.ticketValidPeriod.gt(0) ) {
					return item.subscription.ticketValidPeriod.plus(-prev.subscription.ticketValidPeriod).toNumber()
				}

				return 0;
			})

		let selectedTitle = 'Choose subscription plan';
		const selectedItem = subscriptionsFiltered.find((item) => { return `${item.idx}` === `${this.state.subscriptionTariffSelectedIdx}` });
		if ( selectedItem ) {
			if ( parseInt(`${selectedItem.subscription.counter}`) !== 0 ) {
				selectedTitle = `${ selectedItem.subscription.counter } ${ selectedItem.subscription.counter === 1 ? this.TX_names.singular : this.TX_names.plural }`;
			} else {
				selectedTitle = `${this.convertTimeToStr(selectedItem.subscription.ticketValidPeriod) }`;
			}
		}

		return (
			<div className="input-group">
				<label className="input-label">Subscription plan</label>
				<div className="select-custom select-token">
					<div
						className={`input-control ${this.state.subscriptionPlanListOpened ? 'active' : ''}`}
						onClick={() => {
							this.setState({
								subscriptionPlanListOpened: !this.state.subscriptionPlanListOpened,
								paymentTypeListOpened: false,
								subscriptionTypeListOpened: false,
								paymentTokenListOpened: false,
							})
						}}
					>
						<div className="option-token">
							<span
								className={`${this.state.subscriptionTariffSelectedIdx === -1 ? 'empty' : ''}`}
							>
								{ selectedTitle }
							</span>
							{
								selectedItem?.payWith.find((iitem) => { return iitem.paymentAmount.eq(0) }) ? (
									<span className="badge badge-green mr-0 ml-auto"><span>free</span></span>
								) : null
							}
						</div>
					</div>
					{
						this.state.subscriptionPlanListOpened ? (
							<ul className="options-list">
								<li
									className="option"
									onClick={() => {
										this.forceUpdate();
										this.setState({
											subscriptionTariffSelectedIdx: -1,

											subscriptionPlanListOpened: false,
										});
									}}
								>
									<div className="option-token">
										<span>Choose subscription plan</span>
									</div>
								</li>
								{
									subscriptionsFiltered.map((item) => {

										const foundFreePlan = item.payWith.find((iitem) => { return iitem.paymentAmount.eq(0) });

										if ( parseInt(`${item.subscription.counter}`) !== 0 ) {
											return (
												<li
													className="option"
													onClick={() => {
														this.forceUpdate();
														if ( this.state.subscriptionTokenSelected !== '' ) {
															this.props.subscriptionDispatcher.getPriceWithFee(parseInt(`${item.idx}`),  this.state.subscriptionTokenSelected)
															.then((data) => {
																this.setState({ priceWithFee: data })
															});
														}
														this.setState({
															subscriptionTariffSelectedIdx: item.idx,

															subscriptionPlanListOpened: false,
														});
													}}
												>
													<div className="option-token">
														<span>{ item.subscription.counter } { item.subscription.counter === 1 ? this.TX_names.singular : this.TX_names.plural }</span>
														{
															foundFreePlan ? (
																<span className="badge badge-green mr-0 ml-auto"><span>free</span></span>
															) : null
														}
													</div>
												</li>
											)
										} else {
											return (
												<li
													className="option"
													onClick={() => {
														this.forceUpdate();
														if ( this.state.subscriptionTokenSelected !== '' ) {
															this.props.subscriptionDispatcher.getPriceWithFee(parseInt(`${item.idx}`),  this.state.subscriptionTokenSelected)
															.then((data) => {
																this.setState({ priceWithFee: data })
															});
														}
														this.setState({
															subscriptionTariffSelectedIdx: item.idx,

															subscriptionPlanListOpened: false,
														});
													}}
												>
													<div className="option-token">
														<span>{ this.convertTimeToStr(item.subscription.ticketValidPeriod) }</span>
														{
															foundFreePlan ? (
																<span className="badge badge-green mr-0 ml-auto"><span>free</span></span>
															) : null
														}
													</div>
												</li>
											)
										}
									})
								}
							</ul>
						) : null
					}
				</div>
			</div>
		)
	}
	getSubscriptionTokenSelect() {
		if ( this.state.subscriptionTariffSelectedIdx === -1 ) { return null; }

		const subscriptionsFound = this.state.subscriptionTariffs
			.filter((item) => {
				if ( this.state.subscriptionPaymentTypeSelected === 'timelock' ) {
					return !item.subscription.timelockPeriod.eq(0)
				} else {
					return item.subscription.timelockPeriod.eq(0)
				}
			})
			.filter((item) => {
				if ( this.state.subscriptionTypeSelected === 'time' ) {
					return parseInt(`${item.subscription.counter}`) === 0
				} else {
					return parseInt(`${item.subscription.counter}`) !== 0
				}
			})
			.find((item) => { return item.idx === this.state.subscriptionTariffSelectedIdx });

			let selectedTitle = 'Choose payment token';
			const selectedItem = subscriptionsFound?.payWith.find((item) => { return item.paymentToken.toLowerCase() === this.state.subscriptionTokenSelected.toLowerCase() });
			if ( selectedItem ) {
				selectedTitle = `${ this.getPaymentToken(selectedItem.paymentToken)?.symbol || compactString(selectedItem.paymentToken) }`;
			}

			return (
				<div className="input-group">
					<label className="input-label">Payment token</label>
					<div className="select-custom select-token">
						<div
							className={`input-control ${this.state.paymentTokenListOpened ? 'active' : ''}`}
							onClick={() => {
								this.setState({
									paymentTokenListOpened: !this.state.paymentTokenListOpened,
									paymentTypeListOpened: false,
									subscriptionTypeListOpened: false,
									subscriptionPlanListOpened: false,
								})
							}}
						>
							<span
								className={`${this.state.subscriptionTokenSelected === '' ? 'empty' : ''}`}
							>{ selectedTitle }</span>
						</div>
						{
							this.state.paymentTokenListOpened ? (
								<ul className="options-list">
									<li
										className="option"
										onClick={() => {
											this.forceUpdate();
											this.setState({
												subscriptionTokenSelected: '',

												paymentTokenListOpened: false,
											});
										}}
									>
										<div className="option-token">
											<span>Choose payment token</span>
										</div>
									</li>
									{
										subscriptionsFound?.payWith.map((item) => {
											return (
												<li
													className="option"
													onClick={() => {
														this.forceUpdate();
														this.props.subscriptionDispatcher.getPriceWithFee(this.state.subscriptionTariffSelectedIdx || 0,  item.paymentToken)
															.then((data) => {
																this.setState({ priceWithFee: data })
															});
														this.setState({
															subscriptionTokenSelected: item.paymentToken,

															paymentTokenListOpened: false,
														});
													}}
												>
													<div className="option-token">
														<span>{ this.getPaymentToken(item.paymentToken)?.symbol || compactString(item.paymentToken) }</span>
													</div>
												</li>
											)
										})
									}
								</ul>
							) : null
						}
					</div>
				</div>
			)
	}
	isSubmitDisabled() {
		if ( this.state.subscriptionTariffSelectedIdx === -1 ) { return true; }
		if ( this.state.subscriptionTokenSelected === '' ) { return true; }
		if ( this.state.recieverEdited ) {
			if ( this.state.buyFor === '' ) { return true; }
			return false;
		}
		if ( !this.subscriptionDispatcher.isLocked() && !!this.state.subscriptionRemainings && this.state.buyFor === '' ) { return true; }

		return false
	}

	isMeCheckboxChecked() {
		if ( !this.subscriptionDispatcher.isLocked() && !!this.state.subscriptionRemainings ) { return false; }

		return !this.state.recieverEdited;
	}
	getSubscriptionPopup() {
		return (
			<div
				className="modal"
				onClick={(e) => {
					e.stopPropagation();

					const el = (e.target as HTMLTextAreaElement);
					const parentEl = el.parentElement;

					if ( !el       ) { return; }
					if ( !parentEl ) { return; }

					if (
						parentEl.className.includes('input-control') ||
						parentEl.className.includes('select-custom') ||
						parentEl.className.includes('option-token')
					) { return; }

					this.setState({
						paymentTokenListOpened: false,
						paymentTypeListOpened: false,
						subscriptionTypeListOpened: false,
						subscriptionPlanListOpened: false,
					});
				}}
			>
			<div
					className="modal__inner"
					onMouseDown={(e) => {
						e.stopPropagation();
						if ((e.target as HTMLTextAreaElement).className === 'modal__inner' || (e.target as HTMLTextAreaElement).className === 'container') {
							this.setState({
								recieverEdited: false,
								buyFor: '',
								subscriptionPaymentTypeSelected:'',
								subscriptionTypeSelected: '',
								subscriptionPlanSelected: '',
								subscriptionTariffSelectedIdx: -1,
								subscriptionTokenSelected: '',
								priceWithFee: undefined,
								subscriptionPopupOpened: false
							});
						}
					}}
				>
			<div className="modal__bg"></div>
			<div className="container">
			<div className="modal__content">
				<div
					className="modal__close"
					onClick={() => {
						this.setState({
							recieverEdited: false,
							buyFor: '',
							subscriptionPaymentTypeSelected:'',
							subscriptionTypeSelected: '',
							subscriptionPlanSelected: '',
							subscriptionTariffSelectedIdx: -1,
							subscriptionTokenSelected: '',
							priceWithFee: undefined,
							subscriptionPopupOpened: false
						});
					}}
				>
					<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
						<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
					</svg>
				</div>
				<div className="c-add">
					<div className="c-add__text">
						<div className="h2">SAFT Subscription</div>
					</div>

					<div className="c-add__form">

						<div className="row mb-3">
							<div className="col-12"><span className="input-label">Subscription receiver</span></div>
							<div className="col-12">
								<div className="row mb-2">
									<div className="col-auto my-1">
										<label className="checkbox">
											<input
												type="checkbox"
												name="receiver"
												checked={ this.isMeCheckboxChecked() }
												disabled={ !this.subscriptionDispatcher.isLocked() && !!this.state.subscriptionRemainings }
												onChange={() => { this.setState({ recieverEdited: false }) }}
											/>
												<span className="check"> </span>
												<span className="check-text">Me</span>
										</label>
									</div>
									<div className="col-auto my-1">
										<label className="checkbox">
											<input
												type="checkbox"
												name="receiver"
												checked={ this.state.recieverEdited || ( !this.subscriptionDispatcher.isLocked() && !!this.state.subscriptionRemainings ) }
												onChange={() => { this.setState({ recieverEdited: true }) }}
											/>
												<span className="check"> </span>
												<span className="check-text">Another person</span>
										</label>
									</div>
								</div>
							</div>
							{
								this.state.recieverEdited || ( !this.subscriptionDispatcher.isLocked() && !!this.state.subscriptionRemainings ) ? (
									<div className="col-12">
										<textarea
											className="input-control"
											placeholder="Paste token address"
											rows={1}
											value={ this.state.buyFor }
											onChange={(e) => { this.setState({ buyFor: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") }) }}
										></textarea>
									</div>
								) : null
							}
						</div>

						<div className="row">

							<div className="col-sm-7 mb-5 mb-sm-0">
								{ this.getPaymentTypeSelect() }
								{ this.getSubscriptionTypeSelect() }
								{ this.getSubscriptionPlanSelect() }
								{ this.getSubscriptionTokenSelect() }
							</div>

							<div className="col-sm-5">
								<div className="c-wrap mb-0">
									{ this.getTicketDetails() }
									<button
										className="btn btn-grad w-100"
										disabled={ this.isSubmitDisabled() }
										onClick={() => {
											if ( this.state.subscriptionTariffSelectedIdx === -1 ) { return; }
											const receiver = this.state.buyFor !== '' ? this.state.buyFor : this.metamaskAdapter.userAddress;
											this.subscriptionDispatcher.buySubscription(this.state.subscriptionTariffSelectedIdx, this.state.subscriptionTokenSelected, receiver)
												.then(() => {
													setTimeout(() => {
														this.subscriptionDispatcher.checkSubcription();
														this.setState({
															recieverEdited: false,
															buyFor: '',
															subscriptionPaymentTypeSelected:'',
															subscriptionTypeSelected: '',
															subscriptionPlanSelected: '',
															subscriptionTariffSelectedIdx: -1,
															subscriptionTokenSelected: '',
															priceWithFee: undefined,
															subscriptionPopupOpened: false
														})
													}, 1000)
												});
										}}
									>Subscribe</button>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
			</div>
			</div>
			</div>
		)
	}

	render() {

		if ( !this.state.subscriptionRemainings && this.subscriptionDispatcher.subscriptionRemainings ) {
			this.setState({ subscriptionRemainings: this.subscriptionDispatcher.subscriptionRemainings });
		}

		return (
			<React.Fragment>
			{ this.getSubscriptionBlock() }
			{
				this.state.subscriptionPopupOpened ?
					this.getSubscriptionPopup() : null
			}
			</React.Fragment>
		)
	}
}

export default withTranslation("translations")(SubscriptionRenderer);