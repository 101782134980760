
import React from 'react';
import Tippy from '@tippyjs/react';
import {
	ERC20ContractParamsType,
	MetamaskAdapter,
	CollateralItem,
	_AssetType,
} from '../../models/BlockchainAdapter';

import {
	compactString,
	tokenToFloat,
} from '../../models/_utils';

import icon_i_del            from '../../static/pics/i-del.svg';
import default_icon          from '../../static/pics/coins/_default.svg';
import default_token_preview from '../../static/pics/tb-nft-default.svg';
import icon_loading     from '../../static/pics/loading.svg';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type CollateralViewerProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	t                     : any,
	collaterals           : Array<CollateralItem>,
	collateralErrors?     : Array<{
		address: string,
		tokenId?: string,
		msg: string
	}>,
	nonRemovableAddress?  : string,
	removeRow?            : Function,
	width                 : string, // 'wide' or 'narrow'
	color?                : string, // default: none, '' is for dark, also can be 'light'
	showHeader?           : boolean,
}
type CollateralViewerState = {
	decimalsNative   : number,
	symbolNative     : string,
	iconNative       : string,
	EIPPrefix        : string,

	techToken        : ERC20ContractParamsType,
	erc20CollateralTokens: Array<ERC20ContractParamsType>,
	erc20BatchCollateralTokens: Array<ERC20ContractParamsType>,
	erc20OtherTechTokens : Array<ERC20ContractParamsType>,

	explorerBaseUrl  : string,
}

class CollateralViewer extends React.Component<CollateralViewerProps, CollateralViewerState> {

	store                 : any;
	metamaskAdapter       : MetamaskAdapter;
	unsubscribe!          : Function;
	t                     : any;

	columnClasses         : any;

	constructor(props: CollateralViewerProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.t                      = props.t;

		this.columnClasses          = {
			wide: {
				fungible: {
					icon: 'col-auto col-md-2 mb-2 order-1',
					address: 'col-md-3 mb-2 order-3 order-md-2',
					amount: 'col-md-5 mb-2 order-4 order-md-3',
					assetType: 'col-auto col-md-2 mb-2 order-2 order-md-4',
				},
				nft: {
					icon: 'col-auto col-md-2 mb-2 order-1',
					address: 'col-md-3 mb-2 order-3 order-md-2',
					tokenId: 'col-auto col-md-3 order-5 order-md-4',
					amount: 'col-md-2 mb-2 order-4 order-md-3',
					assetType: 'col-auto col-md-2 mb-2 order-2 order-md-5',
				}
			},
			narrow: {
				fungible: {
					icon: 'col-auto col-md-2 mb-2 order-1',
					address: 'col-md-3 mb-2 order-3 order-md-2',
					amount: 'col-md-5 mb-2 order-4 order-md-3',
					assetType: 'col-auto col-md-2 mb-2 order-2 order-md-4 md-right',
				},
				nft: {
					icon: 'col-auto col-md-2 mb-2 order-1',
					address: 'col-md-3 mb-2 order-3 order-md-2',
					tokenId: 'col-auto col-md-3 mb-2 order-5 order-md-4',
					amount: 'col-md-2 mb-2 order-4 order-md-3',
					assetType: 'col-auto col-md-2 mb-2 order-2 order-md-5 md-right align-self-center align-self-md-start',
				}
			}
		};

		this.state = {
			decimalsNative       : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative         : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative           : this.store.getState().metamaskAdapter.networkTokenIcon,
			EIPPrefix            : this.store.getState().metamaskAdapter.EIPPrefix,

			techToken            : this.store.getState().erc20TechTokenParams,
			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
			erc20BatchCollateralTokens: this.store.getState().erc20BatchCollateralTokens,
			erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,

			explorerBaseUrl      : this.store.getState().metamaskAdapter.explorerBaseUrl,
		}
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {

			this.setState({
				decimalsNative   : this.store.getState().metamaskAdapter.networkTokenDecimals,
				symbolNative     : this.store.getState().metamaskAdapter.networkTokenTicket,
				iconNative       : this.store.getState().metamaskAdapter.networkTokenIcon,
				EIPPrefix        : this.store.getState().metamaskAdapter.EIPPrefix,

				techToken        : this.store.getState().erc20TechTokenParams,
				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
				erc20BatchCollateralTokens: this.store.getState().erc20BatchCollateralTokens,
				erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
			});

		});
 	}
	componentWillUnmount() {
		this.unsubscribe();
	}

	getErrorMsgs(item: CollateralItem): Array<{ address: string, tokenId?: string, msg: string }> {
		let errors: Array<{ address: string, tokenId?: string, msg: string }> = [];

		if ( this.props.collateralErrors && this.props.collateralErrors.length ) {
			errors = this.props.collateralErrors.filter((iitem) => {
				if ( item.tokenId ) {
					return item.address.toLowerCase() === iitem.address.toLowerCase() &&
						item.tokenId === iitem.tokenId
				} else {
					return !iitem.tokenId && item.address.toLowerCase() === iitem.address.toLowerCase()
				}
			})
		}

		return errors;
	}

	getDelButton(item: CollateralItem) {
		if ( !this.props.removeRow ) { return null; }

		if (
			this.props.nonRemovableAddress &&
			this.props.nonRemovableAddress.toLowerCase() === item.address.toLowerCase()
		) {
			return (
				<Tippy
					content={ 'You can\'t delete slot. It is reserved for transfer fee collateral. Switch off wNFT from royalty recipients' }
					appendTo={ document.getElementsByClassName("wrapper")[0] }
					trigger='mouseenter'
					interactive={ false }
					arrow={ false }
					maxWidth={ 512 }
				><button
					className="btn-del"
				>
					<img src={ icon_i_del } alt="" />
				</button></Tippy>
			)
		}

		return (
			<button
				className="btn-del"
				onClick={() => { if ( this.props.removeRow ) { this.props.removeRow(item) } } }
			>
				<img src={ icon_i_del } alt="" />
			</button>
		)

	}
	getCollateralNativeRow(item: CollateralItem) {

		const errors = this.getErrorMsgs(item);

		return (
			<React.Fragment key={ 'native' }>
				<div
					className={`item ${errors.length ? 'has-error' : '' }`}
				>
					<div className="row">

						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.fungible.icon : this.columnClasses.narrow.fungible.icon }>
							<div className="tb-coin">
								<span className="i-coin"><img src={ this.state.iconNative } alt="" /></span>
								<span>{ this.state.symbolNative }</span>
							</div>
						</div>

						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.fungible.address : this.columnClasses.narrow.fungible.address }>
							{/* <span className="col-legend">Contract: </span>
							<span className="text-break">0x000...00000</span> */}
						</div>

						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.fungible.amount : this.columnClasses.narrow.fungible.amount }>
							<span className="col-legend">Amount:</span>
							<span className="text-break">{ item.amount ? tokenToFloat(item.amount, this.state.decimalsNative || 18).toString() : '' }</span>
						</div>

						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.fungible.assetType : this.columnClasses.narrow.fungible.assetType }>
							Native
						</div>

						{ this.getDelButton(item) }
					</div>
				</div>
				{/* {
					errors.map((item) => { return ( <div className="item-error">{ item.msg }</div> ) })
				} */}
				<div className="item-error">{ errors.map((iitem) => { return iitem.msg }).join(', ') }</div>

			</React.Fragment>
		)
	}
	getCollateralERC20Row(item: CollateralItem) {

		const errors = this.getErrorMsgs(item);

		let tokenToRender = undefined;
		if ( item.address.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
			tokenToRender = {
				address: this.state.techToken.address,
				symbol: this.state.techToken.symbol,
				icon: this.state.techToken.icon,
				decimals: this.state.techToken.decimals,
			}
		} else {
			const foundTech = this.state.erc20OtherTechTokens.filter((iitem) => {
				if ( !iitem.address ) { return false; }
				return item.address.toLowerCase() === iitem.address.toLowerCase()
			});
			if ( foundTech.length ) {
				tokenToRender = {
					address: foundTech[0].address,
					symbol: foundTech[0].symbol,
					icon: foundTech[0].icon,
					decimals: foundTech[0].decimals,
				}
			} else {
				let foundToken = this.state.erc20CollateralTokens.filter((iitem) => {
					if ( !iitem.address ) { return false; }
					return item.address.toLowerCase() === iitem.address.toLowerCase()
				});
				if ( !foundToken.length ) {
					foundToken = this.state.erc20BatchCollateralTokens.filter((iitem) => {
						if ( !iitem.address ) { return false; }
						return item.address.toLowerCase() === iitem.address.toLowerCase()
					});
				}
				if ( foundToken.length ) {
					tokenToRender = {
						address: foundToken[0].address,
						symbol: foundToken[0].symbol,
						icon: foundToken[0].icon,
						decimals: foundToken[0].decimals,
					}
				}
			}
		}

		if ( tokenToRender ) {
			return (
				<React.Fragment key={ item.address }>
					<div
						className={`item ${errors.length ? 'has-error' : '' }`}
					>
						<div className="row">
							<div className={ this.props.width === 'wide' ? this.columnClasses.wide.fungible.icon : this.columnClasses.narrow.fungible.icon }>
								<div className="tb-coin">
									<span className="i-coin"><img src={ tokenToRender.icon } alt="" /></span>
									<span>{ tokenToRender.symbol }</span>
								</div>
							</div>

							<div className={ this.props.width === 'wide' ? this.columnClasses.wide.fungible.address : this.columnClasses.narrow.fungible.address }>
								<span className="col-legend">Contract: </span>
								<span className="text-break">{ compactString(item.address) }</span>
							</div>

							<div className={ this.props.width === 'wide' ? this.columnClasses.wide.fungible.amount : this.columnClasses.narrow.fungible.amount }>
								<span className="col-legend">Amount:</span>
								<span className="text-break">{ item.amount ? tokenToFloat(item.amount, tokenToRender.decimals || 18).toString() : '' }</span>
							</div>

							<div className={ this.props.width === 'wide' ? this.columnClasses.wide.fungible.assetType : this.columnClasses.narrow.fungible.assetType }>
								{ this.state.EIPPrefix }-20
							</div>

							{ this.getDelButton(item) }
						</div>
					</div>
					{/* {
						errors.map((item) => { return ( <div className="item-error">{ item.msg }</div> ) })
					} */}
					<div className="item-error">{ errors.map((iitem) => { return iitem.msg }).join(', ') }</div>

				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment key={ item.address }>
					<div
						className={`item ${errors.length ? 'has-error' : '' }`}
					>
						<div className="row">
							<div className={ this.props.width === 'wide' ? this.columnClasses.wide.fungible.icon : this.columnClasses.narrow.fungible.icon }>
								<div className="tb-coin">
									<span className="i-coin"><img src={ default_icon } alt="" /></span>
									<span>{ compactString(item.address) }</span>
								</div>
							</div>

							<div className={ this.props.width === 'wide' ? this.columnClasses.wide.fungible.address : this.columnClasses.narrow.fungible.address }>
								<span className="col-legend">Contract: </span>
								<span className="text-break">{ compactString(item.address) }</span>
							</div>

							<Tippy
								content={ this.t('decimals is unknown; amount shown in wei') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 260 }
							>
								<div className={ this.props.width === 'wide' ? this.columnClasses.wide.fungible.amount : this.columnClasses.narrow.fungible.amount }>
									<span className="col-legend">Amount*:</span>
									<span className="text-break text-orange">{ item.amount ? item.amount.toString() : '' }*</span>
								</div>
							</Tippy>

							<div className={ this.props.width === 'wide' ? this.columnClasses.wide.fungible.assetType : this.columnClasses.narrow.fungible.assetType }>
								{ this.state.EIPPrefix }-20
							</div>

							{ this.getDelButton(item) }
						</div>
					</div>
					{/* {
						errors.map((item) => { return ( <div className="item-error">{ item.msg }</div> ) })
					} */}
					<div className="item-error">{ errors.map((iitem) => { return iitem.msg }).join(', ') }</div>

				</React.Fragment>
			)
		}
	}
	getNFTPreview(item: CollateralItem) {
		if ( item.tokenImg === undefined ) {
			return (
				<span className="i-coin">
					<img src={ icon_loading } alt="" />
				</span>
			)
		}
		if ( item.tokenImg === '' ) {
			return ( <img src={ default_token_preview } alt="" /> )
		}

		return (
			<img src={ item.tokenImg } alt="" />
		)
	}
	getCollateralERC721Row(item: CollateralItem) {
		const errors = this.getErrorMsgs(item);

		return (
			<React.Fragment key={ `ERC721${item.address}${item.tokenId}` }>
				<div
					className={`item ${errors.length ? 'has-error' : '' }`}
				>
					<div className="row">
						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.nft.icon : this.columnClasses.narrow.nft.icon }>
							<div className="tb-nft">
								{ this.getNFTPreview(item) }
							</div>
						</div>

						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.nft.address : this.columnClasses.narrow.nft.address }>
							<span className="col-legend">Contract: </span>
							<span className="text-break">{ item.address ? compactString(item.address) : '' }</span>
						</div>

						<div className="col-md-2 mb-2 order-4 order-md-3 d-none d-md-block"></div>

						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.nft.tokenId : this.columnClasses.narrow.nft.tokenId }>
							<span className="text-break">ID { item.tokenId || '' }</span>
						</div>

						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.nft.assetType : this.columnClasses.narrow.nft.assetType }>
							{ this.state.EIPPrefix }-721
						</div>

						{ this.getDelButton(item) }
					</div>
				</div>
				{/* {
					errors.map((item) => { return ( <div className="item-error">{ item.msg }</div> ) })
				} */}
				<div className="item-error">{ errors.map((iitem) => { return iitem.msg }).join(', ') }</div>

			</React.Fragment>
		)
	}
	get1155Amount(qty: BigNumber | undefined) {
		if ( !qty ) { return '' }
		if ( qty.eq(0) ) { return '' }
		if ( qty.eq(1) ) { return '1 item' }
		return `${qty} items`
	}
	getCollateralERC1155Row(item: CollateralItem) {
		const errors = this.getErrorMsgs(item);

		return (
			<React.Fragment key={ `ERC1155${item.address}${item.tokenId}` }>
				<div
					className={`item ${errors.length ? 'has-error' : '' }`}
				>
					<div className="row">
						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.nft.icon : this.columnClasses.narrow.nft.icon }>
							<div className="tb-nft">
								{ this.getNFTPreview(item) }
							</div>
						</div>

						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.nft.address : this.columnClasses.narrow.nft.address }>
							<span className="col-legend">Contract: </span>
							<span className="text-break">{ item.address ? compactString(item.address) : '' }</span>
						</div>

						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.nft.amount : this.columnClasses.narrow.nft.amount }>
							<span className="col-legend">Amount:</span>
							<span className="text-break">{ this.get1155Amount( item.amount ) }</span>
						</div>

						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.nft.tokenId : this.columnClasses.narrow.nft.tokenId }>
							<span className="text-break">ID { item.tokenId || '' }</span>
						</div>

						<div className={ this.props.width === 'wide' ? this.columnClasses.wide.nft.assetType : this.columnClasses.narrow.nft.assetType }>
							{ this.state.EIPPrefix }-1155
						</div>

						{ this.getDelButton(item) }
					</div>
				</div>
				{/* {
					errors.map((item) => { return ( <div className="item-error">{ item.msg }</div> ) })
				} */}
				<div className="item-error">{ errors.map((iitem) => { return iitem.msg }).join(', ') }</div>

			</React.Fragment>
		)
	}
	render() {
		return (
			<div className={ `c-wrap__table mt-3 ${ this.props.color || '' }` }>

				{
					this.props.showHeader ? (
						<div className="item item-header">
							<div className="row">
								<div className="mb-2 col-md-2 text-muted">Token</div>
								<div className="mb-2 col-md-3">Contract</div>
								<div className="mb-2 col-md-5">Amount</div>
								<div className="mb-2 col-md-2 md-right">Type</div>
							</div>
						</div>
					) : null
				}
				{
					this.props.collaterals
					.sort((item, prev) => {
						if ( item.assetType < prev.assetType ) { return -1 }
						if ( item.assetType > prev.assetType ) { return  1 }

						if ( item.address.toLowerCase() < prev.address.toLowerCase() ) { return -1 }
						if ( item.address.toLowerCase() > prev.address.toLowerCase() ) { return  1 }

						if ( item.tokenId && prev.tokenId ) {
							try {
								if ( new BigNumber(item.tokenId).isNaN() || new BigNumber(prev.tokenId).isNaN() ) {
									if ( parseInt(`${item.tokenId}`) < parseInt(`${prev.tokenId}`) ) { return -1 }
									if ( parseInt(`${item.tokenId}`) > parseInt(`${prev.tokenId}`) ) { return  1 }
								}
								const itemTokenIdNumber = new BigNumber(item.tokenId);
								const prevTokenIdNumber = new BigNumber(prev.tokenId);

								if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return -1 }
								if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return  1 }
							} catch ( ignored ) {
								if ( `${item.tokenId}`.toLowerCase() < `${prev.tokenId}`.toLowerCase() ) { return -1 }
								if ( `${item.tokenId}`.toLowerCase() > `${prev.tokenId}`.toLowerCase() ) { return  1 }
							}
						}

						return 0
					})
					.map((item) => {
						if ( item.assetType === _AssetType.native  ) { return this.getCollateralNativeRow(item)  }
						if ( item.assetType === _AssetType.ERC20   ) { return this.getCollateralERC20Row(item)   }
						if ( item.assetType === _AssetType.ERC721  ) { return this.getCollateralERC721Row(item)  }
						if ( item.assetType === _AssetType.ERC1155 ) { return this.getCollateralERC1155Row(item) }

						return null;
					})
				}
			</div>
		)
	}
}

export default CollateralViewer;