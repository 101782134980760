
import React               from 'react';
import Tippy               from '@tippyjs/react';
import {
	withRouter,
	match,
	Link,
} from 'react-router-dom';
import {
	ERC20ContractParamsType,
	MetamaskAdapter, WrappedTokenType,
} from '../../models/BlockchainAdapter';

import icon_external from '../../static/pics/icons/i-external.svg'
import icon_logo           from "../../static/pics/logo.svg";
import default_icon  from '../../static/pics/coins/_default.svg';

import {
	History,
	Location
} from 'history';
import {
	Trans,
	withTranslation
} from "react-i18next";

import {
	APIRoyaltyItem,
	fetchRoyalty
} from '../../models/APIService/apiservice';

import {
	getWrappedToken
} from '../../models/TokenFetchWrapper/tokenfetchwrapper';
import {
	compactString,
	localStorageGet,
	tokenToFloat,
	unixtimeToStr
} from '../../models/_utils';

import BigNumber from 'bignumber.js';
import { setAuthMethod } from '../../reducers';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type RoyaltyTokensToRender = {
	token: WrappedTokenType,
	royalties: Array<{
		feeToken?: ERC20ContractParamsType,
		feeTokenAddress: string,
		transactions: Array<{
			amount: BigNumber,
			royaltyFrom: string,
			txHash: string,
			timestamp: BigNumber,
		}>,
	}>
}
type RoyaltyERC20Stat = {
	feeToken?      : ERC20ContractParamsType,
	feeTokenAddress: string,
	amount         : BigNumber
}

type MyRoyaltiesPageProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function,
	t                     : any,
	match                 : match;
	location              : Location,
	history               : History,
}
type MyRoyaltiesPageState = {
	chainId           : number,
	userAddress       : string,
	explorerBaseUrl   : string,
	explorerName      : string,

	royalties            : Array<APIRoyaltyItem>,
	royaltiesFetchStarted: boolean,

	tokensToRender       : Array<RoyaltyTokensToRender>,

	erc20CollateralTokens: Array<ERC20ContractParamsType>,
	erc20OtherTechTokens : Array<ERC20ContractParamsType>,
	techToken            : ERC20ContractParamsType,

	wrappedTokens        : Array<WrappedTokenType>,

	erc20TokensStat      : Array<RoyaltyERC20Stat>,
}

class MyRoyaltiesPage extends React.Component<MyRoyaltiesPageProps, MyRoyaltiesPageState> {

	store                 : any;
	unsubscribe!          : Function;
	metamaskAdapter       : MetamaskAdapter;
	showAuthMethodSelector: Function;
	t                     : any;

	constructor(props: MyRoyaltiesPageProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.t                      = props.t;

		this.state = {
			chainId           : this.store.getState().metamaskAdapter.chainId,
			explorerBaseUrl   : this.store.getState().metamaskAdapter.explorerBaseUrl,
			explorerName      : this.store.getState().metamaskAdapter.explorerName,
			userAddress       : this.store.getState().account.address || '',

			royalties            : [],
			royaltiesFetchStarted: false,

			tokensToRender       : [],

			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
			erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
			techToken            : this.store.getState().erc20TechTokenParams,

			wrappedTokens        : this.store.getState().wrappedTokens,

			erc20TokensStat      : [],
		}

		if (
			this.store.getState().account.address &&
			!this.state.royaltiesFetchStarted &&
			!this.state.royalties.length &&
			this.state.chainId !== 0
		) {
			this.clearRoyalties();
			this.updateRoyaltyPage(1, []);
		}
	}

	componentDidMount() {

		if ( !this.store.getState().metamaskAdapter.logged ) {
			const prevAuthMethod = localStorageGet('authMethod').toLowerCase();
			if ( prevAuthMethod ) {
				this.store.dispatch(setAuthMethod( prevAuthMethod ));
				this.metamaskAdapter.connect();
			} else {
				this.props.showAuthMethodSelector();
			}
		}

		this.unsubscribe = this.store.subscribe(() => {

			if (
				this.store.getState().account.address &&
				!this.state.royaltiesFetchStarted &&
				!this.state.royalties.length &&
				this.state.chainId !== 0
			) {
				this.clearRoyalties();
				this.updateRoyaltyPage(1, []);
			}

			const wrappedTokens = this.state.wrappedTokens;
			this.store.getState().wrappedTokens.forEach((item: WrappedTokenType) => {
				const foundToken = wrappedTokens.find((iitem: WrappedTokenType) => {
					return item.contractAddress.toLowerCase() === iitem.contractAddress.toLowerCase() &&
					item.tokenId.toLowerCase() === iitem.tokenId.toLowerCase()
				});

				if ( !foundToken ) { wrappedTokens.push(item) }
			});

			let tokensToRenderUpdated = this.state.tokensToRender;
			let erc20TokensStatUpdated = this.state.erc20TokensStat;
			if (
				this.state.erc20CollateralTokens.length !== this.store.getState().erc20CollateralTokens.length ||
				this.state.erc20OtherTechTokens.length  !== this.store.getState().erc20OtherTechTokens.length
			) {
				tokensToRenderUpdated = this.fillFeeTokens(tokensToRenderUpdated);
				erc20TokensStatUpdated = this.addRoyaltyToERC20Stat(tokensToRenderUpdated);
			}

			this.setState({
				chainId           : this.store.getState().metamaskAdapter.chainId,
				explorerBaseUrl   : this.store.getState().metamaskAdapter.explorerBaseUrl,
				explorerName      : this.store.getState().metamaskAdapter.explorerName,
				userAddress       : this.store.getState().account.address || '',

				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
				erc20OtherTechTokens : this.store.getState().erc20OtherTechTokens,
				techToken            : this.store.getState().erc20TechTokenParams,

				tokensToRender: tokensToRenderUpdated,
				erc20TokensStat: erc20TokensStatUpdated,
				wrappedTokens,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	clearRoyalties() {
		this.setState({
			royalties            : [],
			tokensToRender       : [],
			royaltiesFetchStarted: false,
		});
	}
	updateRoyaltyPage(page: number, fetchedTokens: Array<APIRoyaltyItem>) {

		fetchRoyalty({
			chainId: this.state.chainId,
			userAddress: this.store.getState().account.address,
			page: page
		})
			.then((data) => {
				const royaltiesResp = [
					...fetchedTokens,
					...data || []
				];

				if ( data && data.length && data.length === 12 ) {
					this.updateRoyaltyPage(page + 1, royaltiesResp);
				} else {

					this.groupRoyaltiesByToken(royaltiesResp, this.state.tokensToRender)
						.then((ddata) => {

							const parsedTokes = this.fillFeeTokens(ddata);
							const parsedStat = this.addRoyaltyToERC20Stat(parsedTokes);

							this.setState({
								royalties: royaltiesResp,
								tokensToRender: parsedTokes,
								erc20TokensStat: parsedStat
							})
						});

					this.setState({ royaltiesFetchStarted: false });
				}
			})

		this.setState({ royaltiesFetchStarted: true });
	}

	fillFeeTokens(storedTokens: Array<RoyaltyTokensToRender>) {

		return storedTokens.map((item) => {
			return {
				...item,
				royalties: item.royalties.map((iitem) => {
					let feeToken;
					if ( iitem.feeTokenAddress.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
						feeToken = this.state.techToken;
					}
					if ( !feeToken ) {
						const foundOtherTechToken = this.state.erc20OtherTechTokens.find((iiitem) => { return iitem.feeTokenAddress.toLowerCase() === iiitem.address.toLowerCase() });
						if ( foundOtherTechToken ) { feeToken = foundOtherTechToken }
					}
					if ( !feeToken ) {
						const foundCollateralToken = this.state.erc20CollateralTokens.find((iiitem) => { return iitem.feeTokenAddress.toLowerCase() === iiitem.address.toLowerCase() });
						if ( foundCollateralToken ) { feeToken = foundCollateralToken }
					}
					if ( !feeToken ) {
						this.metamaskAdapter.addERC20Contracts(iitem.feeTokenAddress)
					}

					return {
						...iitem,
						feeToken: feeToken
					}
				})
			}
		});

	}
	addRoyaltyToERC20Stat(storedTokens: Array<RoyaltyTokensToRender>) {

		let output: Array<RoyaltyERC20Stat> = [];
		for ( let idx = 0; idx < storedTokens.length; idx++ ) {
			const item = storedTokens[idx];

			for ( let iidx = 0; iidx < item.royalties.length; iidx++ ) {
			// item.royalties.forEach((iitem) => {
				const iitem = item.royalties[iidx];

				let summedAmount = iitem.transactions.reduce((acc, iiitem) => {
					return acc.plus(iiitem.amount)
				}, new BigNumber(0));

				const foundTokenObjInStore = output.find((iiitem) => {
					if ( !iiitem.feeToken ) { return false; }
					return iiitem.feeToken.address.toLowerCase() === iitem.feeTokenAddress.toLowerCase()
				});

				if ( foundTokenObjInStore ) {
					summedAmount = summedAmount.plus(foundTokenObjInStore.amount)
				}

				output = [
					...output.filter((iiitem) => { return iiitem.feeTokenAddress.toLowerCase() !== iitem.feeTokenAddress.toLowerCase() }),
					{
						feeTokenAddress: iitem.feeTokenAddress,
						feeToken: iitem.feeToken,
						amount: summedAmount
					}
				]
			}

		}

		return output;

	}
	async groupRoyaltiesByToken(inputRoyalties: Array<APIRoyaltyItem>, storedTokens: Array<RoyaltyTokensToRender>) {
		let tokensToStore = storedTokens || [];

		for ( let idx = 0; idx < inputRoyalties.length; idx++ ) {
		// inputRoyalties.forEach(async (item) => {

			const item = inputRoyalties[idx];

			if ( !item.royalty_amount || new BigNumber(item.royalty_amount).isNaN() || new BigNumber(item.royalty_amount).eq(0) ) {
				continue;
			}
			if ( item.royalty_from.toLowerCase() === '0x0000000000000000000000000000000000000000' ) { continue; }
			if ( item.royalty_from.toLowerCase() === this.state.userAddress.toLowerCase()         ) { continue; }

			const foundTokenInStore = tokensToStore.find((iitem) => {
				return item.contract_address.toLowerCase() === iitem.token.contractAddress.toLowerCase() &&
				item.token_id.toLowerCase() === iitem.token.tokenId.toLowerCase()
			});

			if ( foundTokenInStore ) {
				// append to existed

				const foundRoyaltyToken = foundTokenInStore.royalties.find((iitem) => { return item.royalty_token.toLowerCase() === iitem.feeTokenAddress.toLowerCase() });

				if ( foundRoyaltyToken ) {

					const txHashAlreadyExists = foundRoyaltyToken.transactions.find((iitem) => { return iitem.txHash.toLowerCase() === item.txhash.toLowerCase() });
					if ( txHashAlreadyExists ) { continue; }

					tokensToStore = [
						...tokensToStore.filter((iitem) => {
							return iitem.token.contractAddress.toLowerCase() !== foundTokenInStore.token.contractAddress.toLowerCase() ||
							iitem.token.tokenId.toLowerCase() !== foundTokenInStore.token.tokenId.toLowerCase()
						}),
						{
							token: foundTokenInStore.token,
							royalties: [{
								feeTokenAddress: item.royalty_token,
								transactions: [
									...foundRoyaltyToken.transactions,
									{
										amount: item.royalty_amount,
										royaltyFrom: item.royalty_from,
										txHash: item.txhash,
										timestamp: new BigNumber((await this.metamaskAdapter.web3.eth.getBlock(item.blocknumber)).timestamp).multipliedBy(1000),
									}
								]
							}]
						}
					]
				} else {
					tokensToStore = [
						...tokensToStore.filter((iitem) => {
							return iitem.token.contractAddress.toLowerCase() !== foundTokenInStore.token.contractAddress.toLowerCase() ||
							iitem.token.tokenId.toLowerCase() !== foundTokenInStore.token.tokenId.toLowerCase()
						}),
						{
							token: foundTokenInStore.token,
							royalties: [{
								feeTokenAddress: item.royalty_token,
								transactions: [{
									amount: item.royalty_amount,
									royaltyFrom: item.royalty_from,
									txHash: item.txhash,
									timestamp: new BigNumber((await this.metamaskAdapter.web3.eth.getBlock(item.blocknumber)).timestamp).multipliedBy(1000),
								}]
							}]
						}
					]
				}

			} else {
				let tokenToAdd = this.state.wrappedTokens.find((iitem) => {
					return iitem.contractAddress.toLowerCase() === item.contract_address.toLowerCase() &&
					iitem.tokenId.toLowerCase() === item.token_id.toLowerCase()
				});

				if ( !tokenToAdd ) {
					tokenToAdd = await getWrappedToken({
						tokenId: item.token_id,
						chainId: this.state.chainId,
						contractAddress: item.contract_address,
						metamaskAdapter: this.metamaskAdapter,
						t: this.t,
						userAddress: this.state.userAddress,
					});
				}

				tokensToStore = [
					...tokensToStore,
					{
						token: tokenToAdd,
						royalties: [{
							feeTokenAddress: item.royalty_token,
							transactions: [{
								amount: item.royalty_amount,
								royaltyFrom: item.royalty_from,
								txHash: item.txhash,
								timestamp: new BigNumber((await this.metamaskAdapter.web3.eth.getBlock(item.blocknumber)).timestamp).multipliedBy(1000),
							}]
						}]
					}
				]
			}
		}

		return tokensToStore;
	}

	getMedia(token: WrappedTokenType) {

		if ( token.image === undefined ) {
			return (
				<Link
					className="inner"
					to={`/token/${this.state.chainId}/${token.contractAddress}/${token.tokenId}`}
				>
					<div className="default">
						<img src={ icon_logo } alt="" />
						<span><Trans i18nKey="Loading NON-FUNGIBLE TOKEN Preview" components={[<br />]} /></span>
					</div>
				</Link>
			)
		}
		if ( token.image === '' ) {
			return (
				<Link
					className="inner"
					to={`/token/${this.state.chainId}/${token.contractAddress}/${token.tokenId}`}
				>
					<div className="default">
						<img src={ icon_logo } alt="" />
						<span><Trans i18nKey="Cannot load NON-FUNGIBLE TOKEN Preview" components={[<br />]} /></span>
					</div>
				</Link>
			)
		}

		return (
			<Link
				className="inner"
				to={`/token/${this.state.chainId}/${token.contractAddress}/${token.tokenId}`}
			>
				<video className="img" src={ token.image } poster={ token.image } autoPlay={ true } muted={ true } loop={ true } />
			</Link>
		)
	}
	getTokenMyRoyaltyPercent(item: RoyaltyTokensToRender) {
		const foundMyRoyalty = item.token.royalties.find((iitem) => {
			if ( !iitem.address ) { return false; }
			return iitem.address.toLowerCase() === this.state.userAddress.toLowerCase()
		});

		if ( foundMyRoyalty ) { return foundMyRoyalty.percent.toString() }

		return '';
	}
	getTokenItem(item: RoyaltyTokensToRender) {

		return (
			<div className="mb-5" key={`${item.token.contractAddress}${item.token.tokenId}`}>
				<div className="row">
					<div className="col-md-4">

						<div className="w-card w-card-preview no-meta mb-5">
							<div className="bg">
								<div className="w-card__token">
									{ this.getMedia(item.token) }
								</div>

								<div className="w-card__param">
									<div className="field-wrap h-auto">
										<div>
											<span className="text-muted">Royalty</span>
											{ ' ' }
											<b>{ this.getTokenMyRoyaltyPercent(item) }%</b>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					<div className="col-md-8">
						{
							item.royalties.map((iitem) => {
								const summedAmount = iitem.transactions.reduce((acc, iiitem) => {
									return acc.plus(iiitem.amount)
								}, new BigNumber(0));
								return (
									<div className="c-wrap" key={iitem.feeTokenAddress}>
										<div className="c-wrap__header">
											<div><b>{ iitem.transactions.length } transfers</b></div>
											<div className="wnft-royalty">
												<span className="i-coin"><img src={ iitem.feeToken ? iitem.feeToken.icon : default_icon } alt="" /></span>
												<span className="sum">{ tokenToFloat(summedAmount, iitem.feeToken ? iitem.feeToken.decimals : 18).toString() }</span>
											</div>
										</div>
										<div className="c-wrap__table mt-3">
											{
												iitem.transactions.map((iiitem) => {
													return (
														<div className="item" key={iiitem.txHash}>
															<div className="row">
																<div className="mb-2 col-6 col-sm-3">
																	<a
																		className="ex-link"
																		target="_blank" rel="noopener noreferrer"
																		href={`${this.state.explorerBaseUrl}/tx/${iiitem.txHash}`}
																	>
																		{/* <span>{ compactString(iiitem.txHash) }</span> */}
																		<span>{ unixtimeToStr(iiitem.timestamp) }</span>
																		<img className="i-ex" src={ icon_external } alt="" />
																	</a>
																</div>
																	<Tippy
																		content={ iiitem.royaltyFrom }
																		appendTo={ document.getElementsByClassName("wrapper")[0] }
																		trigger='mouseenter'
																		interactive={ false }
																		arrow={ false }
																		maxWidth={ 512 }
																	>
																		<div className="mb-2 col-6 col-sm-3">
																			{ compactString(iiitem.royaltyFrom) }
																		</div>
																	</Tippy>
																<div className="mb-2 col-12 col-sm-6 sm-right">
																	<span className="col-legend d-sm-none">Sum: </span>
																	<span className="text-break"> <b>{ tokenToFloat(new BigNumber(iiitem.amount), iitem.feeToken ? iitem.feeToken.decimals : 18).toString() }</b></span>
																</div>
															</div>
														</div>
													)
												})
											}
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		)
	}
	getTokenFeeStat() {
		return (
			<div className="royalty__stat">
				<div className="row row-sm">

					{
						this.state.erc20TokensStat.map((item) => {

							if ( item.feeToken ) {
								return (
									<div className="col-auto" key={item.feeTokenAddress}>
										<div className="item">
											<span className="i-coin"><img src={ item.feeToken.icon || default_icon } alt="" /></span>
											<span className="sum">{ tokenToFloat(item.amount, item.feeToken.decimals).toString() }</span>
										</div>
									</div>
								)
							} else {
								return (
									<div className="col-auto" key={item.feeTokenAddress}>
										<div className="item">
											<span className="i-coin"><img src={ default_icon } alt="" /></span>
											<span className="sum">{ item.amount.toString() }</span>
										</div>
									</div>
								)
							}
						})
					}

				</div>

			</div>
		)
	}

	render() {

		return (
			<main className="s-main">
				<div className="container">
					<div className="h3">My Royalties </div>
					<div className="c-wrap mb-8">
						{ this.getTokenFeeStat() }
					</div>
					{ this.state.tokensToRender.map((item) => { return this.getTokenItem(item) }) }
				</div>
			</main>
		)
	}
}

export default withTranslation("translations")(withRouter(MyRoyaltiesPage));