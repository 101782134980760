import React               from 'react';
import Tippy               from '@tippyjs/react';
import {
	Link,
	withRouter,
	match,
} from 'react-router-dom';
import {
	History,
	Location
} from 'history';

import {
	MetamaskAdapter,
	ChainParamsType,
	OriginalTokenType,
	_AssetType,
} from '../../models/BlockchainAdapter';

import {
	CrossingDispatcher
} from '../../models/BlockchainAdapter';

import TransferPopup from '../TransferPopup';
import SecretInput from '../SecretInput';
import { withTranslation } from "react-i18next";
import { APICrossingItem } from '../../models/APIService';

import {
	fetchBurns,
	fetchCrossings,
	getOracleCrossingBurnSign,
	getOracleCrossingFreezeSign,
} from '../../models/APIService/apiservice';
import {
	clearInfo,
	requestChain,
	setAuthMethod,
	setError,
	setInfo,
	setLoading,
	unsetLoading
} from '../../reducers';
import { compactString, getABI, localStorageGet   } from '../../models/_utils';

import TokenInList, {
	TokenRenderType
} from '../TokenInList';
import {
	getOriginalToken
} from '../../models/TokenFetchWrapper/tokenfetchwrapper';

import default_icon  from '../../static/pics/coins/_default.svg';
import i_bgcrossing1 from '../../static/pics/bg/bg-crossing-1.svg'
import i_bgcrossing2 from '../../static/pics/bg/bg-crossing-2.svg'
import i_box         from '../../static/pics/icons/i-box.svg'
import i_external    from '../../static/pics/icons/i-external.svg'
import i_fire_red    from '../../static/pics/icons/i-fire-red.svg'
import i_unknown     from '../../static/pics/icons/i-unknown.svg'

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type CrossingListProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	t                     : any,
	match                 : match;
	location              : Location,
	history               : History,
	showAuthMethodSelector: Function,
}
type CrossingListState = {

	chainId           : number,
	isTestNetwork     : boolean,
	chainName         : string,
	userAddress       : string,
	EIPPrefix         : string,

	balanceNative     : BigNumber,
	decimalsNative    : number,
	symbolNative      : string,
	iconNative        : string,
	networkTokenTicket: string,
	explorerBaseUrl   : string,
	explorerName      : string,
	availableChains   : Array<ChainParamsType>

	crossings            : Array<APICrossingItem>,
	burns                : Array<APICrossingItem>,
	crossingsFetchStarted: boolean,
	crossingsFetchPage   : number,
	burnsFetchStarted    : boolean,
	burnsFetchPage       : number,
	crossing             : {
		keeper: string,
		spawners: Array<{ targetChainId: number, address: string, icon?: string, name?: string, }>
	},
	inputPinCodes     : Array<{
		tx: string,
		pinCode: string
	}>,
	targetChains: Array<{ targetChainId: number, address: string, icon?: string, name?: string, }>,
	targetChainListOpened: boolean,

	customTokenModalShow: boolean,
	customTokenAction: string,
	customTokenInputKeeperAddress: string,
	customTokenInputChainId: number,
	customTokenInputContractAddress: string,
	customTokenInputTokenId: string,
	customTokenInputPinCode: string,
	customTokenInputBurnHash: string,

	transferToken           : OriginalTokenType | undefined,
	previewToken            : {
		token: OriginalTokenType,
		id: string,
	} | undefined,
}

class CrossingList extends React.Component<CrossingListProps, CrossingListState> {

	store                 : any;
	unsubscribe!          : Function;
	metamaskAdapter       : MetamaskAdapter;
	t                     : any;
	showAuthMethodSelector: Function;
	targetListRef         : React.RefObject<HTMLInputElement>;
	crossingDispatcher    : CrossingDispatcher | undefined;
	copiedHintTimer       : number;
	copiedHintTimeout     : number;

	constructor(props: CrossingListProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.t                      = props.t;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.targetListRef          = React.createRef();

		this.copiedHintTimer    = 0;
		this.copiedHintTimeout  = 2; // s

		this.crossingDispatcher = this.metamaskAdapter.getCrossingDispatcher();

		const crossingsFromStore = this.store.getState().metamaskAdapter.crossing;

		this.state = {
			chainId           : this.store.getState().metamaskAdapter.chainId,
			isTestNetwork     : this.store.getState().metamaskAdapter.isTestNetwork,
			chainName         : this.store.getState().metamaskAdapter.chainName,
			balanceNative     : this.store.getState().account.balanceNative,
			decimalsNative    : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative      : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative        : this.store.getState().metamaskAdapter.networkTokenIcon,
			networkTokenTicket: this.store.getState().metamaskAdapter.networkTokenTicket,
			explorerBaseUrl   : this.store.getState().metamaskAdapter.explorerBaseUrl,
			explorerName      : this.store.getState().metamaskAdapter.explorerName,
			userAddress       : this.store.getState().account.address || '',
			EIPPrefix         : this.store.getState().metamaskAdapter.EIPPrefix,
			availableChains   : this.store.getState().metamaskAdapter.availableChains,

			crossing          : crossingsFromStore,
			inputPinCodes     : [],
			targetChains      : crossingsFromStore ? crossingsFromStore.targetChains : [],
			targetChainListOpened: false,

			crossings            : [],
			burns                : [],
			crossingsFetchStarted: false,
			crossingsFetchPage   : 1,
			burnsFetchStarted    : false,
			burnsFetchPage       : 1,

			customTokenModalShow: false,
			customTokenAction: 'burn',
			customTokenInputKeeperAddress: '',
			customTokenInputChainId: 0,
			customTokenInputContractAddress: '',
			customTokenInputTokenId: '',
			customTokenInputPinCode: '',
			customTokenInputBurnHash: '',

			transferToken: undefined,
			previewToken : undefined,
		}

		this.clearCrossings();
		this.updateCrossings();
		this.updateBurns();
	}

	componentDidMount() {

		if ( !this.store.getState().metamaskAdapter.logged ) {
			const prevAuthMethod = localStorageGet('authMethod').toLowerCase();
			if ( prevAuthMethod ) {
				this.store.dispatch(setAuthMethod( prevAuthMethod ));
				this.metamaskAdapter.connect();
			} else {
				this.props.showAuthMethodSelector();
			}
		}

		this.unsubscribe = this.store.subscribe(() => {
			if ( !this.crossingDispatcher ) { this.crossingDispatcher = this.metamaskAdapter.getCrossingDispatcher(); }

			this.updateCrossings();
			this.updateBurns();

			const crossingsFromStore = this.store.getState().metamaskAdapter.crossing;

			this.setState({
				chainId              : this.store.getState().metamaskAdapter.chainId,
				isTestNetwork        : this.store.getState().metamaskAdapter.isTestNetwork,
				chainName            : this.store.getState().metamaskAdapter.chainName,
				balanceNative        : this.store.getState().account.balanceNative,
				decimalsNative       : this.store.getState().metamaskAdapter.networkTokenDecimals,
				iconNative           : this.store.getState().metamaskAdapter.networkTokenIcon,
				symbolNative         : this.store.getState().metamaskAdapter.networkTokenTicket,
				explorerBaseUrl      : this.store.getState().metamaskAdapter.explorerBaseUrl,
				explorerName         : this.store.getState().metamaskAdapter.explorerName,
				availableChains      : this.store.getState().metamaskAdapter.availableChains,
				userAddress          : this.store.getState().account.address || '',
				EIPPrefix            : this.store.getState().metamaskAdapter.EIPPrefix,
				crossing             : crossingsFromStore,
				targetChains         : crossingsFromStore ? crossingsFromStore.targetChains : [],
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	clearCrossings() {
		this.setState({
			crossings: [],
			crossingsFetchPage: 1,
			crossingsFetchStarted: false,
			burns: [],
			burnsFetchPage: 1,
			burnsFetchStarted: false,
		});
	}
	updateCrossings() {

		if ( !this.store.getState().account.address ) { return; }
		if ( this.state.crossingsFetchStarted       ) { return; }
		if ( this.state.crossings.length            ) { return; }

		fetchCrossings({
			userAddress: this.store.getState().account.address,
			page: this.state.crossingsFetchPage
		})
			.then((data) => {
				let page = this.state.crossingsFetchPage;
				if ( data && data.length === 12 ) {

					fetchCrossings({
						userAddress: this.store.getState().account.address,
						page: this.state.crossingsFetchPage + 1,
					})
						.then((data) => {
							if ( !data ) { return; }
							this.setState({
								crossings: [
									...this.state.crossings,
									...data
								]
							})
						});


					page = page + 1
				}
				this.setState({
					crossings: data || [],
					crossingsFetchPage: page,
				})
			})

		this.setState({
			crossingsFetchStarted: true,
			crossingsFetchPage: this.state.crossingsFetchPage + 1
		});
	}
	updateBurns() {

		if ( !this.store.getState().account.address ) { return; }
		if ( this.state.burnsFetchStarted       ) { return; }
		if ( this.state.burns.length            ) { return; }

		fetchBurns({
			userAddress: this.store.getState().account.address,
			page: this.state.burnsFetchPage
		})
			.then((data) => {
				let page = this.state.burnsFetchPage;
				if ( data && data.length === 12 ) {

					fetchBurns({
						userAddress: this.store.getState().account.address,
						page: this.state.burnsFetchPage + 1,
					})
						.then((data) => {
							if ( !data ) { return; }
							this.setState({
								burns: [
									...this.state.burns,
									...data
								]
							})
						});


					page = page + 1
				}
				this.setState({
					burns: data || [],
					burnsFetchPage: page,
				})
			})

		this.setState({
			burnsFetchStarted: true,
			burnsFetchPage: this.state.burnsFetchPage + 1
		});
	}
	generateHash(str: string): string {
		const encodedStr = this.metamaskAdapter.web3.eth.abi.encodeParameter('uint256', str);
		const hash = this.metamaskAdapter.web3.utils.keccak256(encodedStr);
		return hash;
	}

	getTxLink(item: APICrossingItem) {
		const foundExplorerUrl = this.getChainExplorerByIndex(item.source_chain);
		if ( foundExplorerUrl ) {
			return (
				<a
					className="ex-link"
					href={ `${foundExplorerUrl}/tx/${item.source_tx}` }
					target="_blank" rel="noopener noreferrer"
				>
					<span>{ compactString(item.source_tx) }</span>
					<img className="i-ex" src={ i_external } alt="" />
				</a>
			)
		}

		return (
			<span>{ compactString(item.source_tx) }</span>
		)
	}
	getInputPinValue(tx: string) {
		const foundInput = this.state.inputPinCodes.find((item) => { return item.tx === tx });
		if ( foundInput ) { return foundInput.pinCode; }

		return '';
	}
	setInputPinValue(tx: string, str: string) {
		let pinCode = str.replaceAll(' ', '').replaceAll(',', '').replaceAll('.', '').replace(/[^0-9]/g, "");
		this.setState({
			inputPinCodes: [
				...this.state.inputPinCodes.filter((item) => { return item.tx !== tx }),
				{
					tx,
					pinCode
				}
			]
		});
	}
	getBurnButtonDisabled(tx: string) {
		const foundInput = this.state.inputPinCodes.find((item) => { return item.tx === tx });
		if ( foundInput && foundInput.pinCode !== '' ) { return false; }

		return true;
	}
	getNotSupportedBurnItem(item: APICrossingItem) {
		if ( this.state.chainId !== item.target_chain ) {
			return (
				<div key={ item.id } className="item">
					<div className="row">
						<div className="mb-2 col-md-3">
							{ this.getTxLink(item) }
						</div>
						<div className="mb-2 col-md-9">
							<button
								className="link-style"
								onClick={(e) => {
									// (window as any).ethereum.request({
									// 	method: 'wallet_switchEthereumChain',
									// 	params: [{ chainId: '0x' + Number(item.target_chain).toString(16) }], // chainId must be in hexadecimal numbers
									// })
									this.store.dispatch(requestChain(item.target_chain))
								}}
							>Switch to <b>{ this.getTargetChainName(item) }</b> to&nbsp;burn wNFT</button>
						</div>
					</div>
				</div>
			)
		} else {
			return (
				<div className="item">
					<div className="row">
						<div className="mb-2 col-auto col-md-2 order-1">
							<img className="align-middle mr-1" src={ i_unknown } alt="" />
						<span>{ item.target_token_id }</span>
						</div>
						<div className="mb-2 mt-3 mt-md-0 col-md-7 order-3 order-md-2">
							<div className="row-sm">
								<div className="col-sm-12 mb-3 mb-md-2">
									<small className="text-red">Chain is not supported so we cannot check status of&nbsp;crossing. Press the button if you sure what are you doing.</small>
								</div>
								<div className="col-sm-auto">
									<button
										className="btn btn-sm btn-red w-100"
										onClick={() => {
											this.setState({
												customTokenModalShow: true,
												customTokenAction: 'burn',
												customTokenInputContractAddress: item.target_contract,
												customTokenInputTokenId: item.target_token_id,
												customTokenInputPinCode: '',
												customTokenInputBurnHash: '',
											});
										}}
									>
										Burn NFT or Claim wNFT
									</button>
								</div>
							</div>
						</div>
						<div className="mb-2 col-auto col-md-3 md-right order-2">
							{ this.getTxLink(item) }
						</div>
					</div>
				</div>
			)
		}
	}
	async burnSubmit(contractAddress: string, tokenId: string) {
		if ( !this.crossingDispatcher ) { return; }

		this.store.dispatch(setLoading({ msg: this.t('Waiting for burn') }));

		let data;
		if ( contractAddress.toLowerCase() === this.crossingDispatcher.spawner.toLowerCase() ) {
			// spawner contract from config (in crossingDispatcher)
			try {
				data = await this.crossingDispatcher.burnToken(tokenId, this.state.userAddress)
			} catch(e: any) {
				this.store.dispatch(setError({
					text: `${this.t('Cannot burn token')}, ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				this.store.dispatch(unsetLoading());
				return;
			}
		} else {
			// other unknown contract
			let spawnerContractABI;
			try {
				spawnerContractABI = getABI(this.metamaskAdapter.chainId || 0, contractAddress, 'spawner');
			} catch(e) {
				console.log(`Cannot load ${contractAddress} wrapper abi:`, e);
				this.store.dispatch(setError({
					text: `Cannot load ${contractAddress} wrapper abi:, ${e}`,
					buttons: undefined,
					links: undefined,
				}));
				this.store.dispatch(unsetLoading());
				return;
			}
			const spawnerContract = new this.metamaskAdapter.web3.eth.Contract(spawnerContractABI, contractAddress);
			try {
				const tx = spawnerContract.methods.burn(tokenId);
				tx.estimateGas({ from: this.state.userAddress });
				data = await tx.send({ from: this.state.userAddress })
			} catch(e: any) {
				this.store.dispatch(setError({
					text: `${this.t('Cannot burn token')}, ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				this.store.dispatch(unsetLoading());
				return;
			}
		}

		this.clearCrossings();
		this.updateCrossings();
		this.updateBurns();

		this.store.dispatch(unsetLoading());
		this.store.dispatch(setInfo({
			title: `${this.t('Token has been burnt')}`,
			text: '',
			buttons: undefined,
			links: [{
				text: `View on ${this.state.explorerName}`,
				url: `${this.state.explorerBaseUrl}/tx/${data.transactionHash}`
			}]
		}));
	}
	getBurnItemBurn(item: APICrossingItem) {
		if ( this.state.chainId !== item.target_chain ) {
			return (
				<div key={ item.id } className="item">
					<div className="row">
						<div className="mb-2 col-md-3">
							{ this.getTxLink(item) }
						</div>
						<div className="mb-2 col-md-9">
							<button
								className="link-style"
								onClick={(e) => {
									// (window as any).ethereum.request({
									// 	method: 'wallet_switchEthereumChain',
									// 	params: [{ chainId: '0x' + Number(item.target_chain).toString(16) }], // chainId must be in hexadecimal numbers
									// })
									this.store.dispatch(requestChain(item.target_chain))
								}}
							>Switch to <b>{ this.getTargetChainName(item) }</b> to&nbsp;burn wNFT</button>
						</div>
					</div>
				</div>
			)
		} else {
			return (

				<div className="item" key={ item.id }>
					<div className="row">
						<div className="mb-2 col-auto col-md-2 order-1">
							<img className="align-middle mr-1" src={ i_fire_red } alt="" />
							<Tippy
								content={ item.target_token_id }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<button
									className="link-style"
									onClick={() => {
										this.fetchAndSetPreviewToken(item);
									}}
								>
									{ compactString(item.target_token_id) }
								</button>
							</Tippy>
						</div>
						<div className="mb-2 mt-3 mt-md-0 col-md-7 order-3 order-md-2">
							<div className="row-sm">
								<div className="col-12 col-sm-4 col-md-auto">
									<button
										className="btn btn-sm btn-outline w-100"
										onClick={(e) => {
											this.store.dispatch(setInfo({
												title: `Burn NFT`,
												text: `Are you sure that you want to burn this NFT?`,
												buttons: [
													{
														text: 'Yes, I am sure',
														clickFunc: () => { this.burnSubmit(item.target_contract, item.target_token_id); }
													},
													{
														text: 'No, not now',
														clickFunc: () => { this.store.dispatch(clearInfo()) }
													},
												],
												links: undefined
											}));
										}}
									>Burn the NFT</button>
								</div>
							</div>
						</div>
						<div className="mb-2 col-auto col-md-3 md-right order-2">
							{ this.getTxLink(item) }
						</div>
					</div>
				</div>
			)
		}
	}
	async unfreezeSubmit(params: {
		keeperAddress: string,
		contractAddress: string,
		tokenId: string,
		targetChainId: number,
		burnTxHash: string,
		pinCode: string,
	}) {
		if ( !this.crossingDispatcher ) { return; }

		this.store.dispatch(setLoading({ msg: this.t('Waiting for unfreeze') }));

		const oraceSignature = await getOracleCrossingBurnSign({ chainId: params.targetChainId, txHash: params.burnTxHash });
		if ( !oraceSignature ) {
			this.store.dispatch(unsetLoading());
			this.store.dispatch(setError({
				text: `Cannot get oracle signature`,
				buttons: undefined,
				links: undefined,
			}));
			return;
		}

		let data;

		if ( params.contractAddress.toLowerCase() === this.crossingDispatcher.spawner.toLowerCase() ) {
			// keeper contract from config (in crossingDispatcher)
			try {
				data = await this.crossingDispatcher.unfreezeToken({
					targetContractAddress: params.contractAddress,
					targetTokenId: params.tokenId,
					secretHash: params.pinCode,
					signature: oraceSignature.oracle_signature,
				})
			} catch(e: any) {
				this.store.dispatch(setError({
					text: `${this.t('Cannot unfreeze token')}, ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				this.store.dispatch(unsetLoading());
				return;
			}
		} else {
			// other unknown contract
			let keeperContractABI;
			try {
				keeperContractABI = getABI(this.metamaskAdapter.chainId || 0, params.keeperAddress, 'keeper');
			} catch(e) {
				console.log(`Cannot load ${params.keeperAddress} keeper abi:`, e);
				this.store.dispatch(setError({
					text: `Cannot load ${params.keeperAddress} keeper abi:, ${e}`,
					buttons: undefined,
					links: undefined,
				}));
				this.store.dispatch(unsetLoading());
				return;
			}
			const keeperContract = new this.metamaskAdapter.web3.eth.Contract(keeperContractABI, params.keeperAddress);

			try {
				const tx = keeperContract.methods.unFreeze(
					params.pinCode,
					params.contractAddress,
					params.tokenId,
					oraceSignature.oracle_signature
				);
				tx.estimateGas({ from: this.state.userAddress });
				data = await tx.send({ from: this.state.userAddress })
			} catch(e: any) {
				this.store.dispatch(setError({
					text: `${this.t('Cannot unfreeze token')}, ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				this.store.dispatch(unsetLoading());
				return;
			}
		}

		this.clearCrossings();
		this.updateCrossings();
		this.updateBurns();

		this.store.dispatch(unsetLoading());
		this.store.dispatch(setInfo({
			title: `${this.t('Token has been unfrozen')}`,
			text: '',
			buttons: undefined,
			links: [{
				text: `View on ${this.state.explorerName}`,
				url: `${this.state.explorerBaseUrl}/tx/${data.transactionHash}`
			}]
		}));
	}
	getBurnItemUnfreeze(item: APICrossingItem) {
		if ( this.state.chainId !== item.source_chain ) {
			return (
				<div key={ item.id } className="item">
					<div className="row">
						<div className="mb-2 col-md-3">
							{ this.getTxLink(item) }
						</div>
						<div className="mb-2 col-md-9">
							<button
								className="link-style"
								onClick={(e) => {
									// (window as any).ethereum.request({
									// 	method: 'wallet_switchEthereumChain',
									// 	params: [{ chainId: '0x' + Number(item.source_chain).toString(16) }], // chainId must be in hexadecimal numbers
									// })
									this.store.dispatch(requestChain(item.source_chain))
								}}
							>Switch to <b>{ this.getSourceChainName(item) }</b> to&nbsp;claim wNFT</button>
						</div>
					</div>
				</div>
			)
		} else {
			return (
				<div className="item" key={ item.id }>
					<div className="row">
						<div className="mb-2 col-auto col-md-2 order-1">
							<img className="align-middle mr-1" src={ i_box } alt="" />
							<Tippy
								content={ item.target_token_id }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span>{ compactString(item.target_token_id) }</span>
							</Tippy>
						</div>
						<div className="mb-2 mt-3 mt-md-0 col-md-7 order-3 order-md-2">
							<div className="row-sm">
								<div className="col-sm-8 mb-2 mb-sm-0">
									<SecretInput
										inputClass="input-sm"
										placeholder="Enter PIN"
										value={ this.getInputPinValue(item.source_tx) }
										onChange={(e) => { this.setInputPinValue(item.source_tx, e.target.value) }}
									/>
								</div>
								<div className="col-sm-auto">
									<button
										className="btn btn-sm w-100"
										disabled={ this.getBurnButtonDisabled(item.source_tx) }
										onClick={async (e) => {
											const pinCode = this.state.inputPinCodes.find((iitem) => { return iitem.tx.toLowerCase() === item.source_tx.toLowerCase() });
											if ( !pinCode ) { return; }

											this.unfreezeSubmit({
												keeperAddress: item.keeper_contract,
												contractAddress: item.target_contract,
												tokenId: item.target_token_id,
												targetChainId: item.target_chain,
												burnTxHash: item.target_burn_txhash,
												pinCode: pinCode.pinCode
											})
										}}
									>Claim wNFT</button>
								</div>
							</div>
						</div>
						<div className="mb-2 col-auto col-md-3 md-right order-2">
							{ this.getTxLink(item) }
						</div>
					</div>
				</div>
			)
		}
	}
	getBurnItem(item: APICrossingItem) {

		if ( item.target_burner_address && item.target_burner_address.toLowerCase().includes('not supported') ) {
			return this.getNotSupportedBurnItem(item);
		}

		if ( item.target_owner.toLowerCase() === this.state.userAddress.toLowerCase() ) {
			return this.getBurnItemBurn(item);
		}

		if ( item.target_burner_address.toLowerCase() === this.state.userAddress.toLowerCase() ) {
			return this.getBurnItemUnfreeze(item);
		}

		return null;
	}
	getBurnBlock() {
		return (
			<div className="c-wrap">
				<div className="c-wrap__header">
					<div className="h4 mt-2">
						NFT to burn
					</div>
					<button
						className="link-style mt-2"
						onClick={() => {
							this.setState({ customTokenModalShow: true })
						}}
					>
						Can't find my NFT key in the list
					</button>
				</div>
				<div className="text-muted mb-2">
					<div> <small>The List of the NFT keys which you have and which you have burnt.</small></div>
					<div> <small>In the list "wNFT for crossing" there are frozen wrapped NFT not to be unfrozen without burning of the NFT keys.</small></div>
				</div>
				<div className="c-wrap__table mt-3">
					{
						this.state.burns
							.sort((item: APICrossingItem, prev: APICrossingItem) => { return prev.sortorder - item.sortorder })
							.map((item) => { return this.getBurnItem(item) })
					}
				</div>
			</div>
		)
	}

	getChainByIndex(idx: number) {
		if ( !this.state.availableChains ) { return undefined; }

		return this.state.availableChains.find((iitem) => { return iitem.chainId === idx });
	}
	getTargetChainName(item: APICrossingItem) {
		const foundChain = this.getChainByIndex(item.target_chain);
		if ( foundChain ) {
			return foundChain.isTestNetwork ? `${foundChain.chainName} testnet` : foundChain.chainName;
		}
		return `Chain ${item.target_chain}`
	}
	getSourceChainName(item: APICrossingItem) {
		const foundChain = this.getChainByIndex(item.source_chain);
		if ( foundChain ) {
			return foundChain.isTestNetwork ? `${foundChain.chainName} testnet` : foundChain.chainName;
		}
		return `Chain ${item.source_chain}`
	}
	getChainExplorerByIndex(idx: number) {
		const foundChain = this.getChainByIndex(idx);
		if ( foundChain ) {
			return foundChain.explorerBaseUrl;
		}
		return undefined
	}
	async mintSubmit(item: APICrossingItem) {
		if ( !this.crossingDispatcher ) { return; }
		if ( this.state.chainId !== item.target_chain ) { return; }

		this.store.dispatch(setLoading({ msg: this.t('Waiting for mint') }));
		const oracleSignature: any = await getOracleCrossingFreezeSign({
			chainId: item.source_chain,
			txHash:  item.source_tx
		});

		if ( !oracleSignature ) {
			this.store.dispatch(setError({
				text: `${this.t('Cannot mint token')} ${this.t('No oracle signature')}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}

		this.crossingDispatcher.mintNewToken({
			targetContract: item.target_contract,
			targetTokenId: item.target_token_id,
			oracleSignature: oracleSignature.oracle_signature,
			userAddress: this.state.userAddress,
		})
			.then((data: any) => {

				this.clearCrossings();
				this.updateCrossings();
				this.updateBurns();

				this.store.dispatch(unsetLoading());
				this.store.dispatch(setInfo({
					text: `${this.t('Token has been minted')} ${data.events.Transfer.address}:${data.events.Transfer.returnValues.tokenId}`,
					buttons: [
						{
							text: 'To the NFT key',
							clickFunc: () => { window.location.href = `/token/${this.state.chainId}/${data.events.Transfer.address}/${data.events.Transfer.returnValues.tokenId}`; }
						},
						{
							text: 'Close',
							clickFunc: () => { this.store.dispatch(clearInfo()) }
						},
					],
					links: [{
						text: `View on ${this.state.explorerName}`,
						url: `${this.state.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
			})
			.catch((e: any) => {
				this.store.dispatch(setError({
					text: `${this.t('Cannot mint token')}, ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				this.store.dispatch(unsetLoading());
				return;
			})
	}
	getFreezedMintButton(item: APICrossingItem) {

		if ( !item.target_owner ) {
			if ( this.state.chainId !== item.target_chain ) {
				return (
					<button
						className="btn btn-sm w-100"
						onClick={(e) => {
							// (window as any).ethereum.request({
							// 	method: 'wallet_switchEthereumChain',
							// 	params: [{ chainId: '0x' + Number(item.target_chain).toString(16) }], // chainId must be in hexadecimal numbers
							// })
							this.store.dispatch(requestChain(item.target_chain))
						}}
					>To the target chain</button>
				)
			} else {
				return (
					<button
						className="btn btn-sm w-100"
						onClick={() => { this.mintSubmit(item); }}
					>Mint NFT key</button>
				)
			}
		}

		if ( item.target_owner.toLowerCase().includes('not supported') ) {
			if ( this.state.chainId !== item.target_chain ) {
				return (
					<button
						className="btn btn-sm btn-red w-100"
						onClick={(e) => {
							// (window as any).ethereum.request({
							// 	method: 'wallet_switchEthereumChain',
							// 	params: [{ chainId: '0x' + Number(item.target_chain).toString(16) }], // chainId must be in hexadecimal numbers
							// })
							this.store.dispatch(requestChain(item.target_chain))
						}}
					>To the target chain</button>
				)
			} else {
				return (
					<button
						className="btn btn-sm btn-red w-100"
						onClick={() => { this.mintSubmit(item); }}
					>Mint NFT key</button>
				)
			}
		}

		if ( this.state.chainId !== item.target_chain ) {
			return (
				<button
					className="btn btn-sm btn-outline w-100"
					onClick={(e) => {
						// (window as any).ethereum.request({
						// 	method: 'wallet_switchEthereumChain',
						// 	params: [{ chainId: '0x' + Number(item.target_chain).toString(16) }], // chainId must be in hexadecimal numbers
						// })
						this.store.dispatch(requestChain(item.target_chain))
					}}
				>To the target chain</button>
			)
		} else {
			return (
				<button
					className="btn btn-sm btn-outline w-100"
					onClick={() => {
						this.setState({ transferToken: {
							assetType: _AssetType.ERC721,
							contractAddress: item.target_contract,
							tokenId: item.target_token_id
						} });
					}}
				>
					Transfer NFT key
				</button>
			);
		}
	}
	getFreezedStatus(item: APICrossingItem) {

		if ( !item.target_owner ) {
			return ( <span className="text-orange">Crossing started</span> )
		}

		if ( item.target_owner.toLowerCase().includes('not supported') ) {
			return ( <small className="text-red">Chain is not supported so we cannot check status of&nbsp;crossing. Press the button if you sure what are you doing.</small> )
		}

		if ( item.target_owner.toLowerCase() === '0x0000000000000000000000000000000000000000' ) {
			return ( <span>Token in target network is burnt</span> )
		}
		return (
			<Link
				className="text-green"
				to={`/token/${item.target_chain}/${item.target_contract}/${item.target_token_id}`}
			>Has NFT in the target chain</Link>
		);
	}
	getFreezedItem(item: APICrossingItem) {
		if (
			item.target_owner &&
			!item.target_owner.toLowerCase().includes('not supported') &&
			item.target_owner.toLowerCase() !== this.state.userAddress.toLowerCase()
		) { return null; }
		return (
			<div className="item">
				<div className="row align-items-start">
					<div className="mb-2 col-sm-3 col-md-2">
						{ this.getTxLink(item) }
					</div>
					<div className="mb-2 col-sm-9 col-md-2">
						<span className="col-legend">Source chain:</span>
						<span className="text-break">{ this.getSourceChainName(item) }</span>
					</div>
				<div className="mb-2 col-sm-9 offset-sm-3 offset-md-0 col-md-2">
					<span className="col-legend">Target chain:</span>
					<span className="text-break">{ this.getTargetChainName(item) }</span>
				</div>
					<div className="mb-2 mt-3 mt-md-0 col-sm-9 offset-sm-3 col-md-3 offset-md-0 col-lg-4">
						{ this.getFreezedStatus(item) }
					</div>
					<div className="mb-2 mt-2 mt-md-0 col-sm-6 offset-sm-3 col-md-3 offset-md-0 col-lg-2">
						{ this.getFreezedMintButton(item) }
					</div>
				</div>
			</div>
		)
	}
	getFreezedBlock() {
		return (
			<div className="c-wrap">
				<div className="c-wrap__header">
					<div className="h4 mt-2">
						wNFT for crossing
					</div>
				</div>
				<div className="text-muted mt-2"> <small>The List of the frozen wrapped NFT with your NFT keys</small></div>

				{
					this.state.crossings.length ?
					(
						<div className="c-wrap__table mt-3">
							<div className="item item-header">
								<div className="row">
									<div className="col-md-2 mb-2">Transaction</div>
									<div className="col-md-2 mb-2">Source chain</div>
									<div className="col-md-2 mb-2">Target chain</div>
									<div className="col-md-3 mb-2">Status</div>
								</div>
							</div>
							{
								this.state.crossings
									.sort((item: APICrossingItem, prev: APICrossingItem) => { return prev.sortorder - item.sortorder })
									.map((item: APICrossingItem) => { return this.getFreezedItem(item) })
							}
						</div>
					) : null
				}
			</div>
		)
	}

	getCheckProofBtn() {
		return (
			<div className="col-12 col-sm-auto mt-3 mt-sm-0">
				<button className="btn btn-outline w-100">Check a Proof</button>
			</div>
		)
	}

	getCustomTokenModalBurnBtnDisabled() {
		return this.state.customTokenInputContractAddress === '' ||
		this.state.customTokenInputTokenId === ''
	}
	getCustomTokenModalClaimBtnDisabled() {
		return this.state.customTokenInputContractAddress === '' ||
		this.state.customTokenInputTokenId === '' ||
		this.state.customTokenInputBurnHash === '' ||
		this.state.customTokenInputPinCode === ''
	}
	getCustomTokenModalSubmitBtn() {
		if ( this.state.customTokenAction === 'burn' ) {
			return (
				<button
					className="btn w-100"
					disabled={ this.getCustomTokenModalBurnBtnDisabled() }
					onClick={async (e) => {
						this.store.dispatch(setInfo({
							title: `Burn NFT`,
							text: `Are you sure that you want to burn this NFT?`,
							buttons: [
								{
									text: 'Yes, I am sure',
									clickFunc: () => { this.burnSubmit(this.state.customTokenInputContractAddress, this.state.customTokenInputTokenId); }
								},
								{
									text: 'No, not now',
									clickFunc: () => { this.store.dispatch(clearInfo()) }
								},
							],
							links: undefined
						}));
					}}
				>Burn NFT key</button>
			)
		} else {
			return (
				<button
					className="btn w-100"
					disabled={ this.getCustomTokenModalClaimBtnDisabled() }
					onClick={async (e) => {
						this.unfreezeSubmit({
							keeperAddress: this.state.customTokenInputKeeperAddress,
							contractAddress: this.state.customTokenInputContractAddress,
							tokenId: this.state.customTokenInputTokenId,
							targetChainId: this.state.customTokenInputChainId,
							burnTxHash: this.state.customTokenInputBurnHash,
							pinCode: this.state.customTokenInputPinCode
						})
					}}
				>Claim the wNFT</button>
			)
		}
	}
	targetChainListOpen = () => {
		setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = (e: any) => {
				if ( !this.targetListRef.current ) { return; }
				if ( e.path && e.path.includes(this.targetListRef.current) ) { return; }
				this.targetChainListClose();
			};
		}, 100);
		this.setState({ targetChainListOpened: true });
	}
	targetChainListClose = () => {
		const body = document.querySelector('body');
		if ( !body ) { return; }
		body.onclick = null;
		this.setState({ targetChainListOpened: false });
	}
	getTargetChain() {

		if ( !this.state.customTokenInputChainId ) {
			return (
				<div
					className="input-control"
					onClick={() => { this.targetChainListOpen() }}
				>
					<span className="empty">Choose a target chain</span>
				</div>
			)
		}

		const foundChain = this.state.targetChains.find((item) => { return item.targetChainId === this.state.customTokenInputChainId });
		if ( !foundChain ) {
			return (
				<div
					className="input-control"
					onClick={() => { this.targetChainListOpen() }}
				>
					<span className="empty">Choose a target chain</span>
				</div>
			)
		}

		return (
			<div
				className="input-control"
				onClick={() => { this.targetChainListOpen() }}
			>
				<span>
					<span className="option-chain">
						<img className="d-inline-block mr-1" src={ foundChain.icon ? foundChain.icon : default_icon } alt="" />
						{ foundChain.name ? foundChain.name : `Chain ${foundChain.targetChainId}` }
					</span>
				</span>
			</div>
		)
	}
	getTargetChainList() {
		if ( !this.state.targetChainListOpened ) { return null; }

		return (
			<ul className="options-list">
				{ this.state.targetChains.map((item) => {
					return (
						<li
							key={ item.targetChainId }
							className={`option ${ this.state.customTokenInputChainId === item.targetChainId ? 'selected' : '' }`}
							onClick={() => { this.setState({ customTokenInputChainId: item.targetChainId, targetChainListOpened: false }) }}
						>
							<span className="option-chain">
								<img className="d-inline-block mr-1" src={ item.icon ? item.icon : default_icon } alt="" />
								{ item.name ? item.name : `Chain ${item.targetChainId}` }
							</span>
						</li>
					)
				}) }
			</ul>
		)
	}
	getCustomTokenModal() {
		if ( !this.state.customTokenModalShow ) { return; }

		return (
			<div className="modal">
				<div
					className="modal__inner"
					onMouseDown={(e) => {
						e.stopPropagation();
						if ((e.target as HTMLTextAreaElement).className === 'modal__inner' || (e.target as HTMLTextAreaElement).className === 'container') {
							this.setState({
								customTokenModalShow: false,
								customTokenAction: 'burn',
								customTokenInputKeeperAddress: '',
								customTokenInputChainId: 0,
								customTokenInputContractAddress: '',
								customTokenInputTokenId: '',
								customTokenInputPinCode: '',
								customTokenInputBurnHash: '',
							});
						}
					}}
				>
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
							<div
								className="modal__close"
								onClick={() => {
									this.setState({
										customTokenModalShow: false,
										customTokenAction: 'burn',
										customTokenInputKeeperAddress: '',
										customTokenInputChainId: 0,
										customTokenInputContractAddress: '',
										customTokenInputTokenId: '',
										customTokenInputPinCode: '',
										customTokenInputBurnHash: '',
									})
								}}
							>
								<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
									<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
								</svg>
							</div>
							<div className="c-add">
								<div className="h2">I want to...</div>
								<div className="c-add__form">
									<div className="row row-sm mb-2">
										<div className="col-auto my-2">
											<label className="checkbox">
												<input
													type="radio"
													name="token-action"
													checked={ this.state.customTokenAction === 'burn' }
													onChange={() => { this.setState({ customTokenAction: 'burn' }) }}
												/>
												<span className="check"> </span>
												<span className="check-text"> <b>Burn an NFT</b></span>
											</label>
										</div>
										<div className="col-auto my-2">
											<label className="checkbox">
												<input
													type="radio"
													name="token-action"
													checked={ this.state.customTokenAction === 'claim' }
													onChange={() => { this.setState({ customTokenAction: 'claim' }) }}
												/>
												<span className="check"> </span>
												<span className="check-text"> <b>Claim a wNFT</b></span>
											</label>
										</div>
									</div>

									<div className="mb-6">
										<div className="text-green mb-2 mb-sm-0"> <small>Check current network: BSC</small></div>
										{
											this.state.customTokenAction === 'burn' ?
												( <div className="text-muted"> <small>To get wNFT in original chain you should burn NFT key. Enter the data of your NFT key in fields</small></div> ) : null
										}
										{
											this.state.customTokenAction === 'claim' ?
												( <div className="text-muted"> <small>To unfreeze wrapped NFT in original chain you should enter data in form and sign the transaction</small></div> ) : null
										}
									</div>

									<div className="form-row">
										{
											this.state.customTokenAction === 'claim' ? (
												<div className="col col-12 col-sm-7">
													<div className="input-group">
														<label className="input-label">
															Keeper address
															<Tippy
																content={ 'Contract which stores frozen wNFT' }
																appendTo={ document.getElementsByClassName("wrapper")[0] }
																trigger='mouseenter'
																interactive={ false }
																arrow={ false }
																maxWidth={ 512 }
															>
																<span className="i-tip"></span>
															</Tippy>
														</label>
														<input
															className="input-control"
															type="text"
															placeholder="Paste here"
															value={ this.state.customTokenInputKeeperAddress }
															onChange={(e) => {
																this.setState({ customTokenInputKeeperAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") })
															}}
														/>
													</div>
												</div>
											) : null
										}
										{
											this.state.customTokenAction === 'claim' ? (
												<div className="col col-12 col-sm-5">
													<div className="input-group">
														<label className="input-label">
															Target chain ID
															<Tippy
																content={ 'Blockchain where NFT key had been created' }
																appendTo={ document.getElementsByClassName("wrapper")[0] }
																trigger='mouseenter'
																interactive={ false }
																arrow={ false }
																maxWidth={ 512 }
															>
																<span className="i-tip"></span>
															</Tippy>
														</label>
														<div
															className="select-custom"
															ref={ this.targetListRef }
														>
															{ this.getTargetChain() }
															{ this.getTargetChainList() }
														</div>
														{/* <input
															className="input-control"
															type="text"
															placeholder="Paste here"
															value={ this.state.customTokenInputChainId }
															onChange={(e) => {
																this.setState({ customTokenInputChainId: e.target.value.toLowerCase().replace(/[^0-9]/g, "") })
															}}
														/> */}
													</div>
												</div>
											) : null
										}
										<div className="col col-12 col-sm-7">
											<div className="input-group">
												<label className="input-label">
													Contract address
													{
														this.state.customTokenAction === 'claim' ?
														(
															<Tippy
																content={ 'NFT key contract address in target chain' }
																appendTo={ document.getElementsByClassName("wrapper")[0] }
																trigger='mouseenter'
																interactive={ false }
																arrow={ false }
																maxWidth={ 512 }
															>
																<span className="i-tip"></span>
															</Tippy>
														) : null
													}
													{
														this.state.customTokenAction === 'burn' ?
														(
															<Tippy
																content={ 'Contract address of NFT key' }
																appendTo={ document.getElementsByClassName("wrapper")[0] }
																trigger='mouseenter'
																interactive={ false }
																arrow={ false }
																maxWidth={ 512 }
															>
																<span className="i-tip"></span>
															</Tippy>
														) : null
													}
												</label>
												<input
													className="input-control"
													type="text"
													placeholder="Paste here"
													value={ this.state.customTokenInputContractAddress }
													onChange={(e) => {
														this.setState({ customTokenInputContractAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") })
													}}
												/>
											</div>
										</div>
										<div className="col col-12 col-sm-5">
											<div className="input-group">
												<label className="input-label">
													Token ID
													{
														this.state.customTokenAction === 'claim' ?
														(
															<Tippy
																content={ 'NFT key token ID in target chain' }
																appendTo={ document.getElementsByClassName("wrapper")[0] }
																trigger='mouseenter'
																interactive={ false }
																arrow={ false }
																maxWidth={ 512 }
															>
																<span className="i-tip"></span>
															</Tippy>
														) : null
													}
													{
														this.state.customTokenAction === 'burn' ?
														(
															<Tippy
																content={ 'Token id of NFT key' }
																appendTo={ document.getElementsByClassName("wrapper")[0] }
																trigger='mouseenter'
																interactive={ false }
																arrow={ false }
																maxWidth={ 512 }
															>
																<span className="i-tip"></span>
															</Tippy>
														) : null
													}
												</label>
												<input
													className="input-control"
													type="text"
													placeholder="Paste here"
													value={ this.state.customTokenInputTokenId }
													onChange={(e) => {
														this.setState({ customTokenInputTokenId: e.target.value.toLowerCase().replace(/[^0-9]/g, "") })
													}}
												/>
											</div>
										</div>
										{
											this.state.customTokenAction === 'claim' ? (
												<div className="col col-12">
													<div className="input-group">
														<label className="input-label">
															PIN-code
															{
																this.state.customTokenAction === 'claim' ?
																(
																	<Tippy
																		content={ 'Secret pin-code set up in time of the freezing to unfreeze wrapped NFT in the original blockchain' }
																		appendTo={ document.getElementsByClassName("wrapper")[0] }
																		trigger='mouseenter'
																		interactive={ false }
																		arrow={ false }
																		maxWidth={ 512 }
																	>
																		<span className="i-tip"></span>
																	</Tippy>
																) : null
															}
														</label>
														<SecretInput
															placeholder="Paste here"
															value={ this.state.customTokenInputPinCode }
															onChange={(e) => {
																this.setState({ customTokenInputPinCode: e.target.value })
															}}
														/>
													</div>
												</div>
											) : null
										}
										{
											this.state.customTokenAction === 'claim' ? (
												<div className="col col-12">
													<div className="input-group">
														<label className="input-label">
															Transaction hash of burning
															{
																this.state.customTokenAction === 'claim' ?
																(
																	<Tippy
																		content={ 'Hash of the NFT key burning transaction in target chain' }
																		appendTo={ document.getElementsByClassName("wrapper")[0] }
																		trigger='mouseenter'
																		interactive={ false }
																		arrow={ false }
																		maxWidth={ 512 }
																	>
																		<span className="i-tip"></span>
																	</Tippy>
																) : null
															}
														</label>
														<input
															className="input-control"
															type="text"
															placeholder="Paste here"
															value={ this.state.customTokenInputBurnHash }
															onChange={(e) => {
																this.setState({ customTokenInputBurnHash: e.target.value })
															}}
														/>
													</div>
												</div>
											) : null
										}
										<div className="col col-12 col-sm-4 mt-3">
											{ this.getCustomTokenModalSubmitBtn() }
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	fetchAndSetPreviewToken(item: APICrossingItem) {
		getOriginalToken({
			chainId: item.target_chain,
			metamaskAdapter: this.metamaskAdapter,
			contractAddress: item.target_contract,
			tokenId: item.target_token_id,
			assetType: _AssetType.ERC721,
			userAddress: this.state.userAddress,
			t: this.t
		})
			.then((data) => {
				this.setState({
					previewToken: {
						token: data,
						id: item.id
					}
				})
			})
			.catch((e) => {
				this.store.dispatch(setError({
					text: 'Cannot connect to contract',
					buttons: undefined,
					links: undefined,
				}));
			})
	}
	getPreviewTokenModal() {
		if ( !this.state.previewToken ) { return; }

		return (
			<div className="modal">
				<div
					className="modal__inner"
					onMouseDown={(e) => {
						e.stopPropagation();
						if ((e.target as HTMLTextAreaElement).className === 'modal__inner' || (e.target as HTMLTextAreaElement).className === 'container') {
							this.setState({
								previewToken: undefined,
							});
						}
					}}
				>
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
							<div
								className="modal__close"
								onClick={() => {
									this.setState({
										previewToken: undefined,
									})
								}}
							>
								<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
									<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
								</svg>
							</div>
							<div className="c-add">
								{/* <div className="c-add__text">
									<div className="h2">ID { this.state.previewToken.id }</div>
								</div> */}
								<div className="c-add__form">
									<div className="row">
										<div className="col-12">
											<TokenInList
												metamaskAdapter={this.metamaskAdapter}
												store={this.store}
												tokenType={TokenRenderType.discovered}
												originalToken={ this.state.previewToken.token }
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		return (
			<React.Fragment>
				<main className="s-main">
					<img className="cr-bg-1" src={ i_bgcrossing1 } alt="" />
					<img className="cr-bg-2" src={ i_bgcrossing2 } alt="" />
					<div className="container">
						<div className="row">
							<div className="col-lg-10">
								<div className="wrap__header">
									<div className="row justify-content-between">
										<div className="col-auto">
											<div className="h3 mt-0">My Crossings</div>
										</div>
										{/* { this.getCheckProofBtn() } */}
									</div>
								</div>
								{ this.getBurnBlock() }
							</div>
						</div>
						{ this.getFreezedBlock() }
						<div className="c-wrap">
							<div className="mb-2">
								<p>With this dApp we try to test various Zero-Knowledge &amp; Crosschain mechanics using programmable NFTs. Detailed instructions on how the dApp works for today <a href="https://docs.envelop.is/tutorials/dapps/cross-nft/version-0/instruction" target="_blank" rel="noopener noreferrer">can be found here</a>.</p>
								<p>If you have any comments and/or additions to the work, please send us a chat message: <a href="https://t.me/envelop_en" target="_blank" rel="noopener noreferrer">https://t.me/envelop_en</a> with hashtag #ZKNFT or #CrossNFT.</p>
							</div>
						</div>
					</div>
				</main>
				{ this.getCustomTokenModal() }
				{ this.getPreviewTokenModal() }
				{
					this.state.transferToken ?
					(
						<TransferPopup
							store={ this.store }
							metamaskAdapter={ this.metamaskAdapter }
							originalToken={ this.state.transferToken }
							wrappedToken={ undefined }
							history={ this.props.history }
							closePopup={()=>{
								this.setState({ transferToken: undefined })
							}}
						/>
					)
					: null
				}
			</React.Fragment>
		)
	}
}

export default withTranslation("translations")(withRouter(CrossingList));
