
import React               from 'react';
import { withTranslation } from "react-i18next";
import {
	ERC20ContractParamsType
} from '../../models/BlockchainAdapter';

import default_icon      from '../../static/pics/coins/_default.svg';
import icon_i_arrow_down from '../../static/pics/icons/i-arrow-down.svg';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type CoinSelectorProps = {
	store        : any,
	tokens       : Array<ERC20ContractParamsType>,
	selectedToken: string,
	onChange     : (address: string) => void,
}
type CoinSelectorState = {
	listOpened: boolean,
	cursorOnList: boolean,
}

class CoinSelector extends React.Component<CoinSelectorProps, CoinSelectorState> {

	store           : any;
	unsubscribe!    : Function;
	t               : any;
	onChange        : (address: string) => void;
	selectorBlockRef: React.RefObject<HTMLInputElement>;

	constructor(props: CoinSelectorProps) {
		super(props);

		this.store            = props.store;
		this.onChange         = props.onChange;

		this.selectorBlockRef = React.createRef();

		this.state = {
			listOpened: false,
			cursorOnList: false,
		}
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {

			this.setState({
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	closeList = () => {
		setTimeout(() => {
			if ( this.state.cursorOnList ) { return; }
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = null;
			this.setState({ listOpened: false });
		}, 100);
	}
	openList = () => {
		// setTimeout(() => {
			const body = document.querySelector('body');
			if ( !body ) { return; }
			body.onclick = (e: any) => {
				if ( !this.selectorBlockRef.current ) { return; }
				if ( e.path && e.path.includes(this.selectorBlockRef.current) ) { return; }
				this.closeList();
			};
		// }, 100);
		this.setState({ listOpened: true });
	}

	render() {
		let selectedTokenObj;
		if ( this.props.selectedToken === '' ) {
			selectedTokenObj = {
				address: '',
				icon: default_icon,
				symbol: '',
			}
		}
		const foundToken = this.props.tokens.filter((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === this.props.selectedToken.toLowerCase()
		});
		if ( foundToken.length ) {
			selectedTokenObj = foundToken[0];
		} else {
			selectedTokenObj = {
				address: '',
				icon: default_icon,
				symbol: '',
			}
		}

		return (
			<div
				className="select-coin"
				ref={ this.selectorBlockRef }
				onMouseLeave={() => { this.closeList() }}
			>

				<div
					className="select-coin__value"
					onMouseEnter={() => { this.openList() }}
					onClick={() => { this.openList() }}
				>

					<span className="field-unit">
						<span className="i-coin"><img src={ selectedTokenObj.icon } alt="" /></span>
						{ selectedTokenObj.symbol }
					</span>
					<img className="arrow" src={ icon_i_arrow_down } alt="" />
				</div>

				{
					this.state.listOpened ?
					(
						<ul
							className="select-coin__list"
							onMouseEnter={() => { this.setState({ cursorOnList: true }) }}
							onMouseLeave={() => { this.setState({ cursorOnList: false }) }}
						>
						{
							this.props.tokens.map((item) => {
								return (
									<li
										key={ item.address }
										onClick={() => { this.onChange(item.address) }}
									><span className="field-unit"><span className="i-coin"><img src={ item.icon } alt="" /></span>{ item.symbol }</span></li>
								)
							})
						}
					</ul> ) : null
				}

			</div>
		)
	}
}

export default withTranslation("translations")(CoinSelector);