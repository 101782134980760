
import React from 'react';
import { createStore } from 'redux';
import config from '../../config.json';
import {
	BrowserRouter as Router,
	Route,
	Switch,
} from "react-router-dom";
import 'tippy.js/dist/tippy.css';

import Header              from '../Header';
import Footer              from '../Footer';
import MainPage            from '../MainPage';
import TokenPreviewWrapper from '../TokenPreviewWrapper';
import TokenList           from '../TokenList';
import SAFTPage            from '../SAFTPage';
import ErrorPage           from '../ErrorPage';
import TokenWrapPage       from '../TokenWrapPage';
import MyRoyaltiesPage     from '../MyRoyaltiesPage';
import WhiteListPage       from '../WhiteListPage';
import CrossingList        from '../CrossingList';

import {
	MetamaskAdapter,
	WrappedTokenType
} from '../../models/BlockchainAdapter';
import {
	AdvancedLoaderType,
	clearError,
	clearInfo,
	reducer,
	setAuthMethod,
} from '../../reducers';

import icon_loading        from '../../static/pics/mascot/loading.png';
import icon_info           from '../../static/pics/mascot/info.png';
import icon_error          from '../../static/pics/mascot/error.png';
import i_external_green_sm from '../../static/pics/icons/i-external-green-sm.svg';
import metamask_icon       from '../../static/pics/i-metamask.svg';

import { withTranslation } from "react-i18next";
import AdvancedLoader      from '../AdvancedLoader';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type AppParamsType = {
	i18n: any,
	t   : any,
}
type AppState = {
	currentPage: string,
	loading    : string,
	advancedLoading: undefined | AdvancedLoaderType,
	error      : undefined | {
		title?: string,
		text  : string | Array<string>,
		buttons: undefined | Array<{
			text: string,
			clickFunc: Function,
		}>,
		links: undefined | Array<{
			text: string,
			url : string,
		}>,
	},
	info       : undefined | {
		title?: string,
		text  : string | Array<string>,
		buttons: undefined | Array<{
			text: string,
			clickFunc: Function,
		}>,
		links: undefined | Array<{
			text: string,
			url : string,
		}>,
	},
	success?   : {
		token          : WrappedTokenType,
		icon           : string,
		text           : string,
		transactionHash: string,
	},
	authMethodSelector: boolean,
	canCloseAuthMethodSelector: boolean,
	infoMessages: Array<{
		text: string;
		link_url: string;
		link_text: string;
		isClosable: boolean;
		closed?:boolean
	}>;
}

class App extends React.Component<AppParamsType> {
	store          : any;
	metamaskAdapter: MetamaskAdapter;
	unsubscribe!   : Function;
	state          : AppState;

	i18n: any;
	t   : any;

	constructor(props: AppParamsType) {
		super(props);

		this.i18n = this.props.i18n;
		this.t    = this.props.t;

		if ( process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT.toLowerCase() === 'production' ) {
			this.store = createStore(reducer);
		} else {
			this.store = createStore(reducer,(window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());
		}
		this.metamaskAdapter = new MetamaskAdapter({ store: this.store, t: this.t })

		this.state = {
			currentPage               : this.store.getState().currentPage,
			loading                   : this.store.getState()._loading,
			advancedLoading           : this.store.getState()._advancedLoading,
			error                     : this.store.getState()._error,
			info                      : this.store.getState()._info,
			success                   : this.store.getState()._success,
			authMethodSelector        : false,
			canCloseAuthMethodSelector: true,
			infoMessages              : [
				...(config.INFO_MESSAGES || []),
				...(this.store.getState().metamaskAdapter.INFO_MESSAGES || [])
			]
		};
	}

	async componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {

			// if ( this.state.currentPage !== this.store.getState().currentPage ) {
			// 	if ( this.store.getState().currentPage === 'list' ) {
			// 		window.scrollTo( 0, 0 );
			// 	}
			// }

			this.setState({
				currentPage      : this.store.getState().currentPage,
				loading          : this.store.getState()._loading,
				advancedLoading  : this.store.getState()._advancedLoading,
				error            : this.store.getState()._error,
				info             : this.store.getState()._info,
				success          : this.store.getState()._success,
				infoMessages     : [
					...(config.INFO_MESSAGES || []),
					...(this.store.getState().metamaskAdapter.INFO_MESSAGES || [])
				]
			});
		});

		// const prevAuthMethod = localStorageGet('provider_type');
		// if ( prevAuthMethod ) {
		// 	this.store.dispatch(setAuthMethod( prevAuthMethod ));
		// 	await this.metamaskAdapter.connect();
		// }
 	}
 	componentWillUnmount() { this.unsubscribe(); }

	getInfoMessages() {
		const messagesToRender = this.state.infoMessages.filter((item) => { return !item.closed });

		if ( !messagesToRender.length ) { return null; }

		return (
			<div className="s-header-banner" style={{ zIndex: 2 }}>
				<div className="container">
					{
						messagesToRender
							.map((item) => {
								return (
									<div className="content" key={ item.text }>
										<p>{ item.text }</p>
										{
											item.link_url ?
											(<a className="btn btn-sm btn-outline" href={ item.link_url } target="_blank" rel="noopener noreferrer">{ item.link_text }</a>) : null
										}

										{
											item.isClosable ? (
												<button
													className="btn-close"
													onClick={() => {
														this.setState({
															infoMessages: [
																...this.state.infoMessages.filter((iitem) => { return item.text !== iitem.text || item.link_url !== iitem.link_url }),
																{
																	...item,
																	closed: true
																}
															]
														})
													}}
												>
													<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fillRule="evenodd" clipRule="evenodd" d="M15.0274 15.9961L0.000304471 0.736269L0.725342 0L15.7524 15.2599L15.0274 15.9961Z" fill="#141616"></path>
														<path fillRule="evenodd" clipRule="evenodd" d="M0.976641 16L16 0.736446L15.2751 0L0.251784 15.2636L0.976641 16Z" fill="#141616"></path>
													</svg>
												</button>
											) : null
										}
									</div>
								)
							})
					}
				</div>
			</div>
		)
	}

	getOverlays() {

		if ( this.state.advancedLoading ) {
			return ( <AdvancedLoader data={ this.state.advancedLoading } /> )
		}

		const getErrorBtns = (msg: string) => {
			if ( msg === 'error' ) {
				if (this.state.error?.buttons) {
					return this.state.error.buttons.map((item, idx) => {
						let btnClass = '';
						if ( this.state.error && this.state.error.buttons && idx + 1 === this.state.error.buttons.length ) {
							btnClass = 'btn btn-outline'
						} else {
							btnClass = 'btn btn-grad'
						}
						return (
							<div
								className="col-12 col-sm-auto mb-3 mb-md-0"
								key={ item.text }
							>
								<button
									className={ btnClass }
									onClick={() => {
										item.clickFunc();
									}}
								>{ item.text }</button>
							</div>
						)
					})
				} else {
					return (
						<div
							className="col-12 col-sm-auto mb-3 mb-md-0"
							key={ '_default' }
						>
							<button
								className="btn btn-grad"
								onClick={() => {
									this.store.dispatch(clearError());
								}}
							>{ this.t('ACCEPT THIS FACT') }</button>
						</div>
					)
				}
			}
			if ( msg === 'info' ) {
				if (this.state.info?.buttons) {
					return this.state.info.buttons.map((item, idx) => {
						let btnClass = '';
						if ( this.state.info && this.state.info.buttons && idx + 1 === this.state.info.buttons.length ) {
							btnClass = 'btn btn-outline'
						} else {
							btnClass = 'btn btn-grad'
						}
						return (
							<div
								className="col-12 col-sm-auto mb-3 mb-md-0"
								key={ item.text }
							>
								<button
									className={ btnClass }
									onClick={() => {
										item.clickFunc();
									}}
								>{ item.text }</button>
							</div>
						)
					})
				} else {
					return (
						<div
							className="col-12 col-sm-auto mb-3 mb-md-0"
							key={ '_default' }
						>
							<button
								className="btn btn-grad"
								onClick={() => {
									this.store.dispatch(clearInfo());
								}}
							>{ this.t('ACCEPT THIS FACT') }</button>
						</div>
					)
				}
			}
		}
		const getLinks = (msg: string) => {
			if ( msg === 'error' ) {
				if (this.state.error?.links) {
					return this.state.error.links.map((item) => {
						return (
							<a
								className="ex-link mr-3"
								key={ item.url }
								href={ item.url }
								target="_blank" rel="noopener noreferrer"
							>
								Transaction
								<img className="i-ex" src={ i_external_green_sm } alt="" />
							</a>
						)
					})
				}
			}
			if ( msg === 'info' ) {
				if (this.state.info?.links) {
					return this.state.info.links.map((item) => {
						return (
							<a
								className="ex-link mr-3"
								key={ item.url }
								href={ item.url }
								target="_blank" rel="noopener noreferrer"
							>
								Transaction
								<img className="i-ex" src={ i_external_green_sm } alt="" />
							</a>
						)
					})
				}
			}
		}

		if ( this.state.error ) {
			return (
				<div className="modal">
				<div className="modal__inner">
				<div className="modal__bg"></div>
				<div className="container">
				<div className="modal__content">
					<div className="c-info">
						<div className="c-info__img"><img src={ icon_error } alt="" /></div>
						<div className="c-info__text">
							<div className="h2">{ this.state.error.title || this.t('Error Screen') }</div>
							<p>{ getLinks('error') }</p>
							{
								this.state.error.text instanceof Array ?
								this.state.error.text.map((item) => { return (<p>{ item }</p>) }) :
								( <p>{ this.state.error.text }</p> )
							}
							<div className="modal__btns">
								{ getErrorBtns('error') }

							</div>
						</div>
					</div>
				</div>
				</div>
				</div>
				</div>
			)
		}
		if ( this.state.info ) {
			return (
				<div className="modal">
				<div className="modal__inner">
				<div className="modal__bg"></div>
				<div className="container">
				<div className="modal__content">
					<div className="c-info">
						<div className="c-info__img"><img src={ icon_info } alt="" /></div>
						<div className="c-info__text">
							<div className="h2">{ this.state.info.title }</div>
							<p>{ getLinks('info') }</p>
							{
								this.state.info.text instanceof Array ?
								this.state.info.text.map((item) => { return (<p>{ item }</p>) }) :
								( <p>{ this.state.info.text }</p> )
							}
							<div className="modal__btns">
								{ getErrorBtns('info') }
							</div>
						</div>
					</div>
				</div>
				</div>
				</div>
				</div>
			)
		}
		if ( this.state.loading ) {
			return (
				<div className="modal">
				<div className="modal__inner">
				<div className="modal__bg"></div>
				<div className="container">
				<div className="modal__content">
					<div className="c-info only-title">
						<div className="c-info__img"><img src={ icon_loading } alt="" /></div>
						<div className="c-info__text">
							<div className="h2">
								{ this.state.loading }
								<span className="loading-dots"><span>.</span><span>.</span><span>.</span></span>
							</div>
						</div>
					</div>
				</div>
				</div>
				</div>
				</div>
			)
		}
	}
	getAuthMethodSelector() {
		if ( !this.state.authMethodSelector ) { return '' }

		return (
			<div className="modal">
			<div className="modal__inner" onClick={ (e) => {
				e.stopPropagation();
				if ((e.target as HTMLTextAreaElement).className === 'modal__inner') {
					if ( this.state.canCloseAuthMethodSelector ) {
						this.setState({ authMethodSelector: false });
					} else {
						this.setState({ authMethodSelector: false });
						window.location.href = "/";
					}
				}
			}}>
			<div className="modal__bg"></div>
			<div className="container">
			<div className="modal__content">

				<div
					className="modal__close"
					onClick={() => {
						if ( this.state.canCloseAuthMethodSelector ) {
							this.setState({ authMethodSelector: false });
						} else {
							this.setState({ authMethodSelector: false });
							window.location.href = "/";
						}
					}}
				>
					<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
						<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
					</svg>
				</div>
				<div className="modal__header">
					<div className="h2">Choose your wallet</div>
				</div>
				<div className="c-connect">
					<div className="modal__btns">
						<div className="col mb-4 mb-sm-0">
							<button
								className="btn-wallet"
								style={{ width: '100%' }}
								onClick={async () => {
									this.store.dispatch(setAuthMethod('metamask'));
									await this.metamaskAdapter.connect();
									this.setState({ authMethodSelector: false });
								}}
							>
								<span className="img">
									<img src={ metamask_icon } alt="" />
								</span>
								<span>Metamask / Other Web3 wallets</span>
							</button>
						</div>
						{/* <div className="col">
						<button
									className="btn-wallet"
									style={{ width: '100%' }}
									onClick={async () => {
										this.store.dispatch(setAuthMethod('walletconnect'));
										await this.metamaskAdapter.connect();
										this.setState({ authMethodSelector: false });
									}}
								>
								<span className="img">
									<img src={ walletconnect_icon } alt="" />
								</span>
								<span>Walletconnect</span>
							</button>
						</div> */}
					</div>
				</div>
			</div>
			</div>
			</div>
			</div>
		)
	}

	render() {

		return (
			<Router>
				{ this.getInfoMessages() }
				<Header
					store           = { this.store }
					metamaskAdapter = { this.metamaskAdapter }
					showAuthMethodSelector = {() => { this.setState({ authMethodSelector: true }) }}
				/>

				<Switch>
					<Route path="/list">
						<TokenList
							store           = { this.store }
							metamaskAdapter = { this.metamaskAdapter }
							showAuthMethodSelector = {() => { this.setState({ authMethodSelector: true, canCloseAuthMethodSelector: false }) }}
						/>
					</Route>
					<Route path="/token/:chainId/:contractAddress/:tokenId">
						<TokenPreviewWrapper
							store           = { this.store }
							metamaskAdapter = { this.metamaskAdapter }
							showAuthMethodSelector = {( canClose: boolean ) => { this.setState({ authMethodSelector: true, canCloseAuthMethodSelector: canClose }) }}
						/>
					</Route>
					<Route path="/wrap/:chainId?/:contractAddress?/:tokenId?">
						<TokenWrapPage
							store           = { this.store }
							metamaskAdapter = { this.metamaskAdapter }
							showAuthMethodSelector = {( canClose: boolean ) => { this.setState({ authMethodSelector: true, canCloseAuthMethodSelector: canClose }) }}
						/>
					</Route>

					<Route path="/" exact>
						<MainPage store = { this.store } />
					</Route>

					<Route path="/saft" exact>
						<SAFTPage
							store = { this.store }
							metamaskAdapter = { this.metamaskAdapter }
							showAuthMethodSelector = {( canClose: boolean ) => { this.setState({ authMethodSelector: true, canCloseAuthMethodSelector: canClose }) }}
							hideAuthMethodSelector = {() => { this.setState({ authMethodSelector: false }) }}
						/>
					</Route>

					<Route path="/royalty" exact>
						<MyRoyaltiesPage
							store = { this.store }
							metamaskAdapter = { this.metamaskAdapter }
							showAuthMethodSelector = {( canClose: boolean ) => { this.setState({ authMethodSelector: true, canCloseAuthMethodSelector: canClose }) }}
						/>
					</Route>
					<Route path="/whitelist" exact>
						<WhiteListPage
							store = { this.store }
							metamaskAdapter = { this.metamaskAdapter }
							showAuthMethodSelector = {( canClose: boolean ) => { this.setState({ authMethodSelector: true, canCloseAuthMethodSelector: canClose }) }}
						/>
					</Route>
					<Route path="/crossings" exact>
						<CrossingList
							store = { this.store }
							metamaskAdapter = { this.metamaskAdapter }
							showAuthMethodSelector = {( canClose: boolean ) => { this.setState({ authMethodSelector: true, canCloseAuthMethodSelector: canClose }) }}
						/>
					</Route>
					{/* <Route path="/mint" exact>
						<MintPage
							store = { this.store }
							metamaskAdapter = { this.metamaskAdapter }
							showAuthMethodSelector = {( canClose: boolean ) => { this.setState({ authMethodSelector: true, canCloseAuthMethodSelector: canClose }) }}
						/>
					</Route> */}

					<Route path="*">
						<ErrorPage store = { this.store } />
					</Route>
				</Switch>

				{ this.getAuthMethodSelector() }
				{ this.getOverlays() }
				<Footer />
			</Router>
		)
	}
}

export default withTranslation("translations")(App);