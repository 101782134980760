
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
import config from '../../config.json';
import { Contract } from 'web3-eth-contract';
import CrossingDispatcher from './crossingDispatcher';
import NftMinterContract from './nftmintercontract';
import {
	ERC20Contract,
	loadERC721TokenAll,
	removeERC721Token,
	WrapperContract,
	SAFTDispatcher
} from '.';

import {
	setError,
	resetAppData,

	metamaskConnectionSuccess,
	metamaskConnectionRejected,
	metamaskConnectionNotInstalled,
	metamaskSetChainParams,

	updateNativeBalance,

	metamaskSetAvailableChains,
	setAuthMethod,
	setLoading,
	unsetLoading,
	clearError,
	requestChain,
	incompleteTokensRemove,
	wrappedTokensClear,
	collateralWhitelistAdd,
	originalTokensBlacklistAdd,
	unsetAuthMethod,
	setInfo,
	clearInfo,
} from '../../reducers';

import {
	decodeAssetTypeFromString,
	_Asset,
	_AssetType
} from './_types';

import default_icon from '../../static/pics/coins/_default.svg';

import {
	getABI,
	localStorageGet,
	localStorageRemove,
	localStorageSet
} from '../_utils';

import { SafeAppProvider } from '@safe-global/safe-apps-provider';
import SafeAppsSDK from '@safe-global/safe-apps-sdk';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type MetamaskAdapterPropsType = {
	store: any,
	t    : any,
}
export type ChainParamsType = {
	chainId?                      : number | undefined,
	chainName                     : string,
	chainColorCode                : string,
	chainRPCUrl                   : string,
	networkTokenTicket            : string,
	EIPPrefix                     : string,
	networkTokenDecimals          : number | undefined,
	networkTokenIcon?             : string | undefined,
	networkIcon?                  : string | undefined,
	wrapperContract               : string,
	checkerContract?              : string,
	WNFTStorageContracts          : Array<{ address: string, standart: string }>,
	supportedERC20Tokens          : Array<string>,
	isTestNetwork                 : Boolean;
	explorerBaseUrl               : string;
	marketplaceUrl?               : string;
	explorerName                  : string;
	minterContract?               : string;
	crossing?                     : {
		keeper      : string,
		spawner     : string,
		targetChains: Array<{ targetChainId: number, address: string, icon?: string, name?: string }>,
	};
	nftMinterContract721?         : string;
	nftMinterContract1155?        : string;
	batchWorker?                  : string;
	batchWrapSupportedERC20Tokens?: Array<string>;
	subscriptionAgent?            : string,
	INFO_MESSAGES?                : Array<{
		text: string;
		link_url: string;
		link_text: string;
		isClosable: boolean;
	}>;
};

export default class MetamaskAdapter {

	store           : any;
	web3!           : Web3;
	wcProvider!     : any;
	availiableChains: Array<ChainParamsType>;
	chainId?        : number;
	chainConfig!    : ChainParamsType;
	userAddress!    : string;
	wrapperContract!: WrapperContract;
	whitelistContract!: Contract;
	erc20CollateralTokens: Array<ERC20Contract>;
	unsubscribe     : () => {};
	chainChangeRequested: boolean;
	crossingDispatcher?: CrossingDispatcher;
	SAFTDispatcher?: SAFTDispatcher;
	nftMinterContract?: NftMinterContract;

	t: any;

	accessAtempts   : number;

	constructor(props: MetamaskAdapterPropsType) {
		this.store = props.store;
		this.t = props.t;
		this.availiableChains = config.CHAIN_SPECIFIC_DATA;
		this.store.dispatch(metamaskSetAvailableChains(
			this.availiableChains.map((item) => {
				let networkIcon   = default_icon;
				try { networkIcon = require(`../../static/pics/networks/${item.chainId}.jpeg`).default } catch (ignored) {}
				try { networkIcon = require(`../../static/pics/networks/${item.chainId}.jpg` ).default } catch (ignored) {}
				try { networkIcon = require(`../../static/pics/networks/${item.chainId}.png` ).default } catch (ignored) {}
				try { networkIcon = require(`../../static/pics/networks/${item.chainId}.svg` ).default } catch (ignored) {}
				return {
					...item,
					networkIcon
				}
			})
		));
		this.erc20CollateralTokens = [];
		this.chainChangeRequested = false;

		this.accessAtempts = 0;

		const sdk = new SafeAppsSDK();
		Promise.race([
			new Promise((res) => { sdk.safe.getInfo().then((data) => { res(data) }) }),
			new Promise((res, rej) => { setTimeout(() => { rej() }, 500) }),
		])
			.then(() => {
				this.connect();
			})
			.catch(() => {
				if ( localStorageGet('authMethod').toLowerCase() === 'gnosis' ) {
					this.store.dispatch(unsetAuthMethod());
					localStorageRemove('authMethod');
				}
			})



		this.unsubscribe = this.store.subscribe(() => {
			if ( this.store.getState().metamaskAdapter.logged && !this.chainChangeRequested && this.store.getState().metamaskAdapter.requestChainId && this.chainId && this.store.getState().metamaskAdapter.requestChainId !== this.chainId ) {
				this.chainChangeRequested = true;
				this.store.dispatch(setInfo({
					text: `You are trying to open chain which does not match one selected in metamask`,
					buttons: [
						{
							text: this.t('Switch network'),
							clickFunc: () => {
								if ( this.store.getState().metamaskAdapter.authMethod === 'walletconnect' ) { return; }
								(window as any).ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: '0x' + Number(this.store.getState().metamaskAdapter.requestChainId).toString(16) }], // chainId must be in hexadecimal numbers
								})
								.catch((e: any) => {
									if ( e.code === 4902 ) {

										const foundChain = this.availiableChains.find((item) => { return item.chainId === this.store.getState().metamaskAdapter.requestChainId });
										if ( !foundChain ) { return; }

										(window as any).ethereum.request({
											method: "wallet_addEthereumChain",
											params: [{
												chainId: '0x' + Number(foundChain.chainId).toString(16),
												rpcUrls: [ foundChain.chainRPCUrl ],
												chainName: foundChain.chainName,
												nativeCurrency: {
													name: foundChain.networkTokenTicket,
													symbol: foundChain.networkTokenTicket,
													decimals: foundChain.networkTokenDecimals
												},
												blockExplorerUrls: [ foundChain.explorerBaseUrl ]
											}]
										})
											.catch((e: any) => {
												this.store.dispatch(requestChain(undefined));
												this.chainChangeRequested = false;
												this.store.dispatch(clearInfo());
												this.store.dispatch(setError({
													text: `${this.t('Cannot add chain')}: ${e.message || e}`,
													buttons: undefined,
													links: undefined,
												}));
											})
									}
								})
							}
						},
						{
							text: this.t('Continue with current'),
							clickFunc: async () => {
								this.store.dispatch(requestChain( undefined ));
								this.chainChangeRequested = false;
								window.location.href = '/list';
								loadERC721TokenAll(this, this.chainId || 0, this.userAddress).forEach((item) => {
									// In progress turned on
									// this.store.dispatch(incompleteTokensAdd( item ));

									// In progress turned off
									this.store.dispatch(incompleteTokensRemove(item));
									removeERC721Token(item, this.chainId || 0)
								});
								await this.getChainConfg();
								this.store.dispatch(clearInfo());
							}
						},
					],
					links: undefined
				}));
			}
		})
	}
	async connect() {
		let method = this.store.getState().metamaskAdapter.authMethod.toLowerCase();
		const sdk = new SafeAppsSDK();

		try {
			await Promise.race([
				new Promise((res) => { sdk.safe.getInfo().then((data) => { res(data) }) }),
				new Promise((res, rej) => { setTimeout(() => { rej() }, 500) }),
			]);
			method = 'gnosis'
		} catch(ignored) {}

		this.store.dispatch(setLoading({ msg: this.t('Waiting for metamask login') }));
		if ( method === 'metamask' ) {
			try {
				if ( !(window as any).ethereum ) {
					// console.log((window as any).ethereum)
					this.store.dispatch(unsetLoading());
					this.store.dispatch(metamaskConnectionNotInstalled());
					this.store.dispatch(setError({
						text: this.t('No access to metamask'),
						buttons: [{
							text: this.t('Download extension or mobile app'),
							clickFunc: () => { window.open('https://metamask.io/download.html', "_blank"); }
						},
						{
							text: this.t('Close'),
							clickFunc: () => {
								window.location.href = '/';
								this.store.dispatch(clearError());
							}
						}],
						links: undefined
					}));
					return;
				} else {
					await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
				}
			} catch(e) {
				this.store.dispatch(unsetLoading());
				this.accessAtempts++;
				console.log('Cannot connect to metamask:', e);

				if ( this.accessAtempts < 3 ) {
					setTimeout(() => { this.connect() }, 100);
				} else {
					this.accessAtempts = 0;
					this.store.dispatch(setError({
						text: this.t('You should grant access in metamask'),
						buttons: [{
							text: this.t('Try again'),
							clickFunc: () => { this.connect(); this.store.dispatch(clearError()); }
						}],
						links: undefined
					}));
					this.store.dispatch(metamaskConnectionRejected());
					localStorageRemove('authMethod');
				}

				return;
			}

			try {
				this.web3 = new Web3( (window as any).ethereum );
				localStorageSet('authMethod', 'metamask');
			} catch(e: any) {
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: this.t('Cannot connect to metamask'),
					buttons: [{
						text: this.t('Try again'),
						clickFunc: () => { this.connect() }
					}],
					links: undefined
				}));
				console.log(`Cannot connect to metamask: ${e.toString()}`);
				this.store.dispatch(metamaskConnectionRejected());
				localStorageRemove('authMethod');
			}

		}
		if ( method === 'walletconnect' ) {
			const urls: any = {};
			this.availiableChains.forEach((item) => {
				if (!item.chainId) { return; }
				urls[item.chainId] = item.chainRPCUrl;
			});

			try {
				this.wcProvider = new WalletConnectProvider({
					rpc: urls,
				});
				await this.wcProvider.enable();
			} catch(e) {
				this.store.dispatch(unsetLoading());
				console.log('Cannot connect to wallet connect:', e);

				this.store.dispatch(setError({
					text: this.t('You should grant access in your wallet'),
					buttons: [{
						text: this.t('Try again'),
						clickFunc: () => {this.connect(); this.store.dispatch(clearError()); }
					},
					{
						text: this.t('Close'),
						clickFunc: () => { this.store.dispatch(clearError()); }
					}],
					links: undefined
				}));
				this.store.dispatch(metamaskConnectionRejected());
				localStorageRemove('authMethod');

				return;
			}
			try {
				this.web3 = new Web3( this.wcProvider );
				localStorageSet('authMethod', 'walletconnect');
			} catch(e: any) {
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: this.t('Cannot connect to walletconnect'),
					buttons: [{
						text: this.t('Try again'),
						clickFunc: () => { this.connect() }
					}],
					links: undefined
				}));
				localStorageRemove('authMethod');
				console.log(`Cannot connect to walletconnect: ${e.toString()}`);
				this.store.dispatch(metamaskConnectionRejected());
			}
		}

		if ( method === 'gnosis' ) {
			try {
				const sdk = new SafeAppsSDK();
				const safe = await sdk.safe.getInfo();
				this.web3 = new Web3(new SafeAppProvider(safe, sdk) as any);
				localStorageSet('authMethod', 'gnosis');
			} catch(e: any) {
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: this.t('Cannot connect to gnosis safe app'),
					buttons: [{
						text: this.t('Try again'),
						clickFunc: () => { this.connect() }
					}],
					links: undefined
				}));
				localStorageRemove('authMethod');
				console.log(`Cannot connect to gnosis safe app: ${e.toString()}`);
				this.store.dispatch(metamaskConnectionRejected());
			}
		}

		console.log('web3', this.web3);
		const accounts = await this.web3.eth.getAccounts();
		this.userAddress = accounts[0];
		this.store.dispatch(metamaskConnectionSuccess({
			address: this.userAddress,
		}));
		await this.getChainId();
		this.store.dispatch(setLoading({msg: this.t('Loading tokens')}));
		this.fetchNativeBalance();
		this.updateChainListener(method);
	}
	async getChainId() {
		const chainId = await this.web3.eth.getChainId()
		this.chainId = chainId;
		await this.checkUrlChain();
	}
	async isContract(address: string) {
		try {
			let code = await this.web3.eth.getCode(address);
			if(!code || code === '0x') {
				console.log('Wrong Custom Contract address:', address);
				return false;
			}
			console.log('Custom Contract address:', address);
			return true;
		}
		catch(e) {
			console.log('Wrong Custom Contract address:', address);
			return false;
		}
	}
	async checkUrlChain() {
		if ( this.store.getState().metamaskAdapter.requestChainId && this.store.getState().metamaskAdapter.requestChainId !== this.chainId ) {
			this.store.dispatch(setInfo({
				text: `You are trying to open chain which does not match one selected in metamask`,
				buttons: [
					{
						text: this.t('Switch network'),
						clickFunc: () => {
							if ( this.store.getState().metamaskAdapter.authMethod === 'walletconnect' ) { return; }
							(window as any).ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: '0x' + Number(this.store.getState().metamaskAdapter.requestChainId).toString(16) }], // chainId must be in hexadecimal numbers
							})
						}
					},
					{
						text: this.t('Continue with current'),
						clickFunc: async () => {
							window.location.href = '/list';
							loadERC721TokenAll(this, this.chainId || 0, this.userAddress).forEach((item) => {
								// In progress turned on
									// this.store.dispatch(incompleteTokensAdd( item ));

									// In progress turned off
									this.store.dispatch(incompleteTokensRemove(item));
									removeERC721Token(item, this.chainId || 0)
							});
							await this.getChainConfg();
							this.store.dispatch(clearInfo());
						}
					},
				],
				links: undefined
			}));
		} else {
			loadERC721TokenAll(this, this.chainId || 0, this.userAddress).forEach((item) => {
				// In progress turned on
				// this.store.dispatch(incompleteTokensAdd( item ));

				// In progress turned off
				this.store.dispatch(incompleteTokensRemove(item));
				removeERC721Token(item, this.chainId || 0)
			});
			await this.getChainConfg();
		}
	}
	fillTargetChainIcons(chainParam: ChainParamsType): ChainParamsType {
		if ( !chainParam.crossing ) { return chainParam; }

		return {
			...chainParam,
			crossing: {
				...chainParam.crossing,
				targetChains: chainParam.crossing.targetChains
					.filter((item) => { return item.targetChainId !== this.chainId })
					.map((item) => {
						let icon = default_icon;
						let name = `Chain ${ item.targetChainId }`;

						const foundChain = this.availiableChains.find((iitem) => { return item.targetChainId === iitem.chainId });
						if ( !foundChain ) { return { ...item, icon, name } }

						name = foundChain.isTestNetwork ? `${foundChain.chainName} testnet` : foundChain.chainName;
						try { icon = require(`../../static/pics/networks/${foundChain.chainId}.jpeg`).default } catch (ignored) {}
						try { icon = require(`../../static/pics/networks/${foundChain.chainId}.jpg` ).default } catch (ignored) {}
						try { icon = require(`../../static/pics/networks/${foundChain.chainId}.png` ).default } catch (ignored) {}
						try { icon = require(`../../static/pics/networks/${foundChain.chainId}.svg` ).default } catch (ignored) {}

						return { ...item, icon, name }
					})
			}
		}
	}
	async getChainConfg() {

		let foundChain = this.availiableChains.filter((item: ChainParamsType) => { return item.chainId === this.chainId });
		if ( !foundChain.length ) {
			const chosenAuthMethod = this.store.getState().metamaskAdapter.authMethod;
			this.store.dispatch(resetAppData());
			this.store.dispatch(setAuthMethod(chosenAuthMethod));
			localStorageRemove('walletconnect');
			const availableChainsStr = this.availiableChains.map((item) => { return item.isTestNetwork ? `${item.chainName} (testnet)` : item.chainName }).join(', ');
			this.store.dispatch(setInfo({
				text: `${this.t('Unsupported chain. Please choose from')}: ${ availableChainsStr }`,
				buttons: [{
					text: this.t('Connect again'),
					clickFunc: () => { this.connect() }
				}],
				links: undefined
			}));
			console.log('Cannot load domain info');
			return;
		}

		let tokenIcon   = default_icon;
		try { tokenIcon = require(`../../static/pics/coins/${foundChain[0].networkTokenTicket.toLowerCase()}.jpeg`).default } catch (ignored) {}
		try { tokenIcon = require(`../../static/pics/coins/${foundChain[0].networkTokenTicket.toLowerCase()}.jpg` ).default } catch (ignored) {}
		try { tokenIcon = require(`../../static/pics/coins/${foundChain[0].networkTokenTicket.toLowerCase()}.png` ).default } catch (ignored) {}
		try { tokenIcon = require(`../../static/pics/coins/${foundChain[0].networkTokenTicket.toLowerCase()}.svg` ).default } catch (ignored) {}

		let networkIcon   = default_icon;
		try { networkIcon = require(`../../static/pics/networks/${foundChain[0].chainId}.jpeg`).default } catch (ignored) {}
		try { networkIcon = require(`../../static/pics/networks/${foundChain[0].chainId}.jpg` ).default } catch (ignored) {}
		try { networkIcon = require(`../../static/pics/networks/${foundChain[0].chainId}.png` ).default } catch (ignored) {}
		try { networkIcon = require(`../../static/pics/networks/${foundChain[0].chainId}.svg` ).default } catch (ignored) {}

		this.chainConfig = this.fillTargetChainIcons({
			chainId                      : this.chainId,
			chainName                    : foundChain[0].chainName,
			chainColorCode               : foundChain[0].chainColorCode,
			chainRPCUrl                  : foundChain[0].chainRPCUrl,
			networkTokenTicket           : foundChain[0].networkTokenTicket,
			EIPPrefix                    : foundChain[0].EIPPrefix,
			networkTokenDecimals         : foundChain[0].networkTokenDecimals,
			networkTokenIcon             : tokenIcon,
			networkIcon                  : networkIcon,
			wrapperContract              : foundChain[0].wrapperContract,
			checkerContract              : foundChain[0].checkerContract,
			WNFTStorageContracts         : foundChain[0].WNFTStorageContracts,
			supportedERC20Tokens         : foundChain[0].supportedERC20Tokens,
			isTestNetwork                : foundChain[0].isTestNetwork,
			explorerBaseUrl              : foundChain[0].explorerBaseUrl,
			marketplaceUrl               : foundChain[0].marketplaceUrl,
			explorerName                 : foundChain[0].explorerName,
			minterContract               : foundChain[0].minterContract,
			crossing                     : foundChain[0].crossing,
			nftMinterContract721         : foundChain[0].nftMinterContract721,
			nftMinterContract1155        : foundChain[0].nftMinterContract1155,
			batchWorker                  : foundChain[0].batchWorker,
			batchWrapSupportedERC20Tokens: foundChain[0].batchWrapSupportedERC20Tokens,
			subscriptionAgent            : foundChain[0].subscriptionAgent,
			INFO_MESSAGES                : foundChain[0].INFO_MESSAGES
		});

		this.store.dispatch(metamaskSetChainParams( this.chainConfig ));

		await this.createWrapperContract();
		// await this.createNftMinterContract();
	}
	chainUpdated() {
		if ( window.location.href.toLowerCase().includes('wrap') ) {
			window.location.href = '/list';
		} else {
			window.location.reload();
		}
	}
	updateChainListener(authMethod: string) {
		if ( authMethod === 'metamask' ) {
			(window as any).ethereum.on('chainChanged',    () => { this.chainUpdated() });
			(window as any).ethereum.on('accountsChanged', () => { this.chainUpdated() });
		}
		if ( authMethod === 'walletconnect' ) {
			this.wcProvider.on("accountsChanged",          () => { this.chainUpdated() });
			this.wcProvider.on("chainChanged",             () => { this.chainUpdated() });
		}
	}
	async createWrapperContract() {

		try {
			this.wrapperContract = new WrapperContract({
				store                   : this.store,
				metamaskAdapter         : this,
				web3                    : this.web3,
				contractAddress         : this.chainConfig.wrapperContract,
				checkerContractAddress  : this.chainConfig.checkerContract,
				wNFTStorages            : this.chainConfig.WNFTStorageContracts.map((item) => { return { ...item, standart: decodeAssetTypeFromString(item.standart) } }),
				userAddress             : this.userAddress,
				updateNativeBalance     : () => { this.fetchNativeBalance() },
				t                       : this.t,
				erc20TokenAddress       : this.chainConfig.supportedERC20Tokens,
				createWhitelistContract : this.createWhitelistContract,
			});
		} catch(e: any) {
			this.store.dispatch(setError({
				text: e.message,
				buttons: [{
					text: 'Try again',
					clickFunc: () => { this.connect() }
				}],
				links: undefined
			}));
		}

		return;
	}
	async createNftMinterContract() {

		try {
			this.nftMinterContract = new NftMinterContract({
				web3                    : this.web3,
				metamaskAdapter         : this,
				store                   : this.store,
				contract721Address      : this.chainConfig.nftMinterContract721!,
				contract1155Address     : this.chainConfig.nftMinterContract1155!,
				userAddress             : this.userAddress,
				t                       : this.t,
			});
		} catch(e: any) {
			this.store.dispatch(setError({
				text: e.message,
				buttons: [{
					text: 'Try again',
					clickFunc: () => { this.connect() }
				}],
				links: undefined
			}));
		}

		return;
	}
	getNftMinterContract() {
		if ( this.nftMinterContract ) { return this.nftMinterContract; }

		if ( !this.chainConfig || !this.chainConfig.nftMinterContract721 || !this.chainConfig.nftMinterContract1155 ) { return undefined; }

		this.nftMinterContract = new NftMinterContract({
			web3                    : this.web3,
			metamaskAdapter         : this,
			store                   : this.store,
			contract721Address      : this.chainConfig.nftMinterContract721!,
			contract1155Address     : this.chainConfig.nftMinterContract1155!,
			userAddress             : this.userAddress,
			t                       : this.t,
		});

		return this.nftMinterContract;
	}
	createWhitelistContract = async (contractAddress: string, techTokenAddress: string) => {
		let whitelistABI;
		try {
			whitelistABI = getABI(this.chainId || 0, contractAddress, 'whitelist');
		} catch(e) {
			console.log(`Cannot load ${contractAddress} wrapper abi:`, e);
			throw new Error(`Cannot load whitelist abi`);
		}
		this.whitelistContract = new this.web3.eth.Contract(whitelistABI, contractAddress);
		const whitelist = await this.whitelistContract.methods.getWLAddresses().call();
		let erc20contracts = whitelist
			.filter((item: _Asset) => { return `${item.assetType}` === `${_AssetType.ERC20}` })
			.filter((item: _Asset) => { return item.contractAddress.toLowerCase() !== techTokenAddress.toLowerCase() })
			.map((item: _Asset) => { return item.contractAddress });
		if ( erc20contracts.length ) {
			this.store.dispatch( collateralWhitelistAdd({ list: whitelist }) );
		} else {
			erc20contracts = this.chainConfig.supportedERC20Tokens;
		}
		this.createERC20Contracts(erc20contracts);

		const blacklist = await this.whitelistContract.methods.getBLAddresses().call();
		this.store.dispatch( originalTokensBlacklistAdd({ list: blacklist }) );
	}

	getCrossingDispatcher() {
		if ( this.crossingDispatcher ) { return this.crossingDispatcher; }

		if ( !this.chainConfig || !this.chainConfig.crossing ) { return undefined; }

		this.crossingDispatcher = new CrossingDispatcher({
			web3: this.web3,
			metamaskAdapter: this,
			store: this.store,
			keeperContractAddress: this.chainConfig.crossing.keeper,
			spawner: this.chainConfig.crossing.spawner,
			targetChains: this.chainConfig.crossing.targetChains,
		});

		return this.crossingDispatcher;
	}
	getSAFTDispatcher() {
		if ( this.SAFTDispatcher ) { return this.SAFTDispatcher; }

		if ( !this.chainConfig || !this.chainConfig.batchWorker ) { return undefined; }

		this.SAFTDispatcher = new SAFTDispatcher({
			web3: this.web3,
			metamaskAdapter: this,
			store: this.store,
			batchWorkerAddress: this.chainConfig.batchWorker,
			t: this.t,
		});

		return this.SAFTDispatcher;
	}

	async createERC20Contracts(erc20Contracts: Array<string>) {
		erc20Contracts.forEach((item: string) => {
			const contract = new ERC20Contract({
				web3                : this.web3,
				store               : this.store,
				contractAddress     : item,
				contractType        : 'collateral',
				userAddress         : this.userAddress,
				wrapperAddress      : this.chainConfig.wrapperContract,
				whitelistContract   : this.whitelistContract,
			});

			this.erc20CollateralTokens = [
				...this.erc20CollateralTokens.filter((iitem) => { return iitem.contractAddress.toLowerCase() !== item.toLowerCase() }),
				contract
			]
		});
	}
	async addERC20Contracts(erc20Contract: string) {
		if ( erc20Contract === '' ) { return; }

		if ( this.wrapperContract.getTechTokenContract(erc20Contract) ) { return; }

		let contract = undefined;
		try {
			contract = new ERC20Contract({
				web3                : this.web3,
				store               : this.store,
				contractAddress     : erc20Contract,
				contractType        : 'collateral',
				userAddress         : this.userAddress,
				wrapperAddress      : this.chainConfig.wrapperContract,
				whitelistContract   : this.whitelistContract,
				delayedParams       : true,
			});

			await contract.getParams();
			contract.addCheckoutEventListener();

			if ( contract ) {
				this.erc20CollateralTokens = [
					...this.erc20CollateralTokens.filter((item) => { return item.contractAddress.toLowerCase() !== erc20Contract.toLowerCase() }),
					contract
				]
			}
		} catch(ignored) {}
	}
	getERC20Contract(address: string): ERC20Contract | undefined {
		if ( !this.wrapperContract.erc20Contract ) { return undefined }

		const foundTechContract = this.wrapperContract.erc20Contract.find((item: ERC20Contract) => { return item.contractAddress.toLowerCase() === address.toLowerCase() });
		if ( foundTechContract ) {
			return foundTechContract
		}

		const foundContract = this.erc20CollateralTokens.find((item: ERC20Contract) => { return item.contractAddress.toLowerCase() === address.toLowerCase() });
		if ( foundContract ) {
			return foundContract
		} else {
			return undefined;
		}
	}
	filterERC20ContractByPermissions(permissions: {
		enabledForCollateral?        : boolean,
		enabledForFee?               : boolean,
		enabledRemoveFromCollateral? : boolean,
	}): Array<ERC20Contract> {
		let tokensToSearch;
		if ( this.wrapperContract ) {
			tokensToSearch = [
				...this.wrapperContract.erc20Contract,
				...this.erc20CollateralTokens
			]
		} else {
			tokensToSearch = [
				...this.erc20CollateralTokens
			]
		}

		// OR LOGIC
		return tokensToSearch.filter((item) => {
			if ( !item || !item.erc20Params ) { return false; }
			if ( permissions.enabledForCollateral        && item.erc20Params.permissions && item.erc20Params.permissions.enabledForCollateral        ) { return true; }
			if ( permissions.enabledForFee               && item.erc20Params.permissions && item.erc20Params.permissions.enabledForFee               ) { return true; }
			if ( permissions.enabledRemoveFromCollateral && item.erc20Params.permissions && item.erc20Params.permissions.enabledRemoveFromCollateral ) { return true; }

			return false;
		})
	}
	updateERC20Balances() {
		this.wrapperContract.erc20Contract.forEach((item: ERC20Contract) => { item.getBalance(); });
		this.erc20CollateralTokens.forEach((item: ERC20Contract) => { item.getBalance(); });
	}
	async fetchNativeBalance() {
		const balance = new BigNumber(await this.web3.eth.getBalance(this.userAddress));
		this.store.dispatch(updateNativeBalance({ balance }));
		return balance;
	}
	updateAllBalances() {
		this.updateERC20Balances();
		this.fetchNativeBalance();
		this.store.dispatch(wrappedTokensClear());
		setTimeout(() => {
			this.wrapperContract.updateTokens();
		}, 3*1000);
	}

}