
import React from 'react';
import Tippy from '@tippyjs/react';

import {
	withRouter,
} from 'react-router-dom';
import { withTranslation, } from "react-i18next";
import queryString from 'query-string';
import CoinSelector from '../CoinSelector';
import {
	checkApprovalERC1155Token,
} from '../../models/BlockchainAdapter/erc1155contract';
import {
	History,
	Location
} from 'history';
import {
	ERC20ContractParamsType,
	MetamaskAdapter,
	CollateralItem,
	_AssetType,
	Royalty,
	Rules,
	checkApprovalERC721Token,
	Lock,
	LockType,
	Fee,
	WrappedTokenType,
	_Asset,
	RoyaltyInput,
} from '../../models/BlockchainAdapter';
import CollateralViewer from '../CollateralViewer';
import {
	getOriginalToken
} from '../../models/TokenFetchWrapper/tokenfetchwrapper';

import erc721_abi  from '../../abis/_erc721.json';
import erc1155_abi from '../../abis/_erc1155.json';

import {
	addThousandSeparator,
	compactString,
	dateToUnixtime,
	localStorageGet,
	monthesNames,
	processSwarmUrl,
	removeThousandSeparator,
	tokenToFloat,
	tokenToInt,
} from '../../models/_utils';

import icon_i_del            from '../../static/pics/i-del.svg';
import default_icon          from '../../static/pics/coins/_default.svg';
import default_token_preview from '../../static/pics/tb-nft-default.svg';
import icon_external         from '../../static/pics/icons/i-external.svg';

import BigNumber from 'bignumber.js';
import { setAuthMethod } from '../../reducers';
import NumberInput from '../NumberInput';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type TokenWrapPageProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function;
	t                     : any,
	match                 : any;
	location              : Location,
	history               : History,
}
type TokenWrapPageState = {
	userAddress: string,

	inputOriginalTokenAddress: string,
	inputOriginalTokenId     : string,
	inputOriginalCopies      : string,
	inputEmptyChecked        : boolean,
	inputApproveAll          : boolean,

	inputStandart: _AssetType,
	inputCopies  : number,

	inputCollateralRecipientAddress: string,
	inputWNFTRecipientAddress: string,
	inputRules: Rules,

	inputAssetType        : _AssetType,
	inputCollateralAddress: string,
	inputCollateralTokenId: string,
	inputCollateralAmount : string,
	collaterals           : Array<CollateralItem>,
	maxCollaterals        : number,
	inputCollateralSlots  : number | undefined,
	collateralErrors      : Array<{
		address: string,
		tokenId?: string,
		msg: string
	}>,

	inputTransferFeeAmount : string,
	inputTransferFeeAddress: string,

	inputAddWNFTChecked  : boolean,
	inputRecipientAddress: string,
	recipients           : Array<RoyaltyInput>,

	inputTimeLockChecked: boolean,
	inputTimeLockDays   : string,
	inputFeeLockChecked : boolean,
	inputFeeLockAmount  : string,

	originalTokenInfo: {
		infoLoaded           : boolean,
		projectName          : string,
		tokenName            : string,
		collectionImg        : string,
		badContract          : boolean,
		originalTokenStandart: _AssetType | undefined,
		isApproved           : boolean,
		owner                : string | undefined,
		balance1155          : BigNumber | undefined,
	},
	originalTokenWrapped: WrappedTokenType | undefined,

	originalTokensBlacklist: Array<_Asset> | undefined,
	collateralWhitelist    : Array<_Asset> | undefined,

	balanceNative : BigNumber,
	decimalsNative: number,
	symbolNative  : string,
	iconNative    : string,
	EIPPrefix     : string,

	techToken            : ERC20ContractParamsType,
	erc20CollateralTokens: Array<ERC20ContractParamsType>,

	explorerBaseUrl: string,

	advancedOptionsOpened: boolean,

	showErrors: Array<string>,

	niftsyTokenIcon: string,
	authMethod     : string,
}

class TokenWrapPage extends React.Component<TokenWrapPageProps, TokenWrapPageState> {

	store                 : any;
	metamaskAdapter       : MetamaskAdapter;
	showAuthMethodSelector: Function;
	unsubscribe!          : Function;
	unlisten!             : Function;
	t                     : any;
	focusable             : any;
	scrollable            : any;

	originalTokenInfoPending: boolean;

	constructor(props: TokenWrapPageProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.t                      = props.t;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.focusable              = {};
		this.scrollable             = {};

		this.originalTokenInfoPending = false;

		let isEmpty = false;
		const urlParams = queryString.parse(this.props.location.search, { parseBooleans: true });
		if ( urlParams.empty && !props.match.params.contractAddress && !props.match.params.tokenId ) {
			isEmpty = true;
		}

		this.state = {
			userAddress: this.store.getState().account.address || '',

			inputOriginalTokenAddress: props.match.params.contractAddress || '',
			inputOriginalTokenId     : props.match.params.tokenId || '',
			inputOriginalCopies      : '',
			inputEmptyChecked        : isEmpty,
			inputApproveAll          : false,

			inputStandart: _AssetType.ERC721,
			inputCopies  : 2,

			inputCollateralRecipientAddress: '',
			inputWNFTRecipientAddress      : '',
			inputRules              : {
				noAddCollateral: false,
				noTransfer     : false,
				noUnwrap       : false,
				noWrap         : false,
			},

			collaterals           : [],

			inputAssetType   : _AssetType.native,
			inputCollateralAddress: '',
			inputCollateralTokenId: '',
			inputCollateralAmount : '',
			maxCollaterals        : this.store.getState().metamaskAdapter.maxCollaterals || 0,
			inputCollateralSlots  : undefined,
			collateralErrors      : [],

			inputTransferFeeAmount : '',
			inputTransferFeeAddress: this.store.getState().erc20TechTokenParams.address || '',

			inputAddWNFTChecked  : false,
			inputRecipientAddress: '',
			recipients           : [],

			inputTimeLockChecked: false,
			inputTimeLockDays   : '',
			inputFeeLockChecked : false,
			inputFeeLockAmount  : '',

			originalTokenInfo: {
				infoLoaded           : false,
				projectName          : '',
				tokenName            : '',
				collectionImg        : '',
				badContract          : false,
				originalTokenStandart: undefined,
				isApproved           : false,
				owner                : undefined,
				balance1155          : undefined,
			},
			originalTokenWrapped: undefined,

			originalTokensBlacklist: this.store.getState().originalTokensBlacklist,
			collateralWhitelist    : this.store.getState().collateralWhitelist,

			balanceNative : this.store.getState().account.balanceNative,
			decimalsNative: this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative  : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative    : this.store.getState().metamaskAdapter.networkTokenIcon,
			EIPPrefix     : this.store.getState().metamaskAdapter.EIPPrefix,

			techToken            : this.store.getState().erc20TechTokenParams,
			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,

			explorerBaseUrl: this.store.getState().metamaskAdapter.explorerBaseUrl,

			advancedOptionsOpened: false,

			showErrors: [],

			niftsyTokenIcon: 'https://envelop.is/assets/img/niftsy.svg',
			authMethod     : this.store.getState().metamaskAdapter.authMethod || 'metamask',
		};

		if ( props.match.params.contractAddress && props.match.params.tokenId ) {
			setTimeout(() => {
				this.fetchOriginalTokenInfo(props.match.params.contractAddress, props.match.params.tokenId);
			}, 100);
		};
	}

	componentDidMount() {

		window.scrollTo(0,0);

		if ( !this.store.getState().metamaskAdapter.logged ) {
			const prevAuthMethod = localStorageGet('authMethod').toLowerCase();
			if ( prevAuthMethod ) {
				this.store.dispatch(setAuthMethod( prevAuthMethod ));
				this.metamaskAdapter.connect();
			} else {
				this.props.showAuthMethodSelector();
			}
		}

		this.unsubscribe = this.store.subscribe(() => {

			this.fetchOriginalTokenInfo(this.state.inputOriginalTokenAddress, this.state.inputOriginalTokenId);

			this.setState({
				userAddress      : this.store.getState().account.address || '',

				balanceNative    : this.store.getState().account.balanceNative,
				decimalsNative   : this.store.getState().metamaskAdapter.networkTokenDecimals,
				symbolNative     : this.store.getState().metamaskAdapter.networkTokenTicket,
				iconNative       : this.store.getState().metamaskAdapter.networkTokenIcon,
				EIPPrefix        : this.store.getState().metamaskAdapter.EIPPrefix,
				explorerBaseUrl  : this.store.getState().metamaskAdapter.explorerBaseUrl,

				originalTokensBlacklist: this.store.getState().originalTokensBlacklist,
				collateralWhitelist    : this.store.getState().collateralWhitelist,

				techToken        : this.store.getState().erc20TechTokenParams,
				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
				maxCollaterals        : this.store.getState().metamaskAdapter.maxCollaterals || 0,
				inputTransferFeeAddress: this.state.inputTransferFeeAddress === '' ? this.store.getState().erc20TechTokenParams.address || '' : this.state.inputTransferFeeAddress,
				authMethod             : this.store.getState().metamaskAdapter.authMethod || 'metamask',
			});

		});

		this.unlisten = this.props.history.listen(() => {
			const urlParams = queryString.parse(this.props.location.search, { parseBooleans: true });
			if ( urlParams.empty && !this.props.match.params.contractAddress && !this.props.match.params.tokenId ) {
				this.setState({ inputEmptyChecked: true });
			}
		});
 	}
	componentWillUnmount() {
		this.unsubscribe();
	}

	filterERC20ContractByPermissions(permissions: {
		enabledForCollateral?        : boolean,
		enabledForFee?               : boolean,
		enabledRemoveFromCollateral? : boolean,
	}): Array<ERC20ContractParamsType> {
		const tokensToSearch = [
			this.state.techToken,
			...this.state.erc20CollateralTokens
		];

		// OR LOGIC
		return tokensToSearch.filter((item) => {
			if ( permissions.enabledForCollateral        && item.permissions && item.permissions.enabledForCollateral        ) { return true; }
			if ( permissions.enabledForFee               && item.permissions && item.permissions.enabledForFee               ) { return true; }
			if ( permissions.enabledRemoveFromCollateral && item.permissions && item.permissions.enabledRemoveFromCollateral ) { return true; }

			return false;
		})
	}

	getEmptyCheckboxBlock() {
		return (
			<div className="col-auto">
				<label className="checkbox">
					<input
						type="checkbox"
						checked={ this.state.inputEmptyChecked }
						onChange={(e) => {
							if ( e.target.checked ) {
								this.setState({
									inputEmptyChecked: e.target.checked,
									inputOriginalTokenAddress: '',
									inputOriginalTokenId: '',
									originalTokenInfo: {
										infoLoaded           : false,
										projectName          : '',
										tokenName            : '',
										collectionImg        : '',
										badContract          : false,
										originalTokenStandart: undefined,
										isApproved           : false,
										owner                : undefined,
										balance1155          : undefined,
									}
								})
							} else {
								this.setState({ inputEmptyChecked: e.target.checked })
							}
						}}
					/>
					<span className="check"></span>
					<span className="check-text">
						Empty wNFT
						<Tippy
							content={ this.t('Create wNFT without original NFT') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 512 }
						>
							<span className="i-tip ml-1"></span>
						</Tippy>
					</span>
				</label>
			</div>
		)
	}
	async fetchOriginalTokenInfo(address: string, tokenId: string) {
		if (
			this.originalTokenInfoPending ||
			!this.metamaskAdapter.web3 ||
			!address ||
			!this.metamaskAdapter.web3.utils.isAddress(address) ||
			this.state.originalTokenInfo.tokenName ||
			this.state.originalTokenInfo.projectName ||
			this.state.originalTokenInfo.collectionImg ||
			this.state.originalTokenInfo.badContract
		) { return; }

		let originalTokenWrappedUpdated = this.state.originalTokenWrapped;
		if (
			this.metamaskAdapter.wrapperContract &&
			!originalTokenWrappedUpdated &&
			this.state.inputOriginalTokenAddress !== '' &&
			this.state.inputOriginalTokenId !== '' &&
			this.store.getState().wNFTStorages.storagesCount === this.store.getState().wNFTStorages.list.length &&
			this.metamaskAdapter.wrapperContract.addressIsStorage(this.state.inputOriginalTokenAddress)
		) {

			const foundToken = this.store.getState().wrappedTokens.find((item: WrappedTokenType) => { return item.contractAddress.toLowerCase() === address.toLowerCase() && item.tokenId === tokenId });
			if ( foundToken ) {
				originalTokenWrappedUpdated = foundToken;
			} else {
				const foundStorage = this.metamaskAdapter.wrapperContract.getStorageContract(this.state.inputOriginalTokenAddress);
				if ( foundStorage && foundStorage.contract ) {
					const fetchedToken = await foundStorage.contract.getNFTTokenById(this.state.inputOriginalTokenId);
					originalTokenWrappedUpdated = fetchedToken;
				}
			}
		}

		this.originalTokenInfoPending = true;

		let originalTokenStandart = undefined;
		let originalTokenImage    = undefined;
		let originalTokenName     = undefined;
		let originalProjectName   = undefined;
		let originalIsApproved    = undefined;
		let originalOwner         = undefined;
		let originalBalance       = undefined;

		const testContract721  = new this.metamaskAdapter.web3.eth.Contract(erc721_abi  as any, address);
		const testContract1155 = new this.metamaskAdapter.web3.eth.Contract(erc1155_abi as any, address);

		const IID_IERC1155 = '0xd9b67a26';
		const IID_IERC721  = '0x80ac58cd';

		if ( originalTokenStandart === undefined ) {
			// ERC721
			try {
				const supports = await testContract721.methods.supportsInterface(IID_IERC721).call();
				if ( supports ) {
					try { originalProjectName = await testContract721.methods.name().call(); } catch(ignored) {}

					if ( tokenId ) {
						try { originalOwner      = await testContract721.methods.ownerOf(tokenId).call(); } catch(ignored) {}
						try {
							const uri            = await testContract721.methods.tokenURI(tokenId).call();
							const response       = await fetch(processSwarmUrl(uri));
							const responseParsed = await response.json();
							originalTokenImage   = processSwarmUrl(responseParsed.image);
							originalTokenName    = responseParsed.name;
						} catch(ignored) {}
						try {
							originalIsApproved   = await checkApprovalERC721Token({
								contract: testContract721,
								tokenId: tokenId,
								userAddress: this.state.userAddress,
								addressTo: this.metamaskAdapter.wrapperContract.contractAddress,
							});
						} catch(ignored) {}
					}
					originalTokenStandart = _AssetType.ERC721;
				}
			} catch (ignored: any) {}
		}
		if ( originalTokenStandart === undefined ) {
			// ERC1155
			try {
				const supports = await testContract1155.methods.supportsInterface(IID_IERC1155).call();
				if ( supports ) {
					try { originalProjectName = await testContract1155.methods.name().call(); } catch(ignored) {}

					if ( tokenId ) {
						try { originalBalance    = new BigNumber(await testContract1155.methods.balanceOf(this.state.userAddress, tokenId).call()); } catch(ignored) {}
						try {
							const uri            = await testContract1155.methods.uri(tokenId).call();
							const response       = await fetch(processSwarmUrl(uri));
							const responseParsed = await response.json();
							originalTokenImage   = processSwarmUrl(responseParsed.image);
							originalTokenName    = responseParsed.name;
						} catch(ignored) {}
					}

					try {
						originalIsApproved = await checkApprovalERC1155Token({
							contract: testContract1155,
							userAddress: this.state.userAddress,
							addressTo: this.metamaskAdapter.wrapperContract.contractAddress,
						});
					} catch(ignored) {}
					originalTokenStandart = _AssetType.ERC1155;
				}
			} catch (ignored: any) {}
		}

		// --- FALLBACK IF CONTRACT HAS NO SUPPORTSINTERFACE
		if ( originalTokenStandart === undefined && tokenId ) {
			// ERC721
			try {
				originalOwner  = await testContract721.methods.ownerOf(tokenId).call();
				const uri = await testContract721.methods.tokenURI(tokenId).call();
				if ( uri ) {
					try { originalProjectName  = await testContract721.methods.name().call(); } catch(ignored) {}
					try {
						const response       = await fetch(processSwarmUrl(uri));
						const responseParsed = await response.json();
						originalTokenImage   = processSwarmUrl(responseParsed.image);
						originalTokenName    = responseParsed.name;
					} catch(ignored) {}

					try {
						originalIsApproved   = await checkApprovalERC721Token({
							contract: testContract721,
							tokenId: tokenId,
							userAddress: this.state.userAddress,
							addressTo: this.metamaskAdapter.wrapperContract.contractAddress,
						});
					} catch(ignored) {}
					originalTokenStandart = _AssetType.ERC721;
				}
			} catch (ignored: any) {}
		}
		if ( originalTokenStandart === undefined && tokenId ) {
			// ERC1155
			try {
				originalBalance = new BigNumber(await testContract1155.methods.balanceOf(this.state.userAddress, tokenId).call());
				const uri = await testContract1155.methods.uri(tokenId).call();
				if ( uri ) {
					try { originalProjectName  = await testContract1155.methods.name().call(); } catch(ignored) {}
					try {
						const response       = await fetch(processSwarmUrl(uri));
						const responseParsed = await response.json();
						originalTokenImage   = processSwarmUrl(responseParsed.image);
						originalTokenName    = responseParsed.name;
					} catch(ignored) {}
					try {
						originalIsApproved   = await checkApprovalERC1155Token({
							contract: testContract1155,
							userAddress: this.state.userAddress,
							addressTo: this.metamaskAdapter.wrapperContract.contractAddress,
						});
					} catch(ignored) {}
					originalTokenStandart = _AssetType.ERC1155;
				}
			} catch (ignored: any) {}
		}
		// --- END FALLBACK IF CONTRACT HAS NO SUPPORTSINTERFACE

		if ( originalTokenStandart === undefined ) {
			this.setState({
				originalTokenInfo: {
					infoLoaded: true,
					projectName: '',
					tokenName: '',
					collectionImg: '',
					originalTokenStandart: undefined,
					isApproved: false,

					badContract: true,

					owner: undefined,
					balance1155: undefined,
				},
				originalTokenWrapped: originalTokenWrappedUpdated,
			});
			return;
		}

		if ( tokenId === this.state.inputOriginalTokenId ) {
			this.setState({
				originalTokenInfo: {
					infoLoaded: true,
					projectName: originalProjectName,
					tokenName: originalTokenName || '',
					collectionImg: originalTokenImage || '',
					badContract: false,
					originalTokenStandart: originalTokenStandart,
					isApproved: originalIsApproved,
					owner: originalOwner,
					balance1155: originalBalance,
				},
				originalTokenWrapped: originalTokenWrappedUpdated,
			});
		}

		this.originalTokenInfoPending = false;
	}
	getCollectionBlock() {
		const getTokenName = () => {
			if ( !this.state.originalTokenInfo.tokenName ) { return null; }
			return ( <p>Name <b>{ this.state.originalTokenInfo.tokenName }</b></p> )
		}
		const getProjectName = () => {
			if ( !this.state.originalTokenInfo.projectName ) { return ( <b> — </b> ) }
			return (
				<b>{ this.state.originalTokenInfo.projectName }</b>
			)
		}
		const getProjectBlock = () => {
			if ( this.state.originalTokenInfo.badContract ) {
				return (
					<p>
						<a
							className="ex-link"
							target="_blank"
							rel="noopener noreferrer"
							href={ `${this.state.explorerBaseUrl}/address/${this.state.inputOriginalTokenAddress}` }
						>
							{ this.t('Cannot connect to contract') }
							<img className="i-ex" src={ icon_external } alt="" />
						</a>
					</p>
				)
			}
			if ( !this.state.inputOriginalTokenAddress ) {
				return (
					<p>Project { getProjectName() }</p>
				)
			}
			return (
				<p>
					<a
						className="ex-link"
						target="_blank"
						rel="noopener noreferrer"
						href={ `${this.state.explorerBaseUrl}/address/${this.state.inputOriginalTokenAddress}` }
					>
						Project { getProjectName() }
						<img className="i-ex" src={ icon_external } alt="" />
					</a>
				</p>
			)
		}

		return (
			<div className="info">
				{ getTokenName() }
				{ getProjectBlock() }
			</div>
		)

	}
	getTokenTypeAlert() {
		if (
			this.state.originalTokenInfo.infoLoaded &&
			this.state.inputOriginalTokenAddress !== '' &&
			( this.state.originalTokenInfo.badContract ||
			this.state.originalTokenInfo.originalTokenStandart === undefined )
		) {
			return (
				<div className="alert mt-md-3 mb-4 mb-md-0">
					Мы&nbsp;не&nbsp;смогли автоматически определить тип исходного контракта, в&nbsp;случае, если контракт совместим со&nbsp;стандартом ERC-1155, укажите количество токенов для заворачивания.
				</div>
			)
		}

		return null;
	}
	getTokenOwnerAlert() {

		if (
			this.metamaskAdapter.wrapperContract &&
			!this.state.inputEmptyChecked &&
			this.store.getState().wNFTStorages.storagesCount === this.store.getState().wNFTStorages.list.length &&
			this.metamaskAdapter.wrapperContract.addressIsStorage(this.state.inputOriginalTokenAddress)
		) {
			return (
				<div className="alert alert-error mt-md-3 mb-4 mb-md-0">Cannot wrap wrapped</div>
			)
		}

		if ( this.state.originalTokensBlacklist ) {
			const foundBlacklistItem = this.state.originalTokensBlacklist.find((item: any) => {
				if ( typeof(item) === 'string' ) {
					return item.toLowerCase() === this.state.inputOriginalTokenAddress.toLowerCase()
				}
				return item.contractAddress.toLowerCase() === this.state.inputOriginalTokenAddress.toLowerCase()
			});
			if ( foundBlacklistItem ) {
				return (
					<div className="alert alert-error mt-md-3 mb-4 mb-md-0">Token address is blacklisted</div>
				)
			}
		}

		if (
			this.state.originalTokenInfo.infoLoaded &&
			this.state.inputOriginalTokenAddress !== '' &&
			this.state.originalTokenInfo.owner &&
			this.state.originalTokenInfo.owner.toLowerCase() !== this.state.userAddress.toLowerCase()
		) {
			return (
				<div className="alert alert-error mt-md-3 mb-4 mb-md-0">Token is not yours</div>
			)
		}

		if (
			!this.state.inputEmptyChecked &&
			this.state.originalTokenInfo.balance1155 &&
			this.state.originalTokenInfo.balance1155.lt(new BigNumber( this.state.inputOriginalCopies ))
		) {
			return (
				<div className="alert alert-error mt-md-3 mb-4 mb-md-0">Not enough original token balance</div>
			)
		}

		return null;
	}
	getOriginalTokenCopiesBlock() {
		if (
			this.state.originalTokenInfo.infoLoaded &&
			this.state.inputOriginalTokenAddress !== '' &&
			( this.state.originalTokenInfo.badContract ||
			this.state.originalTokenInfo.originalTokenStandart === undefined ||
			this.state.originalTokenInfo.originalTokenStandart === _AssetType.ERC1155 )
		) {
			return (
				<div className="col-12 col-md-2">
					<div className="input-group mb-md-0">
						<label className="input-label">Copies</label>
						<input
							className="input-control"
							type="text"
							value={ this.state.inputOriginalCopies }
							onChange={(e) => {
								const value = e.target.value.replaceAll(' ', '');
								if (
									value === '' ||
									isNaN(parseInt(value))
								) {
									this.setState({ inputOriginalCopies: '' })
									return;
								}

								this.setState({ inputOriginalCopies: `${parseInt(value)}` })
							}}
						/>
					</div>
				</div>
			)
		}

		return null;
	}
	getOriginalTokenApproveForAll() {
		if (
			this.state.originalTokenInfo.owner &&
			this.state.originalTokenInfo.owner.toLowerCase() !== this.state.userAddress.toLowerCase()
		) {
			return null;
		}

		if (
			this.state.originalTokenInfo.originalTokenStandart === _AssetType.ERC721 &&
			!this.state.originalTokenInfo.isApproved
		) {
			return (
				<div className="pt-4">
					<label className="checkbox">
						<input
							type="checkbox"
							name="tokens-approval"
							checked={ this.state.inputApproveAll }
							onChange={(e) => {
								this.setState({ inputApproveAll: e.target.checked });
							}}
						/>
						<span className="check"> </span>
						<span className="check-text">
							Set approval for all tokens of&nbsp;contract
							<Tippy
								content={ this.t('Grant permission to the protocol for all your NFT smart contract to use them') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span className="i-tip"></span>
							</Tippy>
						</span>
					</label>
				</div>
			)
		}
	}
	getTokenInfoBlock() {

		const getCollectionImg = () => {
			if ( !this.state.originalTokenInfo.collectionImg ) {
				return (
					<div className="img">
						<img src={ default_token_preview } alt="" />
				</div>
				)
			}
			return  (
				<div className="img">
					<img src={ this.state.originalTokenInfo.collectionImg } alt="" />
				</div>
			)
		}

		return (
			<div
				className="c-wrap"
				ref={ (e) => { this.scrollable.originalToken = e } }
			>
				<div className="row">
					<div className="col-12 col-md-7">
						<div className="input-group mb-md-0">
							<label className="input-label">
								NFT Address
								<Tippy
									content={ this.t('Minter contract address of your original NFT') }
									appendTo={ document.getElementsByClassName("wrapper")[0] }
									trigger='mouseenter'
									interactive={ false }
									arrow={ false }
									maxWidth={ 512 }
								>
									<span className="i-tip"></span>
								</Tippy>
							</label>
							<input
								className="input-control"
								type="text"
								placeholder="Paste here"
								disabled={ this.state.inputEmptyChecked }
								value={ this.state.inputOriginalTokenAddress }
								onChange={(e) => {
									this.fetchOriginalTokenInfo(e.target.value, this.state.inputOriginalTokenId);
									this.originalTokenInfoPending = false;
									this.setState({
										inputOriginalTokenAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, ""),
										originalTokenInfo: {
											infoLoaded           : false,
											projectName          : '',
											tokenName            : '',
											collectionImg        : '',
											badContract          : false,
											isApproved           : false,
											originalTokenStandart: undefined,
											owner                : undefined,
											balance1155          : undefined,
										},
										originalTokenWrapped: undefined,
									})
								}}
							/>
						</div>
					</div>
					<div className="col-12 col-md-5">
						<div className="input-group mb-md-0">
							<label className="input-label">
								Token ID
								<Tippy
									content={ this.t('Token ID of your original NFT') }
									appendTo={ document.getElementsByClassName("wrapper")[0] }
									trigger='mouseenter'
									interactive={ false }
									arrow={ false }
									maxWidth={ 512 }
								>
									<span className="i-tip"></span>
								</Tippy>
							</label>
							<input
								className="input-control"
								type="text"
								placeholder="Paste here"
								disabled={ this.state.inputEmptyChecked }
								value={ this.state.inputOriginalTokenId }
								onChange={(e) => {
									this.fetchOriginalTokenInfo(this.state.inputOriginalTokenAddress, e.target.value);
									this.originalTokenInfoPending = false;
									this.setState({
										inputOriginalTokenId: e.target.value.toLowerCase().replace(/[^0-9]/g, ""),
										originalTokenInfo: {
											infoLoaded           : false,
											projectName          : '',
											tokenName            : '',
											collectionImg        : '',
											badContract          : false,
											isApproved           : false,
											originalTokenStandart: undefined,
											owner                : undefined,
											balance1155          : undefined,
										},
										originalTokenWrapped: undefined,
									})
								}}
							/>
						</div>
					</div>
				</div>

				{ this.getTokenTypeAlert() }
				{ this.getTokenOwnerAlert() }

				<div className="row mt-md-3">

					<div className="col-12 col-md-7">
						<div className="wrap__nft-prev">
							{ getCollectionImg() }
							{ this.getCollectionBlock() }
						</div>
					</div>

					{ this.getOriginalTokenCopiesBlock() }

					{ this.getOriginalTokenApproveForAll() }

				</div>

			</div>
		)
	}
	getCopiesBlock() {
		const showError = this.state.showErrors.find((item) => { return item === 'standart' });

		if ( this.state.inputStandart === _AssetType.ERC1155 ) {
			return (
				<div className="col-12 col-md-4">
					<div className="input-group mb-md-0">
						<label className="input-label">Copies</label>
						<NumberInput
							value={ this.state.inputCopies }
							onChange={(e: number | undefined) => {
								this.setState({
									inputCopies: e || 1,
									showErrors: this.state.showErrors.filter((item) => { return item !== 'standart' })
								})
							}}
							min={ 1 }
							inputClass={ showError ? 'has-error' : '' }
						/>
					</div>
				</div>
			)
		}
	}
	getStandartSelectorBlock() {
		return (
			<div
				className="c-wrap"
				ref={ (e) => { this.scrollable.standart = e } }
			>
				<div className="row">
					<div className="col-12 col-md-6">
						<div className="input-group mb-md-0">
							<label className="input-label">wNFT Standard</label>
							<div className="row row-sm">
								<div className="col-auto my-2">
									<label className="checkbox">
										<input
											type="radio"
											name="wnft-standard"
											value={ _AssetType.ERC721 }
											checked={ this.state.inputStandart === _AssetType.ERC721 }
											onChange={() => { this.setState({ inputStandart: _AssetType.ERC721 }) }}
										/>
										<span className="check"> </span>
										<span className="check-text"><b>{ this.state.EIPPrefix }-721</b></span>
									</label>
								</div>
								<div className="col-auto my-2">
									<label className="checkbox">
										<input
											type="radio"
											name="wnft-standard"
											value={ _AssetType.ERC1155 }
											checked={ this.state.inputStandart === _AssetType.ERC1155 }
											onChange={() => { this.setState({ inputStandart: _AssetType.ERC1155 }) }}
										/>
										<span className="check"> </span>
										<span className="check-text"> <b>{ this.state.EIPPrefix }-1155</b></span>
									</label>
								</div>
							</div>
						</div>
					</div>
					{ this.getCopiesBlock() }
				</div>
			</div>
		)
	}
	getAdvancedOptionsBlock() {
		return (
			// <div className="c-wrap p-0 pb-3">
			<div
				className="c-wrap p-0"
				ref={ (e) => { this.scrollable.advancedOptions = e } }
			>
				<div
					className={`c-wrap__toggle ${this.state.advancedOptionsOpened ? 'active' : ''}`}
					onClick={() => {
						this.setState({
							advancedOptionsOpened: !this.state.advancedOptionsOpened
						});
					}}
				>
					<div><b>Advanced options</b></div>
				</div>
				<div className="c-wrap__dropdown">
					{/* <div className="input-group">
						<label className="input-label">Collateral Recipient</label>
						<input
							className="input-control"
							type="text"
							placeholder="Paste here"
							value={ this.state.inputCollateralRecipientAddress }
							onChange={(e) => { this.setState({ inputCollateralRecipientAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") }) }}
						/>
					</div> */}
					<div className="input-group">
						<label className="input-label">wNFT Recipient</label>
						<input
							className="input-control"
							type="text"
							placeholder="Paste here"
							value={ this.state.inputWNFTRecipientAddress }
							onChange={(e) => { this.setState({ inputWNFTRecipientAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") }) }}
						/>
					</div>
					<div className="input-group pt-2 mb-0">
						<label className="input-label pb-1">Disable:</label>
						<div className="mb-3">
							<label className="checkbox">
								<input
									type="checkbox"
									name=""
									checked={ this.state.inputRules.noUnwrap }
									onChange={(e) => {
										this.setState({
											inputRules: {
												...this.state.inputRules,
												noUnwrap: e.target.checked
											},
											inputTimeLockChecked: false,
											inputTimeLockDays   : '',
											inputFeeLockChecked : false,
											inputFeeLockAmount  : '',
										})
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">
									Unwrapping
									<Tippy
										content={ 'The owner of a future wNFT will be unable to unwrap it' }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 512 }
									>
										<span className="i-tip ml-1"></span>
									</Tippy>
								</span>
							</label>
						</div>
						<div className="mb-3">
							<label className="checkbox">
								<input
									type="checkbox"
									name=""
									checked={ this.state.inputRules.noAddCollateral }
									onChange={(e) => {
										this.setState({
											inputRules: {
												...this.state.inputRules,
												noAddCollateral: e.target.checked
											}
										})
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">
									Adding collateral
									<Tippy
										content={ 'No one will be able to add collateral to future wNFT transactions' }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 512 }
									>
										<span className="i-tip ml-1"></span>
									</Tippy>
								</span>
							</label>
						</div>
						{/* <div className="mb-3">
							<label className="checkbox">
								<input
									type="checkbox"
									name=""
									checked={ this.state.inputRules.noWrap }
									onChange={(e) => {
										this.setState({
											inputRules: {
												...this.state.inputRules,
												noWrap: e.target.checked
											}
										})
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">
									Second wrapping
									<Tippy
										content={ 'The owner of a future wNFT will be unable to wrap it a second time' }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 512 }
									>
										<span className="i-tip ml-1"></span>
									</Tippy>
								</span>
							</label>
						</div> */}
						<div>
							<label className="checkbox">
								<input
									type="checkbox"
									name=""
									checked={ this.state.inputRules.noTransfer }
									onChange={(e) => {
										if ( e.target.checked ) {
											this.setState({
												inputRules: {
													...this.state.inputRules,
													noTransfer: e.target.checked,
												},
												collaterals: this.state.collaterals.filter((item: CollateralItem) => { return !item.amount || !item.amount.eq(0) }),
											});
										} else {
											if ( this.state.inputAddWNFTChecked && this.state.inputTransferFeeAmount !== '' ) {
												setTimeout(() => { this.addCollateralERC20Row(true) }, 1);
											}
											this.setState({
												inputRules: {
													...this.state.inputRules,
													noTransfer: e.target.checked
												},
												inputFeeLockChecked: false
											})
										}
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">
									Transferring (Soulbound <span className="text-nowrap"> Token) <Tippy
										content={ 'The owner of future wNFT will be unable to transfer it' }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 512 }
									>
										<span className="i-tip ml-1"></span>
									</Tippy>
									</span>
								</span>
							</label>
							<p><small className="text-muted">These options are&nbsp;irreversible. The custodian is&nbsp;accountable for&nbsp;any protocol-related actions.</small></p>
						</div>
					</div>
				</div>
			</div>
		)
	}

	// ----- COLLATERAL -----
	// ----- COLLATERAL NATIVE -----
	isNativeBalanceEnough() {
		const amountParsed = new BigNumber(this.state.inputCollateralAmount);

		if ( !amountParsed || amountParsed.isNaN() || amountParsed.eq(0) ) { return true; }
		if ( tokenToInt(amountParsed, this.state.decimalsNative || 18).lte(this.state.balanceNative) ) { return true; }

		return false;
	}
	isCollateralNativeBtnDisabled() {
		if ( this.state.inputCollateralAmount === '' ) { return true; }

		const amountParsed = new BigNumber(this.state.inputCollateralAmount);
		if ( !amountParsed || amountParsed.isNaN() || amountParsed.eq(0) ) { return true; }

		if ( this.state.inputRules.noAddCollateral ) { return true; }

		if ( !this.isNativeBalanceEnough() ) { return true; }

		const foundNativeCollateral = this.state.collaterals.find((item: CollateralItem) => { return item.assetType === _AssetType.native });
		if ( foundNativeCollateral ) { return false; }

		if ( this.isMaxCollaterals() || this.overMaxCollaterals() ) { return true; }

		return false;
	}
	addCollateralNativeRow() {
		let amountNative = new BigNumber(0);
		const nativeAdded = this.state.collaterals.filter((item) => { return item.assetType === _AssetType.native });
		if ( nativeAdded.length && nativeAdded[0].amount ) {
			amountNative = new BigNumber(nativeAdded[0].amount);
		}
		const collateralsUpdated = [
			...this.state.collaterals.filter((item) => { return item.assetType !== _AssetType.native }),
			{
				assetType: _AssetType.native,
				address: '0x0000000000000000000000000000000000000000',
				amount: tokenToInt(new BigNumber(this.state.inputCollateralAmount), this.state.decimalsNative || 18).plus(amountNative),
			}
		];
		this.setState({
			collaterals: collateralsUpdated,
			inputCollateralAmount: '',
		})
	}
	getAddCollateralNativeBtn() {
		return (
			<button
				className="btn btn-grad"
				disabled={ this.isCollateralNativeBtnDisabled() }
				onClick={() => { this.addCollateralNativeRow() }}
			>Add</button>
		)
	}
	getCollateralNativeBalance() {
		return (
			<div className="c-add__max mt-3">
				<div>
					<span>Max: </span>
					<button
						onClick={() => { this.setState({ inputCollateralAmount: tokenToFloat(this.state.balanceNative, this.state.decimalsNative || 18).toString() }) }}
					>{ tokenToFloat(this.state.balanceNative, this.state.decimalsNative || 18).toFixed(5) }</button>
				</div>
			</div>
		)
	}
	getNativeAmountInput() {
		if ( this.isNativeBalanceEnough() ) {
			return (
				<React.Fragment>
					<div className="select-group">
						<input
							className="input-control"
							type="text"
							placeholder="0.000"
							value={ addThousandSeparator(this.state.inputCollateralAmount) }
							onChange={(e) => {
								let value = removeThousandSeparator(e.target.value);
								if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
									if ( new BigNumber(value).isNaN() ) { return; }
									value = new BigNumber(value).toString();
								}
								this.setState({ inputCollateralAmount: value, })
							}}
							onKeyPress={(e) => {
								if ( e.defaultPrevented)                      { return; }
								if ( !!this.isCollateralNativeBtnDisabled() ) { return; }
								if ( e.key !== 'Enter' )                      { return; }

								this.addCollateralNativeRow()
							}}
						/>
						<div className="select-coin">
							<div className="select-coin__value">
								<span className="field-unit">
									<span className="i-coin">
										<img src={ this.state.iconNative } alt="" />
									</span>
									{ this.state.symbolNative }
								</span>
							</div>
						</div>
					</div>
				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment>
					<div className="select-group">
						<input
							className="input-control has-error"
							type="text"
							placeholder="0.000"
							value={ addThousandSeparator(this.state.inputCollateralAmount) }
							onChange={(e) => {
								let value = removeThousandSeparator(e.target.value);
								if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
									if ( new BigNumber(value).isNaN() ) { return; }
									value = new BigNumber(value).toString();
								}
								this.setState({ inputCollateralAmount: value, })
							}}
							onKeyPress={(e) => {
								if ( e.defaultPrevented)                      { return; }
								if ( !!this.isCollateralNativeBtnDisabled() ) { return; }
								if ( e.key !== 'Enter' )                      { return; }

								this.addCollateralNativeRow()
							}}
						/>
						<div className="select-coin">
							<div className="select-coin__value">
								<span className="field-unit">
									<span className="i-coin">
										<img src={ this.state.iconNative } alt="" />
									</span>
									{ this.state.symbolNative }
								</span>
							</div>
						</div>
					</div>
					<div className="input-error">Not enough balance</div>
				</React.Fragment>
			)
		}
	}
	getCollateralNativeBlock() {
		if ( this.state.inputAssetType === _AssetType.native ) {
			return (
				<div className="row">
					<div className="col col-12 col-md-7">
						<label className="input-label">Amount</label>
						{ this.getNativeAmountInput() }

						{ this.getCollateralNativeBalance() }
					</div>
					<div className="col col-12 col-md-2">
						<label className="input-label">&nbsp;</label>
						{ this.getAddCollateralNativeBtn() }
					</div>
				</div>
			)
		}
	}
	// ----- END COLLATERAL NATIVE -----

	// ----- COLLATERAL ERC20 -----
	isERC20BalanceEnough() {
		const amountParsed = new BigNumber(this.state.inputCollateralAmount);
		const foundToken = this.state.erc20CollateralTokens.find((item) => { return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
		if ( foundToken && foundToken.balance.lt(tokenToInt(amountParsed, foundToken.decimals || 18)) ) { return false; }

		return true;
	}
	isCollateralERC20BtnDisabled() {
		if ( this.state.inputCollateralAddress === '' ) { return true; }
		if ( this.state.inputCollateralAmount  === '' ) { return true; }

		const amountParsed = new BigNumber(this.state.inputCollateralAmount);
		if ( !amountParsed || amountParsed.isNaN() || amountParsed.eq(0) ) { return true; }

		if ( this.state.inputRules.noAddCollateral ) { return true; }

		if ( !this.isCollateralAddressInWhitelist()   ) { return true; }
		if ( !this.isERC20BalanceEnough()             ) { return true; }

		const foundERC20Collateral = this.state.collaterals.find((item: CollateralItem) => { return item.assetType === _AssetType.ERC20 && item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
		if ( !foundERC20Collateral ) {
			if ( this.isMaxCollaterals() || this.overMaxCollaterals() ) { return true; }
		}

		return false;
	}
	addCollateralERC20Row(empty?: boolean) {

		// empty means 0-amount collateral slot
		// in case of checked the wNFT royalty recipient
		// for collecting royalty

		let amountAdded = new BigNumber(0);
		let amountToAdd = empty ? new BigNumber(0) : new BigNumber(this.state.inputCollateralAmount);
		let addressToAdd = empty ? this.state.inputTransferFeeAddress : this.state.inputCollateralAddress;

		const erc20Added = this.state.collaterals.filter((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === addressToAdd.toLowerCase()
		});
		if ( erc20Added.length && erc20Added[0].amount ) {
			amountAdded = new BigNumber(erc20Added[0].amount);
		}

		let tokenToAdd = undefined;
		if ( this.state.techToken.address.toLowerCase() === addressToAdd.toLowerCase() ) {
			tokenToAdd = this.state.techToken;
		} else {
			const foundToken = this.state.erc20CollateralTokens.filter((item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() === addressToAdd.toLowerCase()
			});
			if ( foundToken.length ) {
				tokenToAdd = foundToken[0];
			}
		}
		if ( tokenToAdd ) {
			amountAdded = tokenToFloat(new BigNumber(amountAdded), tokenToAdd.decimals || 18);
			amountToAdd = tokenToInt(amountToAdd.plus(amountAdded), tokenToAdd.decimals || 18);
		} else {
			amountToAdd = amountToAdd.plus(amountAdded)
		}

		let collateralsUpdated = this.state.collaterals;
		if ( empty ) {
			collateralsUpdated = collateralsUpdated.filter((item: CollateralItem) => { return !item.amount || !item.amount.eq(0) });
		}
		collateralsUpdated = [
			...collateralsUpdated.filter((item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() !== addressToAdd.toLowerCase()
			}),
			{
				assetType: _AssetType.ERC20,
				address: addressToAdd,
				amount: amountToAdd,
			}
		];
		this.setState({
			collaterals: collateralsUpdated,
			inputCollateralAmount: '',
			inputCollateralAddress: '',
		})
	}
	getAddCollateralERC20Btn() {
		return (
			<button
				className="btn btn-grad"
				disabled={ !!this.isCollateralERC20BtnDisabled() }
				onClick={() => { this.addCollateralERC20Row() }}
			>Add</button>
		)
	}
	getCollateralERC20AmountTitle() {
		const foundERC20 = this.state.erc20CollateralTokens.filter((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase()
		});
		if (
			this.state.inputCollateralAddress &&
			this.state.inputCollateralAddress !== '' &&
			this.state.inputCollateralAddress !== '0' &&
			this.state.inputCollateralAddress !== '0x0000000000000000000000000000000000000000' &&
			this.state.inputCollateralAddress.toLowerCase() !== this.state.techToken.address.toLowerCase() &&
			!foundERC20.length
		) {
			return (
				<Tippy
					content={ this.t('Cannot get decimals from contract, enter amount in wei') }
					appendTo={ document.getElementsByClassName("wrapper")[0] }
					trigger='mouseenter'
					interactive={ false }
					arrow={ false }
					maxWidth={ 512 }
				>
					<label className="input-label text-orange">{ this.t('Amount') }*</label>
				</Tippy>
			)
		} else {
			return (
				<label className="input-label">
					{ this.t('Amount') }
					<Tippy
						content={ this.t('Maximum and allowanced amount of tokens which you can add to collateral of wrapped nft') }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<span className="i-tip"></span>
					</Tippy>
				</label>
			)
		}
		// return ( <label className="input-label">{ this.t('Amount') }</label> )
	}
	getCollateralERC20Balance() {
		if ( this.state.inputCollateralAddress === '' ) { return null; }
		const foundToken = this.state.erc20CollateralTokens.find((item) => { return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
		if ( !foundToken ) { return null; }
		return (
			<div className="c-add__max mt-2 mb-0">
				<div className="mt-1 mb-1">
					<span>Max: </span>
					<button
						onClick={() => { this.setState({ inputCollateralAmount: tokenToFloat(foundToken.balance, foundToken.decimals || 18).toString() }) }}
					>{ tokenToFloat(foundToken.balance, foundToken.decimals || 18).toString() }</button>
				</div>
				<div>
					<span>Allowance: </span>
					<button
						onClick={() => { this.setState({ inputCollateralAmount: tokenToFloat(foundToken.allowance, foundToken.decimals || 18).toString() }) }}
					>{ tokenToFloat(foundToken.allowance, foundToken.decimals || 18).toString() }</button>
				</div>
			</div>
		)
	}
	getCollateralERC20AddressInput() {
		if ( !this.isCollateralAddressInWhitelist() ) {
			return (
				<React.Fragment>
					<div className="select-group">
						<input
							className="input-control has-error"
							type="text"
							placeholder="0.000"
							value={ this.state.inputCollateralAddress }
							onChange={(e) => {
								const value = e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "");
								if ( value !== '' ) { this.metamaskAdapter.addERC20Contracts(value) }
								this.setState({ inputCollateralAddress: value })
							}}
							onKeyPress={(e) => {
								if ( e.defaultPrevented)                     { return; }
								if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
								if ( e.key !== 'Enter' )                     { return; }

								this.addCollateralERC20Row()
							}}
						/>
						<CoinSelector
							store         = { this.store }
							tokens        = { this.filterERC20ContractByPermissions({ enabledForCollateral: true }) }
							selectedToken = { this.state.inputCollateralAddress }
							onChange      = {(address: string) => { this.setState({ inputCollateralAddress: address }) }}
						/>
					</div>
					<div className="input-error">Address is not in whitelist</div>
				</React.Fragment>
			)
		}

		return (
			<React.Fragment>
				<div className="select-group">
					<input
						className="input-control"
						type="text"
						placeholder="0.000"
						value={ this.state.inputCollateralAddress }
						onChange={(e) => {
							const value = e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "");
							if ( value !== '' ) { this.metamaskAdapter.addERC20Contracts(value) }
							this.setState({ inputCollateralAddress: value })
						}}
						onKeyPress={(e) => {
							if ( e.defaultPrevented)                     { return; }
							if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
							if ( e.key !== 'Enter' )                     { return; }

							this.addCollateralERC20Row()
						}}
					/>
					<CoinSelector
						store         = { this.store }
						tokens        = { this.filterERC20ContractByPermissions({ enabledForCollateral: true }) }
						selectedToken = { this.state.inputCollateralAddress }
						onChange      = {(address: string) => { this.setState({ inputCollateralAddress: address }) }}
					/>
				</div>
			</React.Fragment>
		)

	}
	getCollateralERC20AmountInput() {
		if ( !this.isERC20BalanceEnough() ) {
			return (
				<React.Fragment>
					<input
						className="input-control has-error"
						type="text"
						placeholder=""
						value={ addThousandSeparator(this.state.inputCollateralAmount) }
						onChange={(e) => {
							let value = removeThousandSeparator(e.target.value);
							if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
								if ( new BigNumber(value).isNaN() ) { return; }
								value = new BigNumber(value).toString();
							}
							this.setState({ inputCollateralAmount: value, })
						}}
						onKeyPress={(e) => {
							if ( e.defaultPrevented)                     { return; }
							if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
							if ( e.key !== 'Enter' )                     { return; }

							this.addCollateralERC20Row()
						}}
					/>
					<div className="input-error">Not enough balance</div>
				</React.Fragment>
			)
		}

		return (
			<React.Fragment>
				<input
					className="input-control"
					type="text"
					placeholder=""
					value={ addThousandSeparator(this.state.inputCollateralAmount) }
					onChange={(e) => {
						let value = removeThousandSeparator(e.target.value);
						if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
							if ( new BigNumber(value).isNaN() ) { return; }
							value = new BigNumber(value).toString();
						}
						this.setState({ inputCollateralAmount: value, })
					}}
					onKeyPress={(e) => {
						if ( e.defaultPrevented)                     { return; }
						if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
						if ( e.key !== 'Enter' )                     { return; }

						this.addCollateralERC20Row()
					}}
				/>
			</React.Fragment>
		)

	}
	getERC20Link() {
		if ( this.state.inputCollateralAddress === '' ) { return null; }
		let foundToken: ERC20ContractParamsType | undefined = undefined;
		if ( this.state.inputCollateralAddress.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
			foundToken = this.state.techToken;
		} else {
			foundToken = this.state.erc20CollateralTokens.find((item) => { return item.address.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
		}
		if ( !foundToken ) { return null; }

		let tokenImage = '';
		if ( foundToken.name.toLowerCase() === 'niftsy' ) {
			tokenImage = this.state.niftsyTokenIcon
		}

		return (

			<div className="d-flex align-items-center flex-wrap">
				<a
					className="ex-link mr-3 mt-3 mb-3"
					target="_blank"
					rel="noopener noreferrer"
					href={ `${this.state.explorerBaseUrl}/token/${foundToken.address}` }
				>
					<small>More about { foundToken.symbol }</small>
					<img className="i-ex" src={ icon_external } alt="" />
				</a>
				<a
					className="btn btn-sm btn-gray"
					href="/"
					onClick={(e) => {
						e.preventDefault();
						if ( !foundToken ) { return; }
						try {
							if ( !(window as any).ethereum ) { return; }

							(window as any).ethereum.request({
								method: 'wallet_watchAsset',
								params: {
									type: 'ERC20', // Initially only supports ERC20, but eventually more!
									options: {
										address: foundToken.address, // The address that the token is at.
										symbol: foundToken.symbol, // A ticker symbol or shorthand, up to 5 chars.
										decimals: foundToken.decimals, // The number of decimals in the token
										image: tokenImage, // A string url of the token logo
									},
								},
							});
						} catch(e) {
							console.log(e)
						}
					}}
				>Add to Metamask</a>
			</div>
		)
	}
	getCollateralERC20Block() {
		if ( this.state.inputAssetType === _AssetType.ERC20 ) {
			return (
				<React.Fragment>
					<div className="row">
						<div className="col col-12 col-md-7">
							<label className="input-label">Token Address</label>
							{ this.getCollateralERC20AddressInput() }
							{ this.getERC20Link() }

						</div>
						<div className="col col-12 col-md-3">
							{ this.getCollateralERC20AmountTitle() }
							{ this.getCollateralERC20AmountInput() }
							{ this.getCollateralERC20Balance() }
						</div>
						<div className="col col-12 col-md-2">
							<label className="input-label">&nbsp;</label>
							{ this.getAddCollateralERC20Btn() }
						</div>
					</div>
				</React.Fragment>
			)
		}
	}
	// ----- END COLLATERAL ERC20 -----

	// ----- COLLATERAL ERC721 -----
	isCollateralERC721BtnDisabled() {
		if ( this.state.inputCollateralAddress === '' ) { return true; }
		if ( this.state.inputCollateralTokenId === '' ) { return true; }

		if ( this.state.inputRules.noAddCollateral ) { return true; }

		if ( !this.isCollateralAddressInWhitelist()   ) { return true; }

		if ( this.isMaxCollaterals() || this.overMaxCollaterals() ) { return true; }

		return false;
	}
	addCollateralERC721Row() {
		const address = this.state.inputCollateralAddress;
		const tokenId = this.state.inputCollateralTokenId;

		const collateralsUpdated = [
			...this.state.collaterals.filter((item) => {
					if ( item.assetType !== _AssetType.ERC721 ) { return true; }
					if ( !item.address ) { return false; }
					return item.address.toLowerCase() !== address.toLowerCase() ||
						item.tokenId !== tokenId
				}),
			{
				assetType: _AssetType.ERC721,
				address: address,
				tokenId: tokenId,
			}
		];
		this.setState({
			collaterals: collateralsUpdated,
			inputCollateralAmount: '',
			inputCollateralAddress: '',
			inputCollateralTokenId: '',
		})

		getOriginalToken({
			metamaskAdapter: this.metamaskAdapter,
			contractAddress: address,
			tokenId: tokenId,
			t: this.t,
			assetType: _AssetType.ERC721,
			userAddress: this.state.userAddress,
		})
			.then((data) => {

				const collateralsUpdated = [
					...this.state.collaterals.filter((item) => {
						if ( item.assetType !== _AssetType.ERC721 ) { return true; }
						if ( !item.address ) { return false; }
						return item.address.toLowerCase() !== address.toLowerCase() ||
							item.tokenId !== tokenId
					}),
					{
						assetType: _AssetType.ERC721,
						address: address,
						tokenId: tokenId,
						tokenImg: data.image || ''
					}
				];

				let collateralErrorsUpdated = this.state.collateralErrors;
				if ( data.owner && data.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) {
					collateralErrorsUpdated = [
						...collateralErrorsUpdated,
						{
							address: address,
							tokenId: tokenId,
							msg: 'Not yours'
						}
					]
				}

				this.setState({
					collaterals: collateralsUpdated,
					collateralErrors: collateralErrorsUpdated,
				})

			})
			.catch((error) => {
				console.log('Cannot fetch 721 token', error);
				const collateralsUpdated = [
					...this.state.collaterals.filter((item) => {
						if ( item.assetType !== _AssetType.ERC721 ) { return true; }
						if ( !item.address ) { return false; }
						return item.address.toLowerCase() !== address.toLowerCase() ||
							item.tokenId !== tokenId
					}),
					{
						assetType: _AssetType.ERC721,
						address: address,
						tokenId: tokenId,
						tokenImg: ''
					}
				];

				let collateralErrorsUpdated = [
					...this.state.collateralErrors,
					{
						address: address,
						tokenId: tokenId,
						msg: 'Cannot fetch token'
					}
				]

				this.setState({
					collaterals: collateralsUpdated,
					collateralErrors: collateralErrorsUpdated,
				})
			})
	}
	getAddCollateralERC721Btn() {
		return (
			<button
				className="btn btn-grad"
				disabled={ this.isCollateralERC721BtnDisabled() }
				onClick={() => { this.addCollateralERC721Row() }}
			>Add</button>
		)
	}
	getCollateralERC721AddressInput() {
		if ( !this.isCollateralAddressInWhitelist() ) {
			return (
				<React.Fragment>
					<input
						className="input-control"
						type="text"
						placeholder="Paste here"
						value={ this.state.inputCollateralAddress }
							onChange={(e) => { this.setState({ inputCollateralAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") }) }}
							onKeyPress={(e) => {
								if ( e.defaultPrevented)                     { return; }
								if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
								if ( e.key !== 'Enter' )                     { return; }

								this.addCollateralERC721Row()
							}}
					/>
					<div className="input-error">Address is not in whitelist</div>
				</React.Fragment>
			)
		}

		return (
			<React.Fragment>
				<input
					className="input-control"
					type="text"
					placeholder="Paste here"
					value={ this.state.inputCollateralAddress }
						onChange={(e) => { this.setState({ inputCollateralAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") }) }}
						onKeyPress={(e) => {
							if ( e.defaultPrevented)                     { return; }
							if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
							if ( e.key !== 'Enter' )                     { return; }

							this.addCollateralERC721Row()
						}}
				/>
			</React.Fragment>
		)

	}
	getCollateralERC721Block() {
		if ( this.state.inputAssetType === _AssetType.ERC721 ) {
			return (
				<div className="row">
					<div className="col col-12 col-md-7">
						<label className="input-label">NFT Address</label>
						{ this.getCollateralERC721AddressInput() }
					</div>
					<div className="col col-12 col-md-3">
						<label className="input-label">Token ID</label>
						<input
							className="input-control"
							type="text"
							placeholder="99 900"
							value={ addThousandSeparator(this.state.inputCollateralTokenId) }
							onChange={(e) => { this.setState({ inputCollateralTokenId: e.target.value.toLowerCase().replace(/[^0-9]/g, "") }) }}
							onKeyPress={(e) => {
								if ( e.defaultPrevented)                     { return; }
								if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
								if ( e.key !== 'Enter' )                     { return; }

								this.addCollateralERC721Row()
							}}
						/>
					</div>
					<div className="col col-12 col-md-2">
						<label className="input-label">&nbsp;</label>
						{ this.getAddCollateralERC721Btn() }
					</div>
				</div>
			)
		}
	}
	// ----- END COLLATERAL ERC721 -----

	// ----- COLLATERAL ERC1155 -----
	isCollateralERC1155BtnDisabled() {
		if ( this.state.inputCollateralAddress === '' ) { return true; }
		if ( this.state.inputCollateralTokenId === '' ) { return true; }

		if ( this.state.inputRules.noAddCollateral ) { return true; }

		const amountParsed = new BigNumber(this.state.inputCollateralAmount);
		if ( !amountParsed || amountParsed.isNaN() || amountParsed.eq(0) ) { return true; }


		if ( !this.isCollateralAddressInWhitelist()   ) { return true; }

		if ( this.isMaxCollaterals() || this.overMaxCollaterals() ) { return true; }

		return false;
	}
	addCollateralERC1155Row() {
		const address = this.state.inputCollateralAddress;
		const tokenId = this.state.inputCollateralTokenId;
		const amount  = new BigNumber(this.state.inputCollateralAmount);

		const collateralsUpdated = [
			...this.state.collaterals.filter((item) => {
				if ( item.assetType !== _AssetType.ERC1155 ) { return true; }
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() !== address.toLowerCase() ||
					item.tokenId !== tokenId
			}),
			{
				assetType: _AssetType.ERC1155,
				address: address,
				tokenId: tokenId,
				amount : amount,
			}
		];
		this.setState({
			collaterals: collateralsUpdated,
			inputCollateralAmount: '',
			inputCollateralAddress: '',
			inputCollateralTokenId: '',
		})

		getOriginalToken({
			metamaskAdapter: this.metamaskAdapter,
			contractAddress: address,
			tokenId: tokenId,
			t: this.t,
			userAddress: this.state.userAddress,
			assetType: _AssetType.ERC1155
		})
			.then((data) => {

				const collateralsUpdated = [
					...this.state.collaterals.filter((item) => {
						if ( item.assetType !== _AssetType.ERC1155 ) { return true; }
						if ( !item.address ) { return false; }
						return item.address.toLowerCase() !== address.toLowerCase() ||
							item.tokenId !== tokenId
					}),
					{
						assetType: _AssetType.ERC1155,
						address: address,
						tokenId: tokenId,
						amount : amount,
						tokenImg: data.image || ''
					}
				];

				let collateralErrorsUpdated = this.state.collateralErrors;
				if ( data.amount && data.amount.eq(0) ) {
					collateralErrorsUpdated = [
						...collateralErrorsUpdated,
						{
							address: address,
							tokenId: tokenId,
							msg: 'Not enough balance'
						}
					]
				}

				this.setState({
					collaterals: collateralsUpdated,
					collateralErrors: collateralErrorsUpdated,
				})

			})
			.catch((error) => {
				console.log('Cannot fetch 1155 token', error);
				const collateralsUpdated = [
					...this.state.collaterals.filter((item) => {
						if ( item.assetType !== _AssetType.ERC1155 ) { return true; }
						if ( !item.address ) { return false; }
						return item.address.toLowerCase() !== address.toLowerCase() ||
							item.tokenId !== tokenId
					}),
					{
						assetType: _AssetType.ERC1155,
						address: address,
						tokenId: tokenId,
						amount : amount,
						tokenImg: ''
					}
				];

				let collateralErrorsUpdated = [
					...this.state.collateralErrors,
					{
						address: address,
						tokenId: tokenId,
						msg: 'Cannot fetch token'
					}
				]

				this.setState({
					collaterals: collateralsUpdated,
					collateralErrors: collateralErrorsUpdated,
				})
			})
	}
	getAddCollateralERC1155Btn() {
		return (
			<button
				className="btn btn-grad"
				disabled={ this.isCollateralERC1155BtnDisabled() }
				onClick={() => { this.addCollateralERC1155Row() }}
			>Add</button>
		)
	}
	getCollateralERC1155AddressInput() {
		if ( !this.isCollateralAddressInWhitelist() ) {
			return (
				<React.Fragment>
					<input
						className="input-control"
						type="text"
						placeholder="Paste here"
						value={ this.state.inputCollateralAddress }
						onChange={(e) => { this.setState({ inputCollateralAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") }) }}
						onKeyPress={(e) => {
							if ( e.defaultPrevented )                    { return; }
							if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
							if ( e.key !== 'Enter' )                     { return; }

							this.addCollateralERC1155Row()
						}}
					/>
					<div className="input-error">Address is not in whitelist</div>
				</React.Fragment>
			)
		}

		return (
			<React.Fragment>
				<input
					className="input-control"
					type="text"
					placeholder="Paste here"
					value={ this.state.inputCollateralAddress }
					onChange={(e) => { this.setState({ inputCollateralAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") }) }}
					onKeyPress={(e) => {
						if ( e.defaultPrevented )                    { return; }
						if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
						if ( e.key !== 'Enter' )                     { return; }

						this.addCollateralERC1155Row()
					}}
				/>
			</React.Fragment>
		)
	}
	getCollateralERC1155Block() {
		if ( this.state.inputAssetType === _AssetType.ERC1155 ) {
			return (
				<div className="row">
					<div className="col col-12 col-md-5">
						<label className="input-label">NFT Address</label>
						{ this.getCollateralERC1155AddressInput() }
					</div>
					<div className="col col-12 col-md-3">
						<label className="input-label">Token ID</label>
						<input
							className="input-control"
							type="text"
							placeholder="99 900"
							value={ addThousandSeparator(this.state.inputCollateralTokenId) }
							onChange={(e) => { this.setState({ inputCollateralTokenId: e.target.value.toLowerCase().replace(/[^0-9]/g, "") }) }}
							onKeyPress={(e) => {
								if ( e.defaultPrevented )                    { return; }
								if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
								if ( e.key !== 'Enter' )                     { return; }

								this.addCollateralERC1155Row()
							}}
						/>
						</div>
					<div className="col col-12 col-md-2">
						<label className="input-label">Amount</label>
						<input
							className="input-control"
							type="text"
							placeholder="10"
							value={ addThousandSeparator(this.state.inputCollateralAmount) }
							onChange={(e) => {
								// let value = removeThousandSeparator(e.target.value.replace(/[^0-9]/g, ""));
								// if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
								// 	if ( new BigNumber(value).isNaN() ) { return; }
								// 	value = new BigNumber(value).toString();
								// }
								// this.setState({ inputCollateralAmount: value, })
								const value = removeThousandSeparator(e.target.value.replaceAll(' ', ''));
								if (
									value === '' ||
									isNaN(parseInt(value))
								) {
									this.setState({ inputCollateralAmount: '' })
									return;
								}

								this.setState({ inputCollateralAmount: `${parseInt(value)}` })
							}}
							onKeyPress={(e) => {
								if ( e.defaultPrevented )                    { return; }
								if ( !!this.isCollateralERC20BtnDisabled() ) { return; }
								if ( e.key !== 'Enter' )                     { return; }

								this.addCollateralERC20Row()
							}}
						/>
					</div>
					<div className="col col-12 col-md-2">
						<label className="input-label">&nbsp;</label>
						{ this.getAddCollateralERC1155Btn() }
					</div>
				</div>
			)
		}
	}
	get1155Amount(qty: BigNumber | undefined) {
		if ( !qty ) { return '' }
		if ( qty.eq(0) ) { return '' }
		if ( qty.eq(1) ) { return '1 item' }
		return `${qty} items`
	}
	// ----- END COLLATERAL ERC1155 -----

	isCollateralAddressInWhitelist() {
		if ( this.state.inputCollateralAddress === '' ) { return true; }
		if ( this.state.collateralWhitelist ) {
			const foundWhitelistItem = this.state.collateralWhitelist.find((item: _Asset) => { return item.contractAddress.toLowerCase() === this.state.inputCollateralAddress.toLowerCase() });
			if ( !foundWhitelistItem ) { return false; }
		}

		return true;
	}
	isMaxCollaterals() {

		if ( this.state.inputCollateralSlots !== undefined ) {
			if ( this.state.collaterals.length === this.state.inputCollateralSlots ) {
				return true;
			}
		}
		if ( this.state.collaterals.length === this.state.maxCollaterals ) { return true; }

		return false;
	}
	overMaxCollaterals() {
		if ( this.state.inputCollateralSlots !== undefined ) {
			if ( this.state.collaterals.length > this.state.inputCollateralSlots ) {
				return true;
			}
		}
		if ( this.state.collaterals.length > this.state.maxCollaterals ) { return true; }

		return false;
	}
	getNonRemovableAddress() {

		if ( this.state.inputTransferFeeAmount === '' ) { return undefined; }
		if ( new BigNumber(this.state.inputTransferFeeAmount).isNaN() ) { return undefined; }
		if ( new BigNumber(this.state.inputTransferFeeAmount).eq(0) ) { return undefined; }
		if ( !this.state.inputAddWNFTChecked ) { return undefined; }

		return this.state.inputTransferFeeAddress
	}
	getCollateralRows() {
		return (
			<CollateralViewer
				store={this.store}
				metamaskAdapter={this.metamaskAdapter}
				t={ this.t }
				collaterals={
					this.state.collaterals.sort((item, prev) => {

						if (
							this.state.inputTransferFeeAmount !== '' &&
							!new BigNumber(this.state.inputTransferFeeAmount).isNaN() &&
							!new BigNumber(this.state.inputTransferFeeAmount).eq(0) &&
							this.state.inputAddWNFTChecked
						) {
							if ( item.address.toLowerCase() === this.state.inputTransferFeeAddress.toLowerCase() ) {
								return -1;
							} else {
								return 1;
							}
						}

						return item.assetType - prev.assetType
					})
				}
				collateralErrors={ this.state.collateralErrors }
				nonRemovableAddress={ this.getNonRemovableAddress() }
				removeRow={(item: CollateralItem) => {
					let collaterals = this.state.collaterals

					if  (
						this.state.inputAddWNFTChecked &&
						item.address.toLowerCase() === this.state.inputTransferFeeAddress.toLowerCase()
					) {
						collaterals = [
							...collaterals.filter((iitem) => { return iitem.address.toLowerCase() !== item.address.toLowerCase() || ( iitem.tokenId && item.tokenId && iitem.tokenId.toLowerCase() !== item.tokenId.toLowerCase() ) }),
							{
								...item,
								amount: new BigNumber(0)
							}
						]
					} else {
						collaterals = [
							...collaterals.filter((iitem) => { return iitem.address.toLowerCase() !== item.address.toLowerCase() || ( iitem.tokenId && item.tokenId && iitem.tokenId.toLowerCase() !== item.tokenId.toLowerCase() ) }),
						]
					}

					this.setState({
						collaterals,
						collateralErrors: this.state.collateralErrors.filter((iitem) => { return item.address.toLowerCase() !== iitem.address.toLowerCase() || item.tokenId !== iitem.tokenId })
					});
				}}
				width={ 'wide' }
			/>
		)
	}
	getMaxCollateralAlert() {
		if ( this.state.inputCollateralSlots && this.state.inputCollateralSlots === 0 && !this.state.collaterals.length ) { return null; }
		if ( this.overMaxCollaterals() ) { return ( <div className="alert alert-error mb-3">You have changed max collateral slots. Added amount exceeds allowed amount</div> ) }
		if ( this.isMaxCollaterals() ) { return ( <div className="alert alert-warning mb-3">You have reached the maximum number of collateral slots</div> ) }

		return null;
	}
	getCollateralItemAlert() {
		return this.state.collateralErrors.map((item) => { return ( <div key={ item.msg } className="alert alert-error mb-3">{`Error with collateral: ${item.address}:${item.tokenId || ''}: ${item.msg}`}</div> ) })
	}
	getCollateralSlotSelector() {
		return (
			<div className="d-flex align-items-center">
				<span className="text-muted text-right mr-2">
					Mах entries
					<Tippy
						content={ this.t('Enter the maximum number of tokens in collateral') }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<span className="i-tip"></span>
					</Tippy>
				</span>
				<NumberInput
					value={ this.state.inputCollateralSlots }
					onChange={(e: number | undefined) => { this.setState({ inputCollateralSlots: e }) }}
					min={ 0 }
					max={ this.state.maxCollaterals }
					placeholder={ `${this.state.maxCollaterals}` }
				/>
			</div>
		)

		// <div className="d-flex align-items-center">
		// 	<img className="mr-2" src={ icon_attention } alt="" />
		// 	<span className="text-muted">max 25 entries</span>
		// 	<input
		// 		className="input-control"
		// 		type="text"
		// 		placeholder={ `${this.state.maxCollaterals}` }
		// 		value={ this.state.inputCollateralSlots }
		// 		onChange={(e) => {
		// 			const value = e.target.value.replaceAll(' ', '');
		// 			if (
		// 				value === '' ||
		// 				isNaN(parseInt(value))
		// 			) {
		// 				this.setState({ inputCollateralSlots: '' })
		// 				return;
		// 			}

		// 			this.setState({ inputCollateralSlots: `${parseInt(value)}` })
		// 		}}
		// 	/>
		// </div>
	}
	getCollateralBlock() {

		if ( this.state.inputRules.noAddCollateral ) { return null; }

		return (
			<div
				className="c-wrap"
				ref={ (e) => { this.scrollable.collaterals = e } }
			>
				<div className="c-wrap__header">
					<div className="h3">
						Collateral
						<Tippy
							content={ this.t('Assets with which you provide your NFT') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 512 }
						>
							<span className="i-tip ml-1"></span>
						</Tippy>
					</div>
					{ this.getCollateralSlotSelector() }
				</div>
				{ this.getMaxCollateralAlert() }
				{/* { this.getCollateralItemAlert() } */}
				<div className="c-wrap__form">
					<p>You can add assets to collateral of your wrapped NFT. Use list of approved tokens.</p>
					<div className="row row-sm mb-5">
						<div className="col-12 col-sm-auto mr-3 py-2">
							<label className="input-label mb-0">Token Type</label>
						</div>
						<div className="col-auto py-2">
							<label className="checkbox">
								<input
									type="radio"
									name="token-type"
									value={ _AssetType.native }
									checked={ this.state.inputAssetType === _AssetType.native }
									onChange={() => { this.setState({
										inputAssetType: _AssetType.native,
										inputCollateralAddress: '',
										inputCollateralTokenId: '',
										inputCollateralAmount: '',
									}) }}
								/>
								<span className="check"> </span>
								<span className="check-text">Native</span>
							</label>
						</div>
						<div className="col-auto py-2">
							<label className="checkbox">
								<input
									type="radio"
									name="token-type"
									value={ _AssetType.ERC20 }
									checked={ this.state.inputAssetType === _AssetType.ERC20 }
									onChange={() => { this.setState({
										inputAssetType: _AssetType.ERC20,
										inputCollateralAddress: '',
										inputCollateralTokenId: '',
										inputCollateralAmount: '',
									}) }}
								/>
								<span className="check"> </span>
								<span className="check-text">{ this.state.EIPPrefix }-20</span>
							</label>
						</div>
						<div className="col-auto py-2">
							<label className="checkbox">
								<input
									type="radio"
									name="token-type"
									value={ _AssetType.ERC721 }
									checked={ this.state.inputAssetType === _AssetType.ERC721 }
									onChange={() => { this.setState({
										inputAssetType: _AssetType.ERC721,
										inputCollateralAddress: '',
										inputCollateralTokenId: '',
										inputCollateralAmount: '',
									}) }}
								/>
								<span className="check"> </span>
								<span className="check-text">{ this.state.EIPPrefix }-721</span>
							</label>
						</div>
						<div className="col-auto py-2">
							<label className="checkbox">
								<input
									type="radio"
									name="token-type"
									value={ _AssetType.ERC1155 }
									checked={ this.state.inputAssetType === _AssetType.ERC1155 }
									onChange={() => { this.setState({
										inputAssetType: _AssetType.ERC1155,
										inputCollateralAddress: '',
										inputCollateralTokenId: '',
										inputCollateralAmount: '',
									}) }}
								/>
								<span className="check"> </span>
								<span className="check-text">{ this.state.EIPPrefix }-1155</span>
							</label>
						</div>
					</div>
					{ this.getCollateralNativeBlock() }
					{ this.getCollateralERC20Block() }
					{ this.getCollateralERC721Block() }
					{ this.getCollateralERC1155Block() }
				</div>

				<div className="text-right">
					<b>{ this.state.collaterals.length }</b> / <span className="text-muted">{ this.state.inputCollateralSlots === undefined ? this.state.maxCollaterals : this.state.inputCollateralSlots } entries</span>
				</div>

				{ this.getCollateralRows() }
			</div>
		)
	}
	// ----- END COLLATERAL -----

	getFeeBlock() {

		if ( this.state.inputRules.noTransfer ) { return null; }

		return (
			<div className="c-wrap">
				<div className="c-wrap__header">
					<div className="h3">
						Transfer Fee
						<Tippy
							content={ this.t('Amount of transfer fee. Sender has to pay this fee to transfer wNFT. So that fee amount must be approved to this contract before any wNFT transfer (marketplace trading).') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 512 }
						>
							<span className="i-tip ml-1"></span>
						</Tippy>
					</div>
				</div>
				<div className="c-wrap__form">
					<div className="row">
						<div className="col-md-7">
							<div className="select-group">
								<input
									className="input-control"
									type="text"
									placeholder="0.000"
									disabled={ this.state.inputRules.noTransfer }
									value={ addThousandSeparator(this.state.inputTransferFeeAmount) }
									onChange={(e) => {
										let value = removeThousandSeparator(e.target.value);
										if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
											if ( new BigNumber(value).isNaN() ) {
												this.setState({ inputFeeLockChecked: false, });
												return;
											}
											value = new BigNumber(value).toString();
											this.setState({ inputFeeLockChecked: false, });
										}

										let feeLockChecked = this.state.inputFeeLockChecked;
										if (
											value === '' ||
											new BigNumber(value).eq(0)
										) {
											feeLockChecked = false
										} else {
											if ( this.state.inputFeeLockAmount !== '' ) {
												feeLockChecked = true
											}
										}

										if ( this.state.inputAddWNFTChecked && value !== '' ) {
											setTimeout(() => { this.addCollateralERC20Row(true) }, 1);
										}

										this.setState({
											inputTransferFeeAmount: value,
											inputFeeLockChecked: feeLockChecked,
										})
									}}
									onBlur={(e) => {
										if ( e.target.value === '' ) {
											this.setState({
												collaterals: this.state.collaterals.filter((item: CollateralItem) => { return !item.amount || !item.amount.eq(0) }),
											});
										} else {
											if ( this.state.inputAddWNFTChecked ) {
												this.addCollateralERC20Row(true);
											}
										}
									}}
								/>
								<CoinSelector
									store         = { this.store }
									tokens        = { this.filterERC20ContractByPermissions({ enabledForFee: true }) }
									selectedToken = { this.state.inputTransferFeeAddress }
									onChange      = {(address: string) => {
										if ( this.state.inputAddWNFTChecked && this.state.inputTransferFeeAmount !== '' ) {
											setTimeout(() => { this.addCollateralERC20Row(true) }, 1);
										}

										this.setState({
											inputTransferFeeAddress: address,
										})
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	recalcPercents(recs: Array<RoyaltyInput>) {
		let sum = 10000;
		const recLength = recs.length;
		return recs.map((item: RoyaltyInput): RoyaltyInput => {
			let percentToAdd;
			if ( sum > Math.ceil(10000 / recLength) + recLength ) {
				percentToAdd = new BigNumber(Math.floor(10000 / recLength)).dividedBy(100);
				sum = sum - Math.floor(10000 / recLength);
			} else {
				percentToAdd = new BigNumber(sum).dividedBy(100);
				sum = 0;
			}
			return {
				...item,
				percent: percentToAdd.toFixed(2)
			}
		});
	}
	addRecipient(address: string | undefined) {

		const foundRecipient = this.state.recipients.filter((item) => {
			if ( !item.address && !address ) { return true; }
			if ( !item.address ) { return false; }
			if ( !address ) { return false; }
			return item.address.toLowerCase() === address.toLowerCase()
		});
		if ( foundRecipient.length ) { return; }

		let collateralsUpdated = this.state.collaterals;
		if ( !address ) {
			const foundFeeToken = collateralsUpdated.find((item: CollateralItem) => { return item.address.toLowerCase() === this.state.inputTransferFeeAddress.toLowerCase() });
			if ( !foundFeeToken ) {
				collateralsUpdated = [
					...collateralsUpdated,
					{
						assetType: _AssetType.ERC20,
						address: this.state.inputTransferFeeAddress,
						amount: new BigNumber(0),
					}
				]
			}
		}

		this.setState({
			recipients: [
				...this.state.recipients,
				{
					address: address,
					percent: '0',
					timeAdded: new Date().getTime(),
				}
			],
			collaterals: collateralsUpdated,
			inputRecipientAddress: '',
			showErrors: this.state.showErrors.filter((item) => { return item !== 'royalty' })
		});
	}
	removeRecipient(address: string | undefined) {
		let recipientsUpdated: Array<RoyaltyInput>;
		let theWNFTChecked = this.state.inputAddWNFTChecked;
		if ( address ) {
			recipientsUpdated = this.state.recipients.filter((item) => {
				if ( !item.address ) { return true; }
				return item.address.toLowerCase() !== address.toLowerCase()
			});
		} else {
			recipientsUpdated = this.state.recipients.filter((item) => {
				return !!item.address
			});
			theWNFTChecked = false;
		}

		let collateralsUpdated = this.state.collaterals;
		if ( !address ) {
			collateralsUpdated = collateralsUpdated.filter((item: CollateralItem) => { return !item.amount || !item.amount.eq(0) })
		}

		this.setState({
			recipients: recipientsUpdated,
			inputRecipientAddress: '',
			inputAddWNFTChecked: theWNFTChecked,
			collaterals: collateralsUpdated,
		});
	}
	getAddRecipientBtn() {
		return (
			<button
				className="btn btn-grad"
				type="submit"
				disabled={ this.isAddRecipientDisabled() }
				onClick={() => { this.addRecipient(this.state.inputRecipientAddress) }}
			>Add</button>
		)
	}
	isAddRecipientDisabled() {
		if ( this.state.inputRecipientAddress === '' ) { return true; }
		if (
			this.metamaskAdapter.web3 &&
			!this.metamaskAdapter.web3.utils.isAddress(this.state.inputRecipientAddress)
		) { return true; }
		return false;
	}
	getRecipientCalcedRoyalty(item: RoyaltyInput) {
		if ( this.state.inputTransferFeeAmount === '' ) { return '—' }

		const fee = new BigNumber(this.state.inputTransferFeeAmount);
		if ( !fee || fee.isNaN() ) { return '—' }

		const percent = new BigNumber(item.percent).dividedBy(100);

		return fee.multipliedBy(percent).toString()
	}
	getRecipientRow(item: RoyaltyInput, idx: number) {
		return (
			<div
				key={ item.address || 'none' }
				className="item"
			>
				<div className="row">
					<div className="mb-2 col-12 col-md-1">#{ idx }</div>
					<div className="mb-3 mb-md-2 col-12 col-md-4">
						<span className="col-legend">Token: </span>
						<span className="text-break">{ item.address ? compactString(item.address) : 'the wNFT' }</span>
					</div>
					<div className="mb-2 col-12 col-md-2">
						<div className="tb-input tb-percent">
							<input
								className="input-control"
								type="text"
								value={ item.percent.toString() }
								onFocus={(e) => { e.target.select() }}
								onChange={(e) => {

									let value = e.target.value.replaceAll(' ', '').replaceAll(',', '.');
									const valueParsed = new BigNumber(value);

									if ( value === '' ) {
										value = '';
									} else {
										if ( valueParsed.isNaN() ) { return; }
										if ( valueParsed.gt(100) ) { return; }
									}

									// the wNFT
									if ( !item.address ) {
										this.setState({
											recipients: [
												...this.state.recipients.filter((iitem) => { return !!iitem.address }),
												{
													address: undefined,
													percent: value,
												}
											],
											showErrors: this.state.showErrors.filter((item) => { return item !== 'royalty' })
										});
										return;
									}

									// plain address
									const foundRecipient = this.state.recipients.filter((iitem) => {
										if ( !iitem.address ) { return false; }
										if ( !item.address ) { return false; }
										return iitem.address.toLowerCase() === item.address.toLowerCase()
									});
									if ( foundRecipient.length ) {
										this.setState({
											recipients: [
												...this.state.recipients.filter((iitem) => {
													if ( !iitem.address ) { return true; }
													if ( !item.address ) { return true; }
													return iitem.address.toLowerCase() !== item.address.toLowerCase()
												}),
												{
													address: item.address,
													percent: value,
													timeAdded: foundRecipient[0].timeAdded
												},
											],
											showErrors: this.state.showErrors.filter((item) => { return item !== 'royalty' })
										});
									}
								}}
								onBlur={() => {
									this.setState({
										recipients: this.state.recipients.map((item) => {
											return {
												...item,
												percent: new BigNumber(item.percent).toFixed(2, BigNumber.ROUND_DOWN)
											}
										})
									});
								}}
							/>
							<Tippy
								content={ this.t('Percent of royalty from the transfer fee amount') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span className="i-tip ml-1"></span>
							</Tippy>
						</div>
					</div>
					<div className="mb-2 col-12 col-md-4">
						<span className="col-legend">Amount: </span>
						<span className="text-break">{ this.getRecipientCalcedRoyalty(item) }</span>
					</div>
					{
						item.address ? (
							<button
								className="btn-del"
								onClick={() => { this.removeRecipient(item.address) }}
							><img src={ icon_i_del } alt="" /></button>
						): null
					}
				</div>
			</div>
		)
	}
	getDistributionAlert() {

		const showError = this.state.showErrors.find((item) => { return item === 'royalty' })
		if ( !showError ) { return null; }

		if ( this.state.inputTransferFeeAmount !== '' ) {
			const sumRoyaltyPercent = this.state.recipients.reduce((acc, item) => {
				const percentToAdd = new BigNumber(item.percent);
				if ( percentToAdd.isNaN() ) { return acc }
				return acc.plus(percentToAdd)
			}, new BigNumber(0));
			if ( !sumRoyaltyPercent.eq(100) ) { return ( <div className="alert alert-error mt-3">Royalty percent is not equal 100</div> ) }
		}

		return null;
	}
	getRoyaltyBlock() {

		if ( this.state.inputRules.noTransfer ) { return null; }

		const showError = this.state.showErrors.find((item) => { return item === 'emptyRecipients' })

		return (
			<div className="c-wrap">
				<div className="c-wrap__header d-block">
					<div className="row align-items-center">
						<div className="col-12 col-md-auto">
							<div
								className="h3 mt-0"
								ref={ (e) => { this.scrollable.royalty = e } }
							>Royalty Recipients</div>
						</div>
						<div className="col-12 col-md-auto">
							<label className="checkbox">
								<input
									type="checkbox"
									disabled={ this.state.inputTransferFeeAmount === '' || this.state.inputRules.noTransfer }
									checked={ this.state.inputAddWNFTChecked }
									onChange={(e) => {
										if ( e.target.checked ) {
											this.setState({
												inputAddWNFTChecked: e.target.checked,
												showErrors: this.state.showErrors.filter((item) => { return item !== 'emptyRecipients' })
											});
											setTimeout(() => { this.addRecipient(undefined) }, 1);
										} else {
											this.setState({
												inputAddWNFTChecked: e.target.checked,
												showErrors: this.state.showErrors.filter((item) => { return item !== 'emptyRecipients' }),
												inputFeeLockChecked: false
											})
											setTimeout(() => { this.removeRecipient(undefined) }, 1);
										}
									}}
								/>
								<span className="check"> </span>
								<span className="check-text">Add the wNFT to the recipents’s list</span>
							</label>
						</div>
					</div>
				</div>
				<div className="c-wrap__form">
					<div className="row mb-1">
						<div className="col col-12 col-md-6">
							<label className="input-label">
								Address
								<Tippy
									content={ this.t('Address of royalty income reciever') }
									appendTo={ document.getElementsByClassName("wrapper")[0] }
									trigger='mouseenter'
									interactive={ false }
									arrow={ false }
									maxWidth={ 512 }
								>
									<span className="i-tip"></span>
								</Tippy>
							</label>
							<input
								className={`input-control ${ showError ? 'has-error' : '' }`}
								type="text"
								placeholder="Paste here"
								value={ this.state.inputRecipientAddress }
								onChange={(e) => {
									this.setState({
										inputRecipientAddress: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, ""),
										showErrors: this.state.showErrors.filter((item) => { return item !== 'emptyRecipients' })
									})
								}}
								onKeyPress={(e) => {
									if ( e.defaultPrevented)               { return; }
									if ( !!this.isAddRecipientDisabled() ) { return; }
									if ( e.key !== 'Enter' )               { return; }

									this.addRecipient(this.state.inputRecipientAddress)
								}}
							/>
						</div>
						<div className="col col-12 col-md-2">
							<label className="input-label">&nbsp;</label>
							{ this.getAddRecipientBtn() }
						</div>
					</div>

					{
						this.state.recipients.length ?
						(
							<div className="row">
								<div className="col-sm-6 col-md-3">
									<button
										className="btn btn-sm btn-gray"
										onClick={() => {
											this.setState({
												recipients: this.recalcPercents(this.state.recipients),
												showErrors: this.state.showErrors.filter((item) => { return item !== 'emptyRecipients' })
											});
										}}
									>Divide percents evenly</button>
								</div>
							</div>
						) : null
					}

					{ this.getDistributionAlert() }

				</div>
				<div className="c-wrap__table mt-3">
					{
						this.state.recipients
							.sort((item, prev) => {

								if ( !item.address ) {
									return -1
								}
								if ( !prev.address ) {
									return 1
								}

								// if ( item.address < prev.address ) { return -1 }
								// if ( item.address > prev.address ) { return  1 }

								if ( !item.timeAdded ) {
									return -1
								}
								if ( !prev.timeAdded ) {
									return 1
								}
								if ( item.timeAdded < prev.timeAdded ) { return -1 }
								if ( item.timeAdded > prev.timeAdded ) { return  1 }

								return 0;

							})
							.map((item, idx) => { return this.getRecipientRow(item, idx + 1) })
					}
				</div>
			</div>
		)
	}

	getDaysPretty() {
		const daysToAdd = parseInt(this.state.inputTimeLockDays) || 0;
		if ( daysToAdd === 0 ) { return '' }

		const output = new Date();
		output.setDate(output.getDate() + daysToAdd);
		return `${output.getDate()} ${monthesNames[output.getMonth()]} ${output.getFullYear()}`;
	}
	addDays(num: number) {
		const output = new Date();
		output.setDate(output.getDate() + num);
		return output;
	}
	getFeeLockTokenIcon() {
		let icon = default_icon;
		if (
			this.state.inputTransferFeeAddress.toLowerCase() === this.state.techToken.address.toLowerCase() &&
			this.state.techToken.icon
		) {
			icon = this.state.techToken.icon
		} else {
			const foundToken = this.state.erc20CollateralTokens.filter((item) => {
				if ( !item.address ) { return false }
				return this.state.inputTransferFeeAddress.toLowerCase() === item.address.toLowerCase()
			});
			if ( foundToken.length && foundToken[0].icon ) {
				icon = foundToken[0].icon
			}
		}

		return (
			<span className="i-coin"><img src={ icon } alt="" /></span>
		)
	}
	getFeeUnlockInput() {
		if ( !this.state.inputFeeLockChecked ) { return null; }

		const feeShowError = this.state.showErrors.find((item) => { return item === 'feeUnlock' });

		if ( feeShowError ) {

			return (
				<div className="checkbox__extra">
					<div className="coin-group">
						{ this.getFeeLockTokenIcon() }
						<input
							className="input-control has-error"
							type="text"
							placeholder="0"
							ref={ (e) => { this.focusable.fees = e } }
							value={ addThousandSeparator(this.state.inputFeeLockAmount) }
							onChange={(e) => {
								let value = removeThousandSeparator(e.target.value);
								if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
									if ( new BigNumber(value).isNaN() ) { return; }
									value = new BigNumber(value).toString();
								}
								this.setState({
									inputFeeLockAmount: value,
									showErrors: this.state.showErrors.filter((item) => { return item !== 'feeUnlock' })
								})
							}}
						/>
					</div>
				</div>
			)

		}

		if (
			this.state.inputFeeLockChecked &&
			this.state.inputFeeLockAmount !== '' &&
			this.state.collaterals.length
		) {
			const foundCollateral = this.state.collaterals.find((item) => { return item.address.toLowerCase() === this.state.inputTransferFeeAddress.toLowerCase() });
			if ( foundCollateral && foundCollateral.amount ) {
				if ( foundCollateral.amount.gt(new BigNumber( this.state.inputFeeLockAmount )) ) {

					return (
						<div className="checkbox__extra">
							<div className="coin-group">
								{ this.getFeeLockTokenIcon() }
								<input
									className="input-control has-error"
									type="text"
									placeholder="0"
									ref={ (e) => { this.focusable.fees = e } }
									value={ addThousandSeparator(this.state.inputFeeLockAmount) }
									onChange={(e) => {
										let value = removeThousandSeparator(e.target.value);
										if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
											if ( new BigNumber(value).isNaN() ) { return; }
											value = new BigNumber(value).toString();
										}
										this.setState({
											inputFeeLockAmount: value,
											showErrors: this.state.showErrors.filter((item) => { return item !== 'feeUnlock' })
										})
									}}
								/>
								<div className="input-error">Collateral exeeds lock</div>
							</div>
						</div>
					)

				}
			}
		}

		return (
			<div className="checkbox__extra">
				<div className="coin-group">
					{ this.getFeeLockTokenIcon() }
					<input
						className="input-control"
						type="text"
						placeholder="0"
						ref={ (e) => { this.focusable.fees = e } }
						value={ addThousandSeparator(this.state.inputFeeLockAmount) }
						onChange={(e) => {
							let value = removeThousandSeparator(e.target.value);
							if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
								if ( new BigNumber(value).isNaN() ) { return; }
								value = new BigNumber(value).toString();
							}
							this.setState({
								inputFeeLockAmount: value,
								showErrors: this.state.showErrors.filter((item) => { return item !== 'feeUnlock' })
							})
						}}
					/>
				</div>
			</div>
		)
	}
	getFeeUnlockBlock() {
		if ( this.state.inputRules.noTransfer ) { return null; }

		return (
			<div className="col-12 col-sm-auto mt-4">
						<label className="checkbox">
							<input
								type="checkbox"
								checked={ this.state.inputFeeLockChecked }
								disabled={
									this.state.inputTransferFeeAmount === '' ||
									new BigNumber(this.state.inputTransferFeeAmount).isNaN() ||
									new BigNumber(this.state.inputTransferFeeAmount).eq(0) ||
									this.state.inputRules.noUnwrap ||
									!this.state.inputAddWNFTChecked
								}
								onChange={(e) => {
									if (
										this.state.inputTransferFeeAmount === '' ||
										new BigNumber(this.state.inputTransferFeeAmount).isNaN() ||
										new BigNumber(this.state.inputTransferFeeAmount).eq(0)
									) { return; }
									this.setState({
										inputFeeLockChecked: e.target.checked,
										showErrors: this.state.showErrors.filter((item) => { return item !== 'feeUnlock' })
									});
									if ( e.target.checked ) {
										setTimeout(() => { this.focusable.fees.focus() }, 1);
									}
								}}
							/>
							<span className="check"> </span>
							<span className="check-text">FeeLock</span>
							<Tippy
								content={ this.t('Unwrap will be available only after accumulated transfer fee will achieve this amount') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span className="i-tip"></span>
							</Tippy>
						</label>
						{ this.getFeeUnlockInput() }
					</div>
		)
	}
	getUnlockBlock() {

		if ( this.state.inputRules.noUnwrap ) { return null; }

		const timeShowError = this.state.showErrors.find((item) => { return item === 'timeUnlock' })
		return (
			<div
				className="c-wrap wrap__settings"
				ref={ (e) => { this.scrollable.locks = e } }
			>
				<div><b>Unlock Settings</b></div>
				<div className="row">
					<div className="col-12 col-sm-auto mt-4">
						<label className="checkbox">
							<input
								type="checkbox"
								checked={ this.state.inputTimeLockChecked }
								disabled={ this.state.inputRules.noUnwrap }
								onChange={(e) => {
									this.setState({
										inputTimeLockChecked: e.target.checked,
										showErrors: this.state.showErrors.filter((item) => { return item !== 'timeUnlock' })
									});
									if ( e.target.checked ) {
										setTimeout(() => { this.focusable.time.focus() }, 1);
									}
								}}
							/>
							<span className="check"> </span>
							<span className="check-text">TimeLock (days)</span>
							<Tippy
								content={ this.t('Unwrap will be available only after this date') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 512 }
							>
								<span className="i-tip"></span>
							</Tippy>
						</label>

						{
							this.state.inputTimeLockChecked ? (
								<div className="checkbox__extra">
									<input
										className={`input-control ${ timeShowError ? 'has-error' : '' }`}
										type="text"
										placeholder="0"
										ref={ (e) => { this.focusable.time = e } }
										value={ this.state.inputTimeLockDays }
										onChange={(e) => {
											let value = e.target.value.replaceAll(' ', '');

											if (
												value === '' ||
												isNaN(parseInt(value))
											) {
												this.setState({ inputTimeLockDays: '' });
											} else {

												if ( parseInt(value) > 9999999 ) {
													return;
												}

												this.setState({
													inputTimeLockDays: `${parseInt(value)}`,
													showErrors: this.state.showErrors.filter((item) => { return item !== 'timeUnlock' })
												});
											}
										}}
									/>
									<div className="text-muted mt-2">{ this.getDaysPretty() }</div>
								</div>
							) : null
						}
					</div>

					{ this.getFeeUnlockBlock() }
				</div>
			</div>
		)
	}

	hasErrors() {

		const errors: Array<{ msg: string, block: HTMLElement, showError?: string }> = [];

		// ----- ORIGINAL TOKEN -----
		if (
			!this.state.inputEmptyChecked &&
			this.state.inputOriginalTokenAddress === '' &&
			this.state.inputOriginalTokenId === ''
		) {
			errors.push({ msg: 'Enter original token info or set empty', block: this.scrollable.originalToken })
		}

		if (
			!this.state.inputEmptyChecked &&
			this.state.originalTokenInfo.owner &&
			this.state.originalTokenInfo.owner.toLowerCase() !== this.state.userAddress.toLowerCase()
		) {
			errors.push({ msg: 'Not yours original token', block: this.scrollable.originalToken })
		}

		if ( this.state.originalTokensBlacklist ) {
			const foundBlacklistItem = this.state.originalTokensBlacklist.find((item: any) => {
				if ( typeof(item) === 'string' ) {
					return item.toLowerCase() === this.state.inputOriginalTokenAddress.toLowerCase()
				}
				return item.contractAddress.toLowerCase() === this.state.inputOriginalTokenAddress.toLowerCase()
			});
			if ( foundBlacklistItem ) {
				errors.push({ msg: 'Original token address is blacklisted', block: this.scrollable.originalToken })
			}
		}

		if ( this.state.inputOriginalCopies === '' ) {
			if ( this.state.originalTokenInfo.originalTokenStandart === _AssetType.ERC1155 ) {
				errors.push({ msg: 'Enter original token amount', block: this.scrollable.originalToken })
			}
		} else {
			if (
				!this.state.inputEmptyChecked &&
				this.state.originalTokenInfo.balance1155 &&
				this.state.originalTokenInfo.balance1155.lt(new BigNumber( this.state.inputOriginalCopies ))
			) {
				errors.push({ msg: 'Not enough original token balance', block: this.scrollable.originalToken })
			}
		}

		if (
			this.state.inputStandart === _AssetType.ERC1155 &&
			this.state.inputCopies === 0
		) {
			errors.push({ msg: 'Enter wnft token amount', block: this.scrollable.standart, showError: 'standart' })
		}

		if (
			this.metamaskAdapter &&
			this.metamaskAdapter.web3 &&
			!this.state.inputEmptyChecked &&
			this.state.inputOriginalTokenAddress !== '' &&
			!this.metamaskAdapter.web3.utils.isAddress(this.state.inputOriginalTokenAddress)
		) {
			errors.push({ msg: 'Bad address format', block: this.scrollable.originalToken })
		}
		if (
			this.metamaskAdapter &&
			this.metamaskAdapter.web3 &&
			this.state.inputWNFTRecipientAddress !== '' &&
			!this.metamaskAdapter.web3.utils.isAddress(this.state.inputWNFTRecipientAddress)
		) {
			errors.push({ msg: 'Bad wNFT recipient address format', block: this.scrollable.advancedOptions })
		}
		// ----- END ORIGINAL TOKEN -----

		// ----- COLLATERALS -----'
		if ( this.state.inputRules.noAddCollateral && this.state.collaterals.length ) {
			errors.push({ msg: 'Cannot add collaterals due to rule', block: this.scrollable.collaterals })
		}
		if ( this.overMaxCollaterals() ) {
			errors.push({ msg: 'Over max collaterals', block: this.scrollable.collaterals })
		}
		// ----- END COLLATERALS -----

		// ----- ROYALTIES -----
		if ( !this.state.inputRules.noTransfer ) {
			if (
				this.state.inputTransferFeeAmount !== '' &&
				!this.state.recipients.length
			) {
				errors.push({ msg: 'Empty recipients', block: this.scrollable.royalty, showError: 'emptyRecipients' })
			} else {
				if ( this.state.inputTransferFeeAmount !== '' ) {
					const sumRoyaltyPercent = this.state.recipients.reduce((acc, item) => {
						const percentToAdd = new BigNumber(item.percent);
						if ( percentToAdd.isNaN() ) { return acc }
						return acc.plus(percentToAdd)
					}, new BigNumber(0));
					if ( !sumRoyaltyPercent.eq(100) ) { errors.push({ msg: 'Royalty percent is not equal 100', block: this.scrollable.royalty, showError: 'royalty' }) }
				}
			}
		}
		// ----- END ROYALTIES -----

		// ----- LOCKS -----
		if (
			this.state.inputFeeLockChecked
		) {
			const feeAmount = new BigNumber(this.state.inputFeeLockAmount);
			if ( feeAmount.isNaN() || feeAmount.eq(0) ) {
				errors.push({ msg: 'Empty fee unlock', block: this.scrollable.locks, showError: 'feeUnlock' })
			}
		}
		if (
			this.state.inputFeeLockChecked &&
			!this.state.inputAddWNFTChecked
		) {
			errors.push({ msg: 'No the wNFT in recipients list with fee lock', block: this.scrollable.royalty })
		}

		if (
			this.state.inputFeeLockChecked &&
			this.state.inputFeeLockAmount !== '' &&
			this.state.collaterals.length
		) {
			const foundCollateral = this.state.collaterals.find((item) => { return item.address.toLowerCase() === this.state.inputTransferFeeAddress.toLowerCase() });
			if ( foundCollateral && foundCollateral.amount ) {
				if ( foundCollateral.amount.gt(new BigNumber( this.state.inputFeeLockAmount )) ) {
					errors.push({ msg: 'Collateral exeeds lock', block: this.scrollable.locks })
				}
			}
		}

		if (
			this.state.inputTimeLockChecked
		) {
			const days = new BigNumber(this.state.inputTimeLockDays);
			if ( days.isNaN() || days.eq(0) ) {
				errors.push({ msg: 'Empty time unlock', block: this.scrollable.locks, showError: 'timeUnlock' })
			}
		}

		// ----- END LOCKS -----


		// if (
		// 	!this.state.inputEmptyChecked &&
		// 	this.state.originalTokenWrapped &&
		// 	this.state.originalTokenWrapped.rules.noWrap
		// ) { return this.t('No wrap') }
		if (
			this.metamaskAdapter.wrapperContract &&
			!this.state.inputEmptyChecked &&
			this.store.getState().wNFTStorages.storagesCount === this.store.getState().wNFTStorages.list.length &&
			this.metamaskAdapter.wrapperContract.addressIsStorage(this.state.inputOriginalTokenAddress)
		) {
			errors.push({ msg: 'Cannot wrap wrapped', block: this.scrollable.originalToken })
		}

		this.state.collateralErrors.forEach((item) => { errors.push({ msg: `Error with collateral: ${compactString(item.address)} - ${item.tokenId || ''}. ${item.msg}`, block: this.scrollable.collaterals }) })

		return errors;
	}
	getErrorsBlock() {
		const errors = this.hasErrors();

		if ( !errors.length ) { return null; }

		return (
			<div className="c-errors mt-4">
				<div className="mb-3">To enable the Wrap button, correct the following errors:</div>
				<ul>
				{
					errors.map((item) => {
						return (
							<li key={ item.msg }>
								<button
									className="btn-link"
									onClick={() => {
										item.block.scrollIntoView();
										if ( item.showError ) {
											this.setState({
												showErrors: [
													...this.state.showErrors.filter((iitem) => { return iitem !== item.showError }),
													item.showError
												]
											})
										}
									}}
								>
									{ item.msg }
								</button>
							</li>
						)
					})
				}
				</ul>
			</div>
		)
	}

	getWrapBtn() {
		return (
			<button
				className="btn btn-lg w-100"
				disabled={ !!this.hasErrors().length }
				onClick={() => { this.wrapSubmit() }}
			>{ this.t('Wrap') }</button>
		)
	}

	getFeeToken() {
		let feeToken = undefined;
		if ( this.state.inputTransferFeeAddress.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
			feeToken = this.state.techToken;
		} else {
			const foundToken = this.state.erc20CollateralTokens.filter((item) => {
				if ( !item.address ) { return false;}
				return item.address.toLowerCase() === this.state.inputTransferFeeAddress.toLowerCase()
			});
			if ( foundToken.length ) {
					feeToken = foundToken[0];
			}
		}
		return feeToken;
	}
	getWrapParamUnwrapDestination() {
		if ( this.state.inputCollateralRecipientAddress !== '' ) {
			return this.state.inputCollateralRecipientAddress;
		}
		return this.state.userAddress;
	}

	getWrapParamLocks(): Array<Lock> {
		const locks: Array<Lock> = [];

		if ( !this.state.inputRules.noUnwrap && this.state.inputTimeLockChecked ) {
			const days = parseInt(this.state.inputTimeLockDays);
			const unlockDate = this.addDays(days);
			locks.push({
				lockType: LockType.time,
				param: dateToUnixtime(unlockDate),
			});
		}

		if ( !this.state.inputRules.noUnwrap && this.state.inputFeeLockChecked ) {
			const feeToken = this.getFeeToken();
			if ( !feeToken ) {
				console.log('Cannot parse feetoken');
				throw new Error('Cannot parse feetoken');
			}

			const amount = tokenToInt(new BigNumber(this.state.inputFeeLockAmount), feeToken.decimals || 18);
			locks.push({
				lockType: LockType.value,
				param: amount
			});
		}

		if ( this.state.inputCollateralSlots ) {
			const amount = new BigNumber(this.state.inputCollateralSlots);
			locks.push({
				lockType: LockType.slots,
				param: amount
			});
		}

		return locks;
	}
	getWrapParamFees(): Array<Fee> {

		if ( this.state.inputRules.noTransfer ) { return []; }

		const feeToken = this.getFeeToken();
		if ( !feeToken ) {
			console.log('Cannot parse feetoken');
			throw new Error('Cannot parse feetoken');
		}

		const amount = tokenToInt(new BigNumber(this.state.inputTransferFeeAmount), feeToken.decimals || 18);

		const output: Array<Fee> = [];
		if ( this.state.inputTransferFeeAmount !== '' ) {
			output.push({
				token: this.state.inputTransferFeeAddress,
				value: amount,
			});
		}
		return output;
	}

	wrapSubmit() {

		let originalToken;
		if ( this.state.inputEmptyChecked ) {
			originalToken = {
				assetType: _AssetType.empty,
				contractAddress: '0x0000000000000000000000000000000000000000',
				tokenId: '0',
				amount: new BigNumber('0'),
			}
		} else {
			let amount = new BigNumber('0');
			if ( this.state.originalTokenInfo.originalTokenStandart === _AssetType.ERC1155 ) {
				if ( this.state.inputOriginalCopies !== '' ) {
					amount = new BigNumber(this.state.inputOriginalCopies);
				} else {
					amount = new BigNumber(1);
				}
			}
			originalToken = {
				assetType      : this.state.originalTokenInfo.originalTokenStandart || _AssetType.ERC721,
				contractAddress: this.state.inputOriginalTokenAddress,
				tokenId        : this.state.inputOriginalTokenId,
				amount         : amount,
			}
		}

		let royaltiesParsed: Array<Royalty> = [];
		if ( !this.state.inputRules.noTransfer ) {
			royaltiesParsed = this.state.recipients.map((item: RoyaltyInput): Royalty => {
				return {
					address: item.address,
					percent: new BigNumber(item.percent)
				}
			})
		}

		const collateralsSorted = this.state.collaterals.sort((item, prev) => {
			if (
				this.state.inputTransferFeeAmount !== '' &&
				!new BigNumber(this.state.inputTransferFeeAmount).isNaN() &&
				!new BigNumber(this.state.inputTransferFeeAmount).eq(0) &&
				this.state.inputAddWNFTChecked
			) {
				if ( item.address.toLowerCase() === this.state.inputTransferFeeAddress.toLowerCase() ) {
					return -1;
				} else {
					return 1;
				}
			}

			return item.assetType - prev.assetType
		})

		if ( this.state.authMethod === 'gnosis' ) {
			this.metamaskAdapter.wrapperContract.wrapTokenMultisig({
				originalToken    : originalToken,
				approveAll       : this.state.inputApproveAll,
				fees             : this.getWrapParamFees(),
				royalties        : royaltiesParsed,
				collaterals      : collateralsSorted,
				rules            : this.state.inputRules,
				locks            : this.getWrapParamLocks(),
				outType          : this.state.inputStandart,
				outBalance       : this.state.inputStandart === _AssetType.ERC721    ? 0                                          : this.state.inputCopies || 1,
				wrapFor          : this.state.inputWNFTRecipientAddress       !== '' ? this.state.inputWNFTRecipientAddress       : this.state.userAddress,
				unwrapDestination: this.state.inputCollateralRecipientAddress !== '' ? this.state.inputCollateralRecipientAddress : this.state.userAddress,
				history          : this.props.history,
			});
		} else {
			this.metamaskAdapter.wrapperContract.wrapToken({
				originalToken    : originalToken,
				approveAll       : this.state.inputApproveAll,
				fees             : this.getWrapParamFees(),
				royalties        : royaltiesParsed,
				collaterals      : collateralsSorted,
				rules            : this.state.inputRules,
				locks            : this.getWrapParamLocks(),
				outType          : this.state.inputStandart,
				outBalance       : this.state.inputStandart === _AssetType.ERC721    ? 0                                          : this.state.inputCopies || 1,
				wrapFor          : this.state.inputWNFTRecipientAddress       !== '' ? this.state.inputWNFTRecipientAddress       : this.state.userAddress,
				unwrapDestination: this.state.inputCollateralRecipientAddress !== '' ? this.state.inputCollateralRecipientAddress : this.state.userAddress,
				history          : this.props.history,
			});
		}
	}

	render() {

		return (
			<main className="s-main">
				<div className="container">
					<div className="wrap__header">
						<div className="row">
							<div className="col-auto">
								<div className="h3 mt-0">Mint wNFT</div>
							</div>
							{ this.getEmptyCheckboxBlock() }
						</div>
					</div>
					<div className="row">
						<div className="col-lg-8">
							{ this.getTokenInfoBlock() }
							{ this.getStandartSelectorBlock() }
						</div>
						<div className="col-lg-4">
							{ this.getAdvancedOptionsBlock() }
						</div>
					</div>
					{ this.getCollateralBlock() }
					{ this.getFeeBlock() }
					{ this.getRoyaltyBlock() }
					<div className="row">
						<div className="col-md-8 col-lg-6">
							{ this.getUnlockBlock() }
						</div>
						<div className="col-md-4 col-lg-6">
							{ this.getWrapBtn() }
							{ this.getErrorsBlock() }
						</div>
					</div>
				</div>
			</main>
		)
	}
}

export default withTranslation("translations")(withRouter(TokenWrapPage));