
import { Contract } from "web3-eth-contract";

import {
	fillCollateralsImages,
	getERC721Token,
	MetamaskAdapter,
	OriginalTokenType,
	WNFTStorageContract,
	WrappedTokenType,
	_AssetType
} from '../BlockchainAdapter';

import {
	fetchOriginalTokenById,
	fetchWrappedTokenById,
	requestTokenURIUpdate
} from "../APIService/apiservice";

import { getERC1155Token } from "../BlockchainAdapter/erc1155contract";
import { decodeAssetTypeFromIndex } from "../BlockchainAdapter/_types";

import BigNumber from 'bignumber.js';
import { processSwarmUrl } from "../_utils";
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

export const getWrappedToken = async (params: {
	chainId?: number,
	metamaskAdapter?: MetamaskAdapter,
	contractAddress?: string,
	tokenId: string,
	wNFTStorageContract?: WNFTStorageContract,
	assetType?: _AssetType,
	userAddress?: string,
	t?: any,
}): Promise<WrappedTokenType> => {

	let chainId = params.chainId;
	if ( !chainId ) {
		if ( params.metamaskAdapter ) { chainId = params.metamaskAdapter.chainId || 0 }
	}

	if ( !chainId ) { throw new Error('No chainId param in getWrappedToken()'); }

	let contractAddress = params.contractAddress;
	if ( !contractAddress ) {
		if ( params.wNFTStorageContract ) { contractAddress = params.wNFTStorageContract.contractAddress }
	}
	if ( !contractAddress ) { throw new Error('No contractAddress param in getWrappedToken()'); }

	let assetType = params.assetType;
	if ( !assetType ) {
		if ( params.wNFTStorageContract ) { assetType = params.wNFTStorageContract.contractStandart }
	}

	let token = await fetchWrappedTokenById({
		chainId: chainId,
		contractAddress: contractAddress,
		tokenId: params.tokenId,
		assetType: assetType
	});

	if ( token && token.tokenUrl ) {
		if ( params.metamaskAdapter && params.userAddress ) {
			token = await fillCollateralsImages(params.metamaskAdapter, token, params.userAddress);
		}
		return token
	}

	let storageContract = params.wNFTStorageContract;
	if ( !storageContract ) {
		if ( params.metamaskAdapter ) {
			const foundStorage = params.metamaskAdapter.wrapperContract.getStorageContract(contractAddress);
			if ( foundStorage && foundStorage.contract ) {
				storageContract = foundStorage.contract
			}
		}
	}
	if ( !storageContract ) { throw new Error('No storageContract param in getWrappedToken()'); }

	return storageContract.getNFTTokenById(params.tokenId);
}

export const getOriginalToken = async (params: {
	chainId?: number,
	metamaskAdapter?: MetamaskAdapter,
	contractAddress?: string,
	tokenId?: string,
	contract?: Contract
	assetType?: _AssetType,
	userAddress?: string,
	t?: any,
}): Promise<OriginalTokenType> => {

	let token;
	let error;

	if ( !params.metamaskAdapter ) { throw new Error('No metamaskAdapter param in getOriginalToken()') }

	let chainId = params.chainId;
	if ( !chainId ) {
		if ( params.metamaskAdapter ) { chainId = params.metamaskAdapter.chainId || 0 }
	}

	if ( !chainId ) { throw new Error('No chainId param in getWrappedToken()'); }

	let contractAddress = params.contractAddress;
	if ( !contractAddress    ) { throw new Error('No contractAddress param in getWrappedToken()'); }
	if ( !params.tokenId     ) { throw new Error('No tokenId param in getOriginalToken()'); }
	if ( !params.userAddress ) { throw new Error('No userAddress param in getOriginalToken()'); }

	const fetchedToken = await fetchOriginalTokenById({
		chainId: chainId,
		contractAddress: contractAddress,
		tokenId: params.tokenId,
		assetType: params.assetType,
	});

	if ( fetchedToken ) {

		if ( fetchedToken.token_uri ) {

			let resp;
			let tokenJSONRaw;
			let tokenJSON;
			try {
				resp = await fetch(processSwarmUrl(fetchedToken.token_uri));
				tokenJSONRaw = await resp.text();
				tokenJSON = JSON.parse(tokenJSONRaw);
			} catch(ignored) {}

			if ( tokenJSON ) {
				return {
					owner: fetchedToken.owner,
					chainId: chainId,
					assetType: decodeAssetTypeFromIndex(`${fetchedToken.asset_type}`),
					contractAddress: contractAddress,
					tokenId: params.tokenId,
					amount: fetchedToken.balance ? new BigNumber(fetchedToken.balance) : undefined,
					totalSupply: fetchedToken.totalSupply ? new BigNumber(fetchedToken.totalSupply) : undefined,
					description: tokenJSON.description,
					image: processSwarmUrl(tokenJSON.image),
					name: tokenJSON.name,
					tokenUrl: processSwarmUrl(fetchedToken.token_uri),
					tokenUrlRawJSON: tokenJSONRaw,
					sortParams: {
						blockNumber: fetchedToken.blocknumber,
						logIndex: new BigNumber(fetchedToken.logindex),
					}
				}
			}
		} else {
			requestTokenURIUpdate({
				chainId: chainId,
				contractAddress: contractAddress,
				tokenId: params.tokenId,
			})
		}
	}

	if ( params.assetType ) {
		if ( params.assetType === _AssetType.ERC721 ) {

			try {
				token = await getERC721Token(
					params.metamaskAdapter,
					contractAddress,
					params.tokenId,
					params.t,
					{
						blockNumber: undefined,
						logIndex: undefined,
					}
				)
			} catch(e) { error = e; }

			if ( token ) {
				return token;
			}

			throw new Error('Cannot load 721 token');
		}

		if ( params.assetType === _AssetType.ERC1155 ) {

			try {
				token = getERC1155Token(
					params.metamaskAdapter,
					contractAddress,
					params.tokenId,
					params.userAddress,
					params.t,
					{
						blockNumber: undefined,
						logIndex: undefined,
					}
				)
			} catch(e) {}

			if ( token ) {
				return token;
			}

			throw new Error('Cannot load 1155 token');
		}
	}

	try {
		token = await getERC721Token(
			params.metamaskAdapter,
			contractAddress,
			params.tokenId,
			params.t,
			{
				blockNumber: undefined,
				logIndex: undefined,
			}
		)
	} catch(e) { error = e; }

	if ( token ) {
		return token;
	}

	console.log('Cannot load 721 token, trying 1155', error);

	try {
		token = getERC1155Token(
			params.metamaskAdapter,
			contractAddress,
			params.tokenId,
			params.userAddress,
			params.t,
			{
				blockNumber: undefined,
				logIndex: undefined,
			}
		)
	} catch(e) {}

	if ( token ) {
		return token;
	}

	throw new Error('Cannot get original token');
}